import React from 'react';
import styled from 'styled-components';
import { Button, COLOR_NEUTRAL_10, H1, H3 } from '../app/App.style';
import { useTranslation } from 'react-i18next';
import { LektaLogo, LogoContainer } from '../app/features/session/Login.style';
import lektaLogo from '../resources/logo-lekta.png';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  logoutAsyncThunk,
  selectUser,
} from '../app/features/session/api/sessionSlice';

export const NoPermissionsContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLOR_NEUTRAL_10};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const NoPermissionsContentContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 32px;
`;

export const NoPermissionTopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background: #0f172a;
`;

export const NoPermissionBottomBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background: #0f172a;
`;

const NoPermissionsScreen = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <NoPermissionsContainer>
      <NoPermissionTopBar />

      <NoPermissionsContentContainer>
        <LogoContainer style={{ marginBottom: 40 }}>
          <LektaLogo src={lektaLogo} alt="lekta logo" />
        </LogoContainer>

        <H1 $medium>
          {t('common.noPermissions.title')}
          {user.value?.first_name} {user.value?.last_name}
        </H1>
        <div>
          <H3>{t('common.noPermissions.subtitle1')}</H3>
          <H3>{t('common.noPermissions.subtitle2')}</H3>
        </div>
        <Button
          onClick={() => {
            dispatch(logoutAsyncThunk());
          }}
          $styleType="NORMAL"
        >
          {t('sidebar.popover.logout')}
        </Button>
      </NoPermissionsContentContainer>

      <NoPermissionBottomBar />
    </NoPermissionsContainer>
  );
};

export default NoPermissionsScreen;

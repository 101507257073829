import {
  EuiModalBody,
  EuiLoadingSpinner,
  EuiToolTip,
  copyToClipboard,
  EuiButtonIcon,
  EuiFieldText,
} from '@elastic/eui';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  checkAccessTokensCreate,
  getAccessTokensListAsyncThunk,
} from '../../../../userSettings/api/userSettingsSlice';
import Modal from '../../../../../../../common/modals/Modal';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { H3 } from '../../../../../../App.style';

interface PreviewAccessTokensModalProps {
  closeModal: () => void;
}

const PreviewAccessTokensModal: React.FC<PreviewAccessTokensModalProps> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<'loading' | 'ok' | 'fail'>('loading');
  const [accessToken, setAccessToken] = useState<string>('');
  const [isTextCopied, setTextCopied] = useState(false);

  const onBlur = () => {
    setTextCopied(false);
  };

  const statusTitleMap = {
    loading: t('settings.adminPanel.accessTokens.preview.loadingTitle'),
    ok: t('settings.adminPanel.accessTokens.preview.successTitle'),
    fail: t('settings.adminPanel.accessTokens.preview.failTitle'),
  };

  const checkCreate = useAppSelector(checkAccessTokensCreate);
  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success' &&
      checkCreate.value.token
    ) {
      setAccessToken(checkCreate.value.token);
      setStatus('ok');
    } else if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'fail'
    ) {
      setStatus('fail');
    }
  }, [checkCreate]);

  const handleCloseAccessTokenModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    closeModal();
    dispatch(getAccessTokensListAsyncThunk());

    return false;
  };

  const body = (
    <EuiModalBody>
      {status === 'loading' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EuiLoadingSpinner size="xxl" />
        </div>
      ) : status === 'ok' ? (
        <>
          <FormRow
            label={
              <H3>{t('settings.adminPanel.accessTokens.preview.yourToken')}</H3>
            }
          >
            <EuiFieldText
              readOnly
              value={accessToken}
              append={
                <EuiToolTip
                  content={
                    isTextCopied
                      ? t('settings.adminPanel.accessTokens.preview.textCopied')
                      : t('settings.adminPanel.accessTokens.preview.copyText')
                  }
                >
                  <EuiButtonIcon
                    aria-label="Copy text to clipboard"
                    color="text"
                    iconType="copy"
                    onClick={() => {
                      copyToClipboard(accessToken);
                      setTextCopied(true);
                    }}
                    onBlur={onBlur}
                  />
                </EuiToolTip>
              }
            />
          </FormRow>
          <H3 style={{ color: '#BA2525' }}>
            {t('settings.adminPanel.accessTokens.preview.tokenInfo1')}
            <br /> {t('settings.adminPanel.accessTokens.preview.tokenInfo2')}
          </H3>
        </>
      ) : (
        <H3>{t('settings.adminPanel.accessTokens.preview.failMessage')}</H3>
      )}
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={true}
      onCancel={closeModal}
      onConfirm={handleCloseAccessTokenModal}
      children={body}
      headerTitle={statusTitleMap[status]}
      noButtons={status === 'loading'}
      buttonText={t('common.close')}
      noConfirm
    />
  );
};

export default PreviewAccessTokensModal;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ReportsIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M10 13.5C10 13.7761 10.2239 14 10.5 14H11.5C11.7761 14 12 13.7761 12 13.5V7.5C12 7.22386 11.7761 7 11.5 7H10.5C10.2239 7 10 7.22386 10 7.5V13.5Z" />
    <path d="M7.5 14C7.22386 14 7 13.7761 7 13.5V9.5C7 9.22386 7.22386 9 7.5 9H8.5C8.77614 9 9 9.22386 9 9.5V13.5C9 13.7761 8.77614 14 8.5 14H7.5Z" />
    <path d="M4.5 14C4.22386 14 4 13.7761 4 13.5V11.5C4 11.2239 4.22386 11 4.5 11H5.5C5.77614 11 6 11.2239 6 11.5V13.5C6 13.7761 5.77614 14 5.5 14H4.5Z" />
    <path d="M14 14V4.5L9.5 0H4C2.89543 0 2 0.89543 2 2V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14ZM9.5 3C9.5 3.82843 10.1716 4.5 11 4.5H13V14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 14.5523 3 14V2C3 1.44772 3.44772 1 4 1H9.5V3Z" />
  </StyledSvg>
);

import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiSuperSelect,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { ButtonEmpty, H2, H3 } from '../../../App.style';
import {
  BotConfiguration,
  CustomFaas,
  ProcessCreateRequest,
} from '../../../../generated/tenants/Api';
import { FormContainer, FormRow, PresetsBorder } from './ProcessForm.style';
import {
  getCustomFaasesListAsyncThunk,
  getDialoguesListAsyncThunk,
  selectCustomFaasesList,
  selectDialoguesList,
} from '../../presets/dialogues/api/dialoguesSlice';
import CustomFaasFormReadOnly from '../../presets/dialogues/customFaas/CustomFaasFormReadOnly';
import CreateCustomFaas from '../../presets/dialogues/customFaas/CreateCustomFaas';
import { useTranslation } from 'react-i18next';

interface DialoguePresetFormProps {
  strategy: string;
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  edit?: boolean;
}

const DialoguePresetForm: React.FC<DialoguePresetFormProps> = ({
  strategy,
  process,
  setProcess,
  edit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDialoguesListAsyncThunk(strategy));
    dispatch(getCustomFaasesListAsyncThunk(strategy));
  }, []);
  const apiBotConfigList = useAppSelector(selectDialoguesList);

  const [dialoguePreset, setDialoguePreset] = useState<BotConfiguration>();
  useEffect(() => {
    if (process.bot_configuration !== '') {
      const selected = apiBotConfigList.find(
        (bot) => bot.id === process.bot_configuration,
      );

      if (selected) setDialoguePreset(selected);
    }
  }, [apiBotConfigList]);

  const botOptions = [
    ...apiBotConfigList.map((bot) => {
      return {
        value: bot.id,
        inputDisplay: bot.name,
        dropdownDisplay: <H3>{bot.name}</H3>,
      };
    }),
  ];

  useEffect(() => {
    if (dialoguePreset)
      setProcess({ ...process, bot_configuration: dialoguePreset.id });
  }, [dialoguePreset]);

  //custom faases
  const [customFaasPreset, setCustomFaasPreset] = useState<CustomFaas>();
  const [customFaasReportPerCall, setCustomFaasReportPerCall] =
    useState<CustomFaas>();
  const [customFaasReportPerCampaign, setCustomFaasReportPerCampaign] =
    useState<CustomFaas>();
  const [customFaasReportPerRecord, setCustomFaasReportPerRecord] =
    useState<CustomFaas>();
  const [customFaasGetCustomerInfo, setCustomFaasGetCustomerInfo] =
    useState<CustomFaas>();
  const [customFaasReportFeedback, setCustomFaasReportFeedback] =
    useState<CustomFaas>();
  const [newCustomFaasCreated, setNewCustomFaasCreated] =
    useState<boolean>(false);

  useEffect(() => {
    if (process.report_per_call_custom_faas) {
      setCustomFaasReportPerCall(
        apiCustomFaasesList.find(
          (customFaas) => customFaas.id === process.report_per_call_custom_faas,
        ),
      );
    }
    if (process.report_per_campaign_custom_faas) {
      setCustomFaasReportPerCampaign(
        apiCustomFaasesList.find(
          (customFaas) =>
            customFaas.id === process.report_per_campaign_custom_faas,
        ),
      );
    }
    if (process.report_per_record_custom_faas) {
      setCustomFaasReportPerRecord(
        apiCustomFaasesList.find(
          (customFaas) =>
            customFaas.id === process.report_per_record_custom_faas,
        ),
      );
    }
    if (process.get_customer_info_faas) {
      setCustomFaasGetCustomerInfo(
        apiCustomFaasesList.find(
          (customFaas) => customFaas.id === process.get_customer_info_faas,
        ),
      );
    }

    if (process.report_feedback_faas) {
      setCustomFaasReportFeedback(
        apiCustomFaasesList.find(
          (customFaas) => customFaas.id === process.report_feedback_faas,
        ),
      );
    }
  }, [process]);

  const apiCustomFaasesList = useAppSelector(selectCustomFaasesList);

  const importCustomFaasesOptions = [
    ...apiCustomFaasesList
      .filter((customFaas) => customFaas.lambda_type === 'IMPORT')
      .map((customFaas) => ({
        value: customFaas.id,
        inputDisplay: customFaas.name,
        dropdownDisplay: <H3>{customFaas.name}</H3>,
      })),
  ];

  const reportCustomFaasesOptions = [
    ...apiCustomFaasesList
      .filter((customFaas) => customFaas.lambda_type === 'REPORT')
      .map((customFaas) => ({
        value: customFaas.id,
        inputDisplay: customFaas.name,
        dropdownDisplay: <H3>{customFaas.name}</H3>,
      })),
  ];

  const integrationCustomFaasesOptions = [
    ...apiCustomFaasesList
      .filter((customFaas) => customFaas.lambda_type === 'INTEGRATION')
      .map((customFaas) => ({
        value: customFaas.id,
        inputDisplay: customFaas.name,
        dropdownDisplay: <H3>{customFaas.name}</H3>,
      })),
  ];

  useEffect(() => {
    if (customFaasPreset) {
      setProcess({ ...process, import_custom_faas: customFaasPreset.id });
    }
  }, [customFaasPreset]);

  useEffect(() => {
    if (customFaasReportPerCall) {
      setProcess({
        ...process,
        report_per_call_custom_faas: customFaasReportPerCall.id,
      });
    }
  }, [customFaasReportPerCall]);

  useEffect(() => {
    if (customFaasReportPerCampaign) {
      setProcess({
        ...process,
        report_per_campaign_custom_faas: customFaasReportPerCampaign.id,
      });
    }
  }, [customFaasReportPerCampaign]);

  useEffect(() => {
    if (customFaasReportPerRecord) {
      setProcess({
        ...process,
        report_per_record_custom_faas: customFaasReportPerRecord.id,
      });
    }
  }, [customFaasReportPerRecord]);

  useEffect(() => {
    if (customFaasGetCustomerInfo) {
      setProcess({
        ...process,
        get_customer_info_faas: customFaasGetCustomerInfo.id,
      });
    }
  }, [customFaasGetCustomerInfo]);

  useEffect(() => {
    if (customFaasReportFeedback) {
      setProcess({
        ...process,
        report_feedback_faas: customFaasReportFeedback.id,
      });
    }
  }, [customFaasReportFeedback]);

  const handleNewCustomFaasChange = () => {
    setNewCustomFaasCreated((prev) => !prev);
  };

  useEffect(() => {
    if (newCustomFaasCreated) {
      setCustomFaasPreset(apiCustomFaasesList[apiCustomFaasesList.length - 1]);
      handleNewCustomFaasChange();
      return;
    } else if (process.import_custom_faas !== '') {
      const selected = apiCustomFaasesList.find(
        (customFaas) => customFaas.id === process.import_custom_faas,
      );
      if (selected) setCustomFaasPreset(selected);
    }
  }, [apiCustomFaasesList]);

  const [isModifyCustomFaasVisible, setIsModifyCustomFaasVisible] =
    useState<boolean>(false);
  const showCustomFaasModal = () => setIsModifyCustomFaasVisible(true);
  const closeCustomFaasModal = () => setIsModifyCustomFaasVisible(false);

  let modifyCustomFaasModal;
  if (isModifyCustomFaasVisible && customFaasPreset) {
    modifyCustomFaasModal = (
      <EuiModal onClose={closeCustomFaasModal} style={{ width: 600 }}>
        <EuiModalHeader style={{ paddingLeft: 90 }}>
          <H2>{t('processes.form.dialogue.newFaas')}</H2>
        </EuiModalHeader>

        <EuiModalBody style={{ margin: '-50px' }}>
          <CreateCustomFaas
            customFaas={customFaasPreset}
            strategy={strategy}
            backToList={closeCustomFaasModal}
            handleNewCustomFaasChange={handleNewCustomFaasChange}
          />
        </EuiModalBody>
      </EuiModal>
    );
  }

  return (
    <FormContainer $processForm>
      {modifyCustomFaasModal}
      <FormRow
        label={<H3 $bold>{t('processes.form.dialogue.dialoguePreset')}</H3>}
      >
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={botOptions}
          valueOfSelected={dialoguePreset ? dialoguePreset.id : 'Select'}
          onChange={(e) => {
            const selectedBot = apiBotConfigList.find(
              (voice) => voice.id === e,
            );
            if (e !== '') setDialoguePreset(selectedBot);
          }}
        />
      </FormRow>

      <FormRow
        label={<H3 $bold>{t('processes.form.dialogue.importFaas')} </H3>}
      >
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={importCustomFaasesOptions}
          valueOfSelected={customFaasPreset ? customFaasPreset.id : 'empty'}
          onChange={(e) => {
            const selectedCustomFaas = apiCustomFaasesList.find(
              (voice) => voice.id === e,
            );

            if (selectedCustomFaas) {
              setCustomFaasPreset(selectedCustomFaas);
            } else {
              setCustomFaasPreset(undefined);
              setProcess({ ...process, import_custom_faas: null });
            }
          }}
        />
      </FormRow>

      {customFaasPreset && (
        <PresetsBorder>
          <CustomFaasFormReadOnly customFaas={customFaasPreset} />
          {edit && (
            <ButtonEmpty type="button" onClick={showCustomFaasModal}>
              {t('common.modify')}
            </ButtonEmpty>
          )}
        </PresetsBorder>
      )}

      <FormRow label={<H3 $bold>Report per call custom faas</H3>}>
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={reportCustomFaasesOptions}
          valueOfSelected={
            customFaasReportPerCall ? customFaasReportPerCall.id : 'empty'
          }
          onChange={(e) => {
            const selectedCustomFaas = apiCustomFaasesList.find(
              (voice) => voice.id === e,
            );

            if (selectedCustomFaas) {
              setCustomFaasReportPerCall(selectedCustomFaas);
            } else {
              setCustomFaasReportPerCall(undefined);
              setProcess({ ...process, import_custom_faas: null });
            }
          }}
        />
      </FormRow>

      {customFaasReportPerCall && (
        <PresetsBorder>
          <CustomFaasFormReadOnly customFaas={customFaasReportPerCall} />
        </PresetsBorder>
      )}

      <FormRow label={<H3 $bold>Report per record custom faas</H3>}>
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={reportCustomFaasesOptions}
          valueOfSelected={
            customFaasReportPerRecord ? customFaasReportPerRecord.id : 'empty'
          }
          onChange={(e) => {
            const selectedCustomFaas = apiCustomFaasesList.find(
              (voice) => voice.id === e,
            );
            if (selectedCustomFaas) {
              setCustomFaasReportPerRecord(selectedCustomFaas);
            } else {
              setCustomFaasReportPerRecord(undefined);
              setProcess({ ...process, import_custom_faas: null });
            }
          }}
        />
      </FormRow>

      {customFaasReportPerRecord && (
        <PresetsBorder>
          <CustomFaasFormReadOnly customFaas={customFaasReportPerRecord} />
        </PresetsBorder>
      )}

      <FormRow label={<H3 $bold>Report per campaign custom faas</H3>}>
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={reportCustomFaasesOptions}
          valueOfSelected={
            customFaasReportPerCampaign
              ? customFaasReportPerCampaign.id
              : 'empty'
          }
          onChange={(e) => {
            const selectedCustomFaas = apiCustomFaasesList.find(
              (voice) => voice.id === e,
            );
            if (selectedCustomFaas) {
              setCustomFaasReportPerCampaign(selectedCustomFaas);
            } else {
              setCustomFaasReportPerCampaign(undefined);
              setProcess({ ...process, import_custom_faas: null });
            }
          }}
        />
      </FormRow>

      {customFaasReportPerCampaign && (
        <PresetsBorder>
          <CustomFaasFormReadOnly customFaas={customFaasReportPerCampaign} />
        </PresetsBorder>
      )}

      <FormRow label={<H3 $bold>Get customer info faas</H3>}>
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={integrationCustomFaasesOptions}
          valueOfSelected={
            customFaasGetCustomerInfo ? customFaasGetCustomerInfo.id : 'empty'
          }
          onChange={(e) => {
            const selectedCustomFaas = apiCustomFaasesList.find(
              (voice) => voice.id === e,
            );
            if (selectedCustomFaas) {
              setCustomFaasGetCustomerInfo(selectedCustomFaas);
            } else {
              setCustomFaasGetCustomerInfo(undefined);
              setProcess({ ...process, import_custom_faas: null });
            }
          }}
        />
      </FormRow>

      {customFaasGetCustomerInfo && (
        <PresetsBorder>
          <CustomFaasFormReadOnly customFaas={customFaasGetCustomerInfo} />
        </PresetsBorder>
      )}

      <FormRow label={<H3 $bold>Report feedback faas</H3>}>
        <EuiSuperSelect
          disabled={!edit}
          placeholder={t('common.select')}
          options={integrationCustomFaasesOptions}
          valueOfSelected={
            customFaasReportFeedback ? customFaasReportFeedback.id : 'empty'
          }
          onChange={(e) => {
            const selectedCustomFaas = apiCustomFaasesList.find(
              (voice) => voice.id === e,
            );
            if (selectedCustomFaas) {
              setCustomFaasReportFeedback(selectedCustomFaas);
            } else {
              setCustomFaasReportFeedback(undefined);
              setProcess({ ...process, import_custom_faas: null });
            }
          }}
        />
      </FormRow>

      {customFaasReportFeedback && (
        <PresetsBorder>
          <CustomFaasFormReadOnly customFaas={customFaasReportFeedback} />
        </PresetsBorder>
      )}
    </FormContainer>
  );
};

export default DialoguePresetForm;

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiText,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { clearStorage } from '../BotsStartPanel';
import {
  getDialogueDetailedAsyncThunk,
  selectDialogueDetailed,
} from '../../presets/dialogues/api/dialoguesSlice';

const BotsEdit = () => {
  const navigate = useNavigate();
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getDialogueDetailedAsyncThunk(id));
    }
  }, []);

  const botConfig = useAppSelector(selectDialogueDetailed);

  //transfering BotConfig object into separated objects for each module
  useEffect(() => {
    if (
      botConfig &&
      botConfig?.generations_config &&
      botConfig.strategy_config &&
      botConfig.runtime_parameters &&
      id
    ) {
      try {
        //standard data for editing
        localStorage.setItem(
          'vinCompletedSteps',
          JSON.stringify(['1', '2', '3', '4', '5', '6']),
        );
        localStorage.setItem('lastVisitedStep', '1');

        localStorage.setItem('botConfigurationId', id);

        //if there is already a botName, that means the user is creating new bot with preset
        if (localStorage.getItem('botName') == null) {
          const botName = botConfig.name;
          localStorage.setItem('botName', botName);
        }
        //bot name

        //identification generations config
        const vinIdentificationGenerationsConfig = {
          'REQ-INF-may_i_speak_with_debtor_now-1-1':
            botConfig.generations_config[
              'REQ-INF-may_i_speak_with_debtor_now-1-1'
            ],
          'REQ-INF-can_you_hand_over-1-1':
            botConfig.generations_config['REQ-INF-can_you_hand_over-1-1'],
          'INF-STA-WAITING_A_MOMENT-1-1':
            botConfig.generations_config['INF-STA-WAITING_A_MOMENT-1-1'],
          'FEE-REF-odmowa_podania_danej/may_i_speak_with_debtor_now/-1-1':
            botConfig.generations_config[
              'FEE-REF-odmowa_podania_danej/may_i_speak_with_debtor_now/-1-1'
            ],
          'FEE-REF-rodo/may_i_speak_with_debtor_now/-1-1':
            botConfig.generations_config[
              'FEE-REF-rodo/may_i_speak_with_debtor_now/-1-1'
            ],
          'FEE-REF-podstawa_prawna/may_i_speak_with_debtor_now/-1-1':
            botConfig.generations_config[
              'FEE-REF-podstawa_prawna/may_i_speak_with_debtor_now/-1-1'
            ],
        };

        localStorage.setItem(
          'vinIdentificationGenerationsConfig',
          JSON.stringify(vinIdentificationGenerationsConfig),
        );
        //identification generations config
        const vinIdentificationBotStrategyConfig = {
          IMPORT_DEBTOR_STATUS_SKILL:
            botConfig.strategy_config.IMPORT_DEBTOR_STATUS_SKILL,
          IMPORT_NEGOTIATION_SKILL:
            botConfig.strategy_config.IMPORT_NEGOTIATION_SKILL,
          BRANCHES: {
            IDENTIFICATION: botConfig.strategy_config.BRANCHES.IDENTIFICATION,
          },
        };

        localStorage.setItem(
          'vinIdentificationBotStrategyConfig',
          JSON.stringify(vinIdentificationBotStrategyConfig),
        );

        //verification generations config
        const vinVerificationGenerationsConfig = {
          'INF-STA-verification_intro_1_1':
            botConfig.generations_config['INF-STA-verification_intro_1_1'],
          'FEE-SUM-verification_passed-1-1':
            botConfig.generations_config['FEE-SUM-verification_passed-1-1'],
          'INF-STA-verification_failed-1-1':
            botConfig.generations_config['INF-STA-verification_failed-1-1'],
          'REQ-INF-birth_date-1-1':
            botConfig.generations_config['REQ-INF-birth_date-1-1'],
          'REQ-INF-last_pesel_digits-1-1':
            botConfig.generations_config['REQ-INF-last_pesel_digits-1-1'],
          'REQ-INF-id-1-1': botConfig.generations_config['REQ-INF-id-1-1'],
          'REQ-INF-mothers_name-1-1':
            botConfig.generations_config['REQ-INF-mothers_name-1-1'],
          'REQ-INF-fathers_name-1-1':
            botConfig.generations_config['REQ-INF-fathers_name-1-1'],
          'REQ-INF-zip_code-1-1':
            botConfig.generations_config['REQ-INF-zip_code-1-1'],
          'REQ-INF-product_possession-1-1':
            botConfig.generations_config['REQ-INF-product_possession-1-1'],
          'REQ-INF-address-1-1':
            botConfig.generations_config['REQ-INF-address-1-1'],
        };
        localStorage.setItem(
          'vinVerificationGenerationsConfig',
          JSON.stringify(vinVerificationGenerationsConfig),
        );

        //verification bot strategy config
        const vinVerificationBotStrategyConfig = {
          FIRST_TURN_ATTEMPTS: botConfig.strategy_config.FIRST_TURN_ATTEMPTS,
          FIRST_TURN_RANDOM: botConfig.strategy_config.FIRST_TURN_RANDOM,
          FIRST_TURN_QUESTIONS: botConfig.strategy_config.FIRST_TURN_QUESTIONS,
          SECOND_TURN: botConfig.strategy_config.SECOND_TURN,
          SECOND_TURN_QUESTIONS:
            botConfig.strategy_config.SECOND_TURN_QUESTIONS,
          SECOND_TURN_ATTEMPTS: botConfig.strategy_config.SECOND_TURN_ATTEMPTS,
          SECOND_TURN_RANDOM: botConfig.strategy_config.SECOND_TURN_RANDOM,
          BRANCHES: {
            VERIFICATION: botConfig.strategy_config.BRANCHES.VERIFICATION,
          },
          ASK_FOR_MISSING_ZIP_CODE:
            botConfig.strategy_config.ASK_FOR_MISSING_ZIP_CODE,
        };
        localStorage.setItem(
          'vinVerificationBotStrategyConfig',
          JSON.stringify(vinVerificationBotStrategyConfig),
        );

        //payment status generations config
        const vinPaymentStatusGenerationsConfig = {
          'INF-STA-debt_intro-1-1':
            botConfig.generations_config['INF-STA-debt_intro-1-1'],
          'REQ-INF-is_payment_done-1-1':
            botConfig.generations_config['REQ-INF-is_payment_done-1-1'],
          'INF-STA-waiting_for_transfer-1-1':
            botConfig.generations_config['INF-STA-waiting_for_transfer-1-1'],
          'FEE-UNC-REQ-INF-past_payment_date-1-1':
            botConfig.generations_config[
              'FEE-UNC-REQ-INF-past_payment_date-1-1'
            ],
          'INF-STA-negotiation_intro_partially_paid-1-1':
            botConfig.generations_config[
              'INF-STA-negotiation_intro_partially_paid-1-1'
            ],
          'REQ-INF-past_payment_amount-1-1':
            botConfig.generations_config['REQ-INF-past_payment_amount-1-1'],
          'INF-STA-payment_not_found-1-1':
            botConfig.generations_config['INF-STA-payment_not_found-1-1'],
        };
        localStorage.setItem(
          'vinPaymentStatusGenerationsConfig',
          JSON.stringify(vinPaymentStatusGenerationsConfig),
        );

        //payment status bot strategy config
        const vinPaymentStatusBotStrategyConfig = {
          DEFAULT_CURRENCY: botConfig.strategy_config.DEFAULT_CURRENCY,
          MIN_ACCEPTABLE_PAST_PAYMENT_AMOUNT: {
            percent:
              botConfig.strategy_config.MIN_ACCEPTABLE_PAST_PAYMENT_AMOUNT,
          },
          PAST_PAYMENT_DATE_QUESTION:
            botConfig.strategy_config.PAST_PAYMENT_DATE_QUESTION,
          ACCEPTABLE_DAYS_SINCE_PAYMENT:
            botConfig.strategy_config.ACCEPTABLE_DAYS_SINCE_PAYMENT,
          PAST_PAYMENT_AMOUNT_QUESTION:
            botConfig.strategy_config.PAST_PAYMENT_AMOUNT_QUESTION,
          BRANCHES: {
            DEBTOR_STATUS: {
              success: botConfig.strategy_config.BRANCHES.DEBTOR_STATUS.success,
              not_acceptable_date:
                botConfig.strategy_config.BRANCHES.DEBTOR_STATUS
                  .not_acceptable_date,
            },
          },
          SUPPORTED_CURRENCIES: botConfig.strategy_config.SUPPORTED_CURRENCIES,
        };
        localStorage.setItem(
          'vinPaymentStatusBotStrategyConfig',
          JSON.stringify(vinPaymentStatusBotStrategyConfig),
        );

        //negotiations generations config
        const vinNegotiationsGenerationsConfig = {
          'REQ-INF-will_pay_deadline_date_min-int-1-1':
            botConfig.generations_config[
              'REQ-INF-will_pay_deadline_date_min-int-1-1'
            ],
          'REQ-INF-future_payment-date-1-1':
            botConfig.generations_config['REQ-INF-future_payment-date-1-1'],
          'REQ-INF-will_pay_deadline_date_max-1-1':
            botConfig.generations_config[
              'REQ-INF-will_pay_deadline_date_max-1-1'
            ],
          'REQ-INF-accept_arrangements-has_info_item-1-1':
            botConfig.generations_config[
              'REQ-INF-accept_arrangements-has_info_item-1-1'
            ],
          'REQ-INF-accept_arrangements-date_was_moved-1-1':
            botConfig.generations_config[
              'REQ-INF-accept_arrangements-date_was_moved-1-1'
            ],
          'INF-STA-negotiation_success-no_captured_date-1-1':
            botConfig.generations_config[
              'INF-STA-negotiation_success-no_captured_date-1-1'
            ],
          'INF-STA-negotiation_success-has_info_item-1-1':
            botConfig.generations_config[
              'INF-STA-negotiation_success-has_info_item-1-1'
            ],
          'INF-STA-unacceptable_date-1-1':
            botConfig.generations_config['INF-STA-unacceptable_date-1-1'],
        };
        localStorage.setItem(
          'vinNegotiationsGenerationsConfig',
          JSON.stringify(vinNegotiationsGenerationsConfig),
        );

        //negotiations bot strategy config
        const vinNegotiationsBotStrategyConfig = {
          IMPORT_NEGOTIATION_SKILL:
            botConfig.strategy_config.IMPORT_NEGOTIATION_SKILL,
          PAYMENT_DEADLINE_DAYS_MIN_QUESTION:
            botConfig.strategy_config.PAYMENT_DEADLINE_DAYS_MIN_QUESTION,
          FUTURE_PAYMENT_DATE_QUESTION:
            botConfig.strategy_config.FUTURE_PAYMENT_DATE_QUESTION,
          PAYMENT_DEADLINE_DAYS_MAX_QUESTION:
            botConfig.strategy_config.PAYMENT_DEADLINE_DAYS_MAX_QUESTION,
          ACCEPT_ARRANGEMENTS_QUESTION:
            botConfig.strategy_config.ACCEPT_ARRANGEMENTS_QUESTION,
          BRANCHES: {
            NEGOTIATION: {
              success: botConfig.strategy_config.BRANCHES.NEGOTIATION.success,
              fail: botConfig.strategy_config.BRANCHES.NEGOTIATION.fail,
            },
          },
        };
        localStorage.setItem(
          'vinNegotiationsBotStrategyConfig',
          JSON.stringify(vinNegotiationsBotStrategyConfig),
        );

        // negotiations runtime parameters
        const vinNegotiationsRuntimeParameters = {
          PaymentDeadlineDays: botConfig.runtime_parameters.PaymentDeadlineDays,
          PaymentDeadlineDaysMax:
            botConfig.runtime_parameters.PaymentDeadlineDaysMax,
        };
        localStorage.setItem(
          'vinNegotiationsRuntimeParameters',
          JSON.stringify(vinNegotiationsRuntimeParameters),
        );

        //aux modules generations config
        const vinAuxModulesGenerationsConfig = {
          'REQ-INF-may_we_continue-1-1':
            botConfig.generations_config['REQ-INF-may_we_continue-1-1'],

          'INF-STA-consultant_unavailable-1-1':
            botConfig.generations_config['INF-STA-consultant_unavailable-1-1'],

          'INF-STA-refusal_to_be_recordered-continue':
            botConfig.generations_config[
              'INF-STA-refusal_to_be_recordered-continue'
            ],

          'INF-STA-refusal_to_be_recorded-1-1':
            botConfig.generations_config['INF-STA-refusal_to_be_recorded-1-1'],

          'INF-STA-refusal_to_pay-1-1':
            botConfig.generations_config['INF-STA-refusal_to_pay-1-1'],

          'INF-STA-refusal_to_pay-not_verified-1-1':
            botConfig.generations_config[
              'INF-STA-refusal_to_pay-not_verified-1-1'
            ],

          'INF-STA-refusal_to_pay-not_identified-1-1':
            botConfig.generations_config[
              'INF-STA-refusal_to_pay-not_identified-1-1'
            ],

          'SOC-FIN-1-1': botConfig.generations_config['SOC-FIN-1-1'],

          'INF-STA-CALLBACK-TIME-1-1':
            botConfig.generations_config['INF-STA-CALLBACK-TIME-1-1'],

          'REQ-INF-callback_number-1-1':
            botConfig.generations_config['REQ-INF-callback_number-1-1'],

          'INF-STA-no_number-1-1':
            botConfig.generations_config['INF-STA-no_number-1-1'],

          'FEE-POS-new_number-1-1':
            botConfig.generations_config['FEE-POS-new_number-1-1'],
        };
        localStorage.setItem(
          'vinAuxModulesGenerationsConfig',
          JSON.stringify(vinAuxModulesGenerationsConfig),
        );

        //aux modules bot strategy config
        const vinAuxModulesBotStrategyConfig = {
          IMPORT_REDIRECT_TO_HUMAN_FOX_DEN_SKILL:
            botConfig.strategy_config.IMPORT_REDIRECT_TO_HUMAN_FOX_DEN_SKILL,
          BRANCHES: {
            REDIRECT_TO_HUMAN: {
              fail: botConfig.strategy_config.BRANCHES.REDIRECT_TO_HUMAN,
            },
            REFUSAL: botConfig.strategy_config.BRANCHES.REFUSAL,
            INFORM_PHONENUMBER: {
              repeat:
                botConfig.strategy_config.BRANCHES.INFORM_PHONENUMBER.repeat,
              sms: botConfig.strategy_config.BRANCHES.INFORM_PHONENUMBER.sms,
            },
            no_debt: {
              verified: botConfig.strategy_config.BRANCHES.no_debt.verified,
              not_verified:
                botConfig.strategy_config.BRANCHES.no_debt.not_verified,
            },
            is_dead: botConfig.strategy_config.BRANCHES.is_dead,
            wrong_number: botConfig.strategy_config.BRANCHES.wrong_number,
            CALLBACK_NUMBER: {
              no_number:
                botConfig.strategy_config.BRANCHES.CALLBACK_NUMBER.no_number,
              new_number:
                botConfig.strategy_config.BRANCHES.CALLBACK_NUMBER.new_number,
            },
          },
          ASK_QUESTION_MAY_WE_CONTINUE:
            botConfig.strategy_config.ASK_QUESTION_MAY_WE_CONTINUE,
          REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE:
            botConfig.strategy_config.REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE,
          HARD_REFUSAL_TO_PAY: botConfig.strategy_config.HARD_REFUSAL_TO_PAY,
          ASK_ABOUT_REPEAT_OR_SMS:
            botConfig.strategy_config.ASK_ABOUT_REPEAT_OR_SMS,
          IMPORT_INFORM_PHONENUMBER_SKILL:
            botConfig.strategy_config.IMPORT_INFORM_PHONENUMBER_SKILL,
          IMPORT_CALLBACK_DATE_TIME_SKILL:
            botConfig.strategy_config.IMPORT_CALLBACK_DATE_TIME_SKILL,
          IMPORT_CALLBACK_NUMBER_SKILL:
            botConfig.strategy_config.IMPORT_CALLBACK_NUMBER_SKILL,
        };
        localStorage.setItem(
          'vinAuxModulesBotStrategyConfig',
          JSON.stringify(vinAuxModulesBotStrategyConfig),
        );
        setTimeout(() => {
          navigate(`/dashboard/bots/new/1/1`);
        }, 1000);
      } catch (error) {
        setTimeout(() => {
          setLoadingError(true);
        }, 2000);
        clearStorage();
      }
    }
  }, [botConfig]);

  return (
    <>
      {botConfig && !loadingError ? (
        <EuiFlexGroup css={{ flexDirection: 'column' }}>
          <EuiFlexItem
            css={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            <EuiText css={{ fontSize: 35, fontWeight: 'bold' }}>
              Loading
            </EuiText>
            <EuiLoadingSpinner size="xl" />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiFlexGroup>
          <EuiFlexItem css={{ gap: 10 }}>
            <EuiText>Error while loading Bot Configuration</EuiText>
            <div>
              <EuiButton onClick={() => navigate('/dashboard/bots')}>
                Redirect to bots panel
              </EuiButton>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  );
};

export default BotsEdit;

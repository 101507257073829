import React, { useEffect, useState } from 'react';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../../../generated/tenants/Api';
import {
  GroupedData,
  convert2calendar,
  groupByWorkingHours,
} from '../calendarFunctions';
import {
  WorkingHoursBorder,
  WorkingHoursGroupsContainer,
  WorkingHoursNotDefinedContainer,
  WorkingHoursPreviewTitle,
} from '../WorkingHours.style';
import { H3 } from '../../../../../App.style';
import WorkingHoursPreviewGroups from './WorkingHoursPreviewGroups';
import { useTranslation } from 'react-i18next';

interface WorkingHoursPreviewProps {
  calendarConfiguration: CalendarConfigurationRequest | CalendarConfiguration;
}

const WorkingHoursPreview: React.FC<WorkingHoursPreviewProps> = ({
  calendarConfiguration,
}) => {
  const { t } = useTranslation();
  const [groupedOutboundWorkingHours, setGroupedOutboundWorkingHours] =
    useState<GroupedData[]>();

  const [groupedInboundWorkingHours, setGroupedInboundWorkingHours] =
    useState<GroupedData[]>();

  const [groupedCcWorkingHours, setGroupedCcWorkingHours] =
    useState<GroupedData[]>();

  useEffect(() => {
    loadCalendarData();
  }, [calendarConfiguration]);

  //converts working hours into calendars
  const loadCalendarData = () => {
    if (calendarConfiguration) {
      if (calendarConfiguration.working_hours) {
        const week = convert2calendar(calendarConfiguration.working_hours);
        const data = groupByWorkingHours(week);
        setGroupedOutboundWorkingHours(data);
      }
      if (calendarConfiguration.cc_working_hours) {
        const week = convert2calendar(calendarConfiguration.cc_working_hours);
        const data = groupByWorkingHours(week);
        setGroupedCcWorkingHours(data);
      }
      if (calendarConfiguration.inbound_working_hours) {
        const week = convert2calendar(
          calendarConfiguration.inbound_working_hours,
        );

        const data = groupByWorkingHours(week);
        setGroupedInboundWorkingHours(data);
      }
    }
  };

  function areWorkingHoursNotEmpty(groupedDataArray: GroupedData[]): boolean {
    return !groupedDataArray.every((group) => group.workingHours.length === 0);
  }

  return (
    <WorkingHoursGroupsContainer>
      {groupedOutboundWorkingHours && (
        <WorkingHoursBorder>
          <WorkingHoursPreviewTitle>
            <H3>{t('presets.calendarForm.outbound')}</H3>
          </WorkingHoursPreviewTitle>

          {!areWorkingHoursNotEmpty(groupedOutboundWorkingHours) ? (
            <WorkingHoursNotDefinedContainer>
              <H3>{t('presets.calendarForm.notDefined')}</H3>
            </WorkingHoursNotDefinedContainer>
          ) : (
            <WorkingHoursPreviewGroups
              workingHoursData={groupedOutboundWorkingHours}
            />
          )}
        </WorkingHoursBorder>
      )}

      {groupedInboundWorkingHours && (
        <WorkingHoursBorder>
          <WorkingHoursPreviewTitle>
            <H3>{t('presets.calendarForm.inbound')}</H3>
          </WorkingHoursPreviewTitle>

          {!areWorkingHoursNotEmpty(groupedInboundWorkingHours) ? (
            <WorkingHoursNotDefinedContainer>
              <H3>{t('presets.calendarForm.notDefined')}</H3>
            </WorkingHoursNotDefinedContainer>
          ) : (
            <WorkingHoursPreviewGroups
              workingHoursData={groupedInboundWorkingHours}
            />
          )}
        </WorkingHoursBorder>
      )}

      {groupedCcWorkingHours && (
        <WorkingHoursBorder>
          <WorkingHoursPreviewTitle>
            <H3>{t('presets.calendarForm.cc')}</H3>
          </WorkingHoursPreviewTitle>

          {!areWorkingHoursNotEmpty(groupedCcWorkingHours) ? (
            <WorkingHoursNotDefinedContainer>
              <H3>{t('presets.calendarForm.notDefined')}</H3>
            </WorkingHoursNotDefinedContainer>
          ) : (
            <WorkingHoursPreviewGroups
              workingHoursData={groupedCcWorkingHours}
            />
          )}
        </WorkingHoursBorder>
      )}
    </WorkingHoursGroupsContainer>
  );
};

export default WorkingHoursPreview;

import styled from 'styled-components';
import { H6 } from '../../../App.style';

//detailed

export const UserDetailedContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;

  position: relative;

  background: #ffffff;
`;

export const UserDetailedBarContainer = styled.div`
  margin-top: 20px;
  display: flex;

  align-items: center;
  padding: 15px 30px;
`;

export const UserDetailedBarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 30px;
`;

export const UserNameContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const ChangeAvatarLabel = styled.label`
  cursor: pointer;

  ${H6} {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const UserDetailedContentContainer = styled.div<{ profile?: boolean }>`
  margin-top: 40px;
  padding-left: ${(props) => (props.profile ? '15px' : '170px')};
  display: flex;
  height: calc(100% - 200px);
  max-width: 1200px;
`;

export const UserStrategiesTableContainer = styled.div`
  width: 100%;
  padding: 0px 10px;
  margin-right: 40px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const UserStrategiesTab = styled.table`
  margin-top: 8px;
  width: 100%;
  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background: white;
  }
`;

export const AdminViewContainer = styled.div<{ edit: boolean }>`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: ${(props) => (props.edit ? 'center' : 'flex-end')};
  align-items: center;
`;

export const UserDetailedRowButtonsContainer = styled.div`
  margin-left: 30px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

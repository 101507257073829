import React, { useEffect } from 'react';
import { TenantFormContainer } from '../Tenants.style';
import { EuiSuperSelect } from '@elastic/eui';
import { H3 } from '../../../../../../App.style';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  getSmsConfigurationListAsyncThunk,
  selectSmsConfigurationsList,
} from '../../../api/superAdminSlice';
import { CompanyCreateRequest } from '../../../../../../../generated/public/Api';

interface TenantSmsFormProps {
  tenant: CompanyCreateRequest;
  setTenant: React.Dispatch<React.SetStateAction<CompanyCreateRequest>>;
  detailed?: boolean;
}

const TenantSmsForm: React.FC<TenantSmsFormProps> = ({
  tenant,
  setTenant,
  detailed,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSmsConfigurationListAsyncThunk());
  }, []);
  const apiSmsConfigList = useAppSelector(selectSmsConfigurationsList);

  useEffect(() => {
    if (tenant.sms_gateway === undefined && apiSmsConfigList.length > 0) {
      setTenant({ ...tenant, sms_gateway: apiSmsConfigList[0].id });
    }
  }, [apiSmsConfigList]);

  const smsConfigOptions = [
    ...apiSmsConfigList.map((option) => ({
      value: option.id,
      inputDisplay: option.header,
    })),
  ];

  return (
    <TenantFormContainer>
      <FormRow label={<H3>Operator</H3>}>
        <EuiSuperSelect
          valueOfSelected={'smsapi'}
          options={[{ value: 'smsapi', inputDisplay: 'SMSAPI' }]}
          readOnly
        />
      </FormRow>

      <FormRow label={<H3>Nagłówek (nadawca)</H3>}>
        <EuiSuperSelect
          valueOfSelected={tenant.sms_gateway ?? ''}
          options={smsConfigOptions}
          onChange={(e) => {
            setTenant({ ...tenant, sms_gateway: e });
          }}
          readOnly={detailed}
        />
      </FormRow>
    </TenantFormContainer>
  );
};

export default TenantSmsForm;

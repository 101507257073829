import { EuiToolTip, copyToClipboard } from '@elastic/eui';
import React, { useState } from 'react';
import {
  PaginatedRecordDataList,
  RecordData,
  RecordDataStatusEnum,
} from '../../../../../generated/tenants/Api';
import {
  TableContainer,
  RecordsTab,
  RecordsTableHeader,
  RecordsTableData,
  RecordsTableRow,
  RecordsStatus,
  PaginationContainer,
  ArrowsContainer,
  PaginationText,
} from './Records.style';
import {
  COLOR_NEUTRAL_80,
  CopyIdButton,
  H4,
  HoverUnderline,
} from '../../../../App.style';
import moment from 'moment';
import { ArrowIconLeft } from '../../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../../resources/icons-new/ArrowIconRight';
import RecordsDetailedModal from './RecordsDetailedModal';
import { ArrowRightIcon } from '../../../../../resources/icons-new/ArrowRightIcon';
import { CopyIcon } from '../../../../../resources/icons-new/CopyIcon';
import { ArrowUpIcon } from '../../../../../resources/icons-new/ArrowUpIcon';
import { ArrowDownIcon } from '../../../../../resources/icons-new/ArrowDownIcon';
import { useTranslation } from 'react-i18next';

interface RecordsTableProps {
  campaignId: string;
  recordsList: PaginatedRecordDataList | undefined;
  selectedRecords: string[];
  setSelectedRecords: React.Dispatch<React.SetStateAction<string[]>>;
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  ordering: '-created_at' | 'created_at';
  setOrdering: React.Dispatch<
    React.SetStateAction<'-created_at' | 'created_at'>
  >;
  handleCallsHistoryClick: (number: string) => void;
  hasCampaignAccess: boolean;
}

const RecordsTable: React.FC<RecordsTableProps> = ({
  recordsList,
  selectedRecords,
  setSelectedRecords,
  currentPage,
  itemsPerPage,
  handlePageChange,
  ordering,
  setOrdering,
  handleCallsHistoryClick,
  hasCampaignAccess,
}) => {
  const { t } = useTranslation();
  const totalItems = recordsList?.count as number;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  //selected phone number for modal
  const [selectedRecord, setSelectedRecord] = useState<RecordData>();

  const [isPhoneModalVisible, setIsPhoneModalVisible] =
    useState<boolean>(false);

  //selected phone numbers for modal

  const toggleRowSelection = (id: string) => {
    setSelectedRecords((prevState) =>
      prevState.includes(id)
        ? prevState.filter((rowId) => rowId !== id)
        : [...prevState, id],
    );
  };

  const toggleSelectAll = () => {
    if (recordsList?.results) {
      if (selectedRecords.length === recordsList?.results.length) {
        setSelectedRecords([]);
      } else {
        setSelectedRecords(recordsList?.results.map((row) => row.id));
      }
    }
  };

  const [recordIdCopied, setRecordIdCopied] = useState<boolean>(false);

  const handleCopyRecordId = (id: string) => {
    setRecordIdCopied(true);
    copyToClipboard(id);
  };

  const handleOrderingChange = () => {
    setOrdering((prev) =>
      prev === 'created_at' ? '-created_at' : 'created_at',
    );
  };

  const renderRecordStatus = (status: RecordDataStatusEnum) => {
    switch (status) {
      case 'OPEN':
        return t('campaigns.records.table.open');
      case 'FINISHED':
        return t('campaigns.records.table.finished');
      case 'CANCELLED':
        return t('campaigns.records.table.cancelled');
      case 'EXHAUSTED':
        return t('campaigns.records.table.exhausted');
    }
  };

  return (
    <TableContainer>
      {recordsList?.results && selectedRecord && hasCampaignAccess && (
        <RecordsDetailedModal
          isVisible={isPhoneModalVisible}
          recordsList={recordsList.results}
          selectedRecord={selectedRecord}
          setIsPhoneModalVisible={setIsPhoneModalVisible}
        />
      )}

      {recordsList?.results && (
        <RecordsTab>
          <thead>
            <tr>
              <RecordsTableHeader select>
                <input
                  type="checkbox"
                  onChange={toggleSelectAll}
                  checked={
                    selectedRecords.length > 0 &&
                    selectedRecords.length === recordsList?.results.length
                  }
                />
              </RecordsTableHeader>
              <RecordsTableHeader>
                <H4 $grey>{t('campaigns.records.table.telephone')}</H4>
              </RecordsTableHeader>
              <RecordsTableHeader>
                <H4 $grey>{t('campaigns.records.table.recordId')}</H4>
              </RecordsTableHeader>
              <RecordsTableHeader>
                <div
                  onClick={handleOrderingChange}
                  style={{ display: 'flex', gap: 5, alignItems: 'center' }}
                >
                  <H4 $grey>{t('campaigns.records.table.importedDate')}</H4>
                  {ordering === 'created_at' ? (
                    <ArrowUpIcon color={COLOR_NEUTRAL_80} />
                  ) : (
                    <ArrowDownIcon color={COLOR_NEUTRAL_80} />
                  )}
                </div>
              </RecordsTableHeader>
              <RecordsTableHeader>
                <H4 $grey>{t('campaigns.records.table.source')}</H4>
              </RecordsTableHeader>
              <RecordsTableHeader>
                <H4 $grey>{t('campaigns.records.table.recordStatus')}</H4>
              </RecordsTableHeader>
              <RecordsTableHeader pagination>
                <PaginationContainer>
                  <PaginationText>
                    {getPaginationTextContent(
                      currentPage,
                      itemsPerPage,
                      totalItems,
                    )}
                  </PaginationText>

                  <ArrowsContainer>
                    <button
                      disabled={currentPage === 1}
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                    >
                      <ArrowIconLeft disabled={currentPage === 1} />
                    </button>

                    <button
                      disabled={currentPage === totalPages}
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                    >
                      <ArrowIconRight disabled={currentPage === totalPages} />
                    </button>
                  </ArrowsContainer>
                </PaginationContainer>
              </RecordsTableHeader>
            </tr>
          </thead>
          <tbody>
            {recordsList.results.length === 0 ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                {t('campaigns.records.table.noRecords')}
              </div>
            ) : (
              recordsList?.results.map((record) => {
                const formattedImportedDate = moment(record.created_at).format(
                  'DD.MM.YYYY, HH:mm',
                );

                const isSelected = selectedRecords.includes(record.id);

                const isCancelled = record.status === 'CANCELLED';

                return (
                  <RecordsTableRow
                    key={record.id}
                    isCanceled={isCancelled}
                    onClick={() => {
                      if (hasCampaignAccess) {
                        setSelectedRecord(record);
                        setIsPhoneModalVisible(true);
                      }
                    }}
                  >
                    <RecordsTableData select>
                      <input
                        disabled={record.status === 'CANCELLED'}
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleRowSelection(record.id);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </RecordsTableData>
                    <RecordsTableData>
                      <H4 $bold={!isCancelled} $grey={isCancelled}>
                        {record.phone_numbers.join(' ')}
                      </H4>
                    </RecordsTableData>
                    <RecordsTableData>
                      <EuiToolTip
                        position="top"
                        content={
                          <p style={{ textAlign: 'center' }}>
                            {recordIdCopied
                              ? t('campaigns.records.table.idCopied')
                              : record.id}
                          </p>
                        }
                      >
                        <CopyIdButton
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                          ) => {
                            e.stopPropagation();
                            handleCopyRecordId(record.id);
                          }}
                          onMouseLeave={() => setRecordIdCopied(false)}
                        >
                          <CopyIcon />
                          <H4>{t('campaigns.records.table.copyID')}</H4>
                        </CopyIdButton>
                      </EuiToolTip>
                    </RecordsTableData>
                    <RecordsTableData>
                      <H4 $grey={isCancelled}>{formattedImportedDate}</H4>
                    </RecordsTableData>

                    <RecordsTableData>
                      <H4 $grey={isCancelled}>
                        {record.imported_filename
                          ? record.imported_filename
                          : '---'}
                      </H4>
                    </RecordsTableData>
                    <RecordsTableData>
                      <RecordsStatus status={record.status}>
                        {renderRecordStatus(record.status)}
                      </RecordsStatus>
                    </RecordsTableData>
                    <RecordsTableData pagination>
                      <div
                        style={{
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                        }}
                        onClick={() => handleCallsHistoryClick(record.id)}
                      >
                        <H4 $grey={isCancelled}>
                          <HoverUnderline>
                            {t('campaigns.records.table.callsHistory')}
                            {`(${record.calls_count})`}
                          </HoverUnderline>
                        </H4>{' '}
                        <ArrowRightIcon />
                      </div>
                    </RecordsTableData>
                  </RecordsTableRow>
                );
              })
            )}
          </tbody>
        </RecordsTab>
      )}
    </TableContainer>
  );
};

export default RecordsTable;

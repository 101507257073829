import { EuiSkeletonRectangle } from '@elastic/eui';
import React from 'react';
import { styled } from 'styled-components';

const ProcessLoadingContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 16px;
`;

const ProcessListLoading = () => {
  return (
    <div style={{ display: 'flex' }}>
      {Array.from({ length: 3 }).map((i, j) => {
        return (
          <ProcessLoadingContainer key={`loading-${j}`}>
            <EuiSkeletonRectangle
              isLoading={true}
              contentAriaLabel="loading_title"
              width={200}
              height={30}
              borderRadius="m"
            />
            {Array.from({ length: 3 }).map((i, j) => {
              return (
                <EuiSkeletonRectangle
                  key={`loading-2-${j}`}
                  isLoading={true}
                  contentAriaLabel="loading_box_2"
                  width={260}
                  height={240}
                  borderRadius="m"
                />
              );
            })}
          </ProcessLoadingContainer>
        );
      })}
    </div>
  );
};

export default ProcessListLoading;

import styled from 'styled-components';

export const DialogueFinalSettingsContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: white;
  max-width: 750px;
`;

export const DialogueFinalSettingsContentRow = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const DialogueFinalSettingsRowTitle = styled.div`
  width: 200px;
`;

export const DialogueFinalSettingsRowContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

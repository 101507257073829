import React from 'react';
import { EuiCheckbox, EuiPopover } from '@elastic/eui';
import { Button, H4, H5 } from '../../../../../App.style';
import { RecordsFiltersValues } from './RecordsFilters';
import {
  FilterButton,
  FilterBarButton,
  FiltersTopContainer,
  FiltersContentContainer,
  FiltersBottomContainer,
  FiltersContainer,
  FiltersOptionContainer,
} from '../../../panel/bar/filters/CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { useTranslation } from 'react-i18next';

interface RecordsStatusesFilterProps {
  barButton: boolean;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedStatuses: string[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>;
  setFilters: (value: React.SetStateAction<RecordsFiltersValues>) => void;
}

const RecordsStatusesFilter: React.FC<RecordsStatusesFilterProps> = ({
  barButton,
  isOpen,
  setIsOpen,
  selectedStatuses,
  setSelectedStatuses,
  setFilters,
}) => {
  const { t } = useTranslation();
  const statuses = ['CANCELLED', 'EXHAUSTED', 'FINISHED', 'OPEN'];
  const statusesOptions = statuses.map((status) => {
    return { value: status, text: status.toLowerCase() };
  });

  const renderRecordStatus = (status: string) => {
    switch (status.toUpperCase()) {
      case 'OPEN':
        return t('campaigns.records.table.open');
      case 'FINISHED':
        return t('campaigns.records.table.finished');
      case 'CANCELLED':
        return t('campaigns.records.table.cancelled');
      case 'EXHAUSTED':
        return t('campaigns.records.table.exhausted');
    }
  };

  const handleClearSource = () => {
    setSelectedStatuses([]);
    setFilters((prev) => ({ ...prev, statuses: undefined }));
    setIsOpen(false);
  };

  const handleApplySource = () => {
    setFilters((prev) => ({
      ...prev,
      statuses: selectedStatuses as (
        | 'OPEN'
        | 'FINISHED'
        | 'CANCELLED'
        | 'EXHAUSTED'
      )[],
    }));
    setIsOpen(false);
  };

  const handleStatusChange = (option: string) => {
    if (selectedStatuses.includes(option)) {
      setSelectedStatuses(selectedStatuses.filter((value) => value !== option));
    } else {
      setSelectedStatuses([...selectedStatuses, option]);
    }
  };

  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            {t('campaigns.records.filters.status')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <H4 $bold>{t('campaigns.records.filters.statusIs')}</H4>{' '}
            <H4>
              {selectedStatuses
                .map((status) => renderRecordStatus(status))
                .join(', ')
                .toLowerCase()}
            </H4>
            <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <FiltersContainer>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.records.filters.statusIs')}</H4>
          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleClearSource}
            >
              {t('campaigns.records.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer>
          {statusesOptions.map((option, index) => (
            <FiltersOptionContainer
              key={`process-filter-${index}`}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                handleStatusChange(option.value);
              }}
            >
              <EuiCheckbox
                id={option.value}
                key={index}
                label={''}
                checked={selectedStatuses.includes(option.value)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleStatusChange(option.value);
                }}
              />
              <H4 title={option.text}>{renderRecordStatus(option.text)}</H4>
            </FiltersOptionContainer>
          ))}
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button onClick={handleApplySource} $size="S" color="white">
            {t('campaigns.records.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </FiltersContainer>
    </EuiPopover>
  );
};

export default RecordsStatusesFilter;

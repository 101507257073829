import { CallCenterNonWorkingDaysConfigurationRequest } from './../../../../../../generated/tenants/Api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CheckCallCenterDaysOffResult,
  createCallCenterDaysOff,
  deleteCallCenterDaysOff,
  getCallCenterDaysOffDetailed,
  getCallCenterDaysOffList,
  updateCallCenterDaysOff,
} from './callCenterDaysOffApi';
import { CallCenterNonWorkingDaysConfiguration } from '../../../../../../generated/tenants/Api';
import { RootState } from '../../../../../store';

export interface CallCenterDaysOffState {
  list: {
    state: 'pending' | 'idle';
    value: CallCenterNonWorkingDaysConfiguration[];
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckCallCenterDaysOffResult;
  };
  detailed: {
    state: 'pending' | 'idle';
    value?: CallCenterNonWorkingDaysConfiguration;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckCallCenterDaysOffResult;
  };
  delete: {
    state: 'pending' | 'idle';
    value?: CheckCallCenterDaysOffResult;
  };
}

const initialState: CallCenterDaysOffState = {
  list: {
    state: 'pending',
    value: [],
  },
  create: { state: 'pending' },
  update: { state: 'pending' },
  delete: { state: 'pending' },
  detailed: { state: 'pending' },
};

export const getCallCenterDaysOffListAsyncThunk = createAsyncThunk(
  'callCentreDaysOff/list',
  (id: string) => getCallCenterDaysOffList(id),
);

export const createCallCenterDaysOffAsyncThunk = createAsyncThunk(
  'callCentreDaysOff/create',
  (data: CallCenterNonWorkingDaysConfigurationRequest) =>
    createCallCenterDaysOff(data),
);

export const getCallCenterDaysOffDetailedAsyncThunk = createAsyncThunk(
  'callCentreDaysOff/detailed',
  (id: string) => getCallCenterDaysOffDetailed(id),
);

export const updateCallCenterDaysOffAsyncThunk = createAsyncThunk(
  'callCentreDaysOff/update',
  (args: { id: string; data: CallCenterNonWorkingDaysConfigurationRequest }) =>
    updateCallCenterDaysOff(args.id, args.data),
);

export const deleteCallCenterDaysOffAsyncThunk = createAsyncThunk(
  'callCentreDaysOff/delete',
  (id: string) => deleteCallCenterDaysOff(id),
);

export const callCenterDaysOffSlice = createSlice({
  name: 'callCenterDaysOff',
  initialState,
  reducers: {
    clearCreateCallCeterDaysOff: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateCallCenterDaysOff: (state) => {
      state.update = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder

      //list
      .addCase(getCallCenterDaysOffListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(
        getCallCenterDaysOffListAsyncThunk.fulfilled,
        (state, action) => {
          state.list.state = 'idle';
          state.list.value = action.payload;
        },
      )
      .addCase(getCallCenterDaysOffListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //detailed
      .addCase(getCallCenterDaysOffDetailedAsyncThunk.pending, (state) => {
        state.detailed.state = 'pending';
      })
      .addCase(
        getCallCenterDaysOffDetailedAsyncThunk.fulfilled,
        (state, action) => {
          state.detailed.state = 'idle';
          state.detailed.value = action.payload;
        },
      )
      .addCase(getCallCenterDaysOffDetailedAsyncThunk.rejected, (state) => {
        state.detailed.state = 'idle';
      })

      //create
      .addCase(createCallCenterDaysOffAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(createCallCenterDaysOffAsyncThunk.fulfilled, (state, action) => {
        state.create.state = 'idle';
        state.create.value = action.payload;
      })
      .addCase(createCallCenterDaysOffAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })

      //update
      .addCase(updateCallCenterDaysOffAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(updateCallCenterDaysOffAsyncThunk.fulfilled, (state, action) => {
        state.update.state = 'idle';
        state.update.value = action.payload;
      })
      .addCase(updateCallCenterDaysOffAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })

      //delete
      .addCase(deleteCallCenterDaysOffAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteCallCenterDaysOffAsyncThunk.fulfilled, (state, action) => {
        state.delete.state = 'idle';
        state.delete.value = action.payload;
      })
      .addCase(deleteCallCenterDaysOffAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      });
  },
});

export const selectCallCenterDaysOffList = (state: RootState) =>
  state.callCenterDaysOff.list.value;

export const selectCallCenterDaysOffDetailed = (state: RootState) =>
  state.callCenterDaysOff.detailed.value;

export const checkCallCenterDaysOffCreate = (state: RootState) =>
  state.callCenterDaysOff.create;

export const checkCallCenterDaysOffUpdate = (state: RootState) =>
  state.callCenterDaysOff.update;

export const checkCallCenterDaysOffDelete = (state: RootState) =>
  state.callCenterDaysOff.delete;

export const { clearCreateCallCeterDaysOff, clearUpdateCallCenterDaysOff } =
  callCenterDaysOffSlice.actions;

export default callCenterDaysOffSlice.reducer;

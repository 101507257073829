import React from 'react';
import styled from 'styled-components';
import { H3 } from '../../../App.style';
import { useTranslation } from 'react-i18next';

const AssociatedProcessesContainer = styled.div`
  position: absolute;
  top: 150px;
  right: 30px;
  min-height: 50px;
  padding: 10px;
  width: 270px;

  background: rgba(18, 127, 191, 0.14);

  border-radius: 5px;

  border: 1px solid #127fbf;
`;

interface AssociatedProcessesProps {
  edit: boolean;
  associatedProcesess: string[];
}

const AssociatedProcesses: React.FC<AssociatedProcessesProps> = ({
  edit,
  associatedProcesess,
}) => {
  const { t } = useTranslation();
  return (
    <AssociatedProcessesContainer>
      <H3>
        {edit ? t('presets.associatedline1') : t('presets.associatedline2')}
      </H3>
      <H3 $bold>
        {associatedProcesess.length
          ? associatedProcesess.join(', ')
          : t('presets.noProcesses')}
      </H3>
    </AssociatedProcessesContainer>
  );
};

export default AssociatedProcesses;

import { EuiFieldText, EuiDatePicker } from '@elastic/eui';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { Button, H3 } from '../../../../App.style';
import {
  CampaignNonWorkingDaysConfiguration,
  CampaignNonWorkingDaysConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../../processes/forms/ProcessForm.style';
import {
  DaysOffDatesContainer,
  DayOffContainer,
  TrashIconContainer,
  CalendarIconContainer,
} from '../DaysOff.style';
import {
  checkBotDaysOffCreate,
  checkBotDaysOffUpdate,
  clearCreateBotDaysOff,
  clearUpdateBotDaysOff,
  getBotDaysOffListAsyncThunk,
} from './api/botDaysOffSlice';
import { ApiError } from '../../../../../common/types';
import { CalendarIcon } from '../../../../../resources/icons-new/CalendarIcon';
import { TrashIcon } from '../../../../../resources/icons-new/TrashIcon';
import { useTranslation } from 'react-i18next';

const useBotDaysOffApiErrors = () => {
  const checkCreate = useAppSelector(checkBotDaysOffCreate);
  const createErrors = checkCreate?.value?.error;

  const checkUpdate = useAppSelector(checkBotDaysOffUpdate);
  const updateErrors = checkUpdate?.value?.error;

  const [botDaysOffApiErrors, setBotDaysOffApiErrors] = useState<
    ApiError<CampaignNonWorkingDaysConfiguration>
  >({} as ApiError<CampaignNonWorkingDaysConfiguration>);

  useEffect(() => {
    if (createErrors)
      setBotDaysOffApiErrors((prevErrors) => ({
        ...prevErrors,
        ...createErrors,
      }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setBotDaysOffApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [botDaysOffApiErrors, setBotDaysOffApiErrors] as const;
};

interface BotDaysOffProps {
  botDaysOff: CampaignNonWorkingDaysConfigurationRequest;
  setBotDaysOff: React.Dispatch<
    React.SetStateAction<CampaignNonWorkingDaysConfigurationRequest>
  >;
  button?: () => void;
  createNew?: boolean;
  backToList: () => void;
  backToDetailed?: () => void;
  handleNewBotDaysOffChange?: () => void;
  buttonDown?: boolean;
}

const BotDaysOffForm: React.FC<BotDaysOffProps> = ({
  botDaysOff,
  setBotDaysOff,
  button,
  createNew,
  backToList,
  backToDetailed,
  handleNewBotDaysOffChange,
  buttonDown,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const checkCreate = useAppSelector(checkBotDaysOffCreate);
  const checkUpdate = useAppSelector(checkBotDaysOffUpdate);

  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewBotDaysOffChange && handleNewBotDaysOffChange();
      dispatch(getBotDaysOffListAsyncThunk(botDaysOff.strategy));
      dispatch(clearCreateBotDaysOff());

      backToList();
    }

    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      backToDetailed && backToDetailed();
      dispatch(getBotDaysOffListAsyncThunk(botDaysOff.strategy));
      dispatch(clearUpdateBotDaysOff());
    }
  });

  const handleChange = (date: Moment) => {
    const dateString = date.format('YYYY-MM-DD');

    setBotDaysOff((prevState) => {
      if (prevState.holidays && prevState.holidays.includes(dateString)) {
        alert(t('presets.daysOff.dateAlreadyAdded'));
        return prevState;
      }

      const newHolidays = [...(prevState.holidays || []), dateString];
      newHolidays.sort();

      return {
        ...prevState,
        holidays: newHolidays,
      };
    });
  };

  const handleRemove = (indexToRemove: number) => {
    setBotDaysOff((prevState) => ({
      ...prevState,
      holidays: prevState.holidays
        ? prevState.holidays.filter((_, index) => index !== indexToRemove)
        : [],
    }));
  };

  const [botDaysOffApiErrors, setBotDaysOffApiErrors] =
    useBotDaysOffApiErrors();

  return (
    <FormContainer
      $processForm={buttonDown}
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>{t('presets.daysOff.name')}</H3>}
        isInvalid={botDaysOffApiErrors.name ? true : false}
        error={botDaysOffApiErrors.name}
      >
        <EuiFieldText
          isInvalid={botDaysOffApiErrors.name ? true : false}
          value={botDaysOff.name}
          onChange={(e) => {
            setBotDaysOff({ ...botDaysOff, name: e.target.value });

            botDaysOffApiErrors.name &&
              setBotDaysOffApiErrors({
                ...botDaysOffApiErrors,
                name: undefined,
              });
          }}
        />
      </FormRow>
      <FormRow label={<H3>{t('presets.daysOff.holidaysDate')}</H3>}>
        <DaysOffDatesContainer>
          {botDaysOff.holidays && botDaysOff.holidays.length ? (
            botDaysOff.holidays.map((date, index) => {
              return (
                <DayOffContainer key={`date-${index}`}>
                  <H3>{moment(date).format('D MMMM YYYY')}</H3>
                  <TrashIconContainer onClick={() => handleRemove(index)}>
                    <TrashIcon />
                  </TrashIconContainer>
                </DayOffContainer>
              );
            })
          ) : (
            <div>{t('presets.daysOff.nothingToShow')}</div>
          )}
          <DayOffContainer>
            <CalendarIconContainer>
              <EuiDatePicker
                selected={null}
                onChange={handleChange}
                css={{
                  background: 'transparent',
                  position: 'absolute',
                  boxShadow: 'none',
                  height: 30,
                  width: 30,
                  zIndex: 5,
                }}
                customInput={<div></div>}
              />

              <CalendarIcon
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 5,
                  width: 20,
                  height: 20,
                  zIndex: 0,
                }}
              />
            </CalendarIconContainer>
          </DayOffContainer>
        </DaysOffDatesContainer>
      </FormRow>
      {createNew && !buttonDown ? (
        <PresetCreateButton>
          <Button type="submit">{t('common.create')}</Button>
        </PresetCreateButton>
      ) : (
        buttonDown && <Button type="submit">{t('common.create')}</Button>
      )}
    </FormContainer>
  );
};

export default BotDaysOffForm;

import React from 'react';
import { SnapshotContainer } from '../Campaigns.style';
import { Campaign } from '../../../../generated/tenants/Api';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import { EuiFieldText } from '@elastic/eui';
import { H3 } from '../../../App.style';
import { useTranslation } from 'react-i18next';

interface CampaignPreferencesSnapshotProps {
  campaign?: Campaign;
}

const CampaignPreferencesSnapshot: React.FC<
  CampaignPreferencesSnapshotProps
> = ({ campaign }) => {
  const { t } = useTranslation();
  return (
    <SnapshotContainer>
      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.inboundEnabled')}</H3>
        }
      >
        <EuiFieldText
          readOnly
          value={campaign?.inbound_enabled_for_closed_record ? 'yes' : 'no'}
        />
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.directTransfer')}</H3>
        }
      >
        <EuiFieldText
          readOnly
          value={campaign?.direct_transfer_to_cc ? 'Call center' : 'Bot'}
        />
      </FormRow>

      <FormRow
        label={<H3>{t('processes.form.campaignPreferences.redirect')}</H3>}
      >
        <EuiFieldText
          readOnly
          value={campaign?.redirection_presentation_mode}
        />
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.callCenterNumber')}</H3>
        }
      >
        <EuiFieldText
          readOnly
          value={
            campaign?.redirection_phone_number === null
              ? ''
              : campaign?.redirection_phone_number
          }
        />
      </FormRow>

      <FormRow
        label={
          <H3>{t('processes.form.campaignPreferences.fallbackMessage')}</H3>
        }
      >
        <EuiFieldText
          readOnly
          value={campaign?.inbound_fallback_message as string}
        />
      </FormRow>
    </SnapshotContainer>
  );
};

export default CampaignPreferencesSnapshot;

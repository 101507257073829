import React from 'react';
import { Strategy } from '../../../../../../../generated/tenants/Api';
import Modal from '../../../../../../../common/modals/Modal';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../../../common/modals/Modals.style';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../../../../App.style';
import {
  deleteArchivedStrategyAsyncThunk,
  deleteStrategyAsyncThunk,
  getArchivedStrategiesListAsyncThunk,
  getStrategiesListAsyncThunk,
} from '../api/strategiesSlice';
import { useAppDispatch } from '../../../../../../../common/hooks';
import { useTranslation } from 'react-i18next';
import { getLoginStateAsyncThunk } from '../../../../../session/api/sessionSlice';

interface DeleteStrategyModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedStrategy: Strategy;
  currentStrategyId: string;
  isArchived: boolean;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteStrategyModal: React.FC<DeleteStrategyModalProps> = ({
  isVisible,
  closeModal,
  selectedStrategy,
  currentStrategyId,
  isArchived,
  setIsLoadingVisible,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleDeleteClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoadingVisible(true);
    if (!isArchived) {
      dispatch(deleteStrategyAsyncThunk(selectedStrategy.id))
        .then(() => {
          if (selectedStrategy.id === currentStrategyId) {
            localStorage.removeItem('strategy');
            window.location.reload();
          } else {
            closeModal();
            dispatch(getStrategiesListAsyncThunk());
            dispatch(getArchivedStrategiesListAsyncThunk());
            dispatch(getLoginStateAsyncThunk());
          }
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    } else {
      dispatch(deleteArchivedStrategyAsyncThunk(selectedStrategy.id))
        .then(() => {
          closeModal();
          dispatch(getStrategiesListAsyncThunk());
          dispatch(getArchivedStrategiesListAsyncThunk());
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    }

    return false;
  };

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        {currentStrategyId === selectedStrategy.id ? (
          <H3>
            {t('settings.adminPanel.manageWorkspaces.form.deleteUsingNow')}
          </H3>
        ) : (
          <H3>{t('settings.adminPanel.manageWorkspaces.form.delete')}</H3>
        )}
      </DeleteColumnText>
    </DeleteColumnContainer>
  );
  return (
    <Modal
      headerTitle={`${t('settings.adminPanel.manageWorkspaces.form.remove')}${
        selectedStrategy.name
      }`}
      isVisible={isVisible}
      onCancel={closeModal}
      buttonStyle="RED"
      children={body}
      onConfirm={handleDeleteClick}
    />
  );
};

export default DeleteStrategyModal;

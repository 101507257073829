import React, { useEffect, useState } from 'react';
import {
  BillingConfiguration,
  Company,
  CompanyCreateRequest,
} from '../../../../../../generated/public/Api';
import { H3 } from '../../../../../App.style';
import {
  checkTenantCreate,
  checkTenantUpdate,
  clearCreateTenant,
  clearUpdateTenant,
  getTenantsListAsyncThunk,
} from '../../api/superAdminSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { ApiError, ApiErrorExtended } from '../../../../../../common/types';
import {
  TabButton,
  TabButtons,
  TabContent,
  TabsContainer,
} from '../../../../presets/PresetsPanel.style';
import TenantGeneralForm from './forms/TenantGeneralForm';
import TenantBillingForm from './forms/TenantBillingForm';
import TenantSmsForm from './forms/TenantSmsForm';
import TenantLanguageForm from './forms/TenantLanguageForm';
import { WarningIcon } from '../../../../../../resources/icons-new/WarningIcon';
import SuccessPopup from '../../../../../../common/popups/SuccessPopup';
import ErrorPopup from '../../../../../../common/popups/ErrorPopup';
import { t } from 'i18next';

const useTenantApiErrors = () => {
  const checkCreateTenant = useAppSelector(checkTenantCreate);
  const createErrors = checkCreateTenant?.value?.error;

  const checkUpdateTenant = useAppSelector(checkTenantUpdate);
  const updateErrors = checkUpdateTenant?.value?.error;

  const [tenantApiErrors, setTenantApiErrors] = useState<
    ApiErrorExtended<Company>
  >({} as ApiErrorExtended<Company>);

  useEffect(() => {
    if (createErrors)
      setTenantApiErrors((prevErrors) => ({ ...prevErrors, ...createErrors }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setTenantApiErrors((prevErrors) => ({ ...prevErrors, ...updateErrors }));
  }, [updateErrors]);

  return [tenantApiErrors, setTenantApiErrors] as const;
};

interface TenantFormProps {
  tenant: CompanyCreateRequest;
  setTenant: React.Dispatch<React.SetStateAction<CompanyCreateRequest>>;
  createNew?: boolean;
  back: () => void;
  backToDetailed?: () => void;
  detailedView?: boolean;
  openDeleteTenantModal?: () => void;
}

const TenantForm: React.FC<TenantFormProps> = ({
  tenant,
  setTenant,
  createNew,
  back,
  backToDetailed,
  detailedView,
  openDeleteTenantModal,
}) => {
  useEffect(() => {
    return () => {
      dispatch(clearCreateTenant());
      dispatch(clearUpdateTenant());
    };
  }, []);

  const dispatch = useAppDispatch();
  const [tenantApiErrors, setTenantApiErrors] = useTenantApiErrors();

  const checkCreate = useAppSelector(checkTenantCreate);

  const checkUpdate = useAppSelector(checkTenantUpdate);

  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      back();

      dispatch(clearCreateTenant());

      dispatch(getTenantsListAsyncThunk());
    }

    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      backToDetailed && backToDetailed();

      closeTenantErrorErrorPopup();
      showTenantEditSuccessPopup();

      dispatch(clearUpdateTenant());
      dispatch(getTenantsListAsyncThunk());
    } else if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'fail'
    ) {
      showTenantErrorErrorPopup();
    }
  }, [checkCreate, checkUpdate]);

  const tabs = [
    {
      name: t('superAdminPanel.tenants.general'),
      id: 'general',
      content: (
        <TenantGeneralForm
          tenant={tenant}
          setTenant={setTenant}
          tenantApiErrors={tenantApiErrors}
          setTenantApiErrors={setTenantApiErrors}
          openDeleteTenantModal={openDeleteTenantModal}
          detailedView={detailedView}
          createNew={createNew}
        />
      ),
    },
    {
      name: t('superAdminPanel.tenants.priceAndBillings'),
      id: 'billings',
      content: (
        <TenantBillingForm
          tenant={tenant}
          setTenant={setTenant}
          tenantApiErrors={tenantApiErrors}
          setTenantApiErrors={setTenantApiErrors}
          detailedView={detailedView}
          createNew={createNew}
        />
      ),
    },
    {
      name: t('superAdminPanel.tenants.language'),
      id: 'language',
      content: (
        <TenantLanguageForm
          tenant={tenant}
          setTenant={setTenant}
          detailedView={detailedView}
        />
      ),
    },
    {
      name: t('superAdminPanel.tenants.sms'),
      id: 'sms',
      content: (
        <TenantSmsForm
          tenant={tenant}
          setTenant={setTenant}
          detailed={detailedView}
        />
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  const handleTabClick = (tab: string) => {
    setCurrentTab(tab);
  };
  const isTabInvalid = (index: number) => {
    if (
      index === 0 &&
      (tenantApiErrors.name || tenantApiErrors.slug || tenantApiErrors.details)
    ) {
      return true;
    } else if (
      index === 1 &&
      hasBillingConfigurationErrors(tenantApiErrors.billing_configuration)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const hasBillingConfigurationErrors = (
    billingConfigErrors?: ApiError<BillingConfiguration>,
  ) => {
    if (!billingConfigErrors) return false;

    return Object.values(billingConfigErrors).some(
      (value) => value !== undefined,
    );
  };

  const [tenantEditSuccessPopupVisible, setTenantEditSuccessPopupVisible] =
    useState<boolean>(false);

  const showTenantEditSuccessPopup = () =>
    setTenantEditSuccessPopupVisible(true);
  const closeTenantEditSuccessPopup = () =>
    setTenantEditSuccessPopupVisible(false);

  const [tenantErrorPopupVisible, setTenantErrorPopupVisible] =
    useState<boolean>(false);

  const showTenantErrorErrorPopup = () => setTenantErrorPopupVisible(true);
  const closeTenantErrorErrorPopup = () => setTenantErrorPopupVisible(false);

  return (
    <TabsContainer>
      <TabButtons>
        {tabs.map((tab, index) => {
          const isActive = currentTab === tab.id.toLowerCase();
          return (
            <TabButton
              invalid={isTabInvalid(index)}
              key={tab.name}
              active={isActive}
              onClick={() => handleTabClick(tab.id)}
              style={{ width: '25%' }}
            >
              <H3 $grey={!isActive} $bold={isActive}>
                {tab.name}
                {isTabInvalid(index) && (
                  <WarningIcon style={{ marginLeft: 8 }} color="#983700" />
                )}
              </H3>
            </TabButton>
          );
        })}
      </TabButtons>
      <TabContent>
        {tabs.map((tab) => {
          if (tab.id === currentTab) {
            return <div key={tab.name}>{tab.content}</div>;
          }
          return null;
        })}
      </TabContent>
      {tenantEditSuccessPopupVisible && (
        <SuccessPopup
          text={t('superAdminPanel.tenants.changesSaved')}
          onClose={closeTenantEditSuccessPopup}
        />
      )}
      {tenantErrorPopupVisible && (
        <ErrorPopup
          text={t('superAdminPanel.tenants.error')}
          onClose={closeTenantErrorErrorPopup}
        />
      )}
    </TabsContainer>
  );
};

export default TenantForm;

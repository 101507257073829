import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { CallingConfiguration } from '../../../../generated/tenants/Api';
import {
  checkCallingConfigurationCreate,
  checkCallingConfigurationDelete,
  checkCallingConfigurationEdit,
  createCallingConfigurationAsyncThunk,
  deleteCallingConfigurationAsyncThunk,
  getCallingConfigurationListAsyncThunk,
  selectCallingConfigurationList,
} from './api/callingConfigurationSlice';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../processes/api/processSlice';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import CreateCallingPreference from './CreateCallingPreference';
import DetailedCallingPreference from './DetailedCallingPreference';
import CallingPreferencesList from './CallingPreferencesList';
import PresetDeleteModal from '../PresetDeleteModal';
import { PresetContainer } from '../PresetsPanel.style';
import BackToList from '../shared/BackToList';
import usePermissions from '../../users/usePermissions';
import LoadingModal from '../../../../common/modals/LoadingModal';

const CallingPreferences = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);

  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('process_campaign');

  const [callingPreferencesState, setCallingPreferencesState] = useState<
    'create' | 'list' | 'detailed'
  >('list');

  const handleAddCallingPrefernceClick = () =>
    setCallingPreferencesState('create');

  /////detailed view
  const handleDetailedViewClick = (voiceId: string) => {
    const callingPreferenceById = apiCallingPreferencesList.find(
      (callingPreference) => callingPreference.id === voiceId,
    );
    if (callingPreferenceById)
      setSelectedCallingPreference(callingPreferenceById);
    setCallingPreferencesState('detailed');
  };

  const handleBackToList = () => {
    setCallingPreferencesState('list');
  };

  useEffect(() => {
    dispatch(getCallingConfigurationListAsyncThunk(strategy));
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);

  const apiCallingPreferencesList = useAppSelector(
    selectCallingConfigurationList,
  );

  const apiProcessesList = useAppSelector(selectProcessesList);

  function findProcessNamesById(id: string): string[] {
    return apiProcessesList
      .filter((item) => item.calling_configuration === id)
      .map((item) => item.name);
  }

  const [selectedCallingPreference, setSelectedCallingPreference] =
    useState<CallingConfiguration>();

  const checkCallingPrefUpdate = useAppSelector(checkCallingConfigurationEdit);
  useEffect(() => {
    if (
      checkCallingPrefUpdate.state === 'idle' &&
      checkCallingPrefUpdate.value?.status === 'success' &&
      checkCallingPrefUpdate.value.callingConfiguration
    ) {
      setSelectedCallingPreference(
        checkCallingPrefUpdate.value.callingConfiguration,
      );
    }
  }, [checkCallingPrefUpdate]);

  //delete
  const [isDeleteCallingPrefVisible, setIsDeleteCallingPrefVisible] =
    useState<boolean>(false);

  const handleDeleteCallingConfPreset = (id: string) => {
    const selectedCallingPref = apiCallingPreferencesList.find(
      (callPref) => callPref.id === id,
    );
    if (selectedCallingPref) setSelectedCallingPreference(selectedCallingPref);
    openDeleteCallingPrefModal();
  };

  const openDeleteCallingPrefModal = () => setIsDeleteCallingPrefVisible(true);
  const closeDeleteCallingPrefModal = () =>
    setIsDeleteCallingPrefVisible(false);

  const deleteCallingConfAfterModal = () => {
    if (selectedCallingPreference) {
      setIsLoadingVisible(true);
      dispatch(
        deleteCallingConfigurationAsyncThunk(selectedCallingPreference.id),
      ).finally(() => {
        setIsLoadingVisible(false);
      });
      closeDeleteCallingPrefModal();
    } else {
      alert('error');
    }
  };

  const checkDeleteCallingPref = useAppSelector(
    checkCallingConfigurationDelete,
  );

  useEffect(() => {
    if (checkDeleteCallingPref.state === 'idle') {
      dispatch(getCallingConfigurationListAsyncThunk(strategy));
      setSelectedCallingPreference(undefined);
    }
  }, [checkDeleteCallingPref]);

  //clone

  const handleDuplicateCallingPref = (id: string) => {
    const selectedCallingPref = apiCallingPreferencesList.find(
      (callPref) => callPref.id === id,
    );
    if (selectedCallingPref) {
      let count = 1;
      let newName = selectedCallingPref.name;

      let nameExists = apiCallingPreferencesList.some(
        (item) => item.name === newName,
      );

      while (nameExists) {
        newName =
          selectedCallingPref.name +
          ' - copy' +
          (count > 1 ? `(${count})` : '');
        nameExists = apiCallingPreferencesList.some(
          (item) => item.name === newName,
        );
        count++;
      }

      const copiedCallingPref = {
        ...selectedCallingPref,
        name: newName,
      };
      setIsLoadingVisible(true);
      dispatch(createCallingConfigurationAsyncThunk(copiedCallingPref)).finally(
        () => {
          setIsLoadingVisible(false);
        },
      );
    }
  };

  const checkCopy = useAppSelector(checkCallingConfigurationCreate);

  useEffect(() => {
    if (checkCopy.state === 'idle' && checkCopy.value?.status === 'success') {
      dispatch(getCallingConfigurationListAsyncThunk(strategy));
      setSelectedCallingPreference(undefined);
    }
  }, [checkCopy]);
  //components

  const componentsMap = {
    create: (
      <CreateCallingPreference
        strategy={strategy}
        backToList={handleBackToList}
      />
    ),
    list: (
      <CallingPreferencesList
        callingPreferencesList={apiCallingPreferencesList}
        createCallingPreference={handleAddCallingPrefernceClick}
        goToDetailed={handleDetailedViewClick}
        findProcessNamesById={findProcessNamesById}
        handleDeletePreset={handleDeleteCallingConfPreset}
        handleDuplicateCallingConf={handleDuplicateCallingPref}
        hasPermission={hasPermission}
      />
    ),
    detailed: selectedCallingPreference && (
      <DetailedCallingPreference
        callingPreference={selectedCallingPreference}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesById}
        hasPermission={hasPermission}
      />
    ),
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <PresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {selectedCallingPreference && (
        <PresetDeleteModal
          type={'calling prefernece'}
          isVisible={isDeleteCallingPrefVisible}
          presetName={selectedCallingPreference.name}
          closeModal={closeDeleteCallingPrefModal}
          deletePreset={deleteCallingConfAfterModal}
        />
      )}

      {callingPreferencesState !== 'list' && (
        <BackToList handleBackToList={handleBackToList} />
      )}

      {componentsMap[callingPreferencesState]}
    </PresetContainer>
  );
};

export default CallingPreferences;

import {
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiListGroup,
  EuiListGroupItem,
  EuiText,
} from '@elastic/eui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { OldContentContainer, OldThingsContainer } from '../../App.style';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  getDialoguesListAsyncThunk,
  selectDialoguesList,
} from '../presets/dialogues/api/dialoguesSlice';

const BotsPresets = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);

  useEffect(() => {
    dispatch(getDialoguesListAsyncThunk(strategy));
  }, []);
  const botsConfigList = useAppSelector(selectDialoguesList);

  const botName = localStorage.getItem('botName');

  const handleVindicationBotClick = () => {
    localStorage.setItem('botConfigurationType', 'new');
    navigate('/dashboard/bots/new/1/1');
  };

  const handleCreateNewBasedOnPreset = (id: string) => {
    localStorage.setItem('botConfigurationType', 'new');
    navigate(`/dashboard/bots/edit/${id}`);
  };

  const handleCustomBotClick = () => {
    navigate('/dashboard/bots/custom');
  };

  return (
    <OldThingsContainer>
      <OldContentContainer>
        <EuiFlexItem
          css={{ justifyContent: 'space-between', flexDirection: 'row' }}
        >
          <div>
            <EuiButton fill onClick={() => navigate('/dashboard/bots/')}>
              Go Back
            </EuiButton>
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText css={{ fontSize: 35 }}>
            Select preset for bot with name {botName}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexGroup css={{ margin: 10 }}>
          <EuiFlexItem>
            <EuiCard
              css={{ width: 300 }}
              icon={<EuiIcon size="xxl" type="reporter" />}
              title="Vindication bot"
              description="Preview bot description. Includes basic modules:"
              footer={
                <>
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      paddingLeft: 20,
                      marginBottom: 10,
                    }}
                  >
                    <EuiListGroup>
                      <EuiListGroupItem label="• Identification" />

                      <EuiListGroupItem label="• Verification" />

                      <EuiListGroupItem label="• Payment status" />

                      <EuiListGroupItem label="• Negotiations" />
                    </EuiListGroup>
                  </EuiFlexGroup>
                  <EuiButton fill onClick={handleVindicationBotClick}>
                    Select
                  </EuiButton>
                </>
              }
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiCard
              css={{ width: 300 }}
              icon={<EuiIcon size="xxl" type="reporter" />}
              title="Custom bot"
              description="Use pre-compiled bot image. This configuration requires:"
              footer={
                <>
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      paddingLeft: 20,
                      marginBottom: 10,
                    }}
                  >
                    <EuiListGroup>
                      <EuiListGroupItem label="• Existing image tag" />

                      <EuiListGroupItem label="• Required input context data fields" />

                      <EuiListGroupItem label="• Runtime parameters (optional)" />
                    </EuiListGroup>
                  </EuiFlexGroup>
                  <EuiButton fill onClick={handleCustomBotClick}>
                    Select
                  </EuiButton>
                </>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText css={{ fontSize: 35 }}>
            Or use one of your existing bots as preset
          </EuiText>
        </EuiFlexItem>
        <EuiFlexGroup css={{ flexWrap: 'wrap' }}>
          {botsConfigList.map((bot, i) => {
            if (bot.template === 'DEBTOR')
              return (
                <EuiFlexItem
                  key={`${bot.id}-index${i}`}
                  css={{
                    maxWidth: '20%',
                  }}
                >
                  <EuiCard
                    css={{ width: 200 }}
                    icon={<EuiIcon size="xxl" type="reporter" />}
                    title={bot.name}
                    description={bot.template}
                    footer={
                      <EuiFlexGroup
                        css={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: 5,
                        }}
                      >
                        <EuiButton
                          size="s"
                          fill
                          onClick={() => handleCreateNewBasedOnPreset(bot.id)}
                        >
                          Select
                        </EuiButton>
                      </EuiFlexGroup>
                    }
                  />
                </EuiFlexItem>
              );
          })}
        </EuiFlexGroup>
      </OldContentContainer>
    </OldThingsContainer>
  );
};

export default BotsPresets;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CheckAddSuperUserInvitationResult,
  CheckInvitationResult,
  CheckInviteSuperUserResult,
  CheckTenantCreateResult,
  CheckTenantUpdateResult,
  checkSuperUserToken,
  createSuperUserAfterInvitation,
  createTenant,
  deleteTenant,
  getBillingProcesses,
  getBillingsCampaignList,
  getBillingsRaport,
  getSmsConfigurationList,
  getSuperUsersList,
  getTenantsList,
  inviteSuperUser,
  updateSuperUserPermissions,
  updateTenant,
} from './superAdminApi';
import {
  AdminInvitationRequest,
  BillingProcessList,
  BillingRaportRequest,
  Company,
  CompanyCreateRequest,
  InvitationUpdateRequest,
  PaginatedAdminUserList,
  PaginatedBillingCampaignListList,
  PatchedAdminRoleUpdateRequest,
  SmsConfiguration,
} from '../../../../../generated/public/Api';

import { RootState } from '../../../../store';

export interface SuperAdminState {
  tenants: {
    list: {
      state: 'pending' | 'idle';
      value: Company[];
    };
    create: {
      state: 'pending' | 'idle';
      value?: CheckTenantCreateResult;
    };
    update: {
      state: 'pending' | 'idle';
      value?: CheckTenantUpdateResult;
    };
    delete: {
      state: 'pending' | 'idle';
    };
    smsConfigurationsList: {
      state: 'pending' | 'idle';
      value: SmsConfiguration[];
    };
  };
  billings: {
    processList: {
      state: 'pending' | 'idle';
      value?: BillingProcessList;
    };
    campaignsList: {
      state: 'pending' | 'idle';
      value?: PaginatedBillingCampaignListList;
    };
    generateReport: {
      state: 'pending' | 'idle';
      value?: { url: string; filename: string };
    };
  };
  superUsers: {
    list: {
      state: 'pending' | 'idle';
      value?: PaginatedAdminUserList;
    };
    updatePermissions: {
      state: 'pending' | 'idle';
    };
    invite: {
      state: 'pending' | 'idle';
      value?: CheckInviteSuperUserResult;
    };
    checkToken: {
      state: 'pending' | 'idle';
      value?: CheckInvitationResult;
    };
    createSuperUser: {
      state: 'pending' | 'idle';
      value?: CheckAddSuperUserInvitationResult;
    };
  };
}

const initialState: SuperAdminState = {
  tenants: {
    list: {
      state: 'pending',
      value: [],
    },
    create: {
      state: 'pending',
    },
    update: {
      state: 'pending',
    },
    delete: {
      state: 'pending',
    },
    smsConfigurationsList: {
      state: 'pending',
      value: [],
    },
  },
  billings: {
    processList: {
      state: 'pending',
    },
    campaignsList: { state: 'pending' },
    generateReport: { state: 'pending' },
  },
  superUsers: {
    list: {
      state: 'pending',
    },
    updatePermissions: {
      state: 'pending',
    },
    invite: {
      state: 'pending',
    },
    checkToken: { state: 'pending' },
    createSuperUser: { state: 'pending' },
  },
};

export const getTenantsListAsyncThunk = createAsyncThunk(
  'superAdmin/tenants/list',
  () => getTenantsList(),
);

export const createTenantAsyncThunk = createAsyncThunk(
  'superAdmin/tenants/create',
  (data: CompanyCreateRequest) => createTenant(data),
);

export const updateTenantAsyncThunk = createAsyncThunk(
  'superAdmin/tenants/update',
  (args: { id: string; data: CompanyCreateRequest }) =>
    updateTenant(args.id, args.data),
);

export const deleteTenantAsyncThunk = createAsyncThunk(
  'superAdmin/tenants/delete',
  (id: string) => deleteTenant(id),
);

export const getSmsConfigurationListAsyncThunk = createAsyncThunk(
  'superAdmin/tenants/smsConfigurationsList',
  () => getSmsConfigurationList(),
);

//superUsers
export const getSuperUsersListAsyncThunk = createAsyncThunk(
  'superAdmin/superUsers/list',
  (query?: { filter_info?: string; limit?: number; offset?: number }) =>
    getSuperUsersList(query),
);

export const updateSuperUserPermissionsAsyncThunk = createAsyncThunk(
  'superAdmin/superUsers/update_permissins',
  (args: { userId: string; data: PatchedAdminRoleUpdateRequest }) =>
    updateSuperUserPermissions(args.userId, args.data),
);

export const inviteSuperUserAsyncThunk = createAsyncThunk(
  'superAdmin/superUsers/invite',
  (data: AdminInvitationRequest) => inviteSuperUser(data),
);

export const checkSuperUserTokenAsyncThunk = createAsyncThunk(
  'superAdmin/superUsers/checkToken',
  (token: string) => checkSuperUserToken(token),
);

export const createSuperUserAfterInvitationAsyncThunk = createAsyncThunk(
  'superAdmin/superUsers/create',
  (args: { token: string; data: InvitationUpdateRequest }) =>
    createSuperUserAfterInvitation(args.token, args.data),
);

export const getBillingProcessesAsyncThunk = createAsyncThunk(
  'superAdmin/billings/processesList',
  () => getBillingProcesses(),
);

export const getBillingsCampaignListAsyncThunk = createAsyncThunk(
  'supperAdmin/billings/campaignsList',
  (query: {
    campaign_id?: string;
    end_date?: string;
    process_id: string;
    start_date?: string;
    tenant_id: string;
    time?: 'CUSTOM' | 'LAST_7_DAYS' | 'LAST_MONTH' | 'THIS_MONTH' | 'TODAY';
    limit?: number;
    offset?: number;
  }) => getBillingsCampaignList(query),
);

export const getBillingsReportAsyncThunk = createAsyncThunk(
  'superAdmin/billings/report',
  (data: BillingRaportRequest) => getBillingsRaport(data),
);

export const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    clearCreateTenant: (state) => {
      state.tenants.create = { state: 'pending' };
    },
    clearUpdateTenant: (state) => {
      state.tenants.update = { state: 'pending' };
    },
    clearDeleteTenant: (state) => {
      state.tenants.delete = { state: 'pending' };
    },
    clearBillingsCampaignList: (state) => {
      state.billings.campaignsList = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder
      //list
      .addCase(getTenantsListAsyncThunk.pending, (state) => {
        state.tenants.list.state = 'pending';
      })
      .addCase(getTenantsListAsyncThunk.fulfilled, (state, action) => {
        state.tenants.list.state = 'idle';
        state.tenants.list.value = action.payload;
      })
      .addCase(getTenantsListAsyncThunk.rejected, (state) => {
        state.tenants.list.state = 'idle';
      })

      //create
      .addCase(createTenantAsyncThunk.pending, (state) => {
        state.tenants.create.state = 'pending';
      })
      .addCase(createTenantAsyncThunk.fulfilled, (state, action) => {
        state.tenants.create.state = 'idle';
        state.tenants.create.value = action.payload;
      })
      .addCase(createTenantAsyncThunk.rejected, (state) => {
        state.tenants.create.state = 'idle';
      })

      //update
      .addCase(updateTenantAsyncThunk.pending, (state) => {
        state.tenants.update.state = 'pending';
      })
      .addCase(updateTenantAsyncThunk.fulfilled, (state, action) => {
        state.tenants.update.state = 'idle';
        state.tenants.update.value = action.payload;
      })
      .addCase(updateTenantAsyncThunk.rejected, (state) => {
        state.tenants.update.state = 'idle';
      })

      //delete
      .addCase(deleteTenantAsyncThunk.pending, (state) => {
        state.tenants.delete.state = 'pending';
      })
      .addCase(deleteTenantAsyncThunk.fulfilled, (state) => {
        state.tenants.delete.state = 'idle';
      })
      .addCase(deleteTenantAsyncThunk.rejected, (state) => {
        state.tenants.delete.state = 'idle';
      })

      //sms configuration
      .addCase(getSmsConfigurationListAsyncThunk.pending, (state) => {
        state.tenants.smsConfigurationsList.state = 'pending';
      })
      .addCase(getSmsConfigurationListAsyncThunk.fulfilled, (state, action) => {
        state.tenants.smsConfigurationsList.state = 'idle';
        state.tenants.smsConfigurationsList.value = action.payload;
      })
      .addCase(getSmsConfigurationListAsyncThunk.rejected, (state) => {
        state.tenants.smsConfigurationsList.state = 'idle';
      })

      //superusers list
      .addCase(getSuperUsersListAsyncThunk.pending, (state) => {
        state.superUsers.list.state = 'pending';
      })
      .addCase(getSuperUsersListAsyncThunk.fulfilled, (state, action) => {
        state.superUsers.list.state = 'idle';
        state.superUsers.list.value = action.payload;
      })
      .addCase(getSuperUsersListAsyncThunk.rejected, (state) => {
        state.superUsers.list.state = 'idle';
      })

      //superusers permission update
      .addCase(updateSuperUserPermissionsAsyncThunk.pending, (state) => {
        state.superUsers.updatePermissions.state = 'pending';
      })
      .addCase(updateSuperUserPermissionsAsyncThunk.fulfilled, (state) => {
        state.superUsers.updatePermissions.state = 'idle';
      })
      .addCase(updateSuperUserPermissionsAsyncThunk.rejected, (state) => {
        state.superUsers.updatePermissions.state = 'idle';
      })

      //superusers list
      .addCase(inviteSuperUserAsyncThunk.pending, (state) => {
        state.superUsers.invite.state = 'pending';
      })
      .addCase(inviteSuperUserAsyncThunk.fulfilled, (state, action) => {
        state.superUsers.invite.state = 'idle';
        state.superUsers.invite.value = action.payload;
      })
      .addCase(inviteSuperUserAsyncThunk.rejected, (state) => {
        state.superUsers.invite.state = 'idle';
      })

      //check invitation token
      .addCase(checkSuperUserTokenAsyncThunk.pending, (state) => {
        state.superUsers.checkToken.state = 'pending';
      })
      .addCase(checkSuperUserTokenAsyncThunk.fulfilled, (state, action) => {
        state.superUsers.checkToken.state = 'idle';
        state.superUsers.checkToken.value = action.payload;
      })
      .addCase(checkSuperUserTokenAsyncThunk.rejected, (state) => {
        state.superUsers.checkToken.state = 'idle';
      })

      //add user after invitation
      .addCase(createSuperUserAfterInvitationAsyncThunk.pending, (state) => {
        state.superUsers.createSuperUser.state = 'pending';
      })
      .addCase(
        createSuperUserAfterInvitationAsyncThunk.fulfilled,
        (state, action) => {
          state.superUsers.createSuperUser.state = 'idle';
          state.superUsers.createSuperUser.value = action.payload;
        },
      )
      .addCase(createSuperUserAfterInvitationAsyncThunk.rejected, (state) => {
        state.superUsers.createSuperUser.state = 'idle';
      })

      .addCase(getBillingProcessesAsyncThunk.pending, (state) => {
        state.billings.processList.state = 'pending';
      })
      .addCase(getBillingProcessesAsyncThunk.fulfilled, (state, action) => {
        state.billings.processList.state = 'idle';
        state.billings.processList.value = action.payload;
      })
      .addCase(getBillingProcessesAsyncThunk.rejected, (state) => {
        state.billings.processList.state = 'idle';
      })

      .addCase(getBillingsCampaignListAsyncThunk.pending, (state) => {
        state.billings.campaignsList.state = 'pending';
      })
      .addCase(getBillingsCampaignListAsyncThunk.fulfilled, (state, action) => {
        state.billings.campaignsList.state = 'idle';
        state.billings.campaignsList.value = action.payload;
      })
      .addCase(getBillingsCampaignListAsyncThunk.rejected, (state) => {
        state.billings.campaignsList.state = 'idle';
      })

      .addCase(getBillingsReportAsyncThunk.pending, (state) => {
        state.billings.generateReport.state = 'pending';
      })
      .addCase(getBillingsReportAsyncThunk.fulfilled, (state, action) => {
        state.billings.generateReport.state = 'idle';
        state.billings.generateReport.value = action.payload;
      })
      .addCase(getBillingsReportAsyncThunk.rejected, (state) => {
        state.billings.generateReport.state = 'idle';
      });
  },
});

export const selectTenantsList = (state: RootState) =>
  state.superAdmin.tenants.list.value;

export const checkTenantCreate = (state: RootState) =>
  state.superAdmin.tenants.create;

export const checkTenantUpdate = (state: RootState) =>
  state.superAdmin.tenants.update;

export const checkTenantDelete = (state: RootState) =>
  state.superAdmin.tenants.delete;

export const selectSuperUsersList = (state: RootState) =>
  state.superAdmin.superUsers.list.value;

export const selectBillingProcessesList = (state: RootState) =>
  state.superAdmin.billings.processList.value;

export const selectBillingsCampaignsList = (state: RootState) =>
  state.superAdmin.billings.campaignsList.value;

export const selectSmsConfigurationsList = (state: RootState) =>
  state.superAdmin.tenants.smsConfigurationsList.value;

export const checkSuperuserPermissionUpdate = (state: RootState) =>
  state.superAdmin.superUsers.updatePermissions;

export const checkSuperUserInvitation = (state: RootState) =>
  state.superAdmin.superUsers.invite;

export const checkSuperUserInvitationToken = (state: RootState) =>
  state.superAdmin.superUsers.checkToken;

export const checkSuperUserCreate = (state: RootState) =>
  state.superAdmin.superUsers.createSuperUser;

export const {
  clearCreateTenant,
  clearUpdateTenant,
  clearDeleteTenant,
  clearBillingsCampaignList,
} = superAdminSlice.actions;

export default superAdminSlice.reducer;

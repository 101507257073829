import React from 'react';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';
import { DialogueFailIcon } from '../../../../../../resources/icons-new/DialogueFailIcon';
import { DialogueSphereIcon } from '../../../../../../resources/icons-new/DialogueSphereIcon';
import { DialogueSuccessIcon } from '../../../../../../resources/icons-new/DialogueSuccessIcon';
import { H4 } from '../../../../../App.style';
import { VindicationContentContainer } from '../../Vindication.style';
import { EuiAccordion, EuiSwitch } from '@elastic/eui';
import BotStatementBuilder from '../../tokenInput/BotStatementBuilder';

interface DialoguePaymentStatusProps {
  vindicationDialogue: BotConfigurationRequest;
  setVindicationDialogue: React.Dispatch<
    React.SetStateAction<BotConfigurationRequest>
  >;
}

const DialoguePaymentStatus: React.FC<DialoguePaymentStatusProps> = ({
  vindicationDialogue,
  setVindicationDialogue,
}) => {
  const renderAccordionTitle = (
    text: string,
    icon: 'success' | 'fail' | 'sphere',
  ) => {
    const iconMap = {
      success: <DialogueSuccessIcon />,
      fail: <DialogueFailIcon />,
      sphere: <DialogueSphereIcon />,
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {iconMap[icon]}
        <H4 $medium>{text}</H4>
      </div>
    );
  };

  const handleUpdateGenerationsConfig = (
    newValue: string,
    configKey: string,
  ) => {
    setVindicationDialogue((prevState) => ({
      ...prevState,
      generations_config: {
        ...prevState.generations_config,
        [configKey]: newValue, // Dynamicznie aktualizujemy klucz na podstawie configKey
      },
    }));
  };
  return (
    <VindicationContentContainer>
      <BotStatementBuilder
        title="Rozpoczecie modulu payment status"
        value={
          vindicationDialogue.generations_config?.['INF-STA-debt_intro-1-1']
        }
        onSave={handleUpdateGenerationsConfig}
        configKey={'INF-STA-debt_intro-1-1'}
        customTokens={vindicationDialogue.custom_tokens ?? []}
      />

      <BotStatementBuilder
        title="Pytanie o dokonanie płatności"
        value={
          vindicationDialogue.generations_config?.[
            'REQ-INF-is_payment_done-1-1'
          ]
        }
        onSave={handleUpdateGenerationsConfig}
        configKey={'REQ-INF-is_payment_done-1-1'}
        customTokens={vindicationDialogue.custom_tokens ?? []}
      />

      <div style={{ marginLeft: 50 }}>
        <EuiAccordion
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'JEŻELI rozmówca zadeklaruje że zadłużenie zostało zapłacone',
            'success',
          )}
        >
          <div style={{ marginLeft: 50, paddingTop: 50 }}>
            <EuiAccordion
              id={'myAccordion'}
              buttonContent={
                <div>
                  <EuiSwitch
                    compressed
                    checked={true}
                    onChange={(e) => {
                      e.stopPropagation();
                      console.log(e);
                    }}
                    label={<H4 $medium>Bot ma dopytać o datę wpłaty</H4>}
                  />
                </div>
              }
            >
              <div
                style={{
                  marginLeft: 50,
                  paddingTop: 50,
                  borderLeft: '1px solid $grey',
                }}
              >
                <EuiAccordion
                  id={'myAccordion'}
                  buttonContent={renderAccordionTitle(
                    'JEŻELI dłużnik informuje że zapłacił X lub mniej dni temu.',
                    'success',
                  )}
                >
                  <BotStatementBuilder
                    icon="fail"
                    value={vindicationDialogue.generations_config?.['']}
                    onSave={handleUpdateGenerationsConfig}
                    configKey={''}
                    customTokens={vindicationDialogue.custom_tokens ?? []}
                  />
                </EuiAccordion>

                <EuiAccordion
                  style={{ marginTop: 50 }}
                  id={'myAccordion'}
                  buttonContent={renderAccordionTitle(
                    'JEŻELI deklarowany termin wpłaty przekracza X lub więcej dni.',
                    'fail',
                  )}
                >
                  <BotStatementBuilder
                    icon="fail"
                    value={vindicationDialogue.generations_config?.['']}
                    onSave={handleUpdateGenerationsConfig}
                    configKey={''}
                    customTokens={vindicationDialogue.custom_tokens ?? []}
                  />
                </EuiAccordion>

                <EuiAccordion
                  style={{ marginTop: 50 }}
                  id={'myAccordion'}
                  buttonContent={
                    <div>
                      <EuiSwitch
                        compressed
                        checked={false}
                        onChange={(e) => {
                          e.stopPropagation();
                          console.log(e);
                        }}
                        label={
                          <H4 $medium>JEŻELI dłużnik wskaże datę przyszłą</H4>
                        }
                      />
                    </div>
                  }
                >
                  <BotStatementBuilder
                    icon="fail"
                    value={vindicationDialogue.generations_config?.['']}
                    onSave={handleUpdateGenerationsConfig}
                    configKey={''}
                    customTokens={vindicationDialogue.custom_tokens ?? []}
                  />
                </EuiAccordion>
              </div>
            </EuiAccordion>

            <EuiAccordion
              style={{ marginTop: 50 }}
              id={'myAccordion'}
              buttonContent={
                <div>
                  <EuiSwitch
                    compressed
                    checked={true}
                    onChange={(e) => {
                      e.stopPropagation();
                      console.log(e);
                    }}
                    label={<H4 $medium>Bot ma dopytać o kwotę wpłaty</H4>}
                  />
                </div>
              }
            >
              <BotStatementBuilder
                value={vindicationDialogue.generations_config?.['']}
                onSave={handleUpdateGenerationsConfig}
                configKey={''}
                customTokens={vindicationDialogue.custom_tokens ?? []}
              />
            </EuiAccordion>

            <EuiAccordion
              style={{ marginTop: 50 }}
              id={'myAccordion'}
              buttonContent={
                <div>
                  <EuiSwitch
                    compressed
                    checked={true}
                    onChange={(e) => {
                      e.stopPropagation();
                      console.log(e);
                    }}
                    label={<H4 $medium>Bot ma dopytać o walutę</H4>}
                  />
                </div>
              }
            >
              <BotStatementBuilder
                value={vindicationDialogue.generations_config?.['']}
                onSave={handleUpdateGenerationsConfig}
                configKey={''}
                customTokens={vindicationDialogue.custom_tokens ?? []}
              />

              <div
                style={{
                  marginLeft: 50,
                  paddingTop: 50,
                  borderLeft: '1px solid $grey',
                }}
              >
                <EuiAccordion
                  id={'myAccordion'}
                  buttonContent={renderAccordionTitle(
                    'JEŻELI dłużnik udzielił odpowiedzi.',
                    'success',
                  )}
                >
                  <BotStatementBuilder
                    value={
                      vindicationDialogue.generations_config?.[
                        'REQ-ACT-identification_failed-1-1'
                      ]
                    }
                    onSave={handleUpdateGenerationsConfig}
                    configKey={'REQ-ACT-identification_failed-1-1'}
                    readOnly
                    customTokens={vindicationDialogue.custom_tokens ?? []}
                  />
                </EuiAccordion>
              </div>
            </EuiAccordion>
          </div>
        </EuiAccordion>

        <EuiAccordion
          style={{ marginTop: 50 }}
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'JEŻELI dłużnik zadeklaruje że część zadłużenia została zapłacona',
            'sphere',
          )}
        >
          <BotStatementBuilder
            value={
              vindicationDialogue.generations_config?.[
                'REQ-ACT-identification_failed-1-1'
              ]
            }
            onSave={handleUpdateGenerationsConfig}
            configKey={'REQ-ACT-identification_failed-1-1'}
            readOnly
            customTokens={vindicationDialogue.custom_tokens ?? []}
          />
        </EuiAccordion>

        <EuiAccordion
          style={{ marginTop: 50 }}
          id={'myAccordion'}
          buttonContent={
            <div>
              <EuiSwitch
                compressed
                checked={true}
                onChange={(e) => {
                  e.stopPropagation();
                  console.log(e);
                }}
                label={<H4 $medium>JEŻELI dłużnik podaje datę wpłaty</H4>}
              />
            </div>
          }
        >
          <div
            style={{
              marginLeft: 50,
              paddingTop: 50,
              borderLeft: '1px solid $grey',
            }}
          >
            <EuiAccordion
              id={'myAccordion'}
              buttonContent={renderAccordionTitle(
                'JEŻELI dłużnik informuje że zapłacił X lub mniej dni temu.',
                'success',
              )}
            >
              <BotStatementBuilder
                icon="fail"
                value={vindicationDialogue.generations_config?.['']}
                onSave={handleUpdateGenerationsConfig}
                configKey={''}
                customTokens={vindicationDialogue.custom_tokens ?? []}
              />
            </EuiAccordion>

            <EuiAccordion
              style={{ marginTop: 50 }}
              id={'myAccordion'}
              buttonContent={renderAccordionTitle(
                'JEŻELI deklarowany termin wpłaty przekracza X lub więcej dni.',
                'fail',
              )}
            >
              <BotStatementBuilder
                icon="fail"
                value={vindicationDialogue.generations_config?.['']}
                onSave={handleUpdateGenerationsConfig}
                configKey={''}
                customTokens={vindicationDialogue.custom_tokens ?? []}
              />
            </EuiAccordion>
          </div>
        </EuiAccordion>
      </div>
    </VindicationContentContainer>
  );
};

export default DialoguePaymentStatus;

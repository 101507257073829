import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  generateReport,
  generateReportPerCall,
  generateReportPerCampaign,
  generateReportPerRecord,
} from './reportsApi';
import {
  ReportPerCallRequest,
  ReportPerCampaignRequest,
  ReportPerRecordRequest,
} from '../../../../generated/tenants/Api';

export interface ReportsState {
  reportPerCall: {
    state: 'pending' | 'idle';
    value?: { url: string; filename: string };
  };

  reportPerCampaign: {
    state: 'pending' | 'idle';
    value?: { url: string; filename: string };
  };

  reportPerRecord: {
    state: 'pending' | 'idle';
    value?: { url: string; filename: string };
  };

  report: {
    state: 'pending' | 'idle';
    value?: { url: string; filename: string };
  };
}

const initialState: ReportsState = {
  reportPerCall: {
    state: 'pending',
  },
  reportPerCampaign: {
    state: 'pending',
  },
  reportPerRecord: {
    state: 'pending',
  },
  report: { state: 'pending' },
};

export const generateReportPerCallAsyncThunk = createAsyncThunk(
  'reports/per_call/generate',
  (args: { processId: string; data: ReportPerCallRequest }) =>
    generateReportPerCall(args.processId, args.data),
);

export const generateReportPerCampaignAsyncThunk = createAsyncThunk(
  'reports/per_campaign/generate',
  (args: { processId: string; data: ReportPerCampaignRequest }) =>
    generateReportPerCampaign(args.processId, args.data),
);

export const generateReportPerRecordAsyncThunk = createAsyncThunk(
  'reports/per_record/generate',
  (args: { processId: string; data: ReportPerRecordRequest }) =>
    generateReportPerRecord(args.processId, args.data),
);

export const generateReportAsyncThunk = createAsyncThunk(
  'reports/custom',
  (campaignId: string) => generateReport(campaignId),
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //generate report per call
      .addCase(generateReportPerCallAsyncThunk.pending, (state) => {
        state.reportPerCall.state = 'pending';
      })
      .addCase(generateReportPerCallAsyncThunk.fulfilled, (state, action) => {
        state.reportPerCall.state = 'idle';
        state.reportPerCall.value = action.payload;
      })
      .addCase(generateReportPerCallAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.reportPerCall.state = 'idle';
      })

      //generate report per Campaign
      .addCase(generateReportPerCampaignAsyncThunk.pending, (state) => {
        state.reportPerCampaign.state = 'pending';
      })
      .addCase(
        generateReportPerCampaignAsyncThunk.fulfilled,
        (state, action) => {
          state.reportPerCampaign.state = 'idle';
          state.reportPerCampaign.value = action.payload;
        },
      )
      .addCase(
        generateReportPerCampaignAsyncThunk.rejected,
        (state, action) => {
          console.error('Error occurred:', action.error.message);
          state.reportPerCampaign.state = 'idle';
        },
      )

      //generate report per record
      .addCase(generateReportPerRecordAsyncThunk.pending, (state) => {
        state.reportPerRecord.state = 'pending';
      })
      .addCase(generateReportPerRecordAsyncThunk.fulfilled, (state, action) => {
        state.reportPerRecord.state = 'idle';
        state.reportPerRecord.value = action.payload;
      })
      .addCase(generateReportPerRecordAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.reportPerRecord.state = 'idle';
      })

      //generate report per record
      .addCase(generateReportAsyncThunk.pending, (state) => {
        state.report.state = 'pending';
      })
      .addCase(generateReportAsyncThunk.fulfilled, (state, action) => {
        state.report.state = 'idle';
        state.report.value = action.payload;
      })
      .addCase(generateReportAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.report.state = 'idle';
      });
  },
});

export default reportsSlice.reducer;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ArrowLeftIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M2.64645 8.14645C2.45118 8.34171 2.45118 8.65829 2.64645 8.85355L5.82843 12.0355C6.02369 12.2308 6.34027 12.2308 6.53553 12.0355C6.7308 11.8403 6.7308 11.5237 6.53553 11.3284L3.70711 8.5L6.53553 5.67157C6.7308 5.47631 6.7308 5.15973 6.53553 4.96447C6.34027 4.7692 6.02369 4.7692 5.82843 4.96447L2.64645 8.14645ZM12 8L3 8L3 9L12 9L12 8Z" />
  </StyledSvg>
);

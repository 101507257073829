import React, { useEffect, useState } from 'react';
import {
  FormContainer,
  FormRow,
} from '../../../processes/forms/ProcessForm.style';
import {
  EuiFieldNumber,
  EuiToolTip,
  EuiText,
  EuiRadioGroup,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFormRow,
  EuiSuperSelect,
} from '@elastic/eui';

import { t } from 'i18next';
import FormRowTitle from '../../../processes/forms/FormRowTitle';
import {
  Campaign,
  CampaignChangeRequest,
  DynamicTimeBetweenAttemptsRequest,
  RandomizedTimeBetweenAttemptsRequest,
} from '../../../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  selectStrategyId,
  selectStrategiesList,
} from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { Button, H3, H4 } from '../../../../App.style';
import moment, { Moment } from 'moment';
import MinusInCircleIcon from '../../../../../resources/icons-new/MinusInCircleIcon';
import {
  CustomDatepickerStyle,
  RandomizedContainer,
  RandomizedErrorContainer,
  DynamicContainer,
  DynamicTitleContainer,
  DynamicContentContainer,
  DeleteIntervalIcon,
  DynamicButtonContainer,
} from '../../../presets/callingPreferences/CallingPreferences.style';
import _ from 'lodash';
import {
  checkCampaignUpdate,
  clearCampaignUpdate,
  getCampaignByIdAsyncThunk,
} from '../../api/campaignsSlice';
import { CampaignUpdateResponse } from '../../api/campaignsApi';
import { CampaignOptimizationInfoContainer } from './CampaignOptimization.style';
import { InfoBlueIcon } from '../../../../../resources/icons-new/InfoBlueIcon';
import { InfoIcon } from '../../../../../resources/icons-new/InfoIcon';

const useCampaignUpdateApiErrors = () => {
  const checkUpdate = useAppSelector(checkCampaignUpdate);
  const updateErrors = checkUpdate?.value?.error;

  const [callingPrefencesApiErrors, setCallingPreferencesApiErrors] =
    useState<CampaignUpdateResponse>({} as CampaignUpdateResponse);

  useEffect(() => {
    if (updateErrors)
      setCallingPreferencesApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [callingPrefencesApiErrors, setCallingPreferencesApiErrors] as const;
};

interface CampaignOptimizationFormProps {
  campaign: Campaign;
  campaignUpdate: CampaignChangeRequest;
  setCampaignUpdate: React.Dispatch<
    React.SetStateAction<CampaignChangeRequest>
  >;
  setErrors: React.Dispatch<React.SetStateAction<boolean>>;
  handleBackClick: () => void;
  showSuccessPopup: () => void;
}

const CampaignOptimizationForm: React.FC<CampaignOptimizationFormProps> = ({
  campaign,
  campaignUpdate,
  setCampaignUpdate,
  setErrors,
  handleBackClick,
  showSuccessPopup,
}) => {
  const dispatch = useAppDispatch();

  const strategy = useAppSelector(selectStrategyId);
  const strategiesList = useAppSelector(selectStrategiesList);

  const currentStrategyLines = strategiesList.find(
    (obj) => obj.id === strategy,
  )?.assigned_lines;

  const dynamigLinesUsageOptions = [
    {
      id: '1',
      label: (
        <div style={{ display: 'flex', gap: 10 }}>
          <H4>{t('processes.form.general.yes')}</H4>
          <H4 $grey>{t('processes.form.general.additionalLinesYes')}</H4>
        </div>
      ),
    },
    {
      id: '0',
      label: (
        <div style={{ display: 'flex', gap: 10 }}>
          <H4>{t('processes.form.general.no')}</H4>
          <H4 $grey>{t('processes.form.general.additionalLinesNo')}</H4>
        </div>
      ),
    },
  ];

  //interval modes
  const intervalsOptions = [
    {
      value: 'STATIC',
      inputDisplay: t('presets.callingPreferencesForm.static'),
      dropdownDisplay: <H3>{t('presets.callingPreferencesForm.static')}</H3>,
    },
    {
      value: 'DYNAMIC',
      inputDisplay: t('presets.callingPreferencesForm.dynamic'),
      dropdownDisplay: <H3>{t('presets.callingPreferencesForm.dynamic')}</H3>,
    },
    {
      value: 'RANDOMIZED',
      inputDisplay: t('presets.callingPreferencesForm.randomized'),
      dropdownDisplay: (
        <H3>{t('presets.callingPreferencesForm.randomized')}</H3>
      ),
    },
  ];

  const handleIntervalModeChange = (value: string) => {
    if (value === 'STATIC') {
      setCampaignUpdate((currentCampaign) => ({
        ...currentCampaign,
        calling_configuration: {
          ...currentCampaign.calling_configuration,

          time_between_attempts_mode: 'STATIC',
          dynamic_time_between_attempts: null,
          randomized_time_between_attempts: null,
          time_between_attempts_seconds: 3600,
        },
      }));

      setInvalidIntervals({});
    } else if (value === 'DYNAMIC') {
      const initialDynamicTime: DynamicTimeBetweenAttemptsRequest = {
        interval_start_seconds: 28800,
        interval_end_seconds: 54000,
        time_between_attempts_seconds: 1800,
      };
      setCampaignUpdate((currentCampaign) => ({
        ...currentCampaign,
        calling_configuration: {
          ...currentCampaign.calling_configuration,

          time_between_attempts_mode: 'DYNAMIC',
          dynamic_time_between_attempts: [initialDynamicTime],
          randomized_time_between_attempts: null,
          time_between_attempts_seconds: 3600,
        },
      }));
      setInvalidIntervals({});
    } else if (value === 'RANDOMIZED') {
      const initialRandomizedTime: RandomizedTimeBetweenAttemptsRequest = {
        min_time_between_attempts_seconds: 1800,
        max_time_between_attempts_seconds: 3600,
      };

      setCampaignUpdate((currentCampaign) => ({
        ...currentCampaign,
        calling_configuration: {
          ...currentCampaign.calling_configuration,
          time_between_attempts_mode: 'RANDOMIZED',
          randomized_time_between_attempts: initialRandomizedTime,
          dynamic_time_between_attempts: null,
          time_between_attempts_seconds: undefined,
        },
      }));

      setInvalidIntervals({});

      campaignUpdateApiErrors.time_between_attempts_mode &&
        setCampaignUpdateApiErrors({
          ...campaignUpdateApiErrors,
          time_between_attempts_mode: undefined,
        });
    } else {
      alert('something went wrong');
    }
  };

  //static
  const timeBetweenAttempts = moment()
    .startOf('day')
    .add(
      campaignUpdate.calling_configuration?.time_between_attempts_seconds,
      'seconds',
    );

  const handleTimeBetweenAttemptsChange = (date: Moment | null) => {
    if (date) {
      const seconds = date.diff(moment().startOf('day'), 'seconds');
      setCampaignUpdate((currentCampaign) => ({
        ...currentCampaign,
        calling_configuration: {
          ...currentCampaign.calling_configuration,
          time_between_attempts_seconds: seconds,
        },
      }));

      campaignUpdateApiErrors.time_between_attempts_seconds &&
        setCampaignUpdateApiErrors({
          ...campaignUpdateApiErrors,
          time_between_attempts_seconds: undefined,
        });
    }
  };

  const addDynamicTime = () => {
    const lastDynamicTime =
      campaignUpdate.calling_configuration?.dynamic_time_between_attempts?.[
        campaignUpdate.calling_configuration?.dynamic_time_between_attempts
          .length - 1
      ];
    if (lastDynamicTime) {
      const newStartSeconds = lastDynamicTime.interval_end_seconds; // Add 30 minutes (1800 seconds)
      const newEndSeconds = newStartSeconds + 3600; // Add 1 hour (3600 seconds)

      // Check if the newEndSeconds would go over 86400 seconds (24:00)
      if (newEndSeconds >= 86400) {
        alert(t('presets.callingPreferencesForm.intervalError'));
        return;
      }

      const newDynamicTime = {
        interval_start_seconds: newStartSeconds,
        interval_end_seconds: newEndSeconds,
        time_between_attempts_seconds: 1800, // You can set this to your desired default
      };

      setCampaignUpdate((currentCampaign) => ({
        ...currentCampaign,
        calling_configuration: {
          ...currentCampaign.calling_configuration,
          dynamic_time_between_attempts: [
            ...(currentCampaign.calling_configuration
              ?.dynamic_time_between_attempts || []),
            newDynamicTime,
          ],
        },
      }));
    }
  };

  const removeDynamicTime = (indexToRemove: number) => {
    const newDynamicTimeBetweenAttempts =
      campaignUpdate.calling_configuration?.dynamic_time_between_attempts?.filter(
        (_, index) => index !== indexToRemove,
      );

    setCampaignUpdate((currentCampaign) => ({
      ...currentCampaign,
      calling_configuration: {
        ...currentCampaign.calling_configuration,
        dynamic_time_between_attempts: newDynamicTimeBetweenAttempts,
      },
    }));
  };

  const momentToSeconds = (m: moment.Moment) => {
    return m.hour() * 3600 + m.minute() * 60 + m.second();
  };

  const secondsToMoment = (seconds: number) => {
    return moment().startOf('day').add(seconds, 'seconds');
  };

  const handleTimeChange = (
    index: number,
    field: keyof DynamicTimeBetweenAttemptsRequest,
    value: moment.Moment | null,
  ) => {
    if (!value) {
      alert(
        'Unexpected null value occurred while handling time change request',
      );
      return;
    }

    const newDynamicTimeBetweenAttempts = _.cloneDeep(
      campaignUpdate.calling_configuration?.dynamic_time_between_attempts || [],
    );

    if (newDynamicTimeBetweenAttempts[index]) {
      newDynamicTimeBetweenAttempts[index][field] = momentToSeconds(value);
    }

    setCampaignUpdate((currentCampaign) => ({
      ...currentCampaign,
      calling_configuration: {
        ...currentCampaign.calling_configuration,
        dynamic_time_between_attempts: newDynamicTimeBetweenAttempts,
      },
    }));
  };

  //randomized
  const handleRandomizedTimeBetweenAttemptsChange = (
    field: 'min' | 'max',
    selectedDate: moment.Moment | null,
  ) => {
    if (selectedDate) {
      const selectedTimeInSeconds = momentToSeconds(selectedDate);

      const newCallingPreference = JSON.parse(
        JSON.stringify(campaignUpdate.calling_configuration),
      );

      if (field === 'min') {
        newCallingPreference.randomized_time_between_attempts.min_time_between_attempts_seconds =
          selectedTimeInSeconds;
      } else if (field === 'max') {
        newCallingPreference.randomized_time_between_attempts.max_time_between_attempts_seconds =
          selectedTimeInSeconds;
      }

      setCampaignUpdate((currentCampaign) => ({
        ...currentCampaign,
        calling_configuration: newCallingPreference,
      }));
    }
  };

  //if maxTime is smaller than endTime returns true
  const isRandomizedIntervalInvalid = Boolean(
    campaignUpdate.calling_configuration?.randomized_time_between_attempts
      ?.min_time_between_attempts_seconds &&
      campaignUpdate.calling_configuration?.randomized_time_between_attempts
        ?.max_time_between_attempts_seconds &&
      campaignUpdate.calling_configuration?.randomized_time_between_attempts
        .min_time_between_attempts_seconds >=
        campaignUpdate.calling_configuration?.randomized_time_between_attempts
          .max_time_between_attempts_seconds,
  );

  //testowa walidacja
  const [invalidIntervals, setInvalidIntervals] = useState<
    Record<number, string>
  >({});

  const validateIntervals = () => {
    const intervals =
      campaignUpdate.calling_configuration?.dynamic_time_between_attempts || [];
    const newInvalidIntervals: Record<number, string> = {};

    for (let i = 0; i < intervals.length; i++) {
      const curr = intervals[i];

      // Validate if endTime is smaller than startTime within the same interval
      if (curr.interval_end_seconds <= curr.interval_start_seconds) {
        newInvalidIntervals[i] = t(
          'presets.callingPreferencesForm.endTimeError',
        );
      }

      // Validate for touching or overlapping intervals
      for (let j = i + 1; j < intervals.length; j++) {
        const next = intervals[j];
        if (
          curr.interval_end_seconds > next.interval_start_seconds ||
          next.interval_end_seconds < curr.interval_start_seconds
        ) {
          newInvalidIntervals[i] = t(
            'presets.callingPreferencesForm.periodsCantOverlap',
          );
          newInvalidIntervals[j] = t(
            'presets.callingPreferencesForm.periodsCantOverlap',
          );
        }
      }
    }

    setInvalidIntervals(newInvalidIntervals);
  };

  useEffect(() => {
    validateIntervals();
  }, [campaignUpdate.calling_configuration?.dynamic_time_between_attempts]);

  const [campaignUpdateApiErrors, setCampaignUpdateApiErrors] =
    useCampaignUpdateApiErrors();

  useEffect(() => {
    if (
      isRandomizedIntervalInvalid ||
      Object.keys(invalidIntervals).length > 0
    ) {
      setErrors && setErrors(true);
    } else {
      setErrors && setErrors(false);
    }
  }, [validateIntervals, isRandomizedIntervalInvalid]);

  const checkUpdate = useAppSelector(checkCampaignUpdate);

  useEffect(() => {
    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      handleBackClick();
      dispatch(getCampaignByIdAsyncThunk(campaign.id));
      dispatch(clearCampaignUpdate());
      showSuccessPopup();
    }
  });

  return (
    <FormContainer
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
      }}
    >
      <CampaignOptimizationInfoContainer>
        <InfoBlueIcon style={{ width: 40 }} />
        <div>
          <H3>
            <span style={{ fontWeight: 600 }}>
              {t('campaigns.optimizationInfo.line1')}
            </span>
            {t('campaigns.optimizationInfo.line2')}
          </H3>
        </div>
      </CampaignOptimizationInfoContainer>
      <FormRow
        label={
          <FormRowTitle
            title={t('processes.form.general.guarantedLines')}
            tooltip={t('processes.form.general.guarantedLinesTooltip')}
          />
        }
        isInvalid={campaignUpdateApiErrors.guaranteed_lines ? true : false}
        error={campaignUpdateApiErrors.guaranteed_lines}
      >
        <EuiFieldNumber
          value={campaignUpdate.guaranteed_lines}
          onChange={(e) => {
            setCampaignUpdate({
              ...campaignUpdate,
              guaranteed_lines: Number(e.target.value),
            });
            campaignUpdateApiErrors.guaranteed_lines &&
              setCampaignUpdateApiErrors({
                ...campaignUpdateApiErrors,
                guaranteed_lines: undefined,
              });
          }}
          append={
            <EuiToolTip content="">
              <EuiText>max {currentStrategyLines}</EuiText>
            </EuiToolTip>
          }
          max={currentStrategyLines}
          min={0}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('processes.form.general.dynamicLinesUsage')}
            tooltip={t('processes.form.general.dynamicLinesUsageTooltip')}
          />
        }
        isInvalid={campaignUpdateApiErrors.use_idle_lines ? true : false}
        error={campaignUpdateApiErrors.use_idle_lines}
      >
        <EuiRadioGroup
          options={dynamigLinesUsageOptions}
          idSelected={campaignUpdate.use_idle_lines ? '1' : '0'}
          onChange={(id) =>
            setCampaignUpdate({
              ...campaignUpdate,
              use_idle_lines: id === '1' ? true : false,
            })
          }
          name="dynamic-lines-usage"
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.callAttempts')}
            tooltip={t('presets.callingPreferencesForm.callAttemptsTooltip')}
          />
        }
        isInvalid={campaignUpdateApiErrors.call_attempts ? true : false}
        error={campaignUpdateApiErrors.call_attempts}
      >
        <EuiFieldNumber
          value={campaignUpdate.calling_configuration?.call_attempts}
          onChange={(e) => {
            setCampaignUpdate((currentCampaign) => ({
              ...currentCampaign,
              calling_configuration: {
                ...currentCampaign.calling_configuration,
                call_attempts: Number(e.target.value),
              },
            }));

            campaignUpdateApiErrors.call_attempts &&
              setCampaignUpdateApiErrors({
                ...campaignUpdateApiErrors,
                call_attempts: undefined,
              });
          }}
          min={1}
          max={100}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.dailyCallAttempts')}
            tooltip={t(
              'presets.callingPreferencesForm.dailyCallAttemptsTooltip',
            )}
          />
        }
        isInvalid={campaignUpdateApiErrors.call_attempts_per_day ? true : false}
        error={campaignUpdateApiErrors.call_attempts_per_day}
      >
        <EuiFieldNumber
          value={campaignUpdate.calling_configuration?.call_attempts_per_day}
          onChange={(e) => {
            setCampaignUpdate((currentCampaign) => ({
              ...currentCampaign,
              calling_configuration: {
                ...currentCampaign.calling_configuration,
                call_attempts_per_day: Number(e.target.value),
              },
            }));
            campaignUpdateApiErrors.call_attempts_per_day &&
              setCampaignUpdateApiErrors({
                ...campaignUpdateApiErrors,
                call_attempts_per_day: undefined,
              });
          }}
          min={1}
          max={100}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.restDays')}
            tooltip={t('presets.callingPreferencesForm.restDaysTooltip')}
          />
        }
        isInvalid={
          campaignUpdateApiErrors.rest_days_after_call_attempts_per_day_reached
            ? true
            : false
        }
        error={
          campaignUpdateApiErrors.rest_days_after_call_attempts_per_day_reached
        }
      >
        <EuiFieldNumber
          value={
            campaignUpdate.calling_configuration
              ?.rest_days_after_call_attempts_per_day_reached
          }
          onChange={(e) => {
            setCampaignUpdate((currentCampaign) => ({
              ...currentCampaign,
              calling_configuration: {
                ...currentCampaign.calling_configuration,
                rest_days_after_call_attempts_per_day_reached: Number(
                  e.target.value,
                ),
              },
            }));

            campaignUpdateApiErrors.rest_days_after_call_attempts_per_day_reached &&
              setCampaignUpdateApiErrors({
                ...campaignUpdateApiErrors,
                rest_days_after_call_attempts_per_day_reached: undefined,
              });
          }}
          min={0}
          max={100}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.intervalBetweenAttempts')}
            tooltip={t(
              'presets.callingPreferencesForm.intervalBetweenAttemptsTooltip',
            )}
          />
        }
        isInvalid={
          campaignUpdateApiErrors.time_between_attempts_mode ? true : false
        }
        error={campaignUpdateApiErrors.time_between_attempts_mode}
      >
        <EuiSuperSelect
          options={intervalsOptions}
          valueOfSelected={
            campaignUpdate.calling_configuration?.time_between_attempts_mode
          }
          onChange={handleIntervalModeChange}
        />
      </FormRow>

      <CustomDatepickerStyle />

      {campaignUpdate.calling_configuration?.time_between_attempts_mode ===
        'STATIC' && (
        <FormRow
          label={
            <FormRowTitle
              title={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSeconds',
              )}
              tooltip={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSecondsTooltip',
              )}
            />
          }
          isInvalid={
            campaignUpdateApiErrors.time_between_attempts_seconds ? true : false
          }
          error={campaignUpdateApiErrors.time_between_attempts_seconds}
        >
          <EuiDatePicker
            selected={timeBetweenAttempts}
            onChange={handleTimeBetweenAttemptsChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat={'HH:mm'}
            timeFormat={'HH:mm'}
            minTime={moment().hours(0).minutes(30)}
            maxTime={moment().hours(4).minutes(0)}
          />
        </FormRow>
      )}

      {campaignUpdate.calling_configuration?.time_between_attempts_mode ===
        'RANDOMIZED' &&
        campaignUpdate.calling_configuration
          ?.randomized_time_between_attempts && (
          <RandomizedContainer>
            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.minimal')}</H3>}
              style={{ width: 150 }}
              isInvalid={isRandomizedIntervalInvalid}
            >
              <EuiDatePicker
                isInvalid={isRandomizedIntervalInvalid}
                selected={secondsToMoment(
                  campaignUpdate.calling_configuration
                    ?.randomized_time_between_attempts
                    .min_time_between_attempts_seconds,
                )}
                onChange={(date) =>
                  handleRandomizedTimeBetweenAttemptsChange('min', date)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </EuiFormRow>

            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.maximal')}</H3>}
              style={{ width: 150, marginTop: -2 }}
              isInvalid={isRandomizedIntervalInvalid}
            >
              <EuiDatePicker
                isInvalid={isRandomizedIntervalInvalid}
                selected={secondsToMoment(
                  campaignUpdate.calling_configuration
                    ?.randomized_time_between_attempts
                    .max_time_between_attempts_seconds,
                )}
                onChange={(date) =>
                  handleRandomizedTimeBetweenAttemptsChange('max', date)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </EuiFormRow>

            {isRandomizedIntervalInvalid && (
              <RandomizedErrorContainer>
                <H4 style={{ color: '#BA2525' }}>
                  {t('presets.callingPreferencesForm.minimalError')}
                </H4>
              </RandomizedErrorContainer>
            )}
          </RandomizedContainer>
        )}

      {campaignUpdate.calling_configuration?.time_between_attempts_mode ===
        'DYNAMIC' &&
        campaignUpdate.calling_configuration?.dynamic_time_between_attempts && (
          <DynamicContainer>
            <DynamicTitleContainer>
              <H3>{t('presets.callingPreferencesForm.timeRange')}</H3>
              <div style={{ display: 'flex', gap: 8 }}>
                <H3>{`Interval (hh:mm)`}</H3>
                <EuiToolTip
                  position="top"
                  content={
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {t('presets.callingPreferencesForm.timeRangeTooltip')}
                    </div>
                  }
                >
                  <InfoIcon $pointer />
                </EuiToolTip>
              </div>
            </DynamicTitleContainer>
            {campaignUpdate.calling_configuration.dynamic_time_between_attempts.map(
              (interval, index) => (
                <DynamicContentContainer
                  key={`dynamic-time-between-attempts-${index}`}
                >
                  <div style={{ width: 220 }}>
                    <EuiDatePickerRange
                      isInvalid={!!invalidIntervals[index]}
                      startDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_start_seconds,
                          )}
                          onChange={(date) =>
                            handleTimeChange(
                              index,
                              'interval_start_seconds',
                              date,
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      }
                      endDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_end_seconds,
                          )}
                          onChange={(date) =>
                            handleTimeChange(
                              index,
                              'interval_end_seconds',
                              date,
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      }
                    />
                    {invalidIntervals[index] && (
                      <H4
                        style={{
                          color: '#BA2525',
                          marginTop: 5,
                          marginLeft: 2,
                        }}
                      >
                        {invalidIntervals[index]}
                      </H4>
                    )}
                  </div>
                  <div style={{ width: 100 }}>
                    <EuiDatePicker
                      selected={secondsToMoment(
                        interval.time_between_attempts_seconds,
                      )}
                      onChange={(date) =>
                        handleTimeChange(
                          index,
                          'time_between_attempts_seconds',
                          date,
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      minTime={moment().hours(0).minutes(30)}
                      maxTime={moment().hours(4).minutes(0)}
                    />
                  </div>

                  {index > 0 && (
                    <DeleteIntervalIcon>
                      <MinusInCircleIcon
                        $pointer
                        onClick={() => removeDynamicTime(index)}
                      />
                    </DeleteIntervalIcon>
                  )}
                </DynamicContentContainer>
              ),
            )}

            <DynamicButtonContainer>
              <Button
                $size={'S'}
                $styleType="NORMAL"
                type={'button'}
                onClick={addDynamicTime}
              >
                {t('presets.callingPreferencesForm.addPeriod')}
              </Button>
            </DynamicButtonContainer>

            <FormRow
              label={
                <FormRowTitle
                  title={t('presets.callingPreferencesForm.basicInterval')}
                  tooltip={t(
                    'presets.callingPreferencesForm.basicIntervalTooltip',
                  )}
                />
              }
            >
              <EuiDatePicker
                selected={timeBetweenAttempts}
                onChange={handleTimeBetweenAttemptsChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </FormRow>
          </DynamicContainer>
        )}
    </FormContainer>
  );
};

export default CampaignOptimizationForm;

import React from 'react';
import {
  TenantFormContainer,
  TenantSlugContainer,
  TenantsDeleteAccountContainer,
  TenantsGlobalSettingsContainer,
} from '../Tenants.style';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { EuiFieldNumber, EuiFieldText, EuiTextArea } from '@elastic/eui';
import { t } from 'i18next';
import { H2, H3 } from '../../../../../../App.style';
import FormRowTitle from '../../../../../processes/forms/FormRowTitle';
import {
  Company,
  CompanyCreateRequest,
} from '../../../../../../../generated/public/Api';
import { ApiErrorExtended } from '../../../../../../../common/types';

interface TenantGeneralForm {
  tenant: CompanyCreateRequest;
  setTenant: React.Dispatch<React.SetStateAction<CompanyCreateRequest>>;
  tenantApiErrors: ApiErrorExtended<Company>;
  setTenantApiErrors: React.Dispatch<
    React.SetStateAction<ApiErrorExtended<Company>>
  >;
  createNew?: boolean;
  detailedView?: boolean;
  openDeleteTenantModal?: () => void;
}

const TenantGeneralForm: React.FC<TenantGeneralForm> = ({
  tenant,
  setTenant,
  createNew,
  detailedView,
  tenantApiErrors,
  setTenantApiErrors,
  openDeleteTenantModal,
}) => {
  return (
    <TenantFormContainer>
      <FormRow
        label={<H3>{t('superAdminPanel.tenants.form.name')}</H3>}
        isInvalid={tenantApiErrors.name ? true : false}
        error={tenantApiErrors.name}
      >
        {!createNew ? (
          <H3>{tenant.name}</H3>
        ) : (
          <EuiFieldText
            readOnly={!createNew}
            value={tenant.name}
            isInvalid={tenantApiErrors.name ? true : false}
            onChange={(e) => {
              setTenant({ ...tenant, name: e.target.value });

              tenantApiErrors.name &&
                setTenantApiErrors({ ...tenantApiErrors, name: undefined });
            }}
          />
        )}
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title="Slug"
            tooltip={t('superAdminPanel.tenants.form.slugTooltip')}
          />
        }
        isInvalid={tenantApiErrors.slug ? true : false}
        error={tenantApiErrors.slug}
      >
        <TenantSlugContainer>
          {!createNew ? (
            <H3>{tenant.slug}.lekta.lease.pl</H3>
          ) : (
            <>
              <EuiFieldText
                readOnly={!createNew}
                isInvalid={tenantApiErrors.slug ? true : false}
                style={{ width: 150 }}
                value={tenant.slug}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[a-z0-9]*$/;
                  if (value === '' || regex.test(value)) {
                    setTenant({ ...tenant, slug: e.target.value });

                    tenantApiErrors.slug &&
                      setTenantApiErrors({
                        ...tenantApiErrors,
                        slug: undefined,
                      });
                  }
                }}
              />

              <H3
                style={{
                  position: 'absolute',
                  left: 158,
                }}
              >
                .lekta.lease.pl
              </H3>
            </>
          )}
        </TenantSlugContainer>
      </FormRow>

      <FormRow
        label={<H3>{t('superAdminPanel.tenants.form.lines')}</H3>}
        isInvalid={tenantApiErrors.available_lines ? true : false}
        error={tenantApiErrors.available_lines}
      >
        <EuiFieldNumber
          readOnly={detailedView}
          min={1}
          value={tenant.available_lines}
          onChange={(e) => {
            setTenant({ ...tenant, available_lines: Number(e.target.value) });

            tenantApiErrors.available_lines &&
              setTenantApiErrors({
                ...tenantApiErrors,
                available_lines: undefined,
              });
          }}
        />
      </FormRow>

      <TenantsGlobalSettingsContainer>
        <FormRow
          label={
            <div
              style={{
                display: 'flex',
                gap: 8,
                textAlign: 'center',
                marginBottom: 8,
              }}
            >
              <H2>{t('superAdminPanel.tenants.form.biznesOwnerDetails')}</H2>
            </div>
          }
          isInvalid={tenantApiErrors.details ? true : false}
          error={tenantApiErrors.details}
        >
          <EuiTextArea
            readOnly={detailedView}
            value={tenant.details}
            isInvalid={tenantApiErrors.details ? true : false}
            onChange={(e) => {
              setTenant({ ...tenant, details: e.target.value });

              tenantApiErrors.details &&
                setTenantApiErrors({
                  ...tenantApiErrors,
                  details: undefined,
                });
            }}
            style={{ minHeight: 100 }}
          />
        </FormRow>
      </TenantsGlobalSettingsContainer>

      {!createNew && (
        <TenantsDeleteAccountContainer>
          <H2 $medium>{t('superAdminPanel.tenants.form.delete')}</H2>
          <H3 onClick={openDeleteTenantModal}>
            {t('superAdminPanel.tenants.form.deleteThisTenant')}
          </H3>
        </TenantsDeleteAccountContainer>
      )}
    </TenantFormContainer>
  );
};

export default TenantGeneralForm;

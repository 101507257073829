import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../common/hooks';
import {
  VoiceConfiguration,
  VoiceConfigurationRequest,
} from '../../../../generated/tenants/Api';
import { CreatePresetContainer } from '../PresetsPanel.style';
import {
  asrVendors,
  genders,
  languages,
  ttsVendors,
} from './api/voiceConfigurationApi';
import { createVoiceConfigurationAsyncThunk } from './api/voiceConfigurationSlice';
import VoiceForm from './VoiceForm';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface CreateVoiceProps {
  voice?: VoiceConfiguration;
  strategy: string;
  backToList: () => void;
  handleNewVoiceChange?: () => void;
  formButtonDown?: boolean;
}

const CreateVoice: React.FC<CreateVoiceProps> = ({
  strategy,
  backToList,
  voice,
  handleNewVoiceChange,
  formButtonDown,
}) => {
  const dispatch = useAppDispatch();
  const [newVoice, setNewVoice] = useState<VoiceConfigurationRequest>({
    strategy: strategy,
    name: '',
    language: languages[0],
    gender: genders[0],
    base_tts_speed_percentage: 100,
    asr_vendor: asrVendors[0],
    tts_vendor: ttsVendors[0],
    voice_name: '',
  });

  useEffect(() => {
    if (voice) setNewVoice({ ...voice, name: `${voice.name}-modified` });
  }, [voice]);

  const handleAddVoiceClick = () => {
    setIsLoadingVisible(true);
    dispatch(createVoiceConfigurationAsyncThunk(newVoice)).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <CreatePresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <VoiceForm
        voice={newVoice}
        setVoice={setNewVoice}
        button={handleAddVoiceClick}
        createNew
        back={backToList}
        handleNewVoiceChange={handleNewVoiceChange}
        createForm
        buttonDown={formButtonDown}
      />
    </CreatePresetContainer>
  );
};

export default CreateVoice;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const DialogueSphereIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <circle cx="6" cy="6" r="6" fill="#0F172A" />
  </StyledSvg>
);

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { H1, H3 } from '../../../App.style';
import {
  PanelSidebarContainer,
  SidebarSectionContainer,
} from '../Settings.style';
import { ChevronDownIcon } from '../../../../resources/icons-new/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../resources/icons-new/ChevronUpIcon';
import { useTranslation } from 'react-i18next';

const UserPanelSidebar = () => {
  const { t } = useTranslation();
  const [openSections, setOpenSections] = useState<number[]>([]);
  const location = useLocation();
  const navigate = useNavigate(); // replace useHistory with useNavigate

  const links = [
    {
      label: t('settings.users.sidebar.profile'),
      path: '/dashboard/user_settings/profile',
      subLinks: [],
    },
    {
      label: t('settings.users.sidebar.password'),
      path: '/dashboard/user_settings/password',
      subLinks: [],
    },
    {
      label: t('settings.users.sidebar.language'),
      path: '/dashboard/user_settings/language',
      subLinks: [],
    },
    {
      label: t('settings.users.sidebar.notifications'),
      path: '/dashboard/user_settings/notifications',
      subLinks: [],
    },
  ];

  const toggleSection = (index: number, path: string | null) => {
    if (path && links[index].subLinks.length === 0) {
      navigate(path); // navigate to the path using navigate
      return;
    }

    setOpenSections((prevOpenSections) => {
      const isSectionOpen = prevOpenSections.includes(index);
      return isSectionOpen
        ? prevOpenSections.filter((sectionIndex) => sectionIndex !== index)
        : [...prevOpenSections, index];
    });
  };

  return (
    <PanelSidebarContainer>
      <div
        style={{
          width: '100%',
          paddingLeft: 24,
          marginTop: 10,
        }}
      >
        <H1 $medium>{t('settings.users.sidebar.title')}</H1>
      </div>
      {links.map((section, index) => (
        <div key={index} style={{ width: '100%', paddingLeft: 20 }}>
          <SidebarSectionContainer
            $selected={location.pathname === section.path}
            onClick={() => toggleSection(index, section.path)}
            $lightHover
          >
            <H3>{section.label}</H3>
            {section.subLinks.length > 0 &&
              (openSections.includes(index) ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              ))}
          </SidebarSectionContainer>
        </div>
      ))}
    </PanelSidebarContainer>
  );
};

export default UserPanelSidebar;

export const getRandomUUID = () => {
  return Math.floor(Math.random() * Date.now()).toString(16);
};

export interface DialogueToken {
  displayText: string;
  value: string;
  id: string;
  type:
    | 'text'
    | 'token'
    | 'text_by_bot_gender'
    | 'text_by_caller_gender'
    | 'custom'
    | 'custom_verbatim';
}

const create_dialogue_token = (
  displayText: string,
  id: string,
): DialogueToken => {
  return {
    displayText: displayText,
    value: `%_${id.toUpperCase()}`,
    type: 'token',
    id: id,
  };
};

//bot name
const token_bot_name: DialogueToken = create_dialogue_token(
  'nazwa bota',
  'botname',
);

export const botNameTokens = [token_bot_name];

//debtors name
const token_name_dat: DialogueToken = create_dialogue_token(
  'Imieniu',
  'name_dat',
);
const token_name_acc: DialogueToken = create_dialogue_token('Imię', 'name_acc');
const token_name_ins: DialogueToken = create_dialogue_token(
  'Imieniem',
  'name_ins',
);

export const nameTokens = [token_name_dat, token_name_acc, token_name_ins];

//debtors surname
const token_surname_ins: DialogueToken = create_dialogue_token(
  'Nazwiskiem',
  'surname_ins',
);

export const surnameTokens = [token_surname_ins];

//debtors phone number
const token_phonenumber: DialogueToken = create_dialogue_token(
  'Nr. Dłużnika',
  'phonenumber',
);

export const phoneNumberTokens = [
  token_phonenumber,
  //token_phonenumber_death póki co nie używamy
];

// mr/ mrs
const token_address_nom: DialogueToken = create_dialogue_token(
  'Pan/Pani',
  'address_nom',
);
const token_address_gen: DialogueToken = create_dialogue_token(
  'Pana/Pani',
  'address_gen',
);
const token_address_dat: DialogueToken = create_dialogue_token(
  'Panu/Pani',
  'address_dat',
);
const token_address_acc: DialogueToken = create_dialogue_token(
  'Pana/Panią',
  'address_acc',
);
const token_address_ins: DialogueToken = create_dialogue_token(
  'Panem/Panią',
  'address_ins',
);
export const addressTokens = [
  token_address_nom,
  token_address_gen,
  token_address_dat,
  token_address_acc,
  token_address_ins,
];

//mr/mrs + Name Surname
const token_offaddress_nom: DialogueToken = create_dialogue_token(
  'Pan Imię i Nazwisko/Pani Imię i Nazwisko',
  'offaddress_nom',
);
const token_offaddress_gen: DialogueToken = create_dialogue_token(
  'Pana Jana Kowalskiego/Panią Janine Kowalską',
  'offaddress_gen',
);
const token_offaddress_dat: DialogueToken = create_dialogue_token(
  'Panu Janowi Kowalskiemu/Pani Janine Kowalskiej',
  'offaddress_dat',
);
const token_offaddress_ins: DialogueToken = create_dialogue_token(
  'Panem Janem Kowalskim/Panią Janiną Kowalską',
  'offaddress_ins',
);

//missing
export const offaddressTokens = [
  token_offaddress_nom,
  token_offaddress_gen,
  token_offaddress_dat,
  token_offaddress_ins,
];

//break
// const token_break: Token = create_dialogue_token('PAUZA 200ms', 'break', 'pink');
const token_break_100: DialogueToken = create_dialogue_token(
  'Pauza 100ms',
  'break_100',
);
const token_break_200: DialogueToken = create_dialogue_token(
  'Pauza 200ms',
  'break_200',
);
const token_break_500: DialogueToken = create_dialogue_token(
  'Pauza 500ms',
  'break_500',
);
const token_break_5000: DialogueToken = create_dialogue_token(
  'Pauza 5000ms',
  'break_5000',
);

export const breakTokens = [
  token_break_100,
  token_break_200,
  token_break_500,
  token_break_5000,
];

//company name
// const token_companyname: Token = create_dialogue_token('Firma', 'companyname', 'pink');
const token_companyname_nom: DialogueToken = create_dialogue_token(
  'Firma',
  'companyname_nom',
);
const token_companyname_gen: DialogueToken = create_dialogue_token(
  'Firmy',
  'companyname_gen',
);
const token_companyname_acc: DialogueToken = create_dialogue_token(
  'Firmę',
  'companyname_acc',
);
const token_companyname_loc: DialogueToken = create_dialogue_token(
  'Firmie',
  'companyname_loc',
);

export const companynameTokens = [
  token_companyname_nom,
  token_companyname_gen,
  token_companyname_acc,
  token_companyname_loc,
];

//company phone number
const token_companynumber: DialogueToken = create_dialogue_token(
  'Nr. telefonu firmy',
  'companynumber',
);

export const companyPhoneNumberToken = [token_companynumber];

const token_companynumber_80: DialogueToken = create_dialogue_token(
  'Nr. telefonu firmy dyktowany powoli',
  'companynumber_80/200',
);

export const slowedCompanyPhoneNumberToken = [token_companynumber_80];

const token_currentdebtamount_nom: DialogueToken = create_dialogue_token(
  'kwota długu słownie ',
  'currentdebtamount_nom',
);
const token_currentdebtamount_gen: DialogueToken = create_dialogue_token(
  'kwocie długu słownie ',
  'currentdebtamount_gen',
);
const token_currentdebtamount_tostring: DialogueToken = create_dialogue_token(
  '1000.00 PLN',
  'currentdebtamount_tostring',
);

export const currentDebtAmountTokens = [
  // token_currentdebtamount, // default nie używany póki co
  token_currentdebtamount_nom,
  token_currentdebtamount_gen,
  token_currentdebtamount_tostring,
];

//Deadline min
const token_deadlinemin: DialogueToken = create_dialogue_token(
  'proponowany termin spłaty długu',
  'deadlinemin',
);
const token_deadlinemin_gen: DialogueToken = create_dialogue_token(
  'proponowanego terminu spłaty dlugu',
  'deadlinemin_gen',
);
export const deadlineMinTokens = [token_deadlinemin, token_deadlinemin_gen];

//Deadline max
// const token_deadlinemax: Token = create_dialogue_token(
//   'ostateczny termin spłaty długu',
//   'deadlinemax',
//
// );
const token_deadlinemax_nom: DialogueToken = create_dialogue_token(
  'ostateczny termin spłaty długu',
  'deadlinemax_nom',
);
const token_deadlinemax_gen: DialogueToken = create_dialogue_token(
  'ostatecznego terminu spłaty długu',
  'deadlinemax_gen',
);

export const deadlineMaxTokens = [
  // token_deadlinemax, // póki co default nie używany
  token_deadlinemax_nom,
  token_deadlinemax_gen,
];

//Due date
const token_duedate: DialogueToken = create_dialogue_token(
  'Data do której powinna być dokonana płatność',
  'duedate',
);
const token_duedate_gen: DialogueToken = create_dialogue_token(
  'Daty do której powinna być dokonana płatnośc',
  'duedate_gen',
);
export const dueDateTokens = [token_duedate, token_duedate_gen];

//Future payment date
const token_futurepaymentdate_nom: DialogueToken = create_dialogue_token(
  'proponowana data spłaty długu przez dłużnika',
  'futurepaymentdate_nom',
);

const token_futurepaymentdate_gen: DialogueToken = create_dialogue_token(
  'proponowanej daty spłaty długu',
  'futurepaymentdate_gen',
);

export const futurePaymentDateTokens = [
  token_futurepaymentdate_nom,
  token_futurepaymentdate_gen,
];

//Initial debt amount
// const token_initialdebtamount: Token = create_dialogue_token(
//   'kwota zadłużenia cyfrowo',
//   'initialdebtamount',
//
// );

const token_initialdebtamount_nom: DialogueToken = create_dialogue_token(
  'kwota zadłużenia cyfrowo',
  'initialdebtamount_nom',
);
const token_initialdebtamount_gen: DialogueToken = create_dialogue_token(
  'kwota zadłużenia słownie',
  'initialdebtamount_gen',
);
const token_initialdebtamount_tostring: DialogueToken = create_dialogue_token(
  'kwota zadłużenia cyfrowo',
  'initialdebtamount_tostring',
);

export const initialDebtAmountTokens = [
  // token_initialdebtamount, default póki co wywalamy
  token_initialdebtamount_nom,
  token_initialdebtamount_gen,
  token_initialdebtamount_tostring,
];

//Payment date -> UserInvoiceData from ICD
const token_invoicedate_nom: DialogueToken = create_dialogue_token(
  'Termin płatności',
  'invoicedate_nom',
);

const token_invoicedate_gen: DialogueToken = create_dialogue_token(
  'Terminu płatności',
  'invoicedate_gen',
);

export const invoiceDateTokens = [token_invoicedate_nom, token_invoicedate_gen];

//License numbers
const token_licensenumbers: DialogueToken = create_dialogue_token(
  'Nr. rejestracyjny samochodu',
  'licesnsenumbers',
);

export const licenseNumbersToken = [token_licensenumbers];

//Loan holder name
// const token_loanholdername: Token = create_dialogue_token(
//   'Nazwa podmiotu wobec którego jest dług',
//   'loanholdername',
//
// );
const token_loanholdername_nom: DialogueToken = create_dialogue_token(
  'Nazwa podmiotu',
  'loanholdername_nom',
);

export const loanHolderNameTokens = [
  // token_loanholdername, //póki co nie używamy default
  token_loanholdername_nom,
];

//Past payment amount -> Kwota płątności przeszłej, która zadeklarował użytkownik
const token_pastpaymentamount_gen: DialogueToken = create_dialogue_token(
  'zadeklarowana kwota płatności przeszłej',
  'pastpaymentamount_gen',
);

export const pastPaymentAmountToken = [token_pastpaymentamount_gen];

//Id produktu
const token_productid: DialogueToken = create_dialogue_token(
  'Identyfikator produktu',
  'productid',
);

export const productIdTokens = [token_productid];

//Product name
const token_productname_nom: DialogueToken = create_dialogue_token(
  'produkt',
  'productname_nom',
);
const token_productname_gen: DialogueToken = create_dialogue_token(
  'produktu',
  'productname_gen',
);
const token_productname_acc: DialogueToken = create_dialogue_token(
  'produkt',
  'productname_acc',
);
const token_productname_loc: DialogueToken = create_dialogue_token(
  'produkcie',
  'productname_loc',
);

export const productNameTokens = [
  token_productname_nom,
  token_productname_gen,
  token_productname_acc,
  token_productname_loc,
];

//all tokens in one array
const allBotTokens: DialogueToken[] = [
  token_bot_name,
  token_name_dat,
  token_name_acc,
  token_name_ins,
  token_surname_ins,
  token_phonenumber,
  // token_phonenumber_death,
  token_address_nom,
  token_address_gen,
  token_address_dat,
  token_address_acc,
  token_address_ins,
  token_offaddress_nom,
  token_offaddress_gen,
  token_offaddress_dat,
  token_offaddress_ins,
  // token_break,
  token_break_100,
  token_break_200,
  token_break_500,
  token_break_5000,
  // token_companyname,
  token_companyname_nom,
  token_companyname_gen,
  token_companyname_acc,
  token_companyname_loc,
  token_companynumber,
  token_companynumber_80,
  // token_currentdebtamount,
  token_currentdebtamount_nom,
  token_currentdebtamount_gen,
  token_currentdebtamount_tostring,
  token_deadlinemin,
  token_deadlinemin_gen,
  // token_deadlinemax,
  token_deadlinemax_nom,
  token_deadlinemax_gen,
  token_duedate,
  token_duedate_gen,
  token_futurepaymentdate_nom,
  token_futurepaymentdate_gen,
  // token_initialdebtamount,
  token_initialdebtamount_nom,
  token_initialdebtamount_gen,
  token_initialdebtamount_tostring,
  token_invoicedate_nom,
  token_invoicedate_gen,
  token_licensenumbers,
  // token_loanholdername,
  token_loanholdername_nom,
  token_pastpaymentamount_gen,
  token_productid,
  token_productname_nom,
  token_productname_gen,
  token_productname_acc,
  token_productname_loc,
];

const identificationStartConversationTokens: DialogueToken[] = [
  token_offaddress_ins,
];

const identificationNotADebtorTokens: DialogueToken[] = [token_offaddress_dat];

export interface TokensArray {
  text: string;
  tokens: DialogueToken[];
}

//new tokens here
export const dialogueTokensArr: TokensArray[] = [
  {
    text: 'nazwa bota',
    tokens: botNameTokens,
  },
  {
    text: 'imię dłużnika',
    tokens: nameTokens,
  },
  {
    text: 'nazwisko dłużnika',
    tokens: surnameTokens,
  },
  {
    text: 'nr. tel dłużnika',
    tokens: phoneNumberTokens,
  },
  {
    text: 'forma grzecznościowa',
    tokens: addressTokens,
  },
  {
    text: 'forma grzecznościowa + Imie Nazwisko',
    tokens: offaddressTokens,
  },
  {
    text: 'nazwa firmy',
    tokens: companynameTokens,
  },
  {
    text: 'nr. tel firmy',
    tokens: companyPhoneNumberToken,
  },
  {
    text: 'kwota długu',
    tokens: currentDebtAmountTokens,
  },
  {
    text: 'proponowany termin spłaty długu',
    tokens: deadlineMinTokens,
  },
  {
    text: 'ostateczny termin spłaty długu',
    tokens: deadlineMaxTokens,
  },
  {
    text: 'data do której powinna by dokonana płatność',
    tokens: dueDateTokens,
  },
  {
    text: 'data spłaty długu, zaproponowana przez użytkownika',
    tokens: futurePaymentDateTokens,
  },
  {
    text: 'kwota zadłużenia',
    tokens: initialDebtAmountTokens,
  },
  {
    text: 'termin płatności (UserInvoiceDate z ICD)',
    tokens: invoiceDateTokens,
  },
  {
    text: 'numer rejestracyjny',
    tokens: licenseNumbersToken,
  },
  {
    text: 'nazwa podmiotu wobec którego jest dług',
    tokens: loanHolderNameTokens,
  },
  {
    text: 'kwota płatności przeszłej, który zadeklarował użytkownik',
    tokens: pastPaymentAmountToken,
  },
  {
    text: 'id produktu, np Numer Polisy, numer ref kredytu',
    tokens: productIdTokens,
  },
  {
    text: 'nazwa produktu',
    tokens: productNameTokens,
  },
];

export const breakTokensArray: TokensArray = {
  text: '',
  tokens: breakTokens,
};

export {
  identificationNotADebtorTokens,
  identificationStartConversationTokens,
  allBotTokens,
};

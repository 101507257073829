import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../common/hooks';
import { H2, H4 } from '../../../App.style';
import {
  CallingConfiguration,
  CallingConfigurationRequest,
} from '../../../../generated/tenants/Api';
import { updateCallingConfigurationAsyncThunk } from './api/callingConfigurationSlice';
import { FormContainer } from '../../processes/forms/ProcessForm.style';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../PresetsPanel.style';
import CallingPreferenceForm from './CallingPreferenceForm';
import EditPresetButtons from '../shared/EditPresetButtons';
import AssociatedProcesses from '../shared/AssociatedProcesses';
import CallingPreferenceFormReadOnly from './CallingPreferenceFormReadOnly';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface DetailedCallingPreferenceProps {
  callingPreference: CallingConfiguration;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  hasPermission: boolean;
}

const DetailedCallingPreference: React.FC<DetailedCallingPreferenceProps> = ({
  callingPreference,
  backToList,
  findProcessNamesById,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(callingPreference.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(callingPreference.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editCallingPreference, setEditCallingPreference] =
    useState<CallingConfigurationRequest>({
      strategy: '',
      name: '',
      call_attempts: 5,
      call_attempts_per_day: 2,
      rest_days_after_call_attempts_per_day_reached: 10,
      time_between_attempts_seconds: 60,
    });

  useEffect(() => {
    setEditCallingPreference(callingPreference);
  }, [callingPreference]);

  const handleCallingPreferenceUpdateClick = () => {
    const id = callingPreference.id;
    if (editCallingPreference && id) {
      const args = { id: id, data: editCallingPreference };
      setIsLoadingVisible(true);
      dispatch(updateCallingConfigurationAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditCallingPreference(callingPreference);
  };

  const associatedWithProcesses = findProcessNamesById(callingPreference.id);

  const handleBackToDetailed = () => setEdit(false);

  const [errors, setErrors] = useState<boolean>(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {hasPermission && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleCallingPreferenceUpdateClick}
          hasErrors={errors}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{callingPreference.name}</H2>
            <H4 $grey>
              {t('common.created')} {formattedCreated}, {t('common.modified')}{' '}
              {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <CallingPreferenceFormReadOnly
            callingPreference={callingPreference}
          />
        </FormContainer>
      ) : (
        <CallingPreferenceForm
          callingPreference={editCallingPreference}
          setCallingPreference={setEditCallingPreference}
          backToList={backToList}
          backToDetailed={handleBackToDetailed}
          setErrors={setErrors}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default DetailedCallingPreference;

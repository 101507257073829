import React, { useEffect, useState } from 'react';
import { H4 } from '../../../../App.style';
import { EuiSuperSelect } from '@elastic/eui';

interface DialogueActionSelectProps {
  value?: string;
  onChange?: (newValue: string) => void;
  staticStep?:
    | 'IDENTIFICATION'
    | 'VERIFICATION'
    | 'PAYMENT_STATUS'
    | 'NEGOTIATIONS';
}

const DialogueActionSelect: React.FC<DialogueActionSelectProps> = ({
  value,
  onChange,
  staticStep,
}) => {
  const [firstSelectValue, setFirstSelectValue] = useState<'step' | 'module'>(
    'step',
  );
  const [secondSelectValue, setSecondSelectValue] = useState<string>('');

  useEffect(() => {
    if (value) {
      setFirstSelectValue('module');
      setSecondSelectValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (staticStep) {
      setFirstSelectValue('step');
      setSecondSelectValue(staticStep);
    }
  }, [staticStep]);

  const firstSelectOptions = [
    { value: 'step', inputDisplay: 'step' },
    { value: 'module', inputDisplay: 'module' },
    { value: 'actions', inputDisplay: 'action', disabled: true },
  ];

  const secondSelectOptionsMap = {
    step: [
      { value: 'IDENTIFICATION', inputDisplay: '1.Identification' },
      { value: 'VERIFICATION', inputDisplay: '2.Verification' },
      { value: 'PAYMENT_STATUS', inputDisplay: '3.Payment status' },
      { value: 'NEGOTIATIONS', inputDisplay: '4.Negotiations' },
    ],
    module: [
      { value: 'INFORM_PHONE_NUMBER', inputDisplay: 'INFORM_PHONE_NUMBER' }, //Podaje nr telefonu do kontaktu do firmy którą reprezentujemy
      { value: 'CALLBACK_NUMBER', inputDisplay: 'CALLBACK_NUMBER' }, // Bot prosi o podanie numeru do kontaktu
      { value: 'CALLBACK_DATE_TIME', inputDisplay: 'CALLBACK_DATE_TIME' }, //Przelozenie kontaktu na inny termin
      { value: 'GOODBYE', inputDisplay: 'GOODBYE' }, // pożegnanie
      { value: 'MAY_WE_CONTINUE', inputDisplay: 'MAY_WE_CONTINUE' }, //Kontynuacja rozmowy
      { value: 'REDIRECT_TO_HUMAN', inputDisplay: 'REDIRECT_TO_HUMAN' }, // Przekierowanie do konsultanta
      { value: 'SEND_SMS', inputDisplay: 'SEND_SMS' }, // Odpowiada za wysyłke smsów
    ],
  };

  const handleFirstSelectChange = (action: 'step' | 'module' | 'action') => {
    if (action === 'step') {
      setFirstSelectValue('step');
      setSecondSelectValue('IDENTIFICATION');
    } else if (action === 'module') {
      setFirstSelectValue('module');
      setSecondSelectValue('INFORM_PHONE_NUMBER');
    } else {
      alert('akcje?');
    }
  };

  const isDisabled = staticStep ? true : !onChange ? true : false;
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: 8, paddingTop: 8 }}
    >
      <H4>will be activated</H4>
      <div>
        <EuiSuperSelect
          options={firstSelectOptions}
          valueOfSelected={firstSelectValue}
          onChange={(value) => {
            handleFirstSelectChange(value as 'step' | 'module' | 'action');
          }}
          compressed
          aria-label="Select first option"
          readOnly={isDisabled}
        />
      </div>
      <div style={{ minWidth: isDisabled ? '0px' : '150px' }}>
        <EuiSuperSelect
          options={secondSelectOptionsMap[firstSelectValue]}
          valueOfSelected={secondSelectValue}
          onChange={(value) => {
            setSecondSelectValue(value);
          }}
          compressed
          aria-label="Select second option"
          readOnly={isDisabled}
        />
      </div>
    </div>
  );
};

export default DialogueActionSelect;

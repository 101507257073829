import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  BLACK_typography,
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_30,
  COLOR_NEUTRAL_50,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY_3,
} from '../../App.style';

export const SettingsPanelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  background: ${COLOR_NEUTRAL_10};
`;

export const TenantSettingsContentContainer = styled.div`
  width: calc(100% - 180px);
  height: 100vh;

  border-radius: 5px;

  background: ${COLOR_NEUTRAL_20};

  display: flex;
  justify-content: center;
`;

export const UserSettingsContentContainer = styled.div`
  width: calc(100% - 190px);
  height: 100vh;

  border-radius: 5px;

  background: ${COLOR_NEUTRAL_20};

  display: flex;
  justify-content: center;
`;

export const PanelSidebarContainer = styled.div<{ $superAdmin?: boolean }>`
  width: 175px;
  height: 100vh;

  background: ${(props) =>
    props.$superAdmin ? COLOR_PRIMARY_3 : COLOR_NEUTRAL_10};
  color: ${BLACK_typography};
  padding: 10px 0px;

  border-right: 1px solid ${COLOR_NEUTRAL_20};

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

export const TenantSlugSidebarContainer = styled.div`
  width: 90%;

  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const SidebarSectionContainer = styled.div<SidebarLinkContainerProps>`
  width: 100%;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.$selected && COLOR_NEUTRAL_50};
  color: ${(props) =>
    props.$lightHover
      ? BLACK_typography
      : props.$selected
        ? 'white'
        : props.$superAdmin && 'white'};

  border-radius: 5px;
  padding: 0px 8px;
  gap: 8px;
  height: 50px;

  &:hover,
  &:hover * {
    background: ${(props) =>
      props.$selected
        ? COLOR_NEUTRAL_50
        : props.$lightHover
          ? COLOR_NEUTRAL_30
          : COLOR_NEUTRAL_80};

    color: ${(props) => (props.$lightHover ? BLACK_typography : 'white')};
  }
`;

export const SubLinksContainer = styled.div`
  width: 100%;
  padding-left: 10px;
`;

interface SidebarLinkContainerProps {
  $selected?: boolean;
  $superAdmin?: boolean;
  $lightHover?: boolean;
}

export const SidebarLinkContainer = styled(Link)<SidebarLinkContainerProps>`
  width: 100%;

  margin-bottom: 5px;
  min-height: 35px;
  padding: 5px;

  border-radius: 5px;

  color: ${(props) =>
    props.$lightHover
      ? BLACK_typography
      : props.$selected
        ? 'white'
        : props.$superAdmin
          ? 'white'
          : BLACK_typography};

  background: ${(props) => (props.$selected ? COLOR_NEUTRAL_50 : '')};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &:hover,
  &:hover * {
    background: ${(props) =>
      props.$selected
        ? COLOR_NEUTRAL_50
        : props.$lightHover
          ? COLOR_NEUTRAL_30
          : COLOR_NEUTRAL_80};
    color: ${(props) => (props.$lightHover ? BLACK_typography : 'white')};
    border-radius: 5px;
  }
`;

export const LanguageOptions = styled.div<{ $show: boolean }>`
  position: absolute;
  left: 120px;
  bottom: 0;
  display: ${(props) => (props.$show ? 'block' : 'none')};

  background: white;
  z-index: 1;
`;

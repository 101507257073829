import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const SaveIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => {
  return (
    <StyledSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      style={style}
      onClick={$disabled ? undefined : onClick}
      $disabled={$disabled}
      $pointer={$pointer}
    >
      <path d="M1.625 0.8125C1.17627 0.8125 0.8125 1.17627 0.8125 1.625V11.375C0.8125 11.8237 1.17627 12.1875 1.625 12.1875H11.375C11.8237 12.1875 12.1875 11.8237 12.1875 11.375V1.625C12.1875 1.17627 11.8237 0.8125 11.375 0.8125H7.71875C7.27002 0.8125 6.90625 1.17627 6.90625 1.625V7.55048L9.05649 5.40024C9.21514 5.24159 9.47236 5.24159 9.63101 5.40024C9.78966 5.55889 9.78966 5.81611 9.63101 5.97476L6.78726 8.81851C6.62861 8.97716 6.37139 8.97716 6.21274 8.81851L3.36899 5.97476C3.21034 5.81611 3.21034 5.55889 3.36899 5.40024C3.52764 5.24159 3.78486 5.24159 3.94351 5.40024L6.09375 7.55048V1.625C6.09375 0.727537 6.82129 0 7.71875 0H11.375C12.2725 0 13 0.727538 13 1.625V11.375C13 12.2725 12.2725 13 11.375 13H1.625C0.727537 13 0 12.2725 0 11.375V1.625C0 0.727537 0.727537 0 1.625 0H3.65625C3.88062 0 4.0625 0.181884 4.0625 0.40625C4.0625 0.630616 3.88062 0.8125 3.65625 0.8125H1.625Z" />
    </StyledSvg>
  );
};

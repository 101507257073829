import React, { useState } from 'react';
import { CallsFiltersContainer } from '../Calls.styles';
import { EuiPopover } from '@elastic/eui';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import CallsCallTypeFilter from './CallsCallTypeFilter';
import CallsDatesFilter from './CallsDatesFilter';
import CallsStatusesFilter from './CallsStatusesFilter';
import { EmptyFilterContainer } from '../../../panel/bar/filters/CampaignFilters.style';
import { Button, H4 } from '../../../../../App.style';
import { FilterIcon } from '../../../../../../resources/icons-new/FilterIcon';
import { useTranslation } from 'react-i18next';

export type CallStatusOption =
  | 'VOICEMAIL'
  | 'ANSWERED'
  | 'NOT_ANSWERED'
  | 'REJECTED'
  | 'BUSY'
  | 'TRANSFER'
  | 'NONEXISTENT_NUMBER'
  | 'UNMAPPED'
  | 'ERROR';

export interface CallsFiltersValues {
  search: string;
  call_type?: 'INBOUND' | 'OUTBOUND';
  date?: {
    start_date?: string;
    end_date?: string;
  };
  statuses?: CallStatusOption[];
}

interface CallsFiltersProps {
  filters: CallsFiltersValues;
  setFilters: React.Dispatch<React.SetStateAction<CallsFiltersValues>>;
}

const CallsFilters: React.FC<CallsFiltersProps> = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  //call type
  const [callTypeIsOpen, setCallTypeIsOpen] = useState(false);
  const [callTypeBarIsOpen, setCallTypeBarIsOpen] = useState(false);
  const [selectedCallType, setSelectedCallType] = useState<
    'INBOUND' | 'OUTBOUND'
  >('INBOUND');

  //dates
  const [datesIsOpen, setDatesIsOpen] = useState(false);
  const [datesBarIsOpen, setDatesBarisOpen] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState<string>('');
  const [selectedEndDate, setSelectedEndDate] = useState<string>('');

  const { start_date, end_date } = filters.date || {};

  //statuses
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [statusBarIsOpen, setStatusBarIsOpen] = useState(false);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  //search
  const setSearch = (value: string) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      search: value,
    }));
  };

  return (
    <CallsFiltersContainer>
      <div style={{ display: 'flex', gap: 8 }}>
        <EuiPopover
          isOpen={isOpen}
          closePopover={() => {
            setIsOpen(false);

            setCallTypeIsOpen(false);
            setDatesIsOpen(false);
            setStatusIsOpen(false);
          }}
          button={
            <Button
              $styleType="NORMAL"
              $size={'M'}
              onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
            >
              <FilterIcon />
              {t('campaigns.calls.filters.title')}
            </Button>
          }
          panelPaddingSize="none"
          anchorPosition="downLeft"
          hasArrow={false}
        >
          {/* processes popover */}
          <div style={{ width: '100px' }}>
            {filters.call_type === undefined && (
              <CallsCallTypeFilter
                barButton={false}
                isOpen={callTypeIsOpen}
                setIsOpen={setCallTypeIsOpen}
                selectedCallType={selectedCallType}
                setSelectedCallType={setSelectedCallType}
                filters={filters}
                setFilters={setFilters}
              />
            )}

            {(filters.statuses === undefined ||
              filters.statuses.length === 0) && (
              <CallsStatusesFilter
                barButton={false}
                isOpen={statusIsOpen}
                setIsOpen={setStatusIsOpen}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                filters={filters}
                setFilters={setFilters}
              />
            )}

            {start_date === undefined && end_date === undefined && (
              <CallsDatesFilter
                barButton={false}
                isOpen={datesIsOpen}
                setIsOpen={setDatesIsOpen}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                filters={filters}
                setFilters={setFilters}
              />
            )}

            {filters.call_type !== undefined &&
              filters.statuses !== undefined &&
              filters.statuses.length > 0 &&
              (start_date !== undefined || end_date !== undefined) && (
                <EmptyFilterContainer>
                  <H4 $grey>
                    {t('campaigns.calls.filters.allFiltersApplied')}
                  </H4>
                </EmptyFilterContainer>
              )}
          </div>
        </EuiPopover>

        {(start_date || end_date) && (
          <CallsDatesFilter
            barButton
            isOpen={datesBarIsOpen}
            setIsOpen={setDatesBarisOpen}
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            filters={filters}
            setFilters={setFilters}
          />
        )}

        {filters.call_type !== undefined && (
          <CallsCallTypeFilter
            barButton={true}
            isOpen={callTypeBarIsOpen}
            setIsOpen={setCallTypeBarIsOpen}
            selectedCallType={selectedCallType}
            setSelectedCallType={setSelectedCallType}
            filters={filters}
            setFilters={setFilters}
          />
        )}

        {filters.statuses && filters.statuses.length > 0 && (
          <CallsStatusesFilter
            barButton
            isOpen={statusBarIsOpen}
            setIsOpen={setStatusBarIsOpen}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>

      <div>
        <SearchInput value={filters.search} setValue={setSearch} />
      </div>
    </CallsFiltersContainer>
  );
};

export default CallsFilters;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const TwoChevronsLeftIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <g id="Component 68">
      <path
        id="Path (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8536 13.3536C10.6583 13.5488 10.3417 13.5488 10.1464 13.3536L4.14645 7.35355C3.95118 7.15829 3.95118 6.84171 4.14645 6.64645L10.1464 0.646446C10.3417 0.451184 10.6583 0.451184 10.8536 0.646446C11.0488 0.841709 11.0488 1.15829 10.8536 1.35355L5.20711 7L10.8536 12.6464C11.0488 12.8417 11.0488 13.1583 10.8536 13.3536Z"
      />
      <path
        id="Path (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85355 13.3536C6.65829 13.5488 6.34171 13.5488 6.14645 13.3536L0.146447 7.35355C-0.0488152 7.15829 -0.0488151 6.84171 0.146447 6.64645L6.14645 0.646446C6.34171 0.451184 6.65829 0.451184 6.85355 0.646446C7.04882 0.841709 7.04882 1.15829 6.85355 1.35355L1.20711 7L6.85355 12.6464C7.04882 12.8417 7.04882 13.1583 6.85355 13.3536Z"
      />
    </g>
  </StyledSvg>
);

import { useState, useCallback, useEffect } from 'react';

export function useAutoClosePopup(initialState = false, delay = 5000) {
  const [isVisible, setIsVisible] = useState<boolean>(initialState);

  const showPopup = useCallback(() => setIsVisible(true), []);
  const closePopup = useCallback(() => setIsVisible(false), []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, delay);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, delay]);

  return { isVisible, showPopup, closePopup };
}

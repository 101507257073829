import { useAppSelector } from '../../../common/hooks';
import { selectUser } from '../session/api/sessionSlice';
import { selectPermissions } from './api/userSlice';

type UserPermissionType =
  | 'superadmin'
  | 'supervisor'
  | 'tenant_owner'
  | 'tenant_admin'
  | 'workspace_admin'
  | 'campaign'
  | 'process'
  | 'process_campaign'
  | 'read_access'
  | 'no_permissions';

function usePermissions() {
  const user = useAppSelector(selectUser); // Dane o stanie użytkownika
  const userStrategyPermissions = useAppSelector(selectPermissions); // Strategie i ich uprawnienia

  if (!user.value || !userStrategyPermissions) {
    console.log('error');
    throw new Error(
      'User data and strategy permissions must be loaded before using usePermissions',
    );
  }

  const isSuperAdmin = user.value.is_super_admin ?? false;

  const isTenantAdmin = userStrategyPermissions.is_tenant_admin;
  const isTenantOwner = userStrategyPermissions.is_tenant_owner;

  const is_supervisor = user.value?.is_supervisor ?? false;

  const checkPermission = (permission: UserPermissionType): boolean => {
    if (permission === 'superadmin') {
      return isSuperAdmin;
    }

    const hasFullAccess = isSuperAdmin || isTenantAdmin || isTenantOwner;
    if (hasFullAccess && permission !== 'no_permissions') {
      return true;
    }

    if (
      (!user || user.state !== 'idle' || !userStrategyPermissions) &&
      permission !== 'no_permissions'
    ) {
      console.error('Data loading or access check issues');
      return false;
    }

    switch (permission) {
      case 'supervisor':
        return is_supervisor ?? false;

      case 'tenant_owner':
        return isTenantOwner;
      case 'tenant_admin':
        return isTenantAdmin;

      case 'workspace_admin':
        return userStrategyPermissions.strategy;
      case 'read_access':
        return userStrategyPermissions.read_access;
      case 'process':
        return userStrategyPermissions.process_write;
      case 'campaign':
        return userStrategyPermissions.campaign_write;
      case 'process_campaign':
        return (
          userStrategyPermissions.process_write ||
          userStrategyPermissions.campaign_write
        );
      case 'no_permissions':
        return !(
          isSuperAdmin ||
          is_supervisor ||
          isTenantOwner ||
          isTenantAdmin ||
          userStrategyPermissions.strategy ||
          userStrategyPermissions.read_access ||
          userStrategyPermissions.process_write ||
          userStrategyPermissions.campaign_write
        );
      default:
        return false;
    }
  };

  return {
    checkPermission,
    isSuperAdmin,
    isLoading: user?.state !== 'idle' || !userStrategyPermissions,
  };
}

export default usePermissions;

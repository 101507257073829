import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { ProcessCreateRequest } from '../../../generated/tenants/Api';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkProcessCreate,
  createProcessAsyncThunk,
  endCreateProcess,
  startCreateProcess,
} from './api/processSlice';
import ProcessForm from './ProcessForm';
import usePermissions from '../users/usePermissions';
import LoadingModal from '../../../common/modals/LoadingModal';

const CreateProcess = () => {
  const { checkPermission } = usePermissions();
  const hasProcessAccess = checkPermission('process');

  useEffect(() => {
    !hasProcessAccess && navigate('/');
  }, [hasProcessAccess]);

  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [process, setProcess] = useState<ProcessCreateRequest>({
    strategy: strategy,
    name: '',
    calling_configuration: '',
    voice_configuration: '',
    call_center_non_working_days_configuration: '',
    campaign_non_working_days_configuration: '',
    calendar_configuration: '',
    bot_configuration: '',
    recordings_format: 'mp3',
    labels: [],
    guaranteed_lines: 1,
    redirection_phone_number: '',
    delete_data_after_report: false,
    import_file_handling: 'NEW_CAMPAIGN',
    redirection_presentation_mode: 'CAMPAIGN',
    phone_numbers: [],
    default_campaign_duration_days: 30,
    use_idle_lines: true,
    cyclic_report_per_call: true,
  });

  useEffect(() => {
    dispatch(startCreateProcess());
  }, []);

  useEffect(() => {
    setProcess({ ...process, strategy: strategy });
  }, [strategy]);

  let pathname = window.location.pathname;
  if (pathname[0] === '/') pathname = pathname.slice(1);

  const createProcess = () => {
    setIsLoadingVisible(true);

    dispatch(createProcessAsyncThunk(process)).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const checkCreate = useAppSelector(checkProcessCreate);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      dispatch(endCreateProcess());
      navigate('/dashboard/processes');
    }
  }, [checkCreate]);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <ProcessForm
        process={process}
        setProcess={setProcess}
        button={createProcess}
        detailed={false}
        edit={true}
        hasProcessAcceess={hasProcessAccess}
      />
    </>
  );
};

export default CreateProcess;

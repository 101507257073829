import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const TrashIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M5.5 1H8.5C8.77614 1 9 1.22386 9 1.5V2.5H5V1.5C5 1.22386 5.22386 1 5.5 1ZM10 2.5V1.5C10 0.671573 9.32843 0 8.5 0H5.5C4.67157 0 4 0.671573 4 1.5V2.5H1.50566C1.50226 2.49997 1.49885 2.49997 1.49544 2.5H0.5C0.223858 2.5 0 2.72386 0 3C0 3.27614 0.223858 3.5 0.5 3.5H1.0384L1.89116 14.1595C1.97431 15.1989 2.84207 16 3.88479 16H10.1152C11.1579 16 12.0257 15.1989 12.1088 14.1595L12.9616 3.5H13.5C13.7761 3.5 14 3.27614 14 3C14 2.72386 13.7761 2.5 13.5 2.5H12.5046C12.5011 2.49997 12.4977 2.49997 12.4943 2.5H10ZM11.9584 3.5L11.112 14.0797C11.0704 14.5994 10.6366 15 10.1152 15H3.88479C3.36343 15 2.92955 14.5994 2.88798 14.0797L2.0416 3.5H11.9584ZM4.47064 4.50086C4.74631 4.48465 4.98292 4.69497 4.99914 4.97064L5.49914 13.4706C5.51535 13.7463 5.30503 13.9829 5.02936 13.9991C4.7537 14.0154 4.51708 13.805 4.50086 13.5294L4.00086 5.02936C3.98465 4.7537 4.19497 4.51708 4.47064 4.50086ZM9.52936 4.50086C9.80503 4.51708 10.0154 4.7537 9.99914 5.02936L9.49914 13.5294C9.48292 13.805 9.2463 14.0154 8.97064 13.9991C8.69497 13.9829 8.48465 13.7463 8.50086 13.4706L9.00086 4.97064C9.01708 4.69497 9.2537 4.48465 9.52936 4.50086ZM7 4.5C7.27614 4.5 7.5 4.72386 7.5 5V13.5C7.5 13.7761 7.27614 14 7 14C6.72386 14 6.5 13.7761 6.5 13.5V5C6.5 4.72386 6.72386 4.5 7 4.5Z" />
  </StyledSvg>
);

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const CalendarIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M3.5 0C3.77614 0 4 0.223858 4 0.5V1H12V0.5C12 0.223858 12.2239 0 12.5 0C12.7761 0 13 0.223858 13 0.5V1H14C15.1046 1 16 1.89543 16 3V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V3C0 1.89543 0.895431 1 2 1H3V0.5C3 0.223858 3.22386 0 3.5 0ZM2 2C1.44772 2 1 2.44772 1 3V4H15V3C15 2.44772 14.5523 2 14 2H2ZM15 5H1V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V5Z" />
    <path d="M11 7.5C11 7.22386 11.2239 7 11.5 7H12.5C12.7761 7 13 7.22386 13 7.5V8.5C13 8.77614 12.7761 9 12.5 9H11.5C11.2239 9 11 8.77614 11 8.5V7.5Z" />
    <path d="M8 7.5C8 7.22386 8.22386 7 8.5 7H9.5C9.77614 7 10 7.22386 10 7.5V8.5C10 8.77614 9.77614 9 9.5 9H8.5C8.22386 9 8 8.77614 8 8.5V7.5Z" />
    <path d="M6 10.5C6 10.2239 6.22386 10 6.5 10H7.5C7.77614 10 8 10.2239 8 10.5V11.5C8 11.7761 7.77614 12 7.5 12H6.5C6.22386 12 6 11.7761 6 11.5V10.5Z" />
    <path d="M3 10.5C3 10.2239 3.22386 10 3.5 10H4.5C4.77614 10 5 10.2239 5 10.5V11.5C5 11.7761 4.77614 12 4.5 12H3.5C3.22386 12 3 11.7761 3 11.5V10.5Z" />
  </StyledSvg>
);

import { EuiFieldText, EuiFieldNumber, EuiSuperSelect } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Button, H3 } from '../../../App.style';
import { ApiError } from '../../../../common/types';
import {
  BotLanguageEnum,
  GenderEnum,
  AsrVendorEnum,
  TtsVendorEnum,
  VoiceConfigurationRequest,
  VoiceConfiguration,
} from '../../../../generated/tenants/Api';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../processes/forms/ProcessForm.style';
import { languages, asrVendors, ttsVendors } from './api/voiceConfigurationApi';
import {
  checkVoiceConfigurationCreate,
  checkVoiceConfigurationUpdate,
  clearCreateVoice,
  clearUpdateVoice,
  getVoiceConfigurationsListAsyncThunk,
  RetrieveAvailableVoicesAsyncThunk,
  selectAvailableVoices,
} from './api/voiceConfigurationSlice';
import FormRowTitle from '../../processes/forms/FormRowTitle';
import { useTranslation } from 'react-i18next';

const useVoiceApiErrors = () => {
  const checkVoiceConfigurationConfig = useAppSelector(
    checkVoiceConfigurationCreate,
  );
  const createErrors = checkVoiceConfigurationConfig?.value?.error;

  const checkVoiceConfigurationConfigUpdate = useAppSelector(
    checkVoiceConfigurationUpdate,
  );
  const updateErrors = checkVoiceConfigurationConfigUpdate?.value?.error;

  const [voiceApiErrors, setVoiceApiErrors] = useState<
    ApiError<VoiceConfiguration>
  >({} as ApiError<VoiceConfiguration>);

  useEffect(() => {
    if (createErrors)
      setVoiceApiErrors((prevErrors) => ({ ...prevErrors, ...createErrors }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setVoiceApiErrors((prevErrors) => ({ ...prevErrors, ...updateErrors }));
  }, [updateErrors]);

  return [voiceApiErrors, setVoiceApiErrors] as const;
};

interface VoiceFormProps {
  voice: VoiceConfigurationRequest;
  setVoice: React.Dispatch<React.SetStateAction<VoiceConfigurationRequest>>;
  button?: () => void;
  createNew?: boolean;
  back: () => void;
  backToDetailed?: () => void;
  handleNewVoiceChange?: () => void;
  createForm?: boolean;
  readOnly?: boolean;
  buttonDown?: boolean;
}

const VoiceForm: React.FC<VoiceFormProps> = ({
  voice,
  setVoice,
  button,
  createNew,
  back,
  backToDetailed,
  handleNewVoiceChange,
  createForm,
  buttonDown,
}) => {
  const { t } = useTranslation();
  //function checking for available voice configuration languages for language and tts_vendor provided
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { language, tts_vendor, gender } = voice;

    if (language && tts_vendor && gender) {
      if (voice.name !== '' || createForm) {
        dispatch(
          RetrieveAvailableVoicesAsyncThunk({ language, tts_vendor, gender }),
        );
      }
    }
  }, [voice.language, voice.tts_vendor, voice.gender]);

  //available voices from store
  const availableVoices = useAppSelector(selectAvailableVoices);
  const availableVoicesOptions = availableVoices.voices.map((voice) => ({
    value: voice,
    inputDisplay: voice,
    dropdownDisplay: <H3>{voice}</H3>,
  }));

  //if voice_name is blank, put first option from options
  useEffect(() => {
    if (availableVoicesOptions.length && voice.voice_name === '') {
      setVoice({
        ...voice,
        voice_name: availableVoicesOptions[0].value,
      });
    }

    if (!availableVoicesOptions.length) {
      setVoice({ ...voice, voice_name: '' });
    }
  }, [availableVoices]);

  const checkCreate = useAppSelector(checkVoiceConfigurationCreate);

  const checkUpdate = useAppSelector(checkVoiceConfigurationUpdate);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewVoiceChange && handleNewVoiceChange();

      back();
      dispatch(getVoiceConfigurationsListAsyncThunk(voice.strategy));
      dispatch(clearCreateVoice());
    }

    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      backToDetailed && backToDetailed();
      dispatch(getVoiceConfigurationsListAsyncThunk(voice.strategy));
      dispatch(clearUpdateVoice());
    }
  });

  const [voiceApiErrors, setVoiceApiErrors] = useVoiceApiErrors();

  const gendersOptions = [
    {
      value: 'MALE',
      inputDisplay: t('processes.form.voices.male'),
      dropdownDisplay: <H3>{t('processes.form.voices.male')}</H3>,
    },
    {
      value: 'FEMALE',
      inputDisplay: t('processes.form.voices.female'),
      dropdownDisplay: <H3>{t('processes.form.voices.female')}</H3>,
    },
  ];

  return (
    <FormContainer
      $processForm={buttonDown ? true : false}
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>{t('presets.voicesForm.name')}</H3>}
        isInvalid={voiceApiErrors.name ? true : false}
        error={voiceApiErrors.name}
      >
        <EuiFieldText
          value={voice.name}
          onChange={(e) => {
            setVoice({ ...voice, name: e.target.value });

            voiceApiErrors.name &&
              setVoiceApiErrors({ ...voiceApiErrors, name: undefined });
          }}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.language')}
            tooltip={t('presets.voicesForm.languageTooltip')}
          />
        }
      >
        <EuiSuperSelect
          valueOfSelected={voice.language}
          options={languages.map((v) => ({
            value: v,
            inputDisplay: v,
            dropdownDisplay: <H3>{v}</H3>,
          }))}
          onChange={(e) => {
            setVoice({
              ...voice,
              voice_name: '',
              language: e as BotLanguageEnum,
            });
          }}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.gender')}
            tooltip={t('presets.voicesForm.genderTooltip')}
          />
        }
      >
        <EuiSuperSelect
          valueOfSelected={voice.gender}
          options={gendersOptions}
          onChange={(e) =>
            setVoice({ ...voice, voice_name: '', gender: e as GenderEnum })
          }
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.baseTtsSpeed')}
            tooltip={t('presets.voicesForm.baseTtsSpeedTooltip')}
          />
        }
      >
        <EuiFieldNumber
          value={voice.base_tts_speed_percentage}
          onChange={(e) =>
            setVoice({
              ...voice,
              base_tts_speed_percentage: Number(e.target.value),
            })
          }
          max={200}
          min={50}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.asrVendor')}
            tooltip={t('presets.voicesForm.asrVendorTooltip')}
          />
        }
      >
        <EuiSuperSelect
          valueOfSelected={voice.asr_vendor}
          options={asrVendors.map((v) => ({
            value: v,
            inputDisplay: v,
            dropdownDisplay: <H3>{v}</H3>,
          }))}
          onChange={(e) =>
            setVoice({
              ...voice,
              asr_vendor: e as AsrVendorEnum,
            })
          }
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.ttsVendor')}
            tooltip={t('presets.voicesForm.ttsVendorTooltip')}
          />
        }
      >
        <EuiSuperSelect
          valueOfSelected={voice.tts_vendor}
          options={ttsVendors.map((v) => ({
            value: v,
            inputDisplay: v,
            dropdownDisplay: <H3>{v}</H3>,
          }))}
          onChange={(e) =>
            setVoice({
              ...voice,
              voice_name: '',
              tts_vendor: e as TtsVendorEnum,
            })
          }
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.voiceName')}
            tooltip={t('presets.voicesForm.voiceNameTooltip')}
          />
        }
        isInvalid={
          voiceApiErrors.voice_name ? true : availableVoicesOptions.length === 0
        }
        error={
          voiceApiErrors?.voice_name
            ? voiceApiErrors.voice_name
            : t('presets.voicesForm.noVoices')
        }
      >
        <EuiSuperSelect
          valueOfSelected={voice.voice_name}
          options={availableVoicesOptions}
          onChange={(e) => setVoice({ ...voice, voice_name: e })}
        />
      </FormRow>

      {createNew && !buttonDown ? (
        <PresetCreateButton>
          <Button type="submit">{t('common.create')}</Button>
        </PresetCreateButton>
      ) : (
        buttonDown && <Button type="submit">{t('common.create')}</Button>
      )}
    </FormContainer>
  );
};

export default VoiceForm;

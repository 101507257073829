import { EuiCheckbox, EuiSwitch } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { UserSettings } from '../../../../../generated/tenants/Api';
import {
  updateUserSettingsAsyncThunk,
  getUserSettingsAsyncThunk,
  selectUserSettings,
} from '../api/userSettingsSlice';
import { H2, H3, H4 } from '../../../../App.style';
import {
  NotificationsContainer,
  SecurityContainer,
  SecurityContentContainer,
} from './Security.style';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userSettings = useAppSelector(selectUserSettings);

  const [settings, setSettings] = useState<
    Omit<
      UserSettings,
      'id' | 'created_at' | 'updated_at' | 'avatar' | 'initials'
    >
  >({
    record_import_notify: true,
    campaign_status_finished_notify: true,
    campaign_pause_resume_outbound_notify: true,
    campaign_status_running_notify: true,
    campaign_status_stopped_notify: true,
  });

  const [showChangesInCampaignCheckboxes, setShowChangesInCampaignCheckboxes] =
    useState(false);

  // Utility to check if any campaign setting is true
  const anyCampaignNotifyActive = (settings?: Partial<UserSettings>) => {
    if (!settings) return false;

    return Object.keys(settings)
      .filter((key) => key.startsWith('campaign_status_'))
      .some((key) => settings[key as keyof typeof settings]);
  };

  const [showChangesInProcessCheckboxes, setShowChangesInProcessCheckboxes] =
    useState(false);

  const anyProcessNotifyActive = (settings?: Partial<UserSettings>) => {
    if (!settings) return false;

    return Object.keys(settings)
      .filter((key) => key.startsWith('process_'))
      .some((key) => settings[key as keyof typeof settings]);
  };

  const handleSwitchChange = (
    key: keyof Omit<
      UserSettings,
      'id' | 'created_at' | 'updated_at' | 'avatar' | 'initials'
    >,
    value: boolean,
  ) => {
    if (settings) {
      const newSettings = { [key]: value };
      const updatedSettings = { ...settings, ...newSettings };

      // Remove the unwanted fields
      const cleanedSettings = Object.fromEntries(
        Object.entries(updatedSettings).filter(
          ([key]) =>
            !['id', 'created_at', 'updated_at', 'avatar', 'initials'].includes(
              key,
            ),
        ),
      );

      dispatch(updateUserSettingsAsyncThunk(cleanedSettings)).then(() => {
        setSettings(cleanedSettings as typeof settings);
      });
    }
  };

  useEffect(() => {
    dispatch(getUserSettingsAsyncThunk());
  }, []);

  useEffect(() => {
    if (userSettings?.value) {
      setShowChangesInCampaignCheckboxes(
        anyCampaignNotifyActive(userSettings.value),
      );
      setShowChangesInProcessCheckboxes(
        anyProcessNotifyActive(userSettings.value),
      );
      setSettings(userSettings.value);
    } else {
      const defaultSettings = {
        record_import_notify: true,
        campaign_status_finished_notify: true,
        campaign_pause_resume_outbound_notify: true,
        campaign_status_running_notify: true,
        campaign_status_stopped_notify: true,
        process_create_notify: true,
        process_update_notify: true,
        process_delete_notify: true,
      };
      setShowChangesInCampaignCheckboxes(true);
      setShowChangesInProcessCheckboxes(true);
      setSettings(defaultSettings);
    }
  }, [userSettings]);

  const toggleAllCampaignStatus = (show: boolean) => {
    if (settings) {
      const newSettings = {
        campaign_status_scheduled_notify: show,
        campaign_status_running_notify: show,
        campaign_pause_resume_outbound_notify: show,
        campaign_status_stopped_notify: show,
        campaign_status_finished_notify: show,
        campaign_status_error_notify: show,
      };

      const updatedSettings = { ...settings, ...newSettings };
      setSettings(updatedSettings as typeof settings);

      dispatch(updateUserSettingsAsyncThunk(updatedSettings));
    }
    setShowChangesInCampaignCheckboxes(show);
  };

  const toggleAllProcessStatus = (show: boolean) => {
    if (settings) {
      const newSettings = {
        process_create_notify: true,
        process_update_notify: true,
        process_delete_notify: true,
      };

      const updatedSettings = { ...settings, ...newSettings };
      setSettings(updatedSettings);

      dispatch(updateUserSettingsAsyncThunk(updatedSettings));
    }
    setShowChangesInProcessCheckboxes(show);
  };

  return (
    <SecurityContainer>
      <H2 $medium>{t('settings.users.notifications.title')}</H2>

      <H3 style={{ marginTop: 16 }}>
        {t('settings.users.notifications.about')}
      </H3>

      <SecurityContentContainer>
        {settings && (
          <NotificationsContainer>
            <EuiSwitch
              compressed
              checked={settings.campaign_created_notify ?? false}
              onChange={(e) =>
                handleSwitchChange('campaign_created_notify', e.target.checked)
              }
              label={
                <H4>{t('settings.users.notifications.creatingNewCampaign')}</H4>
              }
              id="creating-new-campaign"
            />

            <EuiSwitch
              compressed
              checked={showChangesInCampaignCheckboxes}
              onChange={() =>
                toggleAllCampaignStatus(!showChangesInCampaignCheckboxes)
              }
              label={
                <H4>
                  {t('settings.users.notifications.statusChangeCampaign')}
                </H4>
              }
              id="changes-in-campaign-status"
            />

            {showChangesInCampaignCheckboxes && (
              <div style={{ marginLeft: 35 }}>
                <EuiCheckbox
                  id="campaign_status_scheduled_notify"
                  label={<H4>{t('settings.users.notifications.scheduled')}</H4>}
                  checked={settings.campaign_status_scheduled_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_status_scheduled_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_status_running_notify"
                  label={<H4>{t('settings.users.notifications.running')}</H4>}
                  checked={settings.campaign_status_running_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_status_running_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_status_stopped_notify"
                  label={<H4>{t('settings.users.notifications.stopped')}</H4>}
                  checked={settings.campaign_status_stopped_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_status_stopped_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_status_finished_notify"
                  label={<H4>{t('settings.users.notifications.finished')}</H4>}
                  checked={settings.campaign_status_finished_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_status_finished_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_status_error_notify"
                  label={<H4>{t('settings.users.notifications.error')}</H4>}
                  checked={settings.campaign_status_error_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_status_error_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_pause_resume_outbound_notify"
                  label={
                    <H4>{t('settings.users.notifications.outboundPaused')}</H4>
                  }
                  checked={
                    settings.campaign_pause_resume_outbound_notify ?? false
                  }
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_pause_resume_outbound_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_pause_resume_inbound_notify"
                  label={
                    <H4>{t('settings.users.notifications.inboundPaused')}</H4>
                  }
                  checked={
                    settings.campaign_pause_resume_inbound_notify ?? false
                  }
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_pause_resume_inbound_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="campaign_pause_resume_cc_notify"
                  label={<H4>{t('settings.users.notifications.ccPaused')}</H4>}
                  checked={settings.campaign_pause_resume_cc_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'campaign_pause_resume_cc_notify',
                      e.target.checked,
                    )
                  }
                />
              </div>
            )}

            <EuiSwitch
              compressed
              checked={settings.sftp_file_upload_notify ?? false}
              onChange={(e) =>
                handleSwitchChange('sftp_file_upload_notify', e.target.checked)
              }
              label={<H4>SFTP</H4>}
              id="sftp"
            />

            <EuiSwitch
              compressed
              checked={settings.record_import_notify ?? false}
              onChange={(e) =>
                handleSwitchChange('record_import_notify', e.target.checked)
              }
              label={
                <H4>{t('settings.users.notifications.importNewRecords')}</H4>
              }
              id="new-records-import"
            />

            <EuiSwitch
              compressed
              checked={showChangesInProcessCheckboxes}
              onChange={() =>
                toggleAllProcessStatus(!showChangesInProcessCheckboxes)
              }
              label={
                <H4>{t('settings.users.notifications.changesProcess')}</H4>
              }
              id="changes-in-process"
            />

            {showChangesInProcessCheckboxes && (
              <div style={{ marginLeft: 35 }}>
                <EuiCheckbox
                  id="process_create_notify"
                  label={<H4>{t('common.create')}</H4>}
                  checked={settings.process_create_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'process_create_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="process_update_notify"
                  label={<H4>{t('common.update')}</H4>}
                  checked={settings.process_update_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'process_update_notify',
                      e.target.checked,
                    )
                  }
                />

                <EuiCheckbox
                  id="process_delete_notify"
                  label={<H4>{t('common.delete')}</H4>}
                  checked={settings.process_delete_notify ?? false}
                  onChange={(e) =>
                    handleSwitchChange(
                      'process_delete_notify',
                      e.target.checked,
                    )
                  }
                />
              </div>
            )}
          </NotificationsContainer>
        )}
      </SecurityContentContainer>
    </SecurityContainer>
  );
};

export default Notifications;

import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Cell,
  TooltipProps,
} from 'recharts';
import {
  AnalyticsChartContainer,
  AnalyticsChartNoDataContainer,
  AnalyticsCustomTooltip,
  OutboundCallsDialogueStatusesContainer,
  StatusColorBox,
  StatusItem,
  StatusItemContent,
  StatusList,
  StatusTitle,
} from '../Analytics.style';
import { H2, H3 } from '../../../App.style';
import { FiltersIcon } from '../../../../resources/icons-new/FiltersIcon';
import { getColorByIndex } from './analyticsFunctions';
import Checkbox from '../../../../common/inputs/Checkbox';
import { Moment } from 'moment';
import {
  CampaignOverview,
  ProcessStatusChartResponse,
} from '../../../../generated/tenants/Api';
import Ramka4Img from '../../../../resources/analytics/Ramka4.png';
import FakeLoadingChart from './overview/FakeLoadingChart';
import { useAppDispatch } from '../../../../common/hooks';
import { getProcessesStatusChartsAsyncThunk } from '../api/analyticsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useTranslation } from 'react-i18next';

interface AnsweredCallsDialogueStatusChartProps {
  processId: string;
  type?: 'campaign' | 'time';
  campaigns: CampaignOverview[];
  startDate?: Moment;
  endDate?: Moment;
}

const AnsweredCallsDialogueStatusChart: React.FC<
  AnsweredCallsDialogueStatusChartProps
> = ({ processId, type, campaigns, startDate, endDate }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [chartData, setChartData] = useState<ProcessStatusChartResponse>();
  const [dialogueStatusFilters, setDialogueStatusFilters] = useState<string[]>(
    [],
  );
  const [processStatusFilters, setProcessStatusFilters] = useState<string[]>(
    [],
  );

  const [totalDialogues, setTotalDialogues] = useState(0);
  const [totalProcesses, setTotalProcesses] = useState(0);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const [colorMapping, setColorMapping] = useState<{ [key: string]: string }>(
    {},
  );

  useEffect(() => {
    let payload;

    if (type === 'campaign') {
      payload = {
        processId: processId,
        data: {
          campaign_ids: campaigns.map((campaign) => campaign.id),
        },
      };
    } else {
      payload = {
        processId: processId,
        data: {
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        },
      };
    }

    if (processId && type)
      dispatch(getProcessesStatusChartsAsyncThunk(payload))
        .then(unwrapResult)
        .then((res) => {
          const updatedDialogueStatus = res.dialogue_status.map((item) => ({
            ...item,
            type: 'dialogue',
          }));
          const updatedProcessStatus = res.process_status.map((item) => ({
            ...item,
            type: 'process',
          }));

          setChartData({
            ...res,
            dialogue_status: updatedDialogueStatus,
            process_status: updatedProcessStatus,
          });
          const dialogueFilters = res.dialogue_status.map((item) => item.name);
          const processFilters = res.process_status.map((item) => item.name);
          setDialogueStatusFilters(dialogueFilters);
          setProcessStatusFilters(processFilters);
          setSelectedStatuses([...dialogueFilters, ...processFilters]);

          const newColorMapping: { [key: string]: string } = {};

          res.dialogue_status.forEach((item, index) => {
            newColorMapping[item.name] = getColorByIndex(index, 'dialogue');
          });

          res.process_status.forEach((item, index) => {
            newColorMapping[item.name] = getColorByIndex(index, 'process');
          });

          setColorMapping(newColorMapping);
        });
  }, [processId, type, campaigns, startDate, endDate]);

  const toggleStatus = (statusName: string, type: 'dialogue' | 'process') => {
    setSelectedStatuses((prev) => {
      const count = prev.filter((status) =>
        (type === 'dialogue'
          ? dialogueStatusFilters
          : processStatusFilters
        ).includes(status),
      ).length;

      if (count === 1 && prev.includes(statusName)) {
        return prev;
      }

      return prev.includes(statusName)
        ? prev.filter((name) => name !== statusName)
        : [...prev, statusName];
    });
  };

  const showCharts = () => {
    return (
      (type === 'time' && startDate && endDate) ||
      (type === 'campaign' && campaigns.length > 0)
    );
  };

  const filteredChartData = {
    dialogue_status:
      chartData?.dialogue_status.filter((item) =>
        selectedStatuses.includes(item.name),
      ) || [],
    process_status:
      chartData?.process_status.filter((item) =>
        selectedStatuses.includes(item.name),
      ) || [],
  };

  useEffect(() => {
    if (filteredChartData) {
      setTotalDialogues(calculateTotal(filteredChartData.dialogue_status));
      setTotalProcesses(calculateTotal(filteredChartData.process_status));
    }
  }, [filteredChartData]);

  const calculateTotal = (data: { value: number }[]) =>
    data.reduce((acc, current) => acc + current.value, 0);
  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length > 0) {
      const item = payload[0]; // Assuming that payload structure includes the necessary data
      const total =
        item.payload.type === 'dialogue' ? totalDialogues : totalProcesses;
      const percentage = (((item.value as number) / total) * 100).toFixed(2);

      return (
        <AnalyticsCustomTooltip>
          <H3 style={{ color: '#127fbf' }}>{percentage}%</H3>
          <H3 $grey>
            {item.name}: {item.value}
          </H3>
        </AnalyticsCustomTooltip>
      );
    }

    return null;
  };

  const renderCustomLabel = (
    entry: {
      name: string;
      value: number;
    } & {
      cx?: number;
      cy?: number;
      midAngle?: number;
      innerRadius?: number;
      outerRadius?: number;
      percent?: number;
      index?: number;
    },
  ): React.ReactNode => {
    return `${entry.name}`;
  };
  return (
    <AnalyticsChartContainer style={{ flexDirection: 'row' }}>
      {!showCharts() ? (
        <FakeLoadingChart pieChart src={Ramka4Img} />
      ) : (
        <>
          {chartData?.dialogue_status.length === 0 &&
          chartData.interruption_status.length === 0 ? (
            <AnalyticsChartNoDataContainer>
              <H2 $grey>{t('analytics.charts.noDataAvailableLine1')}</H2>
              <H2 $grey>{t('analytics.charts.noDataAvailableLine2')}</H2>
            </AnalyticsChartNoDataContainer>
          ) : (
            <>
              <StatusList>
                <H2 $medium>Filters</H2>
                <StatusTitle>
                  <FiltersIcon />
                  <H3 $selected>
                    {' '}
                    <H3 $selected>
                      {t('analytics.charts.overview.dialogueStatuses')}
                    </H3>
                  </H3>
                </StatusTitle>
                {dialogueStatusFilters.map((status) => (
                  <StatusItem
                    key={status}
                    onClick={() => toggleStatus(status, 'dialogue')}
                  >
                    <StatusItemContent>
                      <Checkbox
                        checked={selectedStatuses.includes(status)}
                        onChange={() => toggleStatus(status, 'dialogue')}
                      />
                      <H3>{status}</H3>
                    </StatusItemContent>

                    <StatusColorBox
                      style={{
                        background: colorMapping[status],
                      }}
                    />
                  </StatusItem>
                ))}

                <StatusTitle>
                  <FiltersIcon />
                  <H3 $selected>
                    {' '}
                    <H3 $selected>
                      {t('analytics.charts.overview.processesStatuses')}
                    </H3>
                  </H3>
                </StatusTitle>
                {processStatusFilters.map((status) => (
                  <StatusItem
                    key={status}
                    onClick={() => toggleStatus(status, 'process')}
                  >
                    <StatusItemContent>
                      <Checkbox
                        checked={selectedStatuses.includes(status)}
                        onChange={() => toggleStatus(status, 'process')}
                      />
                      <H3>{status}</H3>
                    </StatusItemContent>

                    <StatusColorBox
                      style={{
                        background: colorMapping[status],
                      }}
                    />
                  </StatusItem>
                ))}
              </StatusList>

              <OutboundCallsDialogueStatusesContainer noPadding={!type}>
                <ResponsiveContainer
                  width="100%"
                  height="100%"
                  style={{ marginTop: 20 }}
                  debounce={300}
                >
                  <PieChart>
                    <Pie
                      data={filteredChartData.dialogue_status}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      outerRadius={200}
                      fill="#8884d8"
                    >
                      {filteredChartData.dialogue_status.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colorMapping[entry.name]}
                        />
                      ))}
                    </Pie>
                    <Pie
                      data={filteredChartData.process_status || []}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={220}
                      outerRadius={240}
                      fill="#82ca9d"
                      label={renderCustomLabel}
                    >
                      {filteredChartData.process_status.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colorMapping[entry.name]}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
              </OutboundCallsDialogueStatusesContainer>
            </>
          )}
        </>
      )}
    </AnalyticsChartContainer>
  );
};

export default AnsweredCallsDialogueStatusChart;

import React, { useEffect, useState } from 'react';
import {
  UsersListTab,
  UsersListTableContainer,
  UsersListTableHeader,
} from '../Users.style';
import { ArrowIconLeft } from '../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../resources/icons-new/ArrowIconRight';
import { H4 } from '../../../App.style';
import {
  TableContainer,
  PaginationContainer,
  PaginationText,
  ArrowsContainer,
} from '../../campaigns/panel/CampaignsPanel.style';
import UserTableRow from './UserTableRow';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { getUsersListAsyncThunk, selectUsers } from '../api/userSlice';
import { Strategy } from '../../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';

interface UsersTableProps {
  search: string;
  handleDetailedClick: (user_id: string) => void;

  selectedStrategy?: string;

  strategiesList: Strategy[];
  showSuccessPermissionsPopup: () => void;
  showErrorPermissionsPopup: () => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  search,
  handleDetailedClick,
  selectedStrategy,
  strategiesList,
  showSuccessPermissionsPopup,
  showErrorPermissionsPopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [editUserIndex, setEditUserIndex] = useState<number>();

  const apiUsersList = useAppSelector(selectUsers);

  //pagination
  const itemsPerPage = 12;

  const totalItems = apiUsersList?.count as number;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  //pagination
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };

  const [reload, setReload] = useState<number>(0);

  const handleReloadUsersList = () => setReload((prev) => prev + 1);

  useEffect(() => {
    const query = {
      offset: offset,
      limit: itemsPerPage,
      filter_info: search,
      strategy_id: selectedStrategy !== 'all' ? selectedStrategy : undefined,
    };
    dispatch(getUsersListAsyncThunk(query));
    setEditUserIndex(undefined);
  }, [offset, limit, search, reload, selectedStrategy]);

  return (
    <UsersListTableContainer>
      <TableContainer>
        {apiUsersList?.results && (
          <UsersListTab>
            <thead>
              <tr>
                <UsersListTableHeader avatar></UsersListTableHeader>

                <UsersListTableHeader username>
                  <H4 $grey>{t('settings.adminPanel.users.table.username')}</H4>
                </UsersListTableHeader>

                <UsersListTableHeader userRole>
                  <H4 $grey>{t('settings.adminPanel.users.table.role')}</H4>
                </UsersListTableHeader>

                <UsersListTableHeader permissions>
                  <H4 $grey>
                    {t('settings.adminPanel.users.table.permissions')}
                  </H4>
                </UsersListTableHeader>

                <UsersListTableHeader>
                  <H4 $grey>
                    {t('settings.adminPanel.users.table.lastLogin')}
                  </H4>
                </UsersListTableHeader>

                <UsersListTableHeader pagination>
                  <PaginationContainer>
                    <PaginationText>
                      {getPaginationTextContent(
                        currentPage,
                        itemsPerPage,
                        totalItems,
                      )}
                    </PaginationText>

                    <ArrowsContainer>
                      <button
                        disabled={currentPage === 1}
                        onClick={() =>
                          handlePageChange(Math.max(1, currentPage - 1))
                        }
                      >
                        <ArrowIconLeft disabled={currentPage === 1} />
                      </button>

                      <button
                        disabled={currentPage === totalPages}
                        onClick={() =>
                          handlePageChange(
                            Math.min(totalPages, currentPage + 1),
                          )
                        }
                      >
                        <ArrowIconRight disabled={currentPage === totalPages} />
                      </button>
                    </ArrowsContainer>
                  </PaginationContainer>
                </UsersListTableHeader>
              </tr>
            </thead>
            <tbody>
              {apiUsersList.results?.length === 0 ? (
                <tr
                  style={{
                    padding: 20,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <td>{t('settings.adminPanel.users.table.noUsers')}</td>
                </tr>
              ) : (
                apiUsersList.results.map((user, index) => {
                  return (
                    <UserTableRow
                      key={`user-row-${index}`}
                      user={user}
                      index={index}
                      edit={index === editUserIndex}
                      setEditUserIndex={setEditUserIndex}
                      selectedStrategy={selectedStrategy}
                      handleReloadUsersList={handleReloadUsersList}
                      handleDetailedClick={handleDetailedClick}
                      strategiesList={strategiesList}
                      showSuccessPermissionsPopup={showSuccessPermissionsPopup}
                      showErrorPermissionsPopup={showErrorPermissionsPopup}
                    />
                  );
                })
              )}
            </tbody>
          </UsersListTab>
        )}
      </TableContainer>
    </UsersListTableContainer>
  );
};

export default UsersTable;

import moment from 'moment';
import React from 'react';
import { H3 } from '../../../App.style';
import { CalendarConfiguration } from '../../../../generated/tenants/Api';
import PresetListPreview from '../PresetListPreview';
import {
  AddPresetIconContainer,
  CreateNewPresetContainer,
  CreatePresetButtonContainer,
  ListContainer,
} from '../PresetsPanel.style';
import { NewPresetIcon } from '../../../../resources/icons-new/NewPresetIcon';
import { useTranslation } from 'react-i18next';

interface CalendarsListProps {
  calendarsList: CalendarConfiguration[];
  createCalendar: () => void;
  goToDetailed: (calendarId: string) => void;
  findProcessNamesById(id: string): string[];
  handleDeleteCalendar: (id: string) => void;
  handleDuplicateCalendar: (id: string) => void;
  hasPermission: boolean;
}

const CalendarsList: React.FC<CalendarsListProps> = ({
  calendarsList,
  createCalendar,
  goToDetailed,
  findProcessNamesById,
  handleDeleteCalendar,
  handleDuplicateCalendar,
  hasPermission,
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer>
      {calendarsList &&
        calendarsList.map((calendar, index) => {
          const formattedCreated = moment(calendar.created_at).format(
            'DD/MM/YYYY, HH:mm',
          );
          const fomrattedModified = moment(calendar.updated_at).format(
            'DD/MM/YYYY, HH:mm',
          );

          const usedIn = findProcessNamesById(calendar.id);
          return (
            <PresetListPreview
              type={'calendar'}
              key={`calendar-${index}`}
              index={index}
              onClick={goToDetailed}
              presetId={calendar.id}
              presetName={calendar.name}
              createDate={formattedCreated}
              modifiedDate={fomrattedModified}
              usedIn={usedIn}
              handleDeletePreset={handleDeleteCalendar}
              handleDuplicatePreset={handleDuplicateCalendar}
              disableDots={!hasPermission}
            />
          );
        })}

      {hasPermission && (
        <CreateNewPresetContainer onClick={createCalendar}>
          <CreatePresetButtonContainer>
            <AddPresetIconContainer>
              <NewPresetIcon
                style={{
                  position: 'absolute',
                  left: 17,
                  top: 17,
                  width: 15,
                  height: 15,
                }}
              />
            </AddPresetIconContainer>
            <H3>{t('common.createNew')}</H3>
          </CreatePresetButtonContainer>
        </CreateNewPresetContainer>
      )}
    </ListContainer>
  );
};

export default CalendarsList;

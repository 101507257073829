import React from 'react';
import { VindicationContentContainer } from '../../Vindication.style';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';
import { H2, H4 } from '../../../../../App.style';
import { EuiAccordion } from '@elastic/eui';
import BotStatementBuilder from '../../tokenInput/BotStatementBuilder';
import { DialogueSuccessIcon } from '../../../../../../resources/icons-new/DialogueSuccessIcon';
import { DialogueFailIcon } from '../../../../../../resources/icons-new/DialogueFailIcon';
import { DialogueSphereIcon } from '../../../../../../resources/icons-new/DialogueSphereIcon';
import DialogueActionSelect from '../../actionsSelects/DialogueActionSelect';

interface DialogueIdentificationProps {
  vindicationDialogue: BotConfigurationRequest;
  setVindicationDialogue: React.Dispatch<
    React.SetStateAction<BotConfigurationRequest>
  >;
}

const DialogueIdentification: React.FC<DialogueIdentificationProps> = ({
  vindicationDialogue,
  setVindicationDialogue,
}) => {
  const handleUpdateGenerationsConfig = (
    newValue: string,
    configKey: string,
  ) => {
    setVindicationDialogue((prevState) => ({
      ...prevState,
      generations_config: {
        ...prevState.generations_config,
        [configKey]: newValue, // Dynamicznie aktualizujemy klucz na podstawie configKey
      },
    }));
  };

  const renderAccordionTitle = (
    text: string,
    icon: 'success' | 'fail' | 'sphere',
  ) => {
    const iconMap = {
      success: <DialogueSuccessIcon />,
      fail: <DialogueFailIcon />,
      sphere: <DialogueSphereIcon />,
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {iconMap[icon]}
        <H4 $medium>{text}</H4>
      </div>
    );
  };

  const updateIdentificationAction = (selectedValue: string) => {
    const updatedDialogue = {
      ...vindicationDialogue,
      strategy_config: {
        ...vindicationDialogue.strategy_config,
        BRANCHES: {
          ...vindicationDialogue.strategy_config?.BRANCHES,
          IDENTIFICATION: selectedValue,
        },
      },
    };

    setVindicationDialogue(updatedDialogue);
  };

  const updateIsDeadAction = (selectedValue: string) => {
    const updatedDialogue = {
      ...vindicationDialogue,
      strategy_config: {
        ...vindicationDialogue.strategy_config,
        is_dead: selectedValue,
      },
    };

    setVindicationDialogue(updatedDialogue);
  };

  const updateWrongNumberAction = (selectedValue: string) => {
    const updatedDialogue = {
      ...vindicationDialogue,
      strategy_config: {
        ...vindicationDialogue.strategy_config,
        wrong_number: selectedValue,
      },
    };

    setVindicationDialogue(updatedDialogue);
  };

  return (
    <VindicationContentContainer>
      <BotStatementBuilder
        title="Rozpoczecie rozmowy"
        value={
          vindicationDialogue.generations_config?.[
            'SOC-INI-identification_intro-1-1'
          ]
        }
        onSave={handleUpdateGenerationsConfig}
        configKey={'SOC-INI-identification_intro-1-1'}
        customTokens={vindicationDialogue.custom_tokens ?? []}
      />

      <BotStatementBuilder
        title="Bot się przedstawia"
        value={
          vindicationDialogue.generations_config?.[
            'INF-STA-identification_intro-1-1'
          ]
        }
        onSave={handleUpdateGenerationsConfig}
        configKey={'INF-STA-identification_intro-1-1'}
        customTokens={vindicationDialogue.custom_tokens ?? []}
      />

      <BotStatementBuilder
        title="Pytanie identyfikacyjne"
        value={
          vindicationDialogue.generations_config?.[
            'REQ-INF-may_i_speak_with_client_now-1-1'
          ]
        }
        onSave={handleUpdateGenerationsConfig}
        configKey={'REQ-INF-may_i_speak_with_client_now-1-1'}
        customTokens={vindicationDialogue.custom_tokens ?? []}
      />

      <div>
        <EuiAccordion
          initialIsOpen
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'Jeżeli rozmówca informuje, że nie jest dłużnikiem, ale np. jego małżonkiem, rodzicem',
            'sphere',
          )}
        >
          <BotStatementBuilder
            value={
              vindicationDialogue.generations_config?.[
                'REQ-INF-can_you_hand_over-1-1'
              ]
            }
            onSave={handleUpdateGenerationsConfig}
            configKey={'REQ-INF-can_you_hand_over-1-1'}
            customTokens={vindicationDialogue.custom_tokens ?? []}
          />
        </EuiAccordion>
      </div>

      <div>
        <EuiAccordion
          initialIsOpen
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'JEŻELI moduł zakończy się sukcesem',
            'success',
          )}
        >
          <DialogueActionSelect staticStep="VERIFICATION" />
        </EuiAccordion>
      </div>

      <div>
        <EuiAccordion
          initialIsOpen
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'JEŻELI moduł zakoczy się porażką',
            'fail',
          )}
        >
          <BotStatementBuilder
            icon="fail"
            value={
              vindicationDialogue.generations_config?.[
                'REQ-ACT-identification_failed-1-1'
              ]
            }
            onSave={handleUpdateGenerationsConfig}
            configKey={'REQ-ACT-identification_failed-1-1'}
            customTokens={vindicationDialogue.custom_tokens ?? []}
          />
          <DialogueActionSelect
            value={vindicationDialogue.strategy_config?.BRANCHES.IDENTIFICATION}
            onChange={updateIdentificationAction}
          />
        </EuiAccordion>
      </div>

      <EuiAccordion
        style={{ marginTop: 30 }}
        initialIsOpen
        id={'myAccordion'}
        buttonContent={<H2>ALTERNATYWNE OPCJE</H2>}
      >
        <div style={{ marginLeft: 50, paddingTop: 30 }}>
          <EuiAccordion
            id={'myAccordion'}
            buttonContent={renderAccordionTitle(
              'JEŻELI rozmówca informuje że dłużnik zmarł',
              'sphere',
            )}
          >
            {/* reakcja na smierc */}
            <BotStatementBuilder
              value={
                vindicationDialogue.generations_config?.['INF-STA-is_dead-1-1']
              }
              onSave={handleUpdateGenerationsConfig}
              configKey={'INF-STA-is_dead-1-1'}
              customTokens={vindicationDialogue.custom_tokens ?? []}
            />
            <div style={{ marginTop: 16 }}></div>

            {/* pozegnanie */}
            <BotStatementBuilder
              value={
                vindicationDialogue.generations_config?.['SOC-FIN-is_dead-1-1']
              }
              onSave={handleUpdateGenerationsConfig}
              configKey={'SOC-FIN-is_dead-1-1'}
              customTokens={vindicationDialogue.custom_tokens ?? []}
            />
            <DialogueActionSelect
              value={vindicationDialogue.strategy_config?.is_dead}
              onChange={updateIsDeadAction}
            />
          </EuiAccordion>
        </div>

        <div style={{ marginLeft: 50, paddingTop: 16 }}>
          <EuiAccordion
            id={'myAccordion'}
            buttonContent={renderAccordionTitle(
              'JEŻELI rozmówca informuje że to pomyłka',
              'sphere',
            )}
          >
            {/* reakcja na zly numer */}
            <BotStatementBuilder
              value={
                vindicationDialogue.generations_config?.[
                  'INF-STA-wrong_number-1-1'
                ]
              }
              onSave={handleUpdateGenerationsConfig}
              configKey={'INF-STA-wrong_number-1-1'}
              customTokens={vindicationDialogue.custom_tokens ?? []}
            />
            <div style={{ marginTop: 16 }}></div>

            {/* pozegnanie */}
            <BotStatementBuilder
              value={
                vindicationDialogue.generations_config?.[
                  'SOC-FIN-wrong_number-1-1'
                ]
              }
              onSave={handleUpdateGenerationsConfig}
              configKey={'SOC-FIN-wrong_number-1-1'}
              customTokens={vindicationDialogue.custom_tokens ?? []}
            />

            <DialogueActionSelect
              value={vindicationDialogue.strategy_config?.wrong_number}
              onChange={updateWrongNumberAction}
            />
          </EuiAccordion>
        </div>
      </EuiAccordion>
    </VindicationContentContainer>
  );
};

export default DialogueIdentification;

import {
  Api,
  ReportPerCallRequest,
  ReportPerCampaignRequest,
  ReportPerRecordRequest,
} from '../../../../generated/tenants/Api';

const { api } = new Api();

export const generateReportPerCall = (
  processId: string,
  data: ReportPerCallRequest,
) =>
  api
    .processesReportPerCallCreate(processId, data, { format: 'blob' })
    .then((res) => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition
        ? contentDisposition.match(/filename="([^"]+)"/)
        : null;
      const filename = filenameMatch
        ? filenameMatch[1]
        : 'default_filename.extension';
      const blob = res.data;
      const url = URL.createObjectURL(blob);
      return { url, filename };
    });

export const generateReportPerCampaign = (
  processId: string,
  data: ReportPerCampaignRequest,
) =>
  api
    .processesReportPerCampaignCreate(processId, data, { format: 'blob' })
    .then((res) => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition
        ? contentDisposition.match(/filename="([^"]+)"/)
        : null;
      const filename = filenameMatch
        ? filenameMatch[1]
        : 'default_filename.extension';
      const blob = res.data;
      const url = URL.createObjectURL(blob);
      return { url, filename };
    });

export const generateReportPerRecord = (
  processId: string,
  data: ReportPerRecordRequest,
) =>
  api
    .processesReportPerRecordCreate(processId, data, { format: 'blob' })
    .then((res) => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition
        ? contentDisposition.match(/filename="([^"]+)"/)
        : null;
      const filename = filenameMatch
        ? filenameMatch[1]
        : 'default_filename.extension';
      const blob = res.data;
      const url = URL.createObjectURL(blob);
      return { url, filename };
    });

export const generateReport = (campaignId: string) =>
  api.campaignsReportRetrieve(campaignId, { format: 'blob' }).then((res) => {
    const contentDisposition = res.headers.get('Content-Disposition');
    const filenameMatch = contentDisposition
      ? contentDisposition.match(/filename="([^"]+)"/)
      : null;
    const filename = filenameMatch
      ? filenameMatch[1]
      : 'default_filename.extension';
    const blob = res.data;
    const url = URL.createObjectURL(blob);
    return { url, filename };
  });

import React, { useEffect, useState } from 'react';
import { Company } from '../../../../../../generated/public/Api';
import {
  EditTenantContainer,
  GoToTenantContainer,
  SearchContainer,
  TenantBox,
  TenantsListBarContainer,
  TenantsListBarLeftContainer,
  TenantsListContainer,
  TenantsListContentContainer,
} from './Tenants.style';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import { redirectToTenant } from '../../../../session/api/sessionSlice';
import { Button, H1 } from '../../../../../App.style';
import { useAppDispatch } from '../../../../../../common/hooks';
import { EditIcon } from '../../../../../../resources/icons-new/EditIcon';
import { useTranslation } from 'react-i18next';

interface TenantsListProps {
  tenantsList: Company[];
  createTenant: () => void;
  goToDetailed: (tenantId: string) => void;
}

const TenantsList: React.FC<TenantsListProps> = ({
  tenantsList,
  createTenant,
  goToDetailed,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [filteredTenants, setFilteredTenants] = useState(tenantsList);

  useEffect(() => {
    setFilteredTenants(
      tenantsList.filter((tenant) =>
        tenant.slug.toLowerCase().startsWith(search.toLowerCase()),
      ),
    );
  }, [search, tenantsList]);

  return (
    <TenantsListContainer>
      <TenantsListBarContainer>
        <TenantsListBarLeftContainer>
          <H1 $bold>{t('superAdminPanel.tenants.list.title')}</H1>

          <Button $size="S" onClick={createTenant}>
            {t('superAdminPanel.tenants.list.create')}
          </Button>
        </TenantsListBarLeftContainer>

        <SearchContainer style={{ marginRight: 16 }}>
          <SearchInput value={search} setValue={setSearch} />
        </SearchContainer>
      </TenantsListBarContainer>
      <TenantsListContentContainer>
        {filteredTenants.map((tenant) => {
          return (
            <TenantBox key={tenant.id}>
              <div>{tenant.slug}</div>
              <EditTenantContainer
                onClick={(e) => {
                  e.stopPropagation();
                  goToDetailed(tenant.id);
                }}
              >
                <EditIcon />
              </EditTenantContainer>

              <GoToTenantContainer>
                <Button
                  $size={'S'}
                  $styleType="NORMAL"
                  onClick={() => dispatch(redirectToTenant(tenant.slug))}
                >
                  {t('superAdminPanel.tenants.list.goTo')}
                </Button>
              </GoToTenantContainer>
            </TenantBox>
          );
        })}
      </TenantsListContentContainer>
    </TenantsListContainer>
  );
};

export default TenantsList;

import { ApiError } from '../../../../../common/types';
import {
  Api,
  AsrVendorEnum,
  BotLanguageEnum,
  TtsVendorEnum,
  GenderEnum,
  VoiceConfiguration,
  VoiceConfigurationRequest,
} from '../../../../../generated/tenants/Api';

const { api } = new Api();

export const genders: Array<GenderEnum> = ['MALE', 'FEMALE'];
export const languages: Array<BotLanguageEnum> = [
  'pl-PL',
  'es-ES',
  'es-MX',
  'en-US',
  'en-AU',
];
export const asrVendors: Array<AsrVendorEnum> = [
  'techmo',
  'google',
  'google-vad',
  'lekta',
];
export const ttsVendors: Array<TtsVendorEnum> = [
  'Microsoft',
  'Google',
  'Techmo',
];

export interface CheckVoiceConfigurationResult {
  status: 'success' | 'fail';
  error?: ApiError<VoiceConfiguration>;
  voice?: VoiceConfiguration;
}

export const getVoiceConfigurationsList = (id: string) =>
  api.strategiesVoiceConfigurationsList(id).then((_) => _.data);

export const createVoiceConfiguration = (
  data: VoiceConfigurationRequest,
): Promise<CheckVoiceConfigurationResult> =>
  api
    .voiceConfigurationsCreate(data)
    .then(() => {
      return {
        status: 'success',
      } as CheckVoiceConfigurationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export type VoiceQuery = {
  gender: GenderEnum;
  language: BotLanguageEnum;
  tts_vendor: TtsVendorEnum;
};

export const getAvailableVoices = (data: VoiceQuery) =>
  api.voiceConfigurationsVoicesRetrieve(data).then((_) => _.data);

export const getVoiceConfigurationById = (id: string) =>
  api.voiceConfigurationsRetrieve(id).then((_) => _.data);

export const updateVoiceConfiguration = (
  id: string,
  data: VoiceConfigurationRequest,
): Promise<CheckVoiceConfigurationResult> =>
  api
    .voiceConfigurationsUpdate(id, data)
    .then(() => {
      return {
        status: 'success',
        voice: data,
      } as CheckVoiceConfigurationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteVoiceConfiguration = (
  id: string,
): Promise<CheckVoiceConfigurationResult> =>
  api
    .voiceConfigurationsDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckVoiceConfigurationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

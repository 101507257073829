import React from 'react';
import { TenantPhoneNumber } from '../../../../../../../generated/tenants/Api';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../../../common/modals/Modals.style';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../../../../App.style';
import { formatPhoneNumber } from '../numbersFunctions';
import Modal from '../../../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface UnassignNumberModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedNumber: TenantPhoneNumber;
  strategyName: string;
  handleUnassignNumber: (numberId: string) => void;
}

const UnassignNumberModal: React.FC<UnassignNumberModalProps> = ({
  isVisible,
  closeModal,
  selectedNumber,
  strategyName,
  handleUnassignNumber,
}) => {
  const { t } = useTranslation();
  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>
          {t('settings.adminPanel.numbers.assign.unassignModal.text', {
            number: formatPhoneNumber(selectedNumber.phone_number_str),
            strategyName: strategyName,
          })}
        </H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleUnassignNumber(selectedNumber.id);

    return false;
  };

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('settings.adminPanel.numbers.assign.unassignModal.title')}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default UnassignNumberModal;

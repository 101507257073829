import { EuiModalBody, EuiFormRow, EuiFieldText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { H3 } from '../../../../App.style';
import { Process } from '../../../../../generated/tenants/Api';
import Modal from '../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface AddProcessModalProps {
  isVisible: boolean;
  closeModal: () => void;
  createProcess: (name: string) => void;
  processesList: Process[];
}

const AddProcessModal: React.FC<AddProcessModalProps> = ({
  isVisible,
  closeModal,
  createProcess,
  processesList,
}) => {
  const { t } = useTranslation();
  const [newProcessName, setNewProcessName] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    createProcess(newProcessName);
    return false;
  };

  const closeAddProcessModal = () => {
    setNewProcessName('');
    closeModal();
  };

  const [nameError, setNameError] = useState<boolean>(false);

  useEffect(() => {
    const unique = !processesList.some((obj) => obj.name === newProcessName);
    unique ? setNameError(false) : setNameError(true);
  }, [newProcessName]);

  const buttonDisabled = newProcessName === '' || nameError;

  const body = (
    <EuiModalBody>
      <EuiFormRow
        label={<H3>{t('processes.modals.nameOfProcess')}</H3>}
        isInvalid={nameError}
        error={t('processes.modals.processNameError')}
      >
        <EuiFieldText
          isInvalid={nameError}
          value={newProcessName}
          autoFocus
          maxLength={48}
          onChange={(e) => {
            setNewProcessName(e.target.value);
          }}
        />
      </EuiFormRow>
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('processes.modals.createNewProcess')}
      onConfirm={handleSubmit}
      onCancel={closeAddProcessModal}
      children={body}
      buttonDisabled={buttonDisabled}
    />
  );
};

export default AddProcessModal;

import { OutboundCallsStatus } from './OutboundPerRange';

export function getColorByIndex(
  index: number,
  type: 'dialogue' | 'process' | 'interuption',
) {
  const colorArray =
    type === 'dialogue'
      ? dialoguesColors
      : type === 'process'
        ? processesColors
        : interuptionColors;
  return colorArray[index % colorArray.length];
}

export const dialoguesColors = [
  '#CFECFD',
  '#A5C5DE',
  '#7EA0BF',
  '#5B7BA1',
  '#3B5882',
  '#1E3664',
  '#001746',
];

export const processesColors = [
  '#CFFCF3',
  '#EF7B45',
  '#601700',
  '#A76D60',
  '#BE92A2',
  '#FFA600',
  '#706677',
  '#E26D5C',
  '#3C7D6F',
  '#C1666B',
];

export const interuptionColors = [
  '#BA3F1D',
  '#C1666B',
  '#F9A03F',
  '#321325',
  '#3A3335',
];

export const outboundStatuses = [
  { id: 'VOICEMAIL', label: 'Voicemail', color: '#CFECFD' },
  { id: 'NO_DIALOGUE', label: 'No dialogue', color: '#A5C5DE' },
  { id: 'ANSWERED', label: 'Answered', color: '#7EA0BF' },
  { id: 'IDENTIFIED', label: 'Identified', color: '#5B7BA1' },
  { id: 'VERIFIED', label: 'Verified', color: '#3B5882' },
  { id: 'HOT', label: 'Hot', color: '#1E3664' },
  { id: 'FINISHED', label: 'Finished', color: '#001746' },
];

export const processesStatuses = [
  { id: 'WRONG_NUMBER', label: 'Wrong number', color: '#CFFCF3' },
  { id: 'ANSWER_REFUSAL', label: 'Answer refusal', color: '#EF7B45' },
  { id: 'CLIENT_DEATH', label: 'Client death', color: '#601700' },
  { id: 'REPAYMENT_ACCEPTED', label: 'Repayment accepted', color: '#A76D60' },
  { id: 'RODO', label: 'RODO', color: '#BE92A2' },
  { id: 'UNWILLING_TO_TALK', label: 'Unwilling to talk', color: '#FFA600' },
  {
    id: 'MADE_PAYMENT_DECLARED',
    label: 'Made payment declared',
    color: '#706677',
  },
  {
    id: 'NONCONESENT_TO_RECORD',
    label: 'Nonconsent to record',
    color: '#E26D5C',
  },
  { id: 'TEXT1', label: 'Text1', color: '#3C7D6F' },
  { id: 'TEXT2', label: 'Text2', color: '#C1666B' },
];

export const interuptionStatuses = [
  { id: 'HANG_UP', label: 'Hang-up', color: '#BA3F1D' },
  { id: 'CONTACT_POSTPONED', label: 'Contact postponed', color: '#C1666B' },
  {
    id: 'CLIENT_DEATH',
    label: 'client requesting advisor contact',
    color: '#F9A03F',
  },
  { id: 'NEW_NUMBER', label: 'New number', color: '#321325' },
];

export const convertToPercentages = (
  data: OutboundCallsStatus[],
  selectedStatuses: string[],
) => {
  return data.map((entry) => {
    // Oblicz sumę wybranych statusów dla danego wpisu
    const total = selectedStatuses.reduce((sum, status) => {
      const value = entry[status];
      return sum + (typeof value === 'number' ? value : 0);
    }, 0);

    // Stwórz nowy obiekt z wartościami procentowymi dla wybranych statusów
    const percentages = selectedStatuses.reduce(
      (acc, status) => {
        const value = entry[status];
        // Ucina wartości po dwóch miejscach po przecinku
        acc[status] =
          total > 0
            ? Math.floor(
                ((typeof value === 'number' ? value : 0) / total) * 100 * 100,
              ) / 100
            : 0;
        return acc;
      },
      {} as { [key: string]: number },
    );

    // Zwróć nowy obiekt zawierający nazwę i wartości procentowe
    return {
      name: entry.name,
      ...percentages,
    };
  });
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { COLOR_NEUTRAL_10, H2, H3, H4 } from '../../App.style';
import { EuiPopover } from '@elastic/eui';
import { Label } from './labels/Labels.style';
import { CalendarPresetIcon } from '../../../resources/icons-new/CalendarPresetIcon';
import { VoicePresetIcon } from '../../../resources/icons-new/VoicePresetIcon';
import { DialoguePresetIcon } from '../../../resources/icons-new/DialoguePresetIcon';
import { CallingPreferencePresetIcon } from '../../../resources/icons-new/CallingPreferencePresetIcon';
import { DaysOffPresetIcon } from '../../../resources/icons-new/DaysOffPresetIcon';
import { ThreeDotsIcon } from '../../../resources/icons-new/ThreeDotsIcon';
import {
  LambdaTypeEnum,
  BaseBotConfigurationTemplateEnum,
} from '../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';
import { UserPopoverButton } from '../sidebar/Sidebar.style';

interface PreviewContainerProps {
  botTemplate?: 'CUSTOM' | 'DEBTOR' | 'REMINDER' | 'VENDOR';
}

export const PreviewContainer = styled.div<PreviewContainerProps>`
  width: 270px;
  height: 230px;
  position: relative;
  padding: 12px;

  background: ${COLOR_NEUTRAL_10};

  border-radius: 5px;

  -webkit-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  -moz-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);

  cursor: pointer;

  overflow: hidden;
`;

export const TopContainer = styled.div`
  display: flex;
  margin-top: 3px;
  justify-content: space-between;
`;

export const PreviewIcon = styled.img``;

const ThreeDotsContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;

  cursor: pointer;
`;

const NameContainer = styled.div`
  margin-top: 14px;
  min-height: 40px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  overflow: hidden;
`;

const DatesContainer = styled.div`
  margin-top: 17px;
`;

const UsedInContainer = styled.div`
  margin-top: 30px;
  max-height: 60px;

  display: flex;
  flex-wrap: wrap;

  overflow: hidden;
`;

interface PresetListPreviewProps {
  type:
    | 'voice'
    | 'dialogues'
    | 'calling_preferences'
    | 'calendar'
    | 'call_centre_days_off'
    | 'bot_days_off'
    | 'custom_faas';
  index: number;
  onClick: (voiceId: string) => void;
  presetId: string;
  presetName: string;
  createDate: string;
  modifiedDate: string;
  usedIn: string[];
  handleDeletePreset: (id: string) => void;
  handleDuplicatePreset: (id: string) => void;
  botTemplate?: BaseBotConfigurationTemplateEnum;
  isCustomBot?: boolean;
  customFaasType?: LambdaTypeEnum;
  disableDots?: boolean;
}

const PresetListPreview: React.FC<PresetListPreviewProps> = ({
  type,
  index,
  onClick,
  presetId,
  presetName,
  createDate,
  modifiedDate,
  usedIn,
  handleDeletePreset,
  handleDuplicatePreset,
  botTemplate,
  isCustomBot,
  customFaasType,
  disableDots,
}) => {
  const { t } = useTranslation();
  const icons = {
    voice: <VoicePresetIcon />,
    dialogues: <DialoguePresetIcon />,
    calling_preferences: <CallingPreferencePresetIcon />,
    calendar: <CalendarPresetIcon />,
    call_centre_days_off: <DaysOffPresetIcon />,
    bot_days_off: <DaysOffPresetIcon />,
    custom_faas: <DialoguePresetIcon />,
  };

  const Icon = icons[type];

  const [isPresetPopoverOpen, setIsPresetPopoverOpen] =
    useState<boolean>(false);
  const openPresetPopover = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsPresetPopoverOpen(true);
  };

  const closePresetPopover = () => setIsPresetPopoverOpen(false);

  return (
    <PreviewContainer
      key={`voice-${index}`}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        onClick(presetId);
      }}
    >
      <TopContainer>
        <div
          style={{ display: 'flex', gap: 10, width: 100, alignItems: 'center' }}
        >
          {Icon}
          {isCustomBot ? (
            <Label fontWhite color={'#127FBF'}>
              {t('presets.customized')}
            </Label>
          ) : (
            botTemplate === 'DEBTOR' && <Label color={'#E5D1D0'}>debtor</Label>
          )}
        </div>

        <EuiPopover
          ownFocus={false}
          button={
            <ThreeDotsContainer onClick={openPresetPopover}>
              <ThreeDotsIcon $pointer />
            </ThreeDotsContainer>
          }
          isOpen={isPresetPopoverOpen}
          closePopover={closePresetPopover}
          anchorPosition="downRight"
          panelPaddingSize="none"
        >
          <div>
            <UserPopoverButton
              disabled={disableDots}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                handleDuplicatePreset(presetId);
                closePresetPopover();
              }}
            >
              <H3>{t('common.duplicate')}</H3>
            </UserPopoverButton>

            <UserPopoverButton
              disabled={usedIn.length > 0 || disableDots}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                handleDeletePreset(presetId);
                closePresetPopover();
              }}
            >
              <H3>{t('common.delete')}</H3>
            </UserPopoverButton>
          </div>
        </EuiPopover>
      </TopContainer>

      <NameContainer>
        <H2 $selected>{presetName}</H2>
        {customFaasType && (
          <H3>{customFaasType === 'IMPORT' ? 'Import' : 'Report'}</H3>
        )}
      </NameContainer>

      <DatesContainer>
        <H4 $grey>
          {t('presets.created')}
          {createDate}
        </H4>
        <H4 $grey>
          {t('presets.modified')}
          {modifiedDate}
        </H4>
      </DatesContainer>

      <UsedInContainer>
        {usedIn.length ? (
          <>
            <H3>{t('presets.associatedWith')}</H3>
            <H3 $bold>{usedIn.join(', ')}</H3>
          </>
        ) : (
          <H3 $grey>{t('presets.noProcess')}</H3>
        )}
      </UsedInContainer>
    </PreviewContainer>
  );
};

export default PresetListPreview;

import React, { useEffect, useState } from 'react';
import {
  AnalyticsChartContainer,
  OutboundCallsDialogueStatusesContainer,
  StatusItem,
  StatusList,
} from '../Analytics.style';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
} from 'recharts';
import { H3 } from '../../../App.style';
import Checkbox from '../../../../common/inputs/Checkbox';
import { FiltersIcon } from '../../../../resources/icons-new/FiltersIcon';
import {
  AggregateByEnum,
  CallStatusDistributionData,
  CampaignOverview,
} from '../../../../generated/tenants/Api';
import { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  getProcessCallStatusChartDataAsyncThunk,
  selectProcessCallStatusChart,
} from '../api/analyticsSlice';
import Ramka3Img from '../../../../resources/analytics/Ramka3.png';

import { convertToPercentages, outboundStatuses } from './analyticsFunctions';
import FakeLoadingChart from './overview/FakeLoadingChart';
import { useTranslation } from 'react-i18next';

interface OutboundCallsChartsProps {
  processId: string;
  type?: 'campaign' | 'time';
  campaigns: CampaignOverview[];
  startDate?: Moment;
  endDate?: Moment;
}

const OutboundPerRange: React.FC<OutboundCallsChartsProps> = ({
  processId,
  type,
  campaigns,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
    'VOICEMAIL',
    'NO_DIALOGUE',
    'ANSWERED',
    'IDENTIFIED',
    'VERIFIED',
    'HOT',
    'FINISHED',
  ]);

  useEffect(() => {
    let payload;

    if (type === 'campaign') {
      payload = {
        processId: processId,
        data: {
          campaign_ids: campaigns.map((campaign) => campaign.id),
          aggregate_by: 'HOUR' as AggregateByEnum,
          filter_statuses:
            selectedStatuses.length > 0 ? selectedStatuses : [''],
        },
      };
    } else {
      payload = {
        processId: processId,
        data: {
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate ? endDate.format('YYYY-MM-DD') : undefined,
          aggregate_by: 'HOUR' as AggregateByEnum,
          filter_statuses:
            selectedStatuses.length > 0 ? selectedStatuses : [''],
        },
      };
    }

    dispatch(getProcessCallStatusChartDataAsyncThunk(payload));
  }, [processId, type, campaigns, startDate, endDate, selectedStatuses]);

  const apiMetricsCallStatus = useAppSelector(selectProcessCallStatusChart);

  useEffect(() => {
    if (apiMetricsCallStatus) {
      setAnalyticsProcessCallStatuses(apiMetricsCallStatus);

      const chartDataInPercentages = convertToPercentages(
        apiMetricsCallStatus.data,
        selectedStatuses,
      );

      setAnalyticsProcessCallStatusesPercentage({
        data: chartDataInPercentages,
      });
    }
  }, [apiMetricsCallStatus]);

  const [analyticsProcessCallStatuses, setAnalyticsProcessCallStatuses] =
    useState<CallStatusDistributionData>({ data: [] });

  const [
    analyticsProcessCallStatusesPercentage,
    setAnalyticsProcessCallStatusesPercentage,
  ] = useState<CallStatusDistributionData>({ data: [] });

  const toggleStatus = (statusId: string) => {
    setSelectedStatuses((prevSelectedStatuses) =>
      prevSelectedStatuses.includes(statusId)
        ? prevSelectedStatuses.filter((id) => id !== statusId)
        : [...prevSelectedStatuses, statusId],
    );
  };

  const showCharts = () => {
    return (
      (type === 'time' && startDate && endDate) ||
      (type === 'campaign' && campaigns.length > 0)
    );
  };

  return (
    <AnalyticsChartContainer style={{ flexDirection: 'row' }}>
      {!showCharts() ? (
        <FakeLoadingChart src={Ramka3Img} />
      ) : (
        <>
          <StatusList>
            <div style={{ display: 'flex', gap: 8, marginBottom: 8 }}>
              <FiltersIcon />
              <H3 $selected>
                {t('analytics.charts.overview.dialogueStatuses')}
              </H3>
            </div>
            {outboundStatuses.map((status) => (
              <StatusItem
                key={status.id}
                onClick={() => toggleStatus(status.id)}
              >
                <div style={{ display: 'flex', gap: 8 }}>
                  <Checkbox
                    checked={selectedStatuses.includes(status.id)}
                    onChange={() => toggleStatus(status.id)}
                  />
                  <H3>{status.label}</H3>
                </div>

                <div
                  style={{
                    width: 21,
                    height: 13,
                    borderRadius: 5,
                    background: status.color,
                  }}
                />
              </StatusItem>
            ))}
          </StatusList>

          <OutboundCallsDialogueStatusesContainer noPadding={!type}>
            <H3 $selected>{t('analytics.charts.overview.value')}</H3>
            <ResponsiveContainer width="100%" height="50%" debounce={30}>
              <BarChart
                width={30}
                height={200}
                data={analyticsProcessCallStatuses.data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar dataKey="VOICEMAIL" stackId="a" fill="#CFECFD" />
                <Bar dataKey="NO_DIALOGUE" stackId="a" fill="#A5C5DE" />
                <Bar dataKey="ANSWERED" stackId="a" fill="#7EA0BF" />
                <Bar dataKey="IDENTIFIED" stackId="a" fill="#5B7BA1" />
                <Bar dataKey="VERIFIED" stackId="a" fill="#3B5882" />
                <Bar dataKey="HOT" stackId="a" fill="#1E3664" />
                <Bar dataKey="FINISHED" stackId="a" fill="#001746" />
              </BarChart>
            </ResponsiveContainer>

            <H3 $selected>{t('analytics.charts.overview.percentage')}</H3>
            <ResponsiveContainer width="100%" height="48%" debounce={300}>
              <BarChart
                width={200}
                height={200}
                data={analyticsProcessCallStatusesPercentage.data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar dataKey="VOICEMAIL" stackId="a" fill="#CFECFD" />
                <Bar dataKey="NO_DIALOGUE" stackId="a" fill="#A5C5DE" />
                <Bar dataKey="ANSWERED" stackId="a" fill="#7EA0BF" />
                <Bar dataKey="IDENTIFIED" stackId="a" fill="#5B7BA1" />
                <Bar dataKey="VERIFIED" stackId="a" fill="#3B5882" />
                <Bar dataKey="HOT" stackId="a" fill="#1E3664" />
                <Bar dataKey="FINISHED" stackId="a" fill="#001746" />
              </BarChart>
            </ResponsiveContainer>
          </OutboundCallsDialogueStatusesContainer>
        </>
      )}
    </AnalyticsChartContainer>
  );
};

export default OutboundPerRange;

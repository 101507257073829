import { COLOR_NEUTRAL_40, H3, fontIBM } from '../../App.style';
import styled, { css } from 'styled-components';

export const CampaignDetailedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 24px;

  border-radius: 5px;

  background: white;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  position: relative;
`;

export const CampaignDetailedBar = styled.div`
  width: 100%;
  display: flex;

  position: relative;
`;

export const FakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(152, 162, 179, 0.3);
  background: #127fbf;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-family: ${fontIBM};

  white-space: nowrap;
  overflow: hidden;

  height: 24px;
  padding: 2px 8px;
  gap: 6px;
  font-size: 12px;

  cursor: pointer;

  background: #fff;
  border: 1px solid #e4e7eb;
  font-weight: 400;
  color: #1a1a1a;

  &:hover {
    filter: brightness(90%);
  }
`;

//Create popup

export const CreatePopupContainer = styled.div`
  position: absolute;
  left: 40px;
  bottom: 10px;
  width: 300px;
  z-index: 10;
  background: #d6e6f4;
  border: 1px solid #127fbf;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 11px;
`;

export const CreatePopupCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 7px;
  cursor: pointer;
`;

export const CreatePopupContent = styled.div`
  display: flex;
  gap: 8px;
`;

//bar

export const BarContentContainer = styled.div`
  width: 70%;
  min-width: 800px;

  height: 80px;

  display: flex;
`;

export const BarContentLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BarContentRightContainer = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BorderSpan = styled.span`
  border: 1px solid #127fbf;
  border-radius: 5px;
  padding: 0px 5px;
  margin: 0px 5px;
`;

interface LabelButtonProps {
  copy?: boolean;
}

export const LabelButton = styled.div<LabelButtonProps>`
  border-radius: 5px;
  padding: 2px 8px;
  font-size: 12px;
  border: ${(props) => (props.copy ? '' : `1px solid ${COLOR_NEUTRAL_40}`)};

  display: flex;
  align-items: center;

  background: white;

  &:hover {
    filter: brightness(90%);
    cursor: pointer;
  }
`;

export const LabelButtonIcon = styled.img`
  margin-right: 5px;
  width: 14px;
  height: 14px;

  svg {
    fill: black;
  }
`;

export const BarRightContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const CampaignStatusesContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${COLOR_NEUTRAL_40};
  padding: 8px;
  border-radius: 5px;
`;

export const CampaignDownloadButtonsContainer = styled.div`
  height: 60px;

  display: flex;
  justify-content: center;

  align-items: flex-end;
`;

export const StatusRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusName = styled.div`
  width: 100px;
`;

///tab

interface TabProps {
  $isSelected: boolean;
}

export const TabContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  height: calc(100% - 80px);
`;

export const TabButtonsContainer = styled.div`
  display: flex;
`;

export const TabButton = styled.div<TabProps>`
  flex: 1;
  padding: 10px;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          border-bottom: 2px solid #127fbf;
          background-color: white;
        `
      : css`
          border-bottom: none;
          border-bottom: 1px solid #3e4c59;
        `}

  display: flex;
  justify-content: center;
  text-align: center;

  &:hover ${H3} {
    font-weight: ${({ $isSelected }) => !$isSelected && 600};
  }
`;

export const Content = styled.div`
  padding: 20px 0px;

  height: 100%;
  width: 100%;

  overflow: hidden;
`;

export const FinishCampaignButton = styled.button`
  padding: 5px 15px;

  background: white;

  box-sizing: border-box;

  font-size: 12px;
  font-weight: 400;

  color: black;

  border-radius: 5px;

  font-family: ${fontIBM};
  font-weight: 400;

  border: 1px solid #d3d3d3;

  -webkit-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  -moz-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);

  transition:
    transform 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    background-color: #c25517;
    border: 1px solid #c25517;
  }

  &:disabled {
    background-color: $grey;
    cursor: not-allowed;
  }
`;

//Campaign snapshot

export const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;

  height: 100%;
  gap: 5px;
  overflow: auto;
`;

export const SnapshotContainer = styled.div`
  width: 100%;
  padding: 20px 27px;
`;

//CampaignNumbersModal

export const CampaignNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const CampaignNumberRow = styled.div`
  padding-bottom: 3px;
  border-bottom: 1px solid ${COLOR_NEUTRAL_40};
`;

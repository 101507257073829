import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  COLOR_NEUTRAL_50,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY_2,
  H4,
} from '../../App.style';

const fontFamily = 'IBM Plex Sans';

export const SidebarContainer = styled.div`
  height: 100vh;
  width: 175px;

  background: #0f172a;
  color: white;
  padding: 10px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const StrategyContainer = styled.div``;

interface UserPopoverButtonProps {
  $radiusTop?: boolean;
  $radiusBottom?: boolean;
}

export const UserPopoverButton = styled.button<UserPopoverButtonProps>`
  width: 100%;
  height: 30px;
  text-align: start;
  padding: 8px 12px;
  border-bottom: 0.5px solid #d3dae6;

  ${({ $radiusTop }) =>
    $radiusTop &&
    `
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  `}

  ${({ $radiusBottom }) =>
    $radiusBottom &&
    `
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}



  &:hover {
    background: ${COLOR_PRIMARY_2};
  }

  &:disabled {
    background: #f2f2f2;
    cursor: not-allowed;
  }
`;

export const SidebarSelectContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 7px;
  border: 0.5px solid #d3d3d3;
  background: #eef9ff;
  border-radius: 5px;
`;

export const SidebarStrategiesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  gap: 28px;
`;
export const SidebarLogoContainer = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  box-sizing: border-box;

  margin-top: 8px;

  margin-bottom: 24px;
`;

export const SidebarLektaLogoFont = styled.p`
  font-family: 'IBM Plex Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SidebarLinkContainer = styled(Link)<{ $selected: boolean }>`
  width: 100%;
  height: 50px;
  padding-left: 20px;

  color: white;
  background: ${(props) => props.$selected && COLOR_NEUTRAL_50};

  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;

  &:hover,
  &:hover * {
    background: ${(props) =>
      props.$selected ? COLOR_NEUTRAL_50 : COLOR_NEUTRAL_80};
    color: white;
  }
`;

export const SidebarSpan = styled.p`
  font-family: ${fontFamily};
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: underline;
  }
`;

export const SidebarSpacer = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLOR_NEUTRAL_80};
  margin: 16px 0px;
`;

export const SidebarTenantSettingsContainer = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;

  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: -10px;
  gap: 6px;
  bottom: 80px;

  &:hover {
    text-decoration: underline;
  }
`;
//user profile

export const UserPopoverContainer = styled.div`
  border-top: 1px solid #3e4c59;
  padding-top: 16px;
  padding-left: 10px;
  display: flex;

  align-items: center;

  position: absolute;

  left: 0;
  bottom: 15px;

  cursor: pointer;

  width: 100%;
`;

export const UserProfileContainer = styled.div`
  width: 160px;
  height: 33px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 5px;
`;

export const UserProfileNameContainer = styled.div`
  width: 100px;
  ${H4} {
    color: white;
  }
`;

export const SidebarTenantNameContainer = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

import React, { useEffect, useState } from 'react';
import {
  PatchedUserPermissionEditRequest,
  Strategy,
  StrategyPermissionList,
  UserTenant,
} from '../../../../generated/tenants/Api';
import { H4, Button, COLOR_NEUTRAL_80 } from '../../../App.style';
import {
  UserStrategiesTableContainer,
  UserStrategiesTab,
  AdminViewContainer,
  UserDetailedRowButtonsContainer,
} from './UserDetailed.style';
import { EuiLoadingSpinner, EuiSuperSelect } from '@elastic/eui';
import {
  PermissionLevel,
  StrategyPermissionDisplay,
  UserRole,
  displayUserRole,
  getInitialRole,
  getUserRolesOptions,
  mapPermissionToData,
  permissionHierarchy,
} from '../list/userFunctions';
import { userPermissionsUpdateAsyncThunk } from '../api/userSlice';
import { useAppDispatch } from '../../../../common/hooks';
import {
  EditAccessContainer,
  UserStrategyPermissionRow,
  UsersListTableData,
  UsersListTableHeader,
} from '../Users.style';
import { EditIcon } from '../../../../resources/icons-new/EditIcon';
import { useTranslation } from 'react-i18next';

interface UserDetailedStrategiesTableProps {
  isAdminView: boolean;
  user: UserTenant;
  strategiesList: Strategy[];
  handleReload: () => void;
  showSuccessPermissionsPopup: () => void;
  showErrorPermissionsPopup: () => void;
}

const UserDetailedStrategiesTable: React.FC<
  UserDetailedStrategiesTableProps
> = ({
  isAdminView,
  user,
  strategiesList,
  handleReload,
  showSuccessPermissionsPopup,
  showErrorPermissionsPopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  //role and permissions
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>(
    getInitialRole(user),
  );

  const [selectedPermissions, setSelectedPermissions] = useState<
    StrategyPermissionList[]
  >(user.strategies_permissions);

  useEffect(() => {
    setSelectedUserRole(getInitialRole(user));
    setSelectedPermissions(user.strategies_permissions);
  }, [user, edit]);

  //permissions
  const getPermissionForStrategy = (strategyId: string): PermissionLevel => {
    const permissionList = selectedPermissions.find(
      (p) => p.strategy_id === strategyId,
    );

    if (!permissionList) {
      return 'no_permissions';
    }

    for (const level of Object.keys(permissionHierarchy) as PermissionLevel[]) {
      if (
        permissionHierarchy[level].every((val) =>
          permissionList.permissions.includes(val),
        )
      ) {
        return level;
      }
    }

    return 'no_permissions';
  };

  //handles the permission select change
  const handlePermissionChange = (
    strategyId: string,
    newPermission: PermissionLevel,
  ) => {
    const permissionsToUpdate = permissionHierarchy[newPermission] || [];

    const updatedPermissions = selectedPermissions.map((perm) =>
      perm.strategy_id === strategyId
        ? { ...perm, permissions: permissionsToUpdate }
        : perm,
    );

    // Check if the strategy already exists in the updatedPermissions array
    const strategyIndex = updatedPermissions.findIndex(
      (perm) => perm.strategy_id === strategyId,
    );

    // If it doesn't exist, add it
    if (strategyIndex === -1) {
      updatedPermissions.push({
        strategy_id: strategyId,
        permissions: permissionsToUpdate,
      });
    }

    setSelectedPermissions(updatedPermissions);
  };

  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);

  const handleCancelClick = () => {
    setSelectedPermissions(user.strategies_permissions);
    setSelectedUserRole(getInitialRole(user));
    handleReload();
  };

  //call to backend after clicking save
  const handleSaveClick = () => {
    setLoadingPermissions(true);
    const permissionArray = selectedPermissions.map((permission) => {
      return {
        strategy_id: permission.strategy_id,
        permission: mapPermissionToData(permission.permissions),
      };
    });

    const payload: { userId: string; data: PatchedUserPermissionEditRequest } =
      {
        userId: user.id,
        data: {
          tenant_owner: selectedUserRole === 'tenant_owner',
          tenant_admin: selectedUserRole === 'tenant_admin',
        },
      };

    if (selectedUserRole === 'user') {
      payload.data.strategies_permissions = permissionArray;
    }

    dispatch(userPermissionsUpdateAsyncThunk(payload))
      .then(() => {
        showSuccessPermissionsPopup();
      })
      .catch(() => {
        showErrorPermissionsPopup();
      })
      .finally(() => {
        handleCancelClick();
        setLoadingPermissions(false);
      });
  };

  const userRolesOptions = getUserRolesOptions(
    t('common.owner'),
    t('common.admin'),
    t('common.user'),
  );

  const noStrategiesAccess =
    !user.is_tenant_admin &&
    !user.is_tenant_owner &&
    user.strategies_permissions.length === 0;

  const renderStrategyPermissions = () => {
    if (noStrategiesAccess && !edit) {
      return (
        <H4 $grey $italic>
          {t('settings.adminPanel.users.table.row.noAccess')}
        </H4>
      );
    }

    if (selectedUserRole !== 'user') {
      return (
        <H4 $grey $italic>
          {t('settings.adminPanel.users.table.row.fullAccess')}
        </H4>
      );
    }

    return (
      <UserStrategyPermissionRow>
        {strategiesList.map((strategy) => {
          const currentPermission = getPermissionForStrategy(strategy.id);

          return (
            (currentPermission !== 'no_permissions' || edit) && (
              <StrategyPermissionDisplay
                key={strategy.id}
                strategy={strategy}
                currentPermission={currentPermission}
                isEditing={edit}
                handlePermissionChange={handlePermissionChange}
              />
            )
          );
        })}
      </UserStrategyPermissionRow>
    );
  };

  return (
    <UserStrategiesTableContainer>
      <UserStrategiesTab>
        <thead>
          <tr>
            <UsersListTableHeader userRole>
              <H4 $grey>{t('settings.adminPanel.users.table.role')}</H4>
            </UsersListTableHeader>

            <UsersListTableHeader permissions>
              <H4 $grey>{t('settings.adminPanel.users.table.permissions')}</H4>
            </UsersListTableHeader>

            <UsersListTableHeader></UsersListTableHeader>

            <UsersListTableHeader pagination></UsersListTableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <UsersListTableData userRole>
              <div style={{ width: 120 }}>
                {edit ? (
                  <EuiSuperSelect
                    compressed
                    options={userRolesOptions}
                    valueOfSelected={selectedUserRole}
                    onChange={(e) => setSelectedUserRole(e as UserRole)}
                    itemLayoutAlign="top"
                  />
                ) : (
                  <>
                    {displayUserRole(
                      user,
                      t('common.owner'),
                      t('common.admin'),
                      t('common.user'),
                    )}
                  </>
                )}
              </div>
            </UsersListTableData>

            <UsersListTableData permissions>
              {loadingPermissions ? (
                <EuiLoadingSpinner />
              ) : (
                renderStrategyPermissions()
              )}
            </UsersListTableData>

            <UsersListTableData></UsersListTableData>

            <UsersListTableData pagination>
              {isAdminView && (
                <AdminViewContainer edit={edit}>
                  {edit ? (
                    <UserDetailedRowButtonsContainer>
                      <Button $size={'S'} onClick={handleSaveClick}>
                        {t('common.save')}
                      </Button>
                      <Button
                        $size={'S'}
                        $styleType="NORMAL"
                        onClick={handleCancelClick}
                      >
                        {t('common.cancel')}
                      </Button>
                    </UserDetailedRowButtonsContainer>
                  ) : (
                    <EditAccessContainer onClick={() => setEdit(true)}>
                      <H4 $grey>
                        {t('settings.adminPanel.users.table.row.editAccess')}
                      </H4>
                      <EditIcon color={COLOR_NEUTRAL_80} />
                    </EditAccessContainer>
                  )}
                </AdminViewContainer>
              )}
            </UsersListTableData>
          </tr>
        </tbody>
      </UserStrategiesTab>
    </UserStrategiesTableContainer>
  );
};

export default UserDetailedStrategiesTable;

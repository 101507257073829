export const translationsEn = {
  cookiesToastTitle: 'We use cookies!',
  strenghtBar: {
    tooShort: 'Too short',
    okay: 'Ok',
    strong: 'Strong',
    weak: 'Weak',
    good: 'Good',
  },
  login: {
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot password? click ',
    login: 'Login',
    error: {
      line1: `Sorry, that's not the right email or password.`,
      line2: 'Please try again.',
    },

    resetPassword: {
      modal: {
        title: 'Password reset',
        confirmation: 'Confirmation',
        tip: 'Please provide account email address, you will receive password reset link there.',
        after:
          'A password reset link has been sent to the provided e-mail address',
        notValidEmail: 'Thats not a valid email',
      },
      form: {
        wrongToken: `The link to change your password has expired. Please return to the homepage and try again`,
        backToLogin: 'Back to login',
        newPassword: 'new password',
        confirmNewPassword: 'confirm new password',
        tooShort: `Your password has to be at least 12 characters long and both the password and the confirmation password must match. Please try again.`,
        changed: 'Password changed',
      },
    },
  },

  //super admin panel
  superAdminPanel: {
    sidebar: {
      selectTenant: 'Select tenant',
      superUsers: 'Superusers',
      numbers: {
        title: 'Numbers',
        add: 'Add',
        assign: 'Assign to tenant',
        history: 'History',
      },
      billings: 'Billings',
    },

    tenants: {
      settings: 'Tenant settings',
      general: 'General',
      priceAndBillings: 'Price and billings',
      language: 'Language settings',
      sms: 'SMS',
      changesSaved: 'Changes saved!',
      error: 'Unable to save, the form contains errors',
      list: {
        title: 'Select tenant',
        create: 'Create new tenant',
        goTo: 'Go to tenant',
      },
      form: {
        details: 'Organization Details',
        name: 'Tenant name',
        slugTooltip:
          'Domain prefix for a tenant. No spaces or special characters.',
        lines: 'Lines',
        globalSettings: 'Global settings',
        defaultLanguage: 'Default language',
        timezone: 'Time zone',
        biznesOwnerDetails: 'Biznes owner details',
        delete: 'Delete account',
        deleteThisTenant: 'Delete this tenant account',
        edit: 'Edit tenant settings',
        create: 'Create new tenant',
        billings: {
          billingPlan: 'Billing model',
          perCall: 'Model: Per call rate',
          perRecord: 'Model: Per record rate',
          pricingTelco: 'Price: Telco',
          mobile: 'Mobile',
          domesticMobile: 'Domestic calls. Price per minute',
          internationalMobile: 'International calls. Price per minute',
          landline: 'Landline',
          domesticLandline: 'Domestic calls. Price per minute',
          internationalLandline: 'International calls. Price per minute',
          smsPrice: 'SMS rate (PLN)',
        },
      },
      deleteModal: {
        modalTitle: 'Delete tenant',
        buttonText: 'Delete permamently',
        line1: 'Are you sure you want to remove this tenant?',
        line2: 'Confirm it by writing tenant name here:',
      },
    },

    superUsers: {
      title: 'Lekta Superusers',
      invite: 'Invite superuser',

      inviteModal: {
        title: 'Invite new Lekta superuser',
        emailError: 'Provide a valid e-mail',
        role: 'Role',
        buttonText: 'Invite',
      },

      table: {
        username: 'User name',
        role: 'Role',
        permissions: 'Permissions',
        lastLogin: 'Last login',
        notKnown: 'Not known',
        noUsers: 'No superusers to show',
        full: 'full',
        viewOnly: 'view only',
        notLogged: 'Not logged in yet',
        editAccess: 'Edit access',
      },

      invitation: {
        badToken: 'Bad invitation token',
        error: 'Error',
        step1: {
          hello: 'Hello!',
          invited: 'You have been invited to Lekta Lease Platform!',
          passwordsNotMatch: 'Passwords do not match',
          introduce: 'Step 1. Please introduce yourself',
          firstName: 'First name',
          lastName: 'Last name',
        },
        step2: {
          createPassword:
            ' Step 2. Create a password. Password should include at least 12 letters.',
          password: 'Password',
          repeatPassword: 'Repeat password',
          join: 'Join',
        },
        step3: {
          userCreated: 'User created',
          redirect: 'redirect to tenants',
        },
      },

      popups: {
        permissionEditSuccess: 'Successfully edited permission',
        permissionEditError: 'something went wrong with changing permissions',
        invited: 'Superuser has been invited.',
      },
    },

    numbers: {
      add: {
        title: 'Add new numbers',
        operator: 'Operator tenant:',
        info: `Please enter the numbers into the text field in the form of a list (in each line one number OR after a comma OR after a semicolon)`,
        directToTenant: 'Assign numbers directly to the Tenant',
        assignedNumbers: 'Already assigned numbers: ',
        wrongNumbers: 'Wrong numbers: ',
        addedNumbers: 'Added numbers: ',
        text: 'Add numbers',
        popups: {
          successAdd: 'Numbers have been added to Lekta Platform',
          successAddTenant: 'Numbers have been added to the tenant {{tenant}}',
          error: 'Something went wrong. Try again.',
        },
      },
      assign: {
        title: 'Assign to tenants',
        all: 'All numbers',
        free: 'Free numbers',
        empty: 'No more numbers to show',
        tenant: 'Tenant',
        copy: 'Copy',
        copyNumber: 'Copy number',
        copiedNumber: 'Number copied to clipboard',
        popups: {
          success: `Numbers have been assigned to tenant acount. They are now available for use.`,
          error:
            'Something went wrong while assigning numbers. Please try again.',
        },
      },

      history: {
        title: 'Numbers history',
        select: 'Select number',
        historyFor: 'History for number:    ',
        empty: 'No history for this number',
        goToCampaign: 'Go to campaign',
        copyCampaignID: 'Copy campaign ID',
      },
    },
  },

  navbar: {
    adminPanel: 'Admin panel',
    backToApp: 'Back to app',
    switchTo: 'Switch to',
  },

  settings: {
    adminPanel: {
      sidebar: {
        users: 'Users',
        numbers: 'Numbers',
        assignTo: 'Manage numbers',
        history: 'History',
        addNew: 'Add new',
        sftp: 'SFTP Settings',
        manageWorkspaces: 'Manage workspace',
      },

      accessTokens: {
        title: 'Access tokens',
        buttonText: 'Create new token',
        table: {
          tokenName: 'Token name',
          created: 'Created',
          lastUsed: 'Last used',
          expirationDate: 'Expiration date',
          create: 'Create access token',
          alert: 'Access token expiration date cannot be earlier than today.',
          empty: 'No access tokens to show',
          token_expired: 'token expired',
          today: 'today',
          expiring_in_one_day: 'in 1 day',
          expiring_in: 'in {{count}} days',
        },
        deleteTitle: 'Delete token',
        deleteText: 'Are you sure you want to delete this token?',
        preview: {
          loadingTitle: 'Creating token...',
          successTitle: 'Token created',
          failTitle: 'Failed to create token',
          yourToken: 'Your access token',
          tokenInfo1: `Make sure you copy and save this token, because you won't`,
          tokenInfo2: 'be able to see it again!',
          failMessage:
            'Sorry, something went wrong while creating new access tokent',
          copyText: 'Copy to clipboard',
          textCopied: 'Access token copied to clipboard',
        },
      },

      users: {
        title: 'Users',
        invite: 'Invite new user',
        all: 'All',
        changeAvatar: 'change avatar',
        deleteAccount: 'delete account',
        deactivate: 'deactivate',
        emailAddress: 'Email address: ',
        lastActivity: 'date of last activity: ',

        inviteModal: {
          form: {
            email: 'Email',
            notValidEmail: ' Thats not a valid email',
            role: 'Role',
            workspace: 'Workspace',
          },
        },

        deleteModal: {
          line1: 'Are you sure you want to delete user with mail: ',
          line2: 'Are you sure you want to delete your account?',
          title: 'Delete user',
        },
        deactivateModal: {
          line1: 'Are you sure you want to deactivate user with mail: ',
          line2: 'Are you sure you want to deactivate your account?',
          title: 'Deactivate user',

          line1activate: 'Are you sure you want to activate user with mail: ',
          line2activate: 'Are you sure you want to activate your account?',
          titleactivate: 'Activate user',
        },

        table: {
          username: 'Username',
          role: 'Role',
          permissions: 'Permissions',
          lastLogin: 'Last login',
          noUsers: 'No users to show',
          row: {
            noAccess: `User doesn't have access to any strategies`,
            fullAccess: ` Access to all strategies with all permissions`,
            notLogged: 'Not logged in yet',
            editAccess: 'Edit access',
          },
        },

        popups: {
          permissionsEdited: 'Succesfully edited permissions.',
          error: 'Something went wrong, try again.',
          userInvited: 'User has been invited.',
          avatarChanged: 'Avatar was successfully changed.',
          errorAvatarChange:
            'Something went wrong, make sure the file is less than 1MB.',
        },
      },
      numbers: {
        assign: {
          title: 'Manage numbers',
          all: 'Unassigned numbers',
          free: 'Free numbers across the tenant account',
          empty: 'No more numbers to show',
          workspace: 'Workspace',
          replace: 'replace',
          unassign: 'unassign',
          replaceModal: {
            title: 'Replace number',
            numberToReplace: 'Number you want to replace:',
            replaceTo: 'Replace to:',
          },
          unassignModal: {
            title: 'Unassigning number',
            text: 'Are you sure you want unassign number {{number}} from workspace {{strategyName}}?',
          },
          popups: {
            success:
              'Numbers have been assigned to strategies. They are now available for use.',
            error:
              'Something went wrong while assigning numbers. Please try again.',
          },
        },
      },
      sftp: {
        title: 'Uploading Record Data to SFTP',
        username: 'Username (login)',
        password: 'Password',
        changePassword: 'change password',
        setPassword: 'set password',
        alreadyPassword:
          'The password has already been set, but can still be configured',
        viaKeys: 'Authorization via keys',
        keysTooltip:
          'the entered public key allows for key-based authentication instead of password.',
        trustedKeys: 'Trusted keys',
        noKeys: 'No keys to show',
        addKey: 'Add new RSA Key +',
        publicKey: 'Lekta SFTP public key',
        copySSH: 'Copy SSH key',
        copiedSSH: 'SSH key copied to clipboard',
        passwordChange: {
          notEqual: 'Passwords not equal',
          newPassword: 'New password',
          confirmPassword: 'Confirm password',
        },
        deleteKeyQuestion: 'Are you sure you want to remove this RSA Key?',
        deleteKey: 'Delete RSA Key',
        addKeyTitle: 'Add new RSA key',
        notValidKey: 'Provided public key is not valid',
        name: 'Name',
        publicKeyTitle: 'Public key',
        rsa: 'RSA Configuration',
        removeSftp: 'Remove SFTP Configuration',
        question: 'Are you sure you want to remove this SFTP configuration?',

        list: {
          title: 'Configuring the Connection to the SFTP Server',
          host: 'Host',
          port: 'Port',
          authorization: 'Authorizatoin',
          username: 'Username',
          details: 'Details',
          addNew: 'Add new configuration',
          checking: 'Checking...',
          correctly: 'Correctly verified',
          failed: 'Connection failed',
          test: 'Test',
          delete: 'Delete',
        },
        form: {
          password: 'password',
          rsaKey: 'RSA Key',
          authMethod: 'Authentication method',
          publicKey: 'Lekta public key',
          publicKeyCopied: 'Public key copied',
          publicKeyCopy: 'Copy public key',
          request:
            'We request to add the Lekta account to the trusted public key.',
          test: 'Test SFTP',
          edit: 'Edit SFTP Configuration',
        },
      },
      manageWorkspaces: {
        title: 'Manage workspaces',
        createNew: 'Create new',
        edit: 'Edit workspace',
        list: {
          accordion1title: 'Active workspaces',
          accordion2title: 'Archived workspaces',
          assignedLines: 'assigned lines: ',
          created: 'created ',
          modified: 'modified ',
        },
        form: {
          gpkNotEmpty: 'Public GPG key cannot be empty',
          name: 'Name',
          workspaceName: 'Workspace name',
          assignedLines: 'Assigned lines',
          createDemoResources: 'Create demo resources? ',
          createNewWorkspace: 'Create new workspace',
          noLines:
            'The number of assigned lines is greater than the available ones',
          freeLines: 'Free lines: ',
          encryptImportedData: 'Encrypt imported data',
          encryptImportedDataTooltip: `Use this public key to encrypt files before uploading. Encrypted files should have .gpg extension. System will use generated key to decrypt the file. Files uploaded to Lekta SFTP server will also use this configuration.`,
          yes: 'yes',
          no: 'no',
          generateNewPublicKey: 'Generate new public key',
          publicKey: 'Public key',
          noPublicKey: 'No key generated',
          notDefined: 'Not defined',
          publicKeyCopied: 'Public key copied to clipboard',
          publicKeyCopy: 'Copy public key',
          encryptOutgoingData: 'Encrypt outgoing data',
          encryptOutgoingDataTooltip: `Provided public key will be used to encrypt downloaded output files. Output files uploaded to Lekta SFTP server will also be encrypted.`,
          publicGpgKey: 'Public GPG key',
          restoreQuestion: 'Are you sure you want to restore this workspace?',
          restore: 'Restore workspace: ',
          deleteUsingNow:
            'You are currently using this workspace, after deletion you will be redirected to the selection.',
          delete: 'Are you sure you want to remove this workspace?',
          remove: 'Remove workspace: ',
          archiveTitle: 'Are you sure you want to archive this workspace?',
          archiveText: `Once archived, this workspace will be accessible in the 'Archived Strategies' panel.`,
          archive: 'Archive workspace: ',
        },
      },
    },
    users: {
      sidebar: {
        title: 'User settings',
        profile: 'Profile',
        security: 'Security',
        password: 'Password',
        accessTokens: 'Access Tokens',
        language: 'Language',
        notifications: 'Notifications',
      },
      passwordChange: {
        title: 'Change password',
        current: 'Current password',
        new: 'New password',
        success: 'Your password was sucessfully changed',
      },
      language: 'Language',
      notifications: {
        about: 'Send me emails about...',
        title: 'Notifications',
        creatingNewCampaign: 'Creating new campaign',
        statusChangeCampaign: 'Changes in the status campaign',
        pending: 'Pending',
        scheduled: 'Scheduled',
        running: 'Running',
        stopped: 'Finished M.',
        finished: 'Finished',
        error: 'Error',
        outboundPaused: 'Outbound paused',
        inboundPaused: 'Inbound paused',
        ccPaused: 'CC paused',
        importNewRecords: 'Import of the new records to campaign',
        changesProcess: 'Changes in process',
      },
    },
  },

  reports: {
    reportPerCall: 'Report per call',
    reportPerRecord: 'Report per record',
    reportPerCampaign: 'Report per campaign',
    processIs: 'Process is ',
    campaign: 'Campaign',
    process: 'Process',
    time: 'Time',
    successPopup: 'Report downloaded succesfully',
    reports: 'Reports',
    reportType: 'Report type',
    fileFormat: 'File format',
    reportFilters: 'Report filters',
    campaignOrTime: 'Campaign or time',
    columnsInReport: 'Columns in report',
    downloadReport: 'Download report',
    clearForm: 'Clear form',
    noColumnsSelected: 'Please select at least 1 column.',
    selectCampaign: 'Select campaign',
    campaignIs: 'Campaign is',
    noCampaigns: 'No campaigns with this id',
    removeSelection: 'remove selection',
    apply: 'apply',
  },

  presets: {
    title: 'Presets',
    voices: 'Voices',
    dialogues: 'Dialogues',
    callingPreferences: 'Calling preferences',
    calendar: 'Calendar',
    callCenterDaysOff: 'Call center-days off',
    botDaysOff: 'Bot-days off',
    labels: 'Labels',
    customized: 'customized',
    created: 'created ',
    modified: 'modified ',
    associatedWith: 'associated with processes:',
    noProcess: 'no process is associated with this preset',
    deleteQuestion: 'Are you sure you want to remove this',
    noProcesses: 'No processes',
    associatedline1:
      'Note: Editing this preset will update all associated processes that utilize it, including: ',
    associatedline2: 'associated with processes',
    addNewLabel: 'Create new label',

    voicesForm: {
      name: 'Name',
      language: 'Language',
      languageTooltip:
        'Specifies the language in which the communication bot will read statements.',
      gender: 'Gender',
      genderTooltip: `This field allows the selection of the bot's voice gender.`,
      baseTtsSpeed: 'Base TTS Speed Percentage',
      baseTtsSpeedTooltip:
        'This field determines the speed at which the bot reads statements, expressed as a percentage. 100% is the standard speed. Values below slow down, and above speed up the speech.',
      asrVendor: 'ASR Vendor',
      asrVendorTooltip: `This parameter refers to the choice of speech recognition technology provider, which converts the user's speech into text.`,
      ttsVendor: 'TTS Vendor',
      ttsVendorTooltip:
        'This parameter refers to the choice of technology provider that converts the text generated by the bot into speech.',
      voiceName: 'Voice name',
      voiceNameTooltip:
        'Selection of the voice model available within the provider.',
      noVoices: 'No voices for this configuration',
    },
    labelsForm: {
      selectLabels: 'Select labels',
      color1: 'Soft Camel',
      color2: 'Pasel Lilac',
      color3: 'Delicate Eucalyptus',
      color4: 'Pastel Sky',
      color5: 'Earthy Brown',
      color6: 'Light Sandalwood',
      color7: 'Pale Amber',
      color8: 'Light Pistachio',
      name: 'Name',
      selectColor: 'Select Color',
      createNewLabel: 'Create new label',
      editLabel: 'Edit label',
      deleteQuestion: 'Are you sure you want to remove this label?',
      deleteLabel: 'Delete label: ',
    },
    daysOff: {
      holidaysDate: 'Holidays date',
      nothingToShow: 'Nothing to show',
      dateAlreadyAdded: 'Date already added',
      name: 'Name',
    },
    callingPreferencesForm: {
      static: 'Static',
      dynamic: 'Dynamic',
      randomized: 'Randomized',
      from: 'From: ',
      to: 'To: ',
      interval: 'Interval: ',
      intervalError: 'Cannot add new time set as it would go over 24:00',
      endTimeError: 'End time must be higher than start time',
      periodsCantOverlap: "Periods can't overlap or touch",
      name: 'Name',
      callAttempts: 'Total call attempts',
      callAttemptsTooltip:
        'Defines the maximum number of attempts to make a phone call to a given record within a single campaign. If the limit is reached, further attempts to this number will not be made.',
      dailyCallAttempts: 'Daily call attempts',
      dailyCallAttemptsTooltip:
        'The maximum number of attempts to make a connection to a given number within a single day. If this number is reached, the bot will wait until the next day before making further attempts.',
      restDays: `Retry call delay`,
      restDaysTooltip:
        'This field specifies the number of days that must pass after reaching the daily limit of connection attempts before the bot will contact again. This parameter applies only to multi-day campaigns.',
      intervalBetweenAttempts: 'Interval between contact attempts',
      intervalBetweenAttemptsTooltip: `Static: This refers to a constant value of the interval between contact attempts. In this mode, every attempt to contact is made after a fixed amount of time, regardless of other factors. Dynamic: The value of the interval depends on the time of day. This means the frequency of contact attempts might change based on the time, possibly to align with when people are more likely to be available or responsive. Randomized: The value is chosen randomly from a range of intervals. This approach introduces variability in the timing of contact attempts, which could be useful to simulate more natural interaction patterns or to avoid predictability in the contact schedule.`,
      timeBetweenAttemptsSeconds: 'Minimum time between attempts (HH:mm)',
      timeBetweenAttemptsSecondsTooltip:
        'The minimum interval between subsequent contact attempts. Calendar settings take precedence." This means that when scheduling contact attempts (like in a call or outreach campaign), there should be a minimum time set between each try. However, if there are calendar settings or rules in place, those will override this minimum interval setting.',

      minimal: 'Minimal',
      maximal: 'Maximal',
      minimalError: 'Minimal has to be lower than Maximal',
      timeRange: 'Time range',
      timeRangeTooltip: 'Minimum interval between the next contact attempt',
      addPeriod: 'Add period',
      basicInterval: 'Basic interval',
      basicIntervalTooltip: 'Interval used outside of configured time range',
    },
    calendarForm: {
      timezone: 'Timezone',
      workingHours: 'Working hours',
      name: 'Name',
      outbound: 'Outbound',
      inbound: 'Inbound',
      cc: 'Call center',
      notDefined: 'Not defined',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      addPeriod: 'Add period',
      periodsOverlap: 'Periods overlap',
      periodsError: 'Periods should not end and start at the same hour',
      endTimeGreater: 'End time must be greater than or equal to start time',
      inboundShorter: 'Inbound is shorter than outbound',
      outboundOutside: 'Outbound is outside of inbound',
      nextInterval: `Next interval would be outside of bounds, correct previous intervals if you want to add new`,
    },
  },
  processes: {
    title: 'Processes',
    addNewColumn: 'Add new column',
    noCampaigns: 'No campaigns to show',
    seeCampaigns: 'See campaigns',
    uncategorized: 'Uncategorized',
    noProcesses: 'No processes to show',
    new: 'New process',
    customizeView: 'Customize view',
    campaignStatus: {
      pending: 'PENDING',
      scheduled: 'SCHEDULED',
      running: 'RUNNING',
      paused: 'PAUSED',
      stopped: 'FINISHED M.',
      finished: 'FINISHED',
      error: 'ERROR',
    },
    modals: {
      nameInUse: 'Name already in use',
      lessThan: 'Column name length must be less than 16 characters',
      name: 'Name',
      createNewColumn: 'Create new column',
      nameOfProcess: 'Name of the process',
      processNameError: 'Process with this name already exist',
      createNewProcess: 'Create new process',
      deleteColumnQuestion: 'Are you sure you want to remove this column?',
      removeColumn: 'Remove column: ',
      deleteProcessQuestion: 'Are you sure you want to remove this process?',
      removeProcess: 'Remove process: ',
      currentName: 'Current name',
      newName: 'New name',
      changeColumnName: 'Change column name',
    },
    form: {
      detailedTitle: 'Process details: ',
      title: 'Process configuration: ',
      buttonDisabledText:
        'Publishing process will be accessible once all the required information and settings are provided.',
      publishProcess: 'Publish process',
      editProcess: 'Edit this process',
      tabs: {
        general: 'General',
        dialogue: 'Dialogue',
        voice: 'Voice',
        callingPreferences: 'Calling Preferences',
        campaignPreferences: 'Campaign Preferences',
        calendar: 'Calendar',
      },

      general: {
        add: 'Add',
        update: 'Update',
        yes: 'yes',
        additionalLinesYes: 'Additional free lines avaible will be used',
        no: 'no',
        archive: 'archive',
        archiveTooltip:
          'Files will be moved to the archive folder after processing.',
        delete: 'delete',
        deleteTooltip: 'Files will be permanently deleted after processing.',

        additionalLinesNo:
          'Additional free lines will not be used, even if available.',
        currentDay: 'Current day',
        full: 'Full',
        processName: 'Process name',
        labels: 'Labelki',
        description: 'Description',
        csvImportMode: 'CSV Import Mode',
        csvImportModeTooltip1: `While uploading a csv file, new records are created for each row, and any existing records with a matching 'external_id' are closed.`,
        csvImportModeTooltip2:
          'While uploading a csv file, every row will result in the creation of a new record.',
        guarantedLines: 'Guaranteed lines',
        guarantedLinesTooltip:
          'The number of simultaneous contact attempts made by the bot / the number of telecommunication lines.',
        dynamicLinesUsage: 'Dynamic lines usage',
        dynamicLinesUsageTooltip: `This option lets you choose between maximizing efficiency by using all available lines dynamically for launched companies or keeping a static configuration that consistently uses the number of lines set in the preferences.`,
        sftp: 'SFTP data transfer',
        inGlob: 'Imported files path pattern/ in glob',
        inGlobTooltip: `This field is used to define the pattern of file paths that are to be imported. It allows filtering files from the SFTP server based on their names and extensions. 'Glob' is a standard pattern language used for matching files and directories. For example, *.csv means that only files with the .csv extension will be imported.`,
        archivePath: 'Archive path',
        archivePathTooltip:
          'The path where imported files will be stored in the archive after processing.',
        deleteArchived: 'Managing CSV customer databases after processing',
        deleteArchivedTooltip: 'Should the imported files be archived?',
        recordingsFormat: 'Recordings format',
        importFileHandling: 'CSV File import mode',
        importFileHandlingTooltip1:
          'Choosing this option will create a new campaign based on the data contained in the imported file. All information will be used to initiate a new campaign.',
        importFileHandlingTooltip2:
          'This option allows for updating an existing campaign with records from a new file. Note: if the campaign does not exist, a new campaign will be created.',
        externalSftpRecordings: 'External sftp recordings upload path',
        externalSftpRecordingsTooltip: `This field specifies the path on an external SFTP server where the recordings will be uploaded. Make sure you have granted the appropriate write permissions at this location on your SFTP server.`,
        externalSftpReports: 'External sftp reports upload path',
        externalSftpReportsTooltip: `This field specifies the path on the external SFTP server where reports will be sent. Ensure that you have granted the appropriate write permissions for this location on your SFTP server.`,
        externalSftpTranscript: 'External transcripts upload path',
        externalSftpTranscriptTooltip:
          'This field specifies the path on the external SFTP server where transcriptions will be sent. Ensure that you have granted the appropriate write permissions for this location on your SFTP server.',
        uploadReports: 'Which daily reports should be sent to SFTP?',
        cyclicReports: 'Which cyclic reports should be sent to SFTP?',
        perCall: 'per call',
        perRecord: 'per record',
        perCampaign: 'per campaign',
        generateReportDaily: 'Generate report daily?',
        dailyReportType: 'Daily report type',
        currentDay1:
          'Current Day specifies that the report will only include data from the current day.',
        currentDay2:
          'It includes data from the very beginning of the campaign, showing cumulative results, meaning all the data collected from the start of the campaign up to the moment the report is generated.',
        send: 'send',
        dailyReport: 'daily reports',
        cyclicReport: 'cyclic reports',
        frequency: 'Frequency',
        dailyDataRange: 'Data range for daily report',
        cyclicDataRange: 'Data range for cyclic report',
      },
      dialogue: {
        newFaas: 'Create new preset for custom faas',
        dialoguePreset: 'Dialogue preset',
        importFaas: 'import FAAS',
      },
      voices: {
        newVoice: 'Create new preset for voice',
        voicePreset: 'Voice preset',
        male: 'Male',
        female: 'Female',
      },
      callingPreferences: {
        newCallingPreference: 'Create new preset for calling preference',
        callingPreferencePreset: 'Calling preferences preset',
        phoneNumbers: 'Telephone numbers*',
        phoneNumbersTooltip:
          'The field where you can enter phone numbers from which the campaign will be dialed.',
        atLeast: 'You have to choose at least one number',
        note: `Note: when selecting multiple numbers, the bot will prioritize calling from different numbers as long as possible `,
      },
      calendar: {
        newCalendar: 'Create new preset for calendar',
        newPresetCC: 'Create new preset for call center - days off',
        newPresetBot: 'Create new preset for bot - days off',
        selectCalendar: 'Select calendar preset',
        timezone: 'Timezone',
        daysOffCC: 'Call center - days off',
        nothing: 'Nothing to show',
        daysOffBot: 'Bot - Days off',
      },
      campaignPreferences: {
        caller: 'Caller',
        campaign: 'Campaign',
        inboundEnabled: 'Inbound will be active for a closed record?',
        directTransfer: `The incoming call (inbound) will be communicated with: `,
        redirect:
          'The number that will be displayed after the call is forwarded to CC',
        campaignDuration: 'Default campaign duration (in days)',
        callCenterNumber: 'Call center telephone number',
        fallbackMessage:
          'The message that will be spoken by the bot if inbound is closed.',
      },
    },
  },

  campaigns: {
    campaign: 'Campaign: ',
    outbound: 'Outbound',
    inbound: 'Inbound',
    cc: 'Call center',
    open: 'OPEN',
    paused: 'PAUSED',
    start: 'Start',
    end: 'End',
    changeStatus: 'Change status',
    finish: 'Finish',
    idCopied: 'ID copied to clipboard',
    copyID: 'Copy campaign ID',
    showCampaignNumbers: 'Show campaign numbers',
    goToStats: 'Go to analytics',
    goToReports: 'Go to reports',
    download: 'Download ',
    transcripts: 'Transcripts ',
    recordings: 'Recording ',
    customReport: 'Custom report',
    success: 'Campaign was successfully created',
    optimizationPopup:
      'The campaign will restart and will not be available for a few minutes. During this time, no calls will be made.',
    optimizationInfo: {
      line1: 'NOTE: ',
      line2: `When you save changes, the campaign will restart and will not be available for a few minutes. During this time, no calls will be made.`,
    },
    serviceStatuses: {
      open: 'OPEN',
      paused: 'PAUSED',
      disabled: 'DISABLED',
      closed: 'CLOSED',
    },
    createModal: {
      title: 'Create campaign',
      titleLoading: 'Creating campaign...',
      selectProcess: 'Select process',
      noProcesses: 'No processes to select',
      startEndDates: 'Start-End dates',
      requestedLines: 'Requested lines: ',
      availableLines: 'Available lines: ',
      linesError: 'Requested lines exceed available lines.',
    },
    finishModal: {
      title: 'Finish campaign',
      text1: 'Are you sure you want to finish this campaign?',
      text2: 'This action is irreversible.',
    },
    numbersModal: {
      title: 'Numbers used in this campaign',
    },
    snapshots: {
      general: 'General',
      dialogue: 'Dialogue',
      voice: 'Voice',
      callingPreferences: 'Calling preferences',
      campaignPreferences: 'Campaign preferences',
      calendar: 'Calendar',
    },

    bar: {
      campaigns: 'Campaigns',
      newCampaign: 'New campaign',
      filters: {
        title: 'Filter',
        process: 'Process',
        processIs: 'Process is ',
        status: 'Status',
        statusIs: 'Status is ',
        date: 'Date',
        dateIs: 'Date is ',
        remove: 'remove',
        apply: 'Apply',
      },
    },

    table: {
      startDate: 'Start date',
      endDate: 'End date',
      process: 'Process',
      status: 'Status',
      details: 'Details',
      doneTotal: 'Done / Total',
      doneTotalTooltip:
        'Shows the number of records called out of all records within the campaign',
      labels: 'Process labels',
      noCampaigns: 'No campaigns to show',
      outbound: 'outbound',
      inbound: 'inbound',
      cc: 'call center',
      seeMore: 'see more',
    },

    history: {
      INITIALIZE: 'Campaign created',
      DEPLOY: 'Campaign ready',
      RUN: 'Campaign running',
      PAUSE_RUNNING: 'Campaign paused',
      UNPAUSE_RUNNING: 'Campaign resumed',
      PAUSE_SCHEDULED: 'Campaign paused',
      UNPAUSE_SCHEDULED: 'Campaign resumed',
      ERROR: 'Error',
      STOP: 'Campaign finished manually',
      FINISH: 'Campaign finished',
      PAUSE_INBOUND: 'Inbound calls paused',
      UNPAUSE_INBOUND: 'Inbound calls resumed',
      PAUSE_CC: 'Call center redirection paused',
      UNPAUSE_CC: 'Call center redirection resumed',
      UPLOAD_RECORDS: 'New records uploaded',
      userNotFound: 'User not found',
      byLog: 'by {{firstName}} {{lastName}}',
      byUser: 'by User',
      tokenInfoNotAvailable: 'Token information not available',
      byToken: 'by TOKEN',
      viaSftp: 'via SFTP',
      byAdmin: 'by Lekta admin',
    },

    records: {
      campaignTabs: {
        records: 'Records',
        calls: 'Calls',
        history: 'History',
        processSnapshot: 'Process snapshot',
        optimization: 'Optimization',
      },
      table: {
        telephone: 'Telephone number',
        recordId: 'Record ID',
        importedDate: 'Imported date',
        source: 'Source',
        recordStatus: 'Record status',
        recordDetails: 'Record details',
        noDCM: `Couldn't find number in dialogue context mapping`,
        noRecords: 'No records to show',
        idCopied: 'ID copied to clipboard',
        copyID: 'copy ID',
        callsHistory: 'Calls history',
        open: 'OPEN',
        finished: 'FINISHED',
        cancelled: 'CANCELLED',
        exhausted: 'UNSUCCESSFUL',
      },
      list: {
        uploadRecordsTitle: 'Upload records for this campaign',
        uploadRecords: 'Upload records',
      },
      uploadModal: {
        csv: 'Currently only csv and xlsx file format is supported',
        uploadImportHere: 'Upload import file here',
        fileErrorGpg:
          'Incorrect file format. Supported formats: csv, xlsx or gpg',
        fileErrorCsv: 'Incorrect file format. Supported formats: csv or xlsx',
        fileErrorEmpty: 'Provided file is empty',
        somethingWentWrong:
          'Something went wrong, your file did not process correctly.',
        uploadingRecords: 'Uploading records...',
        verifiedRecords: 'Verified records',
        recordsFailed: 'Records failed: ',
        row: 'Row',
        recordsVerified: 'Records verified successfully ',
      },
      filters: {
        title: 'Filter',
        allFiltersApplied: 'All filters are applied',
        source: 'Source',
        sourceIs: 'Source is ',
        status: 'Status',
        statusIs: 'Status is ',
        remove: 'remove',
        apply: 'Apply',
      },
    },
    calls: {
      filters: {
        VOICEMAIL: 'Voicemail',
        ANSWERED: 'Answered',
        NOT_ANSWERED: 'Not Answered',
        REJECTED: 'Rejected',
        BUSY: 'Busy',
        TRANSFER: 'Transfer',
        NONEXISTENT_NUMBER: 'Nonexistent Number',
        UNMAPPED: 'Unmapped',
        ERROR: 'Error',
        title: 'Filter',

        allFiltersApplied: 'All filters are applied',
        date: 'Date',
        dateIs: 'Date is',
        status: 'Status',
        statusIs: 'Status is ',
        callType: 'Call type',
        callTypeIs: 'Call type is ',
        remove: 'remove',
        apply: 'Apply',
        inbound: 'inbound',
        outbound: 'outbound',
      },
      list: {
        selected: 'Selected:',
        downloadTranscripts: 'Download transcripts',
        downloadRecordings: 'Download recordings',
        date: 'Date',
        hour: 'Hour',
        botNumber: 'Bot number',
        type: 'Type',
        customNumber: 'Client number',
        telcoStatus: 'Telco status',
        noCalls: 'No calls to show',
        seeDetails: 'see details',
      },
      detailed: {
        dashboard: 'Dashboard',
        campaigns: 'Campaigns',
        campaignNr: 'Campaign nr ',
        callNr: 'Call nr ',
        to: 'to',
        outboundCallFrom: 'Outbound call from: ',
        downloadReport: 'Download report',
        callId: 'Call ID',
        idCopied: 'ID copied to clipboard',
        copyId: 'Copy ID',
        dateAndTimeCallStart: 'Date and time call start',
        callDuration: 'Call duration',
        campaign: 'Campaign',
        openingHours: 'Opening hours',
        callStatus: 'Call status',
        callsFromThisRecord: 'Calls from this record',
        recording: 'Recording',
        downloadRecording: 'Download recording',
        noRecordings: 'No recording available for this call',
        transcript: 'Transcript',
        downloadTranscript: 'Download transcript',
        noTranscripts: 'No transcript available for this call',
        lektaBot: 'Lekta bot',
        client: 'Client',
        noRecording: 'No recording available for this call',
        callsFromRecord: 'Calls from this record id: ',
      },
    },
  },

  analytics: {
    panel: {
      title: 'Analytics',
      chooseProcess: 'Choose a process',
      filterType: 'Filter type',
      timeRange: 'Select time range',
      selectCampaigns: 'Select campaigns',
      clearFilters: 'Clear filters',
      selectedCampaigns: 'Selected campaigns:',
      tab: {
        overview: 'Overview',
        outbound_per_day: 'Bot Efficiency',
        outbound_per_hours: 'Outbound per hour',
        interuption_status: 'Call interuption status',
        answered_calls_status: 'Answered calls status',
        outbound_per_weekday: 'Outbound per weekday',
      },
    },
    charts: {
      noDataToShow:
        'No data to show. Please apply the filters to set the statistics.',
      noDataAvailableLine1: 'No data available for the selected period.',
      noDataAvailableLine2: 'Please adjust your filters.',
      overview: {
        contactRate: 'Contact rate',
        contactRateSubtitle:
          'The percentage of records that had successful connection with the customer (any call with status Answered)',
        conversionRate: 'Conversion rate',
        conversionRateSubtitle: `The percentage of outbound calls that result in a desired outcome (status Hot).`,
        successRate: 'Success rate',
        successRateSubtitle: `The percentage of outbound calls that result in a desired outcome (status Finished).`,
        databaseProgress: 'Database progress',
        databaseProgressSubtitle:
          'Percentage of the records which were sucessfully closed or the avaliable calls attempts were finished.',
        records: 'Records',
        callsAmount: 'Calls amount',
        inbound: 'Inbound',
        outbound: 'Outbound',
        total: 'Total: ',
        averageCallsTime: 'Average calls time',
        averageCallsTimeDesc: `The average duration of an outbound and inbound call. Voice mail is not counted.`,
        totalCallsTime: 'Total calls time',
        totalCallsTimeDesc: `Time people were talking with the bot. Voice mail is not counted.`,
        dialogueStatuses: 'Dialogue statusses',
        interuptionStatuses: 'Interruption statuses',
        processesStatuses: 'Processes statuses',
        value: 'Values',
        percentage: 'Percentage',
        oneCandle: '1 candle represents: ',
        count: 'Iloś: ',
      },
    },
  },

  billings: {
    all: 'All',
    today: 'Today',
    last7days: 'Last 7 Days',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    custom: 'Custom',
    loading: 'Loading...',
    title: 'Billings',
    tenant: 'Tenant',
    selectTenant: 'Select tenant',
    clearFilters: 'Clear filters',
    process: 'Process',
    startDate: 'start date',
    endDate: 'end date',
    pasteCampaignID: 'Paste campaign ID',
    pleaseSelectTenant: 'Please select tenant.',
    table: {
      ofSelected: 'of {{length}} selected',
      selectAll: 'select all ',
      total: 'Total: ',
      telcoCost: 'Telco cost: ',
      smsCost: 'SMS cost: ',
      doneTotal: 'Done/ Total',
      exportFile: 'Export file',
      tenantName: 'Tenant name',
      process: 'Process',
      campaignID: 'Campaign ID',
      startDate: 'Start date',
      endDate: 'End date',
      status: 'Status',
      telcoCostTable: 'Telco cost',
      smsCostTable: 'SMS cost',
      noBillingsToShow: 'No billings to show.',
    },
  },

  dashboard: {
    filters: {
      oldest: 'date:oldest',
      newest: 'date:newest',
      alpha_asc: 'alphabetically: from A to Z',
      alpha_desc: 'alphabetically: from Z to A',
    },
    title: 'Dashboard',
    description: `Displaying active campaigns for today (only those with a status of 'Running' or 'Paused').`,
    sort: 'Sorting',
    noCampaignsToShow: 'No campaigns to show.',
    outbound: 'Outbound',
    voicemail: 'Voice mail',
    answered: 'Answered',
    other: 'Other',
    inbound: 'Inbound',
    callCenter: 'Call center',
    databaseCompletion: 'Database completion',
    records: 'records',
    callAnalysis: 'Call analysis for this campaign',
    callSuccessRate: 'Call success rate',
    callConversionRate: 'Call conversion rate',
    callHotRate: 'Call hot rate',
    callActivityChart: 'Call activity chart',
  },

  sidebar: {
    dashboard: 'Dashboard',
    processes: 'Processes',
    campaigns: 'Campaigns',
    reports: 'Reports',
    presets: 'Presets',
    tenantSettings: 'Tenant settings',
    popover: {
      settings: 'Settings',
      superAdmin: 'SA Panel',
      logout: 'Logout',
    },
    dialogue: 'Dialogue',
  },

  popups: {
    success: {
      title: 'Success',
    },
    error: {
      title: 'Error',
    },
  },

  common: {
    yes: 'yes',
    no: 'no',
    created: 'created',
    modified: 'modified',
    createNew: 'Create new',
    notSelected: 'Not selected',
    restore: 'Restore',
    archive: 'Archive',
    somethingWentWrong: 'Something went wrong',
    copyId: 'Copy ID',
    copiedId: 'ID copied to clipboard',
    next: 'Next',
    error: 'Error',
    modify: 'Modify',
    save: 'Save',
    cancel: 'Cancel',
    duplicate: 'Duplicate',
    confirm: 'Confirm',
    create: 'Create',
    update: 'Update',
    remove: 'Remove',
    submit: 'Submit',
    invite: 'Invite',
    edit: 'Edit',
    delete: 'Delete',
    here: 'here',
    tooWeak: 'too weak',
    goBack: 'Go back',
    writeHere: 'write here',
    saveChanges: 'Save changes',
    user: 'User',
    admin: 'Admin',
    owner: 'Owner',
    select: 'Select an option',
    close: 'Close',
    search: 'Search...',
    loadingModalTitle: 'Saving changes...',
    preview: 'Preview',
    getInfo: 'Get info',
    newCampaign: 'New campaign',
    lastChange: 'last change: {{date}} by {{name}}',
    selectProcess: 'Select a process',
    showArchive: 'show archived',
    hideArchive: 'hide archived',
    filterOptions: 'Filter options',

    permissions: {
      no_permissions: 'no permissions',
      read_only: 'view only',
      process_write: 'processes',
      campaign_write: 'campaigns',
      process_campaign_write: 'campaigns, processes',
      workspace: 'workspace',
    },
    noPermissions: {
      title: 'Hello, ',
      subtitle1: `You don't have permission to access this platform.`,
      subtitle2: 'Please reach out to your administrator for help.',
    },
  },
};

import React, { useEffect, useState } from 'react';
import {
  PopupCloseButton,
  SuccessPopupContainer,
  PopupContent,
} from './Popup.style';
import { XIcon } from '../../resources/icons-new/XIcon';
import { COLOR_PRIMARY_1, H3 } from '../../app/App.style';
import { SuccessIcon } from '../../resources/icons-new/SuccessIcon';
import { useTranslation } from 'react-i18next';

interface SuccessPopupProps {
  onClose: () => void;
  text: string;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ onClose, text }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const autoCloseDuration = 5000; // Duration before auto-closing, in milliseconds
  const animationDuration = 1000; // Animation duration, in milliseconds

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // Start fade-out animation
      setTimeout(onClose, animationDuration); // Close popup after animation
    }, autoCloseDuration - animationDuration);

    return () => clearTimeout(timer); // Clear timer if component unmounts
  }, [onClose, autoCloseDuration, animationDuration]);

  return (
    <SuccessPopupContainer isVisible={isVisible}>
      <PopupCloseButton onClick={onClose}>
        <XIcon />
      </PopupCloseButton>
      <PopupContent>
        <SuccessIcon color={COLOR_PRIMARY_1} />
        <H3 $bold>{t('popups.success.title')}</H3>
      </PopupContent>
      <H3>{text}</H3>
    </SuccessPopupContainer>
  );
};

export default SuccessPopup;

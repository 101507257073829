import React from 'react';
import styled from 'styled-components';
import { ArrowLeftIcon } from '../../../../resources/icons-new/ArrowLeftIcon';
import { useTranslation } from 'react-i18next';

export const BackToListButton = styled.button`
  position: absolute;

  left: 61px;
  top: 29px;

  z-index: 1000;

  padding: 3px 8px;

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  gap: 6px;

  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 5px;
  border: 1px solid #e4e7eb;
  background: white;

  box-shadow: 0px 2px 2px 0px rgba(152, 162, 179, 0.3);

  &:hover {
    filter: brightness(90%);
  }
`;

interface BackToListProps {
  handleBackToList: () => void;
}

const BackToList: React.FC<BackToListProps> = ({ handleBackToList }) => {
  const { t } = useTranslation();
  return (
    <BackToListButton onClick={handleBackToList}>
      <ArrowLeftIcon />
      {t('common.goBack')}
    </BackToListButton>
  );
};

export default BackToList;

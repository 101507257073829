import { EuiModal, EuiModalBody, EuiModalHeader } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Button, H2, H3 } from '../../../App.style';
import {
  ProcessCreateRequest,
  VoiceConfiguration,
} from '../../../../generated/tenants/Api';
import {
  getVoiceConfigurationsListAsyncThunk,
  selectVoiceConfigurationsList,
} from '../../presets/voices/api/voiceConfigurationSlice';
import { FormContainer, FormRow, PresetsBorder } from './ProcessForm.style';
import CreateVoice from '../../presets/voices/CreateVoice';
import VoiceFormReadOnly from '../../presets/voices/VoiceFormReadOnly';
import { SuperSelectDark } from '../../../../common/inputs/Inputs.style';
import { useTranslation } from 'react-i18next';

interface VoicePresetProps {
  strategy: string;
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  edit?: boolean;
}

const VoicePreset: React.FC<VoicePresetProps> = ({
  process,
  setProcess,
  strategy,
  edit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [voicePreset, setVoicePreset] = useState<VoiceConfiguration>();

  const [newVoiceCreated, setNewVoiceCreated] = useState<boolean>(false);
  const handleNewVoiceChange = () => {
    setNewVoiceCreated((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getVoiceConfigurationsListAsyncThunk(strategy));
  }, []);

  const apiVoicesList = useAppSelector(selectVoiceConfigurationsList);

  useEffect(() => {
    if (newVoiceCreated) {
      setVoicePreset(apiVoicesList[apiVoicesList.length - 1]);
      handleNewVoiceChange();
      return;
    } else if (process.voice_configuration !== '') {
      const selected = apiVoicesList.find(
        (voice) => voice.id === process.voice_configuration,
      );

      if (selected) setVoicePreset(selected);
    }
  }, [apiVoicesList]);

  const voicesOptions = [
    ...apiVoicesList.map((voice) => {
      return {
        value: voice.id,
        inputDisplay: voice.name,
        dropdownDisplay: <H3>{voice.name}</H3>,
      };
    }),
  ];

  useEffect(() => {
    if (voicePreset)
      setProcess({ ...process, voice_configuration: voicePreset.id });
  }, [voicePreset]);

  const [isModifyVoicePresetVisible, setIsModifyProcesVisible] =
    useState<boolean>(false);

  const closeModifyVoiceModal = () => setIsModifyProcesVisible(false);
  const showModifyVoiceModal = () => setIsModifyProcesVisible(true);

  let modifyVoiceModal;

  if (isModifyVoicePresetVisible && voicePreset) {
    modifyVoiceModal = (
      <EuiModal
        onClose={closeModifyVoiceModal}
        style={{ width: 600, overflow: 'hidden' }}
      >
        <EuiModalHeader style={{ paddingLeft: 90 }}>
          <H2>{t('processes.form.voices.newVoice')}</H2>
        </EuiModalHeader>

        <EuiModalBody>
          <CreateVoice
            voice={voicePreset}
            strategy={strategy}
            backToList={closeModifyVoiceModal}
            handleNewVoiceChange={handleNewVoiceChange}
            formButtonDown
          />
        </EuiModalBody>
      </EuiModal>
    );
  }

  return (
    <FormContainer $processForm>
      {modifyVoiceModal}

      <FormRow label={<H3 $bold>{t('processes.form.voices.voicePreset')}</H3>}>
        <SuperSelectDark
          disabled={!edit}
          options={voicesOptions}
          placeholder={t('common.select')}
          valueOfSelected={voicePreset ? voicePreset.id : ''}
          onChange={(e) => {
            const selectedVoice = apiVoicesList.find((voice) => voice.id === e);
            if (e !== '') setVoicePreset(selectedVoice);
          }}
        />
      </FormRow>

      {voicePreset && (
        <PresetsBorder>
          <VoiceFormReadOnly voice={voicePreset} />

          {edit && (
            <Button
              $styleType="NORMAL"
              $size="S"
              type="button"
              onClick={showModifyVoiceModal}
            >
              {t('common.modify')}
            </Button>
          )}
        </PresetsBorder>
      )}
    </FormContainer>
  );
};

export default VoicePreset;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * * `WEEKDAY` - WEEKDAY
 * * `HOUR` - HOUR
 * * `DAY` - DAY
 * * `WEEK` - WEEK
 * * `MONTH` - MONTH
 */
export type AggregateByEnum = 'WEEKDAY' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';

export interface AnalyticsOverviewResponse {
  contact_rate_count: number;
  conversion_rate_count: number;
  success_rate_count: number;
  calls_count: number;
  records_count: number;
  inbound_calls_count: number;
  outbound_calls_count: number;
  record_fulfillment_count: number;
  total_call_time_seconds: number;
  average_call_time_seconds: number;
  /** @format double */
  contact_rate_percent: number;
  /** @format double */
  conversion_rate_percent: number;
  /** @format double */
  success_rate_percent: number;
  /** @format double */
  outbound_calls_percent: number;
  /** @format double */
  inbound_calls_percent: number;
  /** @format double */
  record_fulfillment_percent: number;
}

export interface AnalyticsRequest {
  campaign_ids?: string[];
  /** @format date */
  start_date?: string;
  /** @format date */
  end_date?: string;
}

export interface AppConfig {
  /** @maxLength 256 */
  base_domain: string;
}

/**
 * * `ENGLISH` - English
 * * `POLISH` - Polish
 * * `SPANISH` - Spanish
 */
export type AppLanguageEnum = 'ENGLISH' | 'POLISH' | 'SPANISH';

/**
 * * `techmo` - techmo
 * * `google` - google
 * * `google-vad` - google-vad
 * * `lekta` - lekta
 */
export type AsrVendorEnum = 'techmo' | 'google' | 'google-vad' | 'lekta';

/**
 * * `PASSWORD` - Password
 * * `RSA_KEY` - Rsa Key
 */
export type AuthMethodEnum = 'PASSWORD' | 'RSA_KEY';

/**
 * * `DEBTOR` - Debtor
 * * `REMINDER` - Reminder
 * * `VENDOR` - Vendor
 */
export type BaseBotConfigurationTemplateEnum = 'DEBTOR' | 'REMINDER' | 'VENDOR';

export interface BillingConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /**
   * @format double
   * @min 0.01
   */
  domestic_mobile_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  international_mobile_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  domestic_landline_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  international_landline_call_price: number;
  /**
   * @format double
   * @min 0.01
   */
  sms_price: number;
  /**
   * * `PLN` - Pln
   * * `EUR` - Eur
   * * `USD` - Usd
   */
  currency: CurrencyEnum;
  /** * `PER_CALL` - Per Call */
  template: BillingConfigurationTemplateEnum;
}

/** * `PER_CALL` - Per Call */
export type BillingConfigurationTemplateEnum = 'PER_CALL';

export type BlankEnum = '';

export interface BotBuild {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /**
   * @min 0
   * @max 2147483647
   */
  pipeline_id?: number | null;
  /**
   * @min 0
   * @max 2147483647
   */
  job_id?: number | null;
  /**
   * * `CREATED` - Created
   * * `FINISHED` - Finished
   * * `FAILED` - Failed
   */
  status?: BotBuildStatusEnum;
  /** @format uuid */
  bot_configuration: string;
}

export interface BotBuildRequest {
  /**
   * @min 0
   * @max 2147483647
   */
  pipeline_id?: number | null;
  /**
   * @min 0
   * @max 2147483647
   */
  job_id?: number | null;
  /**
   * * `CREATED` - Created
   * * `FINISHED` - Finished
   * * `FAILED` - Failed
   */
  status?: BotBuildStatusEnum;
  /** @format uuid */
  bot_configuration: string;
}

/**
 * * `CREATED` - Created
 * * `FINISHED` - Finished
 * * `FAILED` - Failed
 */
export type BotBuildStatusEnum = 'CREATED' | 'FINISHED' | 'FAILED';

export interface BotConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  is_draft?: boolean;
  is_custom?: boolean;
  /** @maxLength 64 */
  name: string;
  strategy_config?: any;
  generations_config?: any;
  runtime_parameters?: any;
  /**
   * * `DEBTOR` - Debtor
   * * `REMINDER` - Reminder
   * * `VENDOR` - Vendor
   */
  template: BaseBotConfigurationTemplateEnum;
  custom_label: string | null;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /** @maxLength 128 */
  image_tag?: string | null;
  record_close_rules?: any;
  frontend_configuration?: any;
  custom_tokens?: string[] | null;
}

export interface BotConfigurationRequest {
  /** @format uuid */
  strategy: string;
  is_draft?: boolean;
  is_custom?: boolean;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  strategy_config?: any;
  generations_config?: any;
  runtime_parameters?: any;
  /**
   * * `DEBTOR` - Debtor
   * * `REMINDER` - Reminder
   * * `VENDOR` - Vendor
   */
  template: BaseBotConfigurationTemplateEnum;
  /** @minLength 1 */
  custom_label: string | null;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /** @maxLength 128 */
  image_tag?: string | null;
  record_close_rules?: any;
  frontend_configuration?: any;
  custom_tokens?: string[] | null;
}

export interface BotConfigurationSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name: string;
  is_draft?: boolean;
  strategy_config?: any;
  generations_config?: any;
  runtime_parameters?: any;
  /**
   * * `DEBTOR` - Debtor
   * * `REMINDER` - Reminder
   * * `VENDOR` - Vendor
   */
  template: BaseBotConfigurationTemplateEnum;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /** @maxLength 128 */
  image_tag?: string | null;
  record_close_rules?: any;
}

export interface BotConfigurationSnapshotRequest {
  /** @format uuid */
  strategy: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  is_draft?: boolean;
  strategy_config?: any;
  generations_config?: any;
  runtime_parameters?: any;
  /**
   * * `DEBTOR` - Debtor
   * * `REMINDER` - Reminder
   * * `VENDOR` - Vendor
   */
  template: BaseBotConfigurationTemplateEnum;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /** @maxLength 128 */
  image_tag?: string | null;
  record_close_rules?: any;
}

/**
 * * `pl-PL` - pl-PL
 * * `es-ES` - es-ES
 * * `es-MX` - es-MX
 * * `en-US` - en-US
 * * `en-AU` - en-AU
 */
export type BotLanguageEnum = 'pl-PL' | 'es-ES' | 'es-MX' | 'en-US' | 'en-AU';

export interface CalendarConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name: string;
  /** @default "Europe/Warsaw" */
  timezone?: string;
  working_hours: WorkingHours[];
  cc_working_hours: WorkingHours[];
  inbound_working_hours: WorkingHours[];
}

export interface CalendarConfigurationRequest {
  /** @format uuid */
  strategy: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /** @default "Europe/Warsaw" */
  timezone?: string;
  working_hours: WorkingHoursRequest[];
  cc_working_hours: WorkingHoursRequest[];
  inbound_working_hours: WorkingHoursRequest[];
}

export interface CalendarConfigurationSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  /** @default "Europe/Warsaw" */
  timezone?: string;
  working_hours: WorkingHours[];
  cc_working_hours: WorkingHours[];
  inbound_working_hours: WorkingHours[];
}

export interface CalendarConfigurationSnapshotRequest {
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  /** @default "Europe/Warsaw" */
  timezone?: string;
  working_hours: WorkingHoursRequest[];
  cc_working_hours: WorkingHoursRequest[];
  inbound_working_hours: WorkingHoursRequest[];
}

export interface Call {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  record: string;
  /** @maxLength 128 */
  record_phone_number: string;
  /** @maxLength 128 */
  campaign_phone_number: string;
  /** @format date-time */
  started_at: string;
  /** @format date-time */
  ended_at: string;
  /**
   * * `INBOUND` - Inbound
   * * `OUTBOUND` - Outbound
   */
  call_type?: CallTypeEnum;
  report_data?: any;
  /**
   * * `ANSWERED` - Answered
   * * `NOT_ANSWERED` - Not Answered
   * * `REJECTED` - Rejected
   * * `BUSY` - Busy
   * * `VOICEMAIL` - Voicemail
   * * `TRANSFER` - Transfer
   * * `NONEXISTENT_NUMBER` - Nonexistent Number
   * * `UNMAPPED` - Unmapped
   * * `ERROR` - Error
   */
  telco_status: TelcoStatusEnum;
  readonly transcript: {
    text?: string;
    speaker?: string;
    end_time_epoch?: number;
    start_time_epoch?: number;
  }[];
}

export interface CallCenterNonWorkingDaysConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name: string;
  holidays?: string[];
}

export interface CallCenterNonWorkingDaysConfigurationRequest {
  /** @format uuid */
  strategy: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  holidays?: string[];
}

export interface CallCenternNonWorkingDaysConfigurationSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  holidays?: string[];
}

export interface CallCenternNonWorkingDaysConfigurationSnapshotRequest {
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  holidays?: string[];
}

export interface CallStatusDistributionData {
  data: any[];
}

export interface CallStatusDistributionRequest {
  campaign_ids?: string[];
  /** @format date */
  start_date?: string;
  /** @format date */
  end_date?: string;
  /**
   * * `WEEKDAY` - WEEKDAY
   * * `HOUR` - HOUR
   * * `DAY` - DAY
   * * `WEEK` - WEEK
   * * `MONTH` - MONTH
   */
  aggregate_by: AggregateByEnum;
  filter_statuses?: string[];
}

/**
 * * `INBOUND` - Inbound
 * * `OUTBOUND` - Outbound
 */
export type CallTypeEnum = 'INBOUND' | 'OUTBOUND';

export interface CallingConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 64 */
  name: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts: number;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts_per_day: number;
  /**
   * @min 0
   * @max 2147483647
   */
  rest_days_after_call_attempts_per_day_reached: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  /**
   * @min 0
   * @max 2147483647
   */
  time_between_attempts_seconds?: number | null;
  dynamic_time_between_attempts?: DynamicTimeBetweenAttempts[] | null;
  randomized_time_between_attempts?: RandomizedTimeBetweenAttempts | null;
}

export interface CallingConfigurationPatch {
  call_attempts?: number;
  call_attempts_per_day?: number;
  rest_days_after_call_attempts_per_day_reached?: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  time_between_attempts_seconds?: number;
  dynamic_time_between_attempts?: DynamicTimeBetweenAttempts[] | null;
  randomized_time_between_attempts?: RandomizedTimeBetweenAttempts | null;
}

export interface CallingConfigurationPatchRequest {
  call_attempts?: number;
  call_attempts_per_day?: number;
  rest_days_after_call_attempts_per_day_reached?: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  time_between_attempts_seconds?: number;
  dynamic_time_between_attempts?: DynamicTimeBetweenAttemptsRequest[] | null;
  randomized_time_between_attempts?: RandomizedTimeBetweenAttemptsRequest | null;
}

export interface CallingConfigurationRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /** @format uuid */
  strategy: string;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts: number;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts_per_day: number;
  /**
   * @min 0
   * @max 2147483647
   */
  rest_days_after_call_attempts_per_day_reached: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  /**
   * @min 0
   * @max 2147483647
   */
  time_between_attempts_seconds?: number | null;
  dynamic_time_between_attempts?: DynamicTimeBetweenAttemptsRequest[] | null;
  randomized_time_between_attempts?: RandomizedTimeBetweenAttemptsRequest | null;
}

export interface CallingConfigurationSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 64 */
  name: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts: number;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts_per_day: number;
  /**
   * @min 0
   * @max 2147483647
   */
  rest_days_after_call_attempts_per_day_reached: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  /**
   * @min 0
   * @max 2147483647
   */
  time_between_attempts_seconds?: number | null;
  dynamic_time_between_attempts?: any;
  randomized_time_between_attempts?: any;
}

export interface CallingConfigurationSnapshotRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /** @format uuid */
  strategy: string;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts: number;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts_per_day: number;
  /**
   * @min 0
   * @max 2147483647
   */
  rest_days_after_call_attempts_per_day_reached: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  /**
   * @min 0
   * @max 2147483647
   */
  time_between_attempts_seconds?: number | null;
  dynamic_time_between_attempts?: any;
  randomized_time_between_attempts?: any;
}

export interface Campaign {
  /** @format date-time */
  readonly start_time: string;
  /** @format date-time */
  readonly end_time: string;
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @format uuid */
  readonly process: string | null;
  readonly process_name: string;
  /**
   * * `mp3` - Mp3
   * * `wav` - Wav
   * * `flac` - Flac
   */
  recordings_format?: RecordingsFormatEnum;
  voice_configuration: VoiceConfigurationSnapshot;
  bot_configuration: BotConfigurationSnapshot;
  calling_configuration: CallingConfigurationSnapshot;
  /** @format date */
  readonly start_date: string;
  /** @format date */
  readonly end_date: string;
  campaign_non_working_days_configuration: CampaignNonWorkingDaysConfigurationSnapshot;
  call_center_non_working_days_configuration: CallCenternNonWorkingDaysConfigurationSnapshot;
  calendar_configuration: CalendarConfigurationSnapshot;
  readonly records_count: number;
  readonly closed_records_count: number;
  readonly inbound_calls_count: number;
  readonly outbound_calls_count: number;
  readonly call_center_calls_count: number;
  daily_report?: boolean;
  readonly inbound_status: ServiceStatusEnum;
  readonly cc_status: ServiceStatusEnum;
  readonly status: CampaignUpdateStatusEnum;
  readonly labels: LabelSnapshot[];
  readonly guaranteed_lines: number;
  readonly use_idle_lines: boolean;
  is_inbound_enabled?: boolean;
  is_redirect_to_cc_enabled?: boolean;
  delete_data_after_report?: boolean;
  delete_data_offset?: string | null;
  inbound_enabled_for_closed_record?: boolean;
  direct_transfer_to_cc?: boolean;
  inbound_fallback_message?: string | null;
  /** @maxLength 128 */
  redirection_phone_number?: string | null;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  phone_numbers?: string[];
  sftp_data_transfer?: boolean;
  /** @maxLength 128 */
  in_glob?: string | null;
  /** @maxLength 128 */
  archive_path?: string | null;
  delete_archived_files?: boolean;
  /**
   * * `NEW_CAMPAIGN` - New Campaign
   * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
   */
  import_file_handling?: ImportFileHandlingEnum;
  /** @maxLength 256 */
  external_sftp_recordings_path?: string | null;
  /** @maxLength 256 */
  external_sftp_reports_path?: string | null;
  /** @maxLength 256 */
  external_sftp_transcripts_path?: string | null;
  upload_report_per_call?: boolean | null;
  upload_report_per_record?: boolean | null;
  upload_report_per_campaign?: boolean | null;
  readonly get_customer_info_faas: CustomFaasSnapshot;
  readonly report_feedback_faas: CustomFaasSnapshot;
  import_custom_faas: CustomFaasSnapshot;
  report_per_call_custom_faas: CustomFaasSnapshot;
  report_per_record_custom_faas: CustomFaasSnapshot;
  report_per_campaign_custom_faas: CustomFaasSnapshot;
  cyclic_reports?: boolean;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  cyclic_reports_frequency_seconds?: number | null;
  cyclic_report_per_call?: boolean;
  cyclic_report_per_record?: boolean;
  cyclic_report_per_campaign?: boolean;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  cyclic_report_data_range?: DailyReportDataRangeEnum;
}

export interface CampaignChange {
  guaranteed_lines?: number;
  use_idle_lines?: boolean;
  calling_configuration?: CallingConfigurationPatch;
}

export interface CampaignChangeRequest {
  guaranteed_lines?: number;
  use_idle_lines?: boolean;
  calling_configuration?: CallingConfigurationPatchRequest;
}

export interface CampaignCreateRequest {
  /** @format uuid */
  process_id: string;
  /** @format date */
  start_date?: string;
  /** @format date */
  end_date?: string;
}

export interface CampaignDashboardData {
  /** @format uuid */
  id: string;
  status: string;
  process_name: string;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  closed_records_count: number;
  total_records_count: number;
  /** @format double */
  successful_calls_percent: number;
  /** @format double */
  conversion_calls_percent: number;
  /** @format double */
  hot_calls_percent: number;
  outbound_calls: number;
  inbound_calls: number;
  answered_calls: number;
  voicemail_calls: number;
  other_calls: number;
  call_center_calls_count: number;
  time_series: TimeSeries[];
  /** @format double */
  database_completion_percent: number;
}

export interface CampaignNonWorkingDaysConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name: string;
  holidays?: string[];
}

export interface CampaignNonWorkingDaysConfigurationRequest {
  /** @format uuid */
  strategy: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  holidays?: string[];
}

export interface CampaignNonWorkingDaysConfigurationSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  holidays?: string[];
}

export interface CampaignNonWorkingDaysConfigurationSnapshotRequest {
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  holidays?: string[];
}

export interface CampaignOverview {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  readonly strategy: string;
  /** @format uuid */
  readonly process: string | null;
  readonly process_name: string;
  /** @format date */
  readonly start_date: string;
  /** @format date */
  readonly end_date: string;
  readonly records_count: number;
  readonly closed_records_count: number;
  readonly inbound_calls_count: number;
  readonly outbound_calls_count: number;
  readonly call_center_calls_count: number;
  readonly status: CampaignUpdateStatusEnum;
  readonly labels: LabelSnapshot[];
  phone_numbers: string[];
}

export interface CampaignPauseCcRequest {
  cc_paused: boolean;
}

export interface CampaignPauseInboundRequest {
  inbound_paused: boolean;
}

export interface CampaignRequest {
  /** @format uuid */
  strategy: string;
  /**
   * * `mp3` - Mp3
   * * `wav` - Wav
   * * `flac` - Flac
   */
  recordings_format?: RecordingsFormatEnum;
  voice_configuration: VoiceConfigurationSnapshotRequest;
  bot_configuration: BotConfigurationSnapshotRequest;
  calling_configuration: CallingConfigurationSnapshotRequest;
  campaign_non_working_days_configuration: CampaignNonWorkingDaysConfigurationSnapshotRequest;
  call_center_non_working_days_configuration: CallCenternNonWorkingDaysConfigurationSnapshotRequest;
  calendar_configuration: CalendarConfigurationSnapshotRequest;
  daily_report?: boolean;
  is_inbound_enabled?: boolean;
  is_redirect_to_cc_enabled?: boolean;
  delete_data_after_report?: boolean;
  delete_data_offset?: string | null;
  inbound_enabled_for_closed_record?: boolean;
  direct_transfer_to_cc?: boolean;
  inbound_fallback_message?: string | null;
  /** @maxLength 128 */
  redirection_phone_number?: string | null;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  phone_numbers?: string[];
  sftp_data_transfer?: boolean;
  /** @maxLength 128 */
  in_glob?: string | null;
  /** @maxLength 128 */
  archive_path?: string | null;
  delete_archived_files?: boolean;
  /**
   * * `NEW_CAMPAIGN` - New Campaign
   * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
   */
  import_file_handling?: ImportFileHandlingEnum;
  /** @maxLength 256 */
  external_sftp_recordings_path?: string | null;
  /** @maxLength 256 */
  external_sftp_reports_path?: string | null;
  /** @maxLength 256 */
  external_sftp_transcripts_path?: string | null;
  upload_report_per_call?: boolean | null;
  upload_report_per_record?: boolean | null;
  upload_report_per_campaign?: boolean | null;
  import_custom_faas: CustomFaasSnapshotRequest;
  report_per_call_custom_faas: CustomFaasSnapshotRequest;
  report_per_record_custom_faas: CustomFaasSnapshotRequest;
  report_per_campaign_custom_faas: CustomFaasSnapshotRequest;
  cyclic_reports?: boolean;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  cyclic_reports_frequency_seconds?: number | null;
  cyclic_report_per_call?: boolean;
  cyclic_report_per_record?: boolean;
  cyclic_report_per_campaign?: boolean;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  cyclic_report_data_range?: DailyReportDataRangeEnum;
}

export interface CampaignStatus {
  /**
   * * `PENDING` - Pending
   * * `SCHEDULED` - Scheduled
   * * `RUNNING` - Running
   * * `PAUSED` - Paused
   * * `STOPPED` - Stopped
   * * `FINISHED` - Finished
   * * `ERROR` - Error
   */
  status: CampaignUpdateStatusEnum;
}

export interface CampaignUpdate {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /**
   * * `USER` - User
   * * `SUPERADMIN` - Superadmin
   * * `SYSTEM` - System
   * * `TOKEN` - Token
   * * `SFTP` - Sftp
   */
  triggered_by: TriggeredByEnum;
  user?: UserTenant;
  token?: Token;
  /**
   * * `INITIALIZE` - Initialize
   * * `DEPLOY` - Deploy
   * * `RUN` - Run
   * * `PAUSE_RUNNING` - Pause Running
   * * `UNPAUSE_RUNNING` - Unpause Running
   * * `PAUSE_SCHEDULED` - Pause Scheduled
   * * `UNPAUSE_SCHEDULED` - Unpause Scheduled
   * * `ERROR` - Error
   * * `STOP` - Stop
   * * `FINISH` - Finish
   * * `PAUSE_INBOUND` - Pause Inbound
   * * `UNPAUSE_INBOUND` - Unpause Inbound
   * * `PAUSE_CC` - Pause Cc
   * * `UNPAUSE_CC` - Unpause Cc
   * * `UPLOAD_RECORDS` - Upload Records
   * * `UPDATE` - Update
   * * `RESTART` - Restart
   */
  transition: CampaignUpdateTransitionEnum;
  /**
   * * `PENDING` - Pending
   * * `SCHEDULED` - Scheduled
   * * `RUNNING` - Running
   * * `PAUSED` - Paused
   * * `STOPPED` - Stopped
   * * `FINISHED` - Finished
   * * `ERROR` - Error
   */
  status: CampaignUpdateStatusEnum;
}

/**
 * * `PENDING` - Pending
 * * `SCHEDULED` - Scheduled
 * * `RUNNING` - Running
 * * `PAUSED` - Paused
 * * `STOPPED` - Stopped
 * * `FINISHED` - Finished
 * * `ERROR` - Error
 */
export type CampaignUpdateStatusEnum =
  | 'PENDING'
  | 'SCHEDULED'
  | 'RUNNING'
  | 'PAUSED'
  | 'STOPPED'
  | 'FINISHED'
  | 'ERROR';

export interface CampaignUpdateStatusRequest {
  /**
   * * `PAUSE` - Pause
   * * `UNPAUSE` - Unpause
   * * `STOP` - Stop
   */
  transition: CampaignUpdateStatusTransitionEnum;
}

/**
 * * `PAUSE` - Pause
 * * `UNPAUSE` - Unpause
 * * `STOP` - Stop
 */
export type CampaignUpdateStatusTransitionEnum = 'PAUSE' | 'UNPAUSE' | 'STOP';

/**
 * * `INITIALIZE` - Initialize
 * * `DEPLOY` - Deploy
 * * `RUN` - Run
 * * `PAUSE_RUNNING` - Pause Running
 * * `UNPAUSE_RUNNING` - Unpause Running
 * * `PAUSE_SCHEDULED` - Pause Scheduled
 * * `UNPAUSE_SCHEDULED` - Unpause Scheduled
 * * `ERROR` - Error
 * * `STOP` - Stop
 * * `FINISH` - Finish
 * * `PAUSE_INBOUND` - Pause Inbound
 * * `UNPAUSE_INBOUND` - Unpause Inbound
 * * `PAUSE_CC` - Pause Cc
 * * `UNPAUSE_CC` - Unpause Cc
 * * `UPLOAD_RECORDS` - Upload Records
 * * `UPDATE` - Update
 * * `RESTART` - Restart
 */
export type CampaignUpdateTransitionEnum =
  | 'INITIALIZE'
  | 'DEPLOY'
  | 'RUN'
  | 'PAUSE_RUNNING'
  | 'UNPAUSE_RUNNING'
  | 'PAUSE_SCHEDULED'
  | 'UNPAUSE_SCHEDULED'
  | 'ERROR'
  | 'STOP'
  | 'FINISH'
  | 'PAUSE_INBOUND'
  | 'UNPAUSE_INBOUND'
  | 'PAUSE_CC'
  | 'UNPAUSE_CC'
  | 'UPLOAD_RECORDS'
  | 'UPDATE'
  | 'RESTART';

export interface ChartRow {
  name: string;
  value: number;
}

export interface Column {
  column: DashboardColumn;
  column_processes: Process[];
}

export interface ColumnIdRequest {
  /** @format uuid */
  column_id: string;
  column_processes: string[];
}

export interface Company {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  readonly name: string;
  /**
   * Tenant URL Name
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  readonly slug: string;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sftp_uid?: number;
  readonly free_lines: number;
  details: string;
  timezone: string;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /**
   * @min 1
   * @max 2147483647
   */
  available_lines?: number;
  /** @format uuid */
  sms_gateway?: string | null;
  billing_configuration: BillingConfiguration;
}

export interface ContextMapping {
  phone_numbers: string[];
  context_data: any;
}

export interface ContextMappingRequest {
  phone_numbers: string[];
  context_data: any;
}

/**
 * * `PLN` - Pln
 * * `EUR` - Eur
 * * `USD` - Usd
 */
export type CurrencyEnum = 'PLN' | 'EUR' | 'USD';

export interface CustomFaas {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 32 */
  name: string;
  /**
   * * `IMPORT` - Import
   * * `REPORT` - Report
   * * `INTEGRATION` - Integration
   */
  lambda_type: LambdaTypeEnum;
  /** @maxLength 128 */
  faas_endpoint: string;
  /** @format uuid */
  strategy: string;
}

export interface CustomFaasRequest {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string;
  /**
   * * `IMPORT` - Import
   * * `REPORT` - Report
   * * `INTEGRATION` - Integration
   */
  lambda_type: LambdaTypeEnum;
  /**
   * @minLength 1
   * @maxLength 128
   */
  faas_endpoint: string;
  /** @format uuid */
  strategy: string;
}

export interface CustomFaasSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 32 */
  name: string;
  /**
   * * `IMPORT` - Import
   * * `REPORT` - Report
   * * `INTEGRATION` - Integration
   */
  lambda_type: LambdaTypeEnum;
  /** @maxLength 128 */
  faas_endpoint: string;
}

export interface CustomFaasSnapshotRequest {
  /** @format uuid */
  strategy: string;
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string;
  /**
   * * `IMPORT` - Import
   * * `REPORT` - Report
   * * `INTEGRATION` - Integration
   */
  lambda_type: LambdaTypeEnum;
  /**
   * @minLength 1
   * @maxLength 128
   */
  faas_endpoint: string;
}

/**
 * * `CURRENT_DAY` - Current Day
 * * `FROM_START` - From Start
 */
export type DailyReportDataRangeEnum = 'CURRENT_DAY' | 'FROM_START';

export interface Dashboard {
  columns: Column[];
  uncategorized: Process[];
}

export interface DashboardColumn {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 16 */
  name: string;
  readonly position: number;
  /** @format uuid */
  strategy: string;
}

export interface DashboardColumnRequest {
  /**
   * @minLength 1
   * @maxLength 16
   */
  name: string;
  /** @format uuid */
  strategy: string;
}

export interface DynamicTimeBetweenAttempts {
  interval_start_seconds: number;
  interval_end_seconds: number;
  time_between_attempts_seconds: number;
}

export interface DynamicTimeBetweenAttemptsRequest {
  interval_start_seconds: number;
  interval_end_seconds: number;
  time_between_attempts_seconds: number;
}

/**
 * * `CONNECTION_TIMEOUT` - CONNECTION_TIMEOUT
 * * `AUTHENTICATION_ERROR` - AUTHENTICATION_ERROR
 * * `OTHER_ERROR` - OTHER_ERROR
 */
export type ErrorCodeEnum = 'CONNECTION_TIMEOUT' | 'AUTHENTICATION_ERROR' | 'OTHER_ERROR';

export interface FileError {
  message: string;
}

/**
 * * `csv` - csv
 * * `json` - json
 * * `xlsx` - xlsx
 */
export type FileTypeEnum = 'csv' | 'json' | 'xlsx';

/**
 * * `MALE` - Male
 * * `FEMALE` - Female
 */
export type GenderEnum = 'MALE' | 'FEMALE';

/**
 * * `NEW_CAMPAIGN` - New Campaign
 * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
 */
export type ImportFileHandlingEnum = 'NEW_CAMPAIGN' | 'UPDATE_EXISTING_CAMPAIGN';

export interface InboundRcsRoutingDecision {
  /**
   * * `message_and_hangup` - Message And Hangup
   * * `hangup` - Hangup
   * * `transfer_to_bot` - Transfer To Bot
   * * `transfer_to_cc` - Transfer To Cc
   */
  inbound_routing_decision: InboundRoutingDecisionEnum;
  message?: string;
  voice_configuration?: InboundVoiceConfiguration;
  icd?: any;
  les_host?: string;
  les_port?: number;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  redirection_phone_number?: string;
  /** @format uuid */
  record_id?: string;
  /** @format uuid */
  campaign_id?: string;
}

/**
 * * `message_and_hangup` - Message And Hangup
 * * `hangup` - Hangup
 * * `transfer_to_bot` - Transfer To Bot
 * * `transfer_to_cc` - Transfer To Cc
 */
export type InboundRoutingDecisionEnum = 'message_and_hangup' | 'hangup' | 'transfer_to_bot' | 'transfer_to_cc';

export interface InboundVoiceConfiguration {
  /**
   * * `MALE` - Male
   * * `FEMALE` - Female
   */
  gender: GenderEnum;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language: BotLanguageEnum;
  /**
   * * `techmo` - techmo
   * * `google` - google
   * * `google-vad` - google-vad
   * * `lekta` - lekta
   */
  asr_vendor: AsrVendorEnum;
  /**
   * * `Microsoft` - Microsoft
   * * `Google` - Google
   * * `Techmo` - Techmo
   */
  tts_vendor: TtsVendorEnum;
  voice_name: string;
  base_tts_speed_percentage: number;
}

export interface Invitation {
  /** @format uuid */
  readonly id: string;
  /**
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @format date-time */
  readonly expires_at: string;
  /**
   * * `USER` - User
   * * `ADMIN` - Admin
   * * `OWNER` - Owner
   */
  role: RoleEnum;
  strategies_permissions?: UserStrategyPermission[];
  readonly url: string;
}

export interface InvitationRequest {
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string;
  /**
   * * `USER` - User
   * * `ADMIN` - Admin
   * * `OWNER` - Owner
   */
  role: RoleEnum;
  strategies_permissions?: UserStrategyPermissionRequest[];
}

export interface Label {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 16 */
  text: string;
  /**
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color?: string;
  /** @maxLength 128 */
  description?: string | null;
  /** @format uuid */
  strategy: string;
}

export interface LabelRequest {
  /**
   * @minLength 1
   * @maxLength 16
   */
  text: string;
  /**
   * @minLength 1
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color?: string;
  /** @maxLength 128 */
  description?: string | null;
  /** @format uuid */
  strategy: string;
}

export interface LabelSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 16 */
  text: string;
  /**
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color?: string;
  /** @maxLength 128 */
  description?: string | null;
  /** @format uuid */
  strategy: string;
}

export interface LabelSnapshotRequest {
  /**
   * @minLength 1
   * @maxLength 16
   */
  text: string;
  /**
   * @minLength 1
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color?: string;
  /** @maxLength 128 */
  description?: string | null;
  /** @format uuid */
  strategy: string;
}

/**
 * * `IMPORT` - Import
 * * `REPORT` - Report
 * * `INTEGRATION` - Integration
 */
export type LambdaTypeEnum = 'IMPORT' | 'REPORT' | 'INTEGRATION';

export interface LoginRequest {
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  password: string;
}

export interface ModifyLabels {
  labels?: string[];
}

export interface ModifyLabelsRequest {
  labels?: string[];
}

export interface OutboundCallConfiguration {
  voice_configuration: InboundVoiceConfiguration;
  icd: any;
  les_host: string;
  les_port: number;
}

export interface PaginatedCallList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: Call[];
}

export interface PaginatedCampaignOverviewList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: CampaignOverview[];
}

export interface PaginatedRecordDataList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: RecordData[];
}

export interface PaginatedTokenList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: Token[];
}

export interface PaginatedUserTenantList {
  /** @example 123 */
  count: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=400&limit=100"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?offset=200&limit=100"
   */
  previous?: string | null;
  results: UserTenant[];
}

export interface PatchedBotBuildRequest {
  /**
   * @min 0
   * @max 2147483647
   */
  pipeline_id?: number | null;
  /**
   * @min 0
   * @max 2147483647
   */
  job_id?: number | null;
  /**
   * * `CREATED` - Created
   * * `FINISHED` - Finished
   * * `FAILED` - Failed
   */
  status?: BotBuildStatusEnum;
  /** @format uuid */
  bot_configuration?: string;
}

export interface PatchedBotConfigurationRequest {
  /** @format uuid */
  strategy?: string;
  is_draft?: boolean;
  is_custom?: boolean;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  strategy_config?: any;
  generations_config?: any;
  runtime_parameters?: any;
  /**
   * * `DEBTOR` - Debtor
   * * `REMINDER` - Reminder
   * * `VENDOR` - Vendor
   */
  template?: BaseBotConfigurationTemplateEnum;
  /** @minLength 1 */
  custom_label?: string | null;
  /**
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /** @maxLength 128 */
  image_tag?: string | null;
  record_close_rules?: any;
  frontend_configuration?: any;
  custom_tokens?: string[] | null;
}

export interface PatchedCalendarConfigurationRequest {
  /** @format uuid */
  strategy?: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  /** @default "Europe/Warsaw" */
  timezone?: string;
  working_hours?: WorkingHoursRequest[];
  cc_working_hours?: WorkingHoursRequest[];
  inbound_working_hours?: WorkingHoursRequest[];
}

export interface PatchedCallCenterNonWorkingDaysConfigurationRequest {
  /** @format uuid */
  strategy?: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  holidays?: string[];
}

export interface PatchedCallingConfigurationRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  /** @format uuid */
  strategy?: string;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts?: number;
  /**
   * @min 1
   * @max 2147483647
   */
  call_attempts_per_day?: number;
  /**
   * @min 0
   * @max 2147483647
   */
  rest_days_after_call_attempts_per_day_reached?: number;
  /**
   * * `STATIC` - Static
   * * `DYNAMIC` - Dynamic
   * * `RANDOMIZED` - Randomized
   */
  time_between_attempts_mode?: TimeBetweenAttemptsModeEnum;
  /**
   * @min 0
   * @max 2147483647
   */
  time_between_attempts_seconds?: number | null;
  dynamic_time_between_attempts?: DynamicTimeBetweenAttemptsRequest[] | null;
  randomized_time_between_attempts?: RandomizedTimeBetweenAttemptsRequest | null;
}

export interface PatchedCampaignNonWorkingDaysConfigurationRequest {
  /** @format uuid */
  strategy?: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  holidays?: string[];
}

export interface PatchedCampaignRequest {
  /** @format uuid */
  strategy?: string;
  /**
   * * `mp3` - Mp3
   * * `wav` - Wav
   * * `flac` - Flac
   */
  recordings_format?: RecordingsFormatEnum;
  voice_configuration?: VoiceConfigurationSnapshotRequest;
  bot_configuration?: BotConfigurationSnapshotRequest;
  calling_configuration?: CallingConfigurationSnapshotRequest;
  campaign_non_working_days_configuration?: CampaignNonWorkingDaysConfigurationSnapshotRequest;
  call_center_non_working_days_configuration?: CallCenternNonWorkingDaysConfigurationSnapshotRequest;
  calendar_configuration?: CalendarConfigurationSnapshotRequest;
  daily_report?: boolean;
  is_inbound_enabled?: boolean;
  is_redirect_to_cc_enabled?: boolean;
  delete_data_after_report?: boolean;
  delete_data_offset?: string | null;
  inbound_enabled_for_closed_record?: boolean;
  direct_transfer_to_cc?: boolean;
  inbound_fallback_message?: string | null;
  /** @maxLength 128 */
  redirection_phone_number?: string | null;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  phone_numbers?: string[];
  sftp_data_transfer?: boolean;
  /** @maxLength 128 */
  in_glob?: string | null;
  /** @maxLength 128 */
  archive_path?: string | null;
  delete_archived_files?: boolean;
  /**
   * * `NEW_CAMPAIGN` - New Campaign
   * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
   */
  import_file_handling?: ImportFileHandlingEnum;
  /** @maxLength 256 */
  external_sftp_recordings_path?: string | null;
  /** @maxLength 256 */
  external_sftp_reports_path?: string | null;
  /** @maxLength 256 */
  external_sftp_transcripts_path?: string | null;
  upload_report_per_call?: boolean | null;
  upload_report_per_record?: boolean | null;
  upload_report_per_campaign?: boolean | null;
  import_custom_faas?: CustomFaasSnapshotRequest;
  report_per_call_custom_faas?: CustomFaasSnapshotRequest;
  report_per_record_custom_faas?: CustomFaasSnapshotRequest;
  report_per_campaign_custom_faas?: CustomFaasSnapshotRequest;
  cyclic_reports?: boolean;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  cyclic_reports_frequency_seconds?: number | null;
  cyclic_report_per_call?: boolean;
  cyclic_report_per_record?: boolean;
  cyclic_report_per_campaign?: boolean;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  cyclic_report_data_range?: DailyReportDataRangeEnum;
}

export interface PatchedCustomFaasRequest {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name?: string;
  /**
   * * `IMPORT` - Import
   * * `REPORT` - Report
   * * `INTEGRATION` - Integration
   */
  lambda_type?: LambdaTypeEnum;
  /**
   * @minLength 1
   * @maxLength 128
   */
  faas_endpoint?: string;
  /** @format uuid */
  strategy?: string;
}

export interface PatchedDashboardColumnRequest {
  /**
   * @minLength 1
   * @maxLength 16
   */
  name?: string;
  /** @format uuid */
  strategy?: string;
}

export interface PatchedDashboardMoveRequest {
  columns?: ColumnIdRequest[];
  uncategorized?: string[];
}

export interface PatchedLabelRequest {
  /**
   * @minLength 1
   * @maxLength 16
   */
  text?: string;
  /**
   * @minLength 1
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  color?: string;
  /** @maxLength 128 */
  description?: string | null;
  /** @format uuid */
  strategy?: string;
}

export interface PatchedProcessCreateRequest {
  /** @format uuid */
  strategy?: string;
  /** @format uuid */
  dashboard_column?: string | null;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  description?: string;
  /** @format uuid */
  calling_configuration?: string;
  /** @format uuid */
  calendar_configuration?: string;
  /** @format uuid */
  voice_configuration?: string;
  /** @format uuid */
  campaign_non_working_days_configuration?: string;
  /** @format uuid */
  call_center_non_working_days_configuration?: string;
  /** @format uuid */
  bot_configuration?: string;
  /**
   * * `mp3` - Mp3
   * * `wav` - Wav
   * * `flac` - Flac
   */
  recordings_format?: RecordingsFormatEnum;
  sftp_data_transfer?: boolean;
  /** @maxLength 128 */
  in_glob?: string | null;
  /** @maxLength 128 */
  archive_path?: string | null;
  delete_archived_files?: boolean;
  /**
   * * `NEW_CAMPAIGN` - New Campaign
   * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
   */
  import_file_handling?: ImportFileHandlingEnum;
  daily_report?: boolean;
  labels?: string[];
  phone_numbers?: string[];
  /**
   * @min 1
   * @max 2147483647
   */
  guaranteed_lines?: number;
  use_idle_lines?: boolean;
  delete_data_after_report?: boolean;
  delete_data_offset?: string | null;
  inbound_enabled_for_closed_record?: boolean;
  direct_transfer_to_cc?: boolean;
  inbound_fallback_message?: string | null;
  /** @maxLength 128 */
  redirection_phone_number?: string | null;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  /**
   * @min 1
   * @max 2147483647
   */
  default_campaign_duration_days?: number;
  /** @maxLength 256 */
  external_sftp_recordings_path?: string | null;
  /** @maxLength 256 */
  external_sftp_reports_path?: string | null;
  /** @maxLength 256 */
  external_sftp_transcripts_path?: string | null;
  /** @format uuid */
  import_custom_faas?: string | null;
  upload_report_per_call?: boolean | null;
  upload_report_per_record?: boolean | null;
  upload_report_per_campaign?: boolean | null;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  daily_report_data_range?: DailyReportDataRangeEnum;
  /** @format uuid */
  report_per_call_custom_faas?: string | null;
  /** @format uuid */
  report_per_record_custom_faas?: string | null;
  /** @format uuid */
  report_per_campaign_custom_faas?: string | null;
  /** @format uuid */
  get_customer_info_faas?: string | null;
  /** @format uuid */
  report_feedback_faas?: string | null;
  cyclic_reports?: boolean;
  /**
   * @min 1
   * @max 2147483647
   */
  cyclic_reports_frequency_seconds?: number | null;
  cyclic_report_per_call?: boolean;
  cyclic_report_per_record?: boolean;
  cyclic_report_per_campaign?: boolean;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  cyclic_report_data_range?: DailyReportDataRangeEnum;
}

export interface PatchedRecordDataRequest {
  phone_numbers?: string[];
  /** @maxLength 128 */
  external_id?: string | null;
  dialogue_context_mapping?: ContextMappingRequest[];
  reporting_data?: any;
  imported_from?: any;
  /** @maxLength 128 */
  imported_filename?: string | null;
  is_inbound_only?: boolean;
}

export interface PatchedSftpConfigurationRequest {
  /**
   * * `PASSWORD` - Password
   * * `RSA_KEY` - Rsa Key
   */
  auth_method?: AuthMethodEnum;
  /**
   * @minLength 1
   * @maxLength 256
   */
  sftp_host?: string;
  /**
   * @min 1
   * @max 65535
   */
  sftp_port?: number;
  /**
   * @minLength 1
   * @maxLength 64
   */
  sftp_username?: string;
  /** @maxLength 64 */
  sftp_password?: string | null;
  /** @maxLength 4096 */
  sftp_ssh_public_key?: string | null;
  /**
   * @min 60
   * @max 2147483647
   */
  sftp_poll_timeout?: number;
}

export interface PatchedSftpPublicKeyRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  /**
   * @minLength 1
   * @maxLength 2048
   */
  ssh_public_key?: string;
}

export interface PatchedStrategyRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  /**
   * @min 1
   * @max 2147483647
   */
  assigned_lines?: number;
  cipher_output_data?: boolean;
  decipher_input_data?: boolean;
  /** @maxLength 4096 */
  cipher_public_key_gpg?: string | null;
}

export interface PatchedTenantPhoneNumberRequest {
  /** @format uuid */
  strategy?: string | null;
  process_list?: TenantPhoneNumberProcessListRequest[];
  campaign_list?: TenantPhoneNumberCampaignListRequest[];
}

export interface PatchedUserPermissionEditRequest {
  tenant_owner?: boolean;
  tenant_admin?: boolean;
  strategies_permissions?: UserStrategyPermissionRequest[];
}

export interface PatchedUserSettingsRequest {
  /**
   * * `ENGLISH` - English
   * * `POLISH` - Polish
   * * `SPANISH` - Spanish
   */
  app_language?: AppLanguageEnum;
  /**
   * @minLength 1
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  accent_color?: string;
  /**
   * @format binary
   * @pattern (?:jpg|jpeg|png)$
   */
  avatar?: File | null;
  campaign_created_notify?: boolean;
  campaign_status_scheduled_notify?: boolean;
  campaign_status_running_notify?: boolean;
  campaign_status_stopped_notify?: boolean;
  campaign_status_finished_notify?: boolean;
  campaign_status_error_notify?: boolean;
  campaign_pause_resume_outbound_notify?: boolean;
  campaign_pause_resume_inbound_notify?: boolean;
  campaign_pause_resume_cc_notify?: boolean;
  record_import_notify?: boolean;
  sftp_file_upload_notify?: boolean;
  process_create_notify?: boolean;
  process_update_notify?: boolean;
  process_delete_notify?: boolean;
}

export interface PatchedVoiceConfigurationRequest {
  /** @format uuid */
  strategy?: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name?: string;
  /**
   * * `MALE` - Male
   * * `FEMALE` - Female
   */
  gender?: GenderEnum;
  /**
   * Main language that will be used for TTS/ASR and the dialogue strategy
   *
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /**
   * Name of the ASR vendor
   *
   * * `techmo` - techmo
   * * `google` - google
   * * `google-vad` - google-vad
   * * `lekta` - lekta
   */
  asr_vendor?: AsrVendorEnum;
  /**
   * Name of the TTS vendor
   *
   * * `Microsoft` - Microsoft
   * * `Google` - Google
   * * `Techmo` - Techmo
   */
  tts_vendor?: TtsVendorEnum;
  /**
   * Name of the voice according to TTS vendor's documentation
   * @minLength 1
   * @maxLength 32
   */
  voice_name?: string;
  /**
   * Base speed for tts. Value 100 means normal speed. Handling of this field has to be implemented on the project side.
   * @min 1
   * @max 32767
   */
  base_tts_speed_percentage?: number;
}

export interface PhoneNumberHistory {
  /** @format uuid */
  strategy: string;
  history: PhoneNumberHistoryInfo[];
  all_calls: number;
  all_records: number;
}

export interface PhoneNumberHistoryInfo {
  /** @format uuid */
  campaign: string;
  process_name: string;
  campaign_strategy: string;
  process_labels: string[];
  records_count: number;
  bot_label: string;
  calls_count: number;
  /** @format date */
  start_of_campaign: string;
  /** @format date */
  end_of_campaign: string;
}

export interface PhoneNumberSwap {
  /** @format uuid */
  old_phone_number: string;
  /** @format uuid */
  new_phone_number: string;
}

export interface PhoneNumberSwapRequest {
  /** @format uuid */
  old_phone_number: string;
  /** @format uuid */
  new_phone_number: string;
}

export interface Process {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  readonly is_archived: boolean;
  /** @format uuid */
  dashboard_column?: string | null;
  readonly position: number;
  readonly last_changes: ProcessHistory;
  /** @maxLength 64 */
  name: string;
  description?: string;
  /** @format uuid */
  calling_configuration: string;
  /** @format uuid */
  calendar_configuration: string;
  /** @format uuid */
  voice_configuration: string;
  /** @format uuid */
  campaign_non_working_days_configuration: string;
  /** @format uuid */
  call_center_non_working_days_configuration: string;
  /** @format uuid */
  bot_configuration: string;
  /**
   * * `mp3` - Mp3
   * * `wav` - Wav
   * * `flac` - Flac
   */
  recordings_format?: RecordingsFormatEnum;
  sftp_data_transfer?: boolean;
  /** @maxLength 128 */
  in_glob?: string | null;
  /** @maxLength 128 */
  archive_path?: string | null;
  delete_archived_files?: boolean;
  /**
   * * `NEW_CAMPAIGN` - New Campaign
   * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
   */
  import_file_handling?: ImportFileHandlingEnum;
  daily_report?: boolean;
  readonly labels: Label[];
  readonly phone_numbers: TenantPhoneNumber[];
  /**
   * @min 1
   * @max 2147483647
   */
  guaranteed_lines: number;
  use_idle_lines?: boolean;
  delete_data_after_report?: boolean;
  delete_data_offset?: string | null;
  inbound_enabled_for_closed_record?: boolean;
  direct_transfer_to_cc?: boolean;
  inbound_fallback_message?: string | null;
  /** @maxLength 128 */
  redirection_phone_number?: string | null;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  /**
   * @min 1
   * @max 2147483647
   */
  default_campaign_duration_days?: number;
  /** @maxLength 256 */
  external_sftp_recordings_path?: string | null;
  /** @maxLength 256 */
  external_sftp_reports_path?: string | null;
  /** @maxLength 256 */
  external_sftp_transcripts_path?: string | null;
  /** @format uuid */
  import_custom_faas?: string | null;
  upload_report_per_call?: boolean | null;
  upload_report_per_record?: boolean | null;
  upload_report_per_campaign?: boolean | null;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  daily_report_data_range?: DailyReportDataRangeEnum;
  report_per_call_custom_faas?: CustomFaas;
  report_per_record_custom_faas?: CustomFaas;
  report_per_campaign_custom_faas?: CustomFaas;
  get_customer_info_faas?: CustomFaas;
  report_feedback_faas?: CustomFaas;
  cyclic_reports?: boolean;
  /**
   * @min 1
   * @max 2147483647
   */
  cyclic_reports_frequency_seconds?: number | null;
  cyclic_report_per_call?: boolean;
  cyclic_report_per_record?: boolean;
  cyclic_report_per_campaign?: boolean;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  cyclic_report_data_range?: DailyReportDataRangeEnum;
}

export interface ProcessCreateRequest {
  /** @format uuid */
  strategy: string;
  /** @format uuid */
  dashboard_column?: string | null;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  description?: string;
  /** @format uuid */
  calling_configuration: string;
  /** @format uuid */
  calendar_configuration: string;
  /** @format uuid */
  voice_configuration: string;
  /** @format uuid */
  campaign_non_working_days_configuration: string;
  /** @format uuid */
  call_center_non_working_days_configuration: string;
  /** @format uuid */
  bot_configuration: string;
  /**
   * * `mp3` - Mp3
   * * `wav` - Wav
   * * `flac` - Flac
   */
  recordings_format?: RecordingsFormatEnum;
  sftp_data_transfer?: boolean;
  /** @maxLength 128 */
  in_glob?: string | null;
  /** @maxLength 128 */
  archive_path?: string | null;
  delete_archived_files?: boolean;
  /**
   * * `NEW_CAMPAIGN` - New Campaign
   * * `UPDATE_EXISTING_CAMPAIGN` - Update Existing Campaign
   */
  import_file_handling?: ImportFileHandlingEnum;
  daily_report?: boolean;
  labels: string[];
  phone_numbers: string[];
  /**
   * @min 1
   * @max 2147483647
   */
  guaranteed_lines: number;
  use_idle_lines?: boolean;
  delete_data_after_report?: boolean;
  delete_data_offset?: string | null;
  inbound_enabled_for_closed_record?: boolean;
  direct_transfer_to_cc?: boolean;
  inbound_fallback_message?: string | null;
  /** @maxLength 128 */
  redirection_phone_number?: string | null;
  /**
   * * `CALLER` - Caller
   * * `CAMPAIGN` - Campaign
   */
  redirection_presentation_mode?: RedirectionPresentationModeEnum;
  /**
   * @min 1
   * @max 2147483647
   */
  default_campaign_duration_days?: number;
  /** @maxLength 256 */
  external_sftp_recordings_path?: string | null;
  /** @maxLength 256 */
  external_sftp_reports_path?: string | null;
  /** @maxLength 256 */
  external_sftp_transcripts_path?: string | null;
  /** @format uuid */
  import_custom_faas?: string | null;
  upload_report_per_call?: boolean | null;
  upload_report_per_record?: boolean | null;
  upload_report_per_campaign?: boolean | null;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  daily_report_data_range?: DailyReportDataRangeEnum;
  /** @format uuid */
  report_per_call_custom_faas?: string | null;
  /** @format uuid */
  report_per_record_custom_faas?: string | null;
  /** @format uuid */
  report_per_campaign_custom_faas?: string | null;
  /** @format uuid */
  get_customer_info_faas?: string | null;
  /** @format uuid */
  report_feedback_faas?: string | null;
  cyclic_reports?: boolean;
  /**
   * @min 1
   * @max 2147483647
   */
  cyclic_reports_frequency_seconds?: number | null;
  cyclic_report_per_call?: boolean;
  cyclic_report_per_record?: boolean;
  cyclic_report_per_campaign?: boolean;
  /**
   * * `CURRENT_DAY` - Current Day
   * * `FROM_START` - From Start
   */
  cyclic_report_data_range?: DailyReportDataRangeEnum;
}

export interface ProcessHistory {
  readonly state: StateEnum;
  readonly name: string;
  /** @format date */
  readonly date: string;
}

export interface ProcessStatusChartResponse {
  dialogue_status: ChartRow[];
  process_status: ChartRow[];
  interruption_status: ChartRow[];
}

export interface RandomizedTimeBetweenAttempts {
  min_time_between_attempts_seconds: number;
  max_time_between_attempts_seconds: number;
}

export interface RandomizedTimeBetweenAttemptsRequest {
  min_time_between_attempts_seconds: number;
  max_time_between_attempts_seconds: number;
}

export interface RecordData {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  readonly campaign: string;
  phone_numbers: string[];
  /** @maxLength 128 */
  external_id: string | null;
  dialogue_context_mapping: ContextMapping[];
  reporting_data?: any;
  imported_from?: any;
  /** @maxLength 128 */
  imported_filename?: string | null;
  readonly status: RecordDataStatusEnum;
  /** @format uuid */
  readonly strategy: string;
  readonly calls_count: number;
  is_inbound_only?: boolean;
}

export interface RecordDataRequest {
  phone_numbers: string[];
  /** @maxLength 128 */
  external_id: string | null;
  dialogue_context_mapping: ContextMappingRequest[];
  reporting_data?: any;
  imported_from?: any;
  /** @maxLength 128 */
  imported_filename?: string | null;
  is_inbound_only?: boolean;
}

/**
 * * `OPEN` - Open
 * * `FINISHED` - Finished
 * * `CANCELLED` - Cancelled
 * * `EXHAUSTED` - Exhausted
 */
export type RecordDataStatusEnum = 'OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED';

export interface RecordImportFileRequest {
  /** @format binary */
  file: File;
  /** @minLength 1 */
  file_name: string;
  /** @default false */
  validate_only?: boolean;
}

export interface RecordImportFileResponse {
  file_errors: FileError[];
  errors: RowError[];
  number_of_source_lines: number;
  number_of_imported_records: number;
}

/**
 * * `mp3` - Mp3
 * * `wav` - Wav
 * * `flac` - Flac
 */
export type RecordingsFormatEnum = 'mp3' | 'wav' | 'flac';

/**
 * * `CALLER` - Caller
 * * `CAMPAIGN` - Campaign
 */
export type RedirectionPresentationModeEnum = 'CALLER' | 'CAMPAIGN';

export interface ReportPerCallRequest {
  campaign_ids?: string[];
  /** @format date-time */
  start_date?: string;
  /** @format date-time */
  end_date?: string;
  columns?: string[];
  /**
   * * `csv` - csv
   * * `json` - json
   * * `xlsx` - xlsx
   */
  file_type?: FileTypeEnum;
}

export interface ReportPerCampaignRequest {
  campaign_ids: string[];
  columns?: string[];
  /**
   * * `csv` - csv
   * * `json` - json
   * * `xlsx` - xlsx
   */
  file_type?: FileTypeEnum;
}

export interface ReportPerRecordRequest {
  campaign_ids: string[];
  /** @format date-time */
  start_date?: string;
  /** @format date-time */
  end_date?: string;
  columns?: string[];
  /**
   * * `csv` - csv
   * * `json` - json
   * * `xlsx` - xlsx
   */
  file_type?: FileTypeEnum;
}

/**
 * * `USER` - User
 * * `ADMIN` - Admin
 * * `OWNER` - Owner
 */
export type RoleEnum = 'USER' | 'ADMIN' | 'OWNER';

export interface RowError {
  message: string;
  line_number: number;
}

/**
 * * `OPEN` - Open
 * * `CLOSED` - Closed
 * * `DISABLED` - Disabled
 * * `PAUSED` - Paused
 */
export type ServiceStatusEnum = 'OPEN' | 'CLOSED' | 'DISABLED' | 'PAUSED';

export interface SftpConfig {
  /** @maxLength 128 */
  login: string;
  /** @maxLength 100 */
  host: string;
  /** @maxLength 100 */
  port: string;
  /** @maxLength 2048 */
  ssh_public_key: string;
}

export interface SftpConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /**
   * * `PASSWORD` - Password
   * * `RSA_KEY` - Rsa Key
   */
  auth_method?: AuthMethodEnum;
  /** @maxLength 256 */
  sftp_host: string;
  /**
   * @min 1
   * @max 65535
   */
  sftp_port?: number;
  /** @maxLength 64 */
  sftp_username: string;
  /** @maxLength 64 */
  sftp_password?: string | null;
  /** @maxLength 4096 */
  sftp_ssh_public_key?: string | null;
  /**
   * @min 60
   * @max 2147483647
   */
  sftp_poll_timeout?: number;
}

export interface SftpConfigurationRequest {
  /**
   * * `PASSWORD` - Password
   * * `RSA_KEY` - Rsa Key
   */
  auth_method?: AuthMethodEnum;
  /**
   * @minLength 1
   * @maxLength 256
   */
  sftp_host: string;
  /**
   * @min 1
   * @max 65535
   */
  sftp_port?: number;
  /**
   * @minLength 1
   * @maxLength 64
   */
  sftp_username: string;
  /** @maxLength 64 */
  sftp_password?: string | null;
  /** @maxLength 4096 */
  sftp_ssh_public_key?: string | null;
  /**
   * @min 60
   * @max 2147483647
   */
  sftp_poll_timeout?: number;
}

export interface SftpCredentials {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 128 */
  password?: string;
  readonly login: string;
}

export interface SftpCredentialsCreateRequest {
  /**
   * @minLength 1
   * @maxLength 128
   */
  password: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  confirm_password: string;
}

export interface SftpPublicKey {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 64 */
  name: string;
  /** @maxLength 2048 */
  ssh_public_key: string;
}

export interface SftpPublicKeyRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 2048
   */
  ssh_public_key: string;
}

/**
 * * `CREATED` - CREATED
 * * `MODIFIED` - MODIFIED
 */
export type StateEnum = 'CREATED' | 'MODIFIED';

export interface Strategy {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @maxLength 64 */
  name: string;
  /**
   * @min 1
   * @max 2147483647
   */
  assigned_lines: number;
  cipher_output_data?: boolean;
  decipher_input_data?: boolean;
  /** @maxLength 4096 */
  cipher_public_key_gpg?: string | null;
  readonly input_public_key: string;
  readonly busy_lines: number;
}

export interface StrategyCreateRequest {
  /** @default false */
  demo?: boolean;
  strategy_data: StrategyRequest;
}

export interface StrategyDashboardData {
  campaigns: CampaignDashboardData[];
}

export interface StrategyPermission {
  read_access?: boolean;
  process_write?: boolean;
  campaign_write?: boolean;
  strategy?: boolean;
}

export interface StrategyPermissionList {
  /** @format uuid */
  strategy_id: string;
  permissions: string[];
}

export interface StrategyPermissionRequest {
  read_access?: boolean;
  process_write?: boolean;
  campaign_write?: boolean;
  strategy?: boolean;
}

export interface StrategyRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /**
   * @min 1
   * @max 2147483647
   */
  assigned_lines: number;
  cipher_output_data?: boolean;
  decipher_input_data?: boolean;
  /** @maxLength 4096 */
  cipher_public_key_gpg?: string | null;
}

/**
 * * `ANSWERED` - Answered
 * * `NOT_ANSWERED` - Not Answered
 * * `REJECTED` - Rejected
 * * `BUSY` - Busy
 * * `VOICEMAIL` - Voicemail
 * * `TRANSFER` - Transfer
 * * `NONEXISTENT_NUMBER` - Nonexistent Number
 * * `UNMAPPED` - Unmapped
 * * `ERROR` - Error
 */
export type TelcoStatusEnum =
  | 'ANSWERED'
  | 'NOT_ANSWERED'
  | 'REJECTED'
  | 'BUSY'
  | 'VOICEMAIL'
  | 'TRANSFER'
  | 'NONEXISTENT_NUMBER'
  | 'UNMAPPED'
  | 'ERROR';

export interface TenantPhoneNumber {
  /** @format uuid */
  readonly id: string;
  readonly phone_number_str: string;
  /** @format uuid */
  strategy?: string | null;
  readonly strategy_name: string;
  process_list: TenantPhoneNumberProcessList[];
  campaign_list: TenantPhoneNumberCampaignList[];
}

export interface TenantPhoneNumberCampaignList {
  /** @format uuid */
  id: string;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  is_only_used_number: boolean;
  process: string;
}

export interface TenantPhoneNumberCampaignListRequest {
  /** @format uuid */
  id: string;
  /** @format date */
  start_date: string;
  /** @format date */
  end_date: string;
  is_only_used_number: boolean;
  /** @minLength 1 */
  process: string;
}

export interface TenantPhoneNumberProcessList {
  /** @format uuid */
  id: string;
  name: string;
  is_only_used_number: boolean;
}

export interface TenantPhoneNumberProcessListRequest {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  is_only_used_number: boolean;
}

export interface TestSftpConfiguration {
  success: boolean;
  error_message: string;
  error_code: ErrorCodeEnum | BlankEnum;
}

/**
 * * `STATIC` - Static
 * * `DYNAMIC` - Dynamic
 * * `RANDOMIZED` - Randomized
 */
export type TimeBetweenAttemptsModeEnum = 'STATIC' | 'DYNAMIC' | 'RANDOMIZED';

export interface TimeSeries {
  name: string;
  value: number;
}

export interface Token {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 64 */
  name: string;
  /** @format date */
  expiration_date?: string | null;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  last_used?: string | null;
}

export interface TokenDisplay {
  /** @format uuid */
  readonly id: string;
  /** @maxLength 64 */
  name: string;
  /** @format date */
  expiration_date?: string | null;
  readonly token: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  last_used?: string | null;
}

export interface TokenRequest {
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /** @format date */
  expiration_date?: string | null;
  /** @format date-time */
  last_used?: string | null;
}

/**
 * * `USER` - User
 * * `SUPERADMIN` - Superadmin
 * * `SYSTEM` - System
 * * `TOKEN` - Token
 * * `SFTP` - Sftp
 */
export type TriggeredByEnum = 'USER' | 'SUPERADMIN' | 'SYSTEM' | 'TOKEN' | 'SFTP';

/**
 * * `Microsoft` - Microsoft
 * * `Google` - Google
 * * `Techmo` - Techmo
 */
export type TtsVendorEnum = 'Microsoft' | 'Google' | 'Techmo';

export interface UserActivationRequest {
  is_active: boolean;
}

export interface UserAdminPermissions {
  is_tenant_owner: boolean;
  is_tenant_admin: boolean;
}

export interface UserAggregatedPermissions {
  /** @format uuid */
  user_id: string;
  is_tenant_owner: boolean;
  is_tenant_admin: boolean;
  strategy: boolean;
  read_access: boolean;
  process_write: boolean;
  campaign_write: boolean;
}

export interface UserAggregatedPermissionsList {
  user_permissions: UserAggregatedPermissions[];
}

export interface UserPermissionEdit {
  tenant_owner?: boolean;
  tenant_admin?: boolean;
  strategies_permissions?: UserStrategyPermission[];
}

export interface UserPublic {
  /** @format uuid */
  readonly id: string;
  /**
   * Email Address
   * @format email
   * @maxLength 254
   */
  email: string;
  is_super_admin?: boolean;
  is_supervisor?: boolean;
  readonly companies: Company[];
  /** @maxLength 128 */
  first_name?: string;
  /** @maxLength 128 */
  last_name?: string;
}

export interface UserSettings {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /**
   * * `ENGLISH` - English
   * * `POLISH` - Polish
   * * `SPANISH` - Spanish
   */
  app_language?: AppLanguageEnum;
  /**
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  accent_color?: string;
  readonly initials: string;
  /**
   * @format uri
   * @pattern (?:jpg|jpeg|png)$
   */
  avatar?: string | null;
  campaign_created_notify?: boolean;
  campaign_status_scheduled_notify?: boolean;
  campaign_status_running_notify?: boolean;
  campaign_status_stopped_notify?: boolean;
  campaign_status_finished_notify?: boolean;
  campaign_status_error_notify?: boolean;
  campaign_pause_resume_outbound_notify?: boolean;
  campaign_pause_resume_inbound_notify?: boolean;
  campaign_pause_resume_cc_notify?: boolean;
  record_import_notify?: boolean;
  sftp_file_upload_notify?: boolean;
  process_create_notify?: boolean;
  process_update_notify?: boolean;
  process_delete_notify?: boolean;
}

export interface UserSettingsRequest {
  /**
   * * `ENGLISH` - English
   * * `POLISH` - Polish
   * * `SPANISH` - Spanish
   */
  app_language?: AppLanguageEnum;
  /**
   * @minLength 1
   * @maxLength 25
   * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
   */
  accent_color?: string;
  /**
   * @format binary
   * @pattern (?:jpg|jpeg|png)$
   */
  avatar?: File | null;
  campaign_created_notify?: boolean;
  campaign_status_scheduled_notify?: boolean;
  campaign_status_running_notify?: boolean;
  campaign_status_stopped_notify?: boolean;
  campaign_status_finished_notify?: boolean;
  campaign_status_error_notify?: boolean;
  campaign_pause_resume_outbound_notify?: boolean;
  campaign_pause_resume_inbound_notify?: boolean;
  campaign_pause_resume_cc_notify?: boolean;
  record_import_notify?: boolean;
  sftp_file_upload_notify?: boolean;
  process_create_notify?: boolean;
  process_update_notify?: boolean;
  process_delete_notify?: boolean;
}

export interface UserStrategyPermission {
  strategy_id: string;
  permission: StrategyPermission;
}

export interface UserStrategyPermissionRequest {
  /** @minLength 1 */
  strategy_id: string;
  permission: StrategyPermissionRequest;
}

export interface UserStrategyPermissions {
  is_tenant_owner: boolean;
  is_tenant_admin: boolean;
  strategy: boolean;
  read_access: boolean;
  process_write: boolean;
  campaign_write: boolean;
}

export interface UserTenant {
  /** @format uuid */
  readonly id: string;
  /** Active */
  is_active?: boolean;
  /**
   * Email Address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** @maxLength 128 */
  first_name?: string;
  /** @maxLength 128 */
  last_name?: string;
  /** @format date-time */
  last_login?: string | null;
  accent_color?: string;
  /** @format uri */
  avatar?: string | null;
  is_tenant_owner: boolean;
  is_tenant_admin: boolean;
  strategies_permissions: StrategyPermissionList[];
}

export interface VoiceConfiguration {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name: string;
  /**
   * * `MALE` - Male
   * * `FEMALE` - Female
   */
  gender?: GenderEnum;
  /**
   * Main language that will be used for TTS/ASR and the dialogue strategy
   *
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /**
   * Name of the ASR vendor
   *
   * * `techmo` - techmo
   * * `google` - google
   * * `google-vad` - google-vad
   * * `lekta` - lekta
   */
  asr_vendor: AsrVendorEnum;
  /**
   * Name of the TTS vendor
   *
   * * `Microsoft` - Microsoft
   * * `Google` - Google
   * * `Techmo` - Techmo
   */
  tts_vendor: TtsVendorEnum;
  /**
   * Name of the voice according to TTS vendor's documentation
   * @maxLength 32
   */
  voice_name: string;
  /**
   * Base speed for tts. Value 100 means normal speed. Handling of this field has to be implemented on the project side.
   * @min 1
   * @max 32767
   */
  base_tts_speed_percentage?: number;
}

export interface VoiceConfigurationRequest {
  /** @format uuid */
  strategy: string;
  /**
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  /**
   * * `MALE` - Male
   * * `FEMALE` - Female
   */
  gender?: GenderEnum;
  /**
   * Main language that will be used for TTS/ASR and the dialogue strategy
   *
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /**
   * Name of the ASR vendor
   *
   * * `techmo` - techmo
   * * `google` - google
   * * `google-vad` - google-vad
   * * `lekta` - lekta
   */
  asr_vendor: AsrVendorEnum;
  /**
   * Name of the TTS vendor
   *
   * * `Microsoft` - Microsoft
   * * `Google` - Google
   * * `Techmo` - Techmo
   */
  tts_vendor: TtsVendorEnum;
  /**
   * Name of the voice according to TTS vendor's documentation
   * @minLength 1
   * @maxLength 32
   */
  voice_name: string;
  /**
   * Base speed for tts. Value 100 means normal speed. Handling of this field has to be implemented on the project side.
   * @min 1
   * @max 32767
   */
  base_tts_speed_percentage?: number;
}

export interface VoiceConfigurationSnapshot {
  /** @format uuid */
  readonly id: string;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  readonly updated_at: string;
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  /**
   * * `MALE` - Male
   * * `FEMALE` - Female
   */
  gender?: GenderEnum;
  /**
   * Main language that will be used for TTS/ASR and the dialogue strategy
   *
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /**
   * Name of the ASR vendor
   *
   * * `techmo` - techmo
   * * `google` - google
   * * `google-vad` - google-vad
   * * `lekta` - lekta
   */
  asr_vendor: AsrVendorEnum;
  /**
   * Name of the TTS vendor
   *
   * * `Microsoft` - Microsoft
   * * `Google` - Google
   * * `Techmo` - Techmo
   */
  tts_vendor: TtsVendorEnum;
  /**
   * Name of the voice according to TTS vendor's documentation
   * @maxLength 32
   */
  voice_name: string;
  /**
   * Base speed for tts. Value 100 means normal speed. Handling of this field has to be implemented on the project side.
   * @min 1
   * @max 32767
   */
  base_tts_speed_percentage?: number;
}

export interface VoiceConfigurationSnapshotRequest {
  /** @format uuid */
  strategy: string;
  /** @maxLength 64 */
  name?: string | null;
  /**
   * * `MALE` - Male
   * * `FEMALE` - Female
   */
  gender?: GenderEnum;
  /**
   * Main language that will be used for TTS/ASR and the dialogue strategy
   *
   * * `pl-PL` - pl-PL
   * * `es-ES` - es-ES
   * * `es-MX` - es-MX
   * * `en-US` - en-US
   * * `en-AU` - en-AU
   */
  language?: BotLanguageEnum;
  /**
   * Name of the ASR vendor
   *
   * * `techmo` - techmo
   * * `google` - google
   * * `google-vad` - google-vad
   * * `lekta` - lekta
   */
  asr_vendor: AsrVendorEnum;
  /**
   * Name of the TTS vendor
   *
   * * `Microsoft` - Microsoft
   * * `Google` - Google
   * * `Techmo` - Techmo
   */
  tts_vendor: TtsVendorEnum;
  /**
   * Name of the voice according to TTS vendor's documentation
   * @minLength 1
   * @maxLength 32
   */
  voice_name: string;
  /**
   * Base speed for tts. Value 100 means normal speed. Handling of this field has to be implemented on the project side.
   * @min 1
   * @max 32767
   */
  base_tts_speed_percentage?: number;
}

export interface VoiceList {
  voices: string[];
}

export interface WorkingHours {
  offset: number;
  duration: number;
}

export interface WorkingHoursRequest {
  offset: number;
  duration: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title No title
 * @version 0.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags access-tokens
     * @name AccessTokensList
     * @request GET:/api/access-tokens
     * @secure
     * @response `200` `PaginatedTokenList`
     */
    accessTokensList: (
      query?: {
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedTokenList, any>({
        path: `/api/access-tokens`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-tokens
     * @name AccessTokensCreate
     * @request POST:/api/access-tokens
     * @secure
     * @response `201` `TokenDisplay`
     */
    accessTokensCreate: (data: TokenRequest, params: RequestParams = {}) =>
      this.request<TokenDisplay, any>({
        path: `/api/access-tokens`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-tokens
     * @name AccessTokensDestroy
     * @request DELETE:/api/access-tokens/{id}
     * @secure
     * @response `204` `void` No response body
     */
    accessTokensDestroy: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/access-tokens/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags bot-configurations
     * @name BotConfigurationsCreate
     * @request POST:/api/bot-configurations
     * @secure
     * @response `201` `BotConfiguration`
     */
    botConfigurationsCreate: (data: BotConfigurationRequest, params: RequestParams = {}) =>
      this.request<BotConfiguration, any>({
        path: `/api/bot-configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bot-configurations
     * @name BotConfigurationsRetrieve
     * @request GET:/api/bot-configurations/{bot_configuration_id}
     * @secure
     * @response `200` `BotConfiguration`
     */
    botConfigurationsRetrieve: (botConfigurationId: string, params: RequestParams = {}) =>
      this.request<BotConfiguration, any>({
        path: `/api/bot-configurations/${botConfigurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bot-configurations
     * @name BotConfigurationsUpdate
     * @request PUT:/api/bot-configurations/{bot_configuration_id}
     * @secure
     * @response `200` `BotConfiguration`
     */
    botConfigurationsUpdate: (botConfigurationId: string, data: BotConfigurationRequest, params: RequestParams = {}) =>
      this.request<BotConfiguration, any>({
        path: `/api/bot-configurations/${botConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bot-configurations
     * @name BotConfigurationsPartialUpdate
     * @request PATCH:/api/bot-configurations/{bot_configuration_id}
     * @secure
     * @response `200` `BotConfiguration`
     */
    botConfigurationsPartialUpdate: (
      botConfigurationId: string,
      data: PatchedBotConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<BotConfiguration, any>({
        path: `/api/bot-configurations/${botConfigurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bot-configurations
     * @name BotConfigurationsDestroy
     * @request DELETE:/api/bot-configurations/{bot_configuration_id}
     * @secure
     * @response `204` `void` No response body
     */
    botConfigurationsDestroy: (botConfigurationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/bot-configurations/${botConfigurationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags calendar-configuration
     * @name CalendarConfigurationCreate
     * @request POST:/api/calendar-configuration
     * @secure
     * @response `201` `CalendarConfiguration`
     */
    calendarConfigurationCreate: (data: CalendarConfigurationRequest, params: RequestParams = {}) =>
      this.request<CalendarConfiguration, any>({
        path: `/api/calendar-configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calendar-configuration
     * @name CalendarConfigurationRetrieve
     * @request GET:/api/calendar-configuration/{calendar_configuration_id}
     * @secure
     * @response `200` `CalendarConfiguration`
     */
    calendarConfigurationRetrieve: (calendarConfigurationId: string, params: RequestParams = {}) =>
      this.request<CalendarConfiguration, any>({
        path: `/api/calendar-configuration/${calendarConfigurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calendar-configuration
     * @name CalendarConfigurationUpdate
     * @request PUT:/api/calendar-configuration/{calendar_configuration_id}
     * @secure
     * @response `200` `CalendarConfiguration`
     */
    calendarConfigurationUpdate: (
      calendarConfigurationId: string,
      data: CalendarConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CalendarConfiguration, any>({
        path: `/api/calendar-configuration/${calendarConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calendar-configuration
     * @name CalendarConfigurationPartialUpdate
     * @request PATCH:/api/calendar-configuration/{calendar_configuration_id}
     * @secure
     * @response `200` `CalendarConfiguration`
     */
    calendarConfigurationPartialUpdate: (
      calendarConfigurationId: string,
      data: PatchedCalendarConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CalendarConfiguration, any>({
        path: `/api/calendar-configuration/${calendarConfigurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calendar-configuration
     * @name CalendarConfigurationDestroy
     * @request DELETE:/api/calendar-configuration/{calendar_configuration_id}
     * @secure
     * @response `204` `void` No response body
     */
    calendarConfigurationDestroy: (calendarConfigurationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/calendar-configuration/${calendarConfigurationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-center-non-working-days-configuration
     * @name CallCenterNonWorkingDaysConfigurationCreate
     * @request POST:/api/call-center-non-working-days-configuration
     * @secure
     * @response `201` `CallCenterNonWorkingDaysConfiguration`
     */
    callCenterNonWorkingDaysConfigurationCreate: (
      data: CallCenterNonWorkingDaysConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CallCenterNonWorkingDaysConfiguration, any>({
        path: `/api/call-center-non-working-days-configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-center-non-working-days-configuration
     * @name CallCenterNonWorkingDaysConfigurationRetrieve
     * @request GET:/api/call-center-non-working-days-configuration/{call_center_non_working_days_configuration_id}
     * @secure
     * @response `200` `CallCenterNonWorkingDaysConfiguration`
     */
    callCenterNonWorkingDaysConfigurationRetrieve: (
      callCenterNonWorkingDaysConfigurationId: string,
      params: RequestParams = {},
    ) =>
      this.request<CallCenterNonWorkingDaysConfiguration, any>({
        path: `/api/call-center-non-working-days-configuration/${callCenterNonWorkingDaysConfigurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-center-non-working-days-configuration
     * @name CallCenterNonWorkingDaysConfigurationUpdate
     * @request PUT:/api/call-center-non-working-days-configuration/{call_center_non_working_days_configuration_id}
     * @secure
     * @response `200` `CallCenterNonWorkingDaysConfiguration`
     */
    callCenterNonWorkingDaysConfigurationUpdate: (
      callCenterNonWorkingDaysConfigurationId: string,
      data: CallCenterNonWorkingDaysConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CallCenterNonWorkingDaysConfiguration, any>({
        path: `/api/call-center-non-working-days-configuration/${callCenterNonWorkingDaysConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-center-non-working-days-configuration
     * @name CallCenterNonWorkingDaysConfigurationPartialUpdate
     * @request PATCH:/api/call-center-non-working-days-configuration/{call_center_non_working_days_configuration_id}
     * @secure
     * @response `200` `CallCenterNonWorkingDaysConfiguration`
     */
    callCenterNonWorkingDaysConfigurationPartialUpdate: (
      callCenterNonWorkingDaysConfigurationId: string,
      data: PatchedCallCenterNonWorkingDaysConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CallCenterNonWorkingDaysConfiguration, any>({
        path: `/api/call-center-non-working-days-configuration/${callCenterNonWorkingDaysConfigurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags call-center-non-working-days-configuration
     * @name CallCenterNonWorkingDaysConfigurationDestroy
     * @request DELETE:/api/call-center-non-working-days-configuration/{call_center_non_working_days_configuration_id}
     * @secure
     * @response `204` `void` No response body
     */
    callCenterNonWorkingDaysConfigurationDestroy: (
      callCenterNonWorkingDaysConfigurationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/call-center-non-working-days-configuration/${callCenterNonWorkingDaysConfigurationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags calling-configurations
     * @name CallingConfigurationsCreate
     * @request POST:/api/calling-configurations
     * @secure
     * @response `201` `CallingConfiguration`
     */
    callingConfigurationsCreate: (data: CallingConfigurationRequest, params: RequestParams = {}) =>
      this.request<CallingConfiguration, any>({
        path: `/api/calling-configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calling-configurations
     * @name CallingConfigurationsBatchDestroy
     * @request DELETE:/api/calling-configurations
     * @secure
     * @response `204` `void` No response body
     */
    callingConfigurationsBatchDestroy: (
      query: {
        id: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/calling-configurations`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags calling-configurations
     * @name CallingConfigurationsRetrieve
     * @request GET:/api/calling-configurations/{calling_configuration_id}
     * @secure
     * @response `200` `CallingConfiguration`
     */
    callingConfigurationsRetrieve: (callingConfigurationId: string, params: RequestParams = {}) =>
      this.request<CallingConfiguration, any>({
        path: `/api/calling-configurations/${callingConfigurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calling-configurations
     * @name CallingConfigurationsUpdate
     * @request PUT:/api/calling-configurations/{calling_configuration_id}
     * @secure
     * @response `200` `CallingConfiguration`
     */
    callingConfigurationsUpdate: (
      callingConfigurationId: string,
      data: CallingConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CallingConfiguration, any>({
        path: `/api/calling-configurations/${callingConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calling-configurations
     * @name CallingConfigurationsPartialUpdate
     * @request PATCH:/api/calling-configurations/{calling_configuration_id}
     * @secure
     * @response `200` `CallingConfiguration`
     */
    callingConfigurationsPartialUpdate: (
      callingConfigurationId: string,
      data: PatchedCallingConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CallingConfiguration, any>({
        path: `/api/calling-configurations/${callingConfigurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calling-configurations
     * @name CallingConfigurationsDestroy
     * @request DELETE:/api/calling-configurations/{calling_configuration_id}
     * @secure
     * @response `204` `void` No response body
     */
    callingConfigurationsDestroy: (callingConfigurationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/calling-configurations/${callingConfigurationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags calls
     * @name CallsRetrieve
     * @request GET:/api/calls/{call_id}
     * @secure
     * @response `200` `Call`
     */
    callsRetrieve: (callId: string, params: RequestParams = {}) =>
      this.request<Call, any>({
        path: `/api/calls/${callId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calls
     * @name CallsRecordingRetrieve
     * @request GET:/api/calls/{call_id}/recording
     * @secure
     * @response `200` `File`
     * @response `400` `void` No response body
     * @response `404` `void` No response body
     */
    callsRecordingRetrieve: (
      callId: string,
      query?: {
        audio_format?: 'flac' | 'mp3' | 'wav';
      },
      params: RequestParams = {},
    ) =>
      this.request<File, void>({
        path: `/api/calls/${callId}/recording`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags calls
     * @name CallsTranscriptRetrieve
     * @request GET:/api/calls/{call_id}/transcript
     * @secure
     * @response `200` `File`
     * @response `400` `void` No response body
     * @response `404` `void` No response body
     */
    callsTranscriptRetrieve: (callId: string, params: RequestParams = {}) =>
      this.request<File, void>({
        path: `/api/calls/${callId}/transcript`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaign-non-working-days-configuration
     * @name CampaignNonWorkingDaysConfigurationCreate
     * @request POST:/api/campaign-non-working-days-configuration
     * @secure
     * @response `201` `CampaignNonWorkingDaysConfiguration`
     */
    campaignNonWorkingDaysConfigurationCreate: (
      data: CampaignNonWorkingDaysConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CampaignNonWorkingDaysConfiguration, any>({
        path: `/api/campaign-non-working-days-configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaign-non-working-days-configuration
     * @name CampaignNonWorkingDaysConfigurationRetrieve
     * @request GET:/api/campaign-non-working-days-configuration/{campaign_non_working_days_configuration_id}
     * @secure
     * @response `200` `CampaignNonWorkingDaysConfiguration`
     */
    campaignNonWorkingDaysConfigurationRetrieve: (
      campaignNonWorkingDaysConfigurationId: string,
      params: RequestParams = {},
    ) =>
      this.request<CampaignNonWorkingDaysConfiguration, any>({
        path: `/api/campaign-non-working-days-configuration/${campaignNonWorkingDaysConfigurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaign-non-working-days-configuration
     * @name CampaignNonWorkingDaysConfigurationUpdate
     * @request PUT:/api/campaign-non-working-days-configuration/{campaign_non_working_days_configuration_id}
     * @secure
     * @response `200` `CampaignNonWorkingDaysConfiguration`
     */
    campaignNonWorkingDaysConfigurationUpdate: (
      campaignNonWorkingDaysConfigurationId: string,
      data: CampaignNonWorkingDaysConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CampaignNonWorkingDaysConfiguration, any>({
        path: `/api/campaign-non-working-days-configuration/${campaignNonWorkingDaysConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaign-non-working-days-configuration
     * @name CampaignNonWorkingDaysConfigurationPartialUpdate
     * @request PATCH:/api/campaign-non-working-days-configuration/{campaign_non_working_days_configuration_id}
     * @secure
     * @response `200` `CampaignNonWorkingDaysConfiguration`
     */
    campaignNonWorkingDaysConfigurationPartialUpdate: (
      campaignNonWorkingDaysConfigurationId: string,
      data: PatchedCampaignNonWorkingDaysConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CampaignNonWorkingDaysConfiguration, any>({
        path: `/api/campaign-non-working-days-configuration/${campaignNonWorkingDaysConfigurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaign-non-working-days-configuration
     * @name CampaignNonWorkingDaysConfigurationDestroy
     * @request DELETE:/api/campaign-non-working-days-configuration/{campaign_non_working_days_configuration_id}
     * @secure
     * @response `204` `void` No response body
     */
    campaignNonWorkingDaysConfigurationDestroy: (
      campaignNonWorkingDaysConfigurationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/campaign-non-working-days-configuration/${campaignNonWorkingDaysConfigurationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsCreate
     * @request POST:/api/campaigns
     * @secure
     * @response `201` `Campaign`
     */
    campaignsCreate: (data: CampaignCreateRequest, params: RequestParams = {}) =>
      this.request<Campaign, any>({
        path: `/api/campaigns`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsBatchDestroy
     * @request DELETE:/api/campaigns
     * @secure
     * @response `204` `void` No response body
     */
    campaignsBatchDestroy: (
      query: {
        id: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/campaigns`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRetrieve
     * @request GET:/api/campaigns/{campaign_id}
     * @secure
     * @response `200` `Campaign`
     */
    campaignsRetrieve: (campaignId: string, params: RequestParams = {}) =>
      this.request<Campaign, any>({
        path: `/api/campaigns/${campaignId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsUpdate
     * @request PUT:/api/campaigns/{campaign_id}
     * @secure
     * @response `200` `Campaign`
     */
    campaignsUpdate: (campaignId: string, data: CampaignRequest, params: RequestParams = {}) =>
      this.request<Campaign, any>({
        path: `/api/campaigns/${campaignId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsPartialUpdate
     * @request PATCH:/api/campaigns/{campaign_id}
     * @secure
     * @response `200` `Campaign`
     */
    campaignsPartialUpdate: (campaignId: string, data: PatchedCampaignRequest, params: RequestParams = {}) =>
      this.request<Campaign, any>({
        path: `/api/campaigns/${campaignId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsDestroy
     * @request DELETE:/api/campaigns/{campaign_id}
     * @secure
     * @response `204` `void` No response body
     */
    campaignsDestroy: (campaignId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/campaigns/${campaignId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsBotBuildsList
     * @request GET:/api/campaigns/{campaign_id}/bot-builds
     * @secure
     * @response `200` `(BotBuild)[]`
     */
    campaignsBotBuildsList: (campaignId: string, params: RequestParams = {}) =>
      this.request<BotBuild[], any>({
        path: `/api/campaigns/${campaignId}/bot-builds`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsBotBuildsRetrieve
     * @request GET:/api/campaigns/{campaign_id}/bot-builds/{id}
     * @secure
     * @response `200` `BotBuild`
     */
    campaignsBotBuildsRetrieve: (campaignId: string, id: string, params: RequestParams = {}) =>
      this.request<BotBuild, any>({
        path: `/api/campaigns/${campaignId}/bot-builds/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsBotBuildsUpdate
     * @request PUT:/api/campaigns/{campaign_id}/bot-builds/{id}
     * @secure
     * @response `200` `BotBuild`
     */
    campaignsBotBuildsUpdate: (campaignId: string, id: string, data: BotBuildRequest, params: RequestParams = {}) =>
      this.request<BotBuild, any>({
        path: `/api/campaigns/${campaignId}/bot-builds/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsBotBuildsPartialUpdate
     * @request PATCH:/api/campaigns/{campaign_id}/bot-builds/{id}
     * @secure
     * @response `200` `BotBuild`
     */
    campaignsBotBuildsPartialUpdate: (
      campaignId: string,
      id: string,
      data: PatchedBotBuildRequest,
      params: RequestParams = {},
    ) =>
      this.request<BotBuild, any>({
        path: `/api/campaigns/${campaignId}/bot-builds/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsBotBuildsDestroy
     * @request DELETE:/api/campaigns/{campaign_id}/bot-builds/{id}
     * @secure
     * @response `204` `void` No response body
     */
    campaignsBotBuildsDestroy: (campaignId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/campaigns/${campaignId}/bot-builds/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsCallsList
     * @request GET:/api/campaigns/{campaign_id}/calls
     * @secure
     * @response `200` `PaginatedCallList`
     */
    campaignsCallsList: (
      campaignId: string,
      query?: {
        call_type?: 'INBOUND' | 'OUTBOUND';
        /** @format date */
        date_from?: string;
        /** @format date */
        date_to?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Specify the field to order by. Prepend minus sign for reverse order. */
        ordering?: '-created_at' | 'created_at';
        record_id?: string;
        record_phone_number?: string;
        telco_status_list?: (
          | 'ANSWERED'
          | 'NOT_ANSWERED'
          | 'REJECTED'
          | 'BUSY'
          | 'VOICEMAIL'
          | 'TRANSFER'
          | 'NONEXISTENT_NUMBER'
          | 'UNMAPPED'
          | 'ERROR'
        )[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCallList, any>({
        path: `/api/campaigns/${campaignId}/calls`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsCampaignUpdatesCreate
     * @request POST:/api/campaigns/{campaign_id}/campaign-updates
     * @secure
     * @response `201` `Campaign`
     */
    campaignsCampaignUpdatesCreate: (
      campaignId: string,
      data: CampaignUpdateStatusRequest,
      params: RequestParams = {},
    ) =>
      this.request<Campaign, any>({
        path: `/api/campaigns/${campaignId}/campaign-updates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsHistoryList
     * @request GET:/api/campaigns/{campaign_id}/history
     * @secure
     * @response `200` `(CampaignUpdate)[]`
     */
    campaignsHistoryList: (campaignId: string, params: RequestParams = {}) =>
      this.request<CampaignUpdate[], any>({
        path: `/api/campaigns/${campaignId}/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsLabelsUpdate
     * @request PUT:/api/campaigns/{campaign_id}/labels
     * @secure
     * @response `200` `ModifyLabels`
     */
    campaignsLabelsUpdate: (campaignId: string, data: ModifyLabelsRequest, params: RequestParams = {}) =>
      this.request<ModifyLabels, any>({
        path: `/api/campaigns/${campaignId}/labels`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsPauseCcCreate
     * @request POST:/api/campaigns/{campaign_id}/pause-cc
     * @secure
     * @response `204` `void` No response body
     */
    campaignsPauseCcCreate: (campaignId: string, data: CampaignPauseCcRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/campaigns/${campaignId}/pause-cc`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsPauseInboundCreate
     * @request POST:/api/campaigns/{campaign_id}/pause-inbound
     * @secure
     * @response `204` `void` No response body
     */
    campaignsPauseInboundCreate: (campaignId: string, data: CampaignPauseInboundRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/campaigns/${campaignId}/pause-inbound`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordingsRetrieve
     * @request GET:/api/campaigns/{campaign_id}/recordings
     * @secure
     * @response `200` `File`
     * @response `400` `void` No response body
     * @response `404` `void` No response body
     */
    campaignsRecordingsRetrieve: (
      campaignId: string,
      query?: {
        call_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<File, void>({
        path: `/api/campaigns/${campaignId}/recordings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsList
     * @request GET:/api/campaigns/{campaign_id}/records
     * @secure
     * @response `200` `PaginatedRecordDataList`
     */
    campaignsRecordsList: (
      campaignId: string,
      query?: {
        imported_from?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Specify the field to order by. Prepend minus sign for reverse order. */
        ordering?: '-created_at' | 'created_at';
        phone_number?: string;
        record_id?: string;
        status_list?: ('OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED')[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedRecordDataList, any>({
        path: `/api/campaigns/${campaignId}/records`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsCreate
     * @request POST:/api/campaigns/{campaign_id}/records
     * @secure
     * @response `201` `RecordData`
     */
    campaignsRecordsCreate: (campaignId: string, data: RecordDataRequest, params: RequestParams = {}) =>
      this.request<RecordData, any>({
        path: `/api/campaigns/${campaignId}/records`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsRetrieve
     * @request GET:/api/campaigns/{campaign_id}/records/{id}
     * @secure
     * @response `200` `RecordData`
     */
    campaignsRecordsRetrieve: (campaignId: string, id: string, params: RequestParams = {}) =>
      this.request<RecordData, any>({
        path: `/api/campaigns/${campaignId}/records/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsUpdate
     * @request PUT:/api/campaigns/{campaign_id}/records/{id}
     * @secure
     * @response `200` `RecordData`
     */
    campaignsRecordsUpdate: (campaignId: string, id: string, data: RecordDataRequest, params: RequestParams = {}) =>
      this.request<RecordData, any>({
        path: `/api/campaigns/${campaignId}/records/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsPartialUpdate
     * @request PATCH:/api/campaigns/{campaign_id}/records/{id}
     * @secure
     * @response `200` `RecordData`
     */
    campaignsRecordsPartialUpdate: (
      campaignId: string,
      id: string,
      data: PatchedRecordDataRequest,
      params: RequestParams = {},
    ) =>
      this.request<RecordData, any>({
        path: `/api/campaigns/${campaignId}/records/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsDestroy
     * @request DELETE:/api/campaigns/{campaign_id}/records/{id}
     * @secure
     * @response `204` `void` No response body
     */
    campaignsRecordsDestroy: (campaignId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/campaigns/${campaignId}/records/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsImportCreate
     * @request POST:/api/campaigns/{campaign_id}/records/import
     * @secure
     * @response `200` `RecordImportFileResponse`
     */
    campaignsRecordsImportCreate: (campaignId: string, data: RecordImportFileRequest, params: RequestParams = {}) =>
      this.request<RecordImportFileResponse, any>({
        path: `/api/campaigns/${campaignId}/records/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsRecordsStopUpdate
     * @request PUT:/api/campaigns/{campaign_id}/records/stop
     * @secure
     * @response `204` `void` No response body
     */
    campaignsRecordsStopUpdate: (
      campaignId: string,
      query: {
        id: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/campaigns/${campaignId}/records/stop`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsReportRetrieve
     * @request GET:/api/campaigns/{campaign_id}/report
     * @secure
     * @response `200` `File`
     * @response `400` `void` No response body
     * @response `404` `void` No response body
     */
    campaignsReportRetrieve: (campaignId: string, params: RequestParams = {}) =>
      this.request<File, void>({
        path: `/api/campaigns/${campaignId}/report`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsStatusRetrieve
     * @request GET:/api/campaigns/{campaign_id}/status
     * @secure
     * @response `200` `CampaignStatus`
     */
    campaignsStatusRetrieve: (campaignId: string, params: RequestParams = {}) =>
      this.request<CampaignStatus, any>({
        path: `/api/campaigns/${campaignId}/status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsTranscriptsRetrieve
     * @request GET:/api/campaigns/{campaign_id}/transcripts
     * @secure
     * @response `200` `File`
     * @response `400` `void` No response body
     * @response `404` `void` No response body
     */
    campaignsTranscriptsRetrieve: (
      campaignId: string,
      query?: {
        call_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<File, void>({
        path: `/api/campaigns/${campaignId}/transcripts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags campaigns
     * @name CampaignsUpdateCreate
     * @request POST:/api/campaigns/{campaign_id}/update
     * @secure
     * @response `200` `CampaignChange`
     */
    campaignsUpdateCreate: (campaignId: string, data: CampaignChangeRequest, params: RequestParams = {}) =>
      this.request<CampaignChange, any>({
        path: `/api/campaigns/${campaignId}/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags config
     * @name ConfigRetrieve
     * @request GET:/api/config
     * @secure
     * @response `200` `AppConfig`
     */
    configRetrieve: (params: RequestParams = {}) =>
      this.request<AppConfig, any>({
        path: `/api/config`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags config
     * @name ConfigSftpRetrieve
     * @request GET:/api/config/sftp
     * @secure
     * @response `200` `SftpConfig`
     */
    configSftpRetrieve: (params: RequestParams = {}) =>
      this.request<SftpConfig, any>({
        path: `/api/config/sftp`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom_faases
     * @name CustomFaasesCreate
     * @request POST:/api/custom_faases
     * @secure
     * @response `200` `CustomFaas`
     * @response `201` `CustomFaas`
     */
    customFaasesCreate: (data: CustomFaasRequest, params: RequestParams = {}) =>
      this.request<CustomFaas, any>({
        path: `/api/custom_faases`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom_faases
     * @name CustomFaasesRetrieve
     * @request GET:/api/custom_faases/{id}
     * @secure
     * @response `200` `CustomFaas`
     */
    customFaasesRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<CustomFaas, any>({
        path: `/api/custom_faases/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom_faases
     * @name CustomFaasesUpdate
     * @request PUT:/api/custom_faases/{id}
     * @secure
     * @response `200` `CustomFaas`
     */
    customFaasesUpdate: (id: string, data: CustomFaasRequest, params: RequestParams = {}) =>
      this.request<CustomFaas, any>({
        path: `/api/custom_faases/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom_faases
     * @name CustomFaasesPartialUpdate
     * @request PATCH:/api/custom_faases/{id}
     * @secure
     * @response `200` `CustomFaas`
     */
    customFaasesPartialUpdate: (id: string, data: PatchedCustomFaasRequest, params: RequestParams = {}) =>
      this.request<CustomFaas, any>({
        path: `/api/custom_faases/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom_faases
     * @name CustomFaasesDestroy
     * @request DELETE:/api/custom_faases/{id}
     * @secure
     * @response `204` `void` No response body
     */
    customFaasesDestroy: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/custom_faases/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard-column
     * @name DashboardColumnCreate
     * @request POST:/api/dashboard-column
     * @secure
     * @response `201` `DashboardColumn`
     */
    dashboardColumnCreate: (data: DashboardColumnRequest, params: RequestParams = {}) =>
      this.request<DashboardColumn, any>({
        path: `/api/dashboard-column`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard-column
     * @name DashboardColumnRetrieve
     * @request GET:/api/dashboard-column/{dashboard_column_id}
     * @secure
     * @response `200` `DashboardColumn`
     */
    dashboardColumnRetrieve: (dashboardColumnId: string, params: RequestParams = {}) =>
      this.request<DashboardColumn, any>({
        path: `/api/dashboard-column/${dashboardColumnId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard-column
     * @name DashboardColumnUpdate
     * @request PUT:/api/dashboard-column/{dashboard_column_id}
     * @secure
     * @response `200` `DashboardColumn`
     */
    dashboardColumnUpdate: (dashboardColumnId: string, data: DashboardColumnRequest, params: RequestParams = {}) =>
      this.request<DashboardColumn, any>({
        path: `/api/dashboard-column/${dashboardColumnId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard-column
     * @name DashboardColumnPartialUpdate
     * @request PATCH:/api/dashboard-column/{dashboard_column_id}
     * @secure
     * @response `200` `DashboardColumn`
     */
    dashboardColumnPartialUpdate: (
      dashboardColumnId: string,
      data: PatchedDashboardColumnRequest,
      params: RequestParams = {},
    ) =>
      this.request<DashboardColumn, any>({
        path: `/api/dashboard-column/${dashboardColumnId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard-column
     * @name DashboardColumnDestroy
     * @request DELETE:/api/dashboard-column/{dashboard_column_id}
     * @secure
     * @response `204` `void` No response body
     */
    dashboardColumnDestroy: (dashboardColumnId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/dashboard-column/${dashboardColumnId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsList
     * @request GET:/api/invitations
     * @secure
     * @response `200` `(Invitation)[]`
     */
    invitationsList: (params: RequestParams = {}) =>
      this.request<Invitation[], any>({
        path: `/api/invitations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invitations
     * @name InvitationsCreate
     * @request POST:/api/invitations
     * @secure
     * @response `201` `Invitation`
     */
    invitationsCreate: (data: InvitationRequest, params: RequestParams = {}) =>
      this.request<Invitation, any>({
        path: `/api/invitations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags labels
     * @name LabelsCreate
     * @request POST:/api/labels
     * @secure
     * @response `200` `Label`
     * @response `201` `Label`
     */
    labelsCreate: (data: LabelRequest, params: RequestParams = {}) =>
      this.request<Label, any>({
        path: `/api/labels`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags labels
     * @name LabelsRetrieve
     * @request GET:/api/labels/{label_id}
     * @secure
     * @response `200` `Label`
     */
    labelsRetrieve: (labelId: string, params: RequestParams = {}) =>
      this.request<Label, any>({
        path: `/api/labels/${labelId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags labels
     * @name LabelsUpdate
     * @request PUT:/api/labels/{label_id}
     * @secure
     * @response `200` `Label`
     */
    labelsUpdate: (labelId: string, data: LabelRequest, params: RequestParams = {}) =>
      this.request<Label, any>({
        path: `/api/labels/${labelId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags labels
     * @name LabelsPartialUpdate
     * @request PATCH:/api/labels/{label_id}
     * @secure
     * @response `200` `Label`
     */
    labelsPartialUpdate: (labelId: string, data: PatchedLabelRequest, params: RequestParams = {}) =>
      this.request<Label, any>({
        path: `/api/labels/${labelId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags labels
     * @name LabelsDestroy
     * @request DELETE:/api/labels/{label_id}
     * @secure
     * @response `204` `void` No response body
     */
    labelsDestroy: (labelId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/labels/${labelId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags permissions
     * @name PermissionsRetrieve
     * @request GET:/api/permissions
     * @secure
     * @response `200` `UserAdminPermissions`
     */
    permissionsRetrieve: (params: RequestParams = {}) =>
      this.request<UserAdminPermissions, any>({
        path: `/api/permissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags phone-numbers
     * @name PhoneNumbersList
     * @request GET:/api/phone-numbers
     * @secure
     * @response `200` `(TenantPhoneNumber)[]`
     */
    phoneNumbersList: (params: RequestParams = {}) =>
      this.request<TenantPhoneNumber[], any>({
        path: `/api/phone-numbers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags phone-numbers
     * @name PhoneNumbersPartialUpdate
     * @request PATCH:/api/phone-numbers/{id}
     * @secure
     * @response `200` `TenantPhoneNumber`
     */
    phoneNumbersPartialUpdate: (id: string, data: PatchedTenantPhoneNumberRequest, params: RequestParams = {}) =>
      this.request<TenantPhoneNumber, any>({
        path: `/api/phone-numbers/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags phone-numbers
     * @name PhoneNumbersHistoryRetrieve
     * @request GET:/api/phone-numbers/{id}/history
     * @secure
     * @response `200` `PhoneNumberHistory`
     */
    phoneNumbersHistoryRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<PhoneNumberHistory, any>({
        path: `/api/phone-numbers/${id}/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags phone-numbers
     * @name PhoneNumbersSwapCreate
     * @request POST:/api/phone-numbers/swap
     * @secure
     * @response `200` `PhoneNumberSwap`
     */
    phoneNumbersSwapCreate: (data: PhoneNumberSwapRequest, params: RequestParams = {}) =>
      this.request<PhoneNumberSwap, any>({
        path: `/api/phone-numbers/swap`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesCreate
     * @request POST:/api/processes
     * @secure
     * @response `201` `Process`
     */
    processesCreate: (data: ProcessCreateRequest, params: RequestParams = {}) =>
      this.request<Process, any>({
        path: `/api/processes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesRetrieve
     * @request GET:/api/processes/{process_id}
     * @secure
     * @response `200` `Process`
     */
    processesRetrieve: (processId: string, params: RequestParams = {}) =>
      this.request<Process, any>({
        path: `/api/processes/${processId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesUpdate
     * @request PUT:/api/processes/{process_id}
     * @secure
     * @response `200` `Process`
     */
    processesUpdate: (processId: string, data: ProcessCreateRequest, params: RequestParams = {}) =>
      this.request<Process, any>({
        path: `/api/processes/${processId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesPartialUpdate
     * @request PATCH:/api/processes/{process_id}
     * @secure
     * @response `200` `Process`
     */
    processesPartialUpdate: (processId: string, data: PatchedProcessCreateRequest, params: RequestParams = {}) =>
      this.request<Process, any>({
        path: `/api/processes/${processId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesDestroy
     * @request DELETE:/api/processes/{process_id}
     * @secure
     * @response `204` `void` No response body
     */
    processesDestroy: (processId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/processes/${processId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesCallStatusDistributionCreate
     * @request POST:/api/processes/{process_id}/call-status-distribution
     * @secure
     * @response `200` `CallStatusDistributionData`
     */
    processesCallStatusDistributionCreate: (
      processId: string,
      data: CallStatusDistributionRequest,
      params: RequestParams = {},
    ) =>
      this.request<CallStatusDistributionData, any>({
        path: `/api/processes/${processId}/call-status-distribution`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesMetricsOverviewCreate
     * @request POST:/api/processes/{process_id}/metrics-overview
     * @secure
     * @response `200` `AnalyticsOverviewResponse`
     */
    processesMetricsOverviewCreate: (processId: string, data: AnalyticsRequest, params: RequestParams = {}) =>
      this.request<AnalyticsOverviewResponse, any>({
        path: `/api/processes/${processId}/metrics-overview`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesProcessStatusChartCreate
     * @request POST:/api/processes/{process_id}/process-status-chart
     * @secure
     * @response `200` `ProcessStatusChartResponse`
     */
    processesProcessStatusChartCreate: (processId: string, data: AnalyticsRequest, params: RequestParams = {}) =>
      this.request<ProcessStatusChartResponse, any>({
        path: `/api/processes/${processId}/process-status-chart`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesReportPerCallCreate
     * @request POST:/api/processes/{process_id}/report-per-call
     * @secure
     * @response `200` `File`
     */
    processesReportPerCallCreate: (processId: string, data: ReportPerCallRequest, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/processes/${processId}/report-per-call`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesReportPerCampaignCreate
     * @request POST:/api/processes/{process_id}/report-per-campaign
     * @secure
     * @response `200` `File`
     */
    processesReportPerCampaignCreate: (processId: string, data: ReportPerCampaignRequest, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/processes/${processId}/report-per-campaign`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags processes
     * @name ProcessesReportPerRecordCreate
     * @request POST:/api/processes/{process_id}/report-per-record
     * @secure
     * @response `200` `File`
     */
    processesReportPerRecordCreate: (processId: string, data: ReportPerRecordRequest, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/processes/${processId}/report-per-record`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session
     * @name SessionRetrieve
     * @request GET:/api/session
     * @secure
     * @response `200` `UserPublic`
     */
    sessionRetrieve: (params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/session`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session
     * @name SessionCreate
     * @request POST:/api/session
     * @secure
     * @response `201` `UserPublic`
     */
    sessionCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<UserPublic, any>({
        path: `/api/session`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session
     * @name SessionDestroy
     * @request DELETE:/api/session
     * @secure
     * @response `204` `void` No response body
     */
    sessionDestroy: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/session`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsList
     * @request GET:/api/sftp-configurations
     * @secure
     * @response `200` `(SftpConfiguration)[]`
     */
    sftpConfigurationsList: (params: RequestParams = {}) =>
      this.request<SftpConfiguration[], any>({
        path: `/api/sftp-configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsCreate
     * @request POST:/api/sftp-configurations
     * @secure
     * @response `201` `SftpConfiguration`
     */
    sftpConfigurationsCreate: (data: SftpConfigurationRequest, params: RequestParams = {}) =>
      this.request<SftpConfiguration, any>({
        path: `/api/sftp-configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsRetrieve
     * @request GET:/api/sftp-configurations/{id}
     * @secure
     * @response `200` `SftpConfiguration`
     */
    sftpConfigurationsRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<SftpConfiguration, any>({
        path: `/api/sftp-configurations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsUpdate
     * @request PUT:/api/sftp-configurations/{id}
     * @secure
     * @response `200` `SftpConfiguration`
     */
    sftpConfigurationsUpdate: (id: string, data: SftpConfigurationRequest, params: RequestParams = {}) =>
      this.request<SftpConfiguration, any>({
        path: `/api/sftp-configurations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsPartialUpdate
     * @request PATCH:/api/sftp-configurations/{id}
     * @secure
     * @response `200` `SftpConfiguration`
     */
    sftpConfigurationsPartialUpdate: (id: string, data: PatchedSftpConfigurationRequest, params: RequestParams = {}) =>
      this.request<SftpConfiguration, any>({
        path: `/api/sftp-configurations/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsDestroy
     * @request DELETE:/api/sftp-configurations/{id}
     * @secure
     * @response `204` `void` No response body
     */
    sftpConfigurationsDestroy: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sftp-configurations/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsTestRetrieve
     * @request GET:/api/sftp-configurations/{id}/test
     * @secure
     * @response `200` `TestSftpConfiguration`
     */
    sftpConfigurationsTestRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<TestSftpConfiguration, any>({
        path: `/api/sftp-configurations/${id}/test`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-configurations
     * @name SftpConfigurationsTestCreate
     * @request POST:/api/sftp-configurations/test
     * @secure
     * @response `200` `TestSftpConfiguration`
     */
    sftpConfigurationsTestCreate: (data: SftpConfigurationRequest, params: RequestParams = {}) =>
      this.request<TestSftpConfiguration, any>({
        path: `/api/sftp-configurations/test`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-credentials
     * @name SftpCredentialsRetrieve
     * @request GET:/api/sftp-credentials
     * @secure
     * @response `204` `void` No response body
     * @response `404` `void` No response body
     */
    sftpCredentialsRetrieve: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/sftp-credentials`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-credentials
     * @name SftpCredentialsCreate
     * @request POST:/api/sftp-credentials
     * @secure
     * @response `204` `void` No response body
     */
    sftpCredentialsCreate: (data: SftpCredentialsCreateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sftp-credentials`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-public-keys
     * @name SftpPublicKeysList
     * @request GET:/api/sftp-public-keys
     * @secure
     * @response `200` `(SftpPublicKey)[]`
     */
    sftpPublicKeysList: (params: RequestParams = {}) =>
      this.request<SftpPublicKey[], any>({
        path: `/api/sftp-public-keys`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-public-keys
     * @name SftpPublicKeysCreate
     * @request POST:/api/sftp-public-keys
     * @secure
     * @response `201` `void` No response body
     */
    sftpPublicKeysCreate: (data: SftpPublicKeyRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sftp-public-keys`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-public-keys
     * @name SftpPublicKeysRetrieve
     * @request GET:/api/sftp-public-keys/{id}
     * @secure
     * @response `200` `SftpPublicKey`
     */
    sftpPublicKeysRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<SftpPublicKey, any>({
        path: `/api/sftp-public-keys/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-public-keys
     * @name SftpPublicKeysUpdate
     * @request PUT:/api/sftp-public-keys/{id}
     * @secure
     * @response `200` `SftpPublicKey`
     */
    sftpPublicKeysUpdate: (id: string, data: SftpPublicKeyRequest, params: RequestParams = {}) =>
      this.request<SftpPublicKey, any>({
        path: `/api/sftp-public-keys/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-public-keys
     * @name SftpPublicKeysPartialUpdate
     * @request PATCH:/api/sftp-public-keys/{id}
     * @secure
     * @response `200` `SftpPublicKey`
     */
    sftpPublicKeysPartialUpdate: (id: string, data: PatchedSftpPublicKeyRequest, params: RequestParams = {}) =>
      this.request<SftpPublicKey, any>({
        path: `/api/sftp-public-keys/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sftp-public-keys
     * @name SftpPublicKeysDestroy
     * @request DELETE:/api/sftp-public-keys/{id}
     * @secure
     * @response `204` `void` No response body
     */
    sftpPublicKeysDestroy: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sftp-public-keys/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesList
     * @request GET:/api/strategies
     * @secure
     * @response `200` `(Strategy)[]`
     */
    strategiesList: (params: RequestParams = {}) =>
      this.request<Strategy[], any>({
        path: `/api/strategies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCreate
     * @request POST:/api/strategies
     * @secure
     * @response `201` `Strategy`
     */
    strategiesCreate: (data: StrategyCreateRequest, params: RequestParams = {}) =>
      this.request<Strategy, any>({
        path: `/api/strategies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesRetrieve
     * @request GET:/api/strategies/{strategy_id}
     * @secure
     * @response `200` `Strategy`
     */
    strategiesRetrieve: (strategyId: string, params: RequestParams = {}) =>
      this.request<Strategy, any>({
        path: `/api/strategies/${strategyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesUpdate
     * @request PUT:/api/strategies/{strategy_id}
     * @secure
     * @response `200` `Strategy`
     */
    strategiesUpdate: (strategyId: string, data: StrategyRequest, params: RequestParams = {}) =>
      this.request<Strategy, any>({
        path: `/api/strategies/${strategyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesPartialUpdate
     * @request PATCH:/api/strategies/{strategy_id}
     * @secure
     * @response `200` `Strategy`
     */
    strategiesPartialUpdate: (strategyId: string, data: PatchedStrategyRequest, params: RequestParams = {}) =>
      this.request<Strategy, any>({
        path: `/api/strategies/${strategyId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesDestroy
     * @request DELETE:/api/strategies/{strategy_id}
     * @secure
     * @response `204` `void` No response body
     */
    strategiesDestroy: (strategyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/strategies/${strategyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesArchiveUpdate
     * @request PUT:/api/strategies/{strategy_id}/archive
     * @secure
     * @response `204` `void` No response body
     * @response `404` `void` No response body
     */
    strategiesArchiveUpdate: (strategyId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/strategies/${strategyId}/archive`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesBotConfigurationsList
     * @request GET:/api/strategies/{strategy_id}/bot-configurations
     * @secure
     * @response `200` `(BotConfiguration)[]`
     */
    strategiesBotConfigurationsList: (strategyId: string, params: RequestParams = {}) =>
      this.request<BotConfiguration[], any>({
        path: `/api/strategies/${strategyId}/bot-configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCalendarConfigurationList
     * @request GET:/api/strategies/{strategy_id}/calendar_configuration
     * @secure
     * @response `200` `(CalendarConfiguration)[]`
     */
    strategiesCalendarConfigurationList: (strategyId: string, params: RequestParams = {}) =>
      this.request<CalendarConfiguration[], any>({
        path: `/api/strategies/${strategyId}/calendar_configuration`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCallCenterNonWorkingDaysConfigurationList
     * @request GET:/api/strategies/{strategy_id}/call-center-non-working-days-configuration
     * @secure
     * @response `200` `(CallCenterNonWorkingDaysConfiguration)[]`
     */
    strategiesCallCenterNonWorkingDaysConfigurationList: (strategyId: string, params: RequestParams = {}) =>
      this.request<CallCenterNonWorkingDaysConfiguration[], any>({
        path: `/api/strategies/${strategyId}/call-center-non-working-days-configuration`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCallingConfigurationsList
     * @request GET:/api/strategies/{strategy_id}/calling-configurations
     * @secure
     * @response `200` `(CallingConfiguration)[]`
     */
    strategiesCallingConfigurationsList: (strategyId: string, params: RequestParams = {}) =>
      this.request<CallingConfiguration[], any>({
        path: `/api/strategies/${strategyId}/calling-configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCampaignNonWorkingDaysConfigurationList
     * @request GET:/api/strategies/{strategy_id}/campaign-non-working-days-configuration
     * @secure
     * @response `200` `(CampaignNonWorkingDaysConfiguration)[]`
     */
    strategiesCampaignNonWorkingDaysConfigurationList: (strategyId: string, params: RequestParams = {}) =>
      this.request<CampaignNonWorkingDaysConfiguration[], any>({
        path: `/api/strategies/${strategyId}/campaign-non-working-days-configuration`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCampaignsList
     * @request GET:/api/strategies/{strategy_id}/campaigns
     * @secure
     * @response `200` `PaginatedCampaignOverviewList`
     */
    strategiesCampaignsList: (
      strategyId: string,
      query?: {
        /**
         * Filter by campaign end date less than or equal to provided date.
         * @format date
         */
        end_date_lte?: string;
        /** Filter campaigns by labels. */
        label_text?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** Specify the field to order by. Prepend minus sign for reverse order. */
        ordering?: '-end_date' | '-start_date' | 'end_date' | 'start_date';
        /** Filter campaigns by process ids. */
        process_ids?: string[];
        /**
         * Filter by campaign start date greater than or eqaual to provided date.
         * @format date
         */
        start_date_gte?: string;
        /** Filter campaigns by statuses. */
        statuses?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedCampaignOverviewList, any>({
        path: `/api/strategies/${strategyId}/campaigns`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesCustomFaasesList
     * @request GET:/api/strategies/{strategy_id}/custom_faases
     * @secure
     * @response `200` `(CustomFaas)[]`
     */
    strategiesCustomFaasesList: (strategyId: string, params: RequestParams = {}) =>
      this.request<CustomFaas[], any>({
        path: `/api/strategies/${strategyId}/custom_faases`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesDashboardRetrieve
     * @request GET:/api/strategies/{strategy_id}/dashboard
     * @secure
     * @response `200` `Dashboard`
     */
    strategiesDashboardRetrieve: (strategyId: string, params: RequestParams = {}) =>
      this.request<Dashboard, any>({
        path: `/api/strategies/${strategyId}/dashboard`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesDashboardPartialUpdate
     * @request PATCH:/api/strategies/{strategy_id}/dashboard
     * @secure
     * @response `204` `void` No response body
     */
    strategiesDashboardPartialUpdate: (
      strategyId: string,
      data: PatchedDashboardMoveRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/strategies/${strategyId}/dashboard`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesDashboardColumnList
     * @request GET:/api/strategies/{strategy_id}/dashboard-column
     * @secure
     * @response `200` `(DashboardColumn)[]`
     */
    strategiesDashboardColumnList: (strategyId: string, params: RequestParams = {}) =>
      this.request<DashboardColumn[], any>({
        path: `/api/strategies/${strategyId}/dashboard-column`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This view is responsible for fetching and returning the dashboard data for a specific strategy. The main functionality of this view is implemented in the `get` method, which handles GET requests. The method fetches the strategy based on the `strategy_id` provided in the URL parameters and retrieves all campaigns associated with this strategy. For each campaign, it calculates the call percentages and hourly distribution data. The call percentages are calculated using the `calculate_calls_data` function, which takes a list of campaigns, start date, and end date as parameters. It also calculates the number of active calls for each minute in the last three hours and stores this data in a time series. The campaigns are then sorted based on their status, with non-paused campaigns coming first. Finally, the method constructs a dictionary containing the id, status, call percentages, call center calls count, time series, and database completion percent for each campaign. This dictionary is returned as a JSON response. Parameters: - request (HttpRequest): The HTTP request object. - *args: Additional positional arguments. - **kwargs: Additional keyword arguments, including `strategy_id`. Returns: - JsonResponse: A JSON response containing the dashboard data for the specified strategy.
     *
     * @tags strategies
     * @name StrategiesDashboardDataRetrieve
     * @request GET:/api/strategies/{strategy_id}/dashboard-data
     * @secure
     * @response `200` `StrategyDashboardData`
     */
    strategiesDashboardDataRetrieve: (strategyId: string, params: RequestParams = {}) =>
      this.request<StrategyDashboardData, any>({
        path: `/api/strategies/${strategyId}/dashboard-data`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesGpgRotateCreate
     * @request POST:/api/strategies/{strategy_id}/gpg-rotate
     * @secure
     * @response `204` `void` No response body
     * @response `404` `void` No response body
     */
    strategiesGpgRotateCreate: (strategyId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/strategies/${strategyId}/gpg-rotate`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesLabelsList
     * @request GET:/api/strategies/{strategy_id}/labels
     * @secure
     * @response `200` `(Label)[]`
     */
    strategiesLabelsList: (strategyId: string, params: RequestParams = {}) =>
      this.request<Label[], any>({
        path: `/api/strategies/${strategyId}/labels`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesPermissionsRetrieve
     * @request GET:/api/strategies/{strategy_id}/permissions
     * @secure
     * @response `200` `UserStrategyPermissions`
     */
    strategiesPermissionsRetrieve: (strategyId: string, params: RequestParams = {}) =>
      this.request<UserStrategyPermissions, any>({
        path: `/api/strategies/${strategyId}/permissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesPhoneNumbersList
     * @request GET:/api/strategies/{strategy_id}/phone-numbers
     * @secure
     * @response `200` `(TenantPhoneNumber)[]`
     */
    strategiesPhoneNumbersList: (strategyId: string, params: RequestParams = {}) =>
      this.request<TenantPhoneNumber[], any>({
        path: `/api/strategies/${strategyId}/phone-numbers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesProcessList
     * @request GET:/api/strategies/{strategy_id}/process
     * @secure
     * @response `200` `(Process)[]`
     */
    strategiesProcessList: (
      strategyId: string,
      query?: {
        /** When set to true, archived and non-archived processes will be returned. */
        is_archived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<Process[], any>({
        path: `/api/strategies/${strategyId}/process`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesUnarchiveUpdate
     * @request PUT:/api/strategies/{strategy_id}/unarchive
     * @secure
     * @response `204` `void` No response body
     * @response `404` `void` No response body
     */
    strategiesUnarchiveUpdate: (strategyId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/strategies/${strategyId}/unarchive`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesVoiceConfigurationsList
     * @request GET:/api/strategies/{strategy_id}/voice_configurations
     * @secure
     * @response `200` `(VoiceConfiguration)[]`
     */
    strategiesVoiceConfigurationsList: (strategyId: string, params: RequestParams = {}) =>
      this.request<VoiceConfiguration[], any>({
        path: `/api/strategies/${strategyId}/voice_configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesArchivedList
     * @request GET:/api/strategies/archived
     * @secure
     * @response `200` `(Strategy)[]`
     */
    strategiesArchivedList: (params: RequestParams = {}) =>
      this.request<Strategy[], any>({
        path: `/api/strategies/archived`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesArchivedRetrieve
     * @request GET:/api/strategies/archived/{strategy_id}
     * @secure
     * @response `200` `Strategy`
     */
    strategiesArchivedRetrieve: (strategyId: string, params: RequestParams = {}) =>
      this.request<Strategy, any>({
        path: `/api/strategies/archived/${strategyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name StrategiesArchivedDestroy
     * @request DELETE:/api/strategies/archived/{strategy_id}
     * @secure
     * @response `204` `void` No response body
     */
    strategiesArchivedDestroy: (strategyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/strategies/archived/${strategyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-settings
     * @name UserSettingsRetrieve
     * @request GET:/api/user-settings
     * @secure
     * @response `200` `UserSettings`
     */
    userSettingsRetrieve: (params: RequestParams = {}) =>
      this.request<UserSettings, any>({
        path: `/api/user-settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-settings
     * @name UserSettingsUpdate
     * @request PUT:/api/user-settings
     * @secure
     * @response `200` `UserSettings`
     */
    userSettingsUpdate: (data: UserSettingsRequest, params: RequestParams = {}) =>
      this.request<UserSettings, any>({
        path: `/api/user-settings`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-settings
     * @name UserSettingsPartialUpdate
     * @request PATCH:/api/user-settings
     * @secure
     * @response `200` `UserSettings`
     */
    userSettingsPartialUpdate: (data: PatchedUserSettingsRequest, params: RequestParams = {}) =>
      this.request<UserSettings, any>({
        path: `/api/user-settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersList
     * @request GET:/api/users
     * @secure
     * @response `200` `PaginatedUserTenantList`
     */
    usersList: (
      query?: {
        filter_info?: string;
        /** Number of results to return per page. */
        limit?: number;
        /** The initial index from which to return the results. */
        offset?: number;
        /** @format uuid */
        strategy_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedUserTenantList, any>({
        path: `/api/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersBatchDestroy
     * @request DELETE:/api/users
     * @secure
     * @response `204` `void` No response body
     */
    usersBatchDestroy: (
      query: {
        id: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/users`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersRetrieve
     * @request GET:/api/users/{id}
     * @secure
     * @response `200` `UserTenant`
     */
    usersRetrieve: (id: string, params: RequestParams = {}) =>
      this.request<UserTenant, any>({
        path: `/api/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersActivateUpdate
     * @request PUT:/api/users/{id}/activate
     * @secure
     * @response `200` `void` No response body
     */
    usersActivateUpdate: (id: string, data: UserActivationRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/${id}/activate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersPermissionsPartialUpdate
     * @request PATCH:/api/users/{user_id}/permissions
     * @secure
     * @response `200` `UserPermissionEdit`
     */
    usersPermissionsPartialUpdate: (
      userId: string,
      data: PatchedUserPermissionEditRequest,
      params: RequestParams = {},
    ) =>
      this.request<UserPermissionEdit, any>({
        path: `/api/users/${userId}/permissions`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersPermissionsRetrieve
     * @request GET:/api/users/permissions
     * @secure
     * @response `200` `UserAggregatedPermissionsList`
     */
    usersPermissionsRetrieve: (
      query: {
        strategy_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserAggregatedPermissionsList, any>({
        path: `/api/users/permissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voice_configurations
     * @name VoiceConfigurationsCreate
     * @request POST:/api/voice_configurations
     * @secure
     * @response `201` `VoiceConfiguration`
     */
    voiceConfigurationsCreate: (data: VoiceConfigurationRequest, params: RequestParams = {}) =>
      this.request<VoiceConfiguration, any>({
        path: `/api/voice_configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voice_configurations
     * @name VoiceConfigurationsRetrieve
     * @request GET:/api/voice_configurations/{voice_configuration_id}
     * @secure
     * @response `200` `VoiceConfiguration`
     */
    voiceConfigurationsRetrieve: (voiceConfigurationId: string, params: RequestParams = {}) =>
      this.request<VoiceConfiguration, any>({
        path: `/api/voice_configurations/${voiceConfigurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voice_configurations
     * @name VoiceConfigurationsUpdate
     * @request PUT:/api/voice_configurations/{voice_configuration_id}
     * @secure
     * @response `200` `VoiceConfiguration`
     */
    voiceConfigurationsUpdate: (
      voiceConfigurationId: string,
      data: VoiceConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<VoiceConfiguration, any>({
        path: `/api/voice_configurations/${voiceConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voice_configurations
     * @name VoiceConfigurationsPartialUpdate
     * @request PATCH:/api/voice_configurations/{voice_configuration_id}
     * @secure
     * @response `200` `VoiceConfiguration`
     */
    voiceConfigurationsPartialUpdate: (
      voiceConfigurationId: string,
      data: PatchedVoiceConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<VoiceConfiguration, any>({
        path: `/api/voice_configurations/${voiceConfigurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags voice_configurations
     * @name VoiceConfigurationsDestroy
     * @request DELETE:/api/voice_configurations/{voice_configuration_id}
     * @secure
     * @response `204` `void` No response body
     */
    voiceConfigurationsDestroy: (voiceConfigurationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/voice_configurations/${voiceConfigurationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags voice_configurations
     * @name VoiceConfigurationsVoicesRetrieve
     * @request GET:/api/voice_configurations/voices
     * @secure
     * @response `200` `VoiceList`
     */
    voiceConfigurationsVoicesRetrieve: (
      query: {
        gender: 'FEMALE' | 'MALE';
        language: 'en-AU' | 'en-US' | 'es-ES' | 'es-MX' | 'pl-PL';
        tts_vendor: 'Google' | 'Microsoft' | 'Techmo';
      },
      params: RequestParams = {},
    ) =>
      this.request<VoiceList, any>({
        path: `/api/voice_configurations/voices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  private = {
    /**
     * @description Note that more than one active campaign with active inbound with both using the same campaign number is unexpected behavior but we allow it however the routing behavior may be unexpected.
     *
     * @tags private
     * @name PrivateApiInboundRoutingRetrieve
     * @request GET:/private/api/inbound/routing
     * @secure
     * @response `200` `InboundRcsRoutingDecision`
     */
    privateApiInboundRoutingRetrieve: (
      query: {
        calling_phone_number: string;
        campaign_phone_number: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InboundRcsRoutingDecision, any>({
        path: `/private/api/inbound/routing`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiOutboundRetrieve
     * @request GET:/private/api/outbound/{record_id}
     * @secure
     * @response `200` `OutboundCallConfiguration`
     */
    privateApiOutboundRetrieve: (
      recordId: string,
      query: {
        record_phone_number: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OutboundCallConfiguration, any>({
        path: `/private/api/outbound/${recordId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags private
     * @name PrivateApiSftpCredentialsRetrieve
     * @request GET:/private/api/sftp-credentials
     * @secure
     * @response `200` `SftpCredentials`
     */
    privateApiSftpCredentialsRetrieve: (params: RequestParams = {}) =>
      this.request<SftpCredentials, any>({
        path: `/private/api/sftp-credentials`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { VoiceConfiguration } from '../../../../generated/tenants/Api';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkVoiceConfigurationCreate,
  checkVoiceConfigurationDelete,
  checkVoiceConfigurationUpdate,
  createVoiceConfigurationAsyncThunk,
  deleteVoiceConfigurationAsyncThunk,
  getVoiceConfigurationsListAsyncThunk,
  selectVoiceConfigurationsList,
} from './api/voiceConfigurationSlice';
import CreateVoice from './CreateVoice';
import DetailedVoice from './DetailedVoice';
import VoicesList from './VoicesList';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../processes/api/processSlice';
import PresetDeleteModal from '../PresetDeleteModal';
import { PresetContainer } from '../PresetsPanel.style';
import BackToList from '../shared/BackToList';
import usePermissions from '../../users/usePermissions';
import LoadingModal from '../../../../common/modals/LoadingModal';

const Voices = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);

  const [voiceState, setVoiceState] = useState<'create' | 'list' | 'detailed'>(
    'list',
  );
  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('process_campaign');

  //create
  const handleAddVoiceClick = () => setVoiceState('create');

  //detailed
  const handleDetailedViewClick = (voiceId: string) => {
    const voiceById = apiVoicesList.find((voice) => voice.id === voiceId);
    if (voiceById) setSelectedVoice(voiceById);
    setVoiceState('detailed');
  };
  const handleBackToList = () => {
    setVoiceState('list');
  };

  useEffect(() => {
    dispatch(getVoiceConfigurationsListAsyncThunk(strategy));
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);

  const apiVoicesList = useAppSelector(selectVoiceConfigurationsList);

  const apiProcesesList = useAppSelector(selectProcessesList);

  function findProcessNamesById(id: string): string[] {
    return apiProcesesList
      .filter((item) => item.voice_configuration === id)
      .map((item) => item.name);
  }

  const [selectedVoice, setSelectedVoice] = useState<VoiceConfiguration>();

  const checkVoiceUpdate = useAppSelector(checkVoiceConfigurationUpdate);
  useEffect(() => {
    if (
      checkVoiceUpdate.state === 'idle' &&
      checkVoiceUpdate.value?.status === 'success' &&
      checkVoiceUpdate.value.voice
    ) {
      setSelectedVoice(checkVoiceUpdate.value.voice);
    }
  }, [checkVoiceUpdate]);

  //delete
  const [isDeleteVoiceModalVisible, setIsDeleteVoiceModalVisible] =
    useState<boolean>(false);

  const handleDeleteVoicesPreset = (id: string) => {
    const selectedVoice = apiVoicesList.find((voice) => voice.id === id);
    if (selectedVoice) setSelectedVoice(selectedVoice);
    openDeleteVoiceModal();
  };

  const openDeleteVoiceModal = () => setIsDeleteVoiceModalVisible(true);
  const closeDeleteVoiceModal = () => setIsDeleteVoiceModalVisible(false);

  const deleteVoiceAfterModal = () => {
    if (selectedVoice) {
      dispatch(deleteVoiceConfigurationAsyncThunk(selectedVoice.id));
      closeDeleteVoiceModal();
    } else {
      alert('error');
    }
  };

  const checkDeleteVoice = useAppSelector(checkVoiceConfigurationDelete);

  useEffect(() => {
    if (
      checkDeleteVoice.value?.status === 'success' &&
      checkDeleteVoice.state === 'idle'
    ) {
      dispatch(getVoiceConfigurationsListAsyncThunk(strategy));
      setSelectedVoice(undefined);
    }
  }, [checkDeleteVoice]);

  //clone

  const handleDuplicateVoice = (id: string) => {
    const selectedVoice = apiVoicesList.find((voice) => voice.id === id);
    if (selectedVoice) {
      let count = 1;
      let newName = selectedVoice.name;

      let nameExists = apiVoicesList.some((item) => item.name === newName);

      while (nameExists) {
        newName =
          selectedVoice.name + ' - copy' + (count > 1 ? `(${count})` : '');
        nameExists = apiVoicesList.some((item) => item.name === newName);
        count++;
      }

      const copiedVoice = {
        ...selectedVoice,
        name: newName,
      };
      setIsLoadingVisible(true);
      dispatch(createVoiceConfigurationAsyncThunk(copiedVoice)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const checkCopy = useAppSelector(checkVoiceConfigurationCreate);

  useEffect(() => {
    if (checkCopy.state === 'idle' && checkCopy.value?.status === 'success') {
      dispatch(getVoiceConfigurationsListAsyncThunk(strategy));
      setSelectedVoice(undefined);
    }
  }, [checkCopy]);

  //component logic
  const componentsMap = {
    create: <CreateVoice strategy={strategy} backToList={handleBackToList} />,
    list: (
      <VoicesList
        voicesList={apiVoicesList}
        createVoice={handleAddVoiceClick}
        goToDetailed={handleDetailedViewClick}
        findProcessNamesById={findProcessNamesById}
        handleDeleteVoicesPreset={handleDeleteVoicesPreset}
        handleDuplicateVoice={handleDuplicateVoice}
        hasPermission={hasPermission}
      />
    ),
    detailed: selectedVoice && (
      <DetailedVoice
        voice={selectedVoice}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesById}
        hasPermission={hasPermission}
      />
    ),
  };
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <PresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {selectedVoice && hasPermission && (
        <PresetDeleteModal
          type={'voice'}
          isVisible={isDeleteVoiceModalVisible}
          presetName={selectedVoice.name}
          closeModal={closeDeleteVoiceModal}
          deletePreset={deleteVoiceAfterModal}
        />
      )}
      {voiceState !== 'list' && (
        <BackToList handleBackToList={handleBackToList} />
      )}

      {componentsMap[voiceState]}
    </PresetContainer>
  );
};

export default Voices;

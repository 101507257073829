import React, { useEffect, useState } from 'react';
import { PhoneNumbersContainer } from '../../../adminPanel/components/phoneNumbers/PhoneNumbers.style';
import {
  ButtonEmpty,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY_1,
  H1,
  H2,
  H3,
} from '../../../../../App.style';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  clearNumberHistory,
  getAllNumbersListAsyncThunk,
  getSuperAdminPhoneNumberHistoryAsyncThunk,
  getSuperAdminPhoneNumberHistoryList,
  selectAllTenantNumbers,
} from '../../../adminPanel/components/phoneNumbers/api/phoneNumbersSlice';
import { formatPhoneNumber } from '../../../adminPanel/components/phoneNumbers/numbersFunctions';
import { EuiAccordion, EuiSelectable, EuiSelectableOption } from '@elastic/eui';
import moment from 'moment';
import { Label } from '../../../../presets/labels/Labels.style';
import { ArrowRightIcon } from '../../../../../../resources/icons-new/ArrowRightIcon';
import {
  NumberHistoryCampaignRow,
  NumbersHistoryCampaignRowLeftContainer,
  NumbersHistoryCampaignRowRightContainer,
  NumbersHistoryContainer,
  NumbersHistoryTenantContainer,
  NumbersSearchList,
  NumbersTopRowContainer,
} from './SuperAdminNumbers.style';
import { useTranslation } from 'react-i18next';

const NumbersAllHistory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllNumbersListAsyncThunk());
  }, []);

  const apiAllNumbers = useAppSelector(selectAllTenantNumbers);

  const [selectedPhoneNumberId, setSelectedPhoneNumberId] =
    useState<string>('');

  useEffect(() => {
    if (selectedPhoneNumberId) {
      dispatch(
        getSuperAdminPhoneNumberHistoryAsyncThunk(selectedPhoneNumberId),
      );
    }
  }, [selectedPhoneNumberId]);

  const apiSuperAdminNumberHistory = useAppSelector(
    getSuperAdminPhoneNumberHistoryList,
  );

  const generateLabel = (label: string) => {
    if (label === 'debtor') {
      return <Label color={'#F1E4C4'}>debtor</Label>;
    } else if (label === 'vendor') {
      return <Label color={'#D5E6DD'}>vendor</Label>;
    } else if (label === 'reminder') {
      return <Label color={'orange'}>reminder</Label>;
    } else {
      return (
        <Label color={COLOR_PRIMARY_1} fontWhite>
          {label}
        </Label>
      );
    }
  };

  const handleGoToCampaign = (subdomain: string, campaign_id: string) => {
    window.open(
      `https://${subdomain}.fennec.lektalease-platform.lab.lekta.ai/dashboard/campaigns/${campaign_id}`,
      '_blank',
    );
  };

  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const onFocusHandler = () => {
    setIsListVisible(true);
  };

  const onBlurHandler = () => {
    setTimeout(() => {
      setIsListVisible(false);
    }, 200);
  };

  const selectableOptions: EuiSelectableOption[] = apiAllNumbers.map(
    (phone) => ({
      label: formatPhoneNumber(phone.phone_number),
      key: phone.id,
    }),
  );

  useEffect(() => {
    if (apiAllNumbers.length > 0) {
      setSelectOptions(selectableOptions);
    }
  }, [apiAllNumbers]);

  const [selectOptions, setSelectOptions] = useState<EuiSelectableOption[]>([]);

  const findNumberById = (id: string) => {
    const selectedNumber = apiAllNumbers.find((number) => number.id === id);
    return selectedNumber ? formatPhoneNumber(selectedNumber.phone_number) : '';
  };

  const onChange = (options: EuiSelectableOption[]) => {
    setSelectOptions(options);

    const selectedOption = options.find((option) => option.checked === 'on');

    if (selectedOption?.key) {
      setSelectedPhoneNumberId(selectedOption.key);
    } else {
      dispatch(clearNumberHistory());
      setSelectedPhoneNumberId('');
    }
  };

  return (
    <PhoneNumbersContainer>
      <H1 $bold>{t('superAdminPanel.numbers.history.title')}</H1>
      <NumbersTopRowContainer>
        <H2 $selected>{t('superAdminPanel.numbers.history.select')}</H2>

        <div
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          style={{ position: 'relative' }}
        >
          <EuiSelectable
            options={selectOptions}
            onChange={onChange}
            singleSelection={true}
            searchable={true}
            searchProps={{ placeholder: t('common.search') }}
          >
            {(list, search) => (
              <React.Fragment>
                {search}
                {isListVisible && <NumbersSearchList>{list}</NumbersSearchList>}
              </React.Fragment>
            )}
          </EuiSelectable>
        </div>
      </NumbersTopRowContainer>

      <NumbersHistoryContainer>
        {selectedPhoneNumberId && (
          <H2 $grey>
            {t('superAdminPanel.numbers.history.historyFor')}{' '}
            {findNumberById(selectedPhoneNumberId)}
          </H2>
        )}
        {apiSuperAdminNumberHistory &&
          apiSuperAdminNumberHistory.tenants.map((tenant) => {
            return (
              <NumbersHistoryTenantContainer key={`tenant-${tenant.name}`}>
                <H2 $medium style={{ marginLeft: 3 }}>
                  {tenant.name}{' '}
                </H2>
                <div style={{ marginTop: 16 }}>
                  {tenant.processes.length === 0 ? (
                    <H3>{t('superAdminPanel.numbers.history.empty')}</H3>
                  ) : (
                    tenant.processes.map((process) => {
                      return (
                        <EuiAccordion
                          key={`process-${process.id}`}
                          id={`tenant-${tenant.name}-process-${process.name}`}
                          initialIsOpen
                          buttonContent={
                            <H3>
                              {process.name}
                              <span
                                style={{
                                  color: COLOR_NEUTRAL_80,
                                  marginLeft: 4,
                                }}
                              >
                                / {process.strategy_name}
                              </span>
                            </H3>
                          }
                          paddingSize="s"
                          style={{ marginBottom: 10 }}
                        >
                          {process.campaigns.map((campaign) => {
                            const formattedStartDate = moment(
                              campaign.created_at,
                            ).format('DD.MM.YYYY');

                            const formattedEndDate = moment(
                              campaign.ended_at,
                            ).format('DD.MM.YYYY');
                            return (
                              <NumberHistoryCampaignRow
                                key={`campaign - ${campaign.id}`}
                              >
                                <NumbersHistoryCampaignRowLeftContainer>
                                  <H3>
                                    {formattedStartDate} - {formattedEndDate}
                                  </H3>
                                  {generateLabel(campaign.bot_label)}
                                </NumbersHistoryCampaignRowLeftContainer>
                                <NumbersHistoryCampaignRowRightContainer>
                                  {!campaign.deleted && (
                                    <ButtonEmpty
                                      onClick={() =>
                                        handleGoToCampaign(
                                          tenant.slug,
                                          campaign.id,
                                        )
                                      }
                                    >
                                      <ArrowRightIcon
                                        style={{ width: 16, height: 16 }}
                                      />
                                      {t(
                                        'superAdminPanel.numbers.history.goToCampaign',
                                      )}
                                    </ButtonEmpty>
                                  )}
                                </NumbersHistoryCampaignRowRightContainer>
                              </NumberHistoryCampaignRow>
                            );
                          })}
                        </EuiAccordion>
                      );
                    })
                  )}
                </div>
              </NumbersHistoryTenantContainer>
            );
          })}
      </NumbersHistoryContainer>
    </PhoneNumbersContainer>
  );
};

export default NumbersAllHistory;

import styled from 'styled-components';
import { COLOR_NEUTRAL_20 } from '../../../../App.style';
//main

export const ProcessBarContainer = styled.div`
  width: 100%;
  min-width: 500px;

  z-index: 10;

  display: flex;
  flex-direction: row;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

//left container with search bar

export const LeftContainer = styled.div`
  height: 100%;
  padding-top: 10px;
  padding-left: 24px;

  gap: 16px;

  display: flex;
  align-items: center;
  position: relative;
`;

export const RightContainer = styled.div`
  display: flex;
  padding-top: 24px;
  padding-right: 24px;
  align-items: center;
  justify-content: flex-end;
`;

export const SearchContainer = styled.div`
  margin-right: 16px;
`;

export const CustomizeDashboardContainer = styled.div`
  padding: 10px 5px;

  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

//edit
export const EditBarContainer = styled.div`
  width: 100%;

  z-index: 2;

  background: ${COLOR_NEUTRAL_20};

  display: flex;

  align-items: center;
  justify-content: space-between;

  padding: 24px;
  padding-bottom: 16px;
`;

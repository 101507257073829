import {
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
} from '@elastic/eui';
import React from 'react';
import { H3, Button, H2 } from '../../../../App.style';
import { Process } from '../../../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';

interface DescriptionModalProps {
  isVisible: boolean;
  closeModal: () => void;
  process: Process;
}

const DescriptionModal: React.FC<DescriptionModalProps> = ({
  isVisible,
  closeModal,
  process,
}) => {
  const { t } = useTranslation();
  let descriptionModal;
  if (isVisible) {
    descriptionModal = (
      <EuiModal onClose={closeModal} style={{ maxWidth: 500 }}>
        <EuiModalHeader>
          <H2>{process.name}</H2>
        </EuiModalHeader>

        <EuiModalBody>
          <H3>{process.description}</H3>
        </EuiModalBody>

        <EuiModalFooter style={{ padding: '20px 20px' }}>
          <Button $styleType="NORMAL" onClick={closeModal}>
            {t('common.close')}
          </Button>
        </EuiModalFooter>
      </EuiModal>
    );
  }
  return <>{descriptionModal}</>;
};

export default DescriptionModal;

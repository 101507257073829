import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const MinusSmallIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <g id="icon">
      <path
        id="Vector 399 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.75C1 3.61193 1.11193 3.5 1.25 3.5H6.75C6.88807 3.5 7 3.61193 7 3.75C7 3.88807 6.88807 4 6.75 4H1.25C1.11193 4 1 3.88807 1 3.75Z"
      />
    </g>
  </StyledSvg>
);

import { useState, useEffect } from 'react';

export const useEditorState = (
  initialValue: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialData?: Record<string, any>,
) => {
  const [editorValue, setEditorValue] = useState<string>(initialValue);
  const [isValidJSON, setIsValidJSON] = useState<boolean>(true);

  useEffect(() => {
    if (initialData) {
      setEditorValue(JSON.stringify(initialData, null, 2));
    }
  }, [initialData]);

  const handleChange = (newValue: string) => {
    const formatted = newValue.replace(/'/g, '"');
    setEditorValue(formatted);

    try {
      const parsed = JSON.parse(formatted);
      setIsValidJSON(true);
      return parsed;
    } catch (e) {
      setIsValidJSON(false);
      return null;
    }
  };

  const formatJSON = () => {
    if (isValidJSON) {
      const parsed = JSON.parse(editorValue);
      const formatted = JSON.stringify(parsed, null, 2);
      setEditorValue(formatted);
    }
  };

  return { editorValue, isValidJSON, handleChange, formatJSON };
};

import { COLOR_NEUTRAL_40 } from './../../../App.style';
import styled, { createGlobalStyle } from 'styled-components';

export const CustomDatepickerStyle = createGlobalStyle`
  .react-datepicker__time-list-item--disabled {
    display: none;
  }
`;

export const RandomizedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 400px;
  justify-content: space-between;

  padding-bottom: 25px;
  position: relative;
`;

export const RandomizedErrorContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

export const DynamicContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

export const DynamicTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DynamicContentContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.5px solid ${COLOR_NEUTRAL_40};
  padding-bottom: 5px;

  position: relative;
`;

export const DeleteIntervalIcon = styled.div`
  position: absolute;
  top: 50%;
  right: -32px;
  cursor: pointer;
  transform: translateY(-50%);
`;

export const DynamicButtonContainer = styled.div`
  margin: 24px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

import React from 'react';
import {
  CalendarInterface,
  EIGHT_O_CLOCK_IN_SECONDS,
  ErrorInterface,
  HOUR_IN_SECONDS,
  IntervalsError,
  MIDNIGHT_IN_SECONDS,
  SIXTEEN_O_CLOCK_IN_SECONDS,
  WorkingHoursInterval,
} from '../calendarFunctions';
import {
  AddPeriodContainer,
  TimeInputsButtons,
  TimeInputsContainer,
} from '../WorkingHours.style';
import TimeSelect from './TimeSelect';
import { BLACK_typography, H4, H5 } from '../../../../../App.style';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { MinusSmallIcon } from '../../../../../../resources/icons-new/MinusSmallIcon';
import { PlusSmallIcon } from '../../../../../../resources/icons-new/PlusSmallIcon';
import { useTranslation } from 'react-i18next';

interface CalendarTimeInputsProps {
  dayIndex: number;
  calendar: CalendarInterface[];
  setCalendar: React.Dispatch<
    React.SetStateAction<CalendarInterface[] | undefined>
  >;
  intervalsErrors?: IntervalsError[];
  timeErrors: ErrorInterface[];
  outsideInboundErrors?: ErrorInterface[];
  type: 'outbound' | 'cc' | 'inbound';
  isSwitchChecked: boolean;
}

const CalendarTimeInputs: React.FC<CalendarTimeInputsProps> = ({
  dayIndex,
  calendar,
  setCalendar,
  intervalsErrors,
  timeErrors,
  outsideInboundErrors,
  type,
  isSwitchChecked,
}) => {
  const { t } = useTranslation();
  const getError = (
    dayIndex: number,
    workingHourIndex: number,
    type: 'outbound' | 'cc' | 'inbound',
    timeErrors: ErrorInterface[],
    intervalsErrors: IntervalsError[] | undefined,
    outsideInboundErrors?: ErrorInterface[],
  ) => {
    if (type === 'outbound' || type === 'cc') {
      const intervalError = intervalsErrors?.find(
        (error) =>
          error.day === dayIndex &&
          (error.intervals.from === workingHourIndex ||
            error.intervals.to === workingHourIndex),
      );

      // Check if we have an overlap and the current time is the 'from' of the second interval.
      if (
        intervalError &&
        intervalError.type === 'overlap' &&
        intervalError.intervals.from === workingHourIndex
      ) {
        return intervalError;
      }

      return (
        intervalError ||
        timeErrors.find(
          (error) =>
            error.day === dayIndex && error.interval === workingHourIndex,
        ) ||
        (outsideInboundErrors &&
          outsideInboundErrors.find(
            (error) =>
              error.day === dayIndex && error.interval === workingHourIndex,
          ))
      );
    } else if (type === 'inbound') {
      return (
        (outsideInboundErrors &&
          outsideInboundErrors.find((error) => error.day === dayIndex)) ||
        timeErrors.find(
          (error) =>
            error.day === dayIndex && error.interval === workingHourIndex,
        )
      );
    }

    return null;
  };

  const getErrorMessage = (
    dayIndex: number,
    workingHourIndex: number,
    timeErrors: ErrorInterface[],
    intervalsErrors?: IntervalsError[],
    outsideInboundErrors?: ErrorInterface[],
    inbound?: boolean,
  ): string => {
    let errorMessage = '';

    const intervalError = intervalsErrors?.find(
      (error) =>
        error.day === dayIndex && error.intervals.to === workingHourIndex,
    );

    const timeError = timeErrors.find(
      (error) => error.day === dayIndex && error.interval === workingHourIndex,
    );

    let outsideInboundError;

    outsideInboundError = outsideInboundErrors?.find(
      (error) => error.day === dayIndex && error.interval === workingHourIndex,
    );

    if (inbound) {
      outsideInboundError = outsideInboundErrors?.find(
        (error) => error.day === dayIndex,
      );
    }

    if (intervalError) {
      errorMessage =
        intervalError.type === 'overlap'
          ? t('presets.calendarForm.periodsOverlap')
          : t('presets.calendarForm.periodsError');
    } else if (timeError) {
      errorMessage = t('presets.calendarForm.endTimeGreater');
    } else if (outsideInboundError) {
      if (inbound) {
        errorMessage = t('presets.calendarForm.inboundShorter');
      } else {
        errorMessage = t('presets.calendarForm.outboundOutside');
      }
    }

    return errorMessage;
  };

  const handleAddInterval = (day: number) => {
    const arr = [...calendar];
    const previousInterval =
      arr[day].workingHours[arr[day].workingHours.length - 1];

    if (arr[day].workingHours.length === 0) {
      arr[day].workingHours.push({
        from: EIGHT_O_CLOCK_IN_SECONDS,
        to: SIXTEEN_O_CLOCK_IN_SECONDS,
      });
    } else {
      const newInterval: WorkingHoursInterval = {
        from: previousInterval.to + HOUR_IN_SECONDS,
        to: previousInterval.to + HOUR_IN_SECONDS * 2,
      };
      if (newInterval.to - 1 > MIDNIGHT_IN_SECONDS) {
        alert(t('presets.calendarForm.nextInterval'));
        return;
      }
      arr[day].workingHours.push(newInterval);
    }

    setCalendar(arr);
  };

  const handleDeleteInterval = (day: number, index: number) => {
    const arr = [...calendar];

    // Check if the array is empty or index is out of range
    if (
      arr[day].workingHours.length === 0 ||
      index < 0 ||
      index >= arr[day].workingHours.length
    ) {
      return;
    }

    // Remove element at specified index
    arr[day].workingHours.splice(index, 1);

    // Set the updated calendar
    setCalendar(arr);
  };

  return (
    <TimeInputsContainer>
      {calendar[dayIndex] &&
      calendar[dayIndex].workingHours.length === 0 &&
      isSwitchChecked ? (
        <AddPeriodContainer onClick={() => handleAddInterval(dayIndex)}>
          <PlusIcon color={BLACK_typography} />
          <H4>{t('presets.calendarForm.addPeriod')}</H4>
        </AddPeriodContainer>
      ) : (
        calendar[dayIndex].workingHours.map((item, workingHourIndex) => {
          const error = getError(
            dayIndex,
            workingHourIndex,
            type,
            timeErrors,
            intervalsErrors,
            outsideInboundErrors,
          );

          const isError = !!error;
          const errorMessage = getErrorMessage(
            dayIndex,
            workingHourIndex,
            timeErrors,
            intervalsErrors,
            outsideInboundErrors,
            type === 'inbound' && true,
          );

          const calendarLength = calendar[dayIndex].workingHours.length;

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
              key={`${item.from}-${item.to}-${workingHourIndex}`}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TimeSelect
                  highlightError={isError}
                  key={`${dayIndex}-day-${workingHourIndex}`}
                  item={item}
                  dayIndex={dayIndex}
                  workingHourIndex={workingHourIndex}
                  calendar={calendar}
                  setCalendar={
                    setCalendar as React.Dispatch<
                      React.SetStateAction<CalendarInterface[]>
                    >
                  }
                />

                <TimeInputsButtons>
                  <>
                    {calendar[dayIndex].workingHours.length != 0 && (
                      <MinusSmallIcon
                        $pointer
                        onClick={() =>
                          handleDeleteInterval(dayIndex, workingHourIndex)
                        }
                        style={{ width: 16, height: 16 }}
                      />
                    )}
                  </>

                  {type !== 'inbound' && (
                    <>
                      {workingHourIndex === calendarLength - 1 &&
                        calendarLength < 4 && (
                          <PlusSmallIcon
                            $pointer
                            onClick={() => handleAddInterval(dayIndex)}
                            style={{ width: 16, height: 16 }}
                          />
                        )}
                    </>
                  )}
                </TimeInputsButtons>
              </div>
              <div
                style={{
                  width: '80%',
                  color: '#ba2525',
                  margin: '0px 5px',
                }}
              >
                {isError && (
                  <H5 style={{ color: '#ba2525' }}>{errorMessage}</H5>
                )}
              </div>
            </div>
          );
        })
      )}
    </TimeInputsContainer>
  );
};

export default CalendarTimeInputs;

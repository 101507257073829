import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../common/hooks';
import { H2, H3, H4 } from '../../../../App.style';
import {
  CallCenterNonWorkingDaysConfiguration,
  CallCenterNonWorkingDaysConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import {
  FormContainer,
  FormRow,
} from '../../../processes/forms/ProcessForm.style';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../../PresetsPanel.style';
import AssociatedProcesses from '../../shared/AssociatedProcesses';
import EditPresetButtons from '../../shared/EditPresetButtons';
import { DaysOffDatesContainer, DayOffContainer } from '../DaysOff.style';
import CallCenterDaysOffForm from './CallCenterDaysOffForm';
import { updateCallCenterDaysOffAsyncThunk } from './api/callCentreDaysOffSlice';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../../common/modals/LoadingModal';

interface CallCenterDaysOffDetailedProps {
  callCenterDaysOff: CallCenterNonWorkingDaysConfiguration;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  hasPermission: boolean;
}

const CallCenterDaysOffDetailed: React.FC<CallCenterDaysOffDetailedProps> = ({
  callCenterDaysOff,
  backToList,
  findProcessNamesById,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(callCenterDaysOff.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(callCenterDaysOff.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editCallCenterDaysOff, setEditCallCenterDaysOff] =
    useState<CallCenterNonWorkingDaysConfigurationRequest>({
      strategy: '',
      name: '',
      holidays: [],
    });

  useEffect(() => {
    setEditCallCenterDaysOff(callCenterDaysOff);
  }, [callCenterDaysOff]);

  const handleCallCenterEditClick = () => {
    const id = callCenterDaysOff.id;
    if (editCallCenterDaysOff && id) {
      const args = { id: id, data: editCallCenterDaysOff };
      setIsLoadingVisible(true);
      dispatch(updateCallCenterDaysOffAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditCallCenterDaysOff(callCenterDaysOff);
  };

  const associatedWithProcesses = findProcessNamesById(callCenterDaysOff.id);

  const handleBackToDetailed = () => setEdit(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />

      {hasPermission && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleCallCenterEditClick}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{callCenterDaysOff.name}</H2>
            <H4 $grey>
              {t('common.created')} {formattedCreated}, {t('common.modified')}{' '}
              {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <FormRow label={<H3>{t('presets.daysOff.holidaysDate')}</H3>}>
            <DaysOffDatesContainer>
              {callCenterDaysOff.holidays &&
              callCenterDaysOff.holidays.length ? (
                callCenterDaysOff.holidays.map((date, index) => {
                  return (
                    <DayOffContainer key={`date-${index}`}>
                      <H3>{moment(date).format('D MMMM YYYY')}</H3>
                    </DayOffContainer>
                  );
                })
              ) : (
                <div>{t('presets.daysOff.nothingToShow')}</div>
              )}
            </DaysOffDatesContainer>
          </FormRow>
        </FormContainer>
      ) : (
        <CallCenterDaysOffForm
          callCenterDaysOff={editCallCenterDaysOff}
          setCallCenterDaysOff={setEditCallCenterDaysOff}
          backToList={backToList}
          backToDetailed={handleBackToDetailed}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default CallCenterDaysOffDetailed;

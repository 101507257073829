import React, { useRef, useState } from 'react';
import { H1, H2, H3, H4 } from '../../../../App.style';
import {
  AnalyticsOverwiewCardContainer,
  AnalyticsOverwiewCardTextContainer,
  AnalyticsOverwiewCardContentContainer,
  Progress,
  ProgressBarContainer,
  ProgressBarNumbersContainer,
  ProgressBarDataContainer,
  ProgressBarDataButton,
  AnalyticsOverviewProgressBarLeftContainer,
  AnalyticsProgressBarContentContainer,
  AnalyticsCallsAmountData,
  FakeLoadingImage,
  ProgressBarHoverContainer,
} from '../../Analytics.style';
import { PhoneIcon } from '../../../../../resources/icons-new/PhoneIcon';
import { TimeIcon } from '../../../../../resources/icons-new/TimeIcon';
import Ramka2Img from '../../../../../resources/analytics/Ramka2.png';
import { useTranslation } from 'react-i18next';

interface ProgressBarChartProps {
  title: string;
  subtitle: string;
  percentage: number;
  callsAmount: {
    inbound: number;
    outbound: number;
    total: number;
    recordsTotal: number;
  };
  avg_calls_time: number;
  total_calls_time: number;
  isLoading?: boolean;
}

const ProgressBarChart: React.FC<ProgressBarChartProps> = ({
  title,
  subtitle,
  percentage,
  callsAmount,
  avg_calls_time,
  total_calls_time,
  isLoading,
}) => {
  const { t } = useTranslation();
  function formatSeconds(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';

    if (hours > 0) {
      result += `${hours} h `;
    }
    if (minutes > 0) {
      result += `${minutes} min `;
    }
    if (remainingSeconds > 0 || seconds === 0) {
      result += `${remainingSeconds} sec`;
    }

    return result.trim();
  }

  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [isHoverVisible, setIsHoverVisible] = useState(false);
  const barRef = useRef<HTMLDivElement>(null);

  const handleShowProgressHover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (barRef.current) {
      const { top, left } = barRef.current.getBoundingClientRect();
      setHoverPosition({
        x: event.clientX - left + 10,
        y: event.clientY - top + 10,
      });
    }
  };
  const handleShowHover = () => setIsHoverVisible(true);
  const handleCloseHover = () => {
    setIsHoverVisible(false);
  };
  return (
    <AnalyticsOverwiewCardContainer
      style={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      noPadding={isLoading}
    >
      {isLoading ? (
        <FakeLoadingImage src={Ramka2Img} />
      ) : (
        <>
          <AnalyticsOverviewProgressBarLeftContainer>
            <AnalyticsOverwiewCardTextContainer>
              <H1 $medium>{title}</H1>
              <H4 $wrap>{subtitle}</H4>
            </AnalyticsOverwiewCardTextContainer>
            <AnalyticsOverwiewCardContentContainer>
              <ProgressBarContainer
                ref={barRef}
                onMouseMove={handleShowProgressHover}
                onMouseEnter={handleShowHover}
                onMouseLeave={handleCloseHover}
              >
                <Progress
                  progress={percentage}
                  style={{ width: `${percentage}%` }}
                />
                <ProgressBarNumbersContainer>
                  <H2>0</H2>
                  <H1>{percentage}%</H1>
                  <H2>100</H2>
                </ProgressBarNumbersContainer>

                <ProgressBarHoverContainer
                  style={{ left: hoverPosition.x, top: hoverPosition.y }}
                  isVisible={isHoverVisible}
                >
                  <H3 style={{ color: '#127fbf' }}>{percentage}%</H3>
                  <H3>
                    {callsAmount.recordsTotal}{' '}
                    {t('analytics.charts.overview.records')}
                  </H3>
                </ProgressBarHoverContainer>
              </ProgressBarContainer>
            </AnalyticsOverwiewCardContentContainer>
          </AnalyticsOverviewProgressBarLeftContainer>
          <ProgressBarDataContainer>
            <H2 $medium>{t('analytics.charts.overview.callsAmount')}</H2>
            <AnalyticsProgressBarContentContainer>
              <AnalyticsCallsAmountData>
                <H4>{t('analytics.charts.overview.inbound')}</H4>
                <H4 $medium>{callsAmount.inbound}</H4>
              </AnalyticsCallsAmountData>
              <AnalyticsCallsAmountData>
                <H4>{t('analytics.charts.overview.outbound')}</H4>
                <H4 $medium>{callsAmount.outbound}</H4>
              </AnalyticsCallsAmountData>
            </AnalyticsProgressBarContentContainer>
            <ProgressBarDataButton>
              <H2>
                {t('analytics.charts.overview.total')}
                <span>{callsAmount.total}</span>
              </H2>
            </ProgressBarDataButton>
          </ProgressBarDataContainer>

          <ProgressBarDataContainer>
            <H2 $medium>{t('analytics.charts.overview.averageCallsTime')}</H2>
            <AnalyticsProgressBarContentContainer>
              <H4 $wrap>
                {t('analytics.charts.overview.averageCallsTimeDesc')}
              </H4>
            </AnalyticsProgressBarContentContainer>
            <ProgressBarDataButton>
              <PhoneIcon />
              <H2 $medium>{formatSeconds(avg_calls_time)}</H2>
            </ProgressBarDataButton>
          </ProgressBarDataContainer>

          <ProgressBarDataContainer>
            <H2 $medium>{t('analytics.charts.overview.totalCallsTime')}</H2>
            <AnalyticsProgressBarContentContainer>
              <H4 $wrap>{t('analytics.charts.overview.totalCallsTimeDesc')}</H4>
            </AnalyticsProgressBarContentContainer>
            <ProgressBarDataButton>
              <TimeIcon />
              <H2 $medium>{formatSeconds(total_calls_time)}</H2>
            </ProgressBarDataButton>
          </ProgressBarDataContainer>
        </>
      )}
    </AnalyticsOverwiewCardContainer>
  );
};

export default ProgressBarChart;

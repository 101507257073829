import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const XIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M4.14645 4.85355C3.95118 4.65829 3.95118 4.34171 4.14645 4.14645C4.34171 3.95118 4.65829 3.95118 4.85355 4.14645L10 9.29289L15.1464 4.14645C15.3417 3.95118 15.6583 3.95118 15.8536 4.14645C16.0488 4.34171 16.0488 4.65829 15.8536 4.85355L10.7071 10L15.8536 15.1464C16.0488 15.3417 16.0488 15.6583 15.8536 15.8536C15.6583 16.0488 15.3417 16.0488 15.1464 15.8536L10 10.7071L4.85355 15.8536C4.65829 16.0488 4.34171 16.0488 4.14645 15.8536C3.95119 15.6583 3.95119 15.3417 4.14645 15.1464L9.29289 10L4.14645 4.85355Z" />
  </StyledSvg>
);

import { EuiModalBody, EuiToolTip, copyToClipboard } from '@elastic/eui';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { H2, H3 } from '../../../../App.style';
import {
  RecordData,
  ContextMapping,
} from '../../../../../generated/tenants/Api';
import {
  RecordsModalContent,
  RecordDetailsRow,
  RecordDetailsLeftRowContainer,
} from './Records.style';
import { CopyIcon } from '../../../../../resources/icons-new/CopyIcon';
import Modal from '../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface RecordsDetailedModalProps {
  isVisible: boolean;
  recordsList: RecordData[];
  selectedRecord: RecordData;
  setIsPhoneModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecordsDetailedModal: React.FC<RecordsDetailedModalProps> = ({
  isVisible,
  selectedRecord,
  setIsPhoneModalVisible,
}) => {
  const { t } = useTranslation();
  const [dialogueContextMapping, setDialogueContextMapping] =
    useState<ContextMapping>();

  useEffect(() => {
    setDialogueContextMapping(selectedRecord.dialogue_context_mapping[0]);
  }, [selectedRecord]);

  const objectToList = () => {
    const obj: { [key: string]: string } = {};
    if (dialogueContextMapping)
      for (const key in dialogueContextMapping?.context_data) {
        const value = dialogueContextMapping.context_data[key];
        if (typeof value === 'boolean') {
          obj[key] = value ? 'true' : 'false';
        } else {
          obj[key] = value;
        }
      }
    return obj;
  };
  const phoneNumberFields = objectToList();

  const [recordIdCopied, setRecordIdCopied] = useState<boolean>(false);

  const copyRecordID = () => {
    setRecordIdCopied(true);
    copyToClipboard(selectedRecord.id);
  };

  const body = (
    <EuiModalBody>
      {dialogueContextMapping !== undefined ? (
        <RecordsModalContent>
          <RecordDetailsRow>
            <RecordDetailsLeftRowContainer>
              <H3 $grey>{t('campaigns.records.table.telephone')}</H3>
            </RecordDetailsLeftRowContainer>
            <H3>{selectedRecord.phone_numbers.join(', ')}</H3>
          </RecordDetailsRow>

          <RecordDetailsRow>
            <RecordDetailsLeftRowContainer>
              <H3 $grey>external_id</H3>
            </RecordDetailsLeftRowContainer>
            <H3>{selectedRecord.external_id}</H3>
          </RecordDetailsRow>

          <RecordDetailsRow>
            <RecordDetailsLeftRowContainer>
              <H3 $grey>{t('campaigns.records.table.recordId')}</H3>
            </RecordDetailsLeftRowContainer>
            <H3 style={{ marginRight: 8 }}>{selectedRecord.id}</H3>

            <EuiToolTip
              position="top"
              content={
                <p style={{ textAlign: 'center' }}>
                  {recordIdCopied
                    ? t('campaigns.records.table.idCopied')
                    : t('campaigns.records.table.copyID')}
                </p>
              }
            >
              <div
                onClick={copyRecordID}
                onMouseLeave={() => setRecordIdCopied(false)}
              >
                <CopyIcon />
              </div>
            </EuiToolTip>
          </RecordDetailsRow>

          {Object.entries(phoneNumberFields).map(([key, value]) => (
            <RecordDetailsRow key={key}>
              <RecordDetailsLeftRowContainer>
                <H3 $grey>{key}</H3>
              </RecordDetailsLeftRowContainer>
              <H3>{value}</H3>
            </RecordDetailsRow>
          ))}

          <RecordDetailsRow>
            <RecordDetailsLeftRowContainer>
              <H3 $grey>{t('campaigns.records.table.source')}</H3>
            </RecordDetailsLeftRowContainer>
            <H3>
              {selectedRecord.imported_filename
                ? selectedRecord.imported_filename
                : '---'}
            </H3>
          </RecordDetailsRow>

          <RecordDetailsRow>
            <RecordDetailsLeftRowContainer>
              <H3 $grey>{t('campaigns.records.table.importedDate')}</H3>
            </RecordDetailsLeftRowContainer>
            <H3>
              {moment(selectedRecord.created_at).format('DD.MM.YYYY, HH:mm')}
            </H3>
          </RecordDetailsRow>
        </RecordsModalContent>
      ) : (
        <H2>{t('campaigns.records.table.noDCM')}</H2>
      )}
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={isVisible}
      onCancel={() => setIsPhoneModalVisible(false)}
      noConfirm
      headerTitle={t('campaigns.records.table.recordDetails')}
      children={body}
    />
  );
};

export default RecordsDetailedModal;

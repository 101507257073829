import React from 'react';
import { SnapshotContainer } from '../Campaigns.style';
import {
  Campaign,
  DynamicTimeBetweenAttemptsRequest,
} from '../../../../generated/tenants/Api';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import { H3 } from '../../../App.style';
import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFieldText,
  EuiFormRow,
  EuiToolTip,
} from '@elastic/eui';
import moment from 'moment';
import { InfoIcon } from '../../../../resources/icons-new/InfoIcon';
import {
  CustomDatepickerStyle,
  RandomizedContainer,
  DynamicContainer,
  DynamicTitleContainer,
  DynamicContentContainer,
} from '../../presets/callingPreferences/CallingPreferences.style';
import FormRowTitle from '../../processes/forms/FormRowTitle';
import { useTranslation } from 'react-i18next';

interface CallingPreferencesSnapshotProps {
  campaign?: Campaign;
}

const CallingPreferencesSnapshot: React.FC<CallingPreferencesSnapshotProps> = ({
  campaign,
}) => {
  const { t } = useTranslation();
  const callingPreference = campaign?.calling_configuration;

  const secondsToMoment = (seconds: number) => {
    return moment().startOf('day').add(seconds, 'seconds');
  };

  const timeBetweenAttempts = moment()
    .startOf('day')
    .add(callingPreference?.time_between_attempts_seconds, 'seconds');
  return (
    <SnapshotContainer>
      {callingPreference?.name && (
        <FormRow label={<H3>{t('presets.callingPreferencesForm.name')}</H3>}>
          <EuiFieldText readOnly value={callingPreference.name} />
        </FormRow>
      )}

      <FormRow
        label={<H3>{t('presets.callingPreferencesForm.callAttempts')}</H3>}
      >
        <EuiFieldText readOnly value={callingPreference?.call_attempts} />
      </FormRow>

      <FormRow
        label={<H3>{t('presets.callingPreferencesForm.dailyCallAttempts')}</H3>}
      >
        <EuiFieldText
          readOnly
          value={callingPreference?.call_attempts_per_day}
        />
      </FormRow>

      <FormRow
        label={
          <H3>{t('presets.callingPreferencesForm.intervalBetweenAttempts')}</H3>
        }
      >
        <EuiFieldText
          readOnly
          value={callingPreference?.time_between_attempts_mode}
        />
      </FormRow>

      <CustomDatepickerStyle />

      {callingPreference?.time_between_attempts_mode === 'STATIC' && (
        <FormRow
          label={
            <FormRowTitle
              title={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSeconds',
              )}
              tooltip={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSecondsTooltip',
              )}
            />
          }
        >
          <EuiDatePicker
            selected={timeBetweenAttempts}
            readOnly
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat={'HH:mm'}
            timeFormat={'HH:mm'}
            minTime={moment().hours(0).minutes(30)}
            maxTime={moment().hours(4).minutes(0)}
          />
        </FormRow>
      )}

      {callingPreference?.time_between_attempts_mode === 'RANDOMIZED' &&
        callingPreference.randomized_time_between_attempts && (
          <RandomizedContainer>
            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.minimal')}</H3>}
              style={{ width: 150 }}
            >
              <EuiDatePicker
                selected={secondsToMoment(
                  callingPreference.randomized_time_between_attempts
                    .min_time_between_attempts_seconds,
                )}
                readOnly
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </EuiFormRow>

            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.maximal')}</H3>}
              style={{ width: 150, marginTop: -2 }}
            >
              <EuiDatePicker
                selected={secondsToMoment(
                  callingPreference.randomized_time_between_attempts
                    .max_time_between_attempts_seconds,
                )}
                readOnly
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </EuiFormRow>
          </RandomizedContainer>
        )}

      {callingPreference?.time_between_attempts_mode === 'DYNAMIC' &&
        callingPreference.dynamic_time_between_attempts && (
          <DynamicContainer>
            <DynamicTitleContainer>
              <H3>{t('presets.callingPreferencesForm.timeRange')}</H3>
              <div style={{ display: 'flex', gap: 8 }}>
                <H3>{`Interval (hh:mm)`}</H3>
                <EuiToolTip
                  position="top"
                  content={
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {t('presets.callingPreferencesForm.timeRangeTooltip')}
                    </div>
                  }
                >
                  <InfoIcon $pointer />
                </EuiToolTip>
              </div>
            </DynamicTitleContainer>
            {callingPreference.dynamic_time_between_attempts.map(
              (interval: DynamicTimeBetweenAttemptsRequest, index: number) => (
                <DynamicContentContainer
                  key={`dynamic-time-between-attempts-${index}`}
                >
                  <div style={{ width: 220 }}>
                    <EuiDatePickerRange
                      readOnly
                      startDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_start_seconds,
                          )}
                          readOnly
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      }
                      endDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_end_seconds,
                          )}
                          readOnly
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      }
                    />
                  </div>
                  <div style={{ width: 100 }}>
                    <EuiDatePicker
                      selected={secondsToMoment(
                        interval.time_between_attempts_seconds,
                      )}
                      readOnly
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      minTime={moment().hours(0).minutes(30)}
                      maxTime={moment().hours(4).minutes(0)}
                    />
                  </div>
                </DynamicContentContainer>
              ),
            )}

            <FormRow
              style={{ marginTop: 10 }}
              label={
                <FormRowTitle
                  title={t('presets.callingPreferencesForm.basicInterval')}
                  tooltip={t(
                    'presets.callingPreferencesForm.basicIntervalTooltip',
                  )}
                />
              }
            >
              <EuiDatePicker
                selected={timeBetweenAttempts}
                readOnly
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </FormRow>
          </DynamicContainer>
        )}
    </SnapshotContainer>
  );
};

export default CallingPreferencesSnapshot;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const SelectTenantIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M6.22783 12.4489C6.37448 12.6262 6.57813 12.747 6.804 12.7908L12.804 13.9798C12.9485 14.0096 13.0978 14.0069 13.241 13.9719C13.3843 13.9369 13.5181 13.8705 13.6325 13.7775C13.747 13.6844 13.8394 13.5671 13.9029 13.434C13.9665 13.3009 13.9996 13.1553 14 13.0078L14 1.98981C13.9987 1.84259 13.965 1.69747 13.9011 1.56481C13.8373 1.43215 13.745 1.31522 13.6307 1.22237C13.5165 1.12952 13.3831 1.06304 13.2402 1.02768C13.0973 0.992317 12.9484 0.988946 12.804 1.01781L6.804 2.20681C6.336 2.29981 6 2.70681 6 3.17881L6 4.99881H5L5 3.17381C5 2.22881 5.673 1.41381 6.608 1.22881L12.608 0.038807C12.8967 -0.0189142 13.1946 -0.012173 13.4805 0.0585493C13.7663 0.129272 14.033 0.26223 14.2615 0.447931C14.49 0.633631 14.6746 0.867493 14.8023 1.13282C14.93 1.39814 14.9975 1.68838 15 1.98281L15 13.0148C14.999 13.3098 14.9326 13.6009 14.8054 13.867C14.6783 14.1332 14.4936 14.3678 14.2647 14.5539C14.0359 14.74 13.7685 14.873 13.482 14.9432C13.1955 15.0134 12.897 15.019 12.608 14.9598L6.608 13.7698C5.673 13.5848 5 12.7698 5 11.8248L5 9.99881H6L6 11.8188C6.00066 12.0489 6.08119 12.2716 6.22783 12.4489Z" />
    <path d="M10.114 6.99878L8.171 5.14878C8.11731 5.10004 8.07441 5.04061 8.04506 4.9743C8.0157 4.908 8.00053 4.83629 8.00053 4.76378C8.00053 4.69127 8.0157 4.61956 8.04506 4.55325C8.07441 4.48695 8.11731 4.42752 8.171 4.37878C8.28451 4.27583 8.43226 4.21881 8.5855 4.21881C8.73874 4.21881 8.88649 4.27583 9 4.37878L11.05 6.32878C11.2122 6.47433 11.3419 6.6524 11.4307 6.8514C11.5194 7.0504 11.5653 7.26587 11.5653 7.48378C11.5653 7.70169 11.5194 7.91715 11.4307 8.11615C11.3419 8.31515 11.2122 8.49323 11.05 8.63878L9 10.5888C8.772 10.8018 8.399 10.8018 8.172 10.5888C8.11831 10.54 8.07541 10.4806 8.04606 10.4143C8.0167 10.348 8.00154 10.2763 8.00154 10.2038C8.00154 10.1313 8.0167 10.0596 8.04606 9.99325C8.07541 9.92695 8.11831 9.86752 8.172 9.81878L10.082 7.99878H1.556C1.249 7.99878 1 7.77478 1 7.49878C1 7.22278 1.249 6.99878 1.556 6.99878H10.114Z" />
  </StyledSvg>
);

import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../common/modals/Modal';
import {
  EuiFieldNumber,
  EuiFieldText,
  EuiLoadingSpinner,
  EuiModalBody,
  EuiSuperSelect,
  EuiToolTip,
  copyToClipboard,
} from '@elastic/eui';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { Button, COLOR_PRIMARY_1, H3, H4 } from '../../../../../../App.style';
import { PasswordVisibilityToggle } from '../../../../../../../common/inputs/Inputs.style';
import { EyeIcon } from '../../../../../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../../../../../resources/icons-new/EyeSlashIcon';
import { CopyIcon } from '../../../../../../../resources/icons-new/CopyIcon';
import { InfoIcon } from '../../../../../../../resources/icons-new/InfoIcon';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  checkSftpCreate,
  checkSftpTest,
  clearTestSftp,
  createSftpConfigurationAsyncThunk,
  clearCreateSftpConfig,
  getSftpConfigurationsListAsyncThunk,
  testSftpConfigurationAsyncThunk,
  updateSftpConfigurationAsyncThunk,
  checkSftpUpdate,
  clearUpdateSftpConfig,
} from '../api/sftpConfigSlice';
import {
  AuthMethodEnum,
  SftpConfiguration,
  SftpConfigurationRequest,
} from '../../../../../../../generated/tenants/Api';
import { SuccessIcon } from '../../../../../../../resources/icons-new/SuccessIcon';
import { XIcon } from '../../../../../../../resources/icons-new/XIcon';
import { TestResultContainer } from '../SftpPanel.style';
import { useTranslation } from 'react-i18next';

interface SftpConfigurationFormModalProps {
  isVisible: boolean;
  closeModal: () => void;
  lektaPublicKey: string;
  sftpConfigurationEdit?: SftpConfiguration;
  clearSelectedSftp: () => void;
}

const SftpConfigurationFormModal: React.FC<SftpConfigurationFormModalProps> = ({
  isVisible,
  closeModal,
  lektaPublicKey,
  sftpConfigurationEdit,
  clearSelectedSftp,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [sftpConfig, setSftpConfig] = useState<SftpConfigurationRequest>({
    auth_method: 'PASSWORD',
    sftp_username: '',
    sftp_host: '',
    sftp_port: 22,
  });

  const handleCloseModal = () => {
    closeModal();
    clearForm();
    clearSelectedSftp();
  };

  const clearForm = () => {
    setSftpConfig({
      auth_method: 'PASSWORD',
      sftp_username: '',
      sftp_password: '',
      sftp_host: '',
      sftp_port: 22,
    });
    setTestResult(undefined);
    setTestState('before');
    dispatch(clearTestSftp());
    clearSelectedSftp();
  };

  useEffect(() => {
    if (sftpConfigurationEdit) {
      setSftpConfig(sftpConfigurationEdit);
    }
  }, [sftpConfigurationEdit]);

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isKeyCopied, setIsKeyCopied] = useState<boolean>(false);

  const handleChangeAuthMethod = (authMethod: AuthMethodEnum) => {
    if (authMethod === 'PASSWORD') {
      setSftpConfig({
        ...sftpConfig,
        auth_method: 'PASSWORD',
        sftp_password: '',
      });
    } else if (authMethod === 'RSA_KEY') {
      setSftpConfig({
        ...sftpConfig,
        auth_method: 'RSA_KEY',
        sftp_password: undefined,
      });
    }
  };

  //form

  const authMethodOptions = [
    {
      value: 'PASSWORD',
      inputDisplay: <H3>{t('settings.adminPanel.sftp.form.password')}</H3>,
      dropdownDisplay: <H3>{t('settings.adminPanel.sftp.form.password')}</H3>,
    },
    {
      value: 'RSA_KEY',
      inputDisplay: <H3>{t('settings.adminPanel.sftp.form.rsaKey')}</H3>,
      dropdownDisplay: <H3>{t('settings.adminPanel.sftp.form.rsaKey')}</H3>,
    },
  ];

  const handleCopyPublicKey = () => {
    setIsKeyCopied(true);
    copyToClipboard(lektaPublicKey);

    setTimeout(() => {
      setIsKeyCopied(false);
    }, 1000);
  };

  const handleSftpConfigurationCheck = () => {
    dispatch(clearTestSftp());
    const password =
      sftpConfig.auth_method === 'RSA_KEY' ? null : sftpConfig.sftp_password;
    dispatch(
      testSftpConfigurationAsyncThunk({
        ...sftpConfig,
        sftp_password: password,
      }),
    );
    setTestState('loading');
  };

  type TestResult = 'success' | 'fail';
  const [testState, setTestState] = useState<'before' | 'loading' | 'result'>(
    'before',
  );

  const [testResult, setTestResult] = useState<TestResult>();

  const checkTestSftp = useAppSelector(checkSftpTest);
  useEffect(() => {
    if (
      checkTestSftp.state === 'idle' &&
      checkTestSftp.value?.status === 'success'
    ) {
      setTestResult('success');
      setTestState('result');
    } else if (
      checkTestSftp.state === 'idle' &&
      checkTestSftp.value?.status === 'fail'
    ) {
      setTestResult('fail');
      setTestState('result');
    }
  }, [checkTestSftp]);

  const handleClickCreateSftp = () => {
    const password =
      sftpConfig.auth_method === 'RSA_KEY' ? null : sftpConfig.sftp_password;
    dispatch(
      createSftpConfigurationAsyncThunk({
        ...sftpConfig,
        sftp_password: password,
      }),
    );
    clearForm();
    dispatch(clearTestSftp());
  };

  const handleClickEditSftp = () => {
    if (sftpConfigurationEdit) {
      const args = { id: sftpConfigurationEdit.id, data: sftpConfig };
      dispatch(updateSftpConfigurationAsyncThunk(args));
    }
  };

  const isTestButtonDisabled = () => {
    const isPasswordAuth = sftpConfig.auth_method === 'PASSWORD';
    const isPasswordProvided = (sftpConfig.sftp_password?.length ?? 0) > 0;

    const isFormIncomplete =
      sftpConfig.sftp_username.length === 0 ||
      sftpConfig.sftp_host.length === 0 ||
      (isPasswordAuth && !isPasswordProvided);

    return isFormIncomplete;
  };

  const handleChangeInForm = () => {
    setTestResult(undefined);
    setTestState('before');
    dispatch(clearTestSftp());
  };

  const handleCloseAfterFail = () => {
    handleChangeInForm();
    closeModal();

    setSftpConfig({
      auth_method: 'PASSWORD',
      sftp_username: '',
      sftp_password: '',
      sftp_host: '',
      sftp_port: 22,
    });
  };

  const checkCreateSftp = useAppSelector(checkSftpCreate);
  useEffect(() => {
    if (
      checkCreateSftp?.state === 'idle' &&
      checkCreateSftp.value?.status === 'success'
    ) {
      closeModal();
      dispatch(getSftpConfigurationsListAsyncThunk());
      dispatch(clearCreateSftpConfig());
      clearForm();
    }
  }, [checkCreateSftp]);

  const checkUpdateSftp = useAppSelector(checkSftpUpdate);

  useEffect(() => {
    if (
      checkUpdateSftp?.state === 'idle' &&
      checkUpdateSftp.value?.status === 'success'
    ) {
      closeModal();
      dispatch(getSftpConfigurationsListAsyncThunk());
      dispatch(clearUpdateSftpConfig());
      clearForm();
    }
  }, [checkUpdateSftp]);

  const body = (
    <EuiModalBody>
      <FormRow label={<H3>{t('settings.adminPanel.sftp.form.authMethod')}</H3>}>
        <EuiSuperSelect
          valueOfSelected={sftpConfig.auth_method}
          options={authMethodOptions}
          onChange={(e) => handleChangeAuthMethod(e as AuthMethodEnum)}
        />
      </FormRow>

      {sftpConfig.auth_method === 'RSA_KEY' && (
        <FormRow
          label={<H3>{t('settings.adminPanel.sftp.form.publicKey')}</H3>}
        >
          <>
            <EuiFieldText
              value={lektaPublicKey}
              readOnly
              append={
                <EuiToolTip
                  content={
                    isKeyCopied
                      ? t('settings.adminPanel.sftp.form.publicKeyCopied')
                      : t('settings.adminPanel.sftp.form.publicKeyCopy')
                  }
                  position="top"
                >
                  <div
                    style={{
                      width: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 40,

                      cursor: 'pointer',
                    }}
                    onClick={handleCopyPublicKey}
                  >
                    <CopyIcon onClick={handleCopyPublicKey} />
                  </div>
                </EuiToolTip>
              }
            />
            <div
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
                marginTop: 5,
              }}
            >
              <InfoIcon />
              <H4 $grey>{t('settings.adminPanel.sftp.form.request')}</H4>
            </div>
          </>
        </FormRow>
      )}

      <FormRow label={<H3>{t('settings.adminPanel.sftp.list.username')}</H3>}>
        <EuiFieldText
          value={sftpConfig.sftp_username}
          onChange={(e) => {
            setSftpConfig({ ...sftpConfig, sftp_username: e.target.value });
            handleChangeInForm();
          }}
          placeholder={t('common.writeHere')}
        />
      </FormRow>

      <FormRow label={<H3>{t('settings.adminPanel.sftp.list.host')}</H3>}>
        <EuiFieldText
          value={sftpConfig.sftp_host}
          onChange={(e) => {
            setSftpConfig({ ...sftpConfig, sftp_host: e.target.value });
            handleChangeInForm();
          }}
          placeholder={t('common.writeHere')}
        />
      </FormRow>

      {sftpConfig.auth_method === 'PASSWORD' && (
        <FormRow label={<H3>{t('settings.adminPanel.sftp.form.password')}</H3>}>
          <EuiFieldText
            placeholder={t('common.writeHere')}
            type={isPasswordVisible ? 'text' : 'password'}
            append={
              <PasswordVisibilityToggle
                type={'button'}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
              >
                {isPasswordVisible ? (
                  <EyeIcon style={{ width: 25, height: 25 }} />
                ) : (
                  <EyeSlashIcon style={{ width: 25, height: 25 }} />
                )}
              </PasswordVisibilityToggle>
            }
            value={sftpConfig.sftp_password ?? ''}
            onChange={(e) => {
              setSftpConfig({ ...sftpConfig, sftp_password: e.target.value });
              handleChangeInForm();
            }}
          />
        </FormRow>
      )}

      <FormRow label={<H3>Port</H3>}>
        <EuiFieldNumber
          value={sftpConfig.sftp_port}
          onChange={(e) => {
            setSftpConfig({ ...sftpConfig, sftp_port: Number(e.target.value) });
            handleChangeInForm();
          }}
          min={1}
          max={99999}
          placeholder={t('common.writeHere')}
        />
      </FormRow>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: testState === 'result' ? 'space-between' : 'flex-end',
        }}
      >
        {testState === 'before' && (
          <Button
            $size={'M'}
            type={'button'}
            onClick={handleSftpConfigurationCheck}
            disabled={isTestButtonDisabled()}
          >
            {t('settings.adminPanel.sftp.form.test')}
          </Button>
        )}

        {testState === 'loading' && (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <H3>{t('settings.adminPanel.sftp.list.checking')}</H3>
            <EuiLoadingSpinner size={'m'} style={{ marginLeft: 10 }} />
          </div>
        )}

        {testState === 'result' && (
          <TestResultContainer>
            {testResult === 'success' ? (
              <>
                <div style={{ display: 'flex' }}>
                  <H3 $semibold style={{ color: COLOR_PRIMARY_1 }}>
                    {t('settings.adminPanel.sftp.list.correctly')}
                  </H3>
                  <SuccessIcon color={COLOR_PRIMARY_1} />
                </div>
                {sftpConfigurationEdit ? (
                  <Button
                    $size="M"
                    type={'button'}
                    onClick={handleClickEditSftp}
                  >
                    {t('settings.adminPanel.sftp.form.edit')}
                  </Button>
                ) : (
                  <Button
                    $size={'M'}
                    type={'button'}
                    onClick={handleClickCreateSftp}
                  >
                    {t('settings.adminPanel.sftp.list.addNew')}
                  </Button>
                )}
              </>
            ) : (
              testResult === 'fail' && (
                <>
                  <div style={{ display: 'flex' }}>
                    <H3 $semibold style={{ color: '#983700' }}>
                      {t('common.error')}
                    </H3>
                    <XIcon color={'#983700'} />
                  </div>
                  <Button
                    $size={'M'}
                    $styleType="NORMAL"
                    type={'button'}
                    onClick={handleCloseAfterFail}
                  >
                    {t('common.close')}
                  </Button>
                </>
              )
            )}
          </TestResultContainer>
        )}
      </div>
    </EuiModalBody>
  );
  return (
    <Modal
      isVisible={isVisible}
      onCancel={handleCloseModal}
      headerTitle={
        sftpConfigurationEdit
          ? t('settings.adminPanel.sftp.form.edit')
          : t('settings.adminPanel.sftp.list.addNew')
      }
      children={body}
      noButtons
    />
  );
};

export default SftpConfigurationFormModal;

import React from 'react';
import { H3 } from '../../App.style';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../common/modals/Modals.style';
import { TrashIcon } from '../../../resources/icons-new/TrashIcon';
import Modal from '../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface PresetDeleteModalProps {
  type:
    | 'voice'
    | 'dialogue'
    | 'calling prefernece'
    | 'calendar'
    | 'call centre-days off'
    | 'bot-days off'
    | 'custom faas';
  isVisible: boolean;
  presetName: string;
  closeModal: () => void;
  deletePreset: () => void;
}

const PresetDeleteModal: React.FC<PresetDeleteModalProps> = ({
  type,
  isVisible,
  closeModal,
  presetName,
  deletePreset,
}) => {
  const { t } = useTranslation();
  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deletePreset();

    return false;
  };

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>
          {t('presets.deleteQuestion')} <b>{type}</b>?
        </H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={`${t('common.remove')} ${type}: ${presetName}`}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default PresetDeleteModal;

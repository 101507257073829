import React, { useEffect, useState } from 'react';
import { XIcon } from '../../resources/icons-new/XIcon';
import {
  ErrorPopupContainer,
  PopupCloseButton,
  PopupContent,
} from './Popup.style';
import { H3 } from '../../app/App.style';
import { useTranslation } from 'react-i18next';

interface ErrorPopupProps {
  onClose: () => void;
  text: string;
  textLine2?: string;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({
  onClose,
  text,
  textLine2,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const autoCloseDuration = 5000; // Duration before auto-closing, in milliseconds
  const animationDuration = 1000; // Animation duration, in milliseconds

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // Start fade-out animation
      setTimeout(onClose, animationDuration); // Close popup after animation
    }, autoCloseDuration - animationDuration);

    return () => clearTimeout(timer); // Clear timer if component unmounts
  }, [onClose, autoCloseDuration, animationDuration]);
  return (
    <ErrorPopupContainer isVisible={isVisible}>
      <PopupCloseButton onClick={onClose}>
        <XIcon />
      </PopupCloseButton>
      <PopupContent style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <XIcon color={'#983700'} />
        <H3 $bold>{t('popups.error.title')}</H3>
      </PopupContent>
      <div>
        <H3>{text}</H3>
        {textLine2 && <H3>{textLine2}</H3>}
      </div>
    </ErrorPopupContainer>
  );
};

export default ErrorPopup;

import { EuiSwitch, EuiToolTip } from '@elastic/eui';
import React from 'react';
import {
  CalendarInterface,
  CopiedIntervals,
  EIGHT_O_CLOCK_IN_SECONDS,
  ErrorInterface,
  IntervalsError,
  SIXTEEN_O_CLOCK_IN_SECONDS,
} from '../calendarFunctions';
import { H4 } from '../../../../../App.style';
import { WorkingHoursData, WorkingHoursRow } from '../WorkingHours.style';
import WorkingHoursTimeInputs from './WorkingHoursTimeInputs';
import { CopyIcon } from '../../../../../../resources/icons-new/CopyIcon';
import { SaveIcon } from '../../../../../../resources/icons-new/SaveIcon';
import { useTranslation } from 'react-i18next';

interface WorkingHoursDayProps {
  dayIndex: number;
  outboundCalendar: CalendarInterface[];
  setOutboundCalendar: React.Dispatch<
    React.SetStateAction<CalendarInterface[] | undefined>
  >;
  ccCalendar: CalendarInterface[];
  setCcCalendar: React.Dispatch<
    React.SetStateAction<CalendarInterface[] | undefined>
  >;
  inboundCalendar: CalendarInterface[];
  setInboundCalendar: React.Dispatch<
    React.SetStateAction<CalendarInterface[] | undefined>
  >;
  outboundOutsideInboundErrors?: ErrorInterface[];
  outboundTimeErrors: ErrorInterface[];
  ccTimeErrors: ErrorInterface[];
  outboundIntervalsError: IntervalsError[];
  ccIntervalsError: IntervalsError[];
  inboundTimeErrors: ErrorInterface[];
  coppiedIntervals: CopiedIntervals | undefined;
  showCopiedMessage: boolean;
  handleCopyIntervals: (dayIndex: number) => void;
  handlePasteIntervals: (dayIndex: number) => void;
}

const WorkingHoursDay: React.FC<WorkingHoursDayProps> = ({
  dayIndex,
  outboundCalendar,
  setOutboundCalendar,
  ccCalendar,
  setCcCalendar,
  inboundCalendar,
  setInboundCalendar,
  outboundOutsideInboundErrors,
  outboundTimeErrors,
  outboundIntervalsError,
  ccTimeErrors,
  ccIntervalsError,
  inboundTimeErrors,
  coppiedIntervals,
  showCopiedMessage,
  handleCopyIntervals,
  handlePasteIntervals,
}) => {
  const { t } = useTranslation();
  //if day has intervals, deletes it, if not => sets standard ones
  const handleDaySwitchClick = () => {
    const ccArr = [...ccCalendar];
    const inboundArr = [...inboundCalendar];
    const outboundArr = [...outboundCalendar];
    if (
      ccArr[dayIndex].workingHours.length === 0 &&
      inboundArr[dayIndex].workingHours.length === 0 &&
      outboundArr[dayIndex].workingHours.length === 0
    ) {
      ccArr[dayIndex].workingHours = [
        { from: EIGHT_O_CLOCK_IN_SECONDS, to: SIXTEEN_O_CLOCK_IN_SECONDS },
      ];
      setCcCalendar(ccArr);

      inboundArr[dayIndex].workingHours = [
        { from: EIGHT_O_CLOCK_IN_SECONDS, to: SIXTEEN_O_CLOCK_IN_SECONDS },
      ];
      setInboundCalendar(inboundArr);

      outboundArr[dayIndex].workingHours = [
        { from: EIGHT_O_CLOCK_IN_SECONDS, to: SIXTEEN_O_CLOCK_IN_SECONDS },
      ];
      setOutboundCalendar(outboundArr);
    } else {
      ccArr[dayIndex].workingHours = [];
      setCcCalendar(ccArr);
      inboundArr[dayIndex].workingHours = [];
      setInboundCalendar(inboundArr);
      outboundArr[dayIndex].workingHours = [];
      setOutboundCalendar(outboundArr);
    }
  };

  //copy
  const validateCopy = (dayIndex: number) => {
    if (ccTimeErrors.some((error) => error.day === dayIndex)) return false;
    else if (inboundTimeErrors.some((error) => error.day === dayIndex))
      return false;
    else if (outboundTimeErrors.some((error) => error.day === dayIndex))
      return false;
    else if (
      outboundOutsideInboundErrors &&
      outboundOutsideInboundErrors.some((error) => error.day === dayIndex)
    )
      return false;
    else return true;
  };

  const isSwitchChecked =
    inboundCalendar[dayIndex].workingHours.length !== 0 ||
    ccCalendar[dayIndex].workingHours.length !== 0 ||
    outboundCalendar[dayIndex].workingHours.length !== 0;

  const renderDayName = (
    dayName:
      | 'Monday'
      | 'Tuesday'
      | 'Wednesday'
      | 'Thursday'
      | 'Friday'
      | 'Saturday'
      | 'Sunday',
  ) => {
    switch (dayName) {
      case 'Monday':
        return t('presets.calendarForm.monday');
      case 'Tuesday':
        return t('presets.calendarForm.tuesday');
      case 'Wednesday':
        return t('presets.calendarForm.wednesday');
      case 'Thursday':
        return t('presets.calendarForm.thursday');
      case 'Friday':
        return t('presets.calendarForm.friday');
      case 'Saturday':
        return t('presets.calendarForm.saturday');
      case 'Sunday':
        return t('presets.calendarForm.sunday');
    }
  };

  return (
    <WorkingHoursRow key={`hey`}>
      <WorkingHoursData day>
        <div
          style={{ display: 'flex', height: 55, alignItems: 'center', gap: 15 }}
        >
          <EuiSwitch
            label={
              <H4 $bold>{renderDayName(outboundCalendar[dayIndex].name)}</H4>
            }
            checked={isSwitchChecked}
            onChange={handleDaySwitchClick}
            compressed
          />
        </div>
      </WorkingHoursData>

      {/* outbound */}
      <WorkingHoursData>
        <WorkingHoursTimeInputs
          dayIndex={dayIndex}
          calendar={outboundCalendar}
          setCalendar={setOutboundCalendar}
          intervalsErrors={outboundIntervalsError}
          timeErrors={outboundTimeErrors}
          outsideInboundErrors={outboundOutsideInboundErrors}
          type="outbound"
          isSwitchChecked={isSwitchChecked}
        />
      </WorkingHoursData>

      {/* inbound */}
      <WorkingHoursData>
        <WorkingHoursTimeInputs
          dayIndex={dayIndex}
          calendar={inboundCalendar}
          setCalendar={setInboundCalendar}
          timeErrors={inboundTimeErrors}
          outsideInboundErrors={outboundOutsideInboundErrors}
          type="inbound"
          isSwitchChecked={isSwitchChecked}
        />
      </WorkingHoursData>

      {/* call center */}
      <WorkingHoursData>
        <WorkingHoursTimeInputs
          dayIndex={dayIndex}
          calendar={ccCalendar}
          setCalendar={setCcCalendar}
          intervalsErrors={ccIntervalsError}
          timeErrors={ccTimeErrors}
          type="cc"
          isSwitchChecked={isSwitchChecked}
        />
      </WorkingHoursData>

      <WorkingHoursData copy>
        {isSwitchChecked && (
          <EuiToolTip
            content={
              !validateCopy(dayIndex)
                ? 'The entered data contains errors'
                : showCopiedMessage
                  ? 'Interval copied'
                  : coppiedIntervals !== undefined
                    ? 'Paste intervals'
                    : 'Copy intervals!'
            }
          >
            {coppiedIntervals === undefined ? (
              <CopyIcon
                $pointer
                $disabled={!validateCopy(dayIndex)}
                onClick={() => handleCopyIntervals(dayIndex)}
              />
            ) : (
              <SaveIcon onClick={() => handlePasteIntervals(dayIndex)} />
            )}
          </EuiToolTip>
        )}
      </WorkingHoursData>
    </WorkingHoursRow>
  );
};

export default WorkingHoursDay;

import moment from 'moment';
import React from 'react';
import { H3 } from '../../../../App.style';
import { CallCenterNonWorkingDaysConfiguration } from '../../../../../generated/tenants/Api';
import PresetListPreview from '../../PresetListPreview';
import {
  AddPresetIconContainer,
  CreateNewPresetContainer,
  CreatePresetButtonContainer,
  ListContainer,
} from '../../PresetsPanel.style';
import { NewPresetIcon } from '../../../../../resources/icons-new/NewPresetIcon';
import { useTranslation } from 'react-i18next';

interface CallCenterDaysOffListProps {
  callCenterDaysOffList: CallCenterNonWorkingDaysConfiguration[];
  createCallCenterDaysOff: () => void;
  goToDetailed: (callCenterDaysOffId: string) => void;
  findProcessNamesById(id: string): string[];
  handleDeleteCallCenterDaysOff: (id: string) => void;
  handleDuplicateCallCenterDaysOff: (id: string) => void;
  hasPermission: boolean;
}

const CallCenterDaysOffList: React.FC<CallCenterDaysOffListProps> = ({
  callCenterDaysOffList,
  createCallCenterDaysOff,
  goToDetailed,
  findProcessNamesById,
  handleDeleteCallCenterDaysOff,
  handleDuplicateCallCenterDaysOff,
  hasPermission,
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer>
      {callCenterDaysOffList &&
        callCenterDaysOffList.map((callCenterDaysOff, index) => {
          const formattedCreated = moment(callCenterDaysOff.created_at).format(
            'DD/MM/YYYY, HH:mm',
          );
          const fomrattedModified = moment(callCenterDaysOff.updated_at).format(
            'DD/MM/YYYY, HH:mm',
          );

          const usedIn = findProcessNamesById(callCenterDaysOff.id);
          return (
            <PresetListPreview
              type={'call_centre_days_off'}
              key={`call_centre_days_off-${index}`}
              index={index}
              onClick={goToDetailed}
              presetId={callCenterDaysOff.id}
              presetName={callCenterDaysOff.name}
              createDate={formattedCreated}
              modifiedDate={fomrattedModified}
              usedIn={usedIn}
              handleDeletePreset={handleDeleteCallCenterDaysOff}
              handleDuplicatePreset={handleDuplicateCallCenterDaysOff}
              disableDots={!hasPermission}
            />
          );
        })}

      {hasPermission && (
        <CreateNewPresetContainer onClick={createCallCenterDaysOff}>
          <CreatePresetButtonContainer>
            <AddPresetIconContainer>
              <NewPresetIcon
                style={{
                  position: 'absolute',
                  left: 17,
                  top: 17,
                  width: 15,
                  height: 15,
                }}
              />
            </AddPresetIconContainer>
            <H3>{t('common.createNew')}</H3>
          </CreatePresetButtonContainer>
        </CreateNewPresetContainer>
      )}
    </ListContainer>
  );
};

export default CallCenterDaysOffList;

import { appendIconComponentCache } from '@elastic/eui/es/components/icon/icon';
import { icon as EuiIconArrowDown } from '@elastic/eui/es/components/icon/assets/arrow_down';
import { icon as EuiIconArrowLeft } from '@elastic/eui/es/components/icon/assets/arrow_left';
import { icon as EuiIconSun } from '@elastic/eui/es/components/icon/assets/sun';
import { icon as EuiIconHome } from '@elastic/eui/es/components/icon/assets/home';
import { icon as EuiIconBug } from '@elastic/eui/es/components/icon/assets/bug';
import { icon as EuiIconAccessibility } from '@elastic/eui/es/components/icon/assets/accessibility';
import { icon as EuiIconHeatmap } from '@elastic/eui/es/components/icon/assets/heatmap';
import { icon as EuiIconMoon } from '@elastic/eui/es/components/icon/assets/moon';
import { icon as EuiIconWrench } from '@elastic/eui/es/components/icon/assets/wrench';
import { icon as EuiIconReporter } from '@elastic/eui/es/components/icon/assets/reporter';

appendIconComponentCache({
  arrowDown: EuiIconArrowDown,
  arrowLeft: EuiIconArrowLeft,
  sun: EuiIconSun,
  home: EuiIconHome,
  bug: EuiIconBug,
  user: EuiIconAccessibility,
  heatmap: EuiIconHeatmap,
  moon: EuiIconMoon,
  wrench: EuiIconWrench,
  reporter: EuiIconReporter,
});

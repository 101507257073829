import styled, { css } from 'styled-components';
import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY_1,
  COLOR_PRIMARY_2,
  COLOR_PRIMARY_3,
  COLOR_WHITE,
  H4,
} from '../../../../App.style';

export const CallsListContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CallsBarContainer = styled.div`
  width: 100%;
  height: 30px;

  margin: 8px;
  margin-top: 5px;

  display: flex;

  justify-content: space-between;
`;

//filters

export const CallsFiltersContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

//table

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const CallsTab = styled.table`
  margin-top: 8px;
  width: 100%;
  height: 100%;
  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    height: 90%;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background: white; // adjust this to your needs
  }
`;

interface RecordsTableHeaderProps {
  date?: boolean;
  pagination?: boolean;
  checkbox?: boolean;
}

export const CallsTableHeader = styled.th<RecordsTableHeaderProps>`
  border-top: 1px solid ${COLOR_NEUTRAL_20};
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};

  padding: 5px;
  width: ${(props) =>
    props.date ? '80px' : props.checkbox ? '30px' : '100px'};
  text-align: left;
`;

export const CallsTableHeaderSelected = styled.th`
  height: 100%;
  padding: 8px;

  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 3px;
  gap: 40px;

  border-top: 1px solid ${COLOR_NEUTRAL_20};
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};

  ${H4} {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CallsTableRow = styled.tr<{ $selected: boolean }>`
  border-bottom: ${(props) =>
    props.$selected ? `1px solid ${COLOR_PRIMARY_1}` : '1px solid transparent'};

  background: ${(props) => (props.$selected ? COLOR_PRIMARY_2 : 'white')};
  border-radius: 5px;
  margin-bottom: 3px;

  box-sizing: border-box;

  &:hover {
    background: ${(props) =>
      props.$selected ? COLOR_PRIMARY_2 : COLOR_NEUTRAL_10};
  }
`;

interface CallsTableDataProps {
  date?: boolean;
  pagination?: boolean;
  checkbox?: boolean;
}

export const CallsTableData = styled.td<CallsTableDataProps>`
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};
  padding: 16px 5px;

  width: ${(props) =>
    props.date ? '80px' : props.checkbox ? '30px' : '100px'};
  vertical-align: middle;
`;

//calls detailed
export const CallsDetailedContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  height: calc(100% - 80px);
  border-radius: 5px;

  background: ${COLOR_WHITE};
`;

export const CallTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
`;

export const NumberSpan = styled.span`
  margin: 0px 5px;
  font-weight: 600;
  color: ${COLOR_PRIMARY_3};
`;

export const CallBarContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  height: 60px;

  display: flex;
  gap: 6px;
`;

export const CallBarLeftContainer = styled.div`
  height: 100%;
  width: calc(100% - 150px);
  padding: 4px 25px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${COLOR_NEUTRAL_20};
`;

export const BarItem = styled.div`
  min-width: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BarItemTitle = styled.div``;
export const BarItemContent = styled.div`
  height: 35px;
`;

export const CallBarRightContainer = styled.div`
  width: 160px;

  height: 100%;
  border-radius: 5px;
  color: white;

  border: 1px solid #253048;
  cursor: pointer;

  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

//recording
export const CallRecordingContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
`;

export const CallRecordingTopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface DownloadButtonProps {
  disabled?: boolean;
}

export const DownloadButton = styled.div<DownloadButtonProps>`
  padding: 0px 8px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: #cfecfd;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: #f5f7fa;
      border: 1px solid #e4e7eb;
      color: #9aa5b1;
      cursor: not-allowed;
    `}
`;

export const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 800px;
`;

export const PlayButton = styled.button`
  margin-right: 7px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const AudioWave = styled.div`
  position: relative;
  flex-grow: 1;
  height: 9px;
  background-color: transparent;

  cursor: pointer;
`;

export const HorizontalBar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 12px;
  background-color: #e4e7eb;
  transform: translateY(-50%);
  border-radius: 5px;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #c2c7cc; // A darker shade for the progress
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: width 0.3s ease;
`;

export const WaveBar = styled.div<{ completed: boolean }>`
  position: absolute;
  bottom: 50%;
  width: 3px; // Changed from 2px to 3px
  transform: translateY(50%);
  background-color: ${(props) => (props.completed ? '#127FBF' : '#9AA5B1')};
  border-radius: 5px;
  transition: background-color 0.3s ease;
`;

export const HoverTime = styled.div`
  position: absolute;
  top: -7px; // Align to the top of the parent
  transform: translateY(-100%) translateX(-50%); // Move it up by its own height
  background: white;
  padding: 2px 5px;
  border-radius: 6px;

  z-index: 20; // Set a high z-index

  border: 1px solid ${COLOR_NEUTRAL_80};
`;

export const Time = styled.span`
  margin-right: 10px;
`;

// call transcript

export const CallTranscriptContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;

  align-items: center;
`;

export const CallTranscriptTopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TranscriptTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 99px;
`;

export const SearchContainer = styled.div`
  display: flex;
`;

export const TranscriptContainer = styled.div`
  display: flex;

  width: 800px;

  height: calc(100vh - 480px);

  min-height: 300px;
  overflow: auto;

  flex-direction: column;
`;

export const TranscriptRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  gap: 20px;
`;

export const SpeakerContainer = styled.div`
  width: 60px;
  padding: 8px 0px;
`;

interface MessageContainerProps {
  speaker: 'BOT' | 'HUMAN';
}

export const MessageContainer = styled.div<MessageContainerProps>`
  padding: 8px;
  background: ${(props) =>
    props.speaker === 'BOT' ? COLOR_PRIMARY_2 : COLOR_NEUTRAL_10};
  width: 100%;

  border-radius: 5px;

  display: flex;
`;

export const MessageLeftContainer = styled.div`
  width: 35px;
`;
export const MessageRightContainer = styled.div``;

//flyout
export const FlyoutTitle = styled.div`
  height: 80px;
  width: 100%;
  background: ${COLOR_PRIMARY_3};
  color: white;

  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 30px;
  position: relative;
`;

export const FlyoutBody = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  align-items: center;
  padding: 20px;

  border: none;
  border: 1px solid red;
`;

export const BackTwoArrows = styled.img`
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }
`;

interface RecordCallRowProps {
  currentCall?: boolean;
}

export const RecordCallsRow = styled.div<RecordCallRowProps>`
  display: flex;
  padding: 8px 12px;
  margin-bottom: 8px;

  justify-content: space-around;
  align-items: center;

  background: ${(props) => props.currentCall && COLOR_PRIMARY_2};

  gap: 15px;
  border-radius: 5px;

  border-bottom: 1px solid #9aa5b1;

  cursor: ${(props) => (props.currentCall ? '' : 'pointer')};

  &:hover {
    background: ${(props) =>
      props.currentCall ? COLOR_PRIMARY_2 : COLOR_NEUTRAL_10};
  }
`;

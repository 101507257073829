import React from 'react';
import { PaginatedCallList } from '../../../../../generated/tenants/Api';
import {
  CallsTab,
  CallsTableData,
  CallsTableHeader,
  CallsTableHeaderSelected,
  CallsTableRow,
  TableContainer,
} from './Calls.styles';
import {
  COLOR_NEUTRAL_20,
  H4,
  H6,
  HoverUnderline,
} from '../../../../App.style';
import moment from 'moment';
import { ArrowIconLeft } from '../../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../../resources/icons-new/ArrowIconRight';
import {
  PaginationContainer,
  PaginationText,
  ArrowsContainer,
} from '../records/Records.style';
import { ArrowLeftLongIcon } from '../../../../../resources/icons-new/ArrowLeftLongIcon';
import { ArrowRightLongIcon } from '../../../../../resources/icons-new/ArrowRightLongIcon';
import { ArrowRightIcon } from '../../../../../resources/icons-new/ArrowRightIcon';
import Checkbox from '../../../../../common/inputs/Checkbox';
import { useAppDispatch } from '../../../../../common/hooks';
import {
  getCampaignsRecordingsAsyncThunk,
  getCampaignsTranscriptsAsyncThunk,
} from '../../api/campaignsSlice';
import { useTranslation } from 'react-i18next';

export const formatNumber = (number: string) => {
  const numberArr = number.match(/.{1,3}/g);
  return numberArr ? numberArr.join(' ') : '';
};

interface CallsTableProps {
  callsList?: PaginatedCallList;
  selectedCalls: string[];
  setSelectedCalls: React.Dispatch<React.SetStateAction<string[]>>;
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleDetailedClick: (call_id: string) => void;
  campaignId: string;
  hasCampaignAccess: boolean;
}

const CallsTable: React.FC<CallsTableProps> = ({
  callsList,
  selectedCalls,
  setSelectedCalls,
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleDetailedClick,
  campaignId,
  hasCampaignAccess,
}) => {
  const { t } = useTranslation();
  const totalItems = callsList?.count as number;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  //checkboxes
  const toggleRowSelection = (id: string) => {
    setSelectedCalls((prevState) =>
      prevState.includes(id)
        ? prevState.filter((rowId) => rowId !== id)
        : [...prevState, id],
    );
  };

  const toggleSelectAll = () => {
    if (callsList?.results) {
      if (selectedCalls.length === callsList?.results.length) {
        setSelectedCalls([]);
      } else {
        setSelectedCalls(callsList?.results.map((row) => row.id));
      }
    }
  };

  const dispatch = useAppDispatch();

  const handleDownloadSelectedTranscripts = () => {
    if (selectedCalls.length > 0) {
      const data = {
        campaignId: campaignId,
        query: { call_ids: selectedCalls },
      };

      dispatch(getCampaignsTranscriptsAsyncThunk(data)).then(
        (returnedAction) => {
          if (
            getCampaignsTranscriptsAsyncThunk.fulfilled.match(returnedAction)
          ) {
            const audioUrl = returnedAction.payload.campaignsTranscriptUrl;

            const link = document.createElement('a');

            link.href = audioUrl;
            link.download = returnedAction.payload.filename;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);

            setSelectedCalls([]);
          }
        },
      );
    }
  };

  const handleDownloadSelectedRecordings = () => {
    if (selectedCalls.length > 0) {
      const data = {
        campaignId: campaignId,
        query: { call_ids: selectedCalls },
      };

      dispatch(getCampaignsRecordingsAsyncThunk(data)).then(
        (returnedAction) => {
          if (
            getCampaignsRecordingsAsyncThunk.fulfilled.match(returnedAction)
          ) {
            const audioUrl = returnedAction.payload.campaignsRecordingsUrl;

            const link = document.createElement('a');

            link.href = audioUrl;
            link.download = returnedAction.payload.filename;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);

            setSelectedCalls([]);
          }
        },
      );
    }
  };

  return (
    <TableContainer>
      {callsList?.results && (
        <CallsTab>
          <thead>
            <tr
              style={{
                background: selectedCalls.length > 0 ? COLOR_NEUTRAL_20 : '',
              }}
            >
              <CallsTableHeader checkbox onClick={toggleSelectAll}>
                <Checkbox
                  checked={selectedCalls.length === callsList.count}
                  readOnly
                />
              </CallsTableHeader>
              {selectedCalls.length > 0 ? (
                <CallsTableHeaderSelected>
                  <H4 $bold>{t('campaigns.calls.list.selected')}</H4>
                  <H4 onClick={handleDownloadSelectedTranscripts}>
                    {t('campaigns.calls.list.downloadTranscripts')}
                  </H4>
                  <H4 onClick={handleDownloadSelectedRecordings}>
                    {t('campaigns.calls.list.downloadRecordings')}
                  </H4>
                </CallsTableHeaderSelected>
              ) : (
                <>
                  <CallsTableHeader date>
                    <H4 $grey>{t('campaigns.calls.list.date')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('campaigns.calls.list.hour')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('campaigns.calls.list.botNumber')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('campaigns.calls.list.type')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('campaigns.calls.list.customNumber')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('campaigns.calls.list.telcoStatus')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader pagination>
                    <PaginationContainer>
                      <PaginationText>
                        {getPaginationTextContent(
                          currentPage,
                          itemsPerPage,
                          totalItems,
                        )}
                      </PaginationText>

                      <ArrowsContainer>
                        <button
                          disabled={currentPage === 1}
                          onClick={() =>
                            handlePageChange(Math.max(1, currentPage - 1))
                          }
                        >
                          <ArrowIconLeft disabled={currentPage === 1} />
                        </button>

                        <button
                          disabled={currentPage === totalPages}
                          onClick={() =>
                            handlePageChange(
                              Math.min(totalPages, currentPage + 1),
                            )
                          }
                        >
                          <ArrowIconRight
                            disabled={currentPage === totalPages}
                          />
                        </button>
                      </ArrowsContainer>
                    </PaginationContainer>
                  </CallsTableHeader>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {callsList?.results.length === 0 ? (
              <div
                style={{
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {t('campaigns.calls.list.noCalls')}
              </div>
            ) : (
              callsList?.results.map((call) => {
                const formattedDate = moment(call.started_at).format(
                  'DD.MM.YYYY',
                );
                const formattedTime = moment(call.started_at).format(
                  'HH:mm:ss',
                );

                return (
                  <CallsTableRow
                    $selected={selectedCalls.includes(call.id)}
                    key={call.id}
                    onClick={() => handleDetailedClick(call.id)}
                  >
                    <CallsTableData
                      checkbox
                      onClick={(
                        e: React.MouseEvent<
                          HTMLTableDataCellElement,
                          MouseEvent
                        >,
                      ) => {
                        e.stopPropagation();
                        toggleRowSelection(call.id);
                      }}
                    >
                      <Checkbox
                        checked={selectedCalls.includes(call.id)}
                        readOnly
                      />
                    </CallsTableData>
                    <CallsTableData date>
                      <H4>{formattedDate}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{formattedTime}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{formatNumber(call.campaign_phone_number)}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <div style={{ position: 'relative' }}>
                        {call.call_type === 'INBOUND' ? (
                          <ArrowLeftLongIcon />
                        ) : (
                          <ArrowRightLongIcon />
                        )}

                        <H6
                          style={{ position: 'absolute', top: -6, left: 7 }}
                          $grey
                        >
                          {call.call_type?.toLowerCase() === 'inbound'
                            ? t('campaigns.calls.filters.inbound')
                            : t('campaigns.calls.filters.outbound')}
                        </H6>
                      </div>
                    </CallsTableData>

                    <CallsTableData>
                      <H4 $bold>{formatNumber(call.record_phone_number)}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{call.telco_status.toLowerCase()}</H4>
                    </CallsTableData>
                    <CallsTableData pagination>
                      {hasCampaignAccess && (
                        <div
                          style={{
                            marginRight: 10,
                            gap: 2,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <H4>
                            <HoverUnderline>
                              {t('campaigns.calls.list.seeDetails')}
                            </HoverUnderline>
                          </H4>{' '}
                          <ArrowRightIcon />
                        </div>
                      )}
                    </CallsTableData>
                  </CallsTableRow>
                );
              })
            )}
          </tbody>
        </CallsTab>
      )}
    </TableContainer>
  );
};

export default CallsTable;

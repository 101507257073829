import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../app/App.style';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const Icon = styled.svg<{ checked?: boolean }>`
  fill: none;
  stroke: blue;
  stroke-width: 2px;
  transition: stroke-dashoffset 0.7s ease;
  stroke-dasharray: 50;
  stroke-dashoffset: ${({ checked }) => (checked ? '0' : '50')};
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid lightgray;
  border-radius: 4px;
  transition: border-color 0.3s;
  background: white;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.4);
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

interface Props {
  checked: boolean;
  onChange?: () => void;
  readOnly?: boolean;
  text?: string;
}

const Checkbox: React.FC<Props> = ({ checked, onChange, readOnly, text }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {readOnly ? (
        <HiddenCheckbox checked={checked} readOnly />
      ) : (
        <HiddenCheckbox checked={checked} onChange={onChange} />
      )}
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24" checked={checked}>
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {text && <H4>{text}</H4>}
    </div>
  );
};

export default Checkbox;

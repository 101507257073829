import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

//success

export const SuccessPopupContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 400px;
  z-index: 10;
  background: #d6e6f4;
  border: 1px solid #127fbf;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 11px;

  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 1s ease-out
    forwards;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const PopupCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 7px;
  cursor: pointer;
`;

export const PopupContent = styled.div`
  display: flex;
  gap: 8px;
`;

//create campaign error popup

export const ErrorPopupContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 400px;
  z-index: 10;
  background: #ecccb8;
  border: 1px solid #ecccb8;
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 11px;

  animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 1s ease-out
    forwards;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

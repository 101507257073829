import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFieldNumber,
  EuiFieldText,
  EuiFormRow,
  EuiSuperSelect,
  EuiToolTip,
} from '@elastic/eui';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Button, H3, H4 } from '../../../App.style';
import { ApiError } from '../../../../common/types';
import {
  CallingConfiguration,
  CallingConfigurationRequest,
  DynamicTimeBetweenAttemptsRequest,
  RandomizedTimeBetweenAttemptsRequest,
} from '../../../../generated/tenants/Api';
import {
  checkCallingConfigurationCreate,
  checkCallingConfigurationEdit,
  clearCreateCallingConfiguration,
  clearUpdateCallingConfiguration,
  getCallingConfigurationListAsyncThunk,
} from './api/callingConfigurationSlice';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../processes/forms/ProcessForm.style';
import moment, { Moment } from 'moment';
import FormRowTitle from '../../processes/forms/FormRowTitle';
import {
  CustomDatepickerStyle,
  DeleteIntervalIcon,
  DynamicButtonContainer,
  DynamicContainer,
  DynamicContentContainer,
  DynamicTitleContainer,
  RandomizedContainer,
  RandomizedErrorContainer,
} from './CallingPreferences.style';
import MinusInCircleIcon from '../../../../resources/icons-new/MinusInCircleIcon';
import _ from 'lodash';
import { InfoIcon } from '../../../../resources/icons-new/InfoIcon';
import { useTranslation } from 'react-i18next';

const useCallingPreferencesApiErrors = () => {
  const checkCreate = useAppSelector(checkCallingConfigurationCreate);
  const createErrors = checkCreate?.value?.error;

  const checkUpdate = useAppSelector(checkCallingConfigurationEdit);
  const updateErrors = checkUpdate?.value?.error;

  const [callingPrefencesApiErrors, setCallingPreferencesApiErrors] = useState<
    ApiError<CallingConfiguration>
  >({} as ApiError<CallingConfiguration>);

  useEffect(() => {
    if (createErrors)
      setCallingPreferencesApiErrors((prevErrors) => ({
        ...prevErrors,
        ...createErrors,
      }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setCallingPreferencesApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [callingPrefencesApiErrors, setCallingPreferencesApiErrors] as const;
};

interface CallingPreferencesFormProps {
  callingPreference: CallingConfigurationRequest;
  setCallingPreference: React.Dispatch<
    React.SetStateAction<CallingConfigurationRequest>
  >;
  button?: () => void;
  createNew?: boolean;
  backToList: () => void;
  backToDetailed?: () => void;
  handleNewCallingPreferenceChange?: () => void;
  setErrors?: React.Dispatch<React.SetStateAction<boolean>>;
  buttonDown?: boolean;
}

const CallingPreferenceForm: React.FC<CallingPreferencesFormProps> = ({
  callingPreference,
  setCallingPreference,
  button,
  createNew,
  backToList,
  backToDetailed,
  handleNewCallingPreferenceChange,
  setErrors,
  buttonDown,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const checkCreate = useAppSelector(checkCallingConfigurationCreate);

  const checkUpdate = useAppSelector(checkCallingConfigurationEdit);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewCallingPreferenceChange && handleNewCallingPreferenceChange();
      backToList();
      dispatch(
        getCallingConfigurationListAsyncThunk(callingPreference.strategy),
      );
      dispatch(clearCreateCallingConfiguration());
    }

    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      backToDetailed && backToDetailed();
      dispatch(
        getCallingConfigurationListAsyncThunk(callingPreference.strategy),
      );
      dispatch(clearUpdateCallingConfiguration());
    }
  });

  const [callingPreferencesApiErrors, setCallingPreferencesApiErrors] =
    useCallingPreferencesApiErrors();

  //interval modes
  const intervalsOptions = [
    {
      value: 'STATIC',
      inputDisplay: 'Static',
      dropdownDisplay: <H3>{t('presets.callingPreferencesForm.static')}</H3>,
    },
    {
      value: 'DYNAMIC',
      inputDisplay: 'Dynamic',
      dropdownDisplay: <H3>{t('presets.callingPreferencesForm.dynamic')}</H3>,
    },
    {
      value: 'RANDOMIZED',
      inputDisplay: 'Randomized',
      dropdownDisplay: (
        <H3>{t('presets.callingPreferencesForm.randomized')}</H3>
      ),
    },
  ];

  const handleIntervalModeChange = (value: string) => {
    if (value === 'STATIC') {
      setCallingPreference({
        ...callingPreference,
        time_between_attempts_mode: 'STATIC',
        dynamic_time_between_attempts: null,
        randomized_time_between_attempts: null,
        time_between_attempts_seconds: 3600,
      });
      setInvalidIntervals({});
    } else if (value === 'DYNAMIC') {
      const initialDynamicTime: DynamicTimeBetweenAttemptsRequest = {
        interval_start_seconds: 28800,
        interval_end_seconds: 54000,
        time_between_attempts_seconds: 1800,
      };

      setCallingPreference({
        ...callingPreference,
        time_between_attempts_mode: 'DYNAMIC',
        dynamic_time_between_attempts: [initialDynamicTime],
        randomized_time_between_attempts: null,
        time_between_attempts_seconds: 3600,
      });
      setInvalidIntervals({});
    } else if (value === 'RANDOMIZED') {
      const initialRandomizedTime: RandomizedTimeBetweenAttemptsRequest = {
        min_time_between_attempts_seconds: 1800,
        max_time_between_attempts_seconds: 3600,
      };

      setCallingPreference({
        ...callingPreference,
        time_between_attempts_mode: 'RANDOMIZED',
        randomized_time_between_attempts: initialRandomizedTime,
        dynamic_time_between_attempts: null,
        time_between_attempts_seconds: null,
      });
      setInvalidIntervals({});
    } else {
      alert('something went wrong');
    }
  };

  //static
  const timeBetweenAttempts = moment()
    .startOf('day')
    .add(callingPreference.time_between_attempts_seconds, 'seconds');

  const handleTimeBetweenAttemptsChange = (date: Moment | null) => {
    if (date) {
      const seconds = date.diff(moment().startOf('day'), 'seconds');
      setCallingPreference({
        ...callingPreference,
        time_between_attempts_seconds: seconds,
      });
    }
  };

  const addDynamicTime = () => {
    const lastDynamicTime =
      callingPreference.dynamic_time_between_attempts?.[
        callingPreference.dynamic_time_between_attempts.length - 1
      ];
    if (lastDynamicTime) {
      const newStartSeconds = lastDynamicTime.interval_end_seconds; // Add 30 minutes (1800 seconds)
      const newEndSeconds = newStartSeconds + 3600; // Add 1 hour (3600 seconds)

      // Check if the newEndSeconds would go over 86400 seconds (24:00)
      if (newEndSeconds >= 86400) {
        alert(t('presets.callingPreferencesForm.intervalError'));
        return;
      }

      const newDynamicTime = {
        interval_start_seconds: newStartSeconds,
        interval_end_seconds: newEndSeconds,
        time_between_attempts_seconds: 1800, // You can set this to your desired default
      };

      setCallingPreference({
        ...callingPreference,
        dynamic_time_between_attempts: [
          ...(callingPreference.dynamic_time_between_attempts || []),
          newDynamicTime,
        ],
      });
    }
  };

  const removeDynamicTime = (indexToRemove: number) => {
    const newDynamicTimeBetweenAttempts =
      callingPreference.dynamic_time_between_attempts?.filter(
        (_, index) => index !== indexToRemove,
      );
    setCallingPreference({
      ...callingPreference,
      dynamic_time_between_attempts: newDynamicTimeBetweenAttempts,
    });
  };

  const momentToSeconds = (m: moment.Moment) => {
    return m.hour() * 3600 + m.minute() * 60 + m.second();
  };

  const secondsToMoment = (seconds: number) => {
    return moment().startOf('day').add(seconds, 'seconds');
  };

  const handleTimeChange = (
    index: number,
    field: keyof DynamicTimeBetweenAttemptsRequest,
    value: moment.Moment | null,
  ) => {
    if (!value) {
      alert(
        'Unexpected null value occurred while handling time change request',
      );
      return;
    }

    const newDynamicTimeBetweenAttempts = _.cloneDeep(
      callingPreference.dynamic_time_between_attempts || [],
    );

    if (newDynamicTimeBetweenAttempts[index]) {
      newDynamicTimeBetweenAttempts[index][field] = momentToSeconds(value);
    }

    setCallingPreference({
      ...callingPreference,
      dynamic_time_between_attempts: newDynamicTimeBetweenAttempts,
    });
  };

  //randomized
  const handleRandomizedTimeBetweenAttemptsChange = (
    field: 'min' | 'max',
    selectedDate: moment.Moment | null,
  ) => {
    if (selectedDate) {
      const selectedTimeInSeconds = momentToSeconds(selectedDate);

      const newCallingPreference = JSON.parse(
        JSON.stringify(callingPreference),
      );

      if (field === 'min') {
        newCallingPreference.randomized_time_between_attempts.min_time_between_attempts_seconds =
          selectedTimeInSeconds;
      } else if (field === 'max') {
        newCallingPreference.randomized_time_between_attempts.max_time_between_attempts_seconds =
          selectedTimeInSeconds;
      }

      setCallingPreference(newCallingPreference);
    }
  };

  //if maxTime is smaller than endTime returns true
  const isRandomizedIntervalInvalid = Boolean(
    callingPreference.randomized_time_between_attempts
      ?.min_time_between_attempts_seconds &&
      callingPreference.randomized_time_between_attempts
        ?.max_time_between_attempts_seconds &&
      callingPreference.randomized_time_between_attempts
        .min_time_between_attempts_seconds >=
        callingPreference.randomized_time_between_attempts
          .max_time_between_attempts_seconds,
  );

  //testowa walidacja
  const [invalidIntervals, setInvalidIntervals] = useState<
    Record<number, string>
  >({});

  const validateIntervals = () => {
    const intervals = callingPreference.dynamic_time_between_attempts || [];
    const newInvalidIntervals: Record<number, string> = {};

    for (let i = 0; i < intervals.length; i++) {
      const curr = intervals[i];

      // Validate if endTime is smaller than startTime within the same interval
      if (curr.interval_end_seconds <= curr.interval_start_seconds) {
        newInvalidIntervals[i] = t(
          'presets.callingPreferencesForm.endTimeError',
        );
      }

      // Validate for touching or overlapping intervals
      for (let j = i + 1; j < intervals.length; j++) {
        const next = intervals[j];
        if (
          curr.interval_end_seconds > next.interval_start_seconds ||
          next.interval_end_seconds < curr.interval_start_seconds
        ) {
          newInvalidIntervals[i] = t(
            'presets.callingPreferencesForm.periodsCantOverlap',
          );
          newInvalidIntervals[j] = t(
            'presets.callingPreferencesForm.periodsCantOverlap',
          );
        }
      }
    }

    setInvalidIntervals(newInvalidIntervals);
  };

  useEffect(() => {
    validateIntervals();
  }, [callingPreference.dynamic_time_between_attempts]);

  useEffect(() => {
    if (
      isRandomizedIntervalInvalid ||
      Object.keys(invalidIntervals).length > 0
    ) {
      setErrors && setErrors(true);
    } else {
      setErrors && setErrors(false);
    }
  }, [validateIntervals, isRandomizedIntervalInvalid]);

  return (
    <FormContainer
      $processForm={buttonDown ? true : false}
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>{t('presets.callingPreferencesForm.name')}</H3>}
        isInvalid={callingPreferencesApiErrors.name ? true : false}
        error={callingPreferencesApiErrors.name}
      >
        <EuiFieldText
          value={callingPreference.name}
          onChange={(e) => {
            setCallingPreference({
              ...callingPreference,
              name: e.target.value,
            });

            callingPreferencesApiErrors.name &&
              setCallingPreferencesApiErrors({
                ...callingPreferencesApiErrors,
                name: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.callAttempts')}
            tooltip={t('presets.callingPreferencesForm.callAttemptsTooltip')}
          />
        }
        isInvalid={callingPreferencesApiErrors.call_attempts ? true : false}
        error={callingPreferencesApiErrors.call_attempts}
      >
        <EuiFieldNumber
          value={callingPreference.call_attempts}
          onChange={(e) => {
            setCallingPreference({
              ...callingPreference,
              call_attempts: Number(e.target.value),
            });

            callingPreferencesApiErrors.call_attempts &&
              setCallingPreferencesApiErrors({
                ...callingPreferencesApiErrors,
                call_attempts: undefined,
              });
          }}
          min={1}
          max={100}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.dailyCallAttempts')}
            tooltip={t(
              'presets.callingPreferencesForm.dailyCallAttemptsTooltip',
            )}
          />
        }
        isInvalid={
          callingPreferencesApiErrors.call_attempts_per_day ? true : false
        }
        error={callingPreferencesApiErrors.call_attempts_per_day}
      >
        <EuiFieldNumber
          value={callingPreference.call_attempts_per_day || ''}
          onChange={(e) => {
            const inputValue = e.target.value; // Raw input as string
            const value = Number(inputValue); // Convert to number for validation

            // Allow user input freely, but correct the value if it exceeds the maximum
            if (inputValue === '' || value >= 0) {
              let correctedValue = value;

              // If the entered value exceeds the allowed maximum, correct it
              if (value > callingPreference.call_attempts) {
                correctedValue = Math.floor(value / 10); // Remove the last digit
              }

              setCallingPreference({
                ...callingPreference,
                call_attempts_per_day: inputValue === '' ? 0 : correctedValue, // Store 0 if input is cleared
              });

              // Clear API errors if there were any
              if (callingPreferencesApiErrors.call_attempts_per_day) {
                setCallingPreferencesApiErrors({
                  ...callingPreferencesApiErrors,
                  call_attempts_per_day: undefined,
                });
              }
            }
          }}
          min={1}
          max={callingPreference.call_attempts}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.restDays')}
            tooltip={t('presets.callingPreferencesForm.restDaysTooltip')}
          />
        }
        isInvalid={
          callingPreferencesApiErrors.rest_days_after_call_attempts_per_day_reached
            ? true
            : false
        }
        error={
          callingPreferencesApiErrors.rest_days_after_call_attempts_per_day_reached
        }
      >
        <EuiFieldNumber
          value={
            callingPreference.rest_days_after_call_attempts_per_day_reached
          }
          onChange={(e) => {
            setCallingPreference({
              ...callingPreference,
              rest_days_after_call_attempts_per_day_reached: Number(
                e.target.value,
              ),
            });

            callingPreferencesApiErrors.rest_days_after_call_attempts_per_day_reached &&
              setCallingPreferencesApiErrors({
                ...callingPreferencesApiErrors,
                rest_days_after_call_attempts_per_day_reached: undefined,
              });
          }}
          min={0}
          max={100}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.intervalBetweenAttempts')}
            tooltip={t(
              'presets.callingPreferencesForm.intervalBetweenAttemptsTooltip',
            )}
          />
        }
      >
        <EuiSuperSelect
          options={intervalsOptions}
          valueOfSelected={callingPreference.time_between_attempts_mode}
          onChange={handleIntervalModeChange}
        />
      </FormRow>

      <CustomDatepickerStyle />

      {callingPreference.time_between_attempts_mode === 'STATIC' && (
        <FormRow
          label={
            <FormRowTitle
              title={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSeconds',
              )}
              tooltip={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSecondsTooltip',
              )}
            />
          }
          isInvalid={
            callingPreferencesApiErrors.time_between_attempts_seconds
              ? true
              : false
          }
          error={callingPreferencesApiErrors.time_between_attempts_seconds}
        >
          <EuiDatePicker
            selected={timeBetweenAttempts}
            onChange={handleTimeBetweenAttemptsChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat={'HH:mm'}
            timeFormat={'HH:mm'}
            minTime={moment().hours(0).minutes(30)}
            maxTime={moment().hours(4).minutes(0)}
          />
        </FormRow>
      )}

      {callingPreference.time_between_attempts_mode === 'RANDOMIZED' &&
        callingPreference.randomized_time_between_attempts && (
          <RandomizedContainer>
            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.minimal')}</H3>}
              style={{ width: 150 }}
              isInvalid={isRandomizedIntervalInvalid}
            >
              <EuiDatePicker
                isInvalid={isRandomizedIntervalInvalid}
                selected={secondsToMoment(
                  callingPreference.randomized_time_between_attempts
                    .min_time_between_attempts_seconds,
                )}
                onChange={(date) =>
                  handleRandomizedTimeBetweenAttemptsChange('min', date)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </EuiFormRow>

            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.maximal')}</H3>}
              style={{ width: 150, marginTop: -2 }}
              isInvalid={isRandomizedIntervalInvalid}
            >
              <EuiDatePicker
                isInvalid={isRandomizedIntervalInvalid}
                selected={secondsToMoment(
                  callingPreference.randomized_time_between_attempts
                    .max_time_between_attempts_seconds,
                )}
                onChange={(date) =>
                  handleRandomizedTimeBetweenAttemptsChange('max', date)
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </EuiFormRow>

            {isRandomizedIntervalInvalid && (
              <RandomizedErrorContainer>
                <H4 style={{ color: '#BA2525' }}>
                  {t('presets.callingPreferencesForm.minimalError')}
                </H4>
              </RandomizedErrorContainer>
            )}
          </RandomizedContainer>
        )}

      {callingPreference.time_between_attempts_mode === 'DYNAMIC' &&
        callingPreference.dynamic_time_between_attempts && (
          <DynamicContainer>
            <DynamicTitleContainer>
              <H3>{t('presets.callingPreferencesForm.timeRange')}</H3>
              <div style={{ display: 'flex', gap: 8 }}>
                <H3>{`Interval (hh:mm)`}</H3>
                <EuiToolTip
                  position="top"
                  content={
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {t('presets.callingPreferencesForm.timeRangeTooltip')}
                    </div>
                  }
                >
                  <InfoIcon $pointer />
                </EuiToolTip>
              </div>
            </DynamicTitleContainer>
            {callingPreference.dynamic_time_between_attempts.map(
              (interval, index) => (
                <DynamicContentContainer
                  key={`dynamic-time-between-attempts-${index}`}
                >
                  <div style={{ width: 220 }}>
                    <EuiDatePickerRange
                      isInvalid={!!invalidIntervals[index]}
                      startDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_start_seconds,
                          )}
                          onChange={(date) =>
                            handleTimeChange(
                              index,
                              'interval_start_seconds',
                              date,
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      }
                      endDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_end_seconds,
                          )}
                          onChange={(date) =>
                            handleTimeChange(
                              index,
                              'interval_end_seconds',
                              date,
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      }
                    />
                    {invalidIntervals[index] && (
                      <H4
                        style={{
                          color: '#BA2525',
                          marginTop: 5,
                          marginLeft: 2,
                        }}
                      >
                        {invalidIntervals[index]}
                      </H4>
                    )}
                  </div>
                  <div style={{ width: 100 }}>
                    <EuiDatePicker
                      selected={secondsToMoment(
                        interval.time_between_attempts_seconds,
                      )}
                      onChange={(date) =>
                        handleTimeChange(
                          index,
                          'time_between_attempts_seconds',
                          date,
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      minTime={moment().hours(0).minutes(30)}
                      maxTime={moment().hours(4).minutes(0)}
                    />
                  </div>

                  {index > 0 && (
                    <DeleteIntervalIcon>
                      <MinusInCircleIcon
                        $pointer
                        onClick={() => removeDynamicTime(index)}
                      />
                    </DeleteIntervalIcon>
                  )}
                </DynamicContentContainer>
              ),
            )}

            <DynamicButtonContainer>
              <Button
                $size={'S'}
                $styleType="NORMAL"
                type={'button'}
                onClick={addDynamicTime}
              >
                {t('presets.callingPreferencesForm.addPeriod')}
              </Button>
            </DynamicButtonContainer>

            <FormRow
              label={
                <FormRowTitle
                  title={t('presets.callingPreferencesForm.basicInterval')}
                  tooltip={t(
                    'presets.callingPreferencesForm.basicIntervalTooltip',
                  )}
                />
              }
            >
              <EuiDatePicker
                selected={timeBetweenAttempts}
                onChange={handleTimeBetweenAttemptsChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
              />
            </FormRow>
          </DynamicContainer>
        )}

      {createNew && !buttonDown ? (
        <PresetCreateButton>
          <Button type="submit">{t('common.create')}</Button>
        </PresetCreateButton>
      ) : (
        buttonDown && <Button type="submit">{t('common.create')}</Button>
      )}
    </FormContainer>
  );
};

export default CallingPreferenceForm;

import { EuiFieldText, EuiFormRow } from '@elastic/eui';
import React from 'react';
import { H2, H3 } from '../../../../../App.style';

import { VindicationContentContainer } from '../../Vindication.style';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';

interface DialogueAdditionalSettingsProps {
  vindicationDialogue: BotConfigurationRequest;
  updateStrategyConfig: (key: string, newValue: string) => void;
}
const DialogueAdditionalSettings: React.FC<DialogueAdditionalSettingsProps> = ({
  vindicationDialogue,
  updateStrategyConfig,
}) => {
  return (
    <VindicationContentContainer>
      <H2 $selected>Additional settings</H2>
      <H2 style={{ marginTop: 16 }} $medium>
        Ustawienia ASR
      </H2>
      <EuiFormRow
        label={
          <H3>Maksymalna długość nasłuchu (SPEECH RECOGNITION TIMEOUT)</H3>
        }
      >
        <EuiFieldText
          value={
            vindicationDialogue.strategy_config?.[
              'DEFAULT_SPEECH_RECOGNITION_TIMEOUT'
            ] ?? ''
          }
          onChange={(e) =>
            updateStrategyConfig(
              'DEFAULT_SPEECH_RECOGNITION_TIMEOUT',
              e.target.value,
            )
          }
        />
      </EuiFormRow>
      <EuiFormRow
        label={
          <H3>
            Po jakim czasie bot ma uznać że użytkownik przestał mówić? SPEECH
            COMPLETE TIMEOUT
          </H3>
        }
      >
        <EuiFieldText
          value={
            vindicationDialogue.strategy_config?.[
              'DEFAULT_SPEECH_COMPLETE_TIMEOUT'
            ] ?? ''
          }
          onChange={(e) =>
            updateStrategyConfig(
              'DEFAULT_SPEECH_COMPLETE_TIMEOUT',
              e.target.value,
            )
          }
        />
      </EuiFormRow>

      <EuiFormRow
        label={
          <H3>
            Przez jaki czas bot ma nasłuchiwać w przypadku braku inputu (?) NO
            INPUT TIMEOUT
          </H3>
        }
      >
        <EuiFieldText
          value={
            vindicationDialogue.strategy_config?.['DEFAULT_NO_INPUT_TIMEOUT'] ??
            ''
          }
          onChange={(e) =>
            updateStrategyConfig('DEFAULT_NO_INPUT_TIMEOUT', e.target.value)
          }
        />
      </EuiFormRow>
    </VindicationContentContainer>
  );
};

export default DialogueAdditionalSettings;

import styled from 'styled-components';
import { COLOR_PRIMARY_1 } from '../../../../App.style';

export const CampaignOptimizationContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-left: 30px;
`;

export const CampaignOptimizationRow = styled.div`
  padding: 16px 0px;
  border-bottom: 0.3px solid #565656;
  gap: 8px;

  display: flex;
  align-items: center;
`;

export const CampaignOptimizationInfoContainer = styled.div`
  display: flex;
  width: 525px;
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  background: rgba(18, 127, 191, 0.14);
  border-radius: 5px;
  border: 1px solid ${COLOR_PRIMARY_1};
  margin-bottom: 24px;
`;

import {
  DashboardColumn,
  DashboardColumnRequest,
  PatchedDashboardMoveRequest,
  ProcessCreateRequest,
} from '../../../../generated/tenants/Api';

import { ApiError } from '../../../../common/types';
import { Api, Process } from '../../../../generated/tenants/Api';

const { api } = new Api();

//campaign template
export interface CheckProcessResult {
  status: 'success' | 'fail';
  error?: ApiError<Process>;
  id?: string;
}

export interface CheckProcessesPanelResult {
  status: 'success' | 'fail';
  error?: ApiError<DashboardColumn>;
}

//process panel
export const getProcessesPanelColumns = (strategyId: string) =>
  api.strategiesDashboardRetrieve(strategyId).then((_) => _.data);

export const reorderProcessesPanelColumns = (
  strategyId: string,
  data: PatchedDashboardMoveRequest,
) => api.strategiesDashboardPartialUpdate(strategyId, data).then((_) => _.data);

export const createProcessesPanelColumn = (
  data: DashboardColumnRequest,
): Promise<CheckProcessesPanelResult> =>
  api
    .dashboardColumnCreate(data)
    .then(() => {
      return { status: 'success' } as CheckProcessesPanelResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const updateProcessesPanelColumn = (
  id: string,
  data: DashboardColumnRequest,
): Promise<CheckProcessesPanelResult> =>
  api
    .dashboardColumnUpdate(id, data)
    .then(() => {
      return { status: 'success' } as CheckProcessesPanelResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteProcessesesPanelColumn = (
  id: string,
): Promise<CheckProcessesPanelResult> =>
  api
    .dashboardColumnDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckProcessesPanelResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getProcessesList = (
  id: string,
  query?: { is_archived?: boolean },
) => api.strategiesProcessList(id, query).then((_) => _.data);

export const createProcess = (
  data: ProcessCreateRequest,
): Promise<CheckProcessResult> =>
  api
    .processesCreate(data)
    .then((response) => {
      return {
        status: 'success',
        id: response.data.id,
      } as CheckProcessResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const updateProcess = (
  id: string,
  data: ProcessCreateRequest,
): Promise<CheckProcessResult> =>
  api
    .processesUpdate(id, data)
    .then(() => {
      return { status: 'success' } as CheckProcessResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const processDetailedView = (id: string) =>
  api.processesRetrieve(id).then((_) => _.data);

export const deleteProcess = (id: string) =>
  api.processesDestroy(id).then((_) => _.data);

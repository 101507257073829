import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Button, H2, H3, H4 } from '../../../App.style';
import {
  CallingConfiguration,
  ProcessCreateRequest,
} from '../../../../generated/tenants/Api';
import {
  getCallingConfigurationListAsyncThunk,
  selectCallingConfigurationList,
} from '../../presets/callingPreferences/api/callingConfigurationSlice';
import CreateCallingPreference from '../../presets/callingPreferences/CreateCallingPreference';
import { FormContainer, FormRow, PresetsBorder } from './ProcessForm.style';
import {
  getStrategyPhoneNumbersAsyncThunk,
  selectStrategyPhoneNumbersList,
} from '../../settings/adminPanel/components/phoneNumbers/api/phoneNumbersSlice';
import CallingPreferenceFormReadOnly from '../../presets/callingPreferences/CallingPreferenceFormReadOnly';
import FormRowTitle from './FormRowTitle';
import { SuperSelectDark } from '../../../../common/inputs/Inputs.style';
import { useTranslation } from 'react-i18next';

interface CallingPreferencesProps {
  strategy: string;
  process: ProcessCreateRequest;
  setProcess: React.Dispatch<React.SetStateAction<ProcessCreateRequest>>;
  edit?: boolean;
}

const CallingPreferencesForm: React.FC<CallingPreferencesProps> = ({
  strategy,
  process,
  setProcess,
  edit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [callingPreferencePreset, setCallingPreferencePreset] =
    useState<CallingConfiguration>();

  const [newCallingPreferenceCreated, setNewCallingPreferenceCreated] =
    useState<boolean>(false);
  const handleNewCallingPreferenceChange = () => {
    setNewCallingPreferenceCreated((prev) => !prev);
  };

  const apiCallingPreferencesList = useAppSelector(
    selectCallingConfigurationList,
  );

  useEffect(() => {
    if (newCallingPreferenceCreated) {
      setCallingPreferencePreset(
        apiCallingPreferencesList[apiCallingPreferencesList.length - 1],
      );
      handleNewCallingPreferenceChange();
      return;
    } else if (process.calling_configuration !== '') {
      const selected = apiCallingPreferencesList.find(
        (callingPreference) =>
          callingPreference.id === process.calling_configuration,
      );
      if (selected) setCallingPreferencePreset(selected);
    }
  }, [apiCallingPreferencesList]);

  useEffect(() => {
    dispatch(getCallingConfigurationListAsyncThunk(strategy));
    dispatch(getStrategyPhoneNumbersAsyncThunk(strategy));
  }, []);

  const callingPreferencesOptions = [
    ...apiCallingPreferencesList.map((callingPreference) => {
      return {
        value: callingPreference.id,
        inputDisplay: callingPreference.name,
        dropdownDisplay: <H3>{callingPreference.name}</H3>,
      };
    }),
  ];

  useEffect(() => {
    if (callingPreferencePreset)
      setProcess({
        ...process,
        calling_configuration: callingPreferencePreset.id,
      });
  }, [callingPreferencePreset]);

  const [
    isModifyCallingPreferenceVisible,
    setIsModifyCallingPreferenceVisible,
  ] = useState<boolean>(false);

  const closeCallingPrefModal = () =>
    setIsModifyCallingPreferenceVisible(false);
  const showCallingPrefModal = () => setIsModifyCallingPreferenceVisible(true);

  let modifyCallingPrefModal;

  if (isModifyCallingPreferenceVisible && callingPreferencePreset) {
    modifyCallingPrefModal = (
      <EuiModal onClose={closeCallingPrefModal} style={{ width: 600 }}>
        <EuiModalHeader style={{ paddingLeft: 90 }}>
          <H2>{t('processes.form.callingPreferences.newCallingPreference')}</H2>
        </EuiModalHeader>

        <EuiModalBody>
          <CreateCallingPreference
            callingPreference={callingPreferencePreset}
            strategy={strategy}
            backToList={closeCallingPrefModal}
            handleNewCallingPreferenceChange={handleNewCallingPreferenceChange}
            formButtonDown
          />
        </EuiModalBody>
      </EuiModal>
    );
  }

  const apiStrategiesPhoneNumbersList = useAppSelector(
    selectStrategyPhoneNumbersList,
  );

  // State to store selected options in the format needed by EuiComboBox
  const [selectedOptions, setSelectedOptions] = useState<
    EuiComboBoxOptionOption[]
  >([]);

  useEffect(() => {
    // Create selected options from process.phone_numbers
    const selected = process.phone_numbers.map((id) => {
      const phoneNumber = apiStrategiesPhoneNumbersList.find(
        (phone) => phone.id === id,
      );
      return { label: phoneNumber?.phone_number_str || '', key: id };
    });

    setSelectedOptions(selected);
  }, [apiStrategiesPhoneNumbersList, process.phone_numbers]);

  const onPhoneNumberChange = (selectedOptions: EuiComboBoxOptionOption[]) => {
    // Save the whole options, not just the labels (phone numbers)
    const phoneNumbers = selectedOptions.map((option) => option.key || '');
    setProcess({ ...process, phone_numbers: phoneNumbers });
    setSelectedOptions(selectedOptions);
  };

  const phoneNumbersOptions = apiStrategiesPhoneNumbersList.map(
    (phoneNumber) => ({
      label: phoneNumber.phone_number_str,
      key: phoneNumber.id,
    }),
  );

  return (
    <FormContainer $processForm>
      {modifyCallingPrefModal}

      <FormRow
        label={
          <H3 $bold>
            {t('processes.form.callingPreferences.callingPreferencePreset')}
          </H3>
        }
      >
        <SuperSelectDark
          disabled={!edit}
          options={callingPreferencesOptions}
          valueOfSelected={
            callingPreferencePreset ? callingPreferencePreset.id : ''
          }
          placeholder={t('common.select')}
          onChange={(e) => {
            const selectedPreset = apiCallingPreferencesList.find(
              (callingPreference) => callingPreference.id === e,
            );
            if (e !== '') setCallingPreferencePreset(selectedPreset);
          }}
        />
      </FormRow>

      {callingPreferencePreset && (
        <PresetsBorder>
          <CallingPreferenceFormReadOnly
            callingPreference={callingPreferencePreset}
          />

          {edit && (
            <Button
              $styleType="NORMAL"
              $size="S"
              type="button"
              onClick={showCallingPrefModal}
            >
              {t('common.modify')}
            </Button>
          )}
        </PresetsBorder>
      )}

      <FormRow
        label={
          <FormRowTitle
            title={t('processes.form.callingPreferences.phoneNumbers')}
            tooltip={t('processes.form.callingPreferences.phoneNumbersTooltip')}
          />
        }
        style={{ marginTop: 50 }}
        isInvalid={process.phone_numbers?.length === 0}
        error={t('processes.form.callingPreferences.atLeast')}
        helpText={
          <div style={{ display: 'flex', gap: 4 }}>
            {process.phone_numbers.length > 1 && (
              <>
                <EuiIcon
                  style={{ color: '#3E4C59' }}
                  type="iInCircle"
                  color={'subdued'}
                />

                <H4 $wrap $grey>
                  {t('processes.form.callingPreferences.note')}
                </H4>
              </>
            )}
          </div>
        }
      >
        <EuiComboBox
          isInvalid={process.phone_numbers?.length === 0}
          aria-label="phone-numbers"
          placeholder={t('common.select')}
          options={phoneNumbersOptions}
          selectedOptions={selectedOptions}
          onChange={onPhoneNumberChange}
          isDisabled={!edit}
          isClearable={true}
        />
      </FormRow>
    </FormContainer>
  );
};

export default CallingPreferencesForm;

import { EuiSuperSelect } from '@elastic/eui';
import { Strategy, UserTenant } from '../../../../generated/tenants/Api';
import { H4, H5, H6 } from '../../../App.style';
import { UserRoleContainer } from '../Users.style';
import { useTranslation } from 'react-i18next';

export const getUserInitials = (
  first_name?: string,
  last_name?: string,
): string =>
  `${first_name ? first_name[0] : ''}${last_name ? last_name[0] : ''}`;

// user role
export type UserRole = 'tenant_owner' | 'tenant_admin' | 'user';

export const getUserRolesOptions = (
  ownerText: string,
  adminText: string,
  userText: string,
) => [
  {
    value: 'tenant_owner',
    inputDisplay: <H5>{ownerText}</H5>,
    dropdownDisplay: <H5>{ownerText}</H5>,
  },
  {
    value: 'tenant_admin',
    inputDisplay: <H5>{adminText}</H5>,
    dropdownDisplay: <H5>{adminText}</H5>,
  },
  {
    value: 'user',
    inputDisplay: <H5>{userText}</H5>,
    dropdownDisplay: <H5>{userText}</H5>,
  },
];

export const getInitialRole = (user: UserTenant): UserRole => {
  if (user.is_tenant_owner) return 'tenant_owner';
  if (user.is_tenant_admin) return 'tenant_admin';
  return 'user';
};

export const displayUserRole = (
  user: UserTenant,
  ownerText: string,
  adminText: string,
  userText: string,
) => {
  if (user.is_tenant_owner) {
    return (
      <UserRoleContainer role="owner">
        <H6 style={{ color: 'white' }} $bold>
          {ownerText}
        </H6>
      </UserRoleContainer>
    );
  } else if (user.is_tenant_admin) {
    return (
      <UserRoleContainer role="admin">
        <H6 $bold>{adminText}</H6>
      </UserRoleContainer>
    );
  } else {
    return (
      <UserRoleContainer role="user">
        <H6 $bold>{userText}</H6>
      </UserRoleContainer>
    );
  }
};

//user permissions
export type PermissionLevel =
  | 'strategy'
  | 'process_campaign_write'
  | 'campaign_write'
  | 'process_write'
  | 'read_access'
  | 'no_permissions';

export type PermissionHierarchy = {
  [key in PermissionLevel]: string[];
};

//changes the PermissionLevel to permissions array (for backend)
export const permissionHierarchy: PermissionHierarchy = {
  strategy: ['read_access', 'process_write', 'campaign_write', 'strategy'],
  process_campaign_write: ['read_access', 'process_write', 'campaign_write'],
  campaign_write: ['read_access', 'campaign_write'],
  process_write: ['read_access', 'process_write'],
  read_access: ['read_access'],
  no_permissions: [],
};

export const getStrategyPermissionsOptions = (
  no_permissions: string,
  read_access: string,
  process_write: string,
  campaign_write: string,
  process_campaign_write: string,
  workspace: string,
) => [
  {
    value: 'no_permissions',
    inputDisplay: no_permissions,
  },
  {
    value: 'read_access',
    inputDisplay: read_access,
  },
  {
    value: 'process_write',
    inputDisplay: process_write,
  },
  {
    value: 'campaign_write',
    inputDisplay: campaign_write,
  },
  {
    value: 'process_campaign_write',
    inputDisplay: process_campaign_write,
  },
  { value: 'strategy', inputDisplay: workspace },
];

export const mapPermissionToData = (permissions: string[]) => {
  return {
    read_access: permissions.includes('read_access'),
    process_write: permissions.includes('process_write'),
    campaign_write: permissions.includes('campaign_write'),
    strategy: permissions.includes('strategy'),
  };
};

const permissionMapping: { [key in PermissionLevel]: string } = {
  strategy: 'workspace',
  process_campaign_write: 'process, campaign',
  campaign_write: 'campaign',
  process_write: 'process',
  read_access: 'view only',
  no_permissions: 'no permission',
};

//permission display name
export const mapPermissionToString = (
  permissionLevel: PermissionLevel,
): string => {
  return permissionMapping[permissionLevel];
};

//old
export const getPermissionBooleans = (selectedPermission: PermissionLevel) => {
  switch (selectedPermission) {
    case 'no_permissions':
      return {
        read_access: false,
        process_write: false,
        campaign_write: false,
      };
    case 'read_access':
      return {
        read_access: true,
        process_write: false,
        campaign_write: false,
      };
    case 'process_write':
      return {
        read_access: true,
        process_write: true,
        campaign_write: false,
      };
    case 'campaign_write':
      return {
        read_access: true,
        process_write: false,
        campaign_write: true,
      };
    case 'process_campaign_write':
      return {
        read_access: true,
        process_write: true,
        campaign_write: true,
      };
    case 'strategy':
      return {
        read_access: true,
        process_write: true,
        campaign_write: true,
        strategy: true,
      };
    default:
      throw new Error('Invalid permission type');
  }
};

interface StrategyPermissionDisplayProps {
  strategy: Strategy;
  currentPermission: PermissionLevel;
  isEditing: boolean;
  handlePermissionChange: (
    strategyId: string,
    newPermission: PermissionLevel,
  ) => void;
}

export const StrategyPermissionDisplay: React.FC<
  StrategyPermissionDisplayProps
> = ({ strategy, currentPermission, isEditing, handlePermissionChange }) => {
  const { t } = useTranslation();

  const strategyPermissionsOptions = getStrategyPermissionsOptions(
    t('common.permissions.no_permissions'),
    t('common.permissions.read_only'),
    t('common.permissions.process_write'),
    t('common.permissions.campaign_write'),
    t('common.permissions.process_campaign_write'),
    t('common.permissions.workspace'),
  );
  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        justifyContent: isEditing ? 'space-between' : '',
        marginRight: isEditing ? '10px' : '0px',
      }}
    >
      <H4>{strategy.name}</H4>
      {isEditing ? (
        <EuiSuperSelect
          style={{ width: 180 }}
          options={strategyPermissionsOptions}
          valueOfSelected={currentPermission}
          compressed
          onChange={(value) =>
            handlePermissionChange(strategy.id, value as PermissionLevel)
          }
        />
      ) : (
        <H4 $bold>{mapPermissionToString(currentPermission)}</H4>
      )}
    </div>
  );
};

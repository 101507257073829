import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const LektaLogoIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="23"
    viewBox="0 0 68 23"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M58.369 18.5458C57.2536 18.5458 56.7727 18.0538 56.6356 17.0642H56.5428C56.1128 18.2305 54.7685 18.7829 53.3204 18.7829C51.2068 18.7829 49.9742 17.5953 49.9742 15.7156C49.9742 13.9265 51.1261 12.6461 53.484 12.4605C53.557 12.4549 56.4555 12.2872 56.4555 12.2872V11.737C56.4555 10.157 55.7612 9.57552 54.2346 9.57552C53.7316 9.57552 53.3071 9.64709 52.9081 9.91658C52.677 10.0785 52.4898 10.2963 52.3635 10.5503C52.2373 10.8042 52.1759 11.0861 52.1851 11.3702H50.4307C50.4119 10.441 50.6983 9.69293 51.4787 8.99964C52.1342 8.41706 53.0783 8.05699 54.2987 8.05699C56.8236 8.05699 58.2706 9.36306 58.2706 11.5794V16.9825H60.267V18.548L58.369 18.5458ZM56.451 15.1353V13.6816L53.7493 13.8516C52.4537 13.9533 51.87 14.5784 51.87 15.4171V15.8129C51.87 16.7634 52.5941 17.2979 53.7294 17.2979C55.1554 17.299 56.451 16.1595 56.451 15.1353Z" />
    <path d="M2.4298e-07 20.0998V2.54393C2.4298e-07 1.86924 0.264962 1.22218 0.736598 0.745099C1.20823 0.26802 1.84791 0 2.5149 0H5.12488V1.54872H1.78309V21.0995H5.12488V22.6449H2.5149C2.18455 22.6449 1.85743 22.579 1.55224 22.4511C1.24704 22.3232 0.969752 22.1357 0.736207 21.8994C0.502663 21.663 0.317439 21.3825 0.191118 21.0737C0.0647975 20.7649 -0.000144968 20.434 2.4298e-07 20.0998Z" />
    <path d="M9.24821 15.8878V4.04821H11.1463V16.8428H17.8298V18.5458H11.8759C11.179 18.5458 10.5106 18.2658 10.0178 17.7673C9.52505 17.2688 9.24821 16.5928 9.24821 15.8878Z" />
    <path d="M19.0215 13.43C19.0215 10.1771 20.8024 8.05587 23.6202 8.05587C26.4568 8.05587 28.0619 10.2375 28.0619 13.1135V13.998H20.9196V14.3033C20.9196 16.0086 21.9565 17.2386 23.7761 17.2386C24.9744 17.2386 25.8422 16.5878 26.0898 15.4573H27.8585C27.3865 17.8682 25.5437 18.8052 23.6169 18.8052C20.8024 18.8052 19.0215 16.6829 19.0215 13.43ZM20.9196 12.4516V12.5902H26.1252V12.3923C26.1252 10.6904 25.1468 9.54197 23.6202 9.54197C22.035 9.54421 20.9196 10.7675 20.9196 12.4516Z" />
    <path d="M30.6067 4.04821H32.4262V13.023H32.5854L34.2127 11.2215L36.8536 8.29182H39.0059L35.4065 12.2503L39.4757 18.5447H37.2814L34.1662 13.5262L32.4262 15.4383V18.5458H30.6067V4.04821Z" />
    <path d="M48.0817 9.85623V8.29074H44.5133V4.76838H42.8694V7.34026C42.8696 7.46512 42.8454 7.58878 42.7982 7.70417C42.751 7.81955 42.6818 7.92439 42.5946 8.01268C42.5073 8.10097 42.4036 8.17097 42.2896 8.21869C42.1755 8.2664 42.0532 8.29088 41.9298 8.29074H40.5248V9.85623H42.6937V15.9628C42.6937 16.6477 42.9626 17.3045 43.4412 17.7889C43.9199 18.2733 44.5691 18.5455 45.2462 18.5458H48.0817V16.9803H44.5133V9.85623H48.0817Z" />
    <path d="M65.4052 22.6452H62.7952V21.0998H66.137V1.54903H62.7952V0.00478363H65.4052C66.0722 0.00478363 66.7119 0.272803 67.1835 0.749882C67.6551 1.22696 67.9201 1.87402 67.9201 2.54871V20.1046C67.9192 20.7787 67.6539 21.4249 67.1823 21.9013C66.7108 22.3776 66.0716 22.6452 65.4052 22.6452Z" />
  </StyledSvg>
);

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, H3 } from '../../../App.style';
import {
  PanelSidebarContainer,
  SidebarLinkContainer,
  SidebarSectionContainer,
  SubLinksContainer,
  TenantSlugSidebarContainer,
} from '../Settings.style';
import { ChevronDownIcon } from '../../../../resources/icons-new/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../resources/icons-new/ChevronUpIcon';
import { useTranslation } from 'react-i18next';
import { LektaLogoIcon } from '../../../../resources/icons-new/LektaLogoIcon';
import {
  SidebarLogoContainer,
  SidebarLektaLogoFont,
} from '../../sidebar/Sidebar.style';
import { useAppSelector } from '../../../../common/hooks';
import { selectConfig } from '../../session/api/sessionSlice';
import { ArrowLeftIcon } from '../../../../resources/icons-new/ArrowLeftIcon';

const AdminPanelSidebar = () => {
  const { t } = useTranslation();
  const [openSections, setOpenSections] = useState<number[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const links = [
    {
      label: t('settings.adminPanel.sidebar.users'),
      path: '/tenant_panel/users',
      subLinks: [],
    },
    {
      label: t('settings.adminPanel.sidebar.numbers'),
      path: '/tenant_panel/*',
      subLinks: [
        {
          path: '/tenant_panel/phone_numbers',
          label: t('settings.adminPanel.sidebar.assignTo'),
        },
        {
          path: '/tenant_panel/numbers_history',
          label: t('settings.adminPanel.sidebar.history'),
        },
      ],
    },
    {
      label: t('settings.adminPanel.sidebar.sftp'),
      path: '/tenant_panel/sftp_settings',
      subLinks: [],
    },
    {
      label: t('settings.adminPanel.sidebar.manageWorkspaces'),
      path: '/tenant_panel/manage_strategies',
      subLinks: [],
    },
    {
      label: t('settings.users.sidebar.accessTokens'),
      path: '/tenant_panel/access_tokens',
      subLinks: [],
    },
  ];

  const toggleSection = (index: number, path: string | null) => {
    if (path && links[index].subLinks.length === 0) {
      navigate(path); // navigate to the path using navigate
      return;
    }

    setOpenSections((prevOpenSections) => {
      const isSectionOpen = prevOpenSections.includes(index);
      return isSectionOpen
        ? prevOpenSections.filter((sectionIndex) => sectionIndex !== index)
        : [...prevOpenSections, index];
    });
  };

  useEffect(() => {
    const newOpenSections: number[] = [];
    links.forEach((section, index) => {
      const isSubLinkMatch = section.subLinks.some(
        (subLink) => subLink.path === location.pathname,
      );
      if (isSubLinkMatch) {
        newOpenSections.push(index);
      }
    });
    setOpenSections(newOpenSections);
  }, [location.pathname]);

  const handleGoToDashboard = () => {
    navigate('/dashboard/');
  };

  const config = useAppSelector(selectConfig);
  const tenant = config.tenantSubdomain;

  return (
    <PanelSidebarContainer>
      <SidebarLogoContainer onClick={handleGoToDashboard}>
        <LektaLogoIcon color="#000000" />
        <SidebarLektaLogoFont>Lease</SidebarLektaLogoFont>
      </SidebarLogoContainer>

      <TenantSlugSidebarContainer>
        <H3 $semibold>{tenant}</H3>

        <Button
          style={{ width: '100%', height: 33 }}
          $size="S"
          $styleType="NORMAL"
          onClick={handleGoToDashboard}
        >
          <ArrowLeftIcon />
          {t('navbar.backToApp')}
        </Button>
      </TenantSlugSidebarContainer>

      {links.map((section, index) => (
        <div key={index} style={{ width: '90%', marginBottom: 8 }}>
          <SidebarSectionContainer
            $selected={location.pathname === section.path}
            onClick={() => toggleSection(index, section.path)}
            $lightHover
          >
            <H3>{section.label}</H3>
            {section.subLinks.length > 0 &&
              (openSections.includes(index) ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              ))}
          </SidebarSectionContainer>
          {openSections.includes(index) && section.subLinks.length > 0 && (
            <SubLinksContainer>
              {section.subLinks.map((link) => (
                <SidebarLinkContainer
                  key={link.path}
                  $selected={location.pathname === link.path}
                  to={link.path}
                  $lightHover
                >
                  {link.label}
                </SidebarLinkContainer>
              ))}
            </SubLinksContainer>
          )}
        </div>
      ))}
    </PanelSidebarContainer>
  );
};

export default AdminPanelSidebar;

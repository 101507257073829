import {
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiConfirmModal,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { BotConfiguration } from '../../../../generated/tenants/Api';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkDialogueDelete,
  clearCreateDialogue,
  clearUpdateDialogue,
  deleteDialogueAsyncThunk,
  getDialoguesListAsyncThunk,
  selectDialoguesList,
} from '../../presets/dialogues/api/dialoguesSlice';

const BotsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleteBotModalVisible, setIsDeleteBotModalVisible] =
    useState<boolean>(false);
  const strategy = useAppSelector(selectStrategyId);

  //bots list logic
  useEffect(() => {
    dispatch(getDialoguesListAsyncThunk(strategy));
  }, [isDeleteBotModalVisible, strategy]);
  const botsConfigList = useAppSelector(selectDialoguesList);

  //buttons
  const handleEditClick = (id: string, template: string) => {
    if (template === 'DEBTOR') {
      localStorage.setItem('botConfigurationType', 'edit');
      navigate(`/dashboard/bots/edit/${id}`);
    } else if (template === 'CUSTOM') {
      navigate(`/dashboard/bots/custom/${id}`);
    }
  };
  const handleDeleteClick = (bot: BotConfiguration) => {
    setDeleteBotConfiguration({ name: bot.name, id: bot.id });
    setIsDeleteBotModalVisible(true);
  };

  //after confirming delete
  const handleConfirmDeleteClick = () => {
    dispatch(deleteDialogueAsyncThunk(deleteBotConfiguration.id));
  };

  //checks if bot was deleted succesfully
  const checkDeleteBot = useAppSelector(checkDialogueDelete);
  useEffect(() => {
    if (checkDeleteBot.value?.status === 'success') {
      setIsDeleteBotModalVisible(false);
    }
  }, [checkDeleteBot]);

  //id and name provided to delete modal
  const [deleteBotConfiguration, setDeleteBotConfiguration] = useState<{
    name: string;
    id: string;
  }>({ name: '', id: '' });

  //delete modal
  let deleteModal;

  if (isDeleteBotModalVisible) {
    deleteModal = (
      <EuiConfirmModal
        title="Deleting bot configuration"
        onCancel={() => setIsDeleteBotModalVisible(false)}
        onConfirm={handleConfirmDeleteClick}
        cancelButtonText="No, don't do it"
        confirmButtonText="Yes, do it"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>
          You're about to delete Bot Configuration with name{' '}
          <b>{deleteBotConfiguration.name}</b>
        </p>
        <p>Are you sure you want to do this?</p>
      </EuiConfirmModal>
    );
  }

  //clears the state
  useEffect(() => {
    dispatch(clearCreateDialogue());
    dispatch(clearUpdateDialogue());
  }, []);
  return (
    <EuiFlexGroup
      css={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {deleteModal}
      {!botsConfigList.length ? (
        <EuiFlexItem css={{ alignItems: 'center' }}>
          <EuiText css={{ fontSize: 25 }}>No bots available</EuiText>
        </EuiFlexItem>
      ) : (
        <EuiFlexGroup
          css={{
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {botsConfigList.map((bot, i) => {
            return (
              <EuiFlexItem css={{ width: 250, maxWidth: 250 }} key={i}>
                <EuiCard
                  css={{ width: 250 }}
                  icon={<EuiIcon size="xxl" type="reporter" />}
                  title={bot.name}
                  description={bot.template}
                  footer={
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'row',
                        padding: 5,
                        paddingBottom: 10,
                        justifyContent: 'space-between',
                      }}
                    >
                      <EuiButton
                        onClick={() => handleEditClick(bot.id, bot.template)}
                        size="s"
                        fill
                      >
                        Edit
                      </EuiButton>
                      <EuiButton
                        onClick={() => handleDeleteClick(bot)}
                        size="s"
                        fill
                        color="danger"
                      >
                        Delete
                      </EuiButton>
                    </EuiFlexGroup>
                  }
                />
              </EuiFlexItem>
            );
          })}
        </EuiFlexGroup>
      )}
    </EuiFlexGroup>
  );
};

export default BotsList;

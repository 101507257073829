import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { ProcessCreateRequest } from '../../../generated/tenants/Api';
import {
  checkProcessUpdate,
  endUpdateProcess,
  processDetailedViewAsyncThunk,
  selectProcessDetailedView,
  updateProcessAsyncThunk,
} from './api/processSlice';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import ProcessForm from './ProcessForm';
import usePermissions from '../users/usePermissions';
import LoadingModal from '../../../common/modals/LoadingModal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProcessDetailed = () => {
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const editOnStart = query.get('edit') === 'true';

  const { checkPermission } = usePermissions();
  const hasProcessAccess = checkPermission('process');

  const [process, setProcess] = useState<ProcessCreateRequest>({
    strategy: strategy,
    name: '',
    calling_configuration: '', //calling configuratoin id
    voice_configuration: '', //voice config id
    call_center_non_working_days_configuration: '',
    campaign_non_working_days_configuration: '',
    calendar_configuration: '',
    bot_configuration: '',
    recordings_format: 'mp3',
    labels: [],
    guaranteed_lines: 1,
    redirection_phone_number: '',
    delete_data_after_report: false,
    phone_numbers: [],
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) dispatch(processDetailedViewAsyncThunk(id));
  }, []);

  const apiProcessDetailed = useAppSelector(selectProcessDetailedView);

  useEffect(() => {
    if (apiProcessDetailed) {
      const labelIds = apiProcessDetailed.labels.map((label) => label.id);
      const phoneNumberIds = apiProcessDetailed.phone_numbers.map(
        (number) => number.id,
      );
      const processDetailed = {
        ...apiProcessDetailed,
        report_per_call_custom_faas:
          apiProcessDetailed.report_per_call_custom_faas
            ? apiProcessDetailed.report_per_call_custom_faas.id
            : '',
        report_per_record_custom_faas:
          apiProcessDetailed.report_per_record_custom_faas
            ? apiProcessDetailed.report_per_record_custom_faas.id
            : '',
        report_per_campaign_custom_faas:
          apiProcessDetailed.report_per_campaign_custom_faas
            ? apiProcessDetailed.report_per_campaign_custom_faas.id
            : '',
        get_customer_info_faas: apiProcessDetailed.get_customer_info_faas
          ? apiProcessDetailed.get_customer_info_faas.id
          : '',
        report_feedback_faas: apiProcessDetailed.report_feedback_faas
          ? apiProcessDetailed.report_feedback_faas.id
          : '',

        labels: labelIds,
        phone_numbers: phoneNumberIds,
      };

      setProcess(processDetailed);
    }
  }, [apiProcessDetailed]);

  let pathname = window.location.pathname;

  if (pathname[0] === '/') pathname = pathname.slice(1);

  const editClick = () => {
    if (apiProcessDetailed) {
      setIsLoadingVisible(true);
      const args = { id: apiProcessDetailed.id, data: process };

      dispatch(updateProcessAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const handleCancelClick = () => {
    if (apiProcessDetailed) {
      const labelIds = apiProcessDetailed.labels.map((label) => label.id);
      const phoneNumberIds = apiProcessDetailed.phone_numbers.map(
        (number) => number.id,
      );
      const processDetailed = {
        ...apiProcessDetailed,
        report_per_call_custom_faas:
          apiProcessDetailed.report_per_call_custom_faas
            ? apiProcessDetailed.report_per_call_custom_faas.id
            : '',
        report_per_record_custom_faas:
          apiProcessDetailed.report_per_record_custom_faas
            ? apiProcessDetailed.report_per_record_custom_faas.id
            : '',
        report_per_campaign_custom_faas:
          apiProcessDetailed.report_per_campaign_custom_faas
            ? apiProcessDetailed.report_per_campaign_custom_faas.id
            : '',
        get_customer_info_faas: apiProcessDetailed.get_customer_info_faas
          ? apiProcessDetailed.get_customer_info_faas.id
          : '',
        report_feedback_faas: apiProcessDetailed.report_feedback_faas
          ? apiProcessDetailed.report_feedback_faas.id
          : '',

        labels: labelIds,
        phone_numbers: phoneNumberIds,
      };

      setProcess(processDetailed);
      dispatch(endUpdateProcess());
    }
  };

  const checkUpdate = useAppSelector(checkProcessUpdate);

  useEffect(() => {
    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      if (id) {
        dispatch(processDetailedViewAsyncThunk(id));
        dispatch(endUpdateProcess());
        handleCancelClick();
        setEdit(false);
      }
    }
  }, [checkUpdate]);

  const [edit, setEdit] = useState<boolean>();

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {process && (
        <ProcessForm
          process={process}
          setProcess={setProcess}
          button={editClick}
          cancelButton={handleCancelClick}
          detailed={true}
          editOnStart={editOnStart}
          edit={edit}
          handleStartEdit={() => setEdit(true)}
          handleStopEdit={() => setEdit(false)}
          hasProcessAcceess={hasProcessAccess}
        />
      )}
    </>
  );
};

export default ProcessDetailed;

import React, { useState } from 'react';
import {
  BotConfigurationRequest,
  CustomFaas,
} from '../../../../../generated/tenants/Api';
import { CreatePresetContainer } from '../../PresetsPanel.style';
import { useAppDispatch } from '../../../../../common/hooks';
import CustomDialogueForm from './CustomDialogueForm';
import { createDialogueAsyncThunk } from '../api/dialoguesSlice';
import LoadingModal from '../../../../../common/modals/LoadingModal';

interface CreateCustomDialogueProps {
  strategy: string;
  backToList: () => void;
  handleNewDialogueChange?: () => void;
  customFaasesList: CustomFaas[];
}

const CreateCustomDialogue: React.FC<CreateCustomDialogueProps> = ({
  strategy,
  backToList,
  customFaasesList,
}) => {
  const dispatch = useAppDispatch();
  const [customDialogue, setCustomDialogue] = useState<BotConfigurationRequest>(
    {
      name: '',
      image_tag: '',
      template: 'DEBTOR',
      is_custom: true,
      language: 'pl-PL',
      strategy: strategy,
      runtime_parameters: {},
      record_close_rules: {},
      custom_label: 'custom',
      is_draft: false,
    },
  );

  const handleAddCustomDialogue = () => {
    setIsLoadingVisible(true);
    dispatch(createDialogueAsyncThunk(customDialogue)).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <CreatePresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <CustomDialogueForm
        customDialogue={customDialogue}
        setCustomDialogue={setCustomDialogue}
        backToList={backToList}
        button={handleAddCustomDialogue}
        createNew
        customFaasesList={customFaasesList}
      />
    </CreatePresetContainer>
  );
};

export default CreateCustomDialogue;

import { ApiError, ApiErrorExtended } from '../../../../../common/types';
import {
  AdminInvitation,
  AdminInvitationRequest,
  Api,
  BillingRaportRequest,
  Company,
  CompanyCreateRequest,
  CompanyPrivate,
  InvitationUpdateRequest,
  PatchedAdminRoleUpdateRequest,
  Api as PublicApi,
  UserPublic,
} from '../../../../../generated/public/Api';

const { api } = new Api();

const { private: apiPrivate } = new PublicApi();

export const getTenantsList = () =>
  apiPrivate.privateApiCompaniesList().then((_) => _.data);

export interface CheckTenantCreateResult {
  status: 'success' | 'fail';
  error?: ApiError<CompanyPrivate>;
}

export const createTenant = (
  data: CompanyCreateRequest,
): Promise<CheckTenantCreateResult> =>
  apiPrivate
    .privateApiCompaniesCreate(data)
    .then(() => {
      return {
        status: 'success',
      } as CheckTenantCreateResult;
    })
    .catch((res) => {
      return {
        status: 'fail',
        error: res.error,
      };
    });

export interface CheckTenantUpdateResult {
  status: 'success' | 'fail';
  error?: ApiErrorExtended<Company>;
}

export const updateTenant = (
  id: string,
  data: CompanyCreateRequest,
): Promise<CheckTenantUpdateResult> =>
  apiPrivate
    .privateApiCompaniesUpdate(id, data)
    .then(() => {
      return {
        status: 'success',
      } as CheckTenantUpdateResult;
    })
    .catch((res) => {
      return {
        status: 'fail',
        error: res.error,
      };
    });

export const deleteTenant = (id: string) =>
  apiPrivate.privateApiCompaniesDestroy(id).then((_) => _.data);

export const getSmsConfigurationList = () =>
  apiPrivate.privateApiSmsConfigurationList().then((_) => _.data);

//superusers
export const getSuperUsersList = (query?: {
  filter_info?: string;
  limit?: number;
  offset?: number;
}) => api.adminUsersList(query).then((_) => _.data);

export const updateSuperUserPermissions = (
  userId: string,
  data: PatchedAdminRoleUpdateRequest,
) =>
  api.adminUsersAdminPermissionsPartialUpdate(userId, data).then((_) => _.data);

export interface CheckInviteSuperUserResult {
  status: 'success' | 'fail';
  error?: ApiError<AdminInvitation>;
}

export const inviteSuperUser = (
  data: AdminInvitationRequest,
): Promise<CheckInviteSuperUserResult> =>
  api
    .adminInvitationsCreate(data)
    .then(() => {
      return { status: 'success' } as CheckInviteSuperUserResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export interface CheckInvitationResult {
  status: 'success' | 'fail' | 'needInfo';
  value?: UserPublic;
}

export const checkSuperUserToken = (
  token: string,
): Promise<CheckInvitationResult> =>
  api.adminInvitationsRetrieve(token).then((response) => {
    if (response.status === 204) {
      return { status: 'needInfo' };
    } else if (response.status === 200) {
      return {
        status: 'success',
        user: response.data,
      };
    } else {
      return { status: 'fail' };
    }
  });

export interface CheckAddSuperUserInvitationResult {
  status: 'success' | 'fail';
  error?: ApiError<{ password: 'string' }>;
}

export const createSuperUserAfterInvitation = (
  token: string,
  data: InvitationUpdateRequest,
): Promise<CheckAddSuperUserInvitationResult> =>
  api
    .adminInvitationsUpdate(token, data)
    .then(() => {
      return { status: 'success' } as CheckAddSuperUserInvitationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getBillingProcesses = () =>
  apiPrivate.privateApiBillingsProcessListRetrieve().then((_) => _.data);

export const getBillingsCampaignList = (query: {
  campaign_id?: string;
  end_date?: string;
  process_id: string;
  start_date?: string;
  tenant_id: string;
  time?: 'CUSTOM' | 'LAST_7_DAYS' | 'LAST_MONTH' | 'THIS_MONTH' | 'TODAY';
  limit?: number;
  offset?: number;
}) => apiPrivate.privateApiBillingsCampaignListList(query).then((_) => _.data);

export const getBillingsRaport = (data: BillingRaportRequest) =>
  apiPrivate
    .privateApiBillingsRaportCreate(data, { format: 'blob' })
    .then((res) => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition
        ? contentDisposition.match(/filename="([^"]+)"/)
        : null;
      const filename = filenameMatch
        ? filenameMatch[1]
        : 'default_filename.extension';
      const blob = res.data;
      const url = URL.createObjectURL(blob);
      return { url, filename };
    });

import { EuiFlyout, EuiFlyoutBody } from '@elastic/eui';
import React from 'react';
import { Call } from '../../../../../../generated/tenants/Api';
import { FlyoutTitle, RecordCallsRow } from '../Calls.styles';

import { COLOR_WHITE, H3, H4, H6 } from '../../../../../App.style';
import './flyout.css';
import moment from 'moment';
import { formatNumber } from '../CallsTable';
import { ArrowLeftLongIcon } from '../../../../../../resources/icons-new/ArrowLeftLongIcon';
import { ArrowRightLongIcon } from '../../../../../../resources/icons-new/ArrowRightLongIcon';
import { TwoChevronsRightIcon } from '../../../../../../resources/icons-new/TwoChevronsRightIcon';
import { useTranslation } from 'react-i18next';

interface CallFlyoutProps {
  currentCall: Call;
  onClose: () => void;
  calls: Call[];
  handleCallClick: (call_id: string) => void;
}

const CallFlyout: React.FC<CallFlyoutProps> = ({
  currentCall,
  onClose,
  calls,
  handleCallClick,
}) => {
  const { t } = useTranslation();
  return (
    <EuiFlyout
      style={{ width: 550, border: 'none' }}
      onClose={onClose}
      aria-labelledby={'record-calls'}
    >
      <FlyoutTitle>
        <TwoChevronsRightIcon $pointer onClick={onClose} color={COLOR_WHITE} />

        <H3 $bold>
          {t('campaigns.calls.detailed.callsFromRecord')}
          <span style={{ fontWeight: 400 }}>{currentCall.id}</span>
        </H3>
      </FlyoutTitle>

      <EuiFlyoutBody>
        {calls.map((call, index) => {
          const isCurrentlyShowed = currentCall.id === call.id;
          return (
            <RecordCallsRow
              currentCall={isCurrentlyShowed}
              onClick={() => {
                if (!isCurrentlyShowed) handleCallClick(call.id);
              }}
            >
              <div>
                <H4>{index + 1}.</H4>
              </div>
              <div>
                <H4>{moment(call.started_at).format('DD.MM.YY, HH:MM')}</H4>
              </div>
              <div>
                <H4>{call.telco_status}</H4>
              </div>
              <div>
                <H4 $medium>{formatNumber(call.record_phone_number)}</H4>
              </div>
              <div style={{ position: 'relative' }}>
                {call.call_type === 'INBOUND' ? (
                  <ArrowLeftLongIcon />
                ) : (
                  <ArrowRightLongIcon />
                )}
                <H6 style={{ position: 'absolute', top: -6, left: 7 }} $grey>
                  {call.call_type?.toLowerCase() === 'inbound'
                    ? t('campaigns.calls.filters.inbound')
                    : t('campaigns.calls.filters.outbound')}
                </H6>
              </div>
              <div>
                <H4 $medium>{formatNumber(call.campaign_phone_number)}</H4>
              </div>
            </RecordCallsRow>
          );
        })}
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};

export default CallFlyout;

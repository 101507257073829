import styled from 'styled-components';
import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_80,
  fontIBM,
} from '../../../../App.style';

export const RecordsListContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const EmptyRecordsContainer = styled.div`
  width: 350px;
  height: 150px;
  border: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const EmptyRecordsContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RecordsBarContainer = styled.div`
  width: 100%;
  height: 30px;

  margin: 8px;
  margin-top: 5px;

  display: flex;

  justify-content: space-between;
`;

export const RecordsFiltersContainer = styled.div`
  width: calc(100% - 5px);

  display: flex;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const RecordsTab = styled.table`
  margin-top: 8px;
  width: 100%;
  height: 100%;
  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    height: 90%;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background: white; // adjust this to your needs
  }
`;

interface RecordsTableHeaderProps {
  select?: boolean;
  pagination?: boolean;
}

export const RecordsTableHeader = styled.th<RecordsTableHeaderProps>`
  border-top: 1px solid ${COLOR_NEUTRAL_40};
  border-bottom: 1px solid ${COLOR_NEUTRAL_40};
  padding: 8px;
  width: ${(props) =>
    props.select ? '30px' : props.pagination ? '110px' : '150px'};
  text-align: left;
`;

interface RecordsTableRowProps {
  isCanceled?: boolean;
}

export const RecordsTableRow = styled.tr<RecordsTableRowProps>`
  background: ${(props) => props.isCanceled && '#f8f9f8'};
  &:hover {
    background: ${COLOR_NEUTRAL_10};
  }
`;

interface RecordsTableDataProps {
  select?: boolean;
  pagination?: boolean;
}
export const RecordsTableData = styled.td<RecordsTableDataProps>`
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};
  padding: 16px 8px;
  width: ${(props) =>
    props.select ? '30px' : props.pagination ? '110px' : '150px'};
  vertical-align: middle;
`;

//record id tooltip
export const RecordIdContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  padding: 2px 8px;

  border-radius: 5px;

  &:hover {
    background: #e4e5e4;
  }
`;

//record status field

interface StatusBoxProps {
  status: 'OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED';
}

const colorMap = {
  OPEN: { background: '#cfecfd', color: '#127FBF' },
  FINISHED: { background: '#c6e4c6', color: '#117B11' },
  CANCELLED: { background: '#f8f9f8', color: '#808080' },
  EXHAUSTED: { background: '#ecccb8', color: '#983700' },
};

export const RecordsStatus = styled.div<StatusBoxProps>`
  display: inline-block;
  background: ${(props) => colorMap[props.status].background};
  color: ${(props) => colorMap[props.status].color};
  border-radius: 5px;
  padding: 4px 8px;

  border: ${(props) => props.status === 'CANCELLED' && '1px solid #f2f2f2'};
`;

export const RecordsSearchContainer = styled.div``;

export const PaginationContainer = styled.div`
  width: 100%;
  min-width: 110px;
  padding-left: 3px;

  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

export const PaginationText = styled.span`
  font-family: ${fontIBM};
  font-weight: 400;
  font-size: 12px;
  color: ${COLOR_NEUTRAL_80};
`;

export const ArrowsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const ArrowIcon = styled.img`
  width: 15px;
  height: 15px;
`;

//////Filters

export const FilterContainer = styled.div`
  padding: 2px 6px;
  display: flex;
  gap: 6px;
  background: #f2f2f2;
  border-radius: 5px;

  align-items: center;
`;

//RecordsDetailModal
export const RecordsModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const RecordDetailsRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLOR_NEUTRAL_40};
  padding-right: 10px;
`;

export const RecordDetailsLeftRowContainer = styled.div`
  width: 200px;
`;

import {
  EuiStepsHorizontal,
  EuiFlexItem,
  EuiText,
  EuiButton,
} from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { BotConfigurationRequest } from '../../../../../generated/tenants/Api';
import { VindicationBackToPreset } from '../../BotsStartPanel';
import {
  checkDialogueCreate,
  checkDialogueUpdate,
  createDialogueAsyncThunk,
  updateDialogueAsyncThunk,
} from '../../../presets/dialogues/api/dialoguesSlice';
import { selectStrategyId } from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';

const VinSummary = () => {
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const [botConfigEdit, setBotConfigEdit] = useState<boolean>(false);
  const [botConfig, setBotConfig] = useState<BotConfigurationRequest>();
  const navigate = useNavigate();

  //identification
  const identificationBotStrategyConfig = localStorage.getItem(
    'vinIdentificationBotStrategyConfig',
  );
  const identificationGenerationsConfig = localStorage.getItem(
    'vinIdentificationGenerationsConfig',
  );
  //verification
  const verificationBotStrategyConfig = localStorage.getItem(
    'vinVerificationBotStrategyConfig',
  );
  const verificationGenerationsConfig = localStorage.getItem(
    'vinVerificationGenerationsConfig',
  );
  //Payment status
  const paymentStatusBotStrategyConfig = localStorage.getItem(
    'vinPaymentStatusBotStrategyConfig',
  );
  const paymentStatusGenerationsConfig = localStorage.getItem(
    'vinPaymentStatusGenerationsConfig',
  );
  //Negotiations
  const negotiationsBotStrategyConfig = localStorage.getItem(
    'vinNegotiationsBotStrategyConfig',
  );
  const negotiationsGenerationsConfig = localStorage.getItem(
    'vinNegotiationsGenerationsConfig',
  );
  const negotiationsRuntimeParameters = localStorage.getItem(
    'vinNegotiationsRuntimeParameters',
  );
  //Auxukuart Modules
  const auxModulesBotStrategyConfig = localStorage.getItem(
    'vinAuxModulesBotStrategyConfig',
  );
  const auxModulesGenerationsConfig = localStorage.getItem(
    'vinAuxModulesGenerationsConfig',
  );
  const botNameStorage = localStorage.getItem('botName');
  const completedSteps = localStorage.getItem('vinCompletedSteps');

  useEffect(() => {
    if (
      identificationBotStrategyConfig !== null &&
      identificationGenerationsConfig !== null &&
      verificationBotStrategyConfig !== null &&
      verificationGenerationsConfig !== null &&
      paymentStatusBotStrategyConfig !== null &&
      paymentStatusGenerationsConfig !== null &&
      negotiationsBotStrategyConfig !== null &&
      negotiationsGenerationsConfig !== null &&
      negotiationsRuntimeParameters !== null &&
      auxModulesBotStrategyConfig !== null &&
      auxModulesGenerationsConfig !== null &&
      completedSteps !== null &&
      botNameStorage !== null
    ) {
      //if user has completed all 5 steps, add 6 steps to completedSteps
      let arr: string[] = [];
      if (completedSteps !== null && !completedSteps?.includes('6')) {
        arr = JSON.parse(completedSteps);
        if (
          arr.includes('1') &&
          arr.includes('2') &&
          arr.includes('3') &&
          arr.includes('4') &&
          arr.includes('5')
        ) {
          arr.push('6');
          arr.sort();
          localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
        }
      }

      //bot strategy
      const identificationBotStrategyData = JSON.parse(
        identificationBotStrategyConfig,
      );
      const verificationBotStrategyData = JSON.parse(
        verificationBotStrategyConfig,
      );
      const paymentStatusBotStrategyData = JSON.parse(
        paymentStatusBotStrategyConfig,
      );
      const negotiationBotStrategyData = JSON.parse(
        negotiationsBotStrategyConfig,
      );
      const auxModulesBotStrategyData = JSON.parse(auxModulesBotStrategyConfig);

      //botStrategyConfig from all modules
      const botStrategy = {
        ...identificationBotStrategyData,
        ...verificationBotStrategyData,
        ...paymentStatusBotStrategyData,
        ...negotiationBotStrategyData,
        ...auxModulesBotStrategyData,
      };

      //merged all branches from all modules into this one
      const BRANCHES = {
        ...identificationBotStrategyData.BRANCHES,
        ...verificationBotStrategyData.BRANCHES,
        ...paymentStatusBotStrategyData.BRANCHES,
        ...negotiationBotStrategyData.BRANCHES,
        ...auxModulesBotStrategyData.BRANCHES,
      };
      //overwrite merged.BRANCHES with all branches
      botStrategy.BRANCHES = BRANCHES;

      //generations Config from all modules
      const identificationGenerationsConfigData = JSON.parse(
        identificationGenerationsConfig,
      );
      const verificationGenerationsConfigData = JSON.parse(
        verificationGenerationsConfig,
      );
      const paymentStatusGenerationsConfigData = JSON.parse(
        paymentStatusGenerationsConfig,
      );
      const negotiationGenerationsConfigData = JSON.parse(
        negotiationsGenerationsConfig,
      );
      const auxModulesGenerationsConfigData = JSON.parse(
        auxModulesGenerationsConfig,
      );

      const generationsConfig = {
        ...identificationGenerationsConfigData,
        ...verificationGenerationsConfigData,
        ...paymentStatusGenerationsConfigData,
        ...negotiationGenerationsConfigData,
        ...auxModulesGenerationsConfigData,
      };

      //runtime parameters
      const runtimeParameters = JSON.parse(negotiationsRuntimeParameters);

      setBotConfig({
        name: botNameStorage,
        strategy_config: botStrategy,
        generations_config: generationsConfig,
        template: 'DEBTOR',
        language: 'pl-PL',
        runtime_parameters: runtimeParameters,
        strategy: strategy,
        custom_label: '',
      });
    } else {
      console.log('You have not completed the whole config');
    }
  }, []);

  //checks if user is editing or creating new bot
  const botConfigurationType = localStorage.getItem('botConfigurationType');
  useEffect(() => {
    if (botConfigurationType === 'edit') {
      setBotConfigEdit(true);
    } else {
      setBotConfigEdit(false);
    }
  }, []);

  const botConfigurationId = localStorage.getItem('botConfigurationId');
  const handleUpdateBot = () => {
    if (botConfig && botConfigurationId) {
      const args = { id: botConfigurationId, data: botConfig };
      dispatch(updateDialogueAsyncThunk(args));
    } else {
      alert('Error');
    }
  };

  const handleCreateBot = () => {
    if (completedSteps) {
      const steps: string[] = JSON.parse(completedSteps);
      if (botConfig && steps.includes('6')) {
        dispatch(createDialogueAsyncThunk(botConfig));
      } else {
        alert('You have not completed all steps');
      }
    }
  };

  const checkBotConfig = useAppSelector(checkDialogueCreate);
  const checkBotConfigUpdate = useAppSelector(checkDialogueUpdate);

  //if everything is OK after dispatching, navigate to bot List
  useEffect(() => {
    if (
      (checkBotConfig.state === 'idle' &&
        checkBotConfig.value?.status === 'success') ||
      (checkBotConfigUpdate?.state === 'idle' &&
        checkBotConfigUpdate.value?.status === 'success')
    ) {
      navigate('/dashboard/bots');
      localStorage.setItem('vinCompletedSteps', '[]');
      localStorage.setItem('lastVisitedStep', '');
      localStorage.removeItem('lastVisitedStep');
      localStorage.removeItem('vinIdentificationGenerationsConfig');
      localStorage.removeItem('vinVerificationBotStrategyConfig');
      localStorage.removeItem('vinIdentificationBotStrategyConfig');
      localStorage.removeItem('vinVerificationGenerationsConfig');
      localStorage.removeItem('vinPaymentStatusBotStrategyConfig');
      localStorage.removeItem('vinPaymentStatusGenerationsConfig');
      localStorage.removeItem('vinNegotiationsBotStrategyConfig');
      localStorage.removeItem('vinNegotiationsGenerationsConfig');
      localStorage.removeItem('vinNegotiationsRuntimeParameters');
      localStorage.removeItem('vinAuxModulesGenerationsConfig');
      localStorage.removeItem('vinAuxModulesBotStrategyConfig');
      localStorage.removeItem('botName');
      localStorage.removeItem('botConfigurationType');
      localStorage.removeItem('botConfigurationId');
    }
  });

  const discardBotConfiguration = () => {
    navigate('/dashboard/bots');
  };

  //steps logic
  let steps: string[] = [];
  if (localStorage.getItem('vinCompletedSteps') !== null) {
    steps = JSON.parse(localStorage.getItem('vinCompletedSteps') as string);
  }
  const horizontalSteps = [
    {
      title: 'Identification',
      status: steps.includes('1') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/1');
      },
    },
    {
      title: 'Verification',
      status: steps.includes('2') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/2');
      },
    },
    {
      title: 'Payment status',
      status: steps.includes('3') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/3');
      },
    },
    {
      title: 'Negotiations',
      status: steps.includes('4') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/4');
      },
    },
    {
      title: 'Auxiliary modules',
      status: steps.includes('5') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/5');
      },
    },
    {
      title: 'Summary',
      status: 'current',
      onClick: () => {
        navigate('/dashboard/bots/new/1/6');
      },
    },
  ];

  //back to presets button logic
  const [backToPresetsButtonVisible, setBackToPresetsButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    const botConfigurationType = localStorage.getItem('botConfigurationType');
    if (botConfigurationType !== null && botConfigurationType === 'new') {
      setBackToPresetsButtonVisible(true);
    } else {
      setBackToPresetsButtonVisible(false);
    }
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        margin: 10,
        padding: 10,

        overflow: 'auto',
        marginTop: 80,
      }}
    >
      <EuiFlexItem
        css={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
      >
        {backToPresetsButtonVisible && <VindicationBackToPreset />}
        <div>
          <EuiButton fill onClick={discardBotConfiguration}>
            Discard
          </EuiButton>
        </div>
      </EuiFlexItem>
      <EuiStepsHorizontal
        steps={horizontalSteps as Omit<EuiStepHorizontalProps, 'step'>[]}
      />
      <EuiFlexItem>
        <EuiText css={{ fontSize: 22 }}>SUMMARY</EuiText>
      </EuiFlexItem>

      {botConfigEdit ? (
        <EuiFlexItem css={{ gap: 10 }}>
          <EuiText>Click button to edit bot</EuiText>

          <div>
            <EuiButton fill onClick={handleUpdateBot}>
              Edit bot
            </EuiButton>
          </div>
        </EuiFlexItem>
      ) : (
        <EuiFlexItem css={{ gap: 10 }}>
          <EuiText>Click button to create bot</EuiText>

          <div>
            <EuiButton fill onClick={handleCreateBot}>
              Create bot
            </EuiButton>
          </div>
        </EuiFlexItem>
      )}
    </div>
  );
};

export default VinSummary;

import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit';
import { setCookieConsent } from '../cookies';
import {
  acceptCookies,
  getConfigAsyncThunk,
  redirectToPublic,
  redirectToTenant,
  toggleTheme,
} from './sessionSlice';
import type { RootState, AppDispatch } from '../../../store';
import { setTheme } from '../localStorage';

export const sessionMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const startAppListening =
  sessionMiddleware.startListening as unknown as AppStartListening;
export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

startAppListening({
  actionCreator: redirectToPublic,
  effect: (action, listenerApi) => {
    const state = listenerApi.getState();
    window.location.replace(
      `${window.location.protocol}//${state.session.config.value?.base_domain}:${window.location.port}`,
    );
  },
});

startAppListening({
  actionCreator: redirectToTenant,
  effect: (action, listenerApi) => {
    const { base_domain } = listenerApi.getState().session.config.value || {};
    const { protocol, port } = window.location;
    const subdomain = action.payload === '' ? '' : `${action.payload}.`;

    window.location.replace(`${protocol}//${subdomain}${base_domain}:${port}`);
  },
});

startAppListening({
  actionCreator: toggleTheme,
  effect: (action, listenerApi) => {
    const state = listenerApi.getState();
    setTheme(state.session.theme);
    window.location.reload();
  },
});

startAppListening({
  actionCreator: acceptCookies,
  effect: () => {
    setCookieConsent();
  },
});

startAppListening({
  actionCreator: getConfigAsyncThunk.rejected,
  effect: () => {
    // redirect to superdomain
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname
        .split('.')
        .slice(1)
        .join('.')}`,
    );
  },
});

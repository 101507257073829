import {
  EuiBadge,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiHighlight,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Label } from '../../../../generated/tenants/Api';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkLabelCreate,
  clearCreateLabel,
  getLabelsListAsyncThunk,
  selectLabelsList,
} from './api/labelsSlice';
import LabelFormModal from './LabelFormModal';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface LabelsComboboxComponentProps {
  labels?: Label[] | string[];
  isDisabled?: boolean;
  handleUpdateLabels?: (labelsArr: Label[]) => void;
}

interface LabelsOptions {
  label: string;
  color?: string;
  id: string;
  description?: string | null;
}

const LabelsComboboxComponent: React.FC<LabelsComboboxComponentProps> = ({
  labels,
  isDisabled,
  handleUpdateLabels,
}) => {
  const { t } = useTranslation();
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getLabelsListAsyncThunk(strategy));
  }, []);

  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    const tempLabelArr: LabelsOptions[] = [];
    labels?.map((label) => {
      if (typeof label === 'string') {
        const tempLabel = labelsList.find((el) => el.id == label);
        if (tempLabel && tempLabel.color) {
          const newLabel: LabelsOptions = {
            label: tempLabel.text,
            color: tempLabel.color,
            id: tempLabel.id,
            description: tempLabel.description,
          };
          tempLabelArr.push(newLabel);
        }
      } else {
        if (label.color) {
          const newLabel: LabelsOptions = {
            label: label.text,
            color: label.color,
            id: label.id,
            description: label.description,
          };
          tempLabelArr.push(newLabel);
        }
      }
    });
    setSelectedLabels(tempLabelArr);
  }, [labels, key]);

  // labels list from api
  const labelsList = useAppSelector(selectLabelsList);
  const labelsOptions = labelsList.map((label) => {
    return {
      label: label.text,
      color: label.color,
      id: label.id,
      description: label.description,
    };
  });
  useEffect(() => {
    setOptions(labelsOptions);
  }, [labelsList]);

  const [options, setOptions] = useState<LabelsOptions[]>(labelsOptions);

  const [selectedLabels, setSelectedLabels] = useState<LabelsOptions[]>([]);

  //what happens onChange
  const onLabelsChange = (
    selectedOptions: EuiComboBoxOptionOption<string>[],
  ) => {
    setSelectedLabels(selectedOptions as LabelsOptions[]);

    const tempOptions = selectedOptions as LabelsOptions[];

    const labelsArr: Label[] = tempOptions.map((label) => {
      return {
        text: label.label,
        id: label.id,
        description: label.description,
        color: label.color,
        strategy: strategy,
      };
    });

    handleUpdateLabels && handleUpdateLabels(labelsArr);
  };

  //how labels are rendered in dropdown
  const renderOption = (
    option: EuiComboBoxOptionOption,
    searchValue: string,
  ) => {
    const { color, label } = option;
    return (
      <EuiBadge color={color}>
        <EuiHighlight search={searchValue}>{label}</EuiHighlight>
      </EuiBadge>
    );
  };

  const [inputValue, setInputValue] = useState<string>('');

  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.length > 0) {
      openCreateLabelModal();
    }
  };

  const checkCreate = useAppSelector(checkLabelCreate);

  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success' &&
      checkCreate.value.label
    ) {
      const newLabel = checkCreate.value.label;

      const tempArr = [
        ...selectedLabels,
        {
          id: newLabel.id,
          description: newLabel.description,
          label: newLabel.text,
          color: newLabel.color,
        },
      ];

      onLabelsChange(tempArr);

      setInputValue('');

      setTimeout(() => {
        setKey((prev) => prev + 1);
        dispatch(clearCreateLabel());
      }, 500);
    }
  }, [checkCreate]);

  const handleSearchChange = (searchValue: string) => {
    setInputValue(searchValue);
  };

  //label modal logic
  const [isCreateLabelModalVisible, setIsCreateLabelModalVisible] =
    useState<boolean>(false);

  const openCreateLabelModal = () => setIsCreateLabelModalVisible(true);

  const closeLabelModal = () => {
    setIsCreateLabelModalVisible(false);
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <LabelFormModal
        isVisible={isCreateLabelModalVisible}
        closeModal={closeLabelModal}
        labelText={inputValue}
        setIsLoadingVisible={setIsLoadingVisible}
      />

      <EuiComboBox
        key={`combobox-${key}`}
        placeholder={t('presets.labelsForm.selectLabels')}
        options={options}
        selectedOptions={selectedLabels}
        onChange={onLabelsChange}
        onKeyUp={handleEnterClick}
        renderOption={renderOption}
        isDisabled={isDisabled}
        onSearchChange={handleSearchChange}
        onCreateOption={() => openCreateLabelModal()}
        customOptionText={`Create new label: ${inputValue}`}
      />
    </>
  );
};

export default LabelsComboboxComponent;

import React from 'react';
import { H3 } from '../../../../App.style';
import { CampaignOptimizationRow } from './CampaignOptimization.style';
import { Campaign } from '../../../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface CampaignOptimizationFormReadOnlyProps {
  campaign: Campaign;
}

const CampaignOptimizationFormReadOnly: React.FC<
  CampaignOptimizationFormReadOnlyProps
> = ({ campaign }) => {
  const { t } = useTranslation();

  const timeBetweenAttempts = moment()
    .startOf('day')
    .add(
      campaign.calling_configuration.time_between_attempts_seconds,
      'seconds',
    );
  const secondsToMoment = (seconds: number) => {
    return moment().startOf('day').add(seconds, 'seconds').format('HH:mm'); // Example format, adjust as needed
  };

  const renderTimeBetweenAttemptsMode = (
    value: 'STATIC' | 'DYNAMIC' | 'RANDOMIZED' | undefined,
  ) => {
    switch (value) {
      case 'STATIC':
        return t('presets.callingPreferencesForm.static');
      case 'DYNAMIC':
        return t('presets.callingPreferencesForm.dynamic');
      case 'RANDOMIZED':
        return t('presets.callingPreferencesForm.randomized');
      case undefined:
        return '';
    }
  };

  return (
    <div style={{ marginTop: 50 }}>
      <CampaignOptimizationRow>
        <H3>{t('processes.form.general.guarantedLines')}</H3>{' '}
        <H3 $semibold>{campaign.guaranteed_lines}</H3>
      </CampaignOptimizationRow>
      <CampaignOptimizationRow>
        <H3>{t('processes.form.general.dynamicLinesUsage')}</H3>{' '}
        <H3 $semibold>{campaign.use_idle_lines ? 'YES' : 'NO'}</H3>
      </CampaignOptimizationRow>

      <CampaignOptimizationRow>
        <H3>{t('presets.callingPreferencesForm.callAttempts')}</H3>
        <H3 $semibold>{campaign.calling_configuration.call_attempts}</H3>
      </CampaignOptimizationRow>
      <CampaignOptimizationRow>
        <H3>{t('presets.callingPreferencesForm.dailyCallAttempts')}</H3>
        <H3 $semibold>
          {campaign.calling_configuration.call_attempts_per_day}
        </H3>
      </CampaignOptimizationRow>

      <CampaignOptimizationRow>
        <H3 style={{ maxWidth: 400 }}>
          {t('presets.callingPreferencesForm.restDays')}
        </H3>
        <H3 $semibold>
          {
            campaign.calling_configuration
              .rest_days_after_call_attempts_per_day_reached
          }
        </H3>
      </CampaignOptimizationRow>

      <CampaignOptimizationRow>
        <H3>{t('presets.callingPreferencesForm.intervalBetweenAttempts')}</H3>
        <H3 $semibold>
          {renderTimeBetweenAttemptsMode(
            campaign.calling_configuration.time_between_attempts_mode,
          )}
        </H3>
      </CampaignOptimizationRow>

      {campaign.calling_configuration.time_between_attempts_mode ===
        'STATIC' && (
        <CampaignOptimizationRow>
          <H3>
            {t('presets.callingPreferencesForm.timeBetweenAttemptsSeconds')}
          </H3>
          <H3 $semibold>{timeBetweenAttempts.format('HH:mm')}</H3>
        </CampaignOptimizationRow>
      )}

      {campaign.calling_configuration.time_between_attempts_mode ===
        'RANDOMIZED' &&
        campaign.calling_configuration.randomized_time_between_attempts && (
          <div>
            <CampaignOptimizationRow>
              <H3>{t('presets.callingPreferencesForm.minimal')}</H3>
              <H3 $semibold>
                {secondsToMoment(
                  campaign.calling_configuration
                    .randomized_time_between_attempts
                    .min_time_between_attempts_seconds,
                )}
              </H3>
            </CampaignOptimizationRow>
            <CampaignOptimizationRow>
              <H3>{t('presets.callingPreferencesForm.maximal')}</H3>
              <H3 $semibold>
                {secondsToMoment(
                  campaign.calling_configuration
                    .randomized_time_between_attempts
                    .max_time_between_attempts_seconds,
                )}
              </H3>
            </CampaignOptimizationRow>
          </div>
        )}

      {campaign.calling_configuration.time_between_attempts_mode ===
        'DYNAMIC' &&
        campaign.calling_configuration.dynamic_time_between_attempts && (
          <div>
            <CampaignOptimizationRow
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <H3>{t('presets.callingPreferencesForm.timeRange')}</H3>

              {campaign.calling_configuration.dynamic_time_between_attempts.map(
                (
                  interval: {
                    interval_start_seconds: number;
                    interval_end_seconds: number;
                    time_between_attempts_seconds: number;
                  },
                  index: number,
                ) => (
                  <div
                    style={{ display: 'flex', gap: 8 }}
                    key={`dynamic-time-between-attempts-${index}`}
                  >
                    <H3>{t('presets.callingPreferencesForm.from')}</H3>
                    <H3 $semibold>
                      {secondsToMoment(interval.interval_start_seconds)}
                    </H3>
                    <H3>{t('presets.callingPreferencesForm.to')}</H3>
                    <H3 $semibold>
                      {secondsToMoment(interval.interval_end_seconds)}
                    </H3>
                    <H3>{t('presets.callingPreferencesForm.interval')}</H3>
                    <H3 $semibold>
                      {secondsToMoment(interval.time_between_attempts_seconds)}
                    </H3>
                  </div>
                ),
              )}
            </CampaignOptimizationRow>
            <CampaignOptimizationRow>
              <H3>{t('presets.callingPreferencesForm.basicInterval')}</H3>
              <H3 $semibold>{timeBetweenAttempts.format('HH:mm')}</H3>
            </CampaignOptimizationRow>
          </div>
        )}
    </div>
  );
};

export default CampaignOptimizationFormReadOnly;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const WandIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      color={color}
      style={style}
      onClick={$disabled ? undefined : onClick}
      $disabled={$disabled}
      $pointer={$pointer}
    >
      <path d="M8.99998 3.32843C8.99998 3.60457 9.22384 3.82843 9.49998 3.82843C9.77612 3.82843 9.99998 3.60457 9.99998 3.32843V1.5C9.99998 1.22386 9.77612 1 9.49998 1C9.22384 1 8.99998 1.22386 8.99998 1.5V3.32843Z" />
      <path d="M13.5 3.36396C13.6952 3.1687 13.6952 2.85212 13.5 2.65685C13.3047 2.46159 12.9881 2.46159 12.7929 2.65685L11.5 3.94975C11.3047 4.14501 11.3047 4.46159 11.5 4.65685C11.6952 4.85212 12.0118 4.85212 12.2071 4.65685L13.5 3.36396Z" />
      <path d="M6.79287 4.65686C6.98813 4.85212 7.30472 4.85212 7.49998 4.65686C7.69524 4.46159 7.69524 4.14501 7.49998 3.94975L6.20709 2.65686C6.01182 2.46159 5.69524 2.46159 5.49998 2.65686C5.30472 2.85212 5.30472 3.1687 5.49998 3.36396L6.79287 4.65686Z" />
      <path d="M6.17155 7.15685C6.44769 7.15685 6.67155 6.933 6.67155 6.65685C6.67155 6.38071 6.44769 6.15685 6.17155 6.15685H4.34312C4.06698 6.15685 3.84313 6.38071 3.84313 6.65685C3.84313 6.933 4.06698 7.15685 4.34312 7.15685H6.17155Z" />
      <path d="M14.6568 7.15685C14.933 7.15685 15.1568 6.933 15.1568 6.65685C15.1568 6.38071 14.933 6.15685 14.6568 6.15685H12.8284C12.5523 6.15685 12.3284 6.38071 12.3284 6.65685C12.3284 6.933 12.5523 7.15685 12.8284 7.15685H14.6568Z" />
      <path d="M12.7929 10.6569C12.9881 10.8521 13.3047 10.8521 13.5 10.6569C13.6952 10.4616 13.6952 10.145 13.5 9.94975L12.2071 8.65686C12.0118 8.46159 11.6952 8.46159 11.5 8.65686C11.3047 8.85212 11.3047 9.1687 11.5 9.36396L12.7929 10.6569Z" />
      <path d="M8.99998 11.8137C8.99998 12.0899 9.22384 12.3137 9.49998 12.3137C9.77612 12.3137 9.99998 12.0899 9.99998 11.8137V9.98528C9.99998 9.70914 9.77612 9.48528 9.49998 9.48528C9.22384 9.48528 8.99998 9.70914 8.99998 9.98528V11.8137Z" />
      <path d="M10.8536 6.71751C11.0488 6.52225 11.0488 6.20567 10.8536 6.01041L10.1464 5.3033C9.95118 5.10804 9.6346 5.10804 9.43934 5.3033L8.14645 6.59619C7.95118 6.79145 7.95118 7.10804 8.14645 7.3033L8.85355 8.01041C9.04881 8.20567 9.3654 8.20567 9.56066 8.01041L10.8536 6.71751Z" />
      <path d="M7.85355 9.71751C8.04881 9.52225 8.04881 9.20567 7.85355 9.01041L7.14645 8.3033C6.95118 8.10804 6.6346 8.10804 6.43934 8.3033L0.146446 14.5962C-0.0488159 14.7915 -0.0488152 15.108 0.146447 15.3033L0.853554 16.0104C1.04882 16.2057 1.3654 16.2057 1.56066 16.0104L7.85355 9.71751Z" />
    </StyledSvg>
  );
};

import {
  EuiFlexGroup,
  EuiStepsHorizontal,
  EuiFlexItem,
  EuiText,
  EuiAccordion,
  EuiPanel,
  EuiButton,
  EuiCheckbox,
  EuiFieldNumber,
  EuiSelect,
  EuiTextColor,
} from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VindicationBackToPreset } from '../../BotsStartPanel';
import TokenInput from '../tokenInputComponents/TokenInput';
import { SUPPORTED_MODULES } from './VinIdentification';

interface GenerationsConfigVinNegotiations {
  'REQ-INF-will_pay_deadline_date_min-int-1-1': string;
  'REQ-INF-future_payment-date-1-1': string;
  'REQ-INF-will_pay_deadline_date_max-1-1': string;
  'REQ-INF-accept_arrangements-has_info_item-1-1': string;
  'REQ-INF-accept_arrangements-date_was_moved-1-1': string;
  'INF-STA-negotiation_success-no_captured_date-1-1': string;
  'INF-STA-negotiation_success-has_info_item-1-1': string;
  'INF-STA-unacceptable_date-1-1': string;
}

interface BotStrategyConfigVinNegotiations {
  IMPORT_NEGOTIATION_SKILL: boolean;
  PAYMENT_DEADLINE_DAYS_MIN_QUESTION: boolean;
  FUTURE_PAYMENT_DATE_QUESTION: boolean;
  PAYMENT_DEADLINE_DAYS_MAX_QUESTION: boolean;
  ACCEPT_ARRANGEMENTS_QUESTION: boolean;
  BRANCHES: { NEGOTIATION: { success: string; fail: string } };
}

interface RuntimeParametersNegotiations {
  PaymentDeadlineDays: number;
  PaymentDeadlineDaysMax: number;
}

const VinNegotiations = () => {
  const [
    vinNegotiationsGenerationsConfig,
    setVinNegotiationsGenerationsConfig,
  ] = useState<GenerationsConfigVinNegotiations>();

  const [
    vinNegotiationsBotStrategyConfig,
    setVinNegotiationsBotStrategyConfig,
  ] = useState<BotStrategyConfigVinNegotiations>();

  const [vinNegotiationRuntimeParameters, setVinNegotiationRuntimeParameters] =
    useState<RuntimeParametersNegotiations>();

  const [showAccordion, setShowAccordion] = useState<{
    startNegotiations: boolean;
    dateOfPayment: boolean;
    notAcceptableDate: boolean;
    acceptableDate: boolean;
    debtorAcceptsDate: boolean;
    debtorSpecifiesAcceptableDate: boolean;
    successfullNegotiation: boolean;
    debtorNotAcceptableDate: boolean;
  }>({
    startNegotiations: false,
    dateOfPayment: false,
    notAcceptableDate: false,
    acceptableDate: false,
    debtorAcceptsDate: false,
    debtorSpecifiesAcceptableDate: false,
    successfullNegotiation: false,
    debtorNotAcceptableDate: false,
  });

  const discardBotConfiguration = () => {
    navigate('/dashboard/bots');
  };

  const navigate = useNavigate();
  //steps logic
  let steps: string[] = [];
  if (localStorage.getItem('vinCompletedSteps') !== null) {
    steps = JSON.parse(localStorage.getItem('vinCompletedSteps') as string);
  }
  const horizontalSteps = [
    {
      title: 'Identification',
      status: steps.includes('1') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/1');
      },
    },
    {
      title: 'Verification',
      status: steps.includes('2') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/2');
      },
    },
    {
      title: 'Payment status',
      status: steps.includes('3') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/3');
      },
    },
    {
      title: 'Negotiations',
      status: 'current',
      onClick: () => {
        navigate('/dashboard/bots/new/1/4');
      },
    },
    {
      title: 'Auxiliary modules',
      status: steps.includes('5') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/5');
      },
    },
    {
      title: 'Summary',
      status: steps.includes('6') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/6');
      },
    },
  ];

  const [selectionError, setSelectionError] = useState<boolean>(false);
  useEffect(() => {
    if (
      vinNegotiationsBotStrategyConfig?.PAYMENT_DEADLINE_DAYS_MIN_QUESTION ===
        true ||
      vinNegotiationsBotStrategyConfig?.FUTURE_PAYMENT_DATE_QUESTION === true
    ) {
      setSelectionError(false);
    } else {
      setSelectionError(true);
    }
  }, [vinNegotiationsBotStrategyConfig]);

  //if there are items in storage, set them, otherwise set standard values
  useEffect(() => {
    try {
      localStorage.setItem('lastVisitedStep', '4');
      const storageGenerationsConfig = localStorage.getItem(
        'vinNegotiationsGenerationsConfig',
      );
      if (storageGenerationsConfig !== null) {
        setVinNegotiationsGenerationsConfig(
          JSON.parse(storageGenerationsConfig),
        );
      } else {
        setVinNegotiationsGenerationsConfig({
          'REQ-INF-will_pay_deadline_date_min-int-1-1':
            'Spłata całości powinna nastąpić do <data>. Czy zapłaci Pani zaległość w tym terminie?',
          'REQ-INF-future_payment-date-1-1':
            'Kiedy moze Pan/Pani zapłacić zaległośc?',
          'REQ-INF-will_pay_deadline_date_max-1-1':
            'Maksymalny termin wpłaty, na który mogę się zgodzić to <date>. Czy zapłaci Pan/Pani do tego czasu?',
          'REQ-INF-accept_arrangements-has_info_item-1-1':
            'Podsumowuję zatem nasze ustalenia. Kwota do zapłaty to <kwota>, ustalony termin spłaty to <data>. Czy potwierdza  Pan/Pani ustalenia?',
          'REQ-INF-accept_arrangements-date_was_moved-1-1':
            'Podany termin przypada na dzień wolny od pracy, zatem czekam na wpłatę w następujący po nim dzień roboczy. Podsumowuję zatem nasze ustalenia. Kwota do zapłaty to <kwota>, ustalony termin spłaty to <data>. Czy potwierdza  Pan/Pani ustalenia?',
          'INF-STA-negotiation_success-no_captured_date-1-1':
            'W takim razie czekam na zaksięgowanie wpłaty na rachunku. Wpłata będzie monitorowana',
          'INF-STA-negotiation_success-has_info_item-1-1':
            'Dziękuję. Czekam na wpłatę kwoty <kwota> do <data>. Wpłata będzie monitorowana',
          'INF-STA-unacceptable_date-1-1':
            'Niestety, ten termin jest zbyt odległy. Brak wpłaty moze się wiązać z rozpoczęciem procesu windykacyjnego.',
        });
      }

      const storageBotStrategyConfig = localStorage.getItem(
        'vinNegotiationsBotStrategyConfig',
      );
      if (storageBotStrategyConfig !== null) {
        const storageDataBotStrategy = JSON.parse(storageBotStrategyConfig);
        setVinNegotiationsBotStrategyConfig(storageDataBotStrategy);
      } else {
        setVinNegotiationsBotStrategyConfig({
          IMPORT_NEGOTIATION_SKILL: true,
          PAYMENT_DEADLINE_DAYS_MIN_QUESTION: false,
          FUTURE_PAYMENT_DATE_QUESTION: false,
          PAYMENT_DEADLINE_DAYS_MAX_QUESTION: false,
          ACCEPT_ARRANGEMENTS_QUESTION: false,
          BRANCHES: {
            NEGOTIATION: {
              success: SUPPORTED_MODULES[0].value,
              fail: SUPPORTED_MODULES[3].value,
            },
          },
        });
      }

      const storageRuntimeParameters = localStorage.getItem(
        'vinNegotiationsRuntimeParameters',
      );
      if (storageRuntimeParameters !== null) {
        setVinNegotiationRuntimeParameters(
          JSON.parse(storageRuntimeParameters),
        );
      } else {
        setVinNegotiationRuntimeParameters({
          PaymentDeadlineDays: 10,
          PaymentDeadlineDaysMax: 10,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  //if user is making changes, save to localStorage
  useEffect(() => {
    if (vinNegotiationsGenerationsConfig)
      localStorage.setItem(
        'vinNegotiationsGenerationsConfig',
        JSON.stringify(vinNegotiationsGenerationsConfig),
      );
  }, [vinNegotiationsGenerationsConfig]);

  useEffect(() => {
    localStorage.setItem(
      'vinNegotiationsBotStrategyConfig',
      JSON.stringify(vinNegotiationsBotStrategyConfig),
    );
  }, [vinNegotiationsBotStrategyConfig]);

  useEffect(() => {
    localStorage.setItem(
      'vinNegotiationsRuntimeParameters',
      JSON.stringify(vinNegotiationRuntimeParameters),
    );
  }, [vinNegotiationRuntimeParameters]);

  //last button logic
  const handleSubmitNegotiationsClick = () => {
    if (selectionError === false) {
      let arr: string[];
      const completedSteps = localStorage.getItem('vinCompletedSteps');
      if (completedSteps !== null && !completedSteps?.includes('4')) {
        arr = JSON.parse(completedSteps);
        arr.push('4');
        arr.sort();
        localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
      } else if (completedSteps && completedSteps?.length > 0) {
        console.log('this step was already added');
      } else {
        arr = ['4'];
        localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
      }
      navigate('/dashboard/bots/new/1/5');
    } else {
      alert('Please select first or second module to continue');
    }
  };

  //back to presets button logic
  const [backToPresetsButtonVisible, setBackToPresetsButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    const botConfigurationType = localStorage.getItem('botConfigurationType');
    if (botConfigurationType !== null && botConfigurationType === 'new') {
      setBackToPresetsButtonVisible(true);
    } else {
      setBackToPresetsButtonVisible(false);
    }
  }, []);
  return (
    <>
      {vinNegotiationsBotStrategyConfig &&
        vinNegotiationsGenerationsConfig &&
        vinNegotiationRuntimeParameters && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              flexDirection: 'column',
              margin: 10,
              padding: 10,

              overflow: 'auto',
              marginTop: 80,
            }}
          >
            <EuiFlexItem
              css={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
            >
              {backToPresetsButtonVisible && <VindicationBackToPreset />}

              <div>
                <EuiButton fill onClick={discardBotConfiguration}>
                  Discard
                </EuiButton>
              </div>
            </EuiFlexItem>
            <EuiStepsHorizontal
              steps={horizontalSteps as Omit<EuiStepHorizontalProps, 'step'>[]}
            />
            <EuiFlexItem>
              <EuiText css={{ fontSize: 22 }}>NEGOCJACJE</EuiText>
              <EuiText>
                W module negocjacji następuje negocjacja terminu spłaty długu.
              </EuiText>
              <EuiText>
                Zarówno udana, jak i nieudana negocjacja, docelowo prowadzi do
                zakończenia rozmowy.
              </EuiText>
            </EuiFlexItem>

            <div style={{ marginTop: 30 }}>
              {selectionError && (
                <EuiFlexItem css={{ fontSize: 23 }}>
                  <EuiTextColor color="danger">
                    You have to select the first, second or both modules to
                    continue creating the bot
                  </EuiTextColor>
                </EuiFlexItem>
              )}
              <EuiFlexItem
                css={{
                  paddingTop: 20,
                  flexDirection: 'row',
                  gap: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EuiText>
                  This module is optional, click the checkbox to turn it ON
                </EuiText>
                <EuiCheckbox
                  id="beggining-negotiations"
                  checked={
                    vinNegotiationsBotStrategyConfig.PAYMENT_DEADLINE_DAYS_MIN_QUESTION
                  }
                  onChange={(e) => {
                    setVinNegotiationsBotStrategyConfig({
                      ...vinNegotiationsBotStrategyConfig,
                      PAYMENT_DEADLINE_DAYS_MIN_QUESTION: e.target.checked,
                    });
                  }}
                />
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  isDisabled={
                    !vinNegotiationsBotStrategyConfig.PAYMENT_DEADLINE_DAYS_MIN_QUESTION
                  }
                  buttonContent="Beginning of negotiations. Asking for repayment at the optimal time"
                  forceState={
                    showAccordion.startNegotiations ? 'open' : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      startNegotiations: !showAccordion.startNegotiations,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should the bot's question be about repaying the
                          arrears on time?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations1'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'REQ-INF-will_pay_deadline_date_min-int-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'REQ-INF-will_pay_deadline_date_min-int-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <EuiText>
                          The proposed number of days to repay the debt
                        </EuiText>
                        <EuiFieldNumber
                          css={{ textAlign: 'center' }}
                          value={
                            vinNegotiationRuntimeParameters.PaymentDeadlineDays
                          }
                          onChange={(e) =>
                            setVinNegotiationRuntimeParameters({
                              ...vinNegotiationRuntimeParameters,
                              PaymentDeadlineDays: Number(e.target.value),
                            })
                          }
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                startNegotiations: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem
                css={{
                  paddingTop: 20,
                  flexDirection: 'row',
                  gap: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EuiText>
                  This module is optional, click the checkbox to turn it ON
                </EuiText>
                <EuiCheckbox
                  id="beggining-negotiations"
                  checked={
                    vinNegotiationsBotStrategyConfig.FUTURE_PAYMENT_DATE_QUESTION
                  }
                  onChange={(e) =>
                    setVinNegotiationsBotStrategyConfig({
                      ...vinNegotiationsBotStrategyConfig,
                      FUTURE_PAYMENT_DATE_QUESTION: e.target.checked,
                    })
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  isDisabled={
                    !vinNegotiationsBotStrategyConfig.FUTURE_PAYMENT_DATE_QUESTION
                  }
                  forceState={showAccordion.dateOfPayment ? 'open' : 'closed'}
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      dateOfPayment: !showAccordion.dateOfPayment,
                    })
                  }
                  buttonContent="Asking about the date of payment of arrears"
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should the bot's question about the date of
                          payment of arrears be?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations2'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'REQ-INF-future_payment-date-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'REQ-INF-future_payment-date-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                dateOfPayment: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem
                css={{
                  paddingTop: 20,
                  flexDirection: 'row',
                  gap: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EuiText>
                  This module is optional, click the checkbox to turn it ON
                </EuiText>
                <EuiCheckbox
                  id="beggining-negotiations"
                  checked={
                    vinNegotiationsBotStrategyConfig.PAYMENT_DEADLINE_DAYS_MAX_QUESTION
                  }
                  onChange={(e) =>
                    setVinNegotiationsBotStrategyConfig({
                      ...vinNegotiationsBotStrategyConfig,
                      PAYMENT_DEADLINE_DAYS_MAX_QUESTION: e.target.checked,
                    })
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  isDisabled={
                    !vinNegotiationsBotStrategyConfig.PAYMENT_DEADLINE_DAYS_MAX_QUESTION
                  }
                  forceState={
                    showAccordion.notAcceptableDate ? 'open' : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      notAcceptableDate: !showAccordion.notAcceptableDate,
                    })
                  }
                  buttonContent="The debtor gives an unacceptably long payment date"
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should be the bot's reaction when the debtor does
                          not agree to repay the debt within an acceptable
                          period?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations3'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'REQ-INF-will_pay_deadline_date_max-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'REQ-INF-will_pay_deadline_date_max-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{ justifyContent: 'center', alignItems: 'center' }}
                      >
                        <EuiText>
                          The maximum permissible number of days for the payment
                          of receivables
                        </EuiText>
                        <div>
                          <EuiFieldNumber
                            css={{ textAlign: 'center' }}
                            value={
                              vinNegotiationRuntimeParameters.PaymentDeadlineDaysMax
                            }
                            onChange={(e) =>
                              setVinNegotiationRuntimeParameters({
                                ...vinNegotiationRuntimeParameters,
                                PaymentDeadlineDaysMax: Number(e.target.value),
                              })
                            }
                          />
                        </div>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                notAcceptableDate: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem
                css={{
                  paddingTop: 20,
                  flexDirection: 'row',
                  gap: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EuiText>
                  This module is optional, click the checkbox to turn it ON
                </EuiText>
                <EuiCheckbox
                  id="beggining-negotiations"
                  checked={
                    vinNegotiationsBotStrategyConfig.ACCEPT_ARRANGEMENTS_QUESTION
                  }
                  onChange={(e) =>
                    setVinNegotiationsBotStrategyConfig({
                      ...vinNegotiationsBotStrategyConfig,
                      ACCEPT_ARRANGEMENTS_QUESTION: e.target.checked,
                    })
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  isDisabled={
                    !vinNegotiationsBotStrategyConfig.ACCEPT_ARRANGEMENTS_QUESTION
                  }
                  forceState={showAccordion.acceptableDate ? 'open' : 'closed'}
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      acceptableDate: !showAccordion.acceptableDate,
                    })
                  }
                  buttonContent="Acceptance and summary of findings"
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should the bot's statement be like when summing
                          up the findings so far?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations4'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'REQ-INF-accept_arrangements-has_info_item-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'REQ-INF-accept_arrangements-has_info_item-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          How this statement should sound when the deadline
                          falls on the weekend
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations5'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'REQ-INF-accept_arrangements-date_was_moved-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'REQ-INF-accept_arrangements-date_was_moved-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                acceptableDate: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  buttonContent="The debtor agrees to the proposed repayment date"
                  forceState={
                    showAccordion.debtorAcceptsDate ? 'open' : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      debtorAcceptsDate: !showAccordion.debtorAcceptsDate,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should the bot's reaction be when the debtor
                          agrees to the proposed repayment date?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations6'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'INF-STA-negotiation_success-no_captured_date-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'INF-STA-negotiation_success-no_captured_date-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <EuiText>
                          Please select the module activated after this bot
                          statement
                        </EuiText>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinNegotiationsBotStrategyConfig.BRANCHES
                              .NEGOTIATION.success
                          }
                          onChange={(e) =>
                            setVinNegotiationsBotStrategyConfig({
                              ...vinNegotiationsBotStrategyConfig,
                              BRANCHES: {
                                NEGOTIATION: {
                                  ...vinNegotiationsBotStrategyConfig.BRANCHES
                                    .NEGOTIATION,
                                  success: e.target.value,
                                },
                              },
                            })
                          }
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                debtorAcceptsDate:
                                  !showAccordion.debtorAcceptsDate,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  // arrowDisplay="right"
                  buttonContent="The debtor provides an acceptable repayment date"
                  forceState={
                    showAccordion.debtorSpecifiesAcceptableDate
                      ? 'open'
                      : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      debtorSpecifiesAcceptableDate:
                        !showAccordion.debtorSpecifiesAcceptableDate,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should be the bot's reaction when the debtor
                          gives a date that we accept?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations7'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'INF-STA-negotiation_success-has_info_item-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'INF-STA-negotiation_success-has_info_item-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <EuiText>
                          Please select the module activated after this bot
                          statement
                        </EuiText>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinNegotiationsBotStrategyConfig.BRANCHES
                              .NEGOTIATION.success
                          }
                          onChange={(e) =>
                            setVinNegotiationsBotStrategyConfig({
                              ...vinNegotiationsBotStrategyConfig,
                              BRANCHES: {
                                NEGOTIATION: {
                                  ...vinNegotiationsBotStrategyConfig.BRANCHES
                                    .NEGOTIATION,
                                  success: e.target.value,
                                },
                              },
                            })
                          }
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                debtorSpecifiesAcceptableDate:
                                  !showAccordion.debtorSpecifiesAcceptableDate,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  buttonContent="The debtor gives too long a repayment date"
                  forceState={
                    showAccordion.debtorNotAcceptableDate ? 'open' : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      debtorNotAcceptableDate:
                        !showAccordion.debtorNotAcceptableDate,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should be the bot's reaction when the debtor
                          gives a date that we don't accept?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinNegotiations8'}
                          text={
                            vinNegotiationsGenerationsConfig[
                              'INF-STA-unacceptable_date-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinNegotiationsGenerationsConfig({
                              ...vinNegotiationsGenerationsConfig,
                              'INF-STA-unacceptable_date-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <EuiText>
                          Please select the module activated after this bot
                          statement
                        </EuiText>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinNegotiationsBotStrategyConfig.BRANCHES
                              .NEGOTIATION.success
                          }
                          onChange={(e) =>
                            setVinNegotiationsBotStrategyConfig({
                              ...vinNegotiationsBotStrategyConfig,
                              BRANCHES: {
                                NEGOTIATION: {
                                  ...vinNegotiationsBotStrategyConfig.BRANCHES
                                    .NEGOTIATION,
                                  fail: e.target.value,
                                },
                              },
                            })
                          }
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                debtorNotAcceptableDate:
                                  !showAccordion.debtorNotAcceptableDate,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
            </div>
            <div>
              <EuiButton
                fill
                onClick={handleSubmitNegotiationsClick}
                disabled={
                  !vinNegotiationsBotStrategyConfig.FUTURE_PAYMENT_DATE_QUESTION &&
                  !vinNegotiationsBotStrategyConfig.PAYMENT_DEADLINE_DAYS_MIN_QUESTION
                }
              >
                Next
              </EuiButton>
            </div>
          </div>
        )}
    </>
  );
};

export default VinNegotiations;

// vinNegotiationsBotStrategyConfig.FUTURE_PAYMENT_DATE_QUESTION;
// vinNegotiationsBotStrategyConfig.PAYMENT_DEADLINE_DAYS_MIN_QUESTION;

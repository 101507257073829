import { H3 } from './../../../../../App.style';
import styled from 'styled-components';
import {
  COLOR_WHITE,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_10,
} from '../../../../../App.style';

export const TenantsContainer = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  padding: 24px;

  background: #ffffff;

  position: relative;
`;

export const TenantsListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const TenantsListBarContainer = styled.div`
  width: 100%;

  display: flex;
  gap: 16px;

  align-items: center;

  justify-content: space-between;
`;

export const TenantsListBarLeftContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const SearchContainer = styled.div`
  height: 30px;
  width: 210px;
`;

export const TenantsListContentContainer = styled.div`
  width: 100%;

  margin-top: 24px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: auto;

  gap: 20px;
`;

//TenantBox anitmation

export const TenantBox = styled.div`
  width: 270px;
  height: 125px;

  background: ${COLOR_WHITE};

  border: 0.5px solid ${COLOR_NEUTRAL_40};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s ease-out;

  cursor: pointer;
  position: relative;

  border: 1px solid #d3d3d3;

  &:hover {
    background: ${COLOR_NEUTRAL_10};
    -webkit-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
    -moz-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
    box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  }
`;

export const EditTenantContainer = styled.div`
  width: 30px;
  height: 30px;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;

  z-index: 100;

  &:hover {
    transform: scale(1.05);
  }
`;

export const GoToTenantContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 8px;
  right: 8px;

  z-index: 100;
`;

//Create
export const CreateTenantContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
`;

//detailed

export const TenantFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const TenantFormBarContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

export const TenantDetailedTitleContainer = styled.div`
  width: 400px;

  padding-bottom: 16px;
`;

export const TenantSlugContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 3px;
`;

export const TenantsGlobalSettingsContainer = styled.div`
  width: 400px;
  border-top: 0.5px solid #3e4c59;
  padding-top: 24px;
  padding-bottom: 16px;
`;

export const TenantsDeleteAccountContainer = styled.div`
  width: 400px;
  border-top: 0.5px solid #3e4c59;
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${H3} {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

//delete modal

export const DeleteTenantContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  gap: 16px;
`;

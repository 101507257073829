import React from 'react';
import { DialogueToken } from '../../configuration/tokenInputComponents/BotsTokens';
import Modal from '../../../../../common/modals/Modal';
import { EuiModalBody } from '@elastic/eui';
import BotStatementEdit from './BotStatementEdit';

interface BotStatementEditModalProps {
  handleSave: () => void;
  handleCancel: () => void;
  dialogueTokens: DialogueToken[];
  setDialogueTokens: React.Dispatch<React.SetStateAction<DialogueToken[]>>;
  modalTitle: string;
  customTokens: string[];
  renderInputText: () => JSX.Element[];
}

const BotStatementEditModal: React.FC<BotStatementEditModalProps> = ({
  handleSave,
  handleCancel,
  dialogueTokens,
  setDialogueTokens,
  modalTitle,
  customTokens,
  renderInputText,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSave();

    return false;
  };

  const body = (
    <EuiModalBody>
      <BotStatementEdit
        dialogueTokens={dialogueTokens}
        setDialogueTokens={setDialogueTokens}
        customTokens={customTokens}
        renderInputText={renderInputText}
      />
    </EuiModalBody>
  );
  return (
    <Modal
      width={1100}
      isVisible={true}
      headerTitle={modalTitle}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      children={body}
      buttonText="Save"
    />
  );
};

export default BotStatementEditModal;

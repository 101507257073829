import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../common/hooks';
import { H2, H4, H3 } from '../../../../App.style';
import {
  CampaignNonWorkingDaysConfiguration,
  CampaignNonWorkingDaysConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import {
  FormContainer,
  FormRow,
} from '../../../processes/forms/ProcessForm.style';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../../PresetsPanel.style';
import AssociatedProcesses from '../../shared/AssociatedProcesses';
import EditPresetButtons from '../../shared/EditPresetButtons';
import { DaysOffDatesContainer, DayOffContainer } from '../DaysOff.style';
import { updateBotDaysOffAsyncThunk } from './api/botDaysOffSlice';
import BotDaysOffForm from './BotDaysOffForm';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../../common/modals/LoadingModal';

interface BotDaysOffDetailedProps {
  botDaysOff: CampaignNonWorkingDaysConfiguration;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  hasPermission: boolean;
}

const BotDaysOffDetailed: React.FC<BotDaysOffDetailedProps> = ({
  backToList,
  botDaysOff,
  findProcessNamesById,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(botDaysOff.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(botDaysOff.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editBotDaysOff, setEditBotDaysOff] =
    useState<CampaignNonWorkingDaysConfigurationRequest>({
      strategy: '',
      name: '',
      holidays: [],
    });

  useEffect(() => {
    setEditBotDaysOff(botDaysOff);
  }, [botDaysOff]);

  const handleBotEditClick = () => {
    const id = botDaysOff.id;
    if (editBotDaysOff && id) {
      const args = { id: id, data: editBotDaysOff };
      setIsLoadingVisible(true);
      dispatch(updateBotDaysOffAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditBotDaysOff(botDaysOff);
  };

  const associatedWithProcesses = findProcessNamesById(botDaysOff.id);

  const handleBackToDetailed = () => {
    setEdit(false);
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {hasPermission && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleBotEditClick}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{botDaysOff.name}</H2>
            <H4 $grey>
              {t('common.created')} {formattedCreated}, {t('common.modified')}{' '}
              {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <FormRow label={<H3>{t('presets.daysOff.holidaysDate')}</H3>}>
            <DaysOffDatesContainer>
              {botDaysOff.holidays && botDaysOff.holidays.length ? (
                botDaysOff.holidays.map((date, index) => {
                  return (
                    <DayOffContainer key={`date-${index}`}>
                      <H3>{moment(date).format('D MMMM YYYY')}</H3>
                    </DayOffContainer>
                  );
                })
              ) : (
                <div>{t('presets.daysOff.nothingToShow')}</div>
              )}
            </DaysOffDatesContainer>
          </FormRow>
        </FormContainer>
      ) : (
        <BotDaysOffForm
          botDaysOff={editBotDaysOff}
          setBotDaysOff={setEditBotDaysOff}
          backToList={backToList}
          backToDetailed={handleBackToDetailed}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default BotDaysOffDetailed;

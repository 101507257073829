import React, { useEffect } from 'react';
import { SftpConfiguration } from '../../../../../../../generated/tenants/Api';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../../../common/modals/Modals.style';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../../../../App.style';
import {
  checkSftpDelete,
  clearDeleteSftp,
  deleteSftpConfigurationAsyncThunk,
  getSftpConfigurationsListAsyncThunk,
} from '../api/sftpConfigSlice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import Modal from '../../../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface DeleteSftpConfigurationModalProps {
  isVisible: boolean;
  closeModal: () => void;
  sftpConfig: SftpConfiguration;
  clearSelectedSftp: () => void;
}

const DeleteSftpConfigurationModal: React.FC<
  DeleteSftpConfigurationModalProps
> = ({ isVisible, closeModal, sftpConfig, clearSelectedSftp }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const checkDelete = useAppSelector(checkSftpDelete);
  useEffect(() => {
    if (
      checkDelete.state === 'idle' &&
      checkDelete.value?.status === 'success'
    ) {
      dispatch(clearDeleteSftp());
      dispatch(getSftpConfigurationsListAsyncThunk());
      closeModal();
      clearSelectedSftp();
    }
  });

  const deleteSftpConfiguration = () => {
    dispatch(deleteSftpConfigurationAsyncThunk(sftpConfig.id));
  };

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteSftpConfiguration();

    return false;
  };

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>{t('settings.adminPanel.sftp.question')}</H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('settings.adminPanel.sftp.removeSftp')}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default DeleteSftpConfigurationModal;

import { ApiError } from '../../../../../common/types';
import {
  Label,
  LabelRequest,
  ModifyLabelsRequest,
} from '../../../../../generated/tenants/Api';
import { Api } from '../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckLabelsResult {
  status: 'success' | 'fail';
  error?: ApiError<Label>;
  label?: Label;
}

export const getLabelsList = (id: string) =>
  api.strategiesLabelsList(id).then((_) => _.data);

export const createLabel = (data: LabelRequest): Promise<CheckLabelsResult> =>
  api
    .labelsCreate(data)
    .then((response) => {
      return { status: 'success', label: response.data } as CheckLabelsResult;
    })
    .catch((response) => {
      return { status: 'success', error: response.error };
    });

export const updateLabel = (
  id: string,
  data: LabelRequest,
): Promise<CheckLabelsResult> =>
  api
    .labelsUpdate(id, data)
    .then(() => {
      return { status: 'success' } as CheckLabelsResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const labelDetailedView = (id: string) =>
  api.labelsRetrieve(id).then((_) => _.data);

export const deleteLabel = (id: string) =>
  api.labelsDestroy(id).then((_) => _.data);

//campaign labels
export const campaignLabelsUpdate = (
  campaignId: string,
  data: ModifyLabelsRequest,
) => api.campaignsLabelsUpdate(campaignId, data).then((_) => _.data);

import React from 'react';
import { H1, H4, H2 } from '../../../../App.style';
import {
  AnalyticsOverwiewCardContainer,
  AnalyticsOverwiewCardTextContainer,
  AnalyticsOverwiewCardContentContainer,
  AnalyticsChartNumbersContainer,
  AnalyticsChartCornerTextContainer,
  FakeLoadingImage,
} from '../../Analytics.style';
import { PieChart, Pie, Cell } from 'recharts';
import Ramka1Img from '../../../../../resources/analytics/Ramka1.png';

interface PieChartCardProps {
  title: string;
  subtitle: string;
  chartColor: string;
  percentage: number;
  cornerText: {
    value: number;
    total: number;
  };
  isLoading?: boolean;
}

const PieChartCard: React.FC<PieChartCardProps> = ({
  title,
  subtitle,
  percentage,
  chartColor,
  cornerText,
  isLoading,
}) => {
  const RADIAN = Math.PI / 180;

  const createChartData = (percentage: number) => {
    return [
      { name: 'A', value: percentage, color: chartColor }, // The provided percentage
      { name: 'B', value: 100 - percentage, color: '#CBD2D9' }, // The reminder to complete 100%
    ];
  };
  const cx = 170;
  const cy = 120;
  const iR = 60;
  const oR = 100;

  const needle = (
    value: number,
    data: { name: string; value: number }[],
    cx: number,
    cy: number,
  ) => {
    let total = 0;
    data.forEach((v: { name: string; value: number }) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = 110;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={'black'} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none"
        fill={'black'}
      />,
    ];
  };

  const chart1Data = createChartData(percentage ?? 0);
  return (
    <AnalyticsOverwiewCardContainer noPadding={isLoading}>
      {isLoading ? (
        <FakeLoadingImage src={Ramka1Img} />
      ) : (
        <>
          <AnalyticsOverwiewCardTextContainer>
            <H1 $medium>{title}</H1>
            <H4 $wrap>{subtitle}</H4>
          </AnalyticsOverwiewCardTextContainer>
          <AnalyticsOverwiewCardContentContainer>
            <PieChart width={300} height={150}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={chart1Data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
              >
                {chart1Data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>

              {needle(percentage ?? 0, chart1Data, cx, cy)}
            </PieChart>
            <AnalyticsChartNumbersContainer>
              <H2>0</H2>
              <H1 style={{ marginLeft: 10, marginTop: 8 }}>{percentage}%</H1>
              <H2>100</H2>
            </AnalyticsChartNumbersContainer>
          </AnalyticsOverwiewCardContentContainer>
          <AnalyticsChartCornerTextContainer>
            <H4 $medium>{cornerText.value ?? 0}</H4>
            <H4>/ {cornerText.total ?? 0}</H4>
          </AnalyticsChartCornerTextContainer>
        </>
      )}
    </AnalyticsOverwiewCardContainer>
  );
};

export default PieChartCard;

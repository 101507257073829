import styled, { css } from 'styled-components';
import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_50,
  H3,
  H4,
  H6,
} from '../../../App.style';

export const BuilderPanelContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;

  flex-direction: column;

  z-index: 100;

  background: ${COLOR_NEUTRAL_10};
`;

export const VindicationBuilderContentContainer = styled.div`
  width: calc(100% - 180px);
  height: calc(100vh - 50px);

  border-radius: 5px;

  background: ${COLOR_NEUTRAL_20};

  display: flex;
  justify-content: center;
`;

export const BuilderNavbarContainer = styled.div`
  width: 100%;
  height: 50px;
  background: ${COLOR_NEUTRAL_10};
  padding: 8px 12px;
  box-shadow: 0px 2px 2px 0px rgba(175, 175, 175, 0.3);
  display: flex;
  justify-content: space-between;
`;

export const BuilderNavbarLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BuilderNavbarRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const VindicationContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 100px;
`;

//sidebar

export const VindicationBuilderSidebarContainer = styled.div<{
  superAdmin?: boolean;
}>`
  height: calc(100vh - 50px);
  width: 180px;

  background: #0f172a;

  padding: 16px 0px;
  border-right: 1px solid ${COLOR_NEUTRAL_20};

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

export const VindicationBuilderSidebarSectionContainer = styled.div<{
  $selected?: boolean;
  $noHover?: boolean;
}>`
  width: 100%;

  margin-bottom: 5px;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;

  justify-content: space-between;

  flex-direction: column;
  color: white;

  background: ${(props) => props.$selected && COLOR_NEUTRAL_50};

  border-radius: 5px;

  border-bottom: 1px solid #3e4c59;

  ${H3} {
    padding: 8px;
  }

  ${H6} {
    color: white;
  }

  &:hover,
  &:hover * {
    background: ${(props) => (props.$noHover ? '' : COLOR_NEUTRAL_50)};
  }
`;

//kroki
export const VindicationBuilderSidebarStepContainer = styled.div<{
  $selected?: boolean;
  $lineDown?: boolean;
}>`
  position: relative;
  padding: 3px 0px;
  margin-top: 12px;

  ${({ $lineDown }) =>
    $lineDown &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 14px;
      left: 15.5px;
      width: 1px;
      height: 100%;
      background-color: white;
      z-index: 0;
    }
  `}

  ${H6} {
    color: white;
  }

  background: ${(props) => (props.$selected ? '#4d576b' : '')};

  &:hover {
    background: #4d576b;
  }
`;

export const VindicationBuilderSidebarStatusCircle = styled.div<{
  $dot?: boolean;
}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  background: #0f172a;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(-50%, -50%);
  border: ${(props) => (props.$dot ? '' : `1px solid ${COLOR_NEUTRAL_40}`)};
  z-index: 1;
`;

export const VindicationBuilderSidebarStepContent = styled.div<{
  $status: 'success' | 'error';
}>`
  display: flex;
  gap: 6px;
  margin-left: 24px;

  /* height: 32px; */

  display: flex;
  align-items: center;

  padding-left: 8px;
  border-radius: 6px;

  max-width: 480px;

  ${H4} {
    color: white;
  }

  ${({ $status }) =>
    $status === 'error' &&
    css`
      background: #f8e9e9;
    `}
`;

import React, { useRef, useState } from 'react';
import {
  CustomLabelTSpan,
  DashboardCampaigPreviewFourthContainer,
  DashboardCampaigPreviewSecondContainer,
  DashboardCampaigPreviewThirdContainer,
  DashboardCampaignPreviewContainer,
  DashboardCampaignPreviewContentContainer,
  DashboardCampaignPreviewFirstContainer,
  DashboardCampaignPreviewFirstRightContainer,
  DashboardCampaignPreviewThirdContentContainer,
  DashboardCampaignPreviewTitleContainer,
  DashboardCampaignsPreviewCallsBar,
} from './DashboardPanel.style';
import { WarningIcon } from '../../../resources/icons-new/WarningIcon';
import { COLOR_NEUTRAL_80, H2, H3, H4, H5 } from '../../App.style';
import { ArrowRightIcon } from '../../../resources/icons-new/ArrowRightIcon';
import {
  PieChart,
  Pie,
  Cell,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import {
  ProgressBarContainer,
  Progress,
  ProgressBarNumbersContainer,
  ProgressBarHoverContainer,
} from '../analytics/Analytics.style';
import { CampaignDashboardData } from '../../../generated/tenants/Api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { renderStatus } from '../campaigns/panel/campaignTableFunctions';
import { useTranslation } from 'react-i18next';
6;

interface DashboardCampaignPreviewProps {
  data: CampaignDashboardData;
}

const DashboardCampaignPreview: React.FC<DashboardCampaignPreviewProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderCustomizedLabel = ({ cx, cy }: { cx: number; cy: number }) => {
    return (
      <text x={cx} y={cy - 15} textAnchor="middle" dominantBaseline="central">
        <CustomLabelTSpan>{data.outbound_calls}</CustomLabelTSpan>
        <CustomLabelTSpan
          x={cx}
          dy="1.2em"
          style={{
            fontSize: 14,
          }}
        >
          {t('dashboard.outbound')}
        </CustomLabelTSpan>
      </text>
    );
  };

  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [isHoverVisible, setIsHoverVisible] = useState(false);
  const barRef = useRef<HTMLDivElement>(null);

  const handleShowProgressHover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (barRef.current) {
      const { top, left } = barRef.current.getBoundingClientRect();
      setHoverPosition({
        x: event.clientX - left + 10,
        y: event.clientY - top + 10,
      });
    }
  };
  const handleShowHover = () => setIsHoverVisible(true);
  const handleCloseHover = () => {
    setIsHoverVisible(false);
  };

  const handleCampaignTitleClick = () => {
    navigate(`/dashboard/campaigns/${data.id}`);
  };

  function roundToTwo(num: number) {
    return Number(num.toFixed(2));
  }

  const pieChartData = [
    {
      name: t('dashboard.voicemail'),
      value: data.voicemail_calls,
      color: '#001746',
    }, // 50% wypełnienia
    {
      name: t('dashboard.answered'),
      value: data.answered_calls,
      color: '#5B7BA1',
    }, // 30% wypełnienia
    { name: t('dashboard.other'), value: data.other_calls, color: '#CFECFD' }, // 20% wypełnienia
  ];

  return (
    <DashboardCampaignPreviewContainer>
      <DashboardCampaignPreviewTitleContainer
        onClick={handleCampaignTitleClick}
      >
        {data.status === 'PAUSED' && <WarningIcon color="#983700" />}
        <H2>
          <span style={{ fontWeight: 500, marginRight: 8 }}>
            {data.process_name}{' '}
          </span>
          {moment(data.start_date).format('DD.MM.YYYY')}-
          {moment(data.end_date).format('DD.MM.YYYY')}
        </H2>
        <ArrowRightIcon />
        <H4>{renderStatus(data.status, 'text')}</H4>
      </DashboardCampaignPreviewTitleContainer>
      <DashboardCampaignPreviewContentContainer>
        <DashboardCampaignPreviewFirstContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 16,
            }}
          >
            <PieChart width={125} height={125}>
              <Pie
                style={{ border: '1px solid red' }}
                data={pieChartData}
                cx={50}
                cy={50}
                innerRadius={45}
                outerRadius={55}
                fill="#8884d8"
                dataKey="value"
                labelLine={false}
                label={renderCustomizedLabel}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
            <DashboardCampaignsPreviewCallsBar style={{ marginTop: 10 }}>
              <div style={{ width: 7, height: 5, background: '#5B7BA1' }} />
              <H4>
                {data.answered_calls} {t('dashboard.answered')}
              </H4>
            </DashboardCampaignsPreviewCallsBar>
            <DashboardCampaignsPreviewCallsBar
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
              }}
            >
              <div style={{ width: 7, height: 5, background: '#001746' }} />
              <H4>
                {data.voicemail_calls} {t('dashboard.voicemail')}
              </H4>
            </DashboardCampaignsPreviewCallsBar>

            <DashboardCampaignsPreviewCallsBar
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
              }}
            >
              <div style={{ width: 7, height: 5, background: '#CFECFD' }} />
              <H4>
                {data.other_calls} {t('dashboard.other')}
              </H4>
            </DashboardCampaignsPreviewCallsBar>
          </div>

          <DashboardCampaignPreviewFirstRightContainer>
            <H2 $medium style={{ marginBottom: 8 }}>
              {data.inbound_calls}
            </H2>
            <H3>{t('dashboard.inbound')}</H3>

            <H2 style={{ marginTop: 16, marginBottom: 8 }} $medium>
              {data.call_center_calls_count}
            </H2>
            <H3>{t('dashboard.callCenter')}</H3>
          </DashboardCampaignPreviewFirstRightContainer>
        </DashboardCampaignPreviewFirstContainer>

        <DashboardCampaigPreviewSecondContainer>
          <H3>{t('dashboard.databaseCompletion')}</H3>
          <ProgressBarContainer
            style={{ marginTop: 50 }}
            ref={barRef}
            onMouseMove={handleShowProgressHover}
            onMouseEnter={handleShowHover}
            onMouseLeave={handleCloseHover}
          >
            <div style={{ position: 'absolute', top: -30, left: 0 }}>
              <H2 $medium>{roundToTwo(data.database_completion_percent)}%</H2>
            </div>
            <Progress
              progress={data.database_completion_percent}
              style={{ width: `${data.database_completion_percent}%` }}
            />
            <ProgressBarHoverContainer
              style={{ left: hoverPosition.x, top: hoverPosition.y }}
              isVisible={isHoverVisible}
            >
              <H3 style={{ color: '#127fbf' }}>
                {roundToTwo(data.database_completion_percent)}%
              </H3>
              <H3>
                {data.closed_records_count} {t('dashboard.records')}
              </H3>
            </ProgressBarHoverContainer>
            <ProgressBarNumbersContainer>
              <H5>0</H5>

              <H5>
                {data.closed_records_count}/ {data.total_records_count}
              </H5>
            </ProgressBarNumbersContainer>
          </ProgressBarContainer>
        </DashboardCampaigPreviewSecondContainer>

        <DashboardCampaigPreviewThirdContainer>
          <H3>{t('dashboard.callAnalysis')}</H3>
          <DashboardCampaignPreviewThirdContentContainer
            style={{
              marginTop: 36,
            }}
          >
            <H3 style={{ color: COLOR_NEUTRAL_80 }}>
              {t('dashboard.callSuccessRate')}
            </H3>
            <H3 $selected>{roundToTwo(data.successful_calls_percent)} %</H3>
          </DashboardCampaignPreviewThirdContentContainer>
          <DashboardCampaignPreviewThirdContentContainer>
            <H3 style={{ color: COLOR_NEUTRAL_80 }}>
              {t('dashboard.callConversionRate')}
            </H3>
            <H3 $selected>{roundToTwo(data.conversion_calls_percent)} %</H3>
          </DashboardCampaignPreviewThirdContentContainer>
          <DashboardCampaignPreviewThirdContentContainer
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <H3 style={{ color: COLOR_NEUTRAL_80 }}>
              {t('dashboard.callHotRate')}
            </H3>
            <H3 $selected>{roundToTwo(data.hot_calls_percent)} %</H3>
          </DashboardCampaignPreviewThirdContentContainer>
        </DashboardCampaigPreviewThirdContainer>

        <DashboardCampaigPreviewFourthContainer>
          <H3>{t('dashboard.callActivityChart')}</H3>

          <ResponsiveContainer
            width="100%"
            height="80%"
            style={{ marginTop: 16 }}
          >
            <LineChart data={data.time_series}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />

              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#127FBF"
                dot={<></>}
              />
            </LineChart>
          </ResponsiveContainer>
        </DashboardCampaigPreviewFourthContainer>
      </DashboardCampaignPreviewContentContainer>
    </DashboardCampaignPreviewContainer>
  );
};

export default DashboardCampaignPreview;

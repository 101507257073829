import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ArrowLeftLongIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="56"
    height="8"
    viewBox="0 0 56 8"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M0.146446 3.64645C-0.0488167 3.84171 -0.0488167 4.1583 0.146446 4.35356L3.32843 7.53554C3.52369 7.7308 3.84027 7.7308 4.03553 7.53554C4.2308 7.34028 4.2308 7.02369 4.03553 6.82843L1.20711 4L4.03553 1.17158C4.2308 0.976315 4.2308 0.659733 4.03553 0.464471C3.84027 0.269208 3.52369 0.269208 3.32843 0.464471L0.146446 3.64645ZM55.5 3.5L0.5 3.5L0.5 4.5L55.5 4.5L55.5 3.5Z" />
  </StyledSvg>
);

import styled from 'styled-components';

interface FilterButtonProps {
  long?: boolean;
}

export const FilterButton = styled.div<FilterButtonProps>`
  padding: 0px 8px;
  width: ${(props) => (props.long ? '120px' : '110px')};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const FilterBarButton = styled.div`
  padding: 2px 6px;
  display: flex;
  height: 30px;
  gap: 6px;
  background: #f2f2f2;
  border-radius: 5px;

  align-items: center;
`;

export const FiltersContainer = styled.div`
  width: 170px;
`;

export const FiltersTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px;
  box-shadow: 0px -1px 0px 0px rgba(211, 218, 230, 0.5) inset;
`;

export const FiltersContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FiltersOptionContainer = styled.div`
  box-shadow: 0px -1px 0px 0px rgba(211, 218, 230, 0.5) inset;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  background: white;

  &:hover {
    filter: brightness(90%);
  }
`;

export const FiltersBottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 8px 0px;
`;

export const EmptyFilterContainer = styled.div`
  padding: 8px 12px;
  width: 140px;

  border-radius: 6px;

  background: white;
`;

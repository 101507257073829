import styled from 'styled-components';
import { COLOR_NEUTRAL_20, H4 } from '../../../../../App.style';

export const DialogueTokensWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DialogueTokensLeftContainer = styled.div`
  width: 48%;
`;

export const DialogueTokensRightContainer = styled.div`
  width: 48%;
`;

export const DialogueTokensContentContainer = styled.div`
  margin-top: 8px;
  padding: 8px;
  background: white;
  min-height: 350px;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  display: flex;
`;

export const CustomTokensListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  height: 100%;
  margin-bottom: 20px;
`;

export const CustomTokensListActionsContainer = styled.div`
  display: flex;
  gap: 15px;

  ${H4} {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const CustomTokensRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding: 6px 0px;

  border-bottom: 0.6px solid ${COLOR_NEUTRAL_20};
`;

import { EuiFieldText } from '@elastic/eui';
import React from 'react';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import { VoiceConfiguration } from '../../../../generated/tenants/Api';
import FormRowTitle from '../../processes/forms/FormRowTitle';
import { useTranslation } from 'react-i18next';

interface VoiceFormReadOnlyProps {
  voice: VoiceConfiguration;
}

const VoiceFormReadOnly: React.FC<VoiceFormReadOnlyProps> = ({ voice }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.language')}
            tooltip={t('presets.voicesForm.languageTooltip')}
          />
        }
      >
        <EuiFieldText value={voice.language} readOnly={true} />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.gender')}
            tooltip={t('presets.voicesForm.genderTooltip')}
          />
        }
      >
        <EuiFieldText
          value={
            voice.gender === 'MALE'
              ? t('processes.form.voices.male')
              : t('processes.form.voices.female')
          }
          readOnly={true}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.baseTtsSpeed')}
            tooltip={t('presets.voicesForm.baseTtsSpeedTooltip')}
          />
        }
      >
        <EuiFieldText value={voice.base_tts_speed_percentage} readOnly={true} />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.asrVendor')}
            tooltip={t('presets.voicesForm.asrVendorTooltip')}
          />
        }
      >
        <EuiFieldText value={voice.asr_vendor} readOnly={true} />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.ttsVendor')}
            tooltip={t('presets.voicesForm.ttsVendorTooltip')}
          />
        }
      >
        <EuiFieldText value={voice.tts_vendor} readOnly={true} />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.voicesForm.voiceName')}
            tooltip={t('presets.voicesForm.voiceNameTooltip')}
          />
        }
      >
        <EuiFieldText value={voice.voice_name} readOnly={true} />
      </FormRow>
    </>
  );
};

export default VoiceFormReadOnly;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  SettingsPanelContainer,
  TenantSettingsContentContainer,
} from '../Settings.style';
import SuperAdminPanelSidebar from './SuperAdminPanelSidebar';
import NumbersAddNew from './components/numbers/NumbersAddNew';
import NumbersTenantAssign from './components/numbers/NumbersTenantAssign';
import NumbersAllHistory from './components/numbers/NumbersAllHistory';
import Tenants from './components/tenants/Tenants';
import SuperUsers from './components/superUsers/SuperUsers';
import Billings from './components/billings/Billings';

const SuperAdminPanel = () => {
  return (
    <SettingsPanelContainer>
      <SuperAdminPanelSidebar />

      <TenantSettingsContentContainer>
        <Routes>
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/" element={<Tenants />} />

          <Route path="/numbers/add" element={<NumbersAddNew />} />
          <Route path="/numbers/assign" element={<NumbersTenantAssign />} />
          <Route path="/numbers/history" element={<NumbersAllHistory />} />

          <Route path="/billings" element={<Billings />} />

          <Route path="/superusers" element={<SuperUsers />} />
        </Routes>
      </TenantSettingsContentContainer>
    </SettingsPanelContainer>
  );
};

export default SuperAdminPanel;

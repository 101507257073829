import { EuiConfirmModal, EuiLoadingSpinner } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  Campaign,
  PaginatedRecordDataList,
} from '../../../../../generated/tenants/Api';
import {
  checkPauseRecords,
  getCampaignRecordsListAsyncThunk,
  pauseRecordsAsyncThunk,
  selectCampaignRecordsList,
} from '../../api/campaignsSlice';
import {
  EmptyRecordsContainer,
  EmptyRecordsContent,
  RecordsBarContainer,
  RecordsListContainer,
} from './Records.style';
import { Button, Center, H3 } from '../../../../App.style';
import RecordsTable from './RecordsTable';
import UploadRecordsModal from './UploadRecordsModal';
import RecordsFilters, { RecordsFiltersValues } from './filters/RecordsFilters';
import { UploadIcon } from '../../../../../resources/icons-new/UploadIcon';
import { useTranslation } from 'react-i18next';
import ErrorPopup from '../../../../../common/popups/ErrorPopup';

interface RecordsListProps {
  campaign: Campaign;
  handleCallsHistoryClick: (number: string) => void;
  hasCampaignAccess: boolean;
}

const RecordsList: React.FC<RecordsListProps> = ({
  campaign,
  handleCallsHistoryClick,
  hasCampaignAccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  //records from api
  const records = useAppSelector(selectCampaignRecordsList);

  const [loadingRecords, setLoadingRecords] = useState<boolean>(true);

  useEffect(() => {
    setRecordsList(records);

    setTimeout(() => {
      setLoadingRecords(false);
    }, 500);
  }, [records, campaign.id]);

  const [recordsList, setRecordsList] = useState<PaginatedRecordDataList>();

  //state of filters
  const [filter, setFilter] = useState<RecordsFiltersValues>({
    source: '',
    search: '',
    statuses: undefined,
  });

  //holds all selected users
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [tableKeyValue, setTableKeyValue] = useState<0 | 1>(0);

  const handlePauseSelectedRecords = () => {
    const data = { campaignId: campaign.id, query: { id: selectedRecords } };
    dispatch(pauseRecordsAsyncThunk(data));
  };

  const checkRecords = useAppSelector(checkPauseRecords);

  useEffect(() => {
    if (checkRecords.state === 'idle') {
      setSelectedRecords([]);
      setTableKeyValue(tableKeyValue === 0 ? 1 : 0);
      closeDisableRecordsModal();
    }
  }, [checkRecords]);

  //disable records confirm modal
  const [isDisableRecordsModalVisible, setIsDisableRecordsModalVisible] =
    useState<boolean>(false);

  const openDisableRecordsModal = () => {
    setIsDisableRecordsModalVisible(true);
  };
  const closeDisableRecordsModal = () => {
    setIsDisableRecordsModalVisible(false);
  };

  let disableRecordsModal;
  if (isDisableRecordsModalVisible) {
    disableRecordsModal = (
      <EuiConfirmModal
        title="Disabling records"
        onCancel={closeDisableRecordsModal}
        onConfirm={handlePauseSelectedRecords}
        cancelButtonText="No, go back"
        confirmButtonText="Yes, disable"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        Are you sure you want to disable{' '}
        {selectedRecords.length === 1
          ? '1 record'
          : `${selectedRecords.length} records`}
        ?
      </EuiConfirmModal>
    );
  }

  const [isUploadRecordsModalVisible, setIsUploadRecordsModalVisible] =
    useState<boolean>(false);

  const showUploadRecordsModal = () => setIsUploadRecordsModalVisible(true);
  const closeUploadRecordsModal = () => setIsUploadRecordsModalVisible(false);

  const [errorPopup, setErrorPopup] = useState<{
    isVisible: boolean;
    error: string;
  }>({ isVisible: false, error: '' });
  const openErrorPopup = (error: string) => {
    setErrorPopup({ isVisible: true, error });
  };

  const closeErrorPopup = () => {
    setErrorPopup({ isVisible: false, error: '' });
  };

  const [ordering, setOrdering] = useState<'-created_at' | 'created_at'>(
    'created_at',
  );
  //table settings
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);

  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };

  useEffect(() => {
    const { source, search, statuses } = filter;

    const query: {
      imported_from?: string;
      limit?: number;
      offset?: number;
      ordering?: '-created_at' | 'created_at';
      phone_number?: string;
      record_id?: string;
      status_list?: ('OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED')[];
    } = {
      imported_from: source,
      limit: itemsPerPage,
      offset: offset,
      phone_number: search,
      ordering: ordering,
      status_list: statuses,
    };

    if (search.length === 36) {
      query.phone_number = undefined;
      query.record_id = search;
    }

    const data = {
      id: campaign.id,
      query,
    };
    dispatch(getCampaignRecordsListAsyncThunk(data));
  }, [filter, offset, limit, tableKeyValue, campaign.id, ordering]);

  const [emptyRecords, setEmptyRecords] = useState<boolean>(false);
  useEffect(() => {
    if (
      recordsList?.results?.length === 0 &&
      filter.source === '' &&
      filter.search === '' &&
      filter.statuses === undefined
    ) {
      setEmptyRecords(true);
    } else {
      setEmptyRecords(false);
    }
  }, [recordsList]);

  return (
    <RecordsListContainer>
      {disableRecordsModal}

      {errorPopup.isVisible && (
        <ErrorPopup text={errorPopup.error} onClose={closeErrorPopup} />
      )}

      <UploadRecordsModal
        isVisible={isUploadRecordsModalVisible}
        campaignId={campaign.id}
        closeModal={closeUploadRecordsModal}
        openErrorPopup={openErrorPopup}
        closeErrorPopup={closeErrorPopup}
      />

      {loadingRecords ? (
        <Center>
          <EuiLoadingSpinner size={'xxl'} />
        </Center>
      ) : emptyRecords ? (
        <Center>
          <EmptyRecordsContainer>
            <EmptyRecordsContent>
              <UploadIcon style={{ width: 23, height: 23 }} />

              {hasCampaignAccess ? (
                <H3>{t('campaigns.records.list.uploadRecordsTitle')}</H3>
              ) : (
                <H3>You dont have access to upload records</H3>
              )}
            </EmptyRecordsContent>
            {hasCampaignAccess && (
              <Button onClick={showUploadRecordsModal}>
                {t('campaigns.records.list.uploadRecords')}
              </Button>
            )}
          </EmptyRecordsContainer>
        </Center>
      ) : (
        <>
          <RecordsBarContainer>
            {campaign.status !== 'ERROR' &&
              campaign.status !== 'FINISHED' &&
              hasCampaignAccess && (
                <div style={{ marginRight: 8 }}>
                  {selectedRecords.length === 0 ? (
                    <>
                      <Button $size={'M'} onClick={showUploadRecordsModal}>
                        <UploadIcon color={'white'} />
                        {t('campaigns.records.list.uploadRecords')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      $styleType="RED"
                      $size="S"
                      onClick={openDisableRecordsModal}
                    >
                      Disable {selectedRecords.length}
                      {selectedRecords.length === 1 ? ' record' : ' records'}
                    </Button>
                  )}
                </div>
              )}

            <RecordsFilters filters={filter} setFilters={setFilter} />
          </RecordsBarContainer>

          {/* table component */}
          <RecordsTable
            campaignId={campaign.id}
            recordsList={recordsList}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            handlePageChange={handlePageChange}
            ordering={ordering}
            setOrdering={setOrdering}
            handleCallsHistoryClick={handleCallsHistoryClick}
            hasCampaignAccess={hasCampaignAccess}
          />
        </>
      )}
    </RecordsListContainer>
  );
};

export default RecordsList;

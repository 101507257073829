import {
  AnalyticsRequest,
  Api,
  CallStatusDistributionRequest,
} from '../../../../generated/tenants/Api';

const { api } = new Api();

export const getProcessCallStatusChartData = (
  processId: string,
  data: CallStatusDistributionRequest,
) =>
  api
    .processesCallStatusDistributionCreate(processId, data)
    .then((_) => _.data);

export const getProcessesMetricsOverview = (
  processId: string,
  data: AnalyticsRequest,
) => api.processesMetricsOverviewCreate(processId, data).then((_) => _.data);

export const getProcessesStatusCharts = (
  processId: string,
  data: AnalyticsRequest,
) => api.processesProcessStatusChartCreate(processId, data).then((_) => _.data);

export const getDashboardData = (strategyId: string) =>
  api.strategiesDashboardDataRetrieve(strategyId).then((_) => _.data);

import React from 'react';
import { CallsFiltersValues } from './CallsFilters';
import { EuiPopover, EuiSuperSelect } from '@elastic/eui';
import { Button, H4, H5 } from '../../../../../App.style';
import {
  FilterButton,
  FilterBarButton,
  FiltersContainer,
  FiltersTopContainer,
  FiltersContentContainer,
  FiltersBottomContainer,
} from '../../../panel/bar/filters/CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { useTranslation } from 'react-i18next';

interface CallsCallTypeFilterProps {
  barButton: boolean;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedCallType: 'INBOUND' | 'OUTBOUND';
  setSelectedCallType: React.Dispatch<
    React.SetStateAction<'INBOUND' | 'OUTBOUND'>
  >;
  filters: CallsFiltersValues;
  setFilters: (value: React.SetStateAction<CallsFiltersValues>) => void;
}

const CallsCallTypeFilter: React.FC<CallsCallTypeFilterProps> = ({
  barButton,
  isOpen,
  setIsOpen,
  selectedCallType,
  setSelectedCallType,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  type CallTypeOptions = 'INBOUND' | 'OUTBOUND';

  const handleApplyCallType = () => {
    setFilters((prev) => ({ ...prev, call_type: selectedCallType }));
    setIsOpen(false);
  };

  const handleClearCallType = () => {
    setSelectedCallType('INBOUND');
    setFilters((prev) => ({ ...prev, call_type: undefined }));
    setIsOpen(false);
  };

  const selectOptions = [
    { value: 'INBOUND', inputDisplay: t('campaigns.calls.filters.inbound') },
    { value: 'OUTBOUND', inputDisplay: t('campaigns.calls.filters.outbound') },
  ];

  const handleChangeCallType = (value: string) => {
    setSelectedCallType(value as CallTypeOptions);
  };
  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            {t('campaigns.calls.filters.callType')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <H4 $bold>{t('campaigns.calls.filters.callTypeIs')}</H4>{' '}
            <H4>{filters.call_type?.toLowerCase()}</H4>
            <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <FiltersContainer>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.calls.filters.callTypeIs')}</H4>
          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleClearCallType}
            >
              {t('campaigns.calls.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer
          style={{
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <EuiSuperSelect
            autoFocus
            valueOfSelected={selectedCallType}
            options={selectOptions}
            onChange={handleChangeCallType}
          />
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button onClick={handleApplyCallType} $size="S" color="white">
            {t('campaigns.calls.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </FiltersContainer>
    </EuiPopover>
  );
};

export default CallsCallTypeFilter;

import { EuiFieldText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { UserChangePasswordRequest } from '../../../../../generated/public/Api';
import { selectConfig } from '../../../session/api/sessionSlice';
import {
  userPasswordChangeAsyncThunk,
  checkUserPasswordChangeResult,
  clearPasswordChangeState,
} from '../api/userSettingsSlice';
import { Button, H2, H3 } from '../../../../App.style';
import {
  SecurityButtonContainer,
  SecurityContentContainer,
  SecurityContainer,
} from './Security.style';
import { FormRow } from '../../../processes/forms/ProcessForm.style';
import { PasswordVisibilityToggle } from '../../../../../common/inputs/Inputs.style';
import { EyeIcon } from '../../../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../../../resources/icons-new/EyeSlashIcon';
import SuccessPopup from '../../../../../common/popups/SuccessPopup';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../../common/modals/LoadingModal';

const PasswordChange = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [newPasswordNotEqual, setNewPasswordNotequal] =
    useState<boolean>(false);
  const [changePassword, setChangePassword] =
    useState<UserChangePasswordRequest>({
      current_password: '',
      new_password: '',
      confirm_password: '',
    });

  const config = useAppSelector(selectConfig);

  const handleChangePasswordClick = () => {
    if (
      changePassword.new_password === changePassword.confirm_password &&
      config.value
    ) {
      const data = {
        data: changePassword,
        baseDomain: config.value?.base_domain,
      };
      setIsLoadingVisible(true);
      dispatch(userPasswordChangeAsyncThunk(data)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      setNewPasswordNotequal(true);
    }
  };

  const [passwordApiErrors, setPasswordApiErrors] = useState<{
    newPassword: boolean;
    wrongCurrentPassword: boolean;
  }>({
    newPassword: false,
    wrongCurrentPassword: false,
  });

  //api data
  const checkPasswordChangeResult = useAppSelector(
    checkUserPasswordChangeResult,
  );
  const apiError = checkPasswordChangeResult.value;

  //if everything is ok, show callout and clear form / if not, show error from api
  useEffect(() => {
    if (
      checkPasswordChangeResult.state === 'idle' &&
      checkPasswordChangeResult.value?.status === 'success'
    ) {
      setNewPasswordNotequal(false);
      setChangePassword({
        current_password: '',
        new_password: '',
        confirm_password: '',
      });

      setPasswordApiErrors({ newPassword: false, wrongCurrentPassword: false });
      dispatch(clearPasswordChangeState());
      setChangePasswordSuccessPopup(true);
    } else if (
      checkPasswordChangeResult.state === 'idle' &&
      apiError?.status === 'fail' &&
      apiError.error === 400
    ) {
      setPasswordApiErrors({ ...passwordApiErrors, newPassword: true });
    } else if (
      checkPasswordChangeResult.state === 'idle' &&
      apiError?.status === 'fail' &&
      apiError.error === 403
    ) {
      setPasswordApiErrors({
        ...passwordApiErrors,
        wrongCurrentPassword: true,
      });
    }
  }, [checkPasswordChangeResult]);

  const passwordTooShort = t('strenghtBar.tooShort');

  const [passwordVisible, setPasswordVisible] = useState<{
    current_password: boolean;
    new_password: boolean;
    confirm_password: boolean;
  }>({ current_password: false, new_password: false, confirm_password: false });

  const togglePasswordVisibility = (
    field: 'current_password' | 'new_password' | 'confirm_password',
  ) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const validateButton = () => {
    if (
      passwordApiErrors.newPassword ||
      passwordApiErrors.wrongCurrentPassword ||
      newPasswordNotEqual ||
      changePassword.new_password.length < 12 ||
      changePassword.confirm_password.length < 8
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [changePasswordSuccessPopup, setChangePasswordSuccessPopup] =
    useState<boolean>(false);
  const closeChanegPasswordSuccessPopup = () =>
    setChangePasswordSuccessPopup(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <SecurityContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {changePasswordSuccessPopup && (
        <SuccessPopup
          onClose={closeChanegPasswordSuccessPopup}
          text={t('settings.users.passwordChange.success')}
        />
      )}

      <H2 $medium>{t('settings.users.passwordChange.title')}</H2>

      <SecurityContentContainer>
        <form
          style={{ width: 300, marginLeft: 5 }}
          onSubmit={(ev) => {
            ev.preventDefault();
            handleChangePasswordClick();
          }}
        >
          <FormRow
            label={<H3>{t('settings.users.passwordChange.current')}</H3>}
            isInvalid={passwordApiErrors.wrongCurrentPassword}
            error={'Wrong password'}
          >
            <EuiFieldText
              placeholder={t('common.writeHere')}
              type={passwordVisible.current_password ? 'text' : 'password'}
              isInvalid={passwordApiErrors.wrongCurrentPassword}
              append={
                <PasswordVisibilityToggle
                  type={'button'}
                  onClick={() => togglePasswordVisibility('current_password')}
                >
                  {passwordVisible.current_password ? (
                    <EyeIcon style={{ width: 25, height: 25 }} />
                  ) : (
                    <EyeSlashIcon style={{ width: 25, height: 25 }} />
                  )}
                </PasswordVisibilityToggle>
              }
              value={changePassword.current_password}
              onChange={(e) => {
                setChangePassword({
                  ...changePassword,
                  current_password: e.target.value,
                });
                setPasswordApiErrors({
                  ...passwordApiErrors,
                  wrongCurrentPassword: false,
                });
              }}
            />
          </FormRow>

          <FormRow
            label={<H3>{t('settings.users.passwordChange.new')}</H3>}
            isInvalid={newPasswordNotEqual || passwordApiErrors.newPassword}
            error={
              newPasswordNotEqual
                ? ''
                : passwordApiErrors.newPassword &&
                  apiError &&
                  t('common.tooWeak')
            }
          >
            <>
              <EuiFieldText
                placeholder={t('common.writeHere')}
                type={passwordVisible.new_password ? 'text' : 'password'}
                isInvalid={newPasswordNotEqual || passwordApiErrors.newPassword}
                append={
                  <PasswordVisibilityToggle
                    type={'button'}
                    onClick={() => togglePasswordVisibility('new_password')}
                  >
                    {passwordVisible.new_password ? (
                      <EyeIcon style={{ width: 25, height: 25 }} />
                    ) : (
                      <EyeSlashIcon style={{ width: 25, height: 25 }} />
                    )}
                  </PasswordVisibilityToggle>
                }
                value={changePassword.new_password}
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    new_password: e.target.value,
                  });
                  setPasswordApiErrors({
                    ...passwordApiErrors,
                    newPassword: false,
                  });
                  setNewPasswordNotequal(false);
                }}
              />
              <PasswordStrengthBar
                password={changePassword.new_password}
                minLength={12}
                scoreWords={[
                  t('strenghtBar.weak'),
                  t('strenghtBar.weak'),
                  t('strenghtBar.okay'),
                  t('strenghtBar.good'),
                  t('strenghtBar.strong'),
                ]}
                shortScoreWord={passwordTooShort}
              />
            </>
          </FormRow>

          <FormRow
            style={{ marginTop: -20 }}
            label={<H3>Confirm password</H3>}
            isInvalid={newPasswordNotEqual || passwordApiErrors.newPassword}
            error={newPasswordNotEqual && 'Passwords are not equal'}
          >
            <EuiFieldText
              placeholder={t('')}
              type={passwordVisible.confirm_password ? 'text' : 'password'}
              isInvalid={passwordApiErrors.newPassword}
              append={
                <PasswordVisibilityToggle
                  type={'button'}
                  onClick={() => togglePasswordVisibility('confirm_password')}
                >
                  {passwordVisible.confirm_password ? (
                    <EyeIcon style={{ width: 25, height: 25 }} />
                  ) : (
                    <EyeSlashIcon style={{ width: 25, height: 25 }} />
                  )}
                </PasswordVisibilityToggle>
              }
              value={changePassword.confirm_password}
              onChange={(e) => {
                setChangePassword({
                  ...changePassword,
                  confirm_password: e.target.value,
                });
                setPasswordApiErrors({
                  ...passwordApiErrors,
                  newPassword: false,
                });
                setNewPasswordNotequal(false);
              }}
            />
          </FormRow>
          <SecurityButtonContainer>
            <Button type={'submit'} disabled={validateButton()}>
              Change password
            </Button>
          </SecurityButtonContainer>
        </form>
      </SecurityContentContainer>
    </SecurityContainer>
  );
};

export default PasswordChange;

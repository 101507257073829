import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Label } from '../../../../generated/tenants/Api';
import { getCampaignsListAsyncThunk } from '../../campaigns/api/campaignsSlice';
import { getProcessesListAsyncThunk } from '../../processes/api/processSlice';
import {
  checkLabelDelete,
  deleteLabelAsyncThunk,
  getLabelsListAsyncThunk,
  selectLabelsList,
} from './api/labelsSlice';
import LabelFormModal from './LabelFormModal';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { Button, H2, H4 } from '../../../App.style';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../PresetsPanel.style';
import {
  Label as LabelBadge,
  LabelsContentContainer,
  LabelsListActionsContainer,
  LabelsRowContainer,
  LabelsListContainer,
} from './Labels.style';
import LabelDeleteModal from './LabelDeleteModal';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../users/usePermissions';
import LoadingModal from '../../../../common/modals/LoadingModal';

const Labels = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);

  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('process_campaign');

  useEffect(() => {
    dispatch(getLabelsListAsyncThunk(strategy));
  }, []);

  const labelsList = useAppSelector(selectLabelsList);

  //label modal logic
  const [isLabelModalVisible, setIsLabelModalVisible] =
    useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<Label>();

  const openLabelModal = () => setIsLabelModalVisible(true);

  const closeLabelModal = () => {
    setSelectedLabel(undefined);
    setIsLabelModalVisible(false);
  };

  const newLabelClick = () => {
    setSelectedLabel(undefined);
    openLabelModal();
  };

  const editLabelClick = (label: Label) => {
    setSelectedLabel(label);
    openLabelModal();
  };

  //delete label modal logic

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const checkDelete = useAppSelector(checkLabelDelete);

  useEffect(() => {
    if (checkDelete.state === 'idle') {
      dispatch(getLabelsListAsyncThunk(strategy));
      dispatch(
        getProcessesListAsyncThunk({
          id: strategy,
          query: { is_archived: true },
        }),
      );
      dispatch(getCampaignsListAsyncThunk(strategy));
    }
  }, [checkDelete]);

  const handleDeleteLabelAfterModal = () => {
    if (selectedLabel) {
      setIsLoadingVisible(true);
      dispatch(deleteLabelAsyncThunk(selectedLabel?.id)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
    setIsDeleteModalVisible(false);
  };

  const handleDeleteLabelClick = (label: Label) => {
    setSelectedLabel(label);
    setIsDeleteModalVisible(true);
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <LabelFormModal
        isVisible={isLabelModalVisible}
        selectedLabel={selectedLabel}
        closeModal={closeLabelModal}
        setIsLoadingVisible={setIsLoadingVisible}
      />

      {selectedLabel && (
        <LabelDeleteModal
          isVisible={isDeleteModalVisible}
          selectedLabel={selectedLabel}
          deleteLabel={handleDeleteLabelAfterModal}
          closeModal={() => setIsDeleteModalVisible(false)}
        />
      )}

      <LabelsContentContainer>
        <PresetNameContainer>
          <H2>{t('presets.labels')}</H2>
        </PresetNameContainer>

        <LabelsListContainer>
          {labelsList.map((label, i) => {
            return (
              <LabelsRowContainer key={`${label.text}-${i}`}>
                <LabelBadge color={label.color as string}>
                  {label.text}
                </LabelBadge>

                {hasPermission && (
                  <LabelsListActionsContainer>
                    <H4 onClick={() => editLabelClick(label)}>
                      {t('common.edit')}
                    </H4>
                    <H4 onClick={() => handleDeleteLabelClick(label)}>
                      {t('common.delete')}
                    </H4>
                  </LabelsListActionsContainer>
                )}
              </LabelsRowContainer>
            );
          })}
        </LabelsListContainer>

        {hasPermission && (
          <Button $size="S" $styleType="NORMAL" onClick={newLabelClick}>
            {t('presets.addNewLabel')}
          </Button>
        )}
      </LabelsContentContainer>
    </DetailedPresetContainer>
  );
};

export default Labels;

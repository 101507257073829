import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const WarningIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M7.93842 2.0157C7.95578 2.00605 7.97748 2 8.00154 2C8.02561 2 8.04731 2.00605 8.06467 2.0157C8.07966 2.02403 8.09948 2.03926 8.11939 2.07312L14.9763 13.7398C15.012 13.8005 15.0113 13.864 14.9785 13.9231C14.962 13.9528 14.9415 13.9724 14.9238 13.9833C14.909 13.9925 14.8901 14 14.8585 14H1.14459C1.11303 14 1.09404 13.9925 1.07932 13.9833C1.06163 13.9724 1.04106 13.9528 1.02461 13.9231C0.991803 13.864 0.991085 13.8005 1.02675 13.7398L7.8837 2.07312C7.9036 2.03926 7.92343 2.02403 7.93842 2.0157ZM8.98151 1.56642C8.53763 0.811193 7.46546 0.811193 7.02158 1.56642L0.164626 13.2331C-0.292407 14.0107 0.256279 15 1.14459 15H14.8585C15.7468 15 16.2955 14.0107 15.8385 13.2331L8.98151 1.56642Z" />
    <path d="M7.00154 12C7.00154 11.4477 7.44926 11 8.00154 11C8.55383 11 9.00154 11.4477 9.00154 12C9.00154 12.5523 8.55383 13 8.00154 13C7.44926 13 7.00154 12.5523 7.00154 12Z" />
    <path d="M7.0995 5.99504C7.04623 5.46228 7.46459 5 8 5C8.53541 5 8.95377 5.46228 8.9005 5.99504L8.54975 9.50248C8.52151 9.78492 8.28384 10 8 10C7.71616 10 7.47849 9.78492 7.45025 9.50248L7.0995 5.99504Z" />
  </StyledSvg>
);

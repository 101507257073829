import React, { useState } from 'react';
import { EuiPopover } from '@elastic/eui';
import RecordsSourceFilter from './RecordsSourceFilter';
import RecordsStatusesFilter from './RecordsStatusesFilter';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import { RecordsFiltersContainer } from '../Records.style';
import { Button, H4 } from '../../../../../App.style';
import { EmptyFilterContainer } from '../../../panel/bar/filters/CampaignFilters.style';
import { FilterIcon } from '../../../../../../resources/icons-new/FilterIcon';
import { useTranslation } from 'react-i18next';

export interface RecordsFiltersValues {
  source: string;
  search: string;
  statuses?: ('OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED')[];
}

interface RecordsFiltersProps {
  filters: RecordsFiltersValues;
  setFilters: React.Dispatch<React.SetStateAction<RecordsFiltersValues>>;
}

const RecordsFilters: React.FC<RecordsFiltersProps> = ({
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  //source
  const [sourceIsOpen, setSourceIsOpen] = useState(false);
  const [sourceBarIsOpen, setSourceBarIsOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState<string>('');

  //statuses

  const [statusesIsOpen, setStatusesIsOpen] = useState(false);
  const [statusesBarIsOpen, setStatusesBarIsOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  //search

  const setSearch = (value: string) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      search: value,
    }));
  };

  return (
    <RecordsFiltersContainer>
      <div
        style={{
          display: 'flex',
          gap: 8,
        }}
      >
        {filters.source !== '' && (
          <RecordsSourceFilter
            barButton={true}
            isOpen={sourceBarIsOpen}
            setIsOpen={setSourceBarIsOpen}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            filters={filters}
            setFilters={setFilters}
          />
        )}

        {filters.statuses !== undefined && (
          <RecordsStatusesFilter
            barButton={true}
            isOpen={statusesBarIsOpen}
            setIsOpen={setStatusesBarIsOpen}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            setFilters={setFilters}
          />
        )}
      </div>
      <div style={{ display: 'flex', gap: 8 }}>
        <EuiPopover
          isOpen={isOpen}
          closePopover={() => {
            setIsOpen(false);

            setSourceIsOpen(false);
            setStatusesIsOpen(false);
          }}
          button={
            <Button
              $size={'M'}
              $styleType="NORMAL"
              onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
            >
              <FilterIcon />
              {t('campaigns.records.filters.title')}
            </Button>
          }
          panelPaddingSize="none"
          anchorPosition="downLeft"
          hasArrow={false}
        >
          {/* processes popover */}
          <div style={{ width: '100px' }}>
            {filters.source === '' && (
              <RecordsSourceFilter
                barButton={false}
                isOpen={sourceIsOpen}
                setIsOpen={setSourceIsOpen}
                selectedSource={selectedSource}
                setSelectedSource={setSelectedSource}
                filters={filters}
                setFilters={setFilters}
              />
            )}

            {filters.statuses === undefined && (
              <RecordsStatusesFilter
                barButton={false}
                isOpen={statusesIsOpen}
                setIsOpen={setStatusesIsOpen}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                setFilters={setFilters}
              />
            )}
          </div>

          {filters.source !== '' && filters.statuses !== undefined && (
            <EmptyFilterContainer>
              <H4 $grey>{t('campaigns.records.filters.allFiltersApplied')}</H4>
            </EmptyFilterContainer>
          )}
        </EuiPopover>

        <div>
          <SearchInput value={filters.search} setValue={setSearch} />
        </div>
      </div>
    </RecordsFiltersContainer>
  );
};

export default RecordsFilters;

import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiSelect,
  EuiFieldText,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiTextColor,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './token.css';

interface GenderModalBlankFormError {
  maleForm: boolean;
  femaleForm: boolean;
}

interface AddGenderTokenModalProps {
  closeModal: () => void;
  handleAddGenderBasedToken: (text: string) => void;
}

const AddGenderTokenModal: React.FC<AddGenderTokenModalProps> = ({
  closeModal,
  handleAddGenderBasedToken,
}) => {
  const { t } = useTranslation();
  const selectOptions = [
    { value: 'BOT', text: 'bot (blue)' },
    { value: 'CALLER', text: 'caller (lime)' },
  ];
  const [type, setType] = useState<string>(selectOptions[0].value);
  const [femaleStatement, setFemaleStatement] = useState<string>('');
  const [maleStatement, setMaleStatement] = useState<string>('');
  const [blankFieldErrors, setBlankFieldErrors] =
    useState<GenderModalBlankFormError>({ maleForm: false, femaleForm: false });

  const validateForm = () => {
    let result = true;
    let maleForm = false;
    let femaleForm = false;
    if (maleStatement.length === 0) {
      maleForm = true;
      result = false;
    }
    if (femaleStatement.length === 0) {
      femaleForm = true;
      result = false;
    }
    setBlankFieldErrors({ maleForm: maleForm, femaleForm: femaleForm });
    return result;
  };

  const handleAddClick = () => {
    if (validateForm()) {
      let statement = '';
      if (type === 'BOT') {
        statement = `{${femaleStatement}|${maleStatement}}`;
        handleAddGenderBasedToken(statement);
      } else if (type === 'CALLER') {
        statement = `[${femaleStatement}|${maleStatement}]`;
        handleAddGenderBasedToken(statement);
      } else {
        alert('something went wrong ');
      }

      closeModal();
    }
  };

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Gender based statement</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm
          id={'genderModalForm'}
          component="form"
          onSubmit={(ev) => {
            ev.preventDefault();
            handleAddClick();
          }}
        >
          <EuiFormRow label="By whose gender is this statement to be inflected">
            <EuiSelect
              css={{ alignSelft: 'center' }}
              options={selectOptions}
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
          </EuiFormRow>

          <EuiFormRow label="Female form">
            <>
              <EuiFieldText
                isInvalid={blankFieldErrors.femaleForm}
                onBlur={(e) =>
                  setBlankFieldErrors({
                    ...blankFieldErrors,
                    femaleForm: !e.target.value,
                  })
                }
                value={femaleStatement}
                onChange={(e) => {
                  setFemaleStatement(e.target.value);
                  setBlankFieldErrors({
                    ...blankFieldErrors,
                    femaleForm: false,
                  });
                }}
              />
              {blankFieldErrors.femaleForm && (
                <EuiTextColor color={'danger'}>
                  {t('onBlurBlankField')}
                </EuiTextColor>
              )}
            </>
          </EuiFormRow>

          <EuiFormRow label="Male form">
            <>
              <EuiFieldText
                isInvalid={blankFieldErrors.maleForm}
                onBlur={(e) =>
                  setBlankFieldErrors({
                    ...blankFieldErrors,
                    maleForm: !e.target.value,
                  })
                }
                value={maleStatement}
                onChange={(e) => {
                  setMaleStatement(e.target.value);
                  setBlankFieldErrors({ ...blankFieldErrors, maleForm: false });
                }}
              />
              {blankFieldErrors.maleForm && (
                <EuiTextColor color={'danger'}>
                  {t('onBlurBlankField')}
                </EuiTextColor>
              )}
            </>
          </EuiFormRow>
          <EuiFormRow label="Preview">
            <div
              id="botTokenGender"
              draggable="true"
              style={{
                background: type === 'BOT' ? '#0074D9' : '#01FF70',
                width: 'max-content',
                textAlign: 'center',
                wordWrap: 'break-word',
                maxWidth: '350px',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                maxHeight: '100%',
                // height: 'auto',
                // display: 'inline-block',
              }}
              className="token"
            >
              {`${femaleStatement}|${maleStatement}`}
            </div>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>

        <EuiButton
          type="submit"
          form={'genderModalForm'}
          disabled={maleStatement.length === 0 || femaleStatement.length === 0}
          fill
        >
          Add
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default AddGenderTokenModal;

import React from 'react';
import { Strategy } from '../../../../../../../generated/tenants/Api';
import Modal from '../../../../../../../common/modals/Modal';
import { H3 } from '../../../../../../App.style';
import {
  ArchiveStrategyContainer,
  ArchiveStrategyContent,
} from '../../../../../../../common/modals/Modals.style';
import { useAppDispatch } from '../../../../../../../common/hooks';
import {
  getStrategiesListAsyncThunk,
  getArchivedStrategiesListAsyncThunk,
  clearArchiveStrategy,
  archiveStrategyAsyncThunk,
} from '../api/strategiesSlice';
import { useTranslation } from 'react-i18next';
import { getLoginStateAsyncThunk } from '../../../../../session/api/sessionSlice';

interface ArchiveStrategyModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedStrategy: Strategy;
  currentStrategyId: string;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArchiveStrategyModal: React.FC<ArchiveStrategyModalProps> = ({
  isVisible,
  closeModal,
  selectedStrategy,
  currentStrategyId,
  setIsLoadingVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleArchiveClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoadingVisible(true);

    dispatch(archiveStrategyAsyncThunk(selectedStrategy.id))
      .then(() => {
        if (selectedStrategy.id === currentStrategyId) {
          localStorage.removeItem('strategy');
          window.location.reload();
        }

        dispatch(getStrategiesListAsyncThunk());
        dispatch(getArchivedStrategiesListAsyncThunk());
        dispatch(clearArchiveStrategy());
        dispatch(getLoginStateAsyncThunk());
        closeModal();
      })
      .finally(() => {
        setIsLoadingVisible(false);
      });

    return false;
  };

  const body = (
    <ArchiveStrategyContainer>
      <ArchiveStrategyContent>
        <H3>{t('settings.adminPanel.manageWorkspaces.form.archiveTitle')}</H3>
        <H3 style={{ marginTop: 20 }}>
          {t('settings.adminPanel.manageWorkspaces.form.archiveText')}
        </H3>
      </ArchiveStrategyContent>
    </ArchiveStrategyContainer>
  );
  return (
    <Modal
      headerTitle={`${t('settings.adminPanel.manageWorkspaces.form.archive')}${
        selectedStrategy.name
      }`}
      isVisible={isVisible}
      onCancel={closeModal}
      buttonStyle="RED"
      buttonText={t('common.archive')}
      children={body}
      onConfirm={handleArchiveClick}
    />
  );
};

export default ArchiveStrategyModal;

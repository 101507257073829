import React, { useState } from 'react';
import { CreateTenantContainer, TenantFormBarContainer } from './Tenants.style';
import { useAppDispatch } from '../../../../../../common/hooks';
import { CompanyCreateRequest } from '../../../../../../generated/public/Api';
import TenantForm from './TenantForm';
import { createTenantAsyncThunk } from '../../api/superAdminSlice';
import { Button, H1 } from '../../../../../App.style';
import { useTranslation } from 'react-i18next';
import { BackToListButton } from '../../../../presets/shared/BackToList';
import { ArrowLeftIcon } from '../../../../../../resources/icons-new/ArrowLeftIcon';
import { EditPresetButtonsContainer } from '../../../../presets/shared/EditPresetButtons';
import LoadingModal from '../../../../../../common/modals/LoadingModal';

interface TenantCreateProps {
  backToList: () => void;
}

const TenantCreate: React.FC<TenantCreateProps> = ({ backToList }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [newTenant, setNewTenant] = useState<CompanyCreateRequest>({
    name: '',
    slug: '',
    available_lines: 1,
    language: 'pl-PL',
    timezone: 'Europe/Warsaw',
    details: undefined,
    sms_gateway: undefined,
    billing_configuration: {
      domestic_mobile_call_price: 0.05,
      international_mobile_call_price: 0.05,
      domestic_landline_call_price: 0.05,
      international_landline_call_price: 0.05,
      sms_price: 0.05,
      currency: 'PLN',
      template: 'PER_CALL',
    },
  });

  const handleCreateTenantClick = () => {
    setIsLoadingVisible(true);
    dispatch(createTenantAsyncThunk(newTenant)).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <CreateTenantContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <TenantFormBarContainer>
        <BackToListButton
          style={{
            display: 'flex',
            top: 0,
            left: 0,
            position: 'relative',
            marginRight: 8,
          }}
          onClick={backToList}
        >
          <ArrowLeftIcon />
          {t('common.goBack')}
        </BackToListButton>

        <H1 style={{ marginTop: -3 }} $bold>
          {t('superAdminPanel.tenants.form.create')}
        </H1>

        <EditPresetButtonsContainer noPosition>
          <Button onClick={handleCreateTenantClick}>
            {t('common.create')}
          </Button>
        </EditPresetButtonsContainer>
      </TenantFormBarContainer>

      <TenantForm
        tenant={newTenant}
        setTenant={setNewTenant}
        createNew
        back={backToList}
      />
    </CreateTenantContainer>
  );
};

export default TenantCreate;

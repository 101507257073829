import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const DownloadIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <g id="Vector">
      <path d="M10.3419 7.67116L8.49219 9.61428V1.05556C8.49219 0.748731 8.26833 0.5 7.99219 0.5C7.71605 0.5 7.49219 0.748731 7.49219 1.05556V9.58224L5.67294 7.67116C5.45994 7.44295 5.11394 7.44295 4.90194 7.67116C4.68894 7.89937 4.68894 8.27171 4.90194 8.49992L6.85194 10.5494C7.49294 11.2384 8.52394 11.2363 9.16294 10.5494L11.1129 8.49992C11.3259 8.27171 11.3259 7.89937 11.1129 7.67116C10.9009 7.44295 10.5549 7.44295 10.3419 7.67116Z" />
      <path d="M15.4922 10.5V13.5059C15.4922 14.6072 14.5977 15.5 13.4981 15.5H2.50187C1.40058 15.5 0.507812 14.6055 0.507812 13.5059V10.5H1.50781V13.5059C1.50781 14.0539 1.95361 14.5 2.50187 14.5H13.4981C14.0461 14.5 14.4922 14.0542 14.4922 13.5059V10.5H15.4922Z" />
    </g>
  </StyledSvg>
);

import React, { useEffect, useState } from 'react';
import {
  BillingContainer,
  BillingBarContainer,
  BillingBarFiltersContainer,
  BillingsContentContainer,
  BillingsFiltersContainer,
  BillingsFiltersDateContainer,
} from './Billings.style';
import { COLOR_PRIMARY_3, H1, H3, H4, fontIBM } from '../../../../../App.style';
import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiSuperSelect,
} from '@elastic/eui';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import { Moment } from 'moment';
import moment from 'moment';
import BillingsTable from './BillingsTable';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  clearBillingsCampaignList,
  getBillingProcessesAsyncThunk,
  getBillingsCampaignListAsyncThunk,
  getBillingsReportAsyncThunk,
  selectBillingProcessesList,
  selectBillingsCampaignsList,
} from '../../api/superAdminSlice';
import {
  BillingCampaignList,
  BillingProcessList,
} from '../../../../../../generated/public/Api';
import { XIcon } from '../../../../../../resources/icons-new/XIcon';
import ProcessesInput from '../../../../../../common/inputs/ProcessesInput';
import { useTranslation } from 'react-i18next';

type BillingsDateOption =
  | 'ALL'
  | 'CUSTOM'
  | 'LAST_7_DAYS'
  | 'LAST_MONTH'
  | 'THIS_MONTH'
  | 'TODAY';

interface BillingsFilterValues {
  search: string;
  date_option: BillingsDateOption;
  date: {
    start_date?: string;
    end_date?: string;
  };
}
const Billings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedTenantId, setSelectedTenantId] = useState<string>('');
  const [selectedProcessId, setSelectedProcessId] = useState<string>('');
  const [isAllCampaignsSelected, setIsAllCampaignsSelected] =
    useState<boolean>(false);

  const [filters, setFilters] = useState<BillingsFilterValues>({
    search: '',
    date_option: 'ALL',
    date: { start_date: '', end_date: '' },
  });

  useEffect(() => {
    dispatch(getBillingProcessesAsyncThunk());
  }, []);

  useEffect(() => {
    if (selectedTenantId) {
      dispatch(getBillingProcessesAsyncThunk());
    }
  }, [selectedTenantId]);
  const apiBillingsProcessesList = useAppSelector(selectBillingProcessesList);

  const transformBillingProcessList = (
    billingProcessList: BillingProcessList,
  ) => {
    const tenantOptions = billingProcessList.tenants.map((tenant) => ({
      value: tenant.id,
      inputDisplay: tenant.slug,
    }));

    const processOptions = [
      ...billingProcessList.tenants.flatMap((tenant) =>
        tenant.strategies.flatMap((strategy) =>
          strategy.processes.map((process) => ({
            value: process.id,
            processName: process.name,
            strategyName: strategy.name,
            tenantId: tenant.id,
            is_archived: process.is_archived,
          })),
        ),
      ),
    ];

    return { tenantOptions, processOptions };
  };

  //stare

  const onDatesChange = (start: Moment | null, end: Moment | null) => {
    if (start) {
      setFilters((prev) => ({
        ...prev,
        date_option: 'CUSTOM',
        date: {
          ...prev.date,
          start_date: formatMomentToString(start),
        },
      }));
    }
    if (end) {
      setFilters((prev) => ({
        ...prev,
        date_option: 'CUSTOM',
        date: {
          ...prev.date,
          end_date: formatMomentToString(end),
        },
      }));
    }
  };

  const options = [
    { value: 'ALL', inputDisplay: t('billings.all') },
    { value: 'TODAY', inputDisplay: t('billings.today') },
    { value: 'LAST_7_DAYS', inputDisplay: t('billings.last7days') },
    { value: 'THIS_MONTH', inputDisplay: t('billings.thisMonth') },
    { value: 'LAST_MONTH', inputDisplay: t('billings.lastMonth') },
    { value: 'CUSTOM', inputDisplay: t('billings.custom') },
  ];

  const formatMomentToString = (date: Moment) => {
    return date.format('YYYY-MM-DD');
  };
  const onDateOptionChange = (value: BillingsDateOption) => {
    setFilters({ ...filters, date_option: value });

    switch (value) {
      case 'TODAY': {
        const today = moment();
        setFilters((prev) => ({
          ...prev,
          date: {
            start_date: formatMomentToString(today),
            end_date: formatMomentToString(today),
          },
        }));
        break;
      }
      case 'LAST_7_DAYS': {
        const lastWeek = moment().subtract(7, 'days');
        setFilters((prev) => ({
          ...prev,
          date: {
            start_date: formatMomentToString(lastWeek),
            end_date: formatMomentToString(moment()),
          },
        }));

        break;
      }
      case 'THIS_MONTH': {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');

        setFilters((prev) => ({
          ...prev,
          date: {
            start_date: formatMomentToString(startOfMonth),
            end_date: formatMomentToString(endOfMonth),
          },
        }));
        break;
      }
      case 'ALL':
      case 'CUSTOM':
      default:
        setFilters((prev) => ({
          ...prev,
          date: {
            start_date: undefined,
            end_date: undefined,
          },
        }));

        break;
    }
  };

  const startDate = filters.date.start_date
    ? moment(filters.date.start_date)
    : null;
  const endDate = filters.date.end_date ? moment(filters.date.end_date) : null;

  //table settings
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);

  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };
  const [selectedBillings, setSelectedBillings] = useState<
    BillingCampaignList[]
  >([]);

  const isValidUUID = (value: string): boolean => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  };

  useEffect(() => {
    if (selectedTenantId) {
      const query = {
        tenant_id: selectedTenantId,
        process_id: selectedProcessId,
        time: filters.date_option === 'ALL' ? undefined : filters.date_option,
        end_date:
          filters.date_option === 'CUSTOM' ? filters.date.end_date : undefined,
        start_date:
          filters.date_option === 'CUSTOM'
            ? filters.date.start_date
            : undefined,
        ...(filters.search && isValidUUID(filters.search)
          ? { campaign_id: filters.search }
          : {}),
        limit: itemsPerPage,
        offset: offset,
        campaign_id: filters.search,
      };

      dispatch(getBillingsCampaignListAsyncThunk(query));
    }
  }, [selectedTenantId, selectedProcessId, filters, offset, limit]);

  const findTenanNameById = (id: string) => {
    const selectedTenant = apiBillingsProcessesList?.tenants.find(
      (tenant) => tenant.id === id,
    );
    return selectedTenant?.slug ?? '';
  };

  const handleGenerateReport = () => {
    const generateReport = (
      slug: string,
      campaignIds: string[],
      processId: string | null,
    ) => {
      dispatch(
        getBillingsReportAsyncThunk({
          slug,
          campaign_ids: campaignIds,
          process_id: processId,
        }),
      ).then((returnedAction) => {
        if (getBillingsReportAsyncThunk.fulfilled.match(returnedAction)) {
          const { url, filename } = returnedAction.payload;
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    };

    const slug = findTenanNameById(selectedTenantId);
    if (isAllCampaignsSelected) {
      generateReport(
        slug,
        [],
        selectedProcessId === '' ? null : selectedProcessId,
      );
    } else if (selectedBillings.length > 0) {
      generateReport(
        slug,
        selectedBillings.map((billing) => billing.id),
        selectedProcessId === '' ? null : selectedProcessId,
      );
    }
  };

  const handleClearSelectedBillings = () => {
    setSelectedTenantId('');
    setSelectedProcessId('');
    setSelectedBillings([]);
    dispatch(clearBillingsCampaignList());
    setFilters({
      search: '',
      date_option: 'ALL',
      date: { start_date: '', end_date: '' },
    });
  };

  const apiBillingsCampaignList = useAppSelector(selectBillingsCampaignsList);

  if (!apiBillingsProcessesList) {
    return <div>{t('billings.loading')}</div>;
  }

  const { tenantOptions, processOptions } = transformBillingProcessList(
    apiBillingsProcessesList,
  );

  const handleSelectAllClick = (state: boolean) => {
    if (state) {
      setSelectedBillings([]);
      setIsAllCampaignsSelected(false);
    } else {
      setIsAllCampaignsSelected(true);
    }
  };

  return (
    <BillingContainer>
      <BillingBarContainer>
        <H1 $bold>{t('billings.title')}</H1>
        <BillingBarFiltersContainer>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <H3>{t('billings.tenant')}</H3>
            <EuiSuperSelect
              placeholder={t('billings.selectTenant')}
              style={{ minWidth: 200 }}
              valueOfSelected={selectedTenantId}
              options={tenantOptions}
              onChange={(value) => {
                setSelectedTenantId(value);
                setSelectedProcessId('');
                setSelectedBillings([]);
                dispatch(clearBillingsCampaignList());
              }}
            />
          </div>

          {selectedProcessId && false && (
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                gap: 4,
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleClearSelectedBillings}
            >
              <H4>{t('billings.clearFilters')}</H4>
              <XIcon />
            </div>
          )}

          {selectedTenantId && (
            <BillingsFiltersContainer>
              <H3>{t('billings.process')}</H3>
              <ProcessesInput
                processesList={processOptions}
                selectedProcessId={selectedProcessId}
                setSelectedProcessId={setSelectedProcessId}
              />

              <BillingsFiltersDateContainer
                $isProcessSelected={!!selectedProcessId}
              >
                <EuiSuperSelect
                  style={{
                    minWidth: 120,
                    border: 'none',
                    boxShadow: 'none',
                    borderRight: selectedProcessId && '1px solid black',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  options={options}
                  valueOfSelected={filters.date_option}
                  onChange={(value) =>
                    onDateOptionChange(value as BillingsDateOption)
                  }
                />
                <EuiDatePickerRange
                  css={{
                    width: 260,
                    background: !selectedProcessId ? '#EEF2F6' : 'white',
                    cursor: 'pointer',
                    borderRadius: 0,
                    zIndex: 0,
                    '.euiFormControlLayoutDelimited': {
                      background: 'transparent !important',
                      border: 'none !important',
                      boxShadow: 'none !important',
                      borderRadius: 0,
                    },
                  }}
                  isInvalid={
                    startDate && endDate ? endDate.isBefore(startDate) : false
                  }
                  // disabled={!selectedProcessId}
                  startDateControl={
                    <EuiDatePicker
                      css={{
                        background: 'white',
                        fontFamily: fontIBM,
                        borderRadius: 0,
                        cursor: 'pointer',
                      }}
                      placeholder={t('billings.startDate')}
                      selected={startDate}
                      onChange={(date) => onDatesChange(date, endDate)}
                      startDate={startDate}
                      endDate={endDate}
                      aria-label="Start date"
                      dateFormat="DD.MM.YYYY"
                    />
                  }
                  endDateControl={
                    <EuiDatePicker
                      css={{
                        background: 'white',
                        fontFamily: fontIBM,
                        cursor: 'pointer',
                        borderRadius: 0,
                      }}
                      placeholder={t('billings.endDate')}
                      selected={endDate}
                      onChange={(date) => onDatesChange(startDate, date)}
                      startDate={startDate}
                      endDate={endDate}
                      aria-label="End date"
                      dateFormat="DD.MM.YYYY"
                    />
                  }
                />
              </BillingsFiltersDateContainer>
              <div>
                <SearchInput
                  style={{
                    width: 200,
                    height: 45,
                    border: selectedProcessId
                      ? `0.5px solid ${COLOR_PRIMARY_3}`
                      : 'none',
                  }}
                  placeholder={t('billings.pasteCampaignID')}
                  value={filters.search}
                  setValue={(value) => {
                    setFilters({ ...filters, search: value });
                  }}
                />
              </div>
            </BillingsFiltersContainer>
          )}
        </BillingBarFiltersContainer>
      </BillingBarContainer>

      <BillingsContentContainer>
        {selectedTenantId ? (
          <BillingsTable
            billingsList={apiBillingsCampaignList?.results ?? []}
            selectedBillings={selectedBillings}
            setSelectedBillings={setSelectedBillings}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleGenerateReport={handleGenerateReport}
            totalCampaigns={apiBillingsCampaignList?.count ?? 0}
            isAllCampaignsSelected={isAllCampaignsSelected}
            handleSelectAllClick={handleSelectAllClick}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('billings.pleaseSelectTenant')}
          </div>
        )}
      </BillingsContentContainer>
    </BillingContainer>
  );
};

export default Billings;

import styled from 'styled-components';

export const SuperUsersContainer = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  padding: 24px;

  background: #ffffff;

  position: relative;

  display: flex;
  flex-direction: column;
`;

//bar

export const SuperUsersBarContainer = styled.div`
  width: 100%;
  min-width: 500px;

  z-index: 10;

  display: flex;

  flex-direction: column;
`;

export const SuperUsersBarTopContainer = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;
`;

export const SuperUsersBarBottomContainer = styled.div`
  width: 100%;
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

export const SuperUsersListSearchContainer = styled.div`
  height: 30px;
`;

import React from 'react';
import styled from 'styled-components';

interface ArrowIconProps {
  disabled: boolean;
}

const StyledSvg = styled.svg<{ disabled: boolean }>`
  fill: ${(props) => (props.disabled ? '#d3d3d3' : '#808080')};

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    fill: ${(props) => (props.disabled ? '#d3d3d3' : '#1a1a1a')};
  }
`;

export const ArrowIconLeft: React.FC<ArrowIconProps> = ({ disabled }) => (
  <StyledSvg
    disabled={disabled}
    width="8"
    height="14"
    viewBox="0 0 8 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35355 13.3536C7.15829 13.5488 6.84171 13.5488 6.64645 13.3536L0.646447 7.35355C0.451185 7.15829 0.451185 6.84171 0.646447 6.64645L6.64645 0.646446C6.84171 0.451184 7.15829 0.451184 7.35355 0.646446C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L1.70711 7L7.35355 12.6464C7.54882 12.8417 7.54882 13.1583 7.35355 13.3536Z"
    />
  </StyledSvg>
);

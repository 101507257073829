import { ApiError } from '../../../../../common/types';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import { Api } from '../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckCalendarResult {
  status: 'success' | 'fail';
  error?: ApiError<CalendarConfiguration>;
  calendar?: CalendarConfiguration;
}

export const getCalendarsList = (id: string) =>
  api.strategiesCalendarConfigurationList(id).then((_) => _.data);

export const createCalendar = (
  data: CalendarConfigurationRequest,
): Promise<CheckCalendarResult> =>
  api
    .calendarConfigurationCreate(data)
    .then(() => {
      return { status: 'success' } as CheckCalendarResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getCalendarDetailed = (id: string) =>
  api.calendarConfigurationRetrieve(id).then((_) => _.data);

export const updateCalendar = (
  id: string,
  data: CalendarConfigurationRequest,
): Promise<CheckCalendarResult> =>
  api
    .calendarConfigurationUpdate(id, data)
    .then(() => {
      return { status: 'success', calendar: data } as CheckCalendarResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteCalendar = (id: string): Promise<CheckCalendarResult> =>
  api
    .calendarConfigurationDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckCalendarResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

import { ApiError } from '../../../../../../../common/types';
import {
  Api,
  Strategy,
  StrategyCreateRequest,
  StrategyRequest,
} from '../../../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckStrategyResult {
  status: 'success' | 'fail';
  error?: ApiError<Strategy>;
  strategy?: Strategy;
}

export const getStrategiesList = () => api.strategiesList().then((_) => _.data);

export const getStrategyDetailed = (id: string) =>
  api.strategiesRetrieve(id).then((_) => _.data);

export const createStrategy = (
  data: StrategyCreateRequest,
): Promise<CheckStrategyResult> =>
  api
    .strategiesCreate(data)
    .then(() => {
      return { status: 'success' } as CheckStrategyResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const updateStrategy = (
  id: string,
  data: StrategyRequest,
): Promise<CheckStrategyResult> =>
  api
    .strategiesUpdate(id, data)
    .then(() => {
      return { status: 'success', strategy: data } as CheckStrategyResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteStrategy = (id: string) =>
  api.strategiesDestroy(id).then((_) => _.data);

export const strategiesArchivedList = () =>
  api.strategiesArchivedList().then((_) => _.data);

export const archiveStrategy = (id: string) =>
  api.strategiesArchiveUpdate(id).then((_) => _.data);

export const unarchiveStrategy = (id: string) =>
  api.strategiesUnarchiveUpdate(id).then((_) => _.data);

export const deleteArchivedStrategy = (id: string) =>
  api.strategiesArchivedDestroy(id).then((_) => _.data);

//gpg rotate

export const gpgRotateStrategy = (strategyId: string) =>
  api.strategiesGpgRotateCreate(strategyId).then((_) => _.data);

import { EuiFlexItem, EuiPopover } from '@elastic/eui';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../../common/hooks';
import { H2, H3 } from '../../../../../App.style';
import { Strategy } from '../../../../../../generated/tenants/Api';
import { setStrategyId } from './api/strategiesSlice';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PopoverStrategies, StrategyButton } from './StrategiesPanel.style';

import { ChevronUpIcon } from '../../../../../../resources/icons-new/ChevronUpIcon';

import {
  SidebarSelectContainer,
  SidebarStrategiesContainer,
} from '../../../../sidebar/Sidebar.style';

interface StrategyNavbarSelectProps {
  selectedStrategy: Strategy;
  setSelectedStrategy: React.Dispatch<
    React.SetStateAction<Strategy | undefined>
  >;
  strategiesList: Strategy[];
}

const StrategyNavbarSelect: React.FC<StrategyNavbarSelectProps> = ({
  selectedStrategy,
  setSelectedStrategy,
  strategiesList,
}) => {
  const dispatch = useAppDispatch();
  const [isStrategyPopoverOpen, setIsStrategyPopoverOpen] =
    useState<boolean>(false);

  const strategiesOptions = strategiesList.map((strategy) => {
    return { value: strategy.id, text: strategy.name };
  });

  const handleChangeStrategy = (e: string) => {
    const selectedStrategy = strategiesList.find(
      (strategy) => strategy.id === e,
    );

    if (selectedStrategy) {
      dispatch(setStrategyId(selectedStrategy.id));
      setSelectedStrategy(selectedStrategy);
      setIsStrategyPopoverOpen(false);
      window.location.reload();
    }
  };

  return (
    <EuiFlexItem>
      <EuiPopover
        ownFocus={false}
        button={
          <SidebarStrategiesContainer>
            <SidebarSelectContainer
              onClick={() => setIsStrategyPopoverOpen((prev) => !prev)}
            >
              <H2>{selectedStrategy?.name}</H2>
              {isStrategyPopoverOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </SidebarSelectContainer>
          </SidebarStrategiesContainer>
        }
        isOpen={isStrategyPopoverOpen}
        closePopover={() => setIsStrategyPopoverOpen(false)}
        anchorPosition="downCenter"
        panelPaddingSize="none"
        hasArrow={false}
      >
        <PopoverStrategies>
          {strategiesOptions.map((strategy, index) => {
            const isSelected = selectedStrategy.id === strategy.value;
            return (
              <StrategyButton
                key={`strategy-${index}`}
                $selected={isSelected}
                disabled={isSelected}
                onClick={() => handleChangeStrategy(strategy.value)}
              >
                <H3>{strategy.text}</H3>
              </StrategyButton>
            );
          })}
        </PopoverStrategies>
      </EuiPopover>
    </EuiFlexItem>
  );
};

export default StrategyNavbarSelect;

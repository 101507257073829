import { EuiPopover } from '@elastic/eui';
import React, { useState } from 'react';
import { ThreeDotsIcon } from '../../../../../../resources/icons-new/ThreeDotsIcon';
import { H3, H2, H4 } from '../../../../../App.style';

import {
  StrategyPreviewContainer,
  ThreeDotsContainer,
  StrategyNameContainer,
} from './StrategiesPanel.style';
import { Strategy } from '../../../../../../generated/tenants/Api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UserPopoverButton } from '../../../../sidebar/Sidebar.style';

interface StrategyPreviewProps {
  strategy: Strategy;
  handleEditClick?: (strategy: Strategy) => void;
  handleDeleteClick?: (strategy: Strategy, isArchived: boolean) => void;
  handleArchiveClick?: (strategy: Strategy) => void;
  handleRestoreClick?: (strategy: Strategy) => void;
  handleDetailedClick?: (strategy: Strategy) => void;
  isArchived: boolean;
}

const StrategyPreview: React.FC<StrategyPreviewProps> = ({
  strategy,
  handleEditClick,
  handleDeleteClick,
  handleArchiveClick,
  handleRestoreClick,
  handleDetailedClick,
  isArchived,
}) => {
  const { t } = useTranslation();
  const [isStrategyPopoverOpen, setIsStrategyPopoverOpen] =
    useState<boolean>(false);

  const openStrategyPopover = () => setIsStrategyPopoverOpen(true);
  const closeStrategyPopover = () => setIsStrategyPopoverOpen(false);

  const formattedCreated = moment(strategy.created_at).format(
    'DD.MM.YYYY, HH:mm',
  );

  return (
    <StrategyPreviewContainer
      isArchived={isArchived}
      onClick={() => handleDetailedClick && handleDetailedClick(strategy)}
    >
      <ThreeDotsContainer>
        <EuiPopover
          ownFocus={false}
          onClick={(e) => {
            e.stopPropagation();
            openStrategyPopover();
          }}
          button={<ThreeDotsIcon $pointer />}
          isOpen={isStrategyPopoverOpen}
          closePopover={closeStrategyPopover}
          anchorPosition="downRight"
          panelPaddingSize="none"
        >
          <div>
            {isArchived ? (
              <UserPopoverButton
                onClick={() => {
                  handleRestoreClick && handleRestoreClick(strategy);
                  closeStrategyPopover();
                }}
              >
                <H3>{t('common.restore')}</H3>
              </UserPopoverButton>
            ) : (
              <>
                <UserPopoverButton
                  onClick={() => {
                    handleEditClick && handleEditClick(strategy);
                    closeStrategyPopover();
                  }}
                >
                  <H3>{t('common.edit')}</H3>
                </UserPopoverButton>

                <UserPopoverButton
                  onClick={() => {
                    handleArchiveClick && handleArchiveClick(strategy);
                    closeStrategyPopover();
                  }}
                >
                  <H3>{t('common.archive')}</H3>
                </UserPopoverButton>
              </>
            )}

            <UserPopoverButton
              onClick={() => {
                handleDeleteClick && handleDeleteClick(strategy, isArchived);
                closeStrategyPopover();
              }}
            >
              <H3>{t('common.delete')}</H3>
            </UserPopoverButton>
          </div>
        </EuiPopover>
      </ThreeDotsContainer>

      <StrategyNameContainer>
        <H2 $selected>{strategy.name}</H2>
      </StrategyNameContainer>
      <H2>
        {t('settings.adminPanel.manageWorkspaces.list.assignedLines')}
        {strategy.assigned_lines}
      </H2>
      <H4>
        {t('settings.adminPanel.manageWorkspaces.list.created')}
        {formattedCreated}
      </H4>
    </StrategyPreviewContainer>
  );
};

export default StrategyPreview;

import React, { useState, useRef, useEffect } from 'react';

import { H3, H6 } from '../../../../../App.style';
import {
  AudioWave,
  AudioWrapper,
  HorizontalBar,
  HoverTime,
  PlayButton,
  ProgressBar,
  Time,
  WaveBar,
} from '../Calls.styles';
import { AudioStopIcon } from '../../../../../../resources/icons-new/AudioStopIcon';
import { AudioPlayIcon } from '../../../../../../resources/icons-new/AudioPlayIcon';
import { useTranslation } from 'react-i18next';

interface CallRecordingProps {
  audioSrc: string;
}

const CallRecording: React.FC<CallRecordingProps> = ({ audioSrc }) => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.src = '';
      audio.load();
      audio.src = audioSrc;
      setCurrentTime(0);
      setIsPlaying(false);
    }
  }, [audioSrc]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      if (audio) {
        setDuration(audio.duration);
      }
    };

    const handleTimeUpdate = () => {
      if (audio) {
        setCurrentTime(audio.currentTime);
      }
    };

    const handleAudioEnd = () => {
      if (audio) {
        audio.currentTime = 0; // Reset to start
        setIsPlaying(false); // Set isPlaying to false
      }
    };

    if (audio) {
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleAudioEnd); // New event listener for 'ended' event
    }

    return () => {
      if (audio) {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleAudioEnd); // Remove event listener for 'ended' event
      }
    };
  }, []);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
        setTimeout(() => {
          setHighlightFirstBar(true);
        }, 1000);
      }
      setIsPlaying((prev) => !prev);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0',
    )}`;
  };

  const percentage = (currentTime / duration) * 100;
  const waveWidth = 680; // Fixed width
  const numBars = Math.floor(waveWidth / 10); // 3px width + 9px gap
  const completedBars = Math.floor((numBars * percentage) / 100);

  //click
  const barRef = useRef<HTMLDivElement>(null); // New ref

  const handleBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const audio = audioRef.current;
    const bar = barRef.current;

    if (audio && bar) {
      const rect = bar.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const newTime = (clickX / rect.width) * duration;

      audio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const [hoverTime, setHoverTime] = useState<number | null>(null);

  const handleBarHover = (e: React.MouseEvent<HTMLDivElement>) => {
    const bar = barRef.current;

    if (bar) {
      const rect = bar.getBoundingClientRect();
      const hoverX = e.clientX - rect.left;
      const hoverTime = (hoverX / rect.width) * duration;

      setHoverTime(hoverTime);
    }
  };

  let patternIndex = 0;
  const pattern = ['16px', '22px', '16px', '16px', '22px']; // The pattern you want to repeat

  const [highlightFirstBar, setHighlightFirstBar] = useState(false);

  return (
    <AudioWrapper>
      {audioSrc ? (
        <>
          <PlayButton onClick={togglePlay}>
            {isPlaying ? <AudioStopIcon /> : <AudioPlayIcon />}
          </PlayButton>
          <Time>
            <H3 style={{ color: '#126FBF' }}>{formatTime(currentTime)}</H3>
          </Time>
          <AudioWave onMouseLeave={() => setHoverTime(null)}>
            <HorizontalBar
              ref={barRef}
              onClick={handleBarClick}
              onMouseMove={handleBarHover}
            >
              <ProgressBar style={{ width: `${percentage}%` }} />
            </HorizontalBar>
            {hoverTime !== null && (
              <HoverTime style={{ left: `${(hoverTime / duration) * 100}%` }}>
                <H6>{formatTime(hoverTime)}</H6>
              </HoverTime>
            )}
            {Array.from({ length: numBars }, (_, index) => {
              let leftPosition = index * 10; // 3px width + 7px gap
              const barHeight = pattern[patternIndex]; // Get the height from the pattern array

              // Update patternIndex for the next iteration
              patternIndex = (patternIndex + 1) % pattern.length;

              if (index === 1) {
                leftPosition -= 0;
              }

              const shouldHighlight =
                index === 0 ? highlightFirstBar : index <= completedBars;

              if (index !== 0 && index !== numBars - 1)
                return (
                  <WaveBar
                    key={index}
                    completed={shouldHighlight}
                    onClick={handleBarClick}
                    style={{ left: `${leftPosition}px`, height: barHeight }}
                  />
                );
            })}
          </AudioWave>
          <div style={{ marginLeft: 10 }}>
            <H3>{formatTime(duration - currentTime)}</H3>
          </div>
          <audio ref={audioRef}>
            <source src={audioSrc} type="audio/wav" />
          </audio>
        </>
      ) : (
        <H3>{t('campaigns.calls.detailed.noRecording')}</H3>
      )}
    </AudioWrapper>
  );
};

export default CallRecording;

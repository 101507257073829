import { EuiFormRow, EuiFieldText } from '@elastic/eui';
import React, { useState } from 'react';
import { PasswordVisibilityToggle } from '../../../../../../../common/inputs/Inputs.style';
import { SftpCredentialsCreateRequest } from '../../../../../../../generated/tenants/Api';
import { EyeIcon } from '../../../../../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../../../../../resources/icons-new/EyeSlashIcon';
import { H3, ButtonEmpty, Button } from '../../../../../../App.style';
import {
  checkSftpPasswordAsyncThunk,
  setSftpPasswordAsyncThunk,
} from '../api/sftpConfigSlice';
import { PasswordButtonsContainer } from '../SftpPanel.style';
import { useAppDispatch } from '../../../../../../../common/hooks';
import { useTranslation } from 'react-i18next';

interface SftpPasswordChangeFormProps {
  closeForm: () => void;
}

const SftpPasswordChangeForm: React.FC<SftpPasswordChangeFormProps> = ({
  closeForm,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');

  const handleCreateSftpPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === rePassword) {
      const data: SftpCredentialsCreateRequest = {
        password: password,
        confirm_password: rePassword,
      };
      dispatch(setSftpPasswordAsyncThunk(data)).finally(() => {
        dispatch(checkSftpPasswordAsyncThunk());
        handleCancelChangePassword();
      });
    } else {
      setPasswordsNotMatch(true);
    }

    return false;
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isRePasswordVisible, setIsRePasswordVisible] =
    useState<boolean>(false);

  const handleCancelChangePassword = () => {
    closeForm();
    setPassword('');
    setRePassword('');
  };

  const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>(false);
  return (
    <form onSubmit={handleCreateSftpPassword}>
      <EuiFormRow
        style={{ marginTop: 10 }}
        label={
          <H3 $semibold>
            {t('settings.adminPanel.sftp.passwordChange.newPassword')}
          </H3>
        }
        isInvalid={passwordsNotMatch}
        error={t('settings.adminPanel.sftp.passwordChange.notEqual')}
      >
        <EuiFieldText
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={t('common.writeHere')}
          isInvalid={passwordsNotMatch}
          append={
            <PasswordVisibilityToggle
              type={'button'}
              onClick={() => setIsPasswordVisible((prev) => !prev)}
            >
              {isPasswordVisible ? (
                <EyeIcon style={{ width: 25, height: 25 }} />
              ) : (
                <EyeSlashIcon style={{ width: 25, height: 25 }} />
              )}
            </PasswordVisibilityToggle>
          }
          value={password}
          onChange={(ev) => {
            setPassword(ev.target.value);
            passwordsNotMatch && setPasswordsNotMatch(false);
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        label={
          <H3 $semibold>
            {t('settings.adminPanel.sftp.passwordChange.confirmPassword')}
          </H3>
        }
        isInvalid={passwordsNotMatch}
      >
        <EuiFieldText
          placeholder={t('common.writeHere')}
          type={isRePasswordVisible ? 'text' : 'password'}
          isInvalid={passwordsNotMatch}
          append={
            <PasswordVisibilityToggle
              type={'button'}
              onClick={() => setIsRePasswordVisible((prev) => !prev)}
            >
              {isRePasswordVisible ? (
                <EyeIcon style={{ width: 25, height: 25 }} />
              ) : (
                <EyeSlashIcon style={{ width: 25, height: 25 }} />
              )}
            </PasswordVisibilityToggle>
          }
          value={rePassword}
          onChange={(e) => {
            setRePassword(e.target.value);
            passwordsNotMatch && setPasswordsNotMatch(false);
          }}
        />
      </EuiFormRow>
      <PasswordButtonsContainer>
        <ButtonEmpty
          style={{ fontSize: 12 }}
          type={'button'}
          onClick={handleCancelChangePassword}
        >
          {t('common.cancel')}
        </ButtonEmpty>
        <Button $size={'S'} type={'submit'}>
          {t('common.save')}
        </Button>
      </PasswordButtonsContainer>
    </form>
  );
};

export default SftpPasswordChangeForm;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ExchangeIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M7.13579 5.33316H0.666667C0.298667 5.33316 0 5.0345 0 4.66651C0 4.29852 0.298667 3.99987 0.666667 3.99987H7.13659L4.16 0.961738C3.92 0.717891 3.95333 0.349205 4.23333 0.14036C4.51067 -0.0684846 4.93067 -0.0416498 5.17333 0.203363L9.17333 4.28692C9.38667 4.5051 9.38667 4.82711 9.17333 5.04529L5.17333 9.12885C5.04 9.26419 4.85467 9.33302 4.66667 9.33302C4.51333 9.33302 4.35867 9.28635 4.23333 9.19185C3.95333 8.983 3.92 8.61432 4.16 8.37047L7.13579 5.33316ZM8.86393 10.6663H15.3333C15.7013 10.6663 16 10.965 16 11.333C16 11.7009 15.7013 11.9996 15.3333 11.9996H8.86369L11.84 15.0374C12.08 15.2813 12.0467 15.65 11.7667 15.8588C11.6413 15.9533 11.4867 16 11.3333 16C11.1453 16 10.96 15.9312 10.8267 15.7958L6.82667 11.7123C6.61333 11.4941 6.61333 11.1721 6.82667 10.9539L10.8267 6.87034C11.0693 6.62533 11.4893 6.59849 11.7667 6.80734C12.0467 7.01618 12.08 7.38487 11.84 7.62871L8.86393 10.6663Z" />
  </StyledSvg>
);

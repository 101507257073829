import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../../processes/forms/ProcessForm.style';
import { Button, H3, H4 } from '../../../../App.style';
import { EuiFieldText, EuiIcon, EuiSuperSelect } from '@elastic/eui';
import {
  BotConfiguration,
  BotConfigurationRequest,
  CustomFaas,
  BaseBotConfigurationTemplateEnum,
} from '../../../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/worker-json';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-javascript';
import {
  JSONCodeBlockContainer,
  JSONFieldButtonContainer,
  JSONFieldContainer,
  JSONFieldLabelContainer,
} from '../Dialogues.style';
import { useEditorState } from './useEditorState';
import { ApiError } from '../../../../../common/types';
import { selectConfig } from '../../../session/api/sessionSlice';
import {
  checkDialogueCreate,
  checkDialogueUpdate,
  clearCreateDialogue,
  clearUpdateDialogue,
  getDialoguesListAsyncThunk,
} from '../api/dialoguesSlice';

const useCustomDialogueApiErrors = () => {
  const checkCreate = useAppSelector(checkDialogueCreate);
  const createErrors = checkCreate.value?.error;

  const checkUpdate = useAppSelector(checkDialogueUpdate);
  const updateErrors = checkUpdate.value?.error;

  const [customDialogueApiErrors, setCustomDialogueApiErrors] = useState<
    ApiError<BotConfiguration>
  >({} as ApiError<BotConfiguration>);

  useEffect(() => {
    if (createErrors)
      setCustomDialogueApiErrors((prevErrors) => ({
        ...prevErrors,
        ...createErrors,
      }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setCustomDialogueApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [customDialogueApiErrors, setCustomDialogueApiErrors] as const;
};

interface CustomDialogueFormProps {
  customDialogue: BotConfigurationRequest;
  setCustomDialogue: React.Dispatch<
    React.SetStateAction<BotConfigurationRequest>
  >;
  button?: () => void;
  createNew?: boolean;
  backToList: () => void;
  backToDetailed?: () => void;
  handleNewDialogueChange?: () => void;
  setHasErrors?: React.Dispatch<React.SetStateAction<boolean>>;
  customFaasesList: CustomFaas[];
}

const CustomDialogueForm: React.FC<CustomDialogueFormProps> = ({
  customDialogue,
  setCustomDialogue,
  button,
  backToDetailed,
  createNew,
  backToList,
  handleNewDialogueChange,
  setHasErrors,
  // customFaasesList,
}) => {
  const dispatch = useAppDispatch();

  const checkCreate = useAppSelector(checkDialogueCreate);

  const checkUpdate = useAppSelector(checkDialogueUpdate);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewDialogueChange && handleNewDialogueChange();
      dispatch(getDialoguesListAsyncThunk(customDialogue.strategy));
      dispatch(clearCreateDialogue());
      backToList();
    }
  });

  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      backToDetailed && backToDetailed();
      dispatch(getDialoguesListAsyncThunk(customDialogue.strategy));
      dispatch(clearUpdateDialogue());
    }
  });

  //JSON errors
  interface JSONErrors {
    runtime_parameters: boolean;
    required_icd: boolean;
    record_close_rules: boolean;
  }

  const [errorJSON, setErrorJSON] = useState<JSONErrors>({
    runtime_parameters: false,
    required_icd: false,
    record_close_rules: false,
  });

  //runtime parameters
  const runtimeParameters = useEditorState(
    '',
    customDialogue.runtime_parameters,
  );

  const handleRuntimeParametersChange = (newValue: string) => {
    const parsed = runtimeParameters.handleChange(newValue);
    if (parsed !== null) {
      setCustomDialogue({ ...customDialogue, runtime_parameters: parsed });
      setErrorJSON({ ...errorJSON, runtime_parameters: false });
      setHasErrors && setHasErrors(false);
    } else {
      setErrorJSON({ ...errorJSON, runtime_parameters: true });
      setHasErrors && setHasErrors(true);
    }

    //clear api errors
    customDialogueApiErrors.runtime_parameters &&
      setCustomDialogueApiErrors({
        ...customDialogueApiErrors,
        runtime_parameters: undefined,
      });
  };

  //record close rules

  const recordCloseRules = useEditorState(
    '',
    customDialogue.record_close_rules,
  );

  const handleRecordCloseRulesChange = (newValue: string) => {
    const parsed = recordCloseRules.handleChange(newValue);

    if (parsed !== null) {
      setCustomDialogue({ ...customDialogue, record_close_rules: parsed });
      setErrorJSON({ ...errorJSON, record_close_rules: false });
      setHasErrors && setHasErrors(false);
    } else {
      setErrorJSON({ ...errorJSON, record_close_rules: true });
      setHasErrors && setHasErrors(true);
    }

    //clear api errors
    customDialogueApiErrors.record_close_rules &&
      setCustomDialogueApiErrors({
        ...customDialogueApiErrors,
        record_close_rules: undefined,
      });
  };

  const checkForm = () => {
    const allJSONErrorsFalse =
      !errorJSON.runtime_parameters &&
      !errorJSON.required_icd &&
      !errorJSON.record_close_rules;
    const nameNotEmpty = customDialogue.name !== '';
    const imageTagNotEmpty = customDialogue.image_tag !== '';

    return allJSONErrorsFalse && nameNotEmpty && imageTagNotEmpty;
  };

  //api errors
  const [customDialogueApiErrors, setCustomDialogueApiErrors] =
    useCustomDialogueApiErrors();

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback: (content: string) => void,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'json') {
        alert('Please upload a .json file');
        return;
      }

      // File is valid, proceed with your logic here
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target?.result;
        callback(fileContent as string);
      };
      reader.readAsText(file);
    }
  };

  const handleUploadClick = (inputId: string) => {
    const fileInput = document.getElementById(inputId);
    if (fileInput) {
      fileInput.click();
    }
  };

  const config = useAppSelector(selectConfig);

  const templateOptions = [
    { value: 'DEBTOR', inputDisplay: 'Debtor' },
    { value: 'REMINDER', inputDisplay: 'Reminder' },
    { value: 'VENDOR', inputDisplay: 'Vendor' },
  ];

  return (
    <FormContainer
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>Name</H3>}
        isInvalid={customDialogueApiErrors.name ? true : false}
        error={customDialogueApiErrors.name}
      >
        <EuiFieldText
          placeholder="name"
          value={customDialogue.name}
          onChange={(e) => {
            setCustomDialogue({ ...customDialogue, name: e.target.value });
            customDialogueApiErrors.name &&
              setCustomDialogueApiErrors({
                ...customDialogueApiErrors,
                name: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>Custom label</H3>}
        isInvalid={customDialogueApiErrors.custom_label ? true : false}
        error={customDialogueApiErrors.custom_label}
      >
        <EuiFieldText
          placeholder="custom_label"
          value={customDialogue.custom_label ?? ''}
          onChange={(e) => {
            setCustomDialogue({
              ...customDialogue,
              custom_label: e.target.value,
            });
            customDialogueApiErrors.custom_label &&
              setCustomDialogueApiErrors({
                ...customDialogueApiErrors,
                custom_label: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>Image tag</H3>}
        isInvalid={customDialogueApiErrors.image_tag ? true : false}
        error={customDialogueApiErrors.image_tag}
        helpText={
          <div style={{ display: 'flex', gap: 4 }}>
            <EuiIcon
              style={{ color: '#3E4C59' }}
              type="iInCircle"
              color={'subdued'}
            />

            <H4 $wrap $grey>
              {`Please make sure that the image is properly named and ready in the
              repository in correct folder for given tenant. Correct path
              structure: registry.lekta.ai/llp/${config.tenantSubdomain}/wrap:{image_tag}`}
            </H4>
          </div>
        }
      >
        <EuiFieldText
          placeholder="url"
          value={customDialogue.image_tag ? customDialogue.image_tag : ''}
          onChange={(e) => {
            setCustomDialogue({ ...customDialogue, image_tag: e.target.value });
            customDialogueApiErrors.image_tag &&
              setCustomDialogueApiErrors({
                ...customDialogueApiErrors,
                image_tag: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>Template</H3>}
        isInvalid={customDialogueApiErrors.template ? true : false}
        error={customDialogueApiErrors.template}
      >
        <EuiSuperSelect
          valueOfSelected={customDialogue.template}
          options={templateOptions}
          onChange={(e) => {
            setCustomDialogue({
              ...customDialogue,
              template: e as BaseBotConfigurationTemplateEnum,
            });
            customDialogueApiErrors.template &&
              setCustomDialogueApiErrors({
                ...customDialogueApiErrors,
                template: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={
          <JSONFieldLabelContainer>
            <H3>Runtime parameters</H3>
          </JSONFieldLabelContainer>
        }
        isInvalid={
          customDialogueApiErrors.runtime_parameters
            ? true
            : errorJSON.runtime_parameters
        }
        error={
          customDialogueApiErrors.runtime_parameters
            ? customDialogueApiErrors.runtime_parameters
            : 'Not a valid JSON'
        }
        helpText={
          <div style={{ display: 'flex', gap: 4 }}>
            <EuiIcon
              style={{ color: '#3E4C59' }}
              type="iInCircle"
              color={'subdued'}
            />

            <H4 $wrap $grey>
              {`These parameters will be passed to the input context data for every dialogue.`}
            </H4>
          </div>
        }
      >
        <JSONFieldContainer>
          <JSONCodeBlockContainer error={errorJSON.runtime_parameters}>
            <AceEditor
              mode="json"
              theme="github"
              onChange={handleRuntimeParametersChange}
              value={runtimeParameters.editorValue}
              name="runtime_parameters_json"
              height="150px"
              editorProps={{ $blockScrolling: true }}
              setOptions={{ enableSnippets: false, useWorker: false }}
            />
          </JSONCodeBlockContainer>
          <JSONFieldButtonContainer>
            <H4>or</H4>
            <Button
              $size="M"
              type="button"
              onClick={() => handleUploadClick('runtimeParametersUpload')}
            >
              Upload
            </Button>
            <input
              id="runtimeParametersUpload"
              type="file"
              accept=".json"
              style={{ display: 'none' }}
              onChange={(e) =>
                handleFileChange(e, handleRuntimeParametersChange)
              }
            />
          </JSONFieldButtonContainer>
        </JSONFieldContainer>
      </FormRow>

      <FormRow
        label={
          <JSONFieldLabelContainer>
            <H3>Record close rules</H3>
          </JSONFieldLabelContainer>
        }
        isInvalid={
          customDialogueApiErrors.record_close_rules
            ? true
            : errorJSON.record_close_rules
        }
        error={
          customDialogueApiErrors.record_close_rules
            ? customDialogueApiErrors.record_close_rules
            : 'Not a valid JSON'
        }
      >
        <JSONFieldContainer>
          <JSONCodeBlockContainer>
            <AceEditor
              mode="json"
              theme="github"
              onChange={handleRecordCloseRulesChange}
              value={recordCloseRules.editorValue}
              name="record_close_rules_json"
              height="150px"
              editorProps={{ $blockScrolling: true }}
              setOptions={{ enableSnippets: false, useWorker: false }}
            />
          </JSONCodeBlockContainer>
          <JSONFieldButtonContainer>
            <H4>or</H4>
            <Button
              $size="M"
              type="button"
              onClick={() => handleUploadClick('recordCloseRulesUpload')}
            >
              Upload
            </Button>
            <input
              id="recordCloseRulesUpload"
              type="file"
              accept=".json"
              style={{ display: 'none' }}
              onChange={(e) =>
                handleFileChange(e, handleRecordCloseRulesChange)
              }
            />
          </JSONFieldButtonContainer>
        </JSONFieldContainer>
      </FormRow>

      {createNew && (
        <PresetCreateButton>
          <Button type="submit" disabled={!checkForm()}>
            Create
          </Button>
        </PresetCreateButton>
      )}
    </FormContainer>
  );
};

export default CustomDialogueForm;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const AnalyticsIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M9.5 3.01758C5.86736 3.27396 3 6.30212 3 10C3 11.7532 3.644 13.3553 4.70925 14.5836L9.5 9.79289V3.01758ZM10.5 3.01758V9.5H16.9824C16.7377 6.03251 13.9675 3.2623 10.5 3.01758ZM16.9824 10.5H10.2071L5.41636 15.2907C6.64474 16.356 8.24677 17 10 17C13.6979 17 16.726 14.1326 16.9824 10.5ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C7.791 18 5.79031 17.104 4.34315 15.6569C2.89598 14.2097 2 12.209 2 10Z" />
  </StyledSvg>
);

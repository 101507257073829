import { TestSftpConfiguration } from '../../../../../../../generated/tenants/Api';
import {
  SftpConfig,
  SftpCredentialsCreateRequest,
  SftpPublicKey,
  SftpPublicKeyRequest,
} from '../../../../../../../generated/tenants/Api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../store';
import {
  addPublicKey,
  CheckeSftpResult,
  CheckSftpAddPublicKey,
  checkSftpPassword,
  CheckSftpPasswordResult,
  CheckTestSftpConfiguration,
  createSftpConfiguration,
  deletePublicKey,
  deleteSftpConfiguration,
  getPublicKeysList,
  getSftpConfig,
  getSftpConfigurationById,
  getSftpConfigurationsList,
  setSftpCredentialsPassword,
  testSftpConfiguration,
  testSftpConfigurationById,
  updateSftpConfiguration,
} from './sftpConfigApi';

import {
  SftpConfiguration,
  SftpConfigurationRequest,
} from '../../../../../../../generated/tenants/Api';

export interface SftpConfigurationsState {
  list: {
    state: 'pending' | 'idle';
    value: Array<SftpConfiguration>;
  };
  byId: {
    state: 'pending' | 'idle';
    value?: SftpConfiguration;
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckeSftpResult;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckeSftpResult;
  };
  testSftp: {
    state: 'pending' | 'idle';
    value?: CheckTestSftpConfiguration;
  };
  testSftpById: {
    state: 'pending' | 'idle';
    value?: TestSftpConfiguration;
  };

  delete: {
    state: 'pending' | 'idle';
    value?: CheckeSftpResult;
  };
  publicKeysList: {
    state: 'pending' | 'idle';
    value?: Array<SftpPublicKey>;
  };
  addPublicKey: {
    state: 'pending' | 'idle';
    value?: CheckSftpAddPublicKey;
  };
  deletePublicKey: {
    state: 'pending' | 'idle';
  };
  checkSftpPassword: {
    state: 'pending' | 'idle';
    value?: CheckSftpPasswordResult;
  };
  addSftpPassword: {
    state: 'pending' | 'idle';
  };
  sftpConfig: {
    state: 'pending' | 'idle';
    value?: SftpConfig;
  };
}

const initialState: SftpConfigurationsState = {
  list: { state: 'pending', value: [] },
  byId: { state: 'pending' },
  create: { state: 'pending' },
  delete: { state: 'pending' },
  update: { state: 'pending' },
  testSftp: { state: 'pending' },
  testSftpById: { state: 'pending' },
  publicKeysList: { state: 'pending', value: [] },
  addPublicKey: { state: 'pending' },
  deletePublicKey: { state: 'pending' },
  checkSftpPassword: { state: 'pending' },
  addSftpPassword: { state: 'pending' },
  sftpConfig: { state: 'pending' },
};

export const getSftpConfigurationsListAsyncThunk = createAsyncThunk(
  'sftpConfigurations/list',
  () => getSftpConfigurationsList(),
);

export const getSftpConfigurationByIdAsyncThunk = createAsyncThunk(
  'sftpConfigurations/byId',
  (id: string) => getSftpConfigurationById(id),
);

export const createSftpConfigurationAsyncThunk = createAsyncThunk(
  'sftpConfigurations/create',
  (data: SftpConfigurationRequest) => createSftpConfiguration(data),
);

export const testSftpConfigurationAsyncThunk = createAsyncThunk(
  'sftpConfigurations/test',
  (data: SftpConfigurationRequest) => testSftpConfiguration(data),
);
export const testSftpConfigurationByIdAsyncThunk = createAsyncThunk(
  'sftpConfigurations/testById',
  (id: string) => testSftpConfigurationById(id),
);

export const updateSftpConfigurationAsyncThunk = createAsyncThunk(
  'sftpConfigurations/update',
  (args: { id: string; data: SftpConfigurationRequest }) =>
    updateSftpConfiguration(args.id, args.data),
);

export const deleteSftpConfigurationAsyncThunk = createAsyncThunk(
  'sftpConfigurations/delete',
  (id: string) => deleteSftpConfiguration(id),
);
//sftp config from lekta

export const getSftpConfigAsyncThunk = createAsyncThunk(
  'sftpCredentials/config',
  () => getSftpConfig(),
);

//sftp password logic
export const checkSftpPasswordAsyncThunk = createAsyncThunk(
  'sftpCredentials/checkPassword',
  () => checkSftpPassword(),
);
export const setSftpPasswordAsyncThunk = createAsyncThunk(
  'sftpCredentials/setPassword',
  (data: SftpCredentialsCreateRequest) => setSftpCredentialsPassword(data),
);

//sftp public keys
export const getSftpPublicKeysListAsyncThunk = createAsyncThunk(
  'sftpCredentials/list',
  () => getPublicKeysList(),
);

export const addSftpPublicKeyAsyncThunk = createAsyncThunk(
  'sftpCredentials/addPublicKey',
  (data: SftpPublicKeyRequest) => addPublicKey(data),
);

export const deleteSftpPublicKeyAsyncThunk = createAsyncThunk(
  'sftpCredentials/deletePublicKey',
  (id: string) => deletePublicKey(id),
);

export const sftpConfigurationsSlice = createSlice({
  name: 'sftpConfigurations',
  initialState,
  reducers: {
    clearCreateSftpConfig: (state) => {
      state.create.state = 'pending';
      state.create.value = undefined;
    },
    clearUpdateSftpConfig: (state) => {
      state.update.state = 'pending';
      state.update.value = undefined;
    },
    clearDeleteSftp: (state) => {
      state.delete = { state: 'pending' };
    },
    clearTestSftp: (state) => {
      state.testSftp.value = undefined;
      state.testSftpById.value = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSftpConfigurationsListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(
        getSftpConfigurationsListAsyncThunk.fulfilled,
        (state, action) => {
          state.list.state = 'idle';
          state.list.value = action.payload;
        },
      )
      .addCase(getSftpConfigurationsListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })
      .addCase(getSftpConfigurationByIdAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(
        getSftpConfigurationByIdAsyncThunk.fulfilled,
        (state, action) => {
          state.list.state = 'idle';
          state.byId.value = action.payload;
        },
      )
      .addCase(getSftpConfigurationByIdAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })
      .addCase(createSftpConfigurationAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(createSftpConfigurationAsyncThunk.fulfilled, (state, action) => {
        state.create.state = 'idle';
        state.create.value = action.payload;
      })
      .addCase(createSftpConfigurationAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })
      .addCase(testSftpConfigurationAsyncThunk.pending, (state) => {
        state.testSftp.state === 'pending';
      })
      .addCase(testSftpConfigurationAsyncThunk.fulfilled, (state, action) => {
        state.testSftp.state = 'idle';
        state.testSftp.value = action.payload;
      })
      .addCase(testSftpConfigurationAsyncThunk.rejected, (state) => {
        state.testSftp.state = 'idle';
      })
      .addCase(testSftpConfigurationByIdAsyncThunk.pending, (state) => {
        state.testSftpById.state === 'pending';
      })
      .addCase(
        testSftpConfigurationByIdAsyncThunk.fulfilled,
        (state, action) => {
          state.testSftpById.state = 'idle';
          state.testSftpById.value = action.payload;
        },
      )
      .addCase(testSftpConfigurationByIdAsyncThunk.rejected, (state) => {
        state.testSftpById.state = 'idle';
      })
      .addCase(updateSftpConfigurationAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(updateSftpConfigurationAsyncThunk.fulfilled, (state, action) => {
        state.update.state = 'idle';
        state.update.value = action.payload;
      })
      .addCase(updateSftpConfigurationAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })
      .addCase(deleteSftpConfigurationAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteSftpConfigurationAsyncThunk.fulfilled, (state, action) => {
        state.delete.state = 'idle';
        state.delete.value = action.payload;
      })
      .addCase(deleteSftpConfigurationAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      })
      .addCase(getSftpPublicKeysListAsyncThunk.pending, (state) => {
        state.publicKeysList.state = 'pending';
      })
      .addCase(getSftpPublicKeysListAsyncThunk.fulfilled, (state, action) => {
        state.publicKeysList.state = 'idle';
        state.publicKeysList.value = action.payload;
      })
      .addCase(getSftpPublicKeysListAsyncThunk.rejected, (state) => {
        state.publicKeysList.state = 'idle';
      })
      .addCase(addSftpPublicKeyAsyncThunk.pending, (state) => {
        state.addPublicKey.state = 'pending';
      })
      .addCase(addSftpPublicKeyAsyncThunk.fulfilled, (state, action) => {
        state.addPublicKey.state = 'idle';
        state.addPublicKey.value = action.payload;
      })
      .addCase(addSftpPublicKeyAsyncThunk.rejected, (state) => {
        state.addPublicKey.state = 'idle';
      })
      .addCase(deleteSftpPublicKeyAsyncThunk.pending, (state) => {
        state.deletePublicKey.state = 'pending';
      })
      .addCase(deleteSftpPublicKeyAsyncThunk.fulfilled, (state) => {
        state.deletePublicKey.state = 'idle';
      })
      .addCase(deleteSftpPublicKeyAsyncThunk.rejected, (state) => {
        state.deletePublicKey.state = 'idle';
      })
      .addCase(checkSftpPasswordAsyncThunk.pending, (state) => {
        state.checkSftpPassword.state = 'pending';
      })
      .addCase(checkSftpPasswordAsyncThunk.fulfilled, (state, action) => {
        state.checkSftpPassword.state = 'idle';
        state.checkSftpPassword.value = action.payload;
      })
      .addCase(checkSftpPasswordAsyncThunk.rejected, (state) => {
        state.checkSftpPassword.state = 'idle';
      })
      .addCase(setSftpPasswordAsyncThunk.pending, (state) => {
        state.addSftpPassword.state = 'pending';
      })
      .addCase(setSftpPasswordAsyncThunk.fulfilled, (state) => {
        state.addSftpPassword.state = 'idle';
      })
      .addCase(setSftpPasswordAsyncThunk.rejected, (state) => {
        state.addSftpPassword.state = 'idle';
      })
      .addCase(getSftpConfigAsyncThunk.pending, (state) => {
        state.sftpConfig.state = 'pending';
      })
      .addCase(getSftpConfigAsyncThunk.fulfilled, (state, action) => {
        state.sftpConfig.state = 'idle';
        state.sftpConfig.value = action.payload;
      })
      .addCase(getSftpConfigAsyncThunk.rejected, (state) => {
        state.sftpConfig.state = 'idle';
      });
  },
});
export const selectSftpConfigurationsList = (state: RootState) =>
  state.sftpConfigurations.list.value;

export const selectSftpConfigurationsById = (state: RootState) =>
  state.sftpConfigurations.byId.value;

export const checkSftpCreate = (state: RootState) =>
  state.sftpConfigurations.create;
export const checkSftpUpdate = (state: RootState) =>
  state.sftpConfigurations.update;
export const checkSftpTest = (state: RootState) =>
  state.sftpConfigurations.testSftp;
export const checkSftpByIdTest = (state: RootState) =>
  state.sftpConfigurations.testSftpById;

export const checkSftpDelete = (state: RootState) =>
  state.sftpConfigurations.delete;

export const selectSftpConfig = (state: RootState) =>
  state.sftpConfigurations.sftpConfig.value;

export const selectSftpPublicKeysList = (state: RootState) =>
  state.sftpConfigurations.publicKeysList.value;

export const checkAddSftpPublicKey = (state: RootState) =>
  state.sftpConfigurations.addPublicKey;

export const checkSftpPasswordSet = (state: RootState) =>
  state.sftpConfigurations.checkSftpPassword.value;

export const {
  clearCreateSftpConfig,
  clearUpdateSftpConfig,
  clearDeleteSftp,
  clearTestSftp,
} = sftpConfigurationsSlice.actions;

export default sftpConfigurationsSlice.reducer;

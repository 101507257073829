import { EuiModalBody } from '@elastic/eui';
import { useEffect } from 'react';
import { useAppSelector } from '../../../common/hooks';
import { checkCampaignStatus } from './api/campaignsSlice';
import Modal from '../../../common/modals/Modal';
import { H3 } from '../../App.style';
import { useTranslation } from 'react-i18next';

interface CampaignFinishModalProps {
  isVisible: boolean;
  closeModal: () => void;
  finishCampaign: () => void;
}

const CampaignFinishModal: React.FC<CampaignFinishModalProps> = ({
  isVisible,
  closeModal,
  finishCampaign,
}) => {
  const { t } = useTranslation();
  const campaignStatusChangeResult = useAppSelector(checkCampaignStatus);

  useEffect(() => {
    console.log(campaignStatusChangeResult);
  }, [campaignStatusChangeResult]);

  const body = (
    <EuiModalBody>
      <div>
        <H3>{t('campaigns.finishModal.text1')}</H3>
        <H3 $bold>{t('campaigns.finishModal.text2')}</H3>
      </div>
    </EuiModalBody>
  );

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    finishCampaign();

    return false;
  };
  return (
    <Modal
      isVisible={isVisible}
      headerTitle={`Finish campaign`}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default CampaignFinishModal;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import { Campaign } from '../../../generated/tenants/Api';
import { AccordionsContainer } from './Campaigns.style';
import { EuiAccordion } from '@elastic/eui';
import { H2 } from '../../App.style';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../processes/api/processSlice';
import GeneralSnapshot from './snapshots/GeneralSnapshot';
import VoiceSnapshot from './snapshots/VoiceSnapshot';
import DialogueSnapshot from './snapshots/DialogueSnapshot';
import CampaignPreferencesSnapshot from './snapshots/CampaignPreferencesSnapshot';
import CalendarSnapshot from './snapshots/CalendarSnapshot';
import CallingPreferencesSnapshot from './snapshots/CallingPreferencesSnapshot';
import { useTranslation } from 'react-i18next';

interface CampaignSnapshotProps {
  campaign: Campaign;
}

const CampaignSnapshot: React.FC<CampaignSnapshotProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);

  useEffect(() => {
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, []);

  const apiProcessesList = useAppSelector(selectProcessesList);

  const findProcessById = () => {
    if (campaign) {
      const process = apiProcessesList.find(
        (process) => process.id === campaign.process,
      );
      return process;
    }
  };

  const usedProcess = findProcessById();

  const [openAccordionId, setOpenAccordionId] = useState('');

  const accordionsData = [
    {
      id: 'general',
      title: t('campaigns.snapshots.general'),
      content: () => (
        <GeneralSnapshot campaign={campaign} usedProcess={usedProcess} />
      ),
    },
    {
      id: 'dialogue',
      title: t('campaigns.snapshots.dialogue'),
      content: () => <DialogueSnapshot />,
    },
    {
      id: 'voice',
      title: t('campaigns.snapshots.voice'),
      content: () => <VoiceSnapshot voice={campaign?.voice_configuration} />,
    },

    {
      id: 'callingPreferences',
      title: t('campaigns.snapshots.callingPreferences'),
      content: () => <CallingPreferencesSnapshot campaign={campaign} />,
    },

    {
      id: 'campaignPreferences',
      title: t('campaigns.snapshots.campaignPreferences'),
      content: () => <CampaignPreferencesSnapshot campaign={campaign} />,
    },

    {
      id: 'calendar',
      title: t('campaigns.snapshots.calendar'),
      content: () => <CalendarSnapshot campaign={campaign} />,
    },
  ];

  const handleAccordionClick = (clickedAccordionId: string) => {
    if (openAccordionId === clickedAccordionId) {
      setOpenAccordionId('');
    } else {
      setOpenAccordionId(clickedAccordionId);
    }
  };

  return (
    <AccordionsContainer>
      {accordionsData.map(({ id, title, content }) => (
        <EuiAccordion
          id={id}
          key={id}
          arrowDisplay="left"
          buttonContent={<H2>{title}</H2>}
          forceState={openAccordionId === id ? 'open' : 'closed'}
          onToggle={() => handleAccordionClick(id)}
        >
          <div>{content()}</div>
        </EuiAccordion>
      ))}
    </AccordionsContainer>
  );
};

export default CampaignSnapshot;

import { Api } from '../../../../generated/public/Api';

const { api } = new Api();

export const getConfig = () => api.configRetrieve().then((_) => _.data);

export const getLoginState = () => api.sessionRetrieve().then((_) => _.data);

export const login = (args: { email: string; password: string }) =>
  api.sessionCreate(args).then((_) => _.data);

export const logout = () =>
  api
    .sessionDestroy()
    .then((_) => _.data)
    .then(() => {
      window.location.replace('http://saas.lekta.ai/login');
    });

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { H3, H4 } from '../../../App.style';
import {
  LanguageOptions,
  PanelSidebarContainer,
  SidebarLinkContainer,
  SidebarSectionContainer,
  SubLinksContainer,
} from '../Settings.style';
import { ChevronDownIcon } from '../../../../resources/icons-new/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../resources/icons-new/ChevronUpIcon';
import { useTranslation } from 'react-i18next';
import { EuiPopover } from '@elastic/eui';
import { ChevronRightIcon } from '../../../../resources/icons-new/ChevronRIght';
import { logoutAsyncThunk, selectUser } from '../../session/api/sessionSlice';
import {
  SidebarLektaLogoFont,
  SidebarLogoContainer,
  UserPopoverButton,
  UserPopoverContainer,
  UserProfileContainer,
  UserProfileNameContainer,
} from '../../sidebar/Sidebar.style';
import { UserAvatarImg, UserAvatarDiv } from '../../users/Users.style';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { selectUserSettings } from '../userSettings/api/userSettingsSlice';
import { SelectTenantIcon } from '../../../../resources/icons-new/SelectTenantIcon';
import { UserIcon } from '../../../../resources/icons-new/UserIcon';
import { PhonePlusIcon } from '../../../../resources/icons-new/PhonePlusIcon';
import { BillingsIcon } from '../../../../resources/icons-new/BillingsIcon';
import { LektaLogoIcon } from '../../../../resources/icons-new/LektaLogoIcon';
import { SuccessIcon } from '../../../../resources/icons-new/SuccessIcon';

const SuperAdminPanelSidebar = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [openSections, setOpenSections] = useState<number[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const links = [
    {
      label: t('superAdminPanel.sidebar.selectTenant'),
      path: '/',
      subLinks: [],
      icon: <SelectTenantIcon color={'#FFFFFF'} />,
    },
    {
      label: t('superAdminPanel.sidebar.superUsers'),
      path: '/superusers',
      subLinks: [],
      icon: <UserIcon color={'#FFFFFF'} />,
    },
    {
      label: t('superAdminPanel.sidebar.numbers.title'),
      path: '/numbers/*',
      icon: <PhonePlusIcon color={'#FFFFFF'} />,
      subLinks: [
        {
          path: '/numbers/add',
          label: t('superAdminPanel.sidebar.numbers.add'),
        },
        {
          path: '/numbers/assign',
          label: t('superAdminPanel.sidebar.numbers.assign'),
        },
        {
          path: '/numbers/history',
          label: t('superAdminPanel.sidebar.numbers.history'),
        },
      ],
    },
    {
      label: t('superAdminPanel.sidebar.billings'),
      path: '/billings',
      subLinks: [],
      icon: <BillingsIcon />,
    },
  ];

  const toggleSection = (index: number, path: string | null) => {
    if (path && links[index].subLinks.length === 0) {
      navigate(path); // navigate to the path using navigate
      return;
    }

    setOpenSections((prevOpenSections) => {
      const isSectionOpen = prevOpenSections.includes(index);
      return isSectionOpen
        ? prevOpenSections.filter((sectionIndex) => sectionIndex !== index)
        : [...prevOpenSections, index];
    });
  };

  useEffect(() => {
    const newOpenSections: number[] = [];
    links.forEach((section, index) => {
      const isSubLinkMatch = section.subLinks.some(
        (subLink) => subLink.path === location.pathname,
      );
      if (isSubLinkMatch) {
        newOpenSections.push(index);
      }
    });
    setOpenSections(newOpenSections);
  }, [location.pathname]);

  //user popover
  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState<boolean>(false);
  const handleUserPopoverClick = () => setIsUserPopoverOpen((prev) => !prev);
  const closeUserPopover = () => setIsUserPopoverOpen(false);

  const userSettings = useAppSelector(selectUserSettings);
  const user = useAppSelector(selectUser);

  const handleGoToDashboard = () => {
    navigate('/dashboard/');
  };

  const [showLanguageOptions, setLanguageOptions] = useState<boolean>(false);

  const handleLanguageChange = (language: 'pl-PL' | 'en-GB' | 'es-ES') => {
    i18n.changeLanguage(language);
    setLanguageOptions(false);
  };

  return (
    <PanelSidebarContainer $superAdmin style={{ paddingTop: 24 }}>
      <SidebarLogoContainer onClick={handleGoToDashboard}>
        <LektaLogoIcon color="#ffffff" />
        <SidebarLektaLogoFont style={{ color: 'white' }}>
          Lease
        </SidebarLektaLogoFont>
      </SidebarLogoContainer>

      <H3 $semibold style={{ color: 'white', marginBottom: 8 }}>
        {`[ ${t('navbar.adminPanel')} ]`}
      </H3>

      {links.map((section, index) => (
        <div key={index} style={{ width: '90%', marginBottom: 8 }}>
          <SidebarSectionContainer
            $superAdmin
            $selected={location.pathname === section.path}
            onClick={() => toggleSection(index, section.path)}
          >
            <div style={{ display: 'flex', gap: 8 }}>
              {section.icon && section.icon}
              <H3 color="#fff">{section.label}</H3>
            </div>
            {section.subLinks.length > 0 &&
              (openSections.includes(index) ? (
                <ChevronUpIcon color="#fff" />
              ) : (
                <ChevronDownIcon color="#fff" />
              ))}
          </SidebarSectionContainer>
          {openSections.includes(index) && section.subLinks.length > 0 && (
            <SubLinksContainer>
              {section.subLinks.map((link) => (
                <SidebarLinkContainer
                  $superAdmin
                  key={link.path}
                  $selected={location.pathname === link.path}
                  to={link.path}
                >
                  {link.label}
                </SidebarLinkContainer>
              ))}
            </SubLinksContainer>
          )}
        </div>
      ))}

      <UserPopoverContainer>
        {user.value && (
          <EuiPopover
            ownFocus={false}
            button={
              <div style={{ width: 160 }}>
                <UserProfileContainer onClick={handleUserPopoverClick}>
                  {userSettings.value?.avatar ? (
                    <UserAvatarImg src={userSettings.value.avatar} />
                  ) : (
                    <UserAvatarDiv
                      color={userSettings.value?.accent_color ?? 'black'}
                    >
                      <H4 $medium style={{ color: 'white' }}>
                        {userSettings.value?.initials
                          ? userSettings.value.initials
                          : 'SA'}
                      </H4>
                    </UserAvatarDiv>
                  )}

                  <UserProfileNameContainer>
                    <H4 $medium>{user.value.first_name}</H4>
                    <H4 $medium>{user.value.last_name}</H4>
                  </UserProfileNameContainer>

                  <ChevronRightIcon color="#FFF" />
                </UserProfileContainer>
              </div>
            }
            isOpen={isUserPopoverOpen}
            closePopover={closeUserPopover}
            anchorPosition="rightCenter"
            panelPaddingSize="none"
            hasArrow={false}
          >
            <div
              style={{ width: 120, position: 'relative' }}
              onMouseEnter={() => setLanguageOptions(true)}
              onMouseLeave={() => setLanguageOptions(false)}
            >
              <UserPopoverButton
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                $radiusTop
                $radiusBottom
                onClick={() => {
                  dispatch(logoutAsyncThunk());
                }}
              >
                <H3>Language</H3>
                <ChevronRightIcon />
              </UserPopoverButton>
              <LanguageOptions $show={showLanguageOptions}>
                <UserPopoverButton
                  $radiusTop
                  onClick={() => handleLanguageChange('pl-PL')}
                  style={{ display: 'flex', gap: 8, width: 80 }}
                >
                  PL
                  {i18n.language === 'pl-PL' && <SuccessIcon />}
                </UserPopoverButton>
                <UserPopoverButton
                  style={{ display: 'flex', gap: 8, width: 80 }}
                  onClick={() => handleLanguageChange('en-GB')}
                >
                  ENG
                  {i18n.language === 'en-GB' && <SuccessIcon />}
                </UserPopoverButton>
                <UserPopoverButton
                  style={{
                    display: 'flex',
                    gap: 8,
                    width: 80,
                    cursor: 'not-allowed',
                    background: 'lightgray',
                  }}
                  onClick={() => alert('Not supported yet ')}
                  $radiusBottom
                >
                  ES
                  {i18n.language === 'es-ES' && <SuccessIcon />}
                </UserPopoverButton>
              </LanguageOptions>
            </div>

            <div style={{ width: 120 }}>
              <UserPopoverButton
                $radiusTop
                $radiusBottom
                onClick={() => {
                  dispatch(logoutAsyncThunk());
                }}
              >
                <H3>{t('sidebar.popover.logout')}</H3>
              </UserPopoverButton>
            </div>
          </EuiPopover>
        )}
      </UserPopoverContainer>
    </PanelSidebarContainer>
  );
};

export default SuperAdminPanelSidebar;

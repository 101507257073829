import {
  CampaignNonWorkingDaysConfiguration,
  CampaignNonWorkingDaysConfigurationRequest,
} from './../../../../../../generated/tenants/Api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../../store';
import {
  createBotDaysOff,
  getBotDaysOffList,
  getBotDaysOffDetailed,
  updateBotDaysOff,
  deleteBotDaysOff,
  CheckBotDaysOffResult,
} from './botDaysOffApi';

export interface BotDaysOffState {
  list: {
    state: 'pending' | 'idle';
    value: CampaignNonWorkingDaysConfiguration[];
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckBotDaysOffResult;
  };
  detailed: {
    state: 'pending' | 'idle';
    value?: CampaignNonWorkingDaysConfiguration;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckBotDaysOffResult;
  };
  delete: {
    state: 'pending' | 'idle';
    value?: CheckBotDaysOffResult;
  };
}

const initialState: BotDaysOffState = {
  list: {
    state: 'pending',
    value: [],
  },
  create: { state: 'pending' },
  update: { state: 'pending' },
  delete: { state: 'pending' },
  detailed: { state: 'pending' },
};

export const getBotDaysOffListAsyncThunk = createAsyncThunk(
  'botDaysOff/list',
  (id: string) => getBotDaysOffList(id),
);

export const createBotDaysOffAsyncThunk = createAsyncThunk(
  'botDaysOff/create',
  (data: CampaignNonWorkingDaysConfigurationRequest) => createBotDaysOff(data),
);

export const getBotDaysOffDetailedAsyncThunk = createAsyncThunk(
  'botDaysOff/detailed',
  (id: string) => getBotDaysOffDetailed(id),
);

export const updateBotDaysOffAsyncThunk = createAsyncThunk(
  'botDaysOff/update',
  (args: { id: string; data: CampaignNonWorkingDaysConfigurationRequest }) =>
    updateBotDaysOff(args.id, args.data),
);

export const deleteBotDaysOffAsyncThunk = createAsyncThunk(
  'botDaysOff/delete',
  (id: string) => deleteBotDaysOff(id),
);

export const botDaysOffSlice = createSlice({
  name: 'botDaysOff',
  initialState,
  reducers: {
    clearCreateBotDaysOff: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateBotDaysOff: (state) => {
      state.update = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder

      //list
      .addCase(getBotDaysOffListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(getBotDaysOffListAsyncThunk.fulfilled, (state, action) => {
        state.list.state = 'idle';
        state.list.value = action.payload;
      })
      .addCase(getBotDaysOffListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //detailed
      .addCase(getBotDaysOffDetailedAsyncThunk.pending, (state) => {
        state.detailed.state = 'pending';
      })
      .addCase(getBotDaysOffDetailedAsyncThunk.fulfilled, (state, action) => {
        state.detailed.state = 'idle';
        state.detailed.value = action.payload;
      })
      .addCase(getBotDaysOffDetailedAsyncThunk.rejected, (state) => {
        state.detailed.state = 'idle';
      })

      //create
      .addCase(createBotDaysOffAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(createBotDaysOffAsyncThunk.fulfilled, (state, action) => {
        state.create.state = 'idle';
        state.create.value = action.payload;
      })
      .addCase(createBotDaysOffAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })

      //update
      .addCase(updateBotDaysOffAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(updateBotDaysOffAsyncThunk.fulfilled, (state, action) => {
        state.update.state = 'idle';
        state.update.value = action.payload;
      })
      .addCase(updateBotDaysOffAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })

      //delete
      .addCase(deleteBotDaysOffAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteBotDaysOffAsyncThunk.fulfilled, (state, action) => {
        state.delete.state = 'idle';
        state.delete.value = action.payload;
      })
      .addCase(deleteBotDaysOffAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      });
  },
});

export const selectBotDaysOffList = (state: RootState) =>
  state.botDaysOff.list.value;

export const selectBotDaysOffDetailed = (state: RootState) =>
  state.botDaysOff.detailed.value;

export const checkBotDaysOffCreate = (state: RootState) =>
  state.botDaysOff.create;

export const checkBotDaysOffUpdate = (state: RootState) =>
  state.botDaysOff.update;

export const checkBotDaysOffDelete = (state: RootState) =>
  state.botDaysOff.delete;

export const { clearCreateBotDaysOff, clearUpdateBotDaysOff } =
  botDaysOffSlice.actions;

export default botDaysOffSlice.reducer;

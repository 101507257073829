import React, { useState } from 'react';
import { Strategy } from '../../../../../../generated/tenants/Api';
import {
  EuiFieldText,
  EuiSwitch,
  EuiTextArea,
  EuiToolTip,
  copyToClipboard,
} from '@elastic/eui';
import { H3 } from '../../../../../App.style';
import { FormRow } from '../../../../processes/forms/ProcessForm.style';
import { StrategyKeyRowContainer } from './StrategiesPanel.style';
import { CopyIconContainer, TextAreaWrapper } from '../sftp/SftpPanel.style';
import { CopyIcon } from '../../../../../../resources/icons-new/CopyIcon';
import { InfoIcon } from '../../../../../../resources/icons-new/InfoIcon';
import { useTranslation } from 'react-i18next';

interface StrategiesFormReadOnlyProps {
  strategy: Strategy;
  publicKey: string;
}

const StrategiesFormReadOnly: React.FC<StrategiesFormReadOnlyProps> = ({
  strategy,
  publicKey,
}) => {
  const { t } = useTranslation();
  const [publicKeyCopied, setPublicKeyCopied] = useState<boolean>(false);
  const handleCopyPublicKey = (key: string) => {
    setPublicKeyCopied(true);
    copyToClipboard(key);
  };
  return (
    <>
      <FormRow
        label={
          <H3>
            {t('settings.adminPanel.manageWorkspaces.form.assignedLines')}s
          </H3>
        }
      >
        <EuiFieldText value={strategy.assigned_lines} type="number" readOnly />
      </FormRow>

      <StrategyKeyRowContainer>
        <H3>
          {t('settings.adminPanel.manageWorkspaces.form.encryptImportedData')}
        </H3>
        <EuiToolTip
          position="top"
          content={t(
            'settings.adminPanel.manageWorkspaces.form.encryptImportedDataTooltip',
          )}
        >
          <InfoIcon />
        </EuiToolTip>
        <EuiSwitch
          checked={strategy.decipher_input_data ?? false}
          compressed
          label={
            strategy.decipher_input_data
              ? t('settings.adminPanel.manageWorkspaces.form.yes')
              : t('settings.adminPanel.manageWorkspaces.form.no')
          }
          id=""
          disabled
          onChange={() => console.log('')}
        />
      </StrategyKeyRowContainer>

      {strategy.decipher_input_data && (
        <FormRow
          style={{ marginTop: 20 }}
          label={
            <H3>{t('settings.adminPanel.manageWorkspaces.form.publicKey')}</H3>
          }
        >
          <TextAreaWrapper>
            <EuiTextArea
              style={{
                width: 400,
                minHeight: 50,
                background: '#F5F7FA',
                paddingRight: 25,
              }}
              value={
                publicKey ??
                t('settings.adminPanel.manageWorkspaces.form.noPublicKey')
              }
              readOnly
              disabled
            />
            <CopyIconContainer>
              <EuiToolTip
                position="top"
                content={
                  <p style={{ textAlign: 'center' }}>
                    {publicKeyCopied
                      ? t(
                          'settings.adminPanel.manageWorkspaces.form.publicKeyCopied',
                        )
                      : t('settings.adminPanel.manageWorkspaces.form.copy')}
                  </p>
                }
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyPublicKey(publicKey);
                  }}
                  onMouseLeave={() => setPublicKeyCopied(false)}
                >
                  <CopyIcon $pointer />
                </div>
              </EuiToolTip>
            </CopyIconContainer>
          </TextAreaWrapper>
        </FormRow>
      )}

      <StrategyKeyRowContainer>
        <H3>
          {t('settings.adminPanel.manageWorkspaces.form.encryptOutgoingData')}
        </H3>
        <EuiToolTip
          position="top"
          content={t(
            'settings.adminPanel.manageWorkspaces.form.encryptOutgoingDataTooltip',
          )}
        >
          <InfoIcon />
        </EuiToolTip>
        <EuiSwitch
          checked={strategy.cipher_output_data ?? false}
          compressed
          label={
            strategy.cipher_output_data
              ? t('settings.adminPanel.manageWorkspaces.form.yes')
              : t('settings.adminPanel.manageWorkspaces.form.no')
          }
          id=""
          onChange={() => console.log('')}
          disabled
        />
      </StrategyKeyRowContainer>

      {strategy.cipher_output_data && (
        <FormRow
          style={{ marginTop: 20 }}
          label={
            <H3>
              {t('settings.adminPanel.manageWorkspaces.form.publicGpgKey')}
            </H3>
          }
        >
          <EuiTextArea
            readOnly
            value={
              strategy.cipher_public_key_gpg ??
              t('settings.adminPanel.manageWorkspaces.form.notDefined')
            }
          />
        </FormRow>
      )}
    </>
  );
};

export default StrategiesFormReadOnly;

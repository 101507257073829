import {
  CallingConfiguration,
  CallingConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import {
  getCallingConfigurationList,
  getCallingConfigurationDetailedView,
  updateCallingConfiguration,
  CheckCallingConfigurationResult,
  createCallingConfiguration,
  deleteCallingConfiguration,
} from './callingConfigurationApi';

export interface CallingConfigurationState {
  list: {
    state: 'pending' | 'idle';
    value: CallingConfiguration[];
  };
  byId: {
    state: 'pending' | 'idle';
    value?: CallingConfiguration;
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckCallingConfigurationResult;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckCallingConfigurationResult;
  };
  delete: {
    state: 'pending' | 'idle';
    value?: CheckCallingConfigurationResult;
  };
}

const initialState: CallingConfigurationState = {
  list: {
    state: 'pending',
    value: [],
  },
  create: { state: 'pending' },
  update: { state: 'pending' },
  byId: {
    state: 'pending',
  },
  delete: {
    state: 'pending',
  },
};
export const getCallingConfigurationListAsyncThunk = createAsyncThunk(
  'callingConfiguration/list',
  (id: string) => getCallingConfigurationList(id),
);

export const getCallingConfigurationDetailedViewAsyncThunk = createAsyncThunk(
  'callingConfiguration/byId',
  (args: string) => getCallingConfigurationDetailedView(args),
);

export const createCallingConfigurationAsyncThunk = createAsyncThunk(
  'callingConfiguration/create',
  (data: CallingConfigurationRequest) => createCallingConfiguration(data),
);

export const updateCallingConfigurationAsyncThunk = createAsyncThunk(
  'callingConfiguration/update',
  (args: { id: string; data: CallingConfigurationRequest }) =>
    updateCallingConfiguration(args.id, args.data),
);

export const deleteCallingConfigurationAsyncThunk = createAsyncThunk(
  'callingConfiguration/delete',
  (id: string) => deleteCallingConfiguration(id),
);

export const callingConfigurationSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    clearCreateCallingConfiguration: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateCallingConfiguration: (state) => {
      state.update = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCallingConfigurationListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(
        getCallingConfigurationListAsyncThunk.fulfilled,
        (state, action) => {
          state.list.state = 'idle';
          state.list.value = action.payload;
        },
      )
      .addCase(getCallingConfigurationListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })
      .addCase(
        getCallingConfigurationDetailedViewAsyncThunk.pending,
        (state) => {
          state.byId.state = 'pending';
        },
      )
      .addCase(
        getCallingConfigurationDetailedViewAsyncThunk.fulfilled,
        (state, action) => {
          state.byId.state = 'idle';
          state.byId.value = action.payload;
        },
      )
      .addCase(
        getCallingConfigurationDetailedViewAsyncThunk.rejected,
        (state) => {
          state.byId.state = 'idle';
        },
      )
      .addCase(createCallingConfigurationAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(
        createCallingConfigurationAsyncThunk.fulfilled,
        (state, action) => {
          state.create.state = 'idle';
          state.create.value = action.payload;
        },
      )
      .addCase(createCallingConfigurationAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })
      .addCase(updateCallingConfigurationAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(
        updateCallingConfigurationAsyncThunk.fulfilled,
        (state, action) => {
          state.update.state = 'idle';
          state.update.value = action.payload;
        },
      )
      .addCase(updateCallingConfigurationAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })
      .addCase(deleteCallingConfigurationAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(
        deleteCallingConfigurationAsyncThunk.fulfilled,
        (state, action) => {
          state.delete.state = 'idle';
          state.delete.value = action.payload;
        },
      )
      .addCase(deleteCallingConfigurationAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      });
  },
});

export const selectCallingConfigurationList = (state: RootState) =>
  state.callingConfigurations.list.value;
export const selectCallingConfigurationDetailedView = (state: RootState) =>
  state.callingConfigurations.byId.value;
export const checkCallingConfigurationCreate = (state: RootState) =>
  state.callingConfigurations.create;
export const checkCallingConfigurationEdit = (state: RootState) =>
  state.callingConfigurations.update;
export const checkCallingConfigurationDelete = (state: RootState) =>
  state.callingConfigurations.delete;

export const {
  clearCreateCallingConfiguration,
  clearUpdateCallingConfiguration,
} = callingConfigurationSlice.actions;

export default callingConfigurationSlice.reducer;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../../../common/inputs/Checkbox';
import { ArrowIconLeft } from '../../../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../../../resources/icons-new/ArrowIconRight';
import {
  Button,
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_30,
  H3,
  H4,
} from '../../../../../App.style';
import {
  TableContainer,
  PaginationContainer,
  PaginationText,
  ArrowsContainer,
} from '../../../../campaigns/panel/CampaignsPanel.style';
import {
  CallsTab,
  CallsTableHeader,
  CallsTableData,
  CallsTableRow,
} from '../../../../campaigns/tabs/calls/Calls.styles';
import {
  BillingsSelectedStyledSpan,
  BillingsTableHeaderSelected,
  BillingsTableHeaderSelectedItem,
  BillingsTableHeaderSelectedLeftContainer,
  BillingsTableHeaderSelectedRightContainer,
} from './Billings.style';
import { FileXlsxIcon } from '../../../../../../resources/icons-new/FileXlsxIcon';
import { BillingCampaignList } from '../../../../../../generated/public/Api';
import { CopyIcon } from '../../../../../../resources/icons-new/CopyIcon';
import { EuiToolTip, copyToClipboard } from '@elastic/eui';

interface BillingsTableProps {
  billingsList: BillingCampaignList[];
  selectedBillings: BillingCampaignList[];
  setSelectedBillings: React.Dispatch<
    React.SetStateAction<BillingCampaignList[]>
  >;
  currentPage: number;
  itemsPerPage: number;
  handlePageChange: (page: number) => void;
  handleGenerateReport: () => void;
  totalCampaigns: number;
  isAllCampaignsSelected: boolean;
  handleSelectAllClick: (state: boolean) => void;
}

const BillingsTable: React.FC<BillingsTableProps> = ({
  billingsList,
  selectedBillings,
  setSelectedBillings,
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleGenerateReport,
  totalCampaigns,
  isAllCampaignsSelected,
  handleSelectAllClick,
}) => {
  const { t } = useTranslation();
  const totalItems = billingsList?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  //checkboxes
  const toggleRowSelection = (id: string) => {
    setSelectedBillings((prevState) =>
      prevState.some((billing) => billing.id === id)
        ? prevState.filter((billing) => billing.id !== id)
        : [...prevState, billingsList.find((billing) => billing.id === id)!],
    );
  };

  const toggleSelectAll = () => {
    if (billingsList) {
      if (selectedBillings.length === billingsList.length) {
        setSelectedBillings([]);
      } else {
        setSelectedBillings(billingsList);
      }
    }
  };

  const calculateTotals = (billings: BillingCampaignList[]) => {
    let totalCost = 0;
    let totalSmsCost = 0;
    let totalTelcoCost = 0;
    let totalFinishedRecords = 0;
    let totalLoadedRecords = 0;

    billings.forEach((billing) => {
      totalCost += parseFloat(billing.total_cost);
      totalSmsCost += parseFloat(billing.sms_cost);
      totalTelcoCost += parseFloat(billing.telco_cost);
      totalFinishedRecords += parseFloat(billing.finished_records.toString());
      totalLoadedRecords += parseFloat(billing.loaded_records.toString());
    });

    return {
      totalCost,
      totalSmsCost,
      totalTelcoCost,
      totalFinishedRecords,
      totalLoadedRecords,
    };
  };

  const {
    totalSmsCost,
    totalTelcoCost,
    totalFinishedRecords,
    totalLoadedRecords,
  } = calculateTotals(selectedBillings);

  const [campaignIdCopied, setCampaignIdCopied] = useState<boolean>(false);

  const handleCampaignIdClick = (id: string) => {
    copyToClipboard(id);
    setCampaignIdCopied(true);
  };
  return (
    <TableContainer>
      {billingsList && (
        <CallsTab>
          <thead>
            <tr
              style={{
                background: selectedBillings.length > 0 ? COLOR_NEUTRAL_10 : '',
                border:
                  selectedBillings.length > 0
                    ? `1px solid ${COLOR_NEUTRAL_30}`
                    : '',
                borderRadius: 5,
                verticalAlign: 'middle',
                height: 40,
              }}
            >
              <CallsTableHeader checkbox onClick={toggleSelectAll}>
                <Checkbox
                  checked={selectedBillings.length === billingsList.length}
                  readOnly
                />
              </CallsTableHeader>
              {selectedBillings.length > 0 ? (
                <BillingsTableHeaderSelected>
                  <BillingsTableHeaderSelectedLeftContainer>
                    <div style={{ display: 'flex', gap: 4 }}>
                      <H3 $bold>{selectedBillings.length} </H3>
                      <H3>
                        {' '}
                        {t('billings.table.ofSelected', {
                          length: billingsList.length,
                        })}
                      </H3>
                    </div>
                    <H3
                      $grey
                      $bold={isAllCampaignsSelected}
                      style={{ cursor: 'pointer', marginLeft: 24 }}
                      onClick={() =>
                        handleSelectAllClick(isAllCampaignsSelected)
                      }
                    >
                      {t('billings.table.selectAll')} {totalCampaigns}
                    </H3>
                  </BillingsTableHeaderSelectedLeftContainer>

                  <BillingsTableHeaderSelectedRightContainer>
                    <H3>{t('billings.table.total')}</H3>

                    <BillingsTableHeaderSelectedItem>
                      <H3>
                        {t('billings.table.telcoCost')}
                        <BillingsSelectedStyledSpan>
                          {totalTelcoCost.toFixed(2)} PLN
                        </BillingsSelectedStyledSpan>
                      </H3>
                    </BillingsTableHeaderSelectedItem>

                    <H3>
                      {t('billings.table.smsCost')}
                      <BillingsSelectedStyledSpan>
                        {totalSmsCost.toFixed(2)} PLN
                      </BillingsSelectedStyledSpan>
                    </H3>

                    <H3>
                      {t('billings.table.doneTotal')}
                      <BillingsSelectedStyledSpan>
                        {totalFinishedRecords} / {totalLoadedRecords}
                      </BillingsSelectedStyledSpan>
                    </H3>

                    <Button
                      $size={'S'}
                      $styleType="NORMAL"
                      onClick={handleGenerateReport}
                      disabled={selectedBillings.length === 0}
                    >
                      <H3>{t('billings.table.exportFile')}</H3>
                      <FileXlsxIcon />
                    </Button>
                  </BillingsTableHeaderSelectedRightContainer>
                </BillingsTableHeaderSelected>
              ) : (
                <>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.tenantName')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.process')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.campaignID')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.startDate')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.endDate')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.status')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.telcoCostTable')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.smsCostTable')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <H4 $grey>{t('billings.table.doneTotal')}</H4>
                  </CallsTableHeader>
                  <CallsTableHeader>
                    <PaginationContainer>
                      <PaginationText>
                        {getPaginationTextContent(
                          currentPage,
                          itemsPerPage,
                          totalItems,
                        )}
                      </PaginationText>

                      <ArrowsContainer>
                        <button
                          disabled={currentPage === 1}
                          onClick={() =>
                            handlePageChange(Math.max(1, currentPage - 1))
                          }
                        >
                          <ArrowIconLeft disabled={currentPage === 1} />
                        </button>

                        <button
                          disabled={currentPage === totalPages}
                          onClick={() =>
                            handlePageChange(
                              Math.min(totalPages, currentPage + 1),
                            )
                          }
                        >
                          <ArrowIconRight
                            disabled={currentPage === totalPages}
                          />
                        </button>
                      </ArrowsContainer>
                    </PaginationContainer>
                  </CallsTableHeader>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {billingsList.length === 0 ? (
              <div
                style={{
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {t('billings.table.noBillingsToShow')}
              </div>
            ) : (
              billingsList.map((billing) => {
                return (
                  <CallsTableRow
                    $selected={selectedBillings.some(
                      (obj) => obj.id === billing.id,
                    )}
                    key={billing.id}
                    onClick={() => toggleRowSelection(billing.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <CallsTableData
                      checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRowSelection(billing.id);
                      }}
                    >
                      <Checkbox
                        checked={selectedBillings.some(
                          (obj) => obj.id === billing.id,
                        )}
                        readOnly
                      />
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{billing.tenant_name}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{billing.process_name}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <H4>{billing.id.slice(0, 10)}...</H4>
                        <EuiToolTip
                          position="top"
                          content={
                            <p style={{ textAlign: 'center' }}>
                              {campaignIdCopied
                                ? t('campaigns.idCopied')
                                : t('campaigns.copyID')}
                            </p>
                          }
                        >
                          <Button
                            $styleType="SECONDARY"
                            $size="S"
                            onClick={(e) => {
                              e.stopPropagation();

                              handleCampaignIdClick(billing.id);
                            }}
                          >
                            <CopyIcon />
                          </Button>
                        </EuiToolTip>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                        }}
                      ></div>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{billing.start_date}</H4>
                    </CallsTableData>
                    <CallsTableData>{billing.end_date}</CallsTableData>
                    <CallsTableData>
                      <H4>{billing.status}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{Number(billing.telco_cost).toFixed(2)} PLN</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{Number(billing.sms_cost).toFixed(2)} PLN</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>
                        {billing.finished_records} / {billing.loaded_records}{' '}
                      </H4>
                    </CallsTableData>
                    <CallsTableData></CallsTableData>
                  </CallsTableRow>
                );
              })
            )}
          </tbody>
        </CallsTab>
      )}
    </TableContainer>
  );
};

export default BillingsTable;

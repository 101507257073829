import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../../../store';
import { getLoginStateAsyncThunk } from '../../session/api/sessionSlice';
import {
  addUserAfterInviteAsyncThunk,
  checkInvitationAsyncThunk,
} from './userSlice';

export const invitationMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const startAppListening =
  invitationMiddleware.startListening as unknown as AppStartListening;
export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

startAppListening({
  actionCreator: addUserAfterInviteAsyncThunk.fulfilled,
  effect: (action, listenerApi) => {
    if (action.payload.status === 'success') {
      listenerApi.dispatch(getLoginStateAsyncThunk());
    }
  },
});

startAppListening({
  actionCreator: checkInvitationAsyncThunk.fulfilled,
  effect: (action, listenerApi) => {
    if (action.payload.status === 'success')
      listenerApi.dispatch(getLoginStateAsyncThunk());
  },
});

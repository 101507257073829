import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const EyeSlashIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M23.3781 19.6657C26.3573 17.0116 28 13.9999 28 13.9999C28 13.9999 22.75 4.37494 14 4.37494C12.2231 4.37494 10.5905 4.77188 9.11692 5.40453L10.466 6.75356C11.5672 6.358 12.7477 6.12494 14 6.12494C17.7089 6.12494 20.7882 8.16926 23.0438 10.4249C24.1565 11.5375 25.0212 12.6558 25.6073 13.4967C25.7346 13.6793 25.8482 13.8481 25.9478 13.9999C25.8482 14.1518 25.7346 14.3206 25.6073 14.5032C25.0212 15.3441 24.1565 16.4624 23.0438 17.575C22.7554 17.8634 22.4535 18.1484 22.1385 18.4261L23.3781 19.6657Z" />
    <path d="M19.7706 16.0582C20 15.4149 20.125 14.722 20.125 13.9999C20.125 10.6172 17.3827 7.87494 14 7.87494C13.2779 7.87494 12.585 7.9999 11.9417 8.22936L13.3808 9.66842C13.5831 9.63977 13.7898 9.62494 14 9.62494C16.4162 9.62494 18.375 11.5837 18.375 13.9999C18.375 14.2101 18.3602 14.4169 18.3315 14.6191L19.7706 16.0582Z" />
    <path d="M14.6192 18.3315L16.0583 19.7705C15.415 20 14.7221 20.1249 14 20.1249C10.6173 20.1249 7.875 17.3827 7.875 13.9999C7.875 13.2778 7.99996 12.5849 8.22943 11.9417L9.66848 13.3807C9.63982 13.583 9.625 13.7897 9.625 13.9999C9.625 16.4162 11.5838 18.3749 14 18.3749C14.2102 18.3749 14.4169 18.3601 14.6192 18.3315Z" />
    <path d="M5.86152 9.57375C5.54654 9.85145 5.24463 10.1364 4.95619 10.4249C3.84354 11.5375 2.97878 12.6558 2.39273 13.4967C2.26545 13.6793 2.15185 13.8481 2.05222 13.9999C2.15185 14.1518 2.26545 14.3206 2.39273 14.5032C2.97878 15.3441 3.84354 16.4624 4.95619 17.575C7.21181 19.8306 10.2911 21.8749 14 21.8749C15.2523 21.8749 16.4328 21.6419 17.5341 21.2463L18.8831 22.5953C17.4095 23.228 15.7769 23.6249 14 23.6249C5.25 23.6249 0 13.9999 0 13.9999C0 13.9999 1.64275 10.9882 4.62195 8.33418L5.86152 9.57375Z" />
    <path d="M23.8813 25.1187L2.88128 4.11866L4.11872 2.88123L25.1187 23.8812L23.8813 25.1187Z" />
  </StyledSvg>
);

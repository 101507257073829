import React from 'react';

import { H3 } from '../../../App.style';
import { SnapshotContainer } from '../Campaigns.style';

const DialogueSnapshot = () => {
  return (
    <SnapshotContainer>
      <H3>Kiedyś</H3>
    </SnapshotContainer>
  );
};

export default DialogueSnapshot;

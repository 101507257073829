import { COLOR_NEUTRAL_10, COLOR_NEUTRAL_80 } from './../../App.style';
import styled, { css } from 'styled-components';
import { COLOR_NEUTRAL_40, H1, H2, H4 } from '../../App.style';

export const AnalyticsContainer = styled.div`
  width: 100%;

  height: 100vh;

  padding-top: 80px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  padding: 24px;
`;

export const AnalyticsChartContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;

  position: relative;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 30px;
`;

export const AnalyticsChartTopContainer = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const AnalyticsChartBottomContainer = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
`;

export const AnalyticsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  height: calc(100% - 30px);

  overflow: hidden;
`;

export const AnalyticsBarContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const AnalyticsBarSelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  height: 35px;
`;

//overwiew
export const AnalyticsOverwiewCardContainer = styled.div<{
  noPadding?: boolean;
}>`
  width: 400px;

  height: 268px;

  padding: ${(props) => (props.noPadding ? '0px' : '24px')};

  border-radius: 5px;
  position: relative;
  background: white;

  box-shadow: ${(props) =>
    props.noPadding ? '' : '0px 2px 10px 0px rgba(0, 0, 0, 0.1);'};
`;
export const AnalyticsOverwiewCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${H4} {
    color: ${COLOR_NEUTRAL_80};
  }
`;

export const AnalyticsOverviewProgressBarLeftContainer = styled.div`
  width: 400px;
  padding-bottom: 18px;
`;

export const AnalyticsProgressBarContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AnalyticsCallsAmountData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AnalyticsOverwiewCardContentContainer = styled.div`
  position: relative;
`;

export const AnalyticsChartNumbersContainer = styled.div`
  position: absolute;
  top: 130px;
  right: 50%;
  transform: translate(53%);
  width: 180px;

  display: flex;
  justify-content: space-between;

  ${H2} {
    color: ${COLOR_NEUTRAL_40};
  }

  ${H1} {
    margin-left: 10px;
    margin-top: 10px;
  }
`;

export const AnalyticsChartCornerTextContainer = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex;
`;

//progress
export const ProgressBarContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  background-color: #cbd2d9;
  border-radius: 3px;
  position: relative;
  height: 16px;

  cursor: pointer;
`;

export const Progress = styled.div<{ progress: number }>`
  background-color: #127fbf;
  height: 100%;
  border-radius: 5px 0 0 5px;

  position: relative;
  transition: width 0.4s ease;

  &::after {
    content: ${({ progress }) => (progress === 0 ? 'none' : "''")};
    position: absolute;
    right: 0;
    top: -3px;
    bottom: 0;
    width: 2px;
    height: 21px;
    background-color: black; /* Line at the end of the loaded portion */
  }
`;

export const ProgressBarHoverContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  min-width: 80px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  z-index: 20;
  pointer-events: none;

  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${COLOR_NEUTRAL_40};
  background: white;

  flex-direction: column;
  gap: 8px;
`;

export const ProgressBarNumbersContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0;

  display: flex;
  justify-content: space-between;

  ${H2} {
    color: ${COLOR_NEUTRAL_40};
  }

  ${H1} {
    margin-left: 20px;
    margin-top: 10px;
  }
`;

export const ProgressBarDataContainer = styled.div`
  min-width: 200px;
  max-width: 260px;
  height: auto;
  border-left: 1px solid #cbd2d9;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 113px;
`;

export const ProgressBarDataButton = styled.div`
  border: 1px dashed #cbd2d9;

  width: 100%;
  margin-top: 8px;
  border-radius: 5;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  border-radius: 5px;
  gap: 8px;

  .span {
    font-weight: 500;
  }
`;

export const FakeLoadingImageContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`;

export const FakeLoadingImage = styled.img<{ pieChart?: boolean }>`
  width: 100%;
  height: 100%;

  ${({ pieChart }) =>
    pieChart &&
    css`
      object-fit: cover;
      object-position: top;
    `}
`;

export const FakeLoadingTextContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

//outbound per day
export const OutboundCallsDialogueStatusesContainer = styled.div<{
  noPadding?: boolean;
}>`
  width: calc(100% - 200px);
  padding: ${({ noPadding }) => !noPadding && '16px'};
  min-height: 500px;

  border-radius: 12px;

  position: relative;
`;

export const OutboundCallsChartColumnSizeContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StatusList = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  width: 200px;
`;

export const StatusItem = styled.label`
  display: flex;
  max-width: 160px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
`;

export const StatusItemContent = styled.div`
  display: flex;
  gap: 8px;
`;

export const StatusTitle = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: 10px;
`;

export const StatusColorBox = styled.div`
  width: 21px;
  height: 13px;
  border-radius: 5px;
`;

export const AnalyticsSelectedCampaigns = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AnalyticsSelectedCampaignContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${COLOR_NEUTRAL_10};
  gap: 8px;
  padding: 2px 6px;
  border-radius: 6px;
`;

export const AnalyticsLoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AnalyticsClearFiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 24px;
  cursor: pointer;
  align-items: center;
`;

export const AnalyticsChartNoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  text-align: center;
  margin-top: 40px;
`;

export const AnalyticsCustomTooltip = styled.div`
  min-width: 80px;
  display: flex;
  z-index: 20;
  pointer-events: none;

  padding: 8px;
  border-radius: 5px;
  border: 1px solid ${COLOR_NEUTRAL_40};
  background: white;

  flex-direction: column;
  gap: 8px;
`;

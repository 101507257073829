import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const FiltersIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.05001 10C8.28164 8.85888 9.29052 8 10.5 8C11.7095 8 12.7184 8.85888 12.95 10H14.5C14.7761 10 15 10.2239 15 10.5C15 10.7761 14.7761 11 14.5 11H12.95C12.7184 12.1411 11.7095 13 10.5 13C9.29052 13 8.28164 12.1411 8.05001 11H1.5C1.22386 11 1 10.7761 1 10.5C1 10.2239 1.22386 10 1.5 10H8.05001ZM7.94999 6C7.71836 7.14112 6.70948 8 5.5 8C4.29052 8 3.28164 7.14112 3.05001 6H1.5C1.22386 6 1 5.77614 1 5.5C1 5.22386 1.22386 5 1.5 5H3.05001C3.28164 3.85888 4.29052 3 5.5 3C6.70948 3 7.71836 3.85888 7.94999 5H14.5C14.7761 5 15 5.22386 15 5.5C15 5.77614 14.7761 6 14.5 6H7.94999ZM4 5.5C4 6.32843 4.67157 7 5.5 7C6.32843 7 7 6.32843 7 5.5C7 4.67157 6.32843 4 5.5 4C4.67157 4 4 4.67157 4 5.5ZM12 10.5C12 9.67157 11.3284 9 10.5 9C9.67157 9 9 9.67157 9 10.5C9 11.3284 9.67157 12 10.5 12C11.3284 12 12 11.3284 12 10.5Z"
    />
  </StyledSvg>
);

import {
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  H4,
} from './../../../../../App.style';
import styled from 'styled-components';

export const SFTPPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;
  padding: 24px;

  position: relative;

  background: #ffffff;
`;

export const SftpContentContainer = styled.div`
  width: calc(100% - 150px);
  height: calc(100% - 140px);
  margin-top: 16px;

  padding-bottom: 0px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const AccordionHeader = styled.div`
  padding: 15px;
  border-radius: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${(props) => (props.isOpen ? '700px' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};

  border: 1px solid ${COLOR_NEUTRAL_20};
  border-top: none;
  border-radius: 5px;

  transition:
    max-height 0.8s ease-in-out,
    opacity 0.3s ease,
    visibility 0.3s ease,
    padding 0.5s ease;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  padding: ${(props) => (props.isOpen ? '20px 30px' : '0 30px')};
`;

export const PasswordButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const AccordionBoxContent = styled.div`
  width: 400px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  border: 1px solid ${COLOR_NEUTRAL_20};

  display: flex;
  flex-direction: column;
  gap: 5px;

  position: relative;
`;

export const RSAKeyTableItem = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};
  display: flex;
  justify-content: space-between;
  padding: 5px 3px;
`;

export const RSATableButton = styled.div`
  margin-top: 5px;

  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LektaSSHKeyContainer = styled.div`
  position: absolute;
  left: 550px;
  top: 65px;
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 400px;
`;

export const CopyIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

export const SftpTableContainer = styled.div`
  width: 100%;

  height: 100%;
  padding: 10px 0px;

  overflow: auto;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const SftpTab = styled.table`
  margin-top: 8px;
  width: 100%;
  height: 100%;
  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    min-height: 90%;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background: white; // adjust this to your needs
  }
`;

export const SftpTableHeader = styled.th`
  border-top: 1px solid ${COLOR_NEUTRAL_40};
  border-bottom: 1px solid ${COLOR_NEUTRAL_40};
  padding: 8px;

  text-align: left;
  vertical-align: middle;
`;

export const SftpTableData = styled.td`
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};
  padding: 12px 8px;

  vertical-align: middle;
`;

export const PasswordContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 25px;
`;

export const SftpActionsButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  ${H4} {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const SftpDeleteActionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SftpButtonContainer = styled.td`
  width: 100%;
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
`;

export const SftpPublicKeyContainer = styled.div`
  ${H4} {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const TestResultContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import React from 'react';
import Modal from '../../../../../../../common/modals/Modal';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../../../common/modals/Modals.style';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../../../../App.style';
import { useTranslation } from 'react-i18next';

interface DeleteAccessTokenModalProps {
  closeModal: () => void;
  deleteToken: () => void;
}

const DeleteAccessTokenModal: React.FC<DeleteAccessTokenModalProps> = ({
  closeModal,
  deleteToken,
}) => {
  const { t } = useTranslation();
  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteToken();

    return false;
  };

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>{t('settings.adminPanel.accessTokens.deleteText')}</H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );
  return (
    <Modal
      isVisible={true}
      headerTitle={t('settings.adminPanel.accessTokens.deleteTitle')}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default DeleteAccessTokenModal;

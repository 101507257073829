import React, { useState } from 'react';
import { CreateTenantContainer, TenantFormBarContainer } from './Tenants.style';
import { useAppDispatch } from '../../../../../../common/hooks';
import {
  Company,
  CompanyCreateRequest,
} from '../../../../../../generated/public/Api';
import EditPresetButtons from '../../../../presets/shared/EditPresetButtons';
import TenantForm from './TenantForm';
import {
  getTenantsListAsyncThunk,
  updateTenantAsyncThunk,
} from '../../api/superAdminSlice';
import { H1 } from '../../../../../App.style';
import DeleteTenantModal from './modals/DeleteTenantModal';
import { useTranslation } from 'react-i18next';
import { BackToListButton } from '../../../../presets/shared/BackToList';
import { ArrowLeftIcon } from '../../../../../../resources/icons-new/ArrowLeftIcon';
import LoadingModal from '../../../../../../common/modals/LoadingModal';

interface TenantDetailedProps {
  tenant: Company;
  backToList: () => void;
}

const TenantDetailed: React.FC<TenantDetailedProps> = ({
  tenant,
  backToList,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(true);

  const [editTenant, setEditTenant] = useState<CompanyCreateRequest>({
    name: tenant.name,
    slug: tenant.slug,
    available_lines: tenant.available_lines ?? 1,
    details: tenant.details ?? '',
    language: tenant.language,
    timezone: tenant.timezone,
    sms_gateway: tenant.sms_gateway ?? undefined,
    billing_configuration: {
      domestic_mobile_call_price:
        tenant.billing_configuration.domestic_mobile_call_price ?? 0,
      international_mobile_call_price:
        tenant.billing_configuration.international_mobile_call_price ?? 0,
      domestic_landline_call_price:
        tenant.billing_configuration.domestic_landline_call_price ?? 0,
      international_landline_call_price:
        tenant.billing_configuration.domestic_landline_call_price ?? 0,
      sms_price: tenant.billing_configuration.sms_price ?? 0,
      currency: tenant.billing_configuration.currency ?? 'PLN',
      template: tenant.billing_configuration.template ?? 'PER_CALL',
    },
  });

  const handleTenantEditClick = () => {
    const id = tenant.id;

    setIsLoadingVisible(true);
    dispatch(updateTenantAsyncThunk({ id, data: editTenant }))
      .then(() => {
        dispatch(getTenantsListAsyncThunk());
      })
      .finally(() => {
        setIsLoadingVisible(false);
      });
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditTenant({
      name: tenant.name,
      slug: tenant.slug,
      available_lines: tenant.available_lines ?? 1,
      details: tenant.details ?? '',
      language: tenant.language,
      timezone: tenant.timezone,
      sms_gateway: tenant.sms_gateway ?? undefined,
      billing_configuration: {
        domestic_mobile_call_price:
          tenant.billing_configuration.domestic_mobile_call_price ?? 0,
        international_mobile_call_price:
          tenant.billing_configuration.international_mobile_call_price ?? 0,
        domestic_landline_call_price:
          tenant.billing_configuration.domestic_landline_call_price ?? 0,
        international_landline_call_price:
          tenant.billing_configuration.international_landline_call_price ?? 0,
        sms_price: tenant.billing_configuration.sms_price ?? 0,
        currency: tenant.billing_configuration.currency ?? 'PLN',
        template: tenant.billing_configuration.template ?? 'PER_CALL',
      },
    });
  };

  const handleBackToDetailed = () => setEdit(false);

  const [isDeleteTenantModalVisible, setIsDeleteTenantModalVisible] =
    useState<boolean>(false);
  const openDeleteTenantModal = () => setIsDeleteTenantModalVisible(true);
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  let deleteModal;
  if (isDeleteTenantModalVisible) {
    deleteModal = (
      <DeleteTenantModal
        isVisible={isDeleteTenantModalVisible}
        closeModal={() => setIsDeleteTenantModalVisible(false)}
        tenant={tenant}
        backToList={backToList}
        setIsLoadingVisible={setIsLoadingVisible}
      />
    );
  }

  return (
    <CreateTenantContainer>
      {deleteModal}

      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />

      <TenantFormBarContainer>
        <BackToListButton
          style={{
            display: 'flex',
            top: 0,
            left: 0,
            position: 'relative',
            marginRight: 8,
          }}
          onClick={backToList}
        >
          <ArrowLeftIcon />
          {t('common.goBack')}
        </BackToListButton>

        <H1 $bold>{editTenant.name}</H1>

        <H1>{t('superAdminPanel.tenants.settings')}</H1>

        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleTenantEditClick}
          noPosition
        />
      </TenantFormBarContainer>

      <TenantForm
        tenant={editTenant}
        setTenant={setEditTenant}
        back={backToList}
        backToDetailed={handleBackToDetailed}
        openDeleteTenantModal={openDeleteTenantModal}
        detailedView={!edit}
      />
    </CreateTenantContainer>
  );
};

export default TenantDetailed;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const PlusIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M7.56025 0.000976562C8.08217 0.000976562 8.50528 0.424081 8.50528 0.946008V6.61619H14.1755C14.6974 6.61619 15.1205 7.0393 15.1205 7.56122C15.1205 8.08315 14.6974 8.50626 14.1755 8.50626H8.50528V14.1764C8.50528 14.6984 8.08217 15.1215 7.56025 15.1215C7.03832 15.1215 6.61522 14.6984 6.61522 14.1764V8.50626H0.945031C0.423105 8.50626 0 8.08315 0 7.56122C0 7.0393 0.423105 6.61619 0.945031 6.61619H6.61522V0.946008C6.61522 0.424081 7.03832 0.000976562 7.56025 0.000976562Z" />
  </StyledSvg>
);

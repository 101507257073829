import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Cell,
  TooltipProps,
} from 'recharts';
import {
  AnalyticsChartContainer,
  AnalyticsChartNoDataContainer,
  AnalyticsCustomTooltip,
  OutboundCallsDialogueStatusesContainer,
  StatusColorBox,
  StatusItem,
  StatusItemContent,
  StatusList,
  StatusTitle,
} from '../Analytics.style';
import { H2, H3 } from '../../../App.style';
import { FiltersIcon } from '../../../../resources/icons-new/FiltersIcon';
import { getColorByIndex } from './analyticsFunctions';
import Checkbox from '../../../../common/inputs/Checkbox';
import { Moment } from 'moment';
import {
  CampaignOverview,
  ProcessStatusChartResponse,
} from '../../../../generated/tenants/Api';
import Ramka4Img from '../../../../resources/analytics/Ramka4.png';
import FakeLoadingChart from './overview/FakeLoadingChart';
import { useAppDispatch } from '../../../../common/hooks';
import { getProcessesStatusChartsAsyncThunk } from '../api/analyticsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useTranslation } from 'react-i18next';

interface InteruptionStatusChartProps {
  processId: string;
  type?: 'campaign' | 'time';
  campaigns: CampaignOverview[];
  startDate?: Moment;
  endDate?: Moment;
}

const InteruptionStatusChart: React.FC<InteruptionStatusChartProps> = ({
  processId,
  type,
  campaigns,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [chartData, setChartData] = useState<ProcessStatusChartResponse>();
  const [dialogueStatusFilters, setDialogueStatusFilters] = useState<string[]>(
    [],
  );
  const [interuptionStatusFilters, setInteruptionStatusFilters] = useState<
    string[]
  >([]);
  const [totalDialogues, setTotalDialogues] = useState(0);
  const [totalInteruption, setTotalInteruption] = useState(0);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const [colorMapping, setColorMapping] = useState<{ [key: string]: string }>(
    {},
  );
  useEffect(() => {
    let payload;

    if (type === 'campaign') {
      payload = {
        processId: processId,
        data: {
          campaign_ids: campaigns.map((campaign) => campaign.id),
        },
      };
    } else {
      payload = {
        processId: processId,
        data: {
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        },
      };
    }

    if (processId && type)
      dispatch(getProcessesStatusChartsAsyncThunk(payload))
        .then(unwrapResult)
        .then((res) => {
          const updatedDialogueStatus = res.dialogue_status.map(
            (item, index) => ({
              ...item,
              type: 'dialogue',
              color: getColorByIndex(index, 'dialogue'),
            }),
          );
          const updatedInteruptionStatus = res.interruption_status.map(
            (item, index) => ({
              ...item,
              type: 'interuption',
              color: getColorByIndex(index, 'interuption'),
            }),
          );

          setChartData({
            ...res,
            dialogue_status: updatedDialogueStatus,
            interruption_status: updatedInteruptionStatus,
          });

          const dialogueFilters = res.dialogue_status.map((item) => item.name);
          const interuptionFilters = res.interruption_status.map(
            (item) => item.name,
          );
          setDialogueStatusFilters(dialogueFilters);
          setInteruptionStatusFilters(interuptionFilters);
          setSelectedStatuses([...dialogueFilters, ...interuptionFilters]);

          const newColorMapping: { [key: string]: string } = {};

          res.dialogue_status.forEach((item, index) => {
            newColorMapping[item.name] = getColorByIndex(index, 'dialogue');
          });

          res.interruption_status.forEach((item, index) => {
            newColorMapping[item.name] = getColorByIndex(index, 'interuption');
          });

          setColorMapping(newColorMapping);
        });
  }, [processId, type, campaigns, startDate, endDate]);

  const toggleStatus = (
    statusName: string,
    type: 'dialogue' | 'interuption',
  ) => {
    setSelectedStatuses((prev) => {
      const count = prev.filter((status) =>
        (type === 'dialogue'
          ? dialogueStatusFilters
          : interuptionStatusFilters
        ).includes(status),
      ).length;

      if (count === 1 && prev.includes(statusName)) {
        return prev;
      }

      return prev.includes(statusName)
        ? prev.filter((name) => name !== statusName)
        : [...prev, statusName];
    });
  };

  const showCharts = () => {
    return (
      (type === 'time' && startDate && endDate) ||
      (type === 'campaign' && campaigns.length > 0)
    );
  };

  const filteredChartData = {
    dialogue_status:
      chartData?.dialogue_status.filter((item) =>
        selectedStatuses.includes(item.name),
      ) || [],
    interuption_status:
      chartData?.interruption_status.filter((item) =>
        selectedStatuses.includes(item.name),
      ) || [],
  };

  useEffect(() => {
    console.log('Dialogi', totalDialogues);
    console.log('Interuptions', totalInteruption);
  }, [totalDialogues, totalInteruption]);

  const calculateTotal = (data: { value: number }[]) =>
    data.reduce((acc, current) => acc + current.value, 0);

  const renderCustomLabel = (
    entry: {
      name: string;
      value: number;
    } & {
      cx?: number;
      cy?: number;
      midAngle?: number;
      innerRadius?: number;
      outerRadius?: number;
      percent?: number;
      index?: number;
    },
  ): React.ReactNode => {
    return `${entry.name}`;
  };

  useEffect(() => {
    if (filteredChartData) {
      setTotalDialogues(calculateTotal(filteredChartData.dialogue_status));
      setTotalInteruption(calculateTotal(filteredChartData.interuption_status));
    }
  }, [filteredChartData]);

  return (
    <AnalyticsChartContainer style={{ flexDirection: 'row' }}>
      {!showCharts() ? (
        <FakeLoadingChart pieChart src={Ramka4Img} />
      ) : (
        <>
          {chartData?.dialogue_status.length === 0 &&
          chartData.process_status.length === 0 ? (
            <AnalyticsChartNoDataContainer>
              <H2 $grey>{t('analytics.charts.noDataAvailableLine1')}</H2>
              <H2 $grey>{t('analytics.charts.noDataAvailableLine2')}</H2>
            </AnalyticsChartNoDataContainer>
          ) : (
            <>
              <StatusList>
                <H2 $medium>Filters</H2>
                <StatusTitle>
                  <FiltersIcon />
                  <H3 $selected>
                    {t('analytics.charts.overview.dialogueStatuses')}
                  </H3>
                </StatusTitle>
                {dialogueStatusFilters.map((status) => (
                  <StatusItem
                    key={status}
                    onClick={() => toggleStatus(status, 'dialogue')}
                  >
                    <StatusItemContent>
                      <Checkbox
                        checked={selectedStatuses.includes(status)}
                        onChange={() => toggleStatus(status, 'dialogue')}
                      />
                      <H3>{status}</H3>
                    </StatusItemContent>

                    <StatusColorBox
                      style={{
                        background: colorMapping[status],
                      }}
                    />
                  </StatusItem>
                ))}

                <StatusTitle>
                  <FiltersIcon />
                  <H3 $selected>
                    {t('analytics.charts.overview.interuptionStatuses')}
                  </H3>
                </StatusTitle>
                {interuptionStatusFilters.map((status) => (
                  <StatusItem
                    key={status}
                    onClick={() => toggleStatus(status, 'interuption')}
                  >
                    <StatusItemContent>
                      <Checkbox
                        checked={selectedStatuses.includes(status)}
                        onChange={() => toggleStatus(status, 'interuption')}
                      />
                      <H3>{status}</H3>
                    </StatusItemContent>

                    <StatusColorBox
                      style={{
                        background: colorMapping[status],
                      }}
                    />
                  </StatusItem>
                ))}
              </StatusList>

              <OutboundCallsDialogueStatusesContainer noPadding={!type}>
                <ResponsiveContainer
                  width="100%"
                  height="100%"
                  debounce={300}
                  style={{ marginTop: 20 }}
                >
                  <PieChart>
                    <Pie
                      data={filteredChartData.dialogue_status}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      outerRadius={200}
                      fill="#8884d8"
                    >
                      {filteredChartData.dialogue_status.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colorMapping[entry.name]}
                        />
                      ))}
                    </Pie>
                    <Pie
                      data={filteredChartData.interuption_status || []}
                      dataKey="value"
                      cx="50%"
                      cy="50%"
                      innerRadius={220}
                      outerRadius={240}
                      fill="#82ca9d"
                      label={renderCustomLabel}
                    >
                      {filteredChartData.interuption_status.map(
                        (entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colorMapping[entry.name]}
                          />
                        ),
                      )}
                    </Pie>
                    <Tooltip
                      content={
                        <CustomTooltip
                          totalDialogues={totalDialogues}
                          totalInteruptions={totalInteruption}
                        />
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </OutboundCallsDialogueStatusesContainer>
            </>
          )}
        </>
      )}
    </AnalyticsChartContainer>
  );
};

export default InteruptionStatusChart;

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  totalDialogues: number; // Definiujemy, że total jest typu number
  totalInteruptions: number;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  totalDialogues,
  totalInteruptions,
  active,
  payload,
}) => {
  const { t } = useTranslation();
  if (active && payload && payload.length > 0) {
    const item = payload[0];
    const total =
      item.payload.type === 'dialogue' ? totalDialogues : totalInteruptions;
    const percentage = (((item.value as number) / total) * 100).toFixed(2);

    return (
      <AnalyticsCustomTooltip>
        <H3 style={{ color: '#127fbf' }}>{percentage}%</H3>
        <H3 $grey>
          {item.name}: {item.value}
        </H3>
        <H2>
          Total {item.payload.type === 'dialogue' ? 'dialog' : 'interuption'}:{' '}
          {total}
        </H2>
        <H2>
          {t('analytics.charts.overview.count')} {item.value}
        </H2>
      </AnalyticsCustomTooltip>
    );
  }

  return null;
};

import React, { useEffect, useState } from 'react';
import { CampaignOptimizationContainer } from './CampaignOptimization.style';
import {
  Campaign,
  CampaignChangeRequest,
} from '../../../../../generated/tenants/Api';
import CampaignOptimizationForm from './CampaignOptimizationForm';
import CampaignOptimizationFormReadOnly from './CampaignOptimizationFormReadOnly';
import EditPresetButtons from '../../../presets/shared/EditPresetButtons';
import { useAppDispatch } from '../../../../../common/hooks';
import { updateCampaignAsyncThunk } from '../../api/campaignsSlice';
import SuccessPopup from '../../../../../common/popups/SuccessPopup';
import { useTranslation } from 'react-i18next';

interface CampaignOptimizationProps {
  campaign: Campaign;
  hasCampaignAccess: boolean;
}

const CampaignOptimization: React.FC<CampaignOptimizationProps> = ({
  campaign,
  hasCampaignAccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [campaignUpdate, setCampaignUpdate] = useState<CampaignChangeRequest>({
    guaranteed_lines: campaign.guaranteed_lines,
    use_idle_lines: campaign.use_idle_lines,
    calling_configuration: {
      call_attempts: campaign.calling_configuration.call_attempts,
      call_attempts_per_day:
        campaign.calling_configuration.call_attempts_per_day,
      rest_days_after_call_attempts_per_day_reached:
        campaign.calling_configuration
          .rest_days_after_call_attempts_per_day_reached,
      time_between_attempts_mode:
        campaign.calling_configuration.time_between_attempts_mode,
      time_between_attempts_seconds: campaign.calling_configuration
        .time_between_attempts_seconds as number | undefined,
      dynamic_time_between_attempts:
        campaign.calling_configuration.dynamic_time_between_attempts,
      randomized_time_between_attempts:
        campaign.calling_configuration.randomized_time_between_attempts,
    },
  });

  const [edit, setEdit] = useState<boolean>(false);

  const handleCancelClick = () => {
    setEdit(false);
  };

  const handleUpdateClick = () => {
    const data = {
      campaignId: campaign.id,
      data: campaignUpdate,
    };

    dispatch(updateCampaignAsyncThunk(data));
  };

  const [errors, setErrors] = useState<boolean>(false);

  const handleBackClick = () => {
    setEdit(false);
  };

  const [isSuccessPopupVisible, setIsSuccessPopupVisible] =
    useState<boolean>(false);

  const showSuccessPopup = () => setIsSuccessPopupVisible(true);

  const closeSuccessPopup = () => setIsSuccessPopupVisible(false);

  useEffect(() => {
    if (edit) {
      closeSuccessPopup();
    }
  }, [edit]);

  function isEditDisabled() {
    return !(
      campaign.status === 'SCHEDULED' ||
      campaign.status === 'RUNNING' ||
      campaign.status === 'PAUSED'
    );
  }
  return (
    <CampaignOptimizationContainer>
      {isSuccessPopupVisible && (
        <SuccessPopup
          onClose={closeSuccessPopup}
          text={t('campaigns.optimizationPopup')}
        />
      )}

      {hasCampaignAccess && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleUpdateClick}
          hasErrors={errors}
          disabled={isEditDisabled()}
        />
      )}

      {!edit ? (
        <CampaignOptimizationFormReadOnly campaign={campaign} />
      ) : (
        <CampaignOptimizationForm
          campaign={campaign}
          campaignUpdate={campaignUpdate}
          setCampaignUpdate={setCampaignUpdate}
          setErrors={setErrors}
          handleBackClick={handleBackClick}
          showSuccessPopup={showSuccessPopup}
        />
      )}
    </CampaignOptimizationContainer>
  );
};

export default CampaignOptimization;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../common/hooks';
import {
  CallingConfiguration,
  CallingConfigurationRequest,
} from '../../../../generated/tenants/Api';
import { CreatePresetContainer } from '../PresetsPanel.style';
import { createCallingConfigurationAsyncThunk } from './api/callingConfigurationSlice';
import CallingPreferenceForm from './CallingPreferenceForm';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface CreateCallingPreferenceProps {
  callingPreference?: CallingConfiguration;
  strategy: string;
  backToList: () => void;
  handleNewCallingPreferenceChange?: () => void;
  formButtonDown?: boolean;
}
const CreateCallingPreference: React.FC<CreateCallingPreferenceProps> = ({
  strategy,
  backToList,
  callingPreference,
  handleNewCallingPreferenceChange,
  formButtonDown,
}) => {
  const dispatch = useAppDispatch();
  const [newCallingPreference, setNewCallingPreference] =
    useState<CallingConfigurationRequest>({
      strategy: strategy,
      name: '',
      call_attempts: 5,
      call_attempts_per_day: 2,
      rest_days_after_call_attempts_per_day_reached: 10,
      time_between_attempts_seconds: 1800,
      time_between_attempts_mode: 'STATIC',
    });

  useEffect(() => {
    if (callingPreference)
      setNewCallingPreference({
        ...callingPreference,
        name: `${callingPreference.name}-modified`,
      });
  }, [callingPreference]);

  const handleAddCallingPreference = () => {
    setIsLoadingVisible(true);
    dispatch(
      createCallingConfigurationAsyncThunk(newCallingPreference),
    ).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <CreatePresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <CallingPreferenceForm
        callingPreference={newCallingPreference}
        setCallingPreference={setNewCallingPreference}
        button={handleAddCallingPreference}
        createNew
        backToList={backToList}
        handleNewCallingPreferenceChange={handleNewCallingPreferenceChange}
        buttonDown={formButtonDown}
      />
    </CreatePresetContainer>
  );
};

export default CreateCallingPreference;

import React, { useState } from 'react';
import { Company } from '../../../../../../../generated/public/Api';
import { useAppDispatch } from '../../../../../../../common/hooks';
import {
  clearDeleteTenant,
  deleteTenantAsyncThunk,
  getTenantsListAsyncThunk,
} from '../../../api/superAdminSlice';
import { DeleteTenantContainer } from '../Tenants.style';
import { H3 } from '../../../../../../App.style';
import Modal from '../../../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { EuiFieldText } from '@elastic/eui';

interface DeleteTenantModalProps {
  isVisible: boolean;
  closeModal: () => void;
  tenant: Company;
  backToList: () => void;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteTenantModal: React.FC<DeleteTenantModalProps> = ({
  isVisible,
  closeModal,
  tenant,
  backToList,
  setIsLoadingVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const deleteTenant = () => {
    setIsLoadingVisible(true);
    dispatch(deleteTenantAsyncThunk(tenant.id))
      .then(() => {
        dispatch(clearDeleteTenant());
        dispatch(getTenantsListAsyncThunk());
        closeModal();
        backToList();
      })
      .finally(() => {
        setIsLoadingVisible(false);
      });
  };

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteTenant();

    return false;
  };

  const [tenantName, setTenantName] = useState<string>('');

  const body = (
    <DeleteTenantContainer>
      <H3>{t('superAdminPanel.tenants.deleteModal.line1')}</H3>

      <FormRow
        label={
          <H3 $selected>{t('superAdminPanel.tenants.deleteModal.line2')}</H3>
        }
      >
        <EuiFieldText
          value={tenantName}
          placeholder={tenant.name}
          onChange={(e) => setTenantName(e.target.value)}
        />
      </FormRow>
    </DeleteTenantContainer>
  );
  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('superAdminPanel.tenants.deleteModal.modalTitle')}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
      buttonDisabled={tenantName !== tenant.name}
      buttonText={t('superAdminPanel.tenants.deleteModal.buttonText')}
    />
  );
};

export default DeleteTenantModal;

import { EuiFieldText } from '@elastic/eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../common/hooks';
import { H2, H3, H4 } from '../../../App.style';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../generated/tenants/Api';
import {
  FormContainer,
  FormRow,
} from '../../processes/forms/ProcessForm.style';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../PresetsPanel.style';
import AssociatedProcesses from '../shared/AssociatedProcesses';
import EditPresetButtons from '../shared/EditPresetButtons';
import { updateCalendarAsyncThunk } from './api/calendarSlice';
import CalendarForm from './CalendarForm';
import WorkingHoursPreview from './workingHours/preview/WorkingHoursPreview';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface CalendarDetailedProps {
  calendar: CalendarConfiguration;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  hasPermission: boolean;
}

const CalendarDetailed: React.FC<CalendarDetailedProps> = ({
  calendar,
  backToList,
  findProcessNamesById,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(calendar.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(calendar.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editCalendar, setEditCalendar] =
    useState<CalendarConfigurationRequest>({
      strategy: '',
      name: '',
      working_hours: [],
      inbound_working_hours: [],
      cc_working_hours: [],
    });

  useEffect(() => {
    setEditCalendar(calendar);
  }, [calendar]);

  const handleCalendarEditClick = () => {
    const id = calendar.id;
    if (editCalendar && id) {
      const args = { id: id, data: editCalendar };
      setIsLoadingVisible(true);
      dispatch(updateCalendarAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditCalendar(calendar);
  };

  const associatedWithProcesses = findProcessNamesById(calendar.id);

  const handleBackToDetailed = () => {
    setEdit(false);
  };

  const [calendarHasErrors, setCalendarHasErrors] = useState<boolean>(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {hasPermission && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleCalendarEditClick}
          hasErrors={calendarHasErrors}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{calendar.name}</H2>
            <H4 $grey>
              {t('common.created')} {formattedCreated}, {t('common.modified')}{' '}
              {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <FormRow label={<H3>{t('presets.calendarForm.timezone')}</H3>}>
            <EuiFieldText defaultValue={calendar.timezone} readOnly />
          </FormRow>

          <FormRow
            style={{ marginTop: -5 }}
            label={<H3>{t('presets.calendarForm.workingHours')}</H3>}
          >
            <WorkingHoursPreview calendarConfiguration={editCalendar} />
          </FormRow>
        </FormContainer>
      ) : (
        <CalendarForm
          calendar={editCalendar}
          setCalendar={
            setEditCalendar as React.Dispatch<
              React.SetStateAction<CalendarConfigurationRequest | undefined>
            >
          }
          back={backToList}
          backToDetailed={handleBackToDetailed}
          calendarHasErrors={calendarHasErrors}
          setCalendarHasErrors={setCalendarHasErrors}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default CalendarDetailed;

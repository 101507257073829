import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const TwoChevronsRightIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <g id="Component 68">
      <path
        id="Path (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z"
        fill="white"
      />
      <path
        id="Path (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14645 0.646447C4.34171 0.451184 4.65829 0.451184 4.85355 0.646447L10.8536 6.64645C11.0488 6.84171 11.0488 7.15829 10.8536 7.35355L4.85355 13.3536C4.65829 13.5488 4.34171 13.5488 4.14645 13.3536C3.95118 13.1583 3.95118 12.8417 4.14645 12.6464L9.79289 7L4.14645 1.35355C3.95118 1.15829 3.95118 0.841709 4.14645 0.646447Z"
        fill="white"
      />
    </g>
  </StyledSvg>
);

import React, { useEffect, useState } from 'react';
import {
  TokenButton,
  TokenPrzypadekButton,
  TokenSelectorContainer,
  TokensAvailableContainer,
  TokensSelectedBottomContainer,
  TokensSelectedContainer,
  TokensSelectedContentContainer,
} from './TokenInput.style';
import {
  DialogueToken,
  TokensArray,
  breakTokensArray,
  dialogueTokensArr,
} from '../../configuration/tokenInputComponents/BotsTokens';
import { Button, ButtonEmpty, H4 } from '../../../../App.style';
import { ArrowLeftIcon } from '../../../../../resources/icons-new/ArrowLeftIcon';
import { ArrowRightIcon } from '../../../../../resources/icons-new/ArrowRightIcon';
import { WandIcon } from '../../../../../resources/icons-new/WandIcon';

interface TokenSelectorProps {
  addToken: (token: DialogueToken) => void;
  pauseTokens?: boolean;
  customTokens?: string[];
}
const TokenSelector: React.FC<TokenSelectorProps> = ({
  addToken,
  pauseTokens,
  customTokens,
}) => {
  const [selectedTokensArray, setSelectedTokensArray] = useState<TokensArray>();
  const [selectedToken, setSelectedToken] = useState<DialogueToken>();

  useEffect(() => {
    if (pauseTokens) {
      setSelectedTokensArray(breakTokensArray);
    }
  }, [pauseTokens]);

  useEffect(() => {
    if (customTokens) {
      // Przetwórz niestandardowe tokeny do formatu oczekiwanego przez komponent
      const processedTokens: DialogueToken[] = customTokens.map(
        (token, index) => ({
          displayText: token.replace('%_CUSTOM_', '').replace('/VERBATIM', ''),
          value: token,
          id: `custom-${index}`,
          type: token.includes('/VERBATIM') ? 'custom_verbatim' : 'custom', // Zakładając, że 'token' jest jednym z dopuszczalnych typów
        }),
      );

      console.log(processedTokens);

      // Utwórz obiekt TokensArray z przetworzonymi tokenami
      const customTokensArray: TokensArray = {
        text: 'Niestandardowe Tokeny',
        tokens: processedTokens,
      };

      setSelectedTokensArray(customTokensArray);
    }
  }, [customTokens]);

  const handleCancelClick = () => {
    setSelectedTokensArray(undefined);
    setSelectedToken(undefined);
  };

  const handleTokenClick = (token: TokensArray) => {
    if (token.tokens.length === 1) {
      addToken(token.tokens[0]);
    } else {
      setSelectedTokensArray(token);
    }
  };

  const getCaseNameInPolish = (value: string): string => {
    const caseMap: { [key: string]: string } = {
      NOM: 'mianownik',
      GEN: 'dopełniacz',
      DAT: 'celownik',
      ACC: 'biernik',
      INS: 'narzędnik',
      LOC: 'miejscownik',
      VOC: 'wołacz',
    };

    const caseSuffix = value.slice(-3).toUpperCase();
    return caseMap[caseSuffix] || 'mianownik';
  };

  const handleAddSelectedToken = () => {
    if (selectedToken) {
      addToken(selectedToken);
    }
  };
  return (
    <TokenSelectorContainer>
      {selectedTokensArray === undefined ? (
        <TokensAvailableContainer>
          {dialogueTokensArr.map((token, i) => {
            return (
              <TokenButton
                type="button"
                key={`token-${token.text}-${i}`}
                onClick={() => handleTokenClick(token)}
              >
                {token.text}
              </TokenButton>
            );
          })}
        </TokensAvailableContainer>
      ) : (
        <TokensSelectedContainer>
          {!pauseTokens && !customTokens && (
            <div>
              <Button
                type="button"
                onClick={handleCancelClick}
                $size={'S'}
                $styleType="NORMAL"
              >
                <ArrowLeftIcon />
              </Button>
            </div>
          )}

          <TokensSelectedContentContainer>
            {selectedTokensArray.tokens.map((token, i) => {
              return (
                <TokenPrzypadekButton
                  type="button"
                  key={`token-${token.displayText}-${i}`}
                  style={{
                    width: token.displayText.length > 14 ? '100%' : '50%',
                  }}
                  onClick={() => setSelectedToken(token)}
                >
                  <input
                    type="radio"
                    checked={selectedToken?.value === token.value}
                    readOnly
                  />
                  {customTokens && <WandIcon />}
                  <span style={{ fontWeight: 500 }}>{token.displayText}</span>
                  {token.type === 'custom_verbatim' && (
                    <H4
                      style={{
                        fontStyle: 'italic',
                        fontWeight: 300,
                        marginLeft: 4,
                      }}
                    >
                      / przeliterowany
                    </H4>
                  )}
                  {!pauseTokens &&
                    !customTokens &&
                    getCaseNameInPolish(token.value)}
                </TokenPrzypadekButton>
              );
            })}
          </TokensSelectedContentContainer>
          <TokensSelectedBottomContainer>
            <ButtonEmpty onClick={handleCancelClick}>Cancel</ButtonEmpty>
            <Button
              type="button"
              onClick={handleAddSelectedToken}
              $size={'S'}
              $styleType="NORMAL"
            >
              Add <ArrowRightIcon />
            </Button>
          </TokensSelectedBottomContainer>
        </TokensSelectedContainer>
      )}
    </TokenSelectorContainer>
  );
};

export default TokenSelector;

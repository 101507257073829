import React from 'react';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../../../common/modals/Modals.style';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../../../../App.style';
import Modal from '../../../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface DeletePublicKeyModalProps {
  isVisible: boolean;
  closeModal: () => void;
  deleteKey: () => void;
}

const DeletePublicKeyModal: React.FC<DeletePublicKeyModalProps> = ({
  isVisible,
  closeModal,
  deleteKey,
}) => {
  const { t } = useTranslation();
  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>{t('settings.adminPanel.sftp.deleteKeyQuestion')}</H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteKey();

    return false;
  };
  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('settings.adminPanel.sftp.deleteKey')}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default DeletePublicKeyModal;

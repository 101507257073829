import React from 'react';
import { COLOR_PRIMARY_2, H3, H4 } from '../../../../../App.style';
import {
  TranscriptContainer,
  TranscriptRow,
  SpeakerContainer,
  MessageContainer,
  MessageLeftContainer,
  MessageRightContainer,
} from '../Calls.styles';
import { useTranslation } from 'react-i18next';

interface CallTranscriptProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transcript: Record<string, any>;
}

export type TranscriptEntry = {
  text: string;
  speaker: 'BOT' | 'HUMAN';
  end_time_epoch: number;
  start_time_epoch: number;
};

const CallTranscript: React.FC<CallTranscriptProps> = ({ transcript }) => {
  const { t } = useTranslation();
  const transcriptData: TranscriptEntry[] = transcript.conversation || [];

  const callStart =
    transcriptData.length > 0 ? transcriptData[0].start_time_epoch : 0;

  const formatMessageTime = (seconds: number) => {
    return new Date(seconds * 1000).toISOString().slice(14, 19);
  };

  return (
    <TranscriptContainer>
      {transcriptData.length === 0 ? (
        <H3>{t('campaigns.calls.detailed.noTranscripts')}</H3>
      ) : (
        transcriptData.map((entry, index) => (
          <TranscriptRow key={`transcript-${index}`}>
            <SpeakerContainer>
              <H4
                style={{
                  color: entry.speaker === 'BOT' ? COLOR_PRIMARY_2 : '',
                }}
              >
                {entry.speaker === 'BOT'
                  ? t('campaigns.calls.detailed.lektaBot')
                  : t('campaigns.calls.detailed.client')}
              </H4>
            </SpeakerContainer>
            <MessageContainer speaker={entry.speaker}>
              <MessageLeftContainer>
                <H4>
                  <span style={{ color: '#3E4C59' }}>
                    {formatMessageTime(entry.start_time_epoch - callStart)}
                  </span>
                </H4>
              </MessageLeftContainer>
              <MessageRightContainer>
                <H4 $wrap>
                  <span style={{ marginLeft: 10 }}>{entry.text}</span>
                </H4>
              </MessageRightContainer>
            </MessageContainer>
          </TranscriptRow>
        ))
      )}
    </TranscriptContainer>
  );
};

export default CallTranscript;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAvatarDiv, UserAvatarImg } from '../Users.style';
import { EuiLoadingSpinner, EuiPopover } from '@elastic/eui';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { ThreeDotsIcon } from '../../../../resources/icons-new/ThreeDotsIcon';
import {
  H4,
  H6,
  COLOR_PRIMARY_1,
  H1,
  COLOR_NEUTRAL_20,
  H3,
  Center,
  Button,
} from '../../../App.style';

import {
  deleteUsersAsyncThunk,
  getUserDetailedAsyncThunk,
  selectUserDetailed,
  userActivateUpdateAsyncThunk,
} from '../api/userSlice';
import {
  getUserSettingsAsyncThunk,
  selectUserSettings,
  updateUserAvatarAsyncThunk,
} from '../../settings/userSettings/api/userSettingsSlice';
import {
  getStrategiesListAsyncThunk,
  selectStrategiesList,
} from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  UserDetailedContainer,
  UserDetailedBarContainer,
  ChangeAvatarLabel,
  UserDetailedContentContainer,
  UserDetailedBarContentContainer,
  UserNameContainer,
} from './UserDetailed.style';
import UserDetailedStrategiesTable from './UserDetailedStrategiesTable';
import DeleteUserModal from './modals/DeleteUserModal';
import { getUserInitials } from '../list/userFunctions';
import { ArrowLeftIcon } from '../../../../resources/icons-new/ArrowLeftIcon';
import { useAutoClosePopup } from '../../../../common/popups/useAutoClosePopup';
import ErrorPopup from '../../../../common/popups/ErrorPopup';
import SuccessPopup from '../../../../common/popups/SuccessPopup';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { UserPopoverButton } from '../../sidebar/Sidebar.style';
import DeactivateUserModal from './modals/DeactivateUserModal';

interface UserDetailedProps {
  userId?: string;
  isSuperAdmin?: boolean;
}

const UserDetailed: React.FC<UserDetailedProps> = ({
  userId,
  isSuperAdmin,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const isAdminView = !userId;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [reload, setReload] = useState<boolean>(false);
  const handleReload = () => setReload((prev) => !prev);
  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getStrategiesListAsyncThunk());
      dispatch(getUserDetailedAsyncThunk(id)).finally(() => setLoading(false));
    }
  }, [id, reload]);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      dispatch(getUserDetailedAsyncThunk(userId)).finally(() =>
        setLoading(false),
      );
    }
  }, [userId, reload]);

  const user = useAppSelector(selectUserDetailed);

  const apiStrategiesList = useAppSelector(selectStrategiesList);

  const [isUserPopoverVisible, setIsUserPopoverVisible] =
    useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      closeShowErrorChangeAvatarPopup();
      const file = files[0];
      dispatch(updateUserAvatarAsyncThunk({ avatar: file }))
        .then(unwrapResult)
        .then((res) => {
          if (res.status === 'success') {
            showSuccessChangeAvatarPopup();
            dispatch(getUserSettingsAsyncThunk());

            handleReload();
          } else if (res.error?.avatar) {
            setAvatarError(res.error?.avatar[0]);
            showErrorChangeAvatarPopup();
          }
        });
    }
  };

  const userSettings = useAppSelector(selectUserSettings);

  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] =
    useState<boolean>(false);
  const closeDeleteUserModal = () => setIsDeleteUserModalVisible(false);
  const showDeleteUserModal = () => setIsDeleteUserModalVisible(true);

  const handleDeleteUser = () => {
    if (user) {
      const query = {
        ids: [user.id],
      };

      dispatch(deleteUsersAsyncThunk(query)).then(() => {
        if (id) {
          navigate('/tenant_panel/users');
        } else if (userId) {
          window.location.reload();
        }
      });
    } else {
      alert(t('common.somethingWentWrong'));
    }
  };

  const [isDeactivateUserModalVisible, setIsDeactivateUserModalVisible] =
    useState<boolean>(false);
  const closeDeactivateUserModal = () => setIsDeactivateUserModalVisible(false);
  const showDeactivateUserModal = () => setIsDeactivateUserModalVisible(true);
  const handleDeactivateUser = () => {
    if (user) {
      const query = {
        id: user.id,
        data: { is_active: user.is_active ? false : true },
      };
      dispatch(userActivateUpdateAsyncThunk(query))
        .then(unwrapResult)
        .then((res) => {
          if (res.status === 'success') {
            dispatch(getUserDetailedAsyncThunk(user.id));
            closeDeactivateUserModal();
          } else {
            alert('nei udalo sie');
          }
        });
    }
  };

  const handleGoToUsersList = () => {
    navigate('/tenant_panel/users');
  };

  const {
    isVisible: successPermissionChangePopupVisible,
    showPopup: showSuccessPermissionsPopup,
    closePopup: closeShowSuccessPopup,
  } = useAutoClosePopup();

  const {
    isVisible: errorPermissionChangePopupVisible,
    showPopup: showErrorPermissionsPopup,
    closePopup: closeShowErrorPopup,
  } = useAutoClosePopup();

  const {
    isVisible: successAvatarChangePopupVisible,
    showPopup: showSuccessChangeAvatarPopup,
    closePopup: closeShowSuccessChangeAvatarPopup,
  } = useAutoClosePopup();

  const [avatarError, setAvatarError] = useState<string>();

  const {
    isVisible: errorAvatarChangePopupVisible,
    showPopup: showErrorChangeAvatarPopup,
    closePopup: closeShowErrorChangeAvatarPopup,
  } = useAutoClosePopup();

  return (
    <UserDetailedContainer>
      {successPermissionChangePopupVisible && (
        <SuccessPopup
          text={t('settings.adminPanel.users.popups.permissionsEdited')}
          onClose={closeShowSuccessPopup}
        />
      )}

      {errorPermissionChangePopupVisible && (
        <ErrorPopup
          text={t('settings.adminPanel.users.popups.error')}
          onClose={closeShowErrorPopup}
        />
      )}

      {successAvatarChangePopupVisible && (
        <SuccessPopup
          text={t('settings.adminPanel.users.popups.avatarChanged')}
          onClose={closeShowSuccessChangeAvatarPopup}
        />
      )}

      {errorAvatarChangePopupVisible && (
        <ErrorPopup
          text={
            avatarError
              ? avatarError
              : t('settings.adminPanel.users.popups.errorAvatarChange')
          }
          onClose={closeShowErrorChangeAvatarPopup}
        />
      )}

      {user && (
        <DeleteUserModal
          isVisible={isDeleteUserModalVisible}
          selectedUser={user}
          adminView={isAdminView}
          closeModal={closeDeleteUserModal}
          deleteUser={handleDeleteUser}
        />
      )}

      {user && (
        <DeactivateUserModal
          isVisible={isDeactivateUserModalVisible}
          selectedUser={user}
          adminView={isAdminView}
          closeModal={closeDeactivateUserModal}
          deactivateUser={handleDeactivateUser}
        />
      )}

      {loading ? (
        <Center>
          <EuiLoadingSpinner size={'xxl'} />
        </Center>
      ) : (
        <>
          {user && !isSuperAdmin ? (
            <>
              <UserDetailedBarContainer>
                {id && (
                  <div
                    style={{
                      width: 150,
                      height: 60,
                      display: 'flex',
                    }}
                  >
                    <Button
                      onClick={handleGoToUsersList}
                      $styleType="NORMAL"
                      $size={'S'}
                    >
                      <ArrowLeftIcon /> {t('common.goBack')}
                    </Button>
                  </div>
                )}
                <div style={{ position: 'relative' }}>
                  {user.avatar ? (
                    <UserAvatarImg detailed src={user.avatar} />
                  ) : (
                    <UserAvatarDiv detailed color={user.accent_color}>
                      <H4 $medium style={{ color: 'white', fontSize: 25 }}>
                        {getUserInitials(user.first_name, user.last_name)}
                      </H4>
                    </UserAvatarDiv>
                  )}
                  {!isAdminView && (
                    <>
                      <input
                        type="file"
                        id="fileInput"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      <ChangeAvatarLabel htmlFor="fileInput">
                        <H6
                          style={{
                            color: COLOR_PRIMARY_1,
                            position: 'absolute',
                            paddingTop: 10,
                          }}
                        >
                          {t('settings.adminPanel.users.changeAvatar')}
                        </H6>
                      </ChangeAvatarLabel>
                    </>
                  )}
                </div>

                <UserDetailedBarContentContainer>
                  <UserNameContainer style={{ marginBottom: 3 }}>
                    <H1 $bold>
                      {user.first_name} {user.last_name}
                    </H1>
                    <div
                      style={{
                        padding: 6,
                        color: user.is_active ? 'green' : 'red',
                      }}
                    >
                      {user.is_active ? 'aktywny' : 'nieaktywny'}
                    </div>
                    <EuiPopover
                      ownFocus={false}
                      button={
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsUserPopoverVisible((prev) => !prev);
                          }}
                          style={{
                            width: 24,
                            background: COLOR_NEUTRAL_20,
                            height: 14,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 5,
                            cursor: 'pointer',
                          }}
                        >
                          <ThreeDotsIcon />
                        </div>
                      }
                      isOpen={isUserPopoverVisible}
                      closePopover={() => setIsUserPopoverVisible(false)}
                      anchorPosition="rightUp"
                      panelPaddingSize="none"
                      hasArrow={false}
                    >
                      <div style={{ width: 130 }}>
                        <UserPopoverButton
                          onClick={(e) => {
                            e.stopPropagation();
                            showDeactivateUserModal();
                          }}
                        >
                          <H3>
                            {user.is_active
                              ? t('settings.adminPanel.users.deactivate')
                              : 'aktywuj'}
                          </H3>
                        </UserPopoverButton>

                        <UserPopoverButton
                          onClick={(e) => {
                            e.stopPropagation();
                            showDeleteUserModal();
                          }}
                        >
                          <H3>
                            {t('settings.adminPanel.users.deleteAccount')}
                          </H3>
                        </UserPopoverButton>
                      </div>
                    </EuiPopover>
                  </UserNameContainer>
                  <H4 $medium>
                    {t('settings.adminPanel.users.emailAddress')}
                    <span style={{ fontWeight: 400 }}>{user.email}</span>
                  </H4>
                  <H6 $grey>
                    {t('settings.adminPanel.users.lastActivity')}
                    {user.last_login
                      ? moment(user.last_login).format('DD.MM.YYYY')
                      : t('settings.adminPanel.users.table.row.notLogged')}
                  </H6>
                </UserDetailedBarContentContainer>
              </UserDetailedBarContainer>

              <UserDetailedContentContainer profile={userId ? true : false}>
                <UserDetailedStrategiesTable
                  user={user}
                  strategiesList={apiStrategiesList}
                  isAdminView={isAdminView}
                  handleReload={handleReload}
                  showSuccessPermissionsPopup={showSuccessPermissionsPopup}
                  showErrorPermissionsPopup={showErrorPermissionsPopup}
                />
              </UserDetailedContentContainer>
            </>
          ) : (
            <Center>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: -100,
                }}
              >
                Super admin
                <div style={{ position: 'relative', marginTop: 30 }}>
                  {userSettings.value?.avatar ? (
                    <UserAvatarImg detailed src={userSettings.value.avatar} />
                  ) : (
                    <UserAvatarDiv
                      detailed
                      color={userSettings.value?.accent_color}
                    >
                      <H4 $medium style={{ color: 'white', fontSize: 25 }}>
                        {userSettings.value?.initials}
                      </H4>
                    </UserAvatarDiv>
                  )}

                  <>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <ChangeAvatarLabel htmlFor="fileInput">
                      <H6
                        style={{
                          color: COLOR_PRIMARY_1,
                          position: 'absolute',
                          paddingTop: 10,
                        }}
                      >
                        {t('settings.adminPanel.users.changeAvatar')}
                      </H6>
                    </ChangeAvatarLabel>
                  </>
                </div>
              </div>
            </Center>
          )}
        </>
      )}
    </UserDetailedContainer>
  );
};

export default UserDetailed;

import styled from 'styled-components';
import { COLOR_PRIMARY_3 } from '../../App.style';

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  height: 100%;
  max-height: 100%;
  width: 25%;
  background: ${COLOR_PRIMARY_3};

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Waves = styled.img`
  width: 100%;
  max-height: 100%;
  height: 100%;
  transform: scale(2) translateX(24%);
`;

export const RightContainer = styled.div`
  height: 100%;
  width: 75%;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const LogoContainer = styled.div`
  width: 60%;
  min-width: 330px;
  margin-bottom: 80px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const LektaLogo = styled.img`
  width: 400px;
  height: 80px;
`;

export const LoginForm = styled.form`
  width: 330px;
  padding-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  position: relative;
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  top: -55px;
  left: 0%;
`;

export const ForgotPasswordContainer = styled.div`
  width: 10%;
  width: 100%;
  min-width: 220px;
  max-width: 400px;
  padding-left: 20px;
`;

export const LoginButtonContainer = styled.div`
  margin-top: 10px;
`;

//password reset

export const BadTokenContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 30px;
`;

import React from 'react';
import { TrashIcon } from '../../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../../App.style';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../common/modals/Modals.style';
import Modal from '../../../../../common/modals/Modal';
import { UserTenant } from '../../../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';

interface DeleteUserModalProps {
  isVisible: boolean;
  selectedUser: UserTenant;
  adminView: boolean;
  closeModal: () => void;
  deleteUser: () => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  isVisible,
  selectedUser,
  adminView,
  closeModal,
  deleteUser,
}) => {
  const { t } = useTranslation();
  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      {adminView ? (
        <DeleteColumnText>
          <H3>{t('settings.adminPanel.users.deleteModal.line1')}</H3>
          <H3 $bold>{selectedUser.email}?</H3>
        </DeleteColumnText>
      ) : (
        <DeleteColumnText>
          <H3>{t('settings.adminPanel.users.deleteModal.line2')}</H3>
        </DeleteColumnText>
      )}
    </DeleteColumnContainer>
  );

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteUser();

    return false;
  };
  return (
    <Modal
      isVisible={isVisible}
      headerTitle={`${t('settings.adminPanel.users.deleteModal.title')}:  ${
        selectedUser.first_name
      } ${selectedUser.last_name}`}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default DeleteUserModal;

import {
  DropResult,
  EuiAccordion,
  EuiButton,
  EuiCheckbox,
  EuiDragDropContext,
  euiDragDropMove,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSelect,
  EuiStepsHorizontal,
  EuiText,
  EuiTextColor,
  useEuiBackgroundColor,
} from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VindicationBackToPreset } from '../../BotsStartPanel';
import TokenInput from '../tokenInputComponents/TokenInput';
import { SUPPORTED_MODULES } from './VinIdentification';

interface GenerationsConfigVinVerification {
  'INF-STA-verification_intro_1_1': string;
  'FEE-SUM-verification_passed-1-1': string;
  'INF-STA-verification_failed-1-1': string;
  'REQ-INF-birth_date-1-1': string;
  'REQ-INF-last_pesel_digits-1-1': string;
  'REQ-INF-id-1-1': string;
  'REQ-INF-mothers_name-1-1': string;
  'REQ-INF-fathers_name-1-1': string;
  'REQ-INF-zip_code-1-1': string;
  'REQ-INF-product_possession-1-1': string;
  'REQ-INF-address-1-1': string;
}

interface BotStrategyConfigVinVerification {
  FIRST_TURN_QUESTIONS: Array<string>;
  FIRST_TURN_ATTEMPTS: number;
  FIRST_TURN_RANDOM: boolean;
  SECOND_TURN: boolean;
  SECOND_TURN_QUESTIONS: Array<string>;
  SECOND_TURN_ATTEMPTS: number;
  SECOND_TURN_RANDOM: boolean;
  BRANCHES: { VERIFICATION: string };
  ASK_FOR_MISSING_ZIP_CODE: boolean;
}

interface VerificationQuestionType {
  content: string;
  id: string;
}

const VinVerification = () => {
  const { t } = useTranslation();
  const [
    vinVerificationGenerationsConfig,
    setVinVerificationGenerationsConfig,
  ] = useState<GenerationsConfigVinVerification>();
  const [
    vinVerificationBotStrategyConfig,
    setVinVerificationBotStrategyConfig,
  ] = useState<BotStrategyConfigVinVerification>();

  //if there is a vinIdentificationGenerationsConfig in localstorage -> load it, if not -> load standard questions
  useEffect(() => {
    try {
      localStorage.setItem('lastVisitedStep', '2');
      const storageGenerationsConfig = localStorage.getItem(
        'vinVerificationGenerationsConfig',
      );
      if (storageGenerationsConfig !== null) {
        setVinVerificationGenerationsConfig(
          JSON.parse(storageGenerationsConfig),
        );
      } else {
        setVinVerificationGenerationsConfig({
          'INF-STA-verification_intro_1_1':
            'Informacje, które mam w do przekazania, objęte są tajemnicą bankową. Aby kontynuować rozmowę, muszę zweryfikować %_ADDRESS_GEN tożsamość.',
          'FEE-SUM-verification_passed-1-1': 'Dziękuje, wszystko się zgadza',
          'INF-STA-verification_failed-1-1':
            'Dziękuję. Niestety, weryfikacja nie przeszła pomyślnie i w tej sytuacji nie mogę kontynuować rozmowy',
          'REQ-INF-birth_date-1-1': 'Proszę o podanie Pana daty urodzenia.',
          'REQ-INF-last_pesel_digits-1-1':
            'Proszę o podanie trzech ostatnich cyfr numeru pesel',
          'REQ-INF-id-1-1':
            'Proszę o podanie numeru %_ADDRESS_GEN dowodu osobistego.',
          'REQ-INF-mothers_name-1-1':
            'Proszę o podanie imienia %_ADDRESS_GEN matki',
          'REQ-INF-fathers_name-1-1':
            'Proszę o podanie imienia %_ADDRESS_GEN ojca',
          'REQ-INF-zip_code-1-1':
            'Proszę o podanie kodu pocztowego %_ADDRESS_GEN adresu korespondencyjnego',
          'REQ-INF-product_possession-1-1':
            'Czy posiada %_ADDRESS_NOM %_PRODUCTNAME_ACC w %_COMPANYNAME_LOC?',
          'REQ-INF-address-1-1':
            'Proszę o podanie %_ADDRESS_GEN pełnego adresu zamieszkania',
        });
      }

      const storageBotStrategyConfig = localStorage.getItem(
        'vinVerificationBotStrategyConfig',
      );
      if (storageBotStrategyConfig !== null) {
        const botStrategyConfig: BotStrategyConfigVinVerification = JSON.parse(
          storageBotStrategyConfig,
        );
        const botFirstQuestions = botStrategyConfig.FIRST_TURN_QUESTIONS;
        const botSecondQuestions = botStrategyConfig.SECOND_TURN_QUESTIONS;

        //creates new aray with {id, content}, because there is an array of string in localStorage
        // let selectedFirstQuestions
        const selectedFirstQuestions = allOptionsDragDrop.filter((val) =>
          botFirstQuestions.includes(val.id),
        );
        const selectedSecondQuestions = allOptionsDragDrop.filter((val) =>
          botSecondQuestions.includes(val.id),
        );

        //filters only available from allOptions array
        let availableOptions = allOptionsDragDrop.filter(
          (val) => !botFirstQuestions.includes(val.id),
        );
        //updates the available options after second questions
        availableOptions = availableOptions.filter(
          (val) => !botSecondQuestions.includes(val.id),
        );

        setAvailableOptions(availableOptions);
        setSelectedFirstQuestions(selectedFirstQuestions);
        setSelectedSecondQuesions(selectedSecondQuestions);
        setVinVerificationBotStrategyConfig(botStrategyConfig);
      } else {
        setVinVerificationBotStrategyConfig({
          FIRST_TURN_ATTEMPTS: 1,
          FIRST_TURN_RANDOM: true,
          FIRST_TURN_QUESTIONS: ['birth_date', 'last_pesel_digits', 'id'],
          SECOND_TURN: false,
          SECOND_TURN_QUESTIONS: [],
          SECOND_TURN_ATTEMPTS: 1,
          SECOND_TURN_RANDOM: false,
          BRANCHES: { VERIFICATION: SUPPORTED_MODULES[3].value },
          ASK_FOR_MISSING_ZIP_CODE: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  //if user is changing something, everything is saved in localStorage
  useEffect(() => {
    localStorage.setItem(
      'vinVerificationGenerationsConfig',
      JSON.stringify(vinVerificationGenerationsConfig),
    );
  }, [vinVerificationGenerationsConfig]);
  useEffect(() => {
    localStorage.setItem(
      'vinVerificationBotStrategyConfig',
      JSON.stringify(vinVerificationBotStrategyConfig),
    );
  }, [vinVerificationBotStrategyConfig]);

  const [availableOptions, setAvailableOptions] = useState<
    Array<VerificationQuestionType>
  >([
    {
      content: `Imię matki`,
      id: 'mothers_name',
    },
    {
      content: `Imię ojca`,
      id: 'fathers_name',
    },
    {
      content: 'Kod pocztowy adresu korespondencyjnego',
      id: `zip_code`,
    },
    {
      content: `Posiadanie kredytu`,
      id: 'product_possesion',
    },
    {
      content: `Adres zamieszkania`,
      id: 'address',
    },
  ]);
  const [selectedFirstQuestions, setSelectedFirstQuestions] = useState<
    Array<VerificationQuestionType>
  >([
    {
      content: `Data urodzenia`,
      id: 'birth_date',
    },
    {
      content: `Trzy ostatnie cyfry PESEL`,
      id: 'last_pesel_digits',
    },
    {
      content: `Numer dowodu osobistego`,
      id: 'id',
    },
  ]);
  const [selectedSecondQuestions, setSelectedSecondQuesions] = useState<
    Array<VerificationQuestionType>
  >([]);

  const allOptionsDragDrop: Array<VerificationQuestionType> = [
    {
      content: `Imię matki`,
      id: 'mothers_name',
    },
    {
      content: `Imię ojca`,
      id: 'fathers_name',
    },
    {
      content: 'Kod pocztowy adresu korespondencyjnego',
      id: `zip_code`,
    },
    {
      content: `Posiadanie kredytu`,
      id: 'product_possesion',
    },
    {
      content: `Adres zamieszkania`,
      id: 'address',
    },
    {
      content: `Data urodzenia`,
      id: 'birth_date',
    },
    {
      content: `Trzy ostatnie cyfry PESEL`,
      id: 'last_pesel_digits',
    },
    {
      content: `Numer dowodu osobistego`,
      id: 'id',
    },
  ];

  //dropList logic
  const onDragFirstRound = ({ source, destination }: DropResult) => {
    // i have no idea what inferface should be here
    const lists = {
      DROPPABLE_AREA_1: availableOptions,
      DROPPABLE_AREA_2: selectedFirstQuestions,
    };

    const actions = {
      DROPPABLE_AREA_1: setAvailableOptions,
      DROPPABLE_AREA_2: setSelectedFirstQuestions,
    };

    type droppableArea = keyof typeof actions;

    if (source && destination) {
      if (source.droppableId === destination.droppableId) {
        const destinationId = destination.droppableId as droppableArea;
        const items = euiDragDropReorder(
          lists[destinationId],
          source.index,
          destination.index,
        );

        actions[destinationId](items);
      } else {
        const sourceId = source.droppableId as droppableArea;
        const destinationId = destination.droppableId as droppableArea;
        const result = euiDragDropMove(
          lists[sourceId],
          lists[destinationId],
          source,
          destination,
        );

        actions[sourceId](result[sourceId]);
        actions[destinationId](result[destinationId]);
      }
    }
  };

  const onDragSecondRound = ({ source, destination }: DropResult) => {
    const lists = {
      DROPPABLE_AREA_1: availableOptions,
      DROPPABLE_AREA_2: selectedSecondQuestions,
    };

    const actions = {
      DROPPABLE_AREA_1: setAvailableOptions,
      DROPPABLE_AREA_2: setSelectedSecondQuesions,
    };

    type droppableArea = keyof typeof actions;

    if (source && destination) {
      if (source.droppableId === destination.droppableId) {
        const destinationId = destination.droppableId as droppableArea;
        const items = euiDragDropReorder(
          lists[destinationId],
          source.index,
          destination.index,
        );

        actions[destinationId](items);
      } else {
        const sourceId = source.droppableId as droppableArea;
        const destinationId = destination.droppableId as droppableArea;
        const result = euiDragDropMove(
          lists[sourceId],
          lists[destinationId],
          source,
          destination,
        );

        actions[sourceId](result[sourceId]);
        actions[destinationId](result[destinationId]);
      }
    }
  };

  const discardBotConfiguration = () => {
    navigate('/dashboard/bots');
  };

  useEffect(() => {
    if (
      vinVerificationBotStrategyConfig &&
      selectedFirstQuestions &&
      selectedSecondQuestions
    ) {
      setVinVerificationBotStrategyConfig({
        ...vinVerificationBotStrategyConfig,
        FIRST_TURN_QUESTIONS: selectedFirstQuestions.map((a) => a.id),
        SECOND_TURN_QUESTIONS: selectedSecondQuestions.map((a) => a.id),
      });
    }
  }, [selectedFirstQuestions, selectedSecondQuestions]);

  const handleSubmitVerificationClick = () => {
    if (vinVerificationBotStrategyConfig) {
      if (vinVerificationBotStrategyConfig.FIRST_TURN_QUESTIONS.length > 0) {
        let arr: string[];
        const completedSteps = localStorage.getItem('vinCompletedSteps');
        if (completedSteps !== null && !completedSteps?.includes('2')) {
          arr = JSON.parse(completedSteps);
          arr.push('2');
          arr.sort();
          localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
        } else if (completedSteps && completedSteps.length > 0) {
          console.log('this step was already added');
        } else {
          arr = ['2'];
          localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
        }
        navigate('/dashboard/bots/new/1/3');
      } else {
        alert('Verification List cant be empty');
      }
    }
  };

  const navigate = useNavigate();

  //steps logic
  let steps: string[] = [];
  if (localStorage.getItem('vinCompletedSteps') !== null) {
    steps = JSON.parse(localStorage.getItem('vinCompletedSteps') as string);
  }
  const horizontalSteps = [
    {
      title: 'Identification',
      status: steps.includes('1') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/1');
      },
    },
    {
      title: 'Verification',
      status: 'current',
      onClick: () => {
        navigate('/dashboard/bots/new/1/2');
      },
    },
    {
      title: 'Payment status',
      status: steps.includes('3') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/3');
      },
    },
    {
      title: 'Negotiations',
      status: steps.includes('4') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/4');
      },
    },
    {
      title: 'Auxiliary modules',
      status: steps.includes('5') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/5');
      },
    },
    {
      title: 'Summary',
      status: steps.includes('6') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/6');
      },
    },
  ];

  const [showAccordion, setShowAccordion] = useState<{
    startVerification: boolean;
    firstRoundQuestions: boolean;
    secondRoundQuestions: boolean;
    positiveVerification: boolean;
    unsuccessfulVerification: boolean;
  }>({
    startVerification: false,
    firstRoundQuestions: false,
    secondRoundQuestions: false,
    positiveVerification: false,
    unsuccessfulVerification: false,
  });

  //modal logic
  const [modalById, setModalById] = useState<string>('');

  const showModal = (id: string) => {
    if (id === 'mothers_name') {
      setModalById(id);
    } else if (id === 'birth_date') {
      setModalById(id);
    } else if (id === 'id') {
      setModalById(id);
    } else if (id === 'fathers_name') {
      setModalById(id);
    } else if (id === 'zip_code') {
      setModalById(id);
    } else if (id === 'product_possesion') {
      setModalById(id);
    } else if (id === 'address') {
      setModalById(id);
    } else if (id === 'last_pesel_digits') {
      setModalById(id);
    }
  };

  let modal;

  if (modalById === 'mothers_name' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{
          width: 800,
          background: useEuiBackgroundColor('subdued'),
        }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Mothers Name</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText>How the bot should ask for the mothers's name</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion1'}
                text={
                  vinVerificationGenerationsConfig?.['REQ-INF-mothers_name-1-1']
                }
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-mothers_name-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  if (modalById === 'fathers_name' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Fathers name</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText>How the bot should ask for the mothers's name</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion2'}
                text={
                  vinVerificationGenerationsConfig?.['REQ-INF-fathers_name-1-1']
                }
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-fathers_name-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  if (modalById === 'id' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>ID</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText>How the bot should ask for the ID card number</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion3'}
                text={vinVerificationGenerationsConfig?.['REQ-INF-id-1-1']}
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-id-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  if (modalById === 'birth_date' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Birth date</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText>How the bot should ask for the date of birth</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion4'}
                text={
                  vinVerificationGenerationsConfig?.['REQ-INF-birth_date-1-1']
                }
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-birth_date-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  if (modalById === 'last_pesel_digits' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Last pesel digits</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText>How the bot should ask for the date of birth</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion5'}
                text={
                  vinVerificationGenerationsConfig?.[
                    'REQ-INF-last_pesel_digits-1-1'
                  ]
                }
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-last_pesel_digits-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  if (modalById === 'zip_code' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Zip code</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText>How the bot should ask for the date of birth</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion6'}
                text={
                  vinVerificationGenerationsConfig?.['REQ-INF-zip_code-1-1']
                }
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-zip_code-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  if (modalById === 'product_possesion' && vinVerificationGenerationsConfig) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Product possession</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText> How a bot should ask for a product possession</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion7'}
                text={
                  vinVerificationGenerationsConfig?.[
                    'REQ-INF-product_possession-1-1'
                  ]
                }
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-product_possession-1-1': text,
                  });
                }}
              />
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }
  if (
    modalById === 'address' &&
    vinVerificationGenerationsConfig &&
    vinVerificationBotStrategyConfig
  ) {
    modal = (
      <EuiModal
        style={{ width: 800, background: useEuiBackgroundColor('subdued') }}
        onClose={() => setModalById('')}
      >
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Address</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody
            css={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 10,
            }}
          >
            <EuiText> How a bot should ask for an address</EuiText>

            <EuiFlexItem
              css={{
                minWidth: 800,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TokenInput
                tokenInputId={'vinVerificationQuestion8'}
                text={vinVerificationGenerationsConfig?.['REQ-INF-address-1-1']}
                inputHook={(text) => {
                  setVinVerificationGenerationsConfig({
                    ...vinVerificationGenerationsConfig,
                    'REQ-INF-address-1-1': text,
                  });
                }}
              />

              <EuiText>
                Should the bot ask for the missing zip code when asking for an
                address?
              </EuiText>
              <div>
                <EuiCheckbox
                  id="missing_zip_code"
                  checked={
                    vinVerificationBotStrategyConfig?.ASK_FOR_MISSING_ZIP_CODE
                  }
                  onChange={(e) =>
                    setVinVerificationBotStrategyConfig({
                      ...vinVerificationBotStrategyConfig,
                      ASK_FOR_MISSING_ZIP_CODE: e.target.checked,
                    })
                  }
                />
              </div>
            </EuiFlexItem>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={() => setModalById('')} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiFlexGroup>
      </EuiModal>
    );
  }

  //back to presets button logic
  const [backToPresetsButtonVisible, setBackToPresetsButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    const botConfigurationType = localStorage.getItem('botConfigurationType');
    if (botConfigurationType !== null && botConfigurationType === 'new') {
      setBackToPresetsButtonVisible(true);
    } else {
      setBackToPresetsButtonVisible(false);
    }
  }, []);

  interface NumberErrors {
    first_round: boolean;
    second_round: boolean;
  }

  const [numberFieldKey, setNumberFieldKey] = useState<0 | 1>(0);

  const [onBlurNumberErrors, setOnBlurNumberErrors] = useState<NumberErrors>({
    first_round: false,
    second_round: false,
  });

  const handleCheckNumberFieldOnBlur = (
    value: string,
    field: keyof NumberErrors,
  ) => {
    //checks if number is higher than 0
    const arr = { ...onBlurNumberErrors };
    Number(value) < 0 ? (arr[field] = true) : (arr[field] = false);
    setOnBlurNumberErrors(arr);

    if (value.startsWith('0')) setNumberFieldKey(numberFieldKey === 0 ? 1 : 0);
  };

  return (
    <>
      {vinVerificationBotStrategyConfig && vinVerificationGenerationsConfig && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            flexDirection: 'column',
            margin: 10,
            padding: 10,

            overflow: 'auto',
            marginTop: 80,
          }}
        >
          <EuiFlexItem
            css={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
          >
            {backToPresetsButtonVisible && <VindicationBackToPreset />}
            <div>
              <EuiButton fill onClick={discardBotConfiguration}>
                Discard
              </EuiButton>
            </div>
          </EuiFlexItem>
          <EuiStepsHorizontal
            steps={horizontalSteps as Omit<EuiStepHorizontalProps, 'step'>[]}
          />
          <EuiFlexItem>
            <EuiText css={{ fontSize: 22 }}>
              Moduł weryfikacji ma na celu ustalenie tożsamości rozmówcy.
            </EuiText>
            <EuiText>
              Etap ten składa się z jednej lub dwóch tur, w każdej z nich bot
              zadaje dowolną ilość pytań, by ustalić, czy osoba, z którą
              rozmawia, jest dłużnikiem. Istnieje możliwość wyboru spośród
              podanych poniżej pytań i określenie kolejności ich zadawania.
              Udana weryfikacja automatycznie aktywuje moduł Status płatności,
              natomiast w przypadku gdy przechodzi niepomyślnie, można
              przekierować dialog do modułów pomocniczych.
            </EuiText>
          </EuiFlexItem>
          <div style={{ marginTop: 30 }}>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Start verification. The person on the phone confirms that he is the debtor"
                forceState={showAccordion.startVerification ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    startVerification: !showAccordion.startVerification,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot say that it will start the process
                        of verifying the caller's identity?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinVerification1'}
                        text={
                          vinVerificationGenerationsConfig[
                            'INF-STA-verification_intro_1_1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinVerificationGenerationsConfig({
                            ...vinVerificationGenerationsConfig,
                            'INF-STA-verification_intro_1_1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              startVerification: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="First round of verification"
                forceState={
                  showAccordion.firstRoundQuestions ? 'open' : 'closed'
                }
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    firstRoundQuestions: !showAccordion.firstRoundQuestions,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{ padding: 20, flexDirection: 'column', gap: 20 }}
                  >
                    <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                      Select verification questions for your bot
                    </EuiText>
                    <EuiDragDropContext onDragEnd={onDragFirstRound}>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiText>Available Options</EuiText>
                          <EuiDroppable
                            droppableId="DROPPABLE_AREA_1"
                            spacing="m"
                            withPanel
                            grow={false}
                          >
                            {availableOptions.length > 0 ? (
                              availableOptions.map(({ content, id }, idx) => (
                                <EuiDraggable
                                  spacing="m"
                                  key={id}
                                  index={idx}
                                  draggableId={id}
                                >
                                  {(provided, state) => (
                                    <EuiPanel>
                                      {content}
                                      {state.isDragging && ' ✨'}
                                    </EuiPanel>
                                  )}
                                </EuiDraggable>
                              ))
                            ) : (
                              <EuiFlexGroup
                                alignItems="center"
                                justifyContent="spaceAround"
                                gutterSize="none"
                                style={{ height: '100%' }}
                              >
                                <EuiFlexItem grow={false}>
                                  <EuiIcon type="faceSad" />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            )}
                          </EuiDroppable>
                        </EuiFlexItem>
                        {modal}
                        <EuiFlexItem>
                          <EuiText>Used Options</EuiText>
                          <EuiDroppable
                            droppableId="DROPPABLE_AREA_2"
                            spacing="m"
                            withPanel
                            grow={false}
                          >
                            {selectedFirstQuestions.length > 0 ? (
                              selectedFirstQuestions.map(
                                ({ content, id }, idx) => (
                                  <EuiDraggable
                                    spacing="m"
                                    key={id}
                                    index={idx}
                                    draggableId={id}
                                  >
                                    {(provided, state) => (
                                      <EuiPanel
                                        onDoubleClick={() => showModal(id)}
                                      >
                                        {content}
                                        {state.isDragging && ' ✨'}
                                      </EuiPanel>
                                    )}
                                  </EuiDraggable>
                                ),
                              )
                            ) : (
                              <EuiFlexGroup
                                alignItems="center"
                                justifyContent="spaceAround"
                                gutterSize="none"
                                style={{ height: '100%' }}
                              >
                                <EuiFlexItem grow={false}>
                                  <EuiIcon type="faceSad" />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            )}
                          </EuiDroppable>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiDragDropContext>
                    <EuiFlexItem css={{ gap: 20, alignItems: 'center' }}>
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        The permissible number of incorrect user answers
                      </EuiText>
                      <div>
                        <EuiFieldNumber
                          key={`number-of-incorrect-users-first-turn-${numberFieldKey}`}
                          css={{ textAlign: 'center' }}
                          value={
                            vinVerificationBotStrategyConfig.FIRST_TURN_ATTEMPTS
                          }
                          onBlur={(e) =>
                            handleCheckNumberFieldOnBlur(
                              e.target.value,
                              'first_round',
                            )
                          }
                          isInvalid={onBlurNumberErrors.first_round}
                          onChange={(e) =>
                            setVinVerificationBotStrategyConfig({
                              ...vinVerificationBotStrategyConfig,
                              FIRST_TURN_ATTEMPTS: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                      {onBlurNumberErrors.first_round && (
                        <EuiTextColor color={'danger'}>
                          {t('ensureValueGreaterThanZero')}
                        </EuiTextColor>
                      )}
                    </EuiFlexItem>
                    <EuiFlexItem css={{ gap: 20, alignItems: 'center' }}>
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        Whether questions are to be asked randomly
                      </EuiText>
                      <div>
                        <EuiCheckbox
                          id="random_questions"
                          checked={
                            vinVerificationBotStrategyConfig.FIRST_TURN_RANDOM
                          }
                          onChange={(e) =>
                            setVinVerificationBotStrategyConfig({
                              ...vinVerificationBotStrategyConfig,
                              FIRST_TURN_RANDOM: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              firstRoundQuestions: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem
              css={{
                flexDirection: 'row',
                gap: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EuiText>
                Second tour of questions is optional, if you want to turn it ON,
                click the checkbox
              </EuiText>
              <EuiCheckbox
                id="second_turn_questions"
                checked={vinVerificationBotStrategyConfig.SECOND_TURN}
                onChange={() =>
                  setVinVerificationBotStrategyConfig({
                    ...vinVerificationBotStrategyConfig,
                    SECOND_TURN: !vinVerificationBotStrategyConfig.SECOND_TURN,
                  })
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Second round of verification"
                isDisabled={!vinVerificationBotStrategyConfig.SECOND_TURN}
                forceState={
                  showAccordion.secondRoundQuestions ? 'open' : 'closed'
                }
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    secondRoundQuestions: !showAccordion.secondRoundQuestions,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{ padding: 20, flexDirection: 'column', gap: 20 }}
                  >
                    <EuiText css={{ fontSize: 30 }}>
                      Select verification questions for your bot
                    </EuiText>
                    <EuiDragDropContext onDragEnd={onDragSecondRound}>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiText>Available Options</EuiText>
                          <EuiDroppable
                            droppableId="DROPPABLE_AREA_1"
                            spacing="m"
                            withPanel
                            grow={false}
                          >
                            {availableOptions.length > 0 ? (
                              availableOptions.map(({ content, id }, idx) => (
                                <EuiDraggable
                                  spacing="m"
                                  key={id}
                                  index={idx}
                                  draggableId={id}
                                >
                                  {(provided, state) => (
                                    <EuiPanel>
                                      {content}
                                      {state.isDragging && ' ✨'}
                                    </EuiPanel>
                                  )}
                                </EuiDraggable>
                              ))
                            ) : (
                              <EuiFlexGroup
                                alignItems="center"
                                justifyContent="spaceAround"
                                gutterSize="none"
                                style={{ height: '100%' }}
                              >
                                <EuiFlexItem grow={false}>
                                  <EuiIcon type="faceSad" />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            )}
                          </EuiDroppable>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiText>Used Options</EuiText>
                          <EuiDroppable
                            droppableId="DROPPABLE_AREA_2"
                            spacing="m"
                            withPanel
                            grow={false}
                          >
                            {selectedSecondQuestions.length > 0 ? (
                              selectedSecondQuestions.map(
                                ({ content, id }, idx) => (
                                  <EuiDraggable
                                    spacing="m"
                                    key={id}
                                    index={idx}
                                    draggableId={id}
                                  >
                                    {(provided, state) => (
                                      <EuiPanel
                                        onDoubleClick={() => showModal(id)}
                                      >
                                        {content}
                                        {state.isDragging && ' ✨'}
                                      </EuiPanel>
                                    )}
                                  </EuiDraggable>
                                ),
                              )
                            ) : (
                              <EuiFlexGroup
                                alignItems="center"
                                justifyContent="spaceAround"
                                gutterSize="none"
                                style={{ height: '100%' }}
                              >
                                <EuiFlexItem grow={false}>
                                  <EuiIcon type="faceSad" />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            )}
                          </EuiDroppable>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiDragDropContext>
                    <EuiFlexItem css={{ gap: 20, alignItems: 'center' }}>
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        The permissible number of incorrect user answers for
                        second turn of verification questions
                      </EuiText>
                      <div>
                        <EuiFieldNumber
                          key={`number-of-incorrect-users-second-turn-${numberFieldKey}`}
                          css={{ textAlign: 'center' }}
                          value={
                            vinVerificationBotStrategyConfig.SECOND_TURN_ATTEMPTS
                          }
                          onBlur={(e) =>
                            handleCheckNumberFieldOnBlur(
                              e.target.value,
                              'second_round',
                            )
                          }
                          isInvalid={onBlurNumberErrors.second_round}
                          onChange={(e) =>
                            setVinVerificationBotStrategyConfig({
                              ...vinVerificationBotStrategyConfig,
                              SECOND_TURN_ATTEMPTS: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                      {onBlurNumberErrors.second_round && (
                        <EuiTextColor color={'danger'}>
                          {t('ensureValueGreaterThanZero')}
                        </EuiTextColor>
                      )}
                    </EuiFlexItem>
                    <EuiFlexItem css={{ gap: 20, alignItems: 'center' }}>
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        Whether questions are to be asked randomly
                      </EuiText>
                      <div>
                        <EuiCheckbox
                          id="random_questions"
                          checked={
                            vinVerificationBotStrategyConfig.SECOND_TURN_RANDOM
                          }
                          onChange={(e) =>
                            setVinVerificationBotStrategyConfig({
                              ...vinVerificationBotStrategyConfig,
                              SECOND_TURN_RANDOM: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              secondRoundQuestions: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Positive verification"
                forceState={
                  showAccordion.positiveVerification ? 'open' : 'closed'
                }
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    positiveVerification: !showAccordion.positiveVerification,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should be the bot's statement informing the debtor
                        that he has successfully passed the identity
                        verification process?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinVerification2'}
                        text={
                          vinVerificationGenerationsConfig[
                            'FEE-SUM-verification_passed-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinVerificationGenerationsConfig({
                            ...vinVerificationGenerationsConfig,
                            'FEE-SUM-verification_passed-1-1': text,
                          });
                        }}
                      />
                      <EuiText css={{ fontSize: 20 }}>
                        •Activation of the{' '}
                        <EuiLink
                          onClick={() => navigate('/dashboard/bots/new/1/3')}
                        >
                          payment status module
                        </EuiLink>
                      </EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              positiveVerification: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Behavior after unsuccessful verification"
                forceState={
                  showAccordion.unsuccessfulVerification ? 'open' : 'closed'
                }
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    unsuccessfulVerification:
                      !showAccordion.unsuccessfulVerification,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should be the bot's statement informing the caller
                        that he failed the identity verification process?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinVerification3'}
                        text={
                          vinVerificationGenerationsConfig[
                            'INF-STA-verification_failed-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinVerificationGenerationsConfig({
                            ...vinVerificationGenerationsConfig,
                            'INF-STA-verification_failed-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem css={{ alignItems: 'center', gap: 20 }}>
                      <EuiText>
                        • Please select which module should be activated after
                        this situation
                      </EuiText>

                      <EuiSelect
                        options={SUPPORTED_MODULES}
                        value={
                          vinVerificationBotStrategyConfig.BRANCHES.VERIFICATION
                        }
                        onChange={(e) =>
                          setVinVerificationBotStrategyConfig({
                            ...vinVerificationBotStrategyConfig,
                            BRANCHES: { VERIFICATION: e.target.value },
                          })
                        }
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButton
                        fill
                        onClick={() =>
                          setShowAccordion({
                            ...showAccordion,
                            unsuccessfulVerification: false,
                          })
                        }
                      >
                        Close this section
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
          </div>
          <div>
            <EuiButton fill onClick={handleSubmitVerificationClick}>
              Next
            </EuiButton>
          </div>
        </div>
      )}
    </>
  );
};

export default VinVerification;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../common/hooks';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../generated/tenants/Api';
import { CreatePresetContainer } from '../PresetsPanel.style';
import { createCalendarAsyncThunk } from './api/calendarSlice';
import CalendarForm from './CalendarForm';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface CalendarCreateProps {
  calendar?: CalendarConfiguration;
  strategy: string;
  backToList: () => void;
  handleNewCalendarChange?: () => void;
  formButtonDown?: boolean;
}

const CalendarCreate: React.FC<CalendarCreateProps> = ({
  calendar,
  strategy,
  backToList,
  handleNewCalendarChange,
  formButtonDown,
}) => {
  const dispatch = useAppDispatch();
  const [newCalendar, setNewCalendar] =
    useState<CalendarConfigurationRequest>();

  useEffect(() => {
    if (calendar) {
      setNewCalendar(calendar);
    } else {
      setNewCalendar({
        name: '',
        strategy: strategy,
        working_hours: [],
        cc_working_hours: [],
        inbound_working_hours: [],
        timezone: 'Europe/Warsaw',
      });
    }
  }, [calendar]);

  const handleAddCalendarClick = () => {
    if (newCalendar) {
      setIsLoadingVisible(true);
      dispatch(createCalendarAsyncThunk(newCalendar)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const [calendarHasErrors, setCalendarHasErrors] = useState<boolean>(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <CreatePresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {newCalendar && (
        <CalendarForm
          calendar={newCalendar}
          setCalendar={setNewCalendar}
          button={handleAddCalendarClick}
          createNew
          back={backToList}
          handleNewCalendarChange={handleNewCalendarChange}
          calendarHasErrors={calendarHasErrors}
          setCalendarHasErrors={setCalendarHasErrors}
          buttonDown={formButtonDown}
        />
      )}
    </CreatePresetContainer>
  );
};

export default CalendarCreate;

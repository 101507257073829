import { EuiModal, EuiModalHeader, EuiModalFooter } from '@elastic/eui';
import React from 'react';
import { H2, H3, Button, ButtonEmpty } from '../../app/App.style';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isVisible: boolean;
  headerTitle: string;
  onCancel: () => void;
  onConfirm?: (e: React.FormEvent<HTMLFormElement>) => boolean;
  children: React.ReactNode;
  buttonStyle?: 'NORMAL' | 'RED' | 'SECONDARY';
  buttonDisabled?: boolean;
  noConfirm?: boolean;
  noButtons?: boolean;
  buttonText?: string;
  width?: number;
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  headerTitle,
  onConfirm,
  onCancel,
  children,
  buttonStyle,
  buttonDisabled,
  noConfirm,
  noButtons,
  buttonText,
  width,
}) => {
  const { t } = useTranslation();
  const formRef = React.useRef<HTMLFormElement>(null);
  let modal;

  const handleConfirmClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (formRef.current) {
      const formEvent = new Event('submit', {
        bubbles: true,
        cancelable: true,
      });
      formRef.current.dispatchEvent(formEvent);
    }
  };
  if (isVisible) {
    const handleCancel = () => {
      onCancel && onCancel();
      onCancel();
    };
    modal = (
      <EuiModal
        onClose={onCancel}
        className="custom-modal"
        style={{ width: width && width }}
      >
        <EuiModalHeader>
          <H2 $selected>{headerTitle}</H2>
        </EuiModalHeader>

        <form ref={formRef} onSubmit={onConfirm} style={{ overflow: 'auto' }}>
          {children}
        </form>
        {!noButtons && (
          <EuiModalFooter>
            {noConfirm ? (
              <>
                <Button
                  onClick={handleCancel}
                  $styleType={'NORMAL'}
                  type={'button'}
                  disabled={buttonDisabled}
                >
                  {noConfirm && buttonText ? buttonText : t('common.cancel')}
                </Button>
              </>
            ) : (
              <>
                <ButtonEmpty onClick={handleCancel}>
                  <H3>{t('common.cancel')}</H3>
                </ButtonEmpty>
                <Button
                  $size="M"
                  onClick={handleConfirmClick}
                  $styleType={buttonStyle}
                  type={'button'}
                  disabled={buttonDisabled}
                >
                  {buttonText || t('common.confirm')}
                </Button>
              </>
            )}
          </EuiModalFooter>
        )}
      </EuiModal>
    );
  }

  return <>{modal}</>;
};

export default Modal;

import moment from 'moment';
import React from 'react';
import { H3 } from '../../../App.style';
import { VoiceConfiguration } from '../../../../generated/tenants/Api';
import PresetListPreview from '../PresetListPreview';
import {
  ListContainer,
  CreateNewPresetContainer,
  CreatePresetButtonContainer,
  AddPresetIconContainer,
} from '../PresetsPanel.style';
import { NewPresetIcon } from '../../../../resources/icons-new/NewPresetIcon';
import { useTranslation } from 'react-i18next';

interface VoicesListProps {
  voicesList: VoiceConfiguration[];
  createVoice: () => void;
  goToDetailed: (voiceId: string) => void;
  findProcessNamesById(id: string): string[];
  handleDeleteVoicesPreset: (id: string) => void;
  handleDuplicateVoice: (id: string) => void;
  hasPermission: boolean;
}

const VoicesList: React.FC<VoicesListProps> = ({
  voicesList,
  createVoice,
  goToDetailed,
  findProcessNamesById,
  handleDeleteVoicesPreset,
  handleDuplicateVoice,
  hasPermission,
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer>
      {voicesList &&
        voicesList.map((voice, index) => {
          const formattedCreated = moment(voice.created_at).format(
            'DD/MM/YYYY, HH:mm',
          );
          const fomrattedModified = moment(voice.updated_at).format(
            'DD/MM/YYYY, HH:mm',
          );

          const usedIn = findProcessNamesById(voice.id);
          return (
            <PresetListPreview
              type="voice"
              key={`voicesList-${index}`}
              index={index}
              onClick={goToDetailed}
              presetId={voice.id}
              presetName={voice.name}
              createDate={formattedCreated}
              modifiedDate={fomrattedModified}
              usedIn={usedIn}
              handleDeletePreset={handleDeleteVoicesPreset}
              handleDuplicatePreset={handleDuplicateVoice}
              disableDots={!hasPermission}
            />
          );
        })}

      {hasPermission && (
        <CreateNewPresetContainer onClick={createVoice}>
          <CreatePresetButtonContainer>
            <AddPresetIconContainer>
              <NewPresetIcon
                style={{
                  position: 'absolute',
                  left: 17,
                  top: 17,
                  width: 15,
                  height: 15,
                }}
              />
            </AddPresetIconContainer>
            <H3>{t('common.createNew')}</H3>
          </CreatePresetButtonContainer>
        </CreateNewPresetContainer>
      )}
    </ListContainer>
  );
};

export default VoicesList;

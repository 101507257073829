import { COLOR_NEUTRAL_20, COLOR_NEUTRAL_50 } from './../../../../../App.style';
import styled from 'styled-components';

export const NumbersTopRowContainer = styled.div`
  width: 100;
  margin-top: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const NumbersSearchList = styled.div`
  background: white;
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 100;
  width: 100%;
  max-height: 300px;
  overflow: auto;
`;

export const NumbersSearchPlaceholder = styled.div`
  position: absolute;
  top: 5%;
  left: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  height: 90%;
  width: calc(100% - 40px);
`;

export const NumbersHistoryContainer = styled.div`
  margin-top: 16px;
`;

export const NumbersHistoryTenantContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid ${COLOR_NEUTRAL_50};
`;

export const NumberHistoryCampaignRow = styled.div`
  display: flx;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};
  padding: 5px 0px;
  margin-left: 20px;
  max-width: 700px;
`;

export const NumbersHistoryCampaignRowLeftContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const NumbersHistoryCampaignRowRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

//adding numbers

export const AddNewNumbersFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddNewNumbersDirectToTenantContainer = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

import { RootState } from '../../../../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  campaignLabelsUpdate,
  CheckLabelsResult,
  createLabel,
  deleteLabel,
  getLabelsList,
  updateLabel,
} from './labelsApi';
import {
  Label,
  LabelRequest,
  ModifyLabelsRequest,
} from '../../../../../generated/tenants/Api';

export interface LabelState {
  list: {
    state: 'pending' | 'idle';
    value: Label[];
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckLabelsResult;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckLabelsResult;
  };
  delete: {
    state: 'pending' | 'idle';
  };
  campaignLabels: {
    state: 'pending' | 'idle';
  };
}

const initialState: LabelState = {
  list: { state: 'pending', value: [] },
  create: { state: 'pending' },
  update: { state: 'pending' },
  delete: {
    state: 'pending',
  },
  campaignLabels: { state: 'pending' },
};

export const getLabelsListAsyncThunk = createAsyncThunk(
  'labels/list',
  (id: string) => getLabelsList(id),
);

export const createLabelAsyncThunk = createAsyncThunk(
  'labels/create',
  (data: LabelRequest) => createLabel(data),
);

export const updateLabelAsyncThunk = createAsyncThunk(
  'labels/update',
  (args: { id: string; data: LabelRequest }) => updateLabel(args.id, args.data),
);

export const deleteLabelAsyncThunk = createAsyncThunk(
  'labels/delete',
  (id: string) => deleteLabel(id),
);

export const campaignLabelsUpdateAsyncThunk = createAsyncThunk(
  'campaigns/labels/update',
  (args: { campaignId: string; data: ModifyLabelsRequest }) =>
    campaignLabelsUpdate(args.campaignId, args.data),
);

export const labelSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    clearCreateLabel: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateLabel: (state) => {
      state.update = { state: 'pending' };
    },
    clearCampaignLabels: (state) => {
      state.campaignLabels = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder

      //list
      .addCase(getLabelsListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(getLabelsListAsyncThunk.fulfilled, (state, action) => {
        state.list.state = 'idle';
        state.list.value = action.payload;
      })
      .addCase(getLabelsListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //create
      .addCase(createLabelAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(createLabelAsyncThunk.fulfilled, (state, action) => {
        state.create.state = 'idle';
        state.create.value = action.payload;
      })
      .addCase(createLabelAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })

      //update
      .addCase(updateLabelAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(updateLabelAsyncThunk.fulfilled, (state, action) => {
        state.update.state = 'idle';
        state.update.value = action.payload;
      })
      .addCase(updateLabelAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })

      //delete
      .addCase(deleteLabelAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteLabelAsyncThunk.fulfilled, (state) => {
        state.delete.state = 'idle';
      })
      .addCase(deleteLabelAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      })

      //campaign labels update
      .addCase(campaignLabelsUpdateAsyncThunk.pending, (state) => {
        state.campaignLabels.state = 'pending';
      })
      .addCase(campaignLabelsUpdateAsyncThunk.fulfilled, (state) => {
        state.campaignLabels.state = 'idle';
      })
      .addCase(campaignLabelsUpdateAsyncThunk.rejected, (state) => {
        state.campaignLabels.state = 'idle';
      });
  },
});

export const selectLabelsList = (state: RootState) => state.labels.list.value;

export const checkLabelCreate = (state: RootState) => state.labels.create;
export const checkLabelUpdate = (state: RootState) => state.labels.update;
export const checkLabelDelete = (state: RootState) => state.labels.delete;

export const checkCampaignLabels = (state: RootState) =>
  state.labels.campaignLabels;

export const { clearCreateLabel, clearUpdateLabel, clearCampaignLabels } =
  labelSlice.actions;

export default labelSlice.reducer;

import styled, { css } from 'styled-components';

export const BLACK_typography = '#1A1A1A';

export const COLOR_WHITE = '#FFFFFF';

//colors
export const COLOR_PRIMARY_1 = '#127FBF';
export const COLOR_PRIMARY_2 = '#CFECFD';
export const COLOR_PRIMARY_3 = '#0F172A';

export const COLOR_NEUTRAL_10 = '#F5F7FA';
export const COLOR_NEUTRAL_20 = '#ECEFF3';
export const COLOR_NEUTRAL_30 = '#CBD2D9';
export const COLOR_NEUTRAL_40 = '#9AA5B1';
export const COLOR_NEUTRAL_50 = '#7B8794';
export const COLOR_NEUTRAL_80 = '#3E4C59';

//fonts
export const fontIBM = 'IBM Plex Sans';

//headings
interface H1Props {
  $bold?: boolean;
  $medium?: boolean;
}

export const H1 = styled.h1<H1Props>`
  font-family: ${fontIBM};
  font-size: 18px;
  font-weight: ${(props) => (props.$bold ? 700 : props.$medium ? 500 : 400)};
  color: ${BLACK_typography};

  opacity: 100%;

  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
`;

interface H2Props {
  $selected?: boolean;
  $semibold?: boolean;
  $medium?: boolean;
  $white?: boolean;
  $red?: boolean;
  $grey?: boolean;
}

export const H2 = styled.h2<H2Props>`
  font-family: ${fontIBM};
  font-size: 16px;
  font-weight: ${(props) =>
    props.$selected ? 600 : props.$medium ? 500 : 400};
  color: ${BLACK_typography};
  color: ${(props) =>
    props.$white
      ? 'white'
      : props.$red
        ? '#ff0000'
        : props.$grey && COLOR_NEUTRAL_80};

  text-decoration: none;

  /* white-space: nowrap; */

  text-overflow: ellipsis;
`;
interface H3Props {
  $bold?: boolean;
  $grey?: boolean;
  $underline?: boolean;
  $semibold?: boolean;
  $selected?: boolean;
}

export const H3 = styled.h3<H3Props>`
  font-family: ${fontIBM};
  font-size: 14px;
  font-weight: ${(props) =>
    props.$bold ? 700 : props.$semibold ? 600 : props.$selected ? 500 : 400};
  line-height: 19px;

  ${(props) => {
    if (props.$grey) {
      return COLOR_NEUTRAL_50;
    }

    if (props.$selected) {
      return COLOR_WHITE;
    } else if (props.$selected === false) {
      return COLOR_NEUTRAL_40;
    }

    if (props.color) {
      return props.color;
    }

    return BLACK_typography;
  }};
  text-decoration: ${(props) => (props.$underline ? 'underline' : 'none')};

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface H4Props {
  $grey?: boolean;
  $bold?: boolean;
  $medium?: boolean;
  $wrap?: boolean;
  $italic?: boolean;
}

export const H4 = styled.h4<H4Props>`
  padding: 2px;
  font-family: ${fontIBM};
  font-size: 12px;
  font-weight: ${(props) => (props.$bold ? 700 : props.$medium ? 500 : 400)};
  ${(props) => {
    if (props.$grey) {
      return COLOR_NEUTRAL_50;
    }

    if (props.color) {
      return props.color;
    }

    return BLACK_typography;
  }};
  font-style: ${({ $italic }) => $italic && 'italic'};

  ${(props) =>
    !props.$wrap &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

interface H5Props {
  $bold?: boolean;
  $color?: string;
  $black?: boolean;
  $underlineHover?: boolean;
}

export const H5 = styled.h5<H5Props>`
  font-family: ${fontIBM};
  font-size: 12px;
  font-weight: ${(props) => (props.$bold ? 500 : 400)};
  color: ${(props) =>
    props.$color
      ? props.$color
      : props.$black
        ? BLACK_typography
        : COLOR_NEUTRAL_80};

  &:hover {
    text-decoration: ${(props) =>
      props.$underlineHover ? 'underline' : 'none'};
  }
`;

interface H6Props {
  $bold?: boolean;
  $grey?: boolean;
  $medium?: boolean;
}

export const H6 = styled.h6<H6Props>`
  font-family: ${fontIBM};
  font-size: 10px;
  font-weight: ${(props) => (props.$bold ? 700 : props.$medium ? 500 : 400)};
  color: ${(props) => (props.$grey ? COLOR_NEUTRAL_80 : BLACK_typography)};
`;

const buttonSizes = {
  XL: css`
    height: 42px;
    padding: 10px 18px;
    gap: 8px;
    font-size: 18px;
  `,
  L: css`
    height: 38px;
    padding: 10px 14px;
    gap: 8px;
    font-size: 16px;
  `,
  M: css`
    padding: 6px 8px;
    gap: 8px;
    font-size: 14px;
  `,
  S: css`
    height: 24px;
    padding: 2px 8px;
    gap: 8px;
    font-size: 12px;
  `,
  XS: css`
    height: 16px;
    padding: 2px 8px;
    gap: 8px;
    font-size: 10px;
    border: none;
  `,
};

interface ButtonProps {
  $size?: 'XL' | 'L' | 'M' | 'S' | 'XS';
  $styleType?: 'NORMAL' | 'RED' | 'SECONDARY';
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(152, 162, 179, 0.3);
  background: #0f172a;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-family: ${fontIBM};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $size }) => buttonSizes[$size || 'L']}

  ${({ $styleType }) =>
    $styleType === 'NORMAL' &&
    css`
      background: #fff;
      border: 1px solid #0f172a;
      font-weight: 400;
      color: #1a1a1a;
    `}

  ${({ $styleType }) =>
    $styleType === 'RED' &&
    css`
      background: #983700;
      font-weight: 400;
    `}

    ${({ $styleType }) =>
    $styleType === 'SECONDARY' &&
    css`
      background: #fff;
      border: none;
      font-weight: 400;
      color: #1a1a1a;
    `}


  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    background: #f5f7fa;
    border: 1px solid #e4e7eb;
    color: #9aa5b1;
    cursor: not-allowed;
    opacity: 70%;
  }

  &:active {
    ${({ $styleType }) =>
      $styleType === 'NORMAL' &&
      css`
        box-shadow: 2px 2px 3px 0px rgba(155, 155, 155, 0.9) inset;
      `}

    ${({ $styleType }) =>
      $styleType !== 'NORMAL' &&
      css`
        box-shadow: 2px 2px 2.4px 0px #adadad inset;
      `}
  }
`;

export const ButtonEmpty = styled.button`
  min-width: 60px;

  padding: 5px 15px;

  font-family: ${fontIBM};
  font-size: 12px;
  font-weight: 400;
  color: black;

  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export const CopyIdButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(152, 162, 179, 0.3);
  background: ${COLOR_NEUTRAL_10};
  border: none;
  color: #1a1a1a;
  font-weight: 500;
  font-family: ${fontIBM};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  height: 24px;
  padding: 2px 8px;
  gap: 6px;
  font-size: 12px;

  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }
`;

//app

export const AppContainer = styled.div`
  width: 100%;

  height: 100vh;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: #ffffff;
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  width: calc(100% - 180px);
  height: 100vh;

  display: flex;
`;

export const DetailedContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  background: white;
  padding: 24px;

  display: flex;
  flex-direction: column;
`;

export const HoverUnderline = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;

//old

export const OldThingsContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

export const OldContentContainer = styled.div`
  width: 80%;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 20px;

  border-radius: 10px;
  padding: 20px;

  -webkit-box-shadow: 0px 0px 43px -5px rgba(142, 142, 142, 1);
  -moz-box-shadow: 0px 0px 43px -5px rgba(142, 142, 142, 1);
  box-shadow: 0px 0px 43px -5px rgba(142, 142, 142, 1);
`;

export const AppLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 10000;
  left: 0px;
  top: 0px;
  background: white;
`;

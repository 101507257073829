import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const AudioStopIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM9.375 7.5C8.33947 7.5 7.5 8.33947 7.5 9.375V14.625C7.5 15.6605 8.33947 16.5 9.375 16.5C10.4105 16.5 11.25 15.6605 11.25 14.625V9.375C11.25 8.33947 10.4105 7.5 9.375 7.5ZM14.625 7.5C13.5895 7.5 12.75 8.33947 12.75 9.375V14.625C12.75 15.6605 13.5895 16.5 14.625 16.5C15.6605 16.5 16.5 15.6605 16.5 14.625V9.375C16.5 8.33947 15.6605 7.5 14.625 7.5Z"
      fill="#127FBF"
    />
  </StyledSvg>
);

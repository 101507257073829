import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const CopyIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M11.4 0C11.6349 0 11.8603 0.098875 12.0223 0.273L14.7651 3.2725C14.916 3.43525 15 3.65138 15 3.87538V13.125C15 13.608 14.616 14 14.1429 14H3.85714C3.384 14 3 13.608 3 13.125V0.875C3 0.392 3.384 0 3.85714 0H11.4ZM14 4H11.4C11.1792 4 11 3.8208 11 3.6V1H4V13H14V4Z" />
    <path d="M3 1H2C1.44772 1 1 1.44772 1 2V15C1 15.5523 1.44772 16 2 16H12C12.5523 16 13 15.5523 13 15V14H12V15H2V2H3V1Z" />
  </StyledSvg>
);

import React from 'react';
import { Process } from '../../../../../../generated/tenants/Api';
import { Button, H4, H5 } from '../../../../../App.style';
import { EuiPopover, EuiCheckbox } from '@elastic/eui';
import { CampaingsFiltersValues } from './CampaignsFilters';
import {
  FilterBarButton,
  FilterButton,
  FiltersBottomContainer,
  FiltersContainer,
  FiltersContentContainer,
  FiltersOptionContainer,
  FiltersTopContainer,
} from './CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { useTranslation } from 'react-i18next';

interface CampaignProcesesFilterProps {
  barButton: boolean;
  processList: Process[];
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedProcesses: string[];
  setSelectedProcesses: React.Dispatch<React.SetStateAction<string[]>>;
  filters: CampaingsFiltersValues;
  setFilters: (value: React.SetStateAction<CampaingsFiltersValues>) => void;
}

const CampaignProcesesFilter: React.FC<CampaignProcesesFilterProps> = ({
  barButton,
  processList,
  isOpen,
  setIsOpen,
  selectedProcesses,
  setSelectedProcesses,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const processesOptions = processList.map((process) => {
    return { value: process.id, text: process.name };
  });

  const handleProcessChange = (option: string) => {
    if (selectedProcesses.includes(option)) {
      setSelectedProcesses(
        selectedProcesses.filter((value) => value !== option),
      );
    } else {
      setSelectedProcesses([...selectedProcesses, option]);
    }
  };

  const handleRemoveProcesses = () => {
    setIsOpen(false);
    setFilters({ ...filters, processes: [] });
  };

  const handleApplySelectedProcesses = () => {
    setFilters({ ...filters, processes: selectedProcesses });
    setIsOpen(false);
  };

  const selectedProcessesNames = processList
    .filter((obj) => filters.processes?.includes(obj.id))
    .map((obj) => obj.name);
  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            {t('campaigns.bar.filters.process')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <H4 $bold>{t('campaigns.bar.filters.processIs')}</H4>{' '}
            <H4>{selectedProcessesNames.join(', ')}</H4> <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <FiltersContainer>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.bar.filters.processIs')}</H4>
          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleRemoveProcesses}
            >
              {t('campaigns.bar.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer>
          {processesOptions.map((option, index) => (
            <FiltersOptionContainer
              key={`process-filter-${index}`}
              onClick={() => handleProcessChange(option.value)}
            >
              <EuiCheckbox
                id={option.value}
                key={index}
                label={''}
                checked={selectedProcesses.includes(option.value)}
                onChange={(e) => e.preventDefault()}
              />
              <H4 title={option.text}>{option.text}</H4>
            </FiltersOptionContainer>
          ))}
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button
            onClick={handleApplySelectedProcesses}
            style={{ minWidth: 40, height: 20, padding: '0px 8px' }}
            $size="S"
            color="white"
          >
            {t('campaigns.bar.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </FiltersContainer>
    </EuiPopover>
  );
};

export default CampaignProcesesFilter;

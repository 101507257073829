import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CopyIdButton,
  DetailedContainer,
  H2,
  H3,
  H4,
  H5,
} from '../../../../../App.style';
import {
  EuiBreadcrumb,
  EuiBreadcrumbs,
  EuiLoadingSpinner,
  EuiPopover,
  EuiToolTip,
  copyToClipboard,
} from '@elastic/eui';
import { Call } from '../../../../../../generated/tenants/Api';
import {
  BarItem,
  BarItemTitle,
  NumberSpan,
  CallBarContainer,
  CallBarLeftContainer,
  CallBarRightContainer,
  CallTitleContainer,
  CallsDetailedContainer,
  BarItemContent,
  CallRecordingContainer,
  CallRecordingTopContainer,
  DownloadButton,
  CallTranscriptContainer,
  CallTranscriptTopContainer,
  TranscriptTitle,
} from '../Calls.styles';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  callDetailedViewAsyncThunk,
  getCallRecordingAsyncThunk,
  getCallTranscriptAsyncThunk,
  getCampaignByIdAsyncThunk,
  getCampaignCallsListAsyncThunk,
  selectCallDetailed,
  selectCallRecording,
  selectCampaignById,
  selectCampaignCallsList,
} from '../../../api/campaignsSlice';
import moment from 'moment';

import CallTranscript from './CallTranscript';

import CallRecording from './CallRecording';
import CallFlyout from './CallFlyout';

import { formatNumber } from '../CallsTable';
import { ChevronUpIcon } from '../../../../../../resources/icons-new/ChevronUpIcon';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { ArrowLeftIcon } from '../../../../../../resources/icons-new/ArrowLeftIcon';
import { CopyIcon } from '../../../../../../resources/icons-new/CopyIcon';
import { TwoChevronsLeftIcon } from '../../../../../../resources/icons-new/TwoChevronsLeftIcon';
import { useTranslation } from 'react-i18next';

const CallDetailed = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { campaign_id, call_id } = useParams();
  const navigate = useNavigate();

  const [call, setCall] = useState<Call>();
  const [recording, setRecording] = useState<string>();

  const handleCallClick = (call_id: string) => {
    navigate(`/dashboard/campaigns/${campaign_id}/calls/${call_id}`);
  };

  //campaign detailed and call detailed
  useEffect(() => {
    if (campaign_id && call_id) {
      dispatch(getCampaignByIdAsyncThunk(campaign_id));
      dispatch(callDetailedViewAsyncThunk(call_id));

      const data: { callId: string; query: { audio_format: 'mp3' } } = {
        callId: call_id,
        query: { audio_format: 'mp3' },
      };

      dispatch(getCallRecordingAsyncThunk(data));
    }
  }, [campaign_id, call_id]);

  const callRecording = useAppSelector(selectCallRecording);

  const [recordingLoading, setRecordinLoading] = useState<boolean>(true);

  //if there is no recording, set it
  useEffect(() => {
    if (!recording && callRecording.state === 'idle') {
      setRecording(
        callRecording.value?.recordingUrl
          ? callRecording.value.recordingUrl
          : undefined,
      );
      setRecordinLoading(false);
    }
  }, [callRecording, call_id, campaign_id]);

  // //unmouting
  useEffect(() => {
    return () => {
      setCall(undefined);
      setRecording(undefined);
      setRecordinLoading(true);
      if (callRecording.value?.recordingUrl) {
        URL.revokeObjectURL(callRecording.value.recordingUrl);
      }
    };
  }, []);

  const callDetailed = useAppSelector(selectCallDetailed);
  const campaign = useAppSelector(selectCampaignById);

  useEffect(() => {
    if (callDetailed && campaign_id) {
      setCall(callDetailed);

      //get calls list from this record
      const data = {
        id: campaign_id,
        query: { record_id: callDetailed.record },
      };
      dispatch(getCampaignCallsListAsyncThunk(data));
    }
  }, [callDetailed]);

  const callsFromThisRecord = useAppSelector(selectCampaignCallsList);

  const breadcrumbs: EuiBreadcrumb[] = [
    {
      text: <H4 $grey>{t('campaigns.calls.detailed.dashboard')}</H4>,
      href: '/dashboard',
      onClick: (e) => {
        e.preventDefault();
        navigate('/dashboard');
      },
      'data-test-subj': 'breadcrumbsAnimals',
    },
    {
      text: <H4 $grey>{t('campaigns.calls.detailed.campaigns')}</H4>,
      href: '/dashboard',
      onClick: (e) => {
        e.preventDefault();
        navigate('/dashboard?campaigns=true');
      },
    },
    {
      text: (
        <H4 $grey>
          {t('campaigns.calls.detailed.campaignNr')}
          {campaign_id}
        </H4>
      ),
      href: '/dashboard',
      onClick: (e) => {
        e.preventDefault();
        navigate(`/dashboard/campaigns/${campaign_id}?calls=true`);
      },
    },
    {
      text: (
        <H4 $grey>
          {t('campaigns.calls.detailed.callNr')}
          {call_id}
        </H4>
      ),
    },
  ];

  const renderTitle = () => {
    if (call?.call_type === 'INBOUND') {
      return (
        <>
          <EuiToolTip position="top" content="Client number">
            <NumberSpan>{formatNumber(call.record_phone_number)}</NumberSpan>
          </EuiToolTip>
          {t('campaigns.calls.detailed.to')}
          <EuiToolTip position="top" content="Lekta bot number">
            <NumberSpan>{formatNumber(call.campaign_phone_number)}</NumberSpan>
          </EuiToolTip>
        </>
      );
    } else if (call?.call_type === 'OUTBOUND') {
      return (
        <>
          <EuiToolTip position="top" content="Lekta bot number">
            <NumberSpan>{formatNumber(call.campaign_phone_number)}</NumberSpan>
          </EuiToolTip>
          {t('campaigns.calls.detailed.to')}
          <EuiToolTip position="top" content="Client number">
            <NumberSpan>{formatNumber(call.record_phone_number)}</NumberSpan>
          </EuiToolTip>
        </>
      );
    }
  };

  const [callIdCopied, setCallIdCopied] = useState<boolean>(false);

  const handleCopyCallId = () => {
    if (call) {
      copyToClipboard(call.id);
      setCallIdCopied(true);
    }
  };

  function calculateCallDuration(started_at: string, ended_at: string): string {
    const startDate = moment(started_at);
    const endDate = moment(ended_at);

    const duration = moment.duration(endDate.diff(startDate));
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds()) % 60;

    // Format minutes and seconds as two-digits numbers
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  //flyout
  const [isRecordCallsFlyoutVisible, setIsRecordCallsFlyoutVisible] =
    useState<boolean>(false);
  const openRecordCallsFlyout = () => setIsRecordCallsFlyoutVisible(true);
  const closeRecordCallsFlyout = () => setIsRecordCallsFlyoutVisible(false);

  let flyout;
  if (isRecordCallsFlyoutVisible && callsFromThisRecord?.results && call) {
    flyout = (
      <CallFlyout
        currentCall={call}
        onClose={closeRecordCallsFlyout}
        calls={callsFromThisRecord.results}
        handleCallClick={handleCallClick}
      />
    );
  }

  const [isRecordingPopoverVisible, setIsRecordingPopoverVisible] =
    useState<boolean>(false);

  const handleDownloadRecordingClick = (format: 'flac' | 'mp3' | 'wav') => {
    if (call_id) {
      const data = { callId: call_id, query: { audio_format: format } };

      dispatch(getCallRecordingAsyncThunk(data)).then((returnedAction) => {
        if (getCallRecordingAsyncThunk.fulfilled.match(returnedAction)) {
          const audioUrl = returnedAction.payload.recordingUrl;

          // Create an anchor element
          const link = document.createElement('a');

          // Set the href and download attributes
          link.href = audioUrl;

          // Set your desired file name here
          link.download = returnedAction.payload.filename;

          // Append it and trigger the download
          document.body.appendChild(link);
          link.click();

          // Cleanup
          document.body.removeChild(link);
        }
      });
    }

    setIsRecordingPopoverVisible(false);
  };

  const handleDownloadTranscriptClick = () => {
    if (call_id) {
      dispatch(getCallTranscriptAsyncThunk(call_id)).then((returnedAction) => {
        if (getCallTranscriptAsyncThunk.fulfilled.match(returnedAction)) {
          const transcriptUrl = returnedAction.payload.transcriptUrl;
          const link = document.createElement('a');

          link.href = transcriptUrl;

          link.download = returnedAction.payload.filename;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        }
      });
    }
  };

  return (
    <DetailedContainer>
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="form-breadcumbs"
      />

      {call && campaign && (
        <CallsDetailedContainer>
          {flyout}
          <CallTitleContainer>
            <Button
              $size={'S'}
              $styleType="NORMAL"
              onClick={() =>
                navigate(`/dashboard/campaigns/${campaign_id}?calls=true`)
              }
            >
              <ArrowLeftIcon />
              {t('common.goBack')}
            </Button>
            <H2>
              {t('campaigns.calls.detailed.outboundCallFrom')}
              {renderTitle()}
            </H2>
          </CallTitleContainer>
          <CallBarContainer>
            <CallBarLeftContainer>
              <BarItem>
                <BarItemTitle>
                  <H4 $grey>{t('campaigns.calls.detailed.callId')}</H4>
                </BarItemTitle>
                <BarItemContent>
                  <EuiToolTip
                    position="top"
                    content={
                      callIdCopied
                        ? t('campaigns.calls.detailed.idCopied')
                        : call.id
                    }
                  >
                    <CopyIdButton
                      onClick={handleCopyCallId}
                      onMouseLeave={() => setCallIdCopied(false)}
                    >
                      <CopyIcon />
                      {t('campaigns.calls.detailed.copyId')}
                    </CopyIdButton>
                  </EuiToolTip>
                </BarItemContent>
              </BarItem>

              <BarItem>
                <BarItemTitle>
                  <H4 $grey>
                    {t('campaigns.calls.detailed.dateAndTimeCallStart')}
                  </H4>
                </BarItemTitle>
                <BarItemContent>
                  <H5 $black>
                    {moment(call.started_at).format('DD.MM.YY, HH:mm')}
                  </H5>
                </BarItemContent>
              </BarItem>

              <BarItem>
                <BarItemTitle>
                  <H4 $grey>{t('campaigns.calls.detailed.callDuration')}</H4>
                </BarItemTitle>
                <BarItemContent>
                  <H5 $black>
                    {calculateCallDuration(call.started_at, call.ended_at)}
                  </H5>
                </BarItemContent>
              </BarItem>

              <BarItem>
                <BarItemTitle>
                  <H4 $grey>{t('campaigns.calls.detailed.campaign')}</H4>
                </BarItemTitle>
                <BarItemContent>
                  <H5 $black>{t('campaigns.calls.detailed.openingHours')}</H5>
                  <H5 $black>
                    {moment(campaign.start_date).format('DD.MM.YY')}-
                    {moment(campaign.end_date).format('DD.MM.YYYY')}
                  </H5>
                </BarItemContent>
              </BarItem>

              <BarItem>
                <BarItemTitle>
                  <H4 $grey>{t('campaigns.calls.detailed.callStatus')}</H4>
                </BarItemTitle>
                <BarItemContent>
                  <H5 $black>{call.telco_status}</H5>
                </BarItemContent>
              </BarItem>
            </CallBarLeftContainer>
            <CallBarRightContainer onClick={openRecordCallsFlyout}>
              <TwoChevronsLeftIcon />
              <H4 $bold>{t('campaigns.calls.detailed.callsFromThisRecord')}</H4>
            </CallBarRightContainer>
          </CallBarContainer>

          <CallRecordingContainer>
            <CallRecordingTopContainer>
              <H2 $selected>{t('campaigns.calls.detailed.recording')} </H2>

              <EuiPopover
                button={
                  <Button
                    $size={'S'}
                    $styleType="NORMAL"
                    onClick={() =>
                      setIsRecordingPopoverVisible((prev) => !prev)
                    }
                  >
                    {t('campaigns.calls.detailed.downloadRecording')}
                    {isRecordingPopoverVisible ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </Button>
                }
                isOpen={isRecordingPopoverVisible}
                closePopover={() => setIsRecordingPopoverVisible(false)}
                panelPaddingSize="none"
                anchorPosition="downRight"
                hasArrow={false}
              >
                <div>
                  <DownloadButton
                    onClick={() => handleDownloadRecordingClick('mp3')}
                  >
                    mp3
                  </DownloadButton>
                  <DownloadButton
                    onClick={() => handleDownloadRecordingClick('wav')}
                  >
                    wav
                  </DownloadButton>
                  <DownloadButton
                    onClick={() => handleDownloadRecordingClick('flac')}
                  >
                    flac
                  </DownloadButton>
                </div>
              </EuiPopover>
            </CallRecordingTopContainer>
            {recordingLoading ? (
              <EuiLoadingSpinner style={{ marginLeft: 100 }} size={'l'} />
            ) : (
              <>
                {recording && call.telco_status !== 'NOT_ANSWERED' ? (
                  <CallRecording audioSrc={recording} />
                ) : (
                  <H3>{t('campaigns.calls.detailed.noRecordings')}</H3>
                )}
              </>
            )}
          </CallRecordingContainer>

          <CallTranscriptContainer>
            <CallTranscriptTopContainer>
              <TranscriptTitle>
                <H2 $selected>{t('campaigns.calls.detailed.transcript')}</H2>
              </TranscriptTitle>
              <Button
                $size={'S'}
                $styleType="NORMAL"
                onClick={handleDownloadTranscriptClick}
              >
                {t('campaigns.calls.detailed.downloadTranscript')}
              </Button>
            </CallTranscriptTopContainer>
            {call.transcript && <CallTranscript transcript={call.transcript} />}
          </CallTranscriptContainer>
        </CallsDetailedContainer>
      )}
    </DetailedContainer>
  );
};

export default CallDetailed;

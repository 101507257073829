import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { H1, H3 } from '../../App.style';
import Calendars from './calendars/Calendars';
import CallingPreferences from './callingPreferences/CallingPreferences';
import BotDaysOff from './days_off/bot/BotDaysOff';
import CallCenterDaysOff from './days_off/callCentre/CallCenterDaysOff';
import Dialogues from './dialogues/Dialogues';
import {
  PresetsPanelContainer,
  TabsContainer,
  TabButtons,
  TabButton,
  TabContent,
} from './PresetsPanel.style';
import Voices from './voices/Voices';
import Labels from './labels/Labels';
import { useTranslation } from 'react-i18next';

const PresetsPanel: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs = [
    { name: t('presets.voices'), id: 'voices', content: <Voices /> },
    { name: t('presets.dialogues'), id: 'dialogues', content: <Dialogues /> },
    {
      name: t('presets.callingPreferences'),
      id: 'calling_preferences',
      content: <CallingPreferences />,
    },
    { name: t('presets.calendar'), id: 'calendar', content: <Calendars /> },
    {
      name: t('presets.callCenterDaysOff'),
      id: 'call_center_days_off',
      content: <CallCenterDaysOff />,
    },
    {
      name: t('presets.botDaysOff'),
      id: 'bots_day_off',
      content: <BotDaysOff />,
    },
    { name: t('presets.labels'), id: 'labels', content: <Labels /> },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleTabClick = (tab: string) => {
    navigate(`/dashboard/presets/${tab.toLowerCase()}`);
  };

  const { preset } = useParams();

  useEffect(() => {
    if (preset) {
      setActiveTab(preset);
    }
  }, [preset]);

  return (
    <PresetsPanelContainer>
      <H1 $medium>{t('presets.title')}</H1>
      <TabsContainer>
        <TabButtons>
          {tabs.map((tab) => {
            const isActive = activeTab === tab.id.toLowerCase();
            return (
              <TabButton
                key={tab.name}
                active={isActive}
                onClick={() => handleTabClick(tab.id)}
              >
                <H3 $grey={!isActive} $bold={isActive}>
                  {tab.name}
                </H3>
              </TabButton>
            );
          })}
        </TabButtons>

        <TabContent>
          {tabs.map((tab) => {
            if (tab.id === activeTab) {
              return <div key={tab.name}>{tab.content}</div>;
            }
            return null;
          })}
        </TabContent>
      </TabsContainer>
    </PresetsPanelContainer>
  );
};

export default PresetsPanel;

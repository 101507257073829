import { RootState } from '../../../../../../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Strategy,
  StrategyCreateRequest,
  StrategyRequest,
} from '../../../../../../../generated/tenants/Api';
import {
  getStrategiesList,
  createStrategy,
  CheckStrategyResult,
  updateStrategy,
  deleteStrategy,
  strategiesArchivedList,
  archiveStrategy,
  unarchiveStrategy,
  deleteArchivedStrategy,
  gpgRotateStrategy,
  getStrategyDetailed,
} from './strategiesApi';

export interface StrategyState {
  // strategy?: Strategy;
  strategy: string;
  strategiesList: { state: 'pending' | 'idle'; value: Strategy[] };
  strategyDetailed: { state: 'pending' | 'idle'; value?: Strategy };
  create: { state: 'pending' | 'idle'; value?: CheckStrategyResult };
  update: { state: 'pending' | 'idle'; value?: CheckStrategyResult };
  delete: { state: 'pending' | 'idle' };
  archivedList: { state: 'pending' | 'idle'; value: Strategy[] };
  archive: { state: 'pending' | 'idle' };
  unarchive: { state: 'pending' | 'idle' };
  deleteArchived: { state: 'pending' | 'idle' };
  gpgRotate: { state: 'pending' | 'idle' };
}

const initialState: StrategyState = {
  strategy: '',
  strategiesList: { state: 'pending', value: [] },
  strategyDetailed: { state: 'pending' },
  create: { state: 'pending' },
  update: { state: 'pending' },
  delete: { state: 'pending' },
  archivedList: { state: 'pending', value: [] },
  archive: { state: 'pending' },
  unarchive: { state: 'pending' },
  deleteArchived: { state: 'pending' },
  gpgRotate: { state: 'pending' },
};

export const getStrategiesListAsyncThunk = createAsyncThunk(
  'strategies/list',
  () => getStrategiesList(),
);

export const getStrategyDetailedAsyncThunk = createAsyncThunk(
  'strategies/detialed',
  (id: string) => getStrategyDetailed(id),
);

export const createStrategyAsyncThunk = createAsyncThunk(
  'strategies/create',
  (data: StrategyCreateRequest) => createStrategy(data),
);

export const updateStrategyAsyncThunk = createAsyncThunk(
  'strategies/update',
  (args: { id: string; data: StrategyRequest }) =>
    updateStrategy(args.id, args.data),
);

export const deleteStrategyAsyncThunk = createAsyncThunk(
  'strategies/delete',
  (id: string) => deleteStrategy(id),
);

export const getArchivedStrategiesListAsyncThunk = createAsyncThunk(
  'strategies/archived/list',
  () => strategiesArchivedList(),
);

export const archiveStrategyAsyncThunk = createAsyncThunk(
  'strategies/archive',
  (id: string) => archiveStrategy(id),
);

export const unarchiveStrategyAsyncThunk = createAsyncThunk(
  'strategies/unarchive',
  (id: string) => unarchiveStrategy(id),
);

export const deleteArchivedStrategyAsyncThunk = createAsyncThunk(
  'strategies/archive/delete',
  (id: string) => deleteArchivedStrategy(id),
);

export const gpgRotateStrategyAsyncThunk = createAsyncThunk(
  'strategies/gpg-rotate',
  (strategyId: string) => gpgRotateStrategy(strategyId),
);

export const strategiesSlice = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    clearCreateStrategy: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateStrategy: (state) => {
      state.update = { state: 'pending' };
    },
    setStrategyId: (state, action) => {
      state.strategy = action.payload;
      localStorage.setItem('strategy', action.payload);
    },
    clearArchiveStrategy: (state) => {
      state.archive = { state: 'pending' };
    },
    clearUnarchiveStrategy: (state) => {
      state.unarchive = { state: 'pending' };
    },
    clearGpgRotate: (state) => {
      state.gpgRotate = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStrategiesListAsyncThunk.pending, (state) => {
        state.strategiesList.state = 'pending';
      })
      .addCase(getStrategiesListAsyncThunk.fulfilled, (state, action) => {
        state.strategiesList.state = 'idle';
        state.strategiesList.value = action.payload;
      })
      .addCase(getStrategiesListAsyncThunk.rejected, (state) => {
        state.strategiesList.state = 'idle';
      })
      .addCase(getStrategyDetailedAsyncThunk.pending, (state) => {
        state.strategyDetailed.state = 'pending';
      })
      .addCase(getStrategyDetailedAsyncThunk.fulfilled, (state, action) => {
        state.strategyDetailed.state = 'idle';
        state.strategyDetailed.value = action.payload;
      })
      .addCase(getStrategyDetailedAsyncThunk.rejected, (state) => {
        state.strategyDetailed.state = 'idle';
      })
      .addCase(createStrategyAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(createStrategyAsyncThunk.fulfilled, (state, action) => {
        state.create.state = 'idle';
        state.create.value = action.payload;
      })
      .addCase(createStrategyAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })
      .addCase(updateStrategyAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(updateStrategyAsyncThunk.fulfilled, (state, action) => {
        state.update.state = 'idle';
        state.update.value = action.payload;
      })
      .addCase(updateStrategyAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })
      .addCase(deleteStrategyAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteStrategyAsyncThunk.fulfilled, (state) => {
        state.delete.state = 'idle';
      })
      .addCase(deleteStrategyAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      })
      .addCase(getArchivedStrategiesListAsyncThunk.pending, (state) => {
        state.archivedList.state = 'pending';
      })
      .addCase(
        getArchivedStrategiesListAsyncThunk.fulfilled,
        (state, action) => {
          state.archivedList.state = 'idle';
          state.archivedList.value = action.payload;
        },
      )
      .addCase(getArchivedStrategiesListAsyncThunk.rejected, (state) => {
        state.archivedList.state = 'idle';
      })
      .addCase(archiveStrategyAsyncThunk.pending, (state) => {
        state.archive.state = 'pending';
      })
      .addCase(archiveStrategyAsyncThunk.fulfilled, (state) => {
        state.archive.state = 'idle';
      })
      .addCase(archiveStrategyAsyncThunk.rejected, (state) => {
        state.archive.state = 'idle';
      })
      .addCase(unarchiveStrategyAsyncThunk.pending, (state) => {
        state.unarchive.state = 'pending';
      })
      .addCase(unarchiveStrategyAsyncThunk.fulfilled, (state) => {
        state.unarchive.state = 'idle';
      })
      .addCase(unarchiveStrategyAsyncThunk.rejected, (state) => {
        state.unarchive.state = 'idle';
      })
      .addCase(deleteArchivedStrategyAsyncThunk.pending, (state) => {
        state.deleteArchived.state = 'pending';
      })
      .addCase(deleteArchivedStrategyAsyncThunk.fulfilled, (state) => {
        state.deleteArchived.state = 'idle';
      })
      .addCase(deleteArchivedStrategyAsyncThunk.rejected, (state) => {
        state.deleteArchived.state = 'idle';
      })
      .addCase(gpgRotateStrategyAsyncThunk.pending, (state) => {
        state.gpgRotate.state = 'pending';
      })
      .addCase(gpgRotateStrategyAsyncThunk.fulfilled, (state) => {
        state.gpgRotate.state = 'idle';
      })
      .addCase(gpgRotateStrategyAsyncThunk.rejected, (state) => {
        state.gpgRotate.state = 'idle';
      });
  },
});

export const selectStrategyId = (state: RootState) => state.strategies.strategy;
export const selectStrategiesList = (state: RootState) =>
  state.strategies.strategiesList.value;
export const selectStrategyDetailed = (state: RootState) =>
  state.strategies.strategyDetailed.value;

export const checkStrategyCreate = (state: RootState) =>
  state.strategies.create;
export const checkStrategyUpdate = (state: RootState) =>
  state.strategies.update;

export const selectArchivedStrategiesList = (state: RootState) =>
  state.strategies.archivedList.value;

export const checkGpgRotate = (state: RootState) => state.strategies.gpgRotate;

export const {
  clearCreateStrategy,
  clearUpdateStrategy,
  setStrategyId,
  clearArchiveStrategy,
  clearUnarchiveStrategy,
  clearGpgRotate,
} = strategiesSlice.actions;

export default strategiesSlice.reducer;

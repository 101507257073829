import React, { useEffect, useState } from 'react';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../../processes/forms/ProcessForm.style';
import { Button, H3 } from '../../../../App.style';
import { EuiFieldText, EuiSuperSelect } from '@elastic/eui';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  CustomFaas,
  CustomFaasRequest,
  LambdaTypeEnum,
} from '../../../../../generated/tenants/Api';
import {
  checkCustomFaasCreate,
  checkCustomFaasUpdate,
  clearCreateCustomFaas,
  clearUpdateCustomFaas,
  getCustomFaasesListAsyncThunk,
} from '../api/dialoguesSlice';
import { ApiError } from '../../../../../common/types';

const useCustomFaasApiErrors = () => {
  const checkCreate = useAppSelector(checkCustomFaasCreate);
  const createErrors = checkCreate?.value?.error;

  const checkUpdate = useAppSelector(checkCustomFaasUpdate);
  const updateErrors = checkUpdate?.value?.error;

  const [customFaasApiErrors, setCustomFaasApiErrors] = useState<
    ApiError<CustomFaas>
  >({} as ApiError<CustomFaas>);

  useEffect(() => {
    if (createErrors)
      setCustomFaasApiErrors((prevErrors) => ({
        ...prevErrors,
        ...createErrors,
      }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setCustomFaasApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [customFaasApiErrors, setCustomFaasApiErrors] as const;
};

interface CustomFaasFormProps {
  customFaas: CustomFaasRequest;
  setCustomFaas: React.Dispatch<React.SetStateAction<CustomFaasRequest>>;
  button?: () => void;
  createNew?: boolean;
  backToList: () => void;
  backToDetailed?: () => void;
  handleNewCustomFaasChange?: () => void;
}

const CustomFaasForm: React.FC<CustomFaasFormProps> = ({
  customFaas,
  setCustomFaas,
  button,
  createNew,
  backToList,
  backToDetailed,
  handleNewCustomFaasChange,
}) => {
  const dispatch = useAppDispatch();

  const checkCreate = useAppSelector(checkCustomFaasCreate);

  const checkUpdate = useAppSelector(checkCustomFaasUpdate);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewCustomFaasChange && handleNewCustomFaasChange();
      backToList();
      dispatch(getCustomFaasesListAsyncThunk(customFaas.strategy));
      dispatch(clearCreateCustomFaas());
    }

    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      backToDetailed && backToDetailed();
      dispatch(getCustomFaasesListAsyncThunk(customFaas.strategy));
      dispatch(clearUpdateCustomFaas());
    }
  });

  const [customFaasApiErrors, setCustomFaasApiErrors] =
    useCustomFaasApiErrors();

  const lambdaTypeOptions = [
    {
      inputDisplay: 'Import',
      value: 'IMPORT',
      dropdownDisplay: <H3>Import</H3>,
    },
    {
      inputDisplay: 'Report',
      value: 'REPORT',
      dropdownDisplay: <H3>Report</H3>,
    },
    {
      inputDisplay: 'Integration',
      value: 'INTEGRATION',
      dropdownDisplay: <H3>Integration</H3>,
    },
  ];

  return (
    <FormContainer
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>Name</H3>}
        isInvalid={customFaasApiErrors.name ? true : false}
        error={customFaasApiErrors.name}
      >
        <EuiFieldText
          placeholder="write here"
          value={customFaas.name}
          onChange={(e) => {
            setCustomFaas({
              ...customFaas,
              name: e.target.value,
            });

            customFaasApiErrors.name &&
              setCustomFaasApiErrors({
                ...customFaasApiErrors,
                name: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>Type</H3>}
        isInvalid={customFaasApiErrors.lambda_type ? true : false}
        error={customFaasApiErrors.lambda_type}
      >
        <EuiSuperSelect
          placeholder="name"
          valueOfSelected={customFaas.lambda_type}
          options={lambdaTypeOptions}
          onChange={(e) => {
            setCustomFaas({
              ...customFaas,
              lambda_type: e as LambdaTypeEnum,
            });

            customFaasApiErrors.lambda_type &&
              setCustomFaasApiErrors({
                ...customFaasApiErrors,
                lambda_type: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>Service endpoint</H3>}
        isInvalid={customFaasApiErrors.faas_endpoint ? true : false}
        error={customFaasApiErrors.faas_endpoint}
      >
        <EuiFieldText
          placeholder="write here"
          value={customFaas.faas_endpoint}
          onChange={(e) => {
            setCustomFaas({
              ...customFaas,
              faas_endpoint: e.target.value,
            });

            customFaasApiErrors.faas_endpoint &&
              setCustomFaasApiErrors({
                ...customFaasApiErrors,
                faas_endpoint: undefined,
              });
          }}
        />
      </FormRow>

      {createNew && (
        <PresetCreateButton>
          <Button type="submit">Create</Button>
        </PresetCreateButton>
      )}
    </FormContainer>
  );
};

export default CustomFaasForm;

import React, { useEffect, useRef, useState } from 'react';
import {
  BottomContainer,
  LeftPanel,
  RightPanel,
  StyledPanel,
  StyledPanelSmallText,
  TabButton,
  TextPreviewContainer,
  TextsContainer,
  TokenDisplayContainer,
  BotStatementEditContainer,
  TokensContainer,
  TokensOptionsContainer,
  TopContainer,
} from './TokenInput.style';
import {
  EuiDragDropContext,
  EuiDroppable,
  EuiDraggable,
  DropResult,
  EuiToolTip,
} from '@elastic/eui';
import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_40,
  H4,
  H6,
} from '../../../../App.style';
import { TrashIcon } from '../../../../../resources/icons-new/TrashIcon';
import { EditIcon } from '../../../../../resources/icons-new/EditIcon';
import TokenSelector from './TokenSelector';
import { DialogueToken } from '../../configuration/tokenInputComponents/BotsTokens';
import TextToken from './TextToken';
import { WarningIcon } from '../../../../../resources/icons-new/WarningIcon';
import { WandIcon } from '../../../../../resources/icons-new/WandIcon';

interface BotStatementEditProps {
  dialogueTokens: DialogueToken[];
  setDialogueTokens: React.Dispatch<React.SetStateAction<DialogueToken[]>>;
  customTokens: string[];
  renderInputText: () => JSX.Element[];
}
const BotStatementEdit: React.FC<BotStatementEditProps> = ({
  dialogueTokens,
  setDialogueTokens,
  customTokens,
  renderInputText,
}) => {
  const handleAddItem = (newItem: DialogueToken) => {
    const newToken: DialogueToken = {
      ...newItem,
      id: newItem.id + dialogueTokens.length,
    };
    setDialogueTokens([...dialogueTokens, newToken]);
  };

  //right container
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // Check if the destination is null (dragging outside of a droppable area)
    if (!destination) {
      return;
    }

    const itemsCopy = [...dialogueTokens];
    const [reorderedItem] = itemsCopy.splice(source.index, 1);
    itemsCopy.splice(destination.index, 0, reorderedItem);

    setDialogueTokens(itemsCopy);
  };

  const handleDeleteItem = (index: number) => {
    setDialogueTokens(dialogueTokens.filter((item, i) => i !== index));
  };

  const [selectedTab, setSelectedTab] = useState<
    | 'text'
    | 'text_by_bot_gender'
    | 'text_by_caller_gender'
    | 'custom'
    | 'token'
    | 'token_pause'
  >('token');

  function transformGenderText(input: string) {
    const parts = input.split(' / ');
    if (parts.length === 2) {
      return (
        <H4>
          {parts[0]} <span style={{ color: COLOR_NEUTRAL_40 }}>(m)</span> /{' '}
          {parts[1]} <span style={{ color: COLOR_NEUTRAL_40 }}>(k)</span>
        </H4>
      );
    }
    return input;
  }

  const endOfListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfListRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [dialogueTokens]);

  const renderText = (
    text: string,
    type:
      | 'text'
      | 'token'
      | 'text_by_bot_gender'
      | 'text_by_caller_gender'
      | 'pause'
      | 'custom'
      | 'custom_verbatim',
  ) => {
    if (type === 'text') {
      return <H4 $wrap>{text}</H4>;
    } else if (type === 'token') {
      return <H4 style={{ color: '#127FBF', fontWeight: 500 }}>{text}</H4>;
    } else if (type === 'text_by_bot_gender') {
      return (
        <>
          <StyledPanelSmallText>
            <H6 style={{ color: '#127FBF' }}>
              text dependent on the bot's gender
            </H6>
          </StyledPanelSmallText>
          <H4>{transformGenderText(text)}</H4>
        </>
      );
    } else if (type === 'text_by_caller_gender') {
      return (
        <>
          <StyledPanelSmallText>
            <H6 style={{ color: '#127FBF' }}>
              text dependent on the caller's gender
            </H6>
          </StyledPanelSmallText>
          <H4>{transformGenderText(text)}</H4>
          <EuiToolTip
            content="Przed identyfikacją bot może nie znać płci rozmówcy. Na tym etapie dialogu zalecamy zastosować formę bezosobową"
            position="top"
          >
            <WarningIcon style={{ marginLeft: 8 }} color="#FF0000" />
          </EuiToolTip>
        </>
      );
    } else if (type === 'custom' || type === 'custom_verbatim') {
      return (
        <>
          <StyledPanelSmallText>
            <H6 style={{ color: '#127FBF' }}>custom token</H6>
            <WandIcon style={{ width: 7, height: 7 }} color="#127FBF" />
          </StyledPanelSmallText>
          <H4>{text}</H4>
          {type === 'custom_verbatim' && (
            <H4 style={{ fontStyle: 'italic', fontWeight: 300, marginLeft: 4 }}>
              / przeliterowany
            </H4>
          )}
        </>
      );
    }
  };

  return (
    <BotStatementEditContainer>
      <TopContainer>
        <LeftPanel>
          <H4 $medium>Dodaj</H4>

          <TokensContainer>
            <TokensOptionsContainer>
              <H4 style={{ marginRight: 8 }}>Tokeny</H4>

              <TabButton
                type="button"
                $selected={selectedTab === 'token'}
                onClick={() => setSelectedTab('token')}
              >
                Zmienna kontekstowa
              </TabButton>
              <TabButton
                type="button"
                $selected={selectedTab === 'custom'}
                onClick={() => setSelectedTab('custom')}
              >
                Custom
              </TabButton>
              <TabButton
                type="button"
                $selected={selectedTab === 'token_pause'}
                onClick={() => setSelectedTab('token_pause')}
              >
                Pauza
              </TabButton>
            </TokensOptionsContainer>
            {selectedTab === 'token' && (
              <TokenSelector addToken={handleAddItem} />
            )}
            {selectedTab === 'token_pause' && (
              <TokenSelector addToken={handleAddItem} pauseTokens />
            )}
            {selectedTab === 'custom' && (
              <TokenSelector
                addToken={handleAddItem}
                customTokens={customTokens}
              />
            )}
          </TokensContainer>

          <TextsContainer>
            <TokensOptionsContainer>
              <H4 style={{ marginRight: 8 }}>Tekst</H4>

              <TabButton
                type="button"
                $selected={selectedTab === 'text'}
                onClick={() => setSelectedTab('text')}
              >
                Stały
              </TabButton>
              <TabButton
                type="button"
                $selected={selectedTab === 'text_by_bot_gender'}
                onClick={() => setSelectedTab('text_by_bot_gender')}
              >
                Zmienny wg płci bota
              </TabButton>
              <TabButton
                type="button"
                $selected={selectedTab === 'text_by_caller_gender'}
                onClick={() => setSelectedTab('text_by_caller_gender')}
              >
                Zmienny wg płci rozmówcy
              </TabButton>
            </TokensOptionsContainer>

            {selectedTab === 'text' && (
              <TextToken addToken={handleAddItem} type="text" />
            )}
            {selectedTab === 'text_by_bot_gender' && (
              <TextToken addToken={handleAddItem} type="text_by_bot_gender" />
            )}
            {selectedTab === 'text_by_caller_gender' && (
              <TextToken
                addToken={handleAddItem}
                type="text_by_caller_gender"
              />
            )}
          </TextsContainer>
        </LeftPanel>

        <RightPanel>
          <H4 style={{ marginTop: 8, marginLeft: 8, marginBottom: 8 }}>Ułóż</H4>
          <EuiDragDropContext onDragEnd={onDragEnd}>
            <EuiDroppable
              droppableId="DROPPABLE_AREA"
              spacing="m"
              withPanel
              style={{
                background: COLOR_NEUTRAL_10,
                height: 270,
                maxWidth: 500,
                overflow: 'auto',
              }}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {dialogueTokens.map((item, index) => (
                    <EuiDraggable
                      spacing="s"
                      key={item.id}
                      index={index}
                      draggableId={item.id}
                    >
                      {(provided, state) => (
                        <TokenDisplayContainer>
                          <StyledPanel hasShadow={state.isDragging}>
                            {renderText(item.displayText, item.type)}

                            {state.isDragging && ' ✨'}

                            {item.type === 'text' && (
                              <EditIcon
                                $pointer
                                onClick={(e) => {
                                  e.stopPropagation();
                                  alert('edit??');
                                }}
                                style={{
                                  position: 'absolute',
                                  top: 15,
                                  right: 15,
                                }}
                              />
                            )}
                          </StyledPanel>
                          <TrashIcon
                            $pointer
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteItem(index);
                            }}
                          />
                        </TokenDisplayContainer>
                      )}
                    </EuiDraggable>
                  ))}
                  <div ref={endOfListRef} />
                </div>
              )}
            </EuiDroppable>
          </EuiDragDropContext>
        </RightPanel>
      </TopContainer>
      <BottomContainer>
        <H4 $bold>Podgląd</H4>
        <TextPreviewContainer>{renderInputText()}</TextPreviewContainer>
      </BottomContainer>
    </BotStatementEditContainer>
  );
};

export default BotStatementEdit;

import { EuiToolTip } from '@elastic/eui';
import React from 'react';
import { InfoIcon } from '../../../../resources/icons-new/InfoIcon';
import { H3 } from '../../../App.style';
import { FormRowLabel } from './ProcessForm.style';

interface FormRowTitleProps {
  title: string;
  tooltip?: string;
}

const FormRowTitle: React.FC<FormRowTitleProps> = ({ title, tooltip }) => {
  return (
    <FormRowLabel>
      <H3>{title}</H3>
      {tooltip && (
        <EuiToolTip
          position="top"
          content={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {tooltip}
            </div>
          }
        >
          <InfoIcon />
        </EuiToolTip>
      )}
    </FormRowLabel>
  );
};

export default FormRowTitle;

import { CSSProperties, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { COLOR_NEUTRAL_40 } from '../../app/App.style';

export interface IconProps {
  color?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<SVGSVGElement>;
  $pointer?: boolean;
  $disabled?: boolean;
}

export const StyledSvg = styled.svg<IconProps>`
  fill: ${(props) => props.color || '#1a1a1a'};
  cursor: ${(props) => props.$pointer && 'pointer'};

  ${(props) =>
    props.$disabled &&
    `
      fill: ${COLOR_NEUTRAL_40}; 
      cursor: 'not-allowed';
      `}
`;

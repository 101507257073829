import { EuiDatePicker, EuiFieldText } from '@elastic/eui';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, H3 } from '../../../../App.style';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../../processes/forms/ProcessForm.style';
import {
  DaysOffDatesContainer,
  DayOffContainer,
  TrashIconContainer,
  CalendarIconContainer,
} from '../DaysOff.style';
import {
  CallCenterNonWorkingDaysConfiguration,
  CallCenterNonWorkingDaysConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  checkCallCenterDaysOffCreate,
  checkCallCenterDaysOffUpdate,
  clearCreateCallCeterDaysOff,
  clearUpdateCallCenterDaysOff,
  getCallCenterDaysOffListAsyncThunk,
} from './api/callCentreDaysOffSlice';
import moment from 'moment';
import { ApiError } from '../../../../../common/types';
import { CalendarIcon } from '../../../../../resources/icons-new/CalendarIcon';
import { TrashIcon } from '../../../../../resources/icons-new/TrashIcon';
import { useTranslation } from 'react-i18next';

const useCallCenterDaysOffApiErrors = () => {
  const checkCreate = useAppSelector(checkCallCenterDaysOffCreate);
  const createErrors = checkCreate?.value?.error;

  const checkUpdate = useAppSelector(checkCallCenterDaysOffUpdate);
  const updateErrors = checkUpdate?.value?.error;

  const [callCenterDaysOffApiErrors, setCallCenterDaysOffApiErrors] = useState<
    ApiError<CallCenterNonWorkingDaysConfiguration>
  >({} as ApiError<CallCenterNonWorkingDaysConfiguration>);

  useEffect(() => {
    if (createErrors)
      setCallCenterDaysOffApiErrors((prevErrors) => ({
        ...prevErrors,
        ...createErrors,
      }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setCallCenterDaysOffApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [callCenterDaysOffApiErrors, setCallCenterDaysOffApiErrors] as const;
};

interface CallCenterDaysOffProps {
  callCenterDaysOff: CallCenterNonWorkingDaysConfigurationRequest;
  setCallCenterDaysOff: React.Dispatch<
    React.SetStateAction<CallCenterNonWorkingDaysConfigurationRequest>
  >;
  button?: () => void;
  createNew?: boolean;
  backToList: () => void;
  backToDetailed?: () => void;
  handleNewCallCenterDaysOffChange?: () => void;
  buttonDown?: boolean;
}

const CallCenterDaysOffForm: React.FC<CallCenterDaysOffProps> = ({
  callCenterDaysOff,
  setCallCenterDaysOff,
  button,
  createNew,
  backToList,
  backToDetailed,
  handleNewCallCenterDaysOffChange,
  buttonDown,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const checkCreate = useAppSelector(checkCallCenterDaysOffCreate);
  const checkUpdate = useAppSelector(checkCallCenterDaysOffUpdate);

  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewCallCenterDaysOffChange && handleNewCallCenterDaysOffChange();

      dispatch(getCallCenterDaysOffListAsyncThunk(callCenterDaysOff.strategy));
      dispatch(clearCreateCallCeterDaysOff());
      backToList();
    }

    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      dispatch(getCallCenterDaysOffListAsyncThunk(callCenterDaysOff.strategy));
      dispatch(clearUpdateCallCenterDaysOff());
      backToDetailed && backToDetailed();
    }
  });

  const handleChange = (date: Moment) => {
    const dateString = date.format('YYYY-MM-DD');

    setCallCenterDaysOff((prevState) => {
      if (prevState.holidays && prevState.holidays.includes(dateString)) {
        alert(t('presets.daysOff.dateAlreadyAdded'));
        return prevState;
      }

      const newHolidays = [...(prevState.holidays || []), dateString];
      newHolidays.sort();

      return {
        ...prevState,
        holidays: newHolidays,
      };
    });
  };

  const handleRemove = (indexToRemove: number) => {
    setCallCenterDaysOff((prevState) => ({
      ...prevState,
      holidays: prevState.holidays
        ? prevState.holidays.filter((_, index) => index !== indexToRemove)
        : [],
    }));
  };

  const [callCenterDaysOffApiErrors, setCallCenterDaysOffApiErrors] =
    useCallCenterDaysOffApiErrors();

  return (
    <FormContainer
      $processForm={buttonDown}
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>{t('presets.daysOff.name')}</H3>}
        isInvalid={callCenterDaysOffApiErrors.name ? true : false}
        error={callCenterDaysOffApiErrors.name}
      >
        <EuiFieldText
          isInvalid={callCenterDaysOffApiErrors.name ? true : false}
          value={callCenterDaysOff.name}
          onChange={(e) => {
            setCallCenterDaysOff({
              ...callCenterDaysOff,
              name: e.target.value,
            });

            callCenterDaysOffApiErrors.name &&
              setCallCenterDaysOffApiErrors({
                ...callCenterDaysOffApiErrors,
                name: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow label={<H3>{t('presets.daysOff.holidaysDate')}</H3>}>
        <DaysOffDatesContainer>
          {callCenterDaysOff.holidays && callCenterDaysOff.holidays.length ? (
            callCenterDaysOff.holidays.map((date, index) => {
              return (
                <DayOffContainer key={`date-${index}`}>
                  <H3>{moment(date).format('D MMMM YYYY')}</H3>
                  <TrashIconContainer onClick={() => handleRemove(index)}>
                    <TrashIcon />
                  </TrashIconContainer>
                </DayOffContainer>
              );
            })
          ) : (
            <div>{t('presets.daysOff.nothingToShow')}</div>
          )}
          <DayOffContainer>
            <CalendarIconContainer>
              <EuiDatePicker
                selected={null}
                onChange={handleChange}
                css={{
                  background: 'transparent',
                  position: 'absolute',
                  boxShadow: 'none',
                  height: 30,
                  width: 30,
                  zIndex: 5,
                }}
                customInput={<div></div>}
              />
              <CalendarIcon
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 5,
                  width: 20,
                  height: 20,
                  zIndex: 0,
                }}
              />
            </CalendarIconContainer>
          </DayOffContainer>
        </DaysOffDatesContainer>
      </FormRow>

      {createNew && !buttonDown ? (
        <PresetCreateButton>
          <Button type="submit">{t('common.create')}</Button>
        </PresetCreateButton>
      ) : (
        buttonDown && <Button type="submit">{t('common.create')}</Button>
      )}
    </FormContainer>
  );
};

export default CallCenterDaysOffForm;

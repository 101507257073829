import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const SuccessIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21924 12.2808L6.22016 12.2817C6.36641 12.4265 6.55823 12.5 6.75002 12.5C6.94181 12.5 7.13363 12.4265 7.27989 12.2817L14.2813 5.28034C14.5733 4.98682 14.5733 4.51322 14.2813 4.21969L14.2803 4.21879C13.9868 3.92676 13.5128 3.92723 13.2192 4.21925L6.75003 10.6894L3.28035 7.21879C2.98682 6.92676 2.51322 6.92676 2.2197 7.21879L2.2188 7.21969C1.92677 7.51322 1.92722 7.98727 2.21925 8.28079L6.21924 12.2808Z"
    />
  </StyledSvg>
);

import { EuiModalBody, EuiFormRow, EuiFieldText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { H3 } from '../../../../App.style';
import { selectStrategyId } from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkProcessesPanelColumnCreate,
  getProcessesPanelColumnsAsyncThunk,
} from '../../api/processSlice';
import Modal from '../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface AddColumnModalProps {
  isVisible: boolean;
  closeModal: () => void;
  addNewColumn: (columnName: string) => void;
}

const AddColumnModal: React.FC<AddColumnModalProps> = ({
  isVisible,
  closeModal,
  addNewColumn,
}) => {
  const { t } = useTranslation();
  const strategyId = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const [newColumnName, setNewColumnName] = useState<string>('');

  useEffect(() => {
    setApiError(false);
    setNewColumnName('');
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addNewColumn(newColumnName);

    return false;
  };

  const checkAddColumn = useAppSelector(checkProcessesPanelColumnCreate);

  //if new column is added, refresh list
  useEffect(() => {
    if (
      checkAddColumn.state === 'idle' &&
      checkAddColumn.value?.status === 'success'
    ) {
      dispatch(getProcessesPanelColumnsAsyncThunk(strategyId));
      closeAddColumnModal();
    } else if (checkAddColumn.state === 'idle' && checkAddColumn.value?.error) {
      setApiError(true);
    }
  }, [checkAddColumn]);

  const [apiError, setApiError] = useState<boolean>(false);
  const [lengthError, setLengthError] = useState<boolean>(false);

  const closeAddColumnModal = () => {
    setNewColumnName('');
    closeModal();
  };

  const maxColumnNameLength = 16;

  useEffect(() => {
    newColumnName.length > maxColumnNameLength
      ? setLengthError(true)
      : setLengthError(false);
  }, [newColumnName]);

  const buttonDisabled = newColumnName === '' || apiError || lengthError;

  const body = (
    <EuiModalBody>
      <EuiFormRow
        isInvalid={apiError || lengthError}
        error={
          apiError
            ? t('processes.modals.nameInUse')
            : lengthError && t('processes.modals.lessThan')
        }
        label={<H3>{t('processes.modals.name')}</H3>}
      >
        <EuiFieldText
          isInvalid={apiError || lengthError}
          value={newColumnName}
          autoFocus
          onChange={(e) => {
            setNewColumnName(e.target.value);
            apiError && setApiError(false);
          }}
        />
      </EuiFormRow>
    </EuiModalBody>
  );
  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('processes.modals.createNewColumn')}
      onConfirm={handleSubmit}
      onCancel={closeAddColumnModal}
      children={body}
      buttonDisabled={buttonDisabled}
    />
  );
};

export default AddColumnModal;

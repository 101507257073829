import React, { useEffect, useState } from 'react';
import TenantCreate from './TenantCreate';
import TenantsList from './TenantsList';
import TenantDetailed from './TenantDetailed';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { Company } from '../../../../../../generated/public/Api';
import { TenantsContainer } from './Tenants.style';
import {
  getTenantsListAsyncThunk,
  selectTenantsList,
} from '../../api/superAdminSlice';
import {
  redirectToTenant,
  selectUser,
} from '../../../../session/api/sessionSlice';

const Tenants = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (user.value) {
      const userValue = user.value;
      if (
        !userValue.is_super_admin &&
        !userValue.is_supervisor &&
        userValue.companies.length > 0
      ) {
        dispatch(redirectToTenant(userValue.companies[0].slug));
      }
    }
  }, [user]);

  useEffect(() => {
    dispatch(getTenantsListAsyncThunk());
  }, []);

  const apiTenantsList = useAppSelector(selectTenantsList);

  const [tenantState, setTenantState] = useState<
    'create' | 'list' | 'detailed'
  >('list');

  const [selectedTenant, setSelectedTenant] = useState<Company>();

  const handleCreateTenantClick = () => setTenantState('create');

  const handleDetailedViewClick = (tenantId: string) => {
    const tenantById = apiTenantsList.find((tenant) => tenant.id === tenantId);
    if (tenantById) setSelectedTenant(tenantById);
    setTenantState('detailed');
  };

  const handleBackToList = () => {
    setTenantState('list');
  };

  const componentsMap = {
    create: <TenantCreate backToList={handleBackToList} />,
    list: (
      <TenantsList
        tenantsList={apiTenantsList}
        createTenant={handleCreateTenantClick}
        goToDetailed={handleDetailedViewClick}
      />
    ),
    detailed: selectedTenant && (
      <TenantDetailed tenant={selectedTenant} backToList={handleBackToList} />
    ),
  };

  return <TenantsContainer>{componentsMap[tenantState]}</TenantsContainer>;
};

export default Tenants;

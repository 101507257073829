import styled, { css } from 'styled-components';
import {
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_20,
  COLOR_PRIMARY_2,
  COLOR_PRIMARY_1,
  COLOR_NEUTRAL_10,
} from '../../App.style';

export const ReportsPanelContainer = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: auto;

  position: relative;

  background: #ffffff;

  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;

export const ReportsBarContainer = styled.div`
  width: 100%;

  z-index: 10;

  flex-direction: column;

  display: flex;
  justify-content: center;
`;

export const ReportFormContainer = styled.form`
  width: 450px;
`;

export const ReportFormLeftContainer = styled.div`
  width: 150px;
`;
export const ReportFormRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 300px;
`;

export const SuperSelectContainer = styled.div`
  position: relative;
`;

interface ReportButtonProps {
  disabled?: boolean;
}

export const ReportButton = styled.div<ReportButtonProps>`
  position: absolute;
  height: 40px;

  padding: 12px 14px;
  display: flex;
  justify-content: space-between;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  align-items: center;
  z-index: 10;

  &:disabled {
  }
`;

export const ReportTypeContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const FileFormatContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const ReportFiltersContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const ColumnsInReportContainer = styled.div`
  margin-top: 30px;
  display: flex;
`;

export const GenerateReportButtonContainer = styled.div`
  margin-top: 25px;

  display: flex;
  justify-content: flex-end;

  gap: 16px;
`;

//popup
export const ReportPopupContainer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 400px;
  z-index: 10;
  background: ${COLOR_PRIMARY_2};
  border: 1px solid ${COLOR_PRIMARY_1};
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 11px;
`;

export const ReportPopupCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 7px;
  cursor: pointer;
`;

export const ReportPopupContent = styled.div`
  display: flex;
  gap: 8px;
`;

//ReportsCampaignsInput

export const ReportsCampaignInputContainer = styled.div`
  position: relative;
`;

export const CampaignsInputContainer = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  padding-left: 35px;
  border-radius: 5px;
  border: 1px solid ${COLOR_NEUTRAL_40};
`;

export const SelectedCampaignsEmpty = styled.div`
  width: 100%;
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  gap: 8px;
  border-radius: 5px;

  cursor: pointer;

  border: 1px solid ${COLOR_NEUTRAL_20};

  &:hover {
    background: ${COLOR_PRIMARY_2};
  }
`;

export const SelectedCampaignsDiv = styled.div<{ analytics?: boolean }>`
  padding: 12px 12px;
  cursor: pointer;
  position: relative;

  ${({ analytics }) =>
    analytics &&
    css`
      display: flex;
      max-width: 250px;
      overflow: hidden;
    `}

  border: ${({ analytics }) =>
    analytics ? '1px solid #CBD2D9' : `1px solid ${COLOR_PRIMARY_1}`};
  border-radius: 5px;
`;

export const SelectedCampaignsArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 0px;
  width: 20px;
  height: 16px;
  transform: translateY(-50%);

  background: white;
`;

export const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  width: 350px;

  max-height: 350px;
  overflow-y: auto;
  box-shadow:
    0px 15px 15px -1px rgba(0, 0, 0, 0.04),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08);

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  z-index: 1;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;

  box-shadow: 0px -1px 0px 0px rgba(211, 218, 230, 0.5) inset;

  &:hover {
    background: ${COLOR_NEUTRAL_10};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px 16px;
  gap: 12px;
`;

//Report csv columns
export const ReportColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReportColumnCheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;

  cursor: pointer;
`;

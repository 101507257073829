import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const DialoguePresetIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M3.17999 12.9366C3.42983 13.1871 3.55494 13.536 3.52121 13.8883C3.44564 14.6776 3.27158 15.4991 3.04804 16.2622C4.70447 15.8794 5.71612 15.4353 6.17597 15.2022C6.43699 15.07 6.73766 15.0387 7.02029 15.1145C7.80148 15.324 8.63403 15.4375 9.5 15.4375C14.2448 15.4375 17.8125 12.1035 17.8125 8.3125C17.8125 4.52146 14.2448 1.1875 9.5 1.1875C4.75523 1.1875 1.1875 4.52146 1.1875 8.3125C1.1875 10.0559 1.92055 11.6736 3.17999 12.9366ZM2.59456 17.5737C2.58656 17.5753 2.57856 17.5769 2.57053 17.5785C2.46149 17.6 2.35032 17.6211 2.23703 17.6419C2.07812 17.671 1.91501 17.6993 1.74767 17.7267C1.51134 17.7654 1.33043 17.5182 1.42379 17.2976C1.48478 17.1535 1.5449 17.0025 1.60348 16.846C1.64069 16.7465 1.67728 16.6448 1.71307 16.5412C1.71461 16.5367 1.71615 16.5322 1.71769 16.5278C2.01189 15.6731 2.25146 14.6907 2.33912 13.7751C0.882385 12.3142 0 10.4035 0 8.3125C0 3.72163 4.25329 0 9.5 0C14.7467 0 19 3.72163 19 8.3125C19 12.9034 14.7467 16.625 9.5 16.625C8.5301 16.625 7.59415 16.4978 6.71274 16.2615C6.09587 16.5741 4.76703 17.1431 2.59456 17.5737Z" />
    <path d="M4.75 5.34375C4.75 5.01583 5.01583 4.75 5.34375 4.75H13.6562C13.9842 4.75 14.25 5.01583 14.25 5.34375C14.25 5.67167 13.9842 5.9375 13.6562 5.9375H5.34375C5.01583 5.9375 4.75 5.67167 4.75 5.34375ZM4.75 8.3125C4.75 7.98458 5.01583 7.71875 5.34375 7.71875H13.6562C13.9842 7.71875 14.25 7.98458 14.25 8.3125C14.25 8.64042 13.9842 8.90625 13.6562 8.90625H5.34375C5.01583 8.90625 4.75 8.64042 4.75 8.3125ZM4.75 11.2812C4.75 10.9533 5.01583 10.6875 5.34375 10.6875H10.0938C10.4217 10.6875 10.6875 10.9533 10.6875 11.2812C10.6875 11.6092 10.4217 11.875 10.0938 11.875H5.34375C5.01583 11.875 4.75 11.6092 4.75 11.2812Z" />
  </StyledSvg>
);

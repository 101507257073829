import React from 'react';
import {
  CampaignsBarContainer,
  TopContainer,
  BottomContainer,
  SearchContainer,
} from './CampaignsBar.style';
import SearchInput from '../../../../../common/inputs/SearchInput';
import CampaignsFilters, {
  CampaingsFiltersValues,
} from './filters/CampaignsFilters';
import { Process } from '../../../../../generated/tenants/Api';
import { Button, H1 } from '../../../../App.style';
import { useTranslation } from 'react-i18next';

interface CampaignsBarProps {
  filters: CampaingsFiltersValues;
  setFilters: React.Dispatch<React.SetStateAction<CampaingsFiltersValues>>;
  processList: Process[];
  showCampaignCreateModal: () => void;
  hasCampaignAccess: boolean;
}

const CampaignsBar: React.FC<CampaignsBarProps> = ({
  filters,
  setFilters,
  processList,
  showCampaignCreateModal,
  hasCampaignAccess,
}) => {
  const { t } = useTranslation();

  return (
    <CampaignsBarContainer>
      <TopContainer>
        <H1 $medium>{t('campaigns.bar.campaigns')}</H1>
        {hasCampaignAccess && (
          <Button $size={'S'} onClick={showCampaignCreateModal}>
            {t('campaigns.bar.newCampaign')}
          </Button>
        )}
        <SearchContainer>
          <SearchInput
            value={filters.search}
            setValue={(e) => setFilters({ ...filters, search: e })}
          />
        </SearchContainer>
      </TopContainer>
      <BottomContainer>
        <CampaignsFilters
          filters={filters}
          setFilters={setFilters}
          processList={processList}
        />
      </BottomContainer>
    </CampaignsBarContainer>
  );
};

export default CampaignsBar;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../common/hooks';
import {
  CampaignNonWorkingDaysConfiguration,
  CampaignNonWorkingDaysConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import { CreatePresetContainer } from '../../PresetsPanel.style';
import { createBotDaysOffAsyncThunk } from './api/botDaysOffSlice';
import BotDaysOffForm from './BotDaysOffForm';
import LoadingModal from '../../../../../common/modals/LoadingModal';

interface BotCreateProps {
  botDaysOff?: CampaignNonWorkingDaysConfiguration;
  strategy: string;
  backToList: () => void;
  handleNewBotDaysOffChange?: () => void;
  formButtonDown?: boolean;
}

const BotDaysOffCreate: React.FC<BotCreateProps> = ({
  botDaysOff,
  strategy,
  backToList,
  handleNewBotDaysOffChange,
  formButtonDown,
}) => {
  const dispatch = useAppDispatch();
  const [newBotDaysOff, setNewBotDaysOff] =
    useState<CampaignNonWorkingDaysConfigurationRequest>({
      name: '',
      strategy: strategy,
      holidays: [],
    });

  useEffect(() => {
    if (botDaysOff)
      setNewBotDaysOff({ ...botDaysOff, name: `${botDaysOff.name}-modified` });
  }, [botDaysOff]);

  const handleAddBotDaysOffClick = () => {
    setIsLoadingVisible(true);
    dispatch(createBotDaysOffAsyncThunk(newBotDaysOff)).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <CreatePresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <BotDaysOffForm
        botDaysOff={newBotDaysOff}
        setBotDaysOff={setNewBotDaysOff}
        button={handleAddBotDaysOffClick}
        createNew
        backToList={backToList}
        handleNewBotDaysOffChange={handleNewBotDaysOffChange}
        buttonDown={formButtonDown}
      />
    </CreatePresetContainer>
  );
};

export default BotDaysOffCreate;

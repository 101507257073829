import styled from 'styled-components';
import { COLOR_NEUTRAL_20, COLOR_NEUTRAL_40 } from '../../../../../App.style';

export const DialogueVerificationQuestionsContainer = styled.div`
  border-radius: 5px;
  padding: 16px;
  background: ${COLOR_NEUTRAL_20};
  border: 1px solid ${COLOR_NEUTRAL_40};
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 800px;

  position: relative;
`;

export const DialogueVerificationQuestionsTitle = styled.div`
  width: 60%;
`;

export const DialogueVerificationQuestionsContentContainer = styled.div``;

export const DialogueVerificationQuestionsRandom = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

//wtf?

export const DialogueVerificationQuestionContainer = styled.div``;

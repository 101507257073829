import {
  EuiButton,
  EuiConfirmModal,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTextColor,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { OldContentContainer } from '../../App.style';
import BotsList from './configuration/BotsList';
import {
  getDialoguesListAsyncThunk,
  selectDialoguesList,
} from '../presets/dialogues/api/dialoguesSlice';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';

export const clearStorage = () => {
  localStorage.setItem('vinCompletedSteps', '[]');
  localStorage.setItem('lastVisitedStep', '');

  const storageItems: string[] = [
    'lastVisitedStep',
    'vinIdentificationGenerationsConfig',
    'vinVerificationBotStrategyConfig',
    'vinVerificationGenerationsConfig',
    'vinIdentificationBotStrategyConfig',
    'vinPaymentStatusBotStrategyConfig',
    'vinPaymentStatusGenerationsConfig',
    'vinNegotiationsBotStrategyConfig',
    'vinNegotiationsGenerationsConfig',
    'vinNegotiationsRuntimeParameters',
    'vinAuxModulesGenerationsConfig',
    'vinAuxModulesBotStrategyConfig',
    'botName',
    'botConfigurationType',
    'botConfigurationId',
  ];
  //remove items
  storageItems.forEach((item) => localStorage.removeItem(item));
};

export const BotsStartPanel = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const [isDeleteBotModalVisible, setIsDeleteBotModalVisible] = useState(false);
  const [isOverwriteConfigModalVisible, setIsOverwriteConfigModalVisible] =
    useState(false);
  const [botName, setBotName] = useState<string>('');
  const [botNameError, setBotNameError] = useState<{
    empty: boolean;
    alreadyUsed: boolean;
  }>({ empty: false, alreadyUsed: false });

  useEffect(() => {
    dispatch(getDialoguesListAsyncThunk(strategy));
  }, [isDeleteBotModalVisible]);
  const botsConfigList = useAppSelector(selectDialoguesList);
  const botNames = botsConfigList.map((bot) => {
    return bot.name;
  });

  const [botConfigurationType, setBotConfigurationType] = useState<string>('');

  const navigate = useNavigate();

  const handleStartCreatingNewBot = () => {
    if (botNames.includes(botName)) {
      setBotNameError({ ...botNameError, alreadyUsed: true });
    } else if (botName == '') {
      setBotNameError({ ...botNameError, empty: true });
    } else if (botConfigurationType == '') {
      navigate('/dashboard/bots/new');
      localStorage.setItem('botName', botName);
      localStorage.setItem('vinCompletedSteps', '[]');
    } else {
      setIsOverwriteConfigModalVisible(true);
    }
  };

  const handleDiscardBotConfigAndCreateNew = () => {
    handleDeleteBotConfig();
    navigate('/dashboard/bots/new');
    localStorage.setItem('botName', botName);
    localStorage.setItem('vinCompletedSteps', '[]');
  };

  const handleDeleteBotConfig = () => {
    clearStorage();
    setBotConfigurationType('');
    setIsDeleteBotModalVisible(false);
    setIsOverwriteConfigModalVisible(false);
  };

  const [lastVisitedStep, setLastVisitedStep] = useState<string>('');

  useEffect(() => {
    const lastStep = localStorage.getItem('lastVisitedStep');
    const botConfigurationType = localStorage.getItem('botConfigurationType');

    if (
      lastStep !== '' &&
      lastStep !== null &&
      botConfigurationType !== '' &&
      botConfigurationType !== null
    ) {
      setLastVisitedStep(JSON.parse(lastStep));
      setBotConfigurationType(botConfigurationType);
    }
  }, []);

  const handleContinueCreatingBot = () => {
    navigate(`/dashboard/bots/new/1/${lastVisitedStep}`);
  };

  let deleteModal;

  if (isDeleteBotModalVisible) {
    deleteModal = (
      <EuiConfirmModal
        title="Deleting bot configuration"
        onCancel={() => setIsDeleteBotModalVisible(false)}
        onConfirm={handleDeleteBotConfig}
        cancelButtonText="No, don't do it"
        confirmButtonText="Yes, do it"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>All changes in this bot config will be deleted.</p>
        <p>Are you sure you want to do this?</p>
      </EuiConfirmModal>
    );
  }

  let confirmModal;

  if (isOverwriteConfigModalVisible) {
    confirmModal = (
      <EuiConfirmModal
        title="New bot config"
        onCancel={() => setIsOverwriteConfigModalVisible(false)}
        onConfirm={handleDiscardBotConfigAndCreateNew}
        cancelButtonText="No, don't do it"
        confirmButtonText="Yes, do it"
        defaultFocusedButton="confirm"
      >
        <p>You have already started creating a bot configuration</p>
        <p>do you want to delete it and start over?</p>
      </EuiConfirmModal>
    );
  }

  return (
    <OldContentContainer>
      <>
        <EuiFlexGroup>
          {deleteModal}
          {confirmModal}
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText css={{ fontSize: 33 }}>
            Welcome to bot configuration panel
          </EuiText>
        </EuiFlexItem>
        <EuiFlexGroup
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: 30,
            fontSize: 20,
            margin: 15,
          }}
        >
          <EuiFlexItem css={{ width: '50%' }}>
            <EuiText>
              Here you can customize the behaviour of your bot. There are few
              bot configuration presets to choose from, which can be then
              customized according to your needs. This panel will guide you
              through the process.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem css={{ alignItems: 'flex-start' }}>
            <EuiText>- Choose the bot preset</EuiText>
            <EuiText>- Configure the main modules </EuiText>
            <EuiText>- Configure the common behaviors</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        {botConfigurationType !== '' && (
          <EuiFlexItem
            css={{ flexDirection: 'row', gap: 30, justifyContent: 'center' }}
          >
            <div>
              <EuiButton fill onClick={handleContinueCreatingBot}>
                {botConfigurationType === 'new'
                  ? 'Continue creating bot'
                  : 'Continue editing bot'}
              </EuiButton>
            </div>
            <div>
              <EuiButton
                color="danger"
                fill
                onClick={() => setIsDeleteBotModalVisible(true)}
              >
                Discard current configuration
              </EuiButton>
            </div>
          </EuiFlexItem>
        )}
        <EuiFlexGroup
          css={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: 30,
            gap: 10,
          }}
        >
          <div>
            <EuiFieldText
              isInvalid={botNameError.empty || botNameError.alreadyUsed}
              value={botName}
              onChange={(e) => {
                setBotName(e.target.value);
                setBotNameError({ empty: false, alreadyUsed: false });
              }}
              placeholder="bot name"
              css={{ textAlign: 'center', width: 300 }}
            />
            {botNameError.empty ? (
              <EuiTextColor color="danger">
                Bot name cannot be empty
              </EuiTextColor>
            ) : (
              botNameError.alreadyUsed && (
                <EuiTextColor color="danger">
                  Bot configuration with this name already exist
                </EuiTextColor>
              )
            )}
          </div>
          <div>
            <EuiButton
              type="submit"
              fill
              onClick={() => handleStartCreatingNewBot()}
            >
              Start creating new bot
            </EuiButton>
          </div>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiText css={{ fontSize: 30 }}>
            Have a bot already and want to modify it?
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <BotsList />
        </EuiFlexItem>
      </>
    </OldContentContainer>
  );
};

export default BotsStartPanel;

export const VindicationBackToPreset = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  let confirmModal;

  const clearStorageWhileBackToPreset = () => {
    localStorage.setItem('vinCompletedSteps', '[]');
    localStorage.removeItem('vinIdentificationGenerationsConfig');
    localStorage.removeItem('vinVerificationBotStrategyConfig');
    localStorage.removeItem('vinIdentificationBotStrategyConfig');
    localStorage.removeItem('vinVerificationGenerationsConfig');
    localStorage.removeItem('vinPaymentStatusBotStrategyConfig');
    localStorage.removeItem('vinPaymentStatusGenerationsConfig');
    localStorage.removeItem('vinNegotiationsBotStrategyConfig');
    localStorage.removeItem('vinNegotiationsGenerationsConfig');
    localStorage.removeItem('vinNegotiationsRuntimeParameters');
    localStorage.removeItem('vinAuxModulesGenerationsConfig');
    localStorage.removeItem('vinAuxModulesBotStrategyConfig');
    localStorage.removeItem('botConfigurationId');
    localStorage.removeItem('lastVisitedStep');
    localStorage.removeItem('botConfigurationType');
    navigate('/dashboard/bots/new');
  };

  if (isModalVisible) {
    confirmModal = (
      <EuiConfirmModal
        title="Back to preset"
        onCancel={() => setIsModalVisible(false)}
        onConfirm={clearStorageWhileBackToPreset}
        cancelButtonText="No, don't do it"
        confirmButtonText="Yes, do it"
        defaultFocusedButton="confirm"
      >
        <p>Are you sure you want to go back to bot presets?</p>
        <p>all changes will be deleted</p>
      </EuiConfirmModal>
    );
  }
  return (
    <>
      {confirmModal}
      <EuiButton onClick={() => setIsModalVisible(true)} fill>
        Back to preset
      </EuiButton>
    </>
  );
};

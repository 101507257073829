import React from 'react';
import { useTranslation } from 'react-i18next';
import { EuiLoadingSpinner } from '@elastic/eui';
import Modal from './Modal';

interface LoadingModalProps {
  isVisible: boolean;
  closeModal: () => void;
  title?: string;
}
const LoadingModal: React.FC<LoadingModalProps> = ({
  isVisible,
  closeModal,
  title,
}) => {
  const { t } = useTranslation();

  const loadingBody = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 20,
      }}
    >
      <EuiLoadingSpinner size={'xxl'} />
    </div>
  );
  return (
    <Modal
      headerTitle={title ?? t('common.loadingModalTitle')}
      isVisible={isVisible}
      onCancel={closeModal}
      children={loadingBody}
      noButtons={true}
    />
  );
};

export default LoadingModal;

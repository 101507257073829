import {
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_30,
  COLOR_NEUTRAL_40,
  fontIBM,
} from '../../../App.style';
import styled, { keyframes } from 'styled-components';
import { EuiDroppable, EuiDraggable } from '@elastic/eui';

export const ProcessPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;

  position: relative;

  background: #ffffff;
`;

export const ProcessesColumnsContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: 7px;

  display: flex;

  overflow: hidden;
  overflow-x: auto;
`;

export const NewColumnContainer = styled.div`
  min-width: 250px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const IconContainer = styled.div`
  width: 50px;
  height: 50px;

  border-radius: 50%;

  position: relative;

  border: 1px solid black;

  transition: all 0.3s ease-out;

  &:active {
    animation: ${clickAnimation} 0.3s ease-in-out;
  }
`;

export const CreateNewColumnIcon = styled.img`
  position: absolute;
  left: 17px;
  top: 17px;

  width: 15px;
  height: 15px;
`;

export const CreateButtonContainer = styled.div`
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-family: ${fontIBM};
  font-size: 14px;
  font-weight: 400;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:hover ${IconContainer} {
    background: #cfecfd;
    border: none;
  }

  &:hover ${CreateNewColumnIcon} {
    top: 18px;
    left: 18px;
  }
`;

export const NewProcessButtonContainer = styled.div`
  position: absolute;
  bottom: 50px;
  right: 35px;

  width: 150px;
  height: 40px;
`;

//ProcessesColumn.tsx

interface ProcessesColumnContainerProps {
  $edit: boolean;
}

export const ProcessesColumnContainer = styled.div<ProcessesColumnContainerProps>`
  display: flex;
  flex-direction: column;
  width: 340px;
  border-radius: 5px;
  padding-top: 5px;

  background: ${(props) => props.$edit && '#dee5ee'};
`;

export const ProcessesColumnHeader = styled.div`
  width: 100%;
  height: 20px;

  padding-right: 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 7px;

  position: sticky;
  top: 10px;

  padding-bottom: 8px;
`;

export const ProcessColumHeaderBorder = styled.div`
  width: 95%;
  height: 0.5px;
  background: ${COLOR_NEUTRAL_40};
  position: absolute;
  bottom: 0;
`;

export const LeftColumnHeaderContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const RightColumnHeaderContainer = styled.div`
  width: 20%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 5px;
`;

export const DroppableContainer = styled(EuiDroppable)`
  width: 340px;

  transition: none;
  overflow-y: auto;
  max-height: calc(100vh - 130px);

  scrollbar-width: none;
  scrollbar-color: red;

  margin-right: 30px;

  // Hide scrollbar for Internet Explorer, Edge, and Firefox
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // Internet Explorer and Edge

  // Hide scrollbar for Webkit browsers like Chrome, Safari
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DraggableContainer = styled(EuiDraggable)``;

//ProcessPreview.tsx

export const PreviewPanel = styled.div`
  width: 320px;
  height: 170px;

  border: 1px solid ${COLOR_NEUTRAL_30};

  background: white;
  position: relative;
  border-radius: 5px;

  margin-left: 4px;
  margin-bottom: 8px;

  box-shadow: 0px 2px 4px 0px rgba(154, 165, 177, 0.5);
`;

export const ThreeDotsContainer = styled.div`
  position: absolute;
  height: 30px;
  width: 45px;
  display: flex;

  align-items: center;
  justify-content: center;

  top: 0px;
  right: 0px;
  z-index: 1;

  cursor: pointer;
`;

export const ThreeDots = styled.img`
  width: 100%;
  height: 100%;
`;

export const ProcessPreviewContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ProcessPreviewTitleContainer = styled.div`
  width: 90%;
  margin-top: 24px;

  margin-bottom: 8px;
  display: flex;

  align-items: center;
  overflow: hidden;
  padding: 0px 16px;
  gap: 8px;
`;

export const ProcessPreviewLabelsContainer = styled.div`
  overflow-x: auto;
  width: auto;
  margin: 0px 16px;
  margin-bottom: 8px;

  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  min-height: 22px;
  max-height: 45px;
  overflow: hidden;

  position: relative;
`;

export const ProcessPreviewLabelsChevronContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;

export const ProcessPanelButton = styled.button`
  height: 25px;
  min-width: 90px;

  padding: 4px 8px;

  background: white;
  color: black;

  border-radius: 5px;

  -webkit-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  -moz-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);

  transition:
    transform 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    background: $grey;
  }
`;

export const NoProcessesContainer = styled.div`
  width: 80%;
  padding: 20px 5px;
`;

export const ColumnLenghtBox = styled.div`
  margin-left: 3px;
  display: inline-flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: ${COLOR_NEUTRAL_20};
  border-radius: 6px;
`;

export const ProcessPreviewLastModifiedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
`;

export const ProcessPreviewButtonsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLOR_NEUTRAL_30};
  cursor: pointer;
`;

export const ProcessPreviewButton = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

import React, { useEffect, useState } from 'react';
import { H4 } from '../../../../../App.style';
import {
  ArrowsContainer,
  PaginationContainer,
  PaginationText,
  TableContainer,
} from '../../../../campaigns/panel/CampaignsPanel.style';
import {
  CallsTab,
  CallsTableHeader,
  CallsTableRow,
  CallsTableData,
} from '../../../../campaigns/tabs/calls/Calls.styles';
import { Token } from '../../../../../../generated/tenants/Api';
import { TrashIcon } from '../../../../../../resources/icons-new/TrashIcon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { EuiToolTip } from '@elastic/eui';
import { ArrowIconLeft } from '../../../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../../../resources/icons-new/ArrowIconRight';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  getAccessTokensListAsyncThunk,
  selectAccessTokensList,
} from '../../../userSettings/api/userSettingsSlice';

interface AccessTokensTableProps {
  handleDeleteClick: (token: Token) => void;
}

const AccessTokensTable: React.FC<AccessTokensTableProps> = ({
  handleDeleteClick,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formatDateStatus = (dateString?: string | null) => {
    if (!dateString) return '---'; // Handle cases where there is no date

    const today = moment().startOf('day'); // Set reference to the start of today
    const expirationDate = moment(dateString).startOf('day'); // Consider only the date part of the expiration
    const isExpired = expirationDate.isBefore(today); // Check if the expiration date is before today

    if (isExpired) {
      return t('settings.adminPanel.accessTokens.table.token_expired');
    }

    const daysDiff = expirationDate.diff(today, 'days'); // Calculate the difference in days
    if (daysDiff === 0) {
      return t('settings.adminPanel.accessTokens.table.today');
    }
    if (daysDiff === 1) {
      return t('settings.adminPanel.accessTokens.table.expiring_in_one_day');
    } else {
      return t('settings.adminPanel.accessTokens.table.expiring_in', {
        count: daysDiff,
      });
    }
  };

  const [tokensList, setTokensList] = useState<Token[]>([]);

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);
  useEffect(() => {
    const query = {
      offset: offset,
      limit: itemsPerPage,
    };
    dispatch(getAccessTokensListAsyncThunk(query));
  }, [offset, limit]);
  const accessTokensList = useAppSelector(selectAccessTokensList);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };

  const totalItems = accessTokensList?.count as number;
  const itemsPerPage = 12;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTokensList(accessTokensList?.results || []);
  }, [accessTokensList]);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  return (
    <TableContainer>
      {tokensList && (
        <CallsTab>
          <thead>
            <tr>
              <CallsTableHeader>
                <H4 $grey>
                  {t('settings.adminPanel.accessTokens.table.tokenName')}
                </H4>
              </CallsTableHeader>
              <CallsTableHeader>
                <H4 $grey>
                  {t('settings.adminPanel.accessTokens.table.created')}
                </H4>
              </CallsTableHeader>
              <CallsTableHeader>
                <H4 $grey>
                  {t('settings.adminPanel.accessTokens.table.expirationDate')}
                </H4>
              </CallsTableHeader>
              <CallsTableHeader>
                <H4 $grey>
                  {t('settings.adminPanel.accessTokens.table.lastUsed')}
                </H4>
              </CallsTableHeader>
              <CallsTableHeader>
                <PaginationContainer>
                  <PaginationText>
                    {getPaginationTextContent(
                      currentPage,
                      itemsPerPage,
                      totalItems,
                    )}
                  </PaginationText>

                  <ArrowsContainer>
                    <button
                      disabled={currentPage === 1}
                      onClick={() =>
                        handlePageChange(Math.max(1, currentPage - 1))
                      }
                    >
                      <ArrowIconLeft disabled={currentPage === 1} />
                    </button>

                    <button
                      disabled={currentPage === totalPages}
                      onClick={() =>
                        handlePageChange(Math.min(totalPages, currentPage + 1))
                      }
                    >
                      <ArrowIconRight disabled={currentPage === totalPages} />
                    </button>
                  </ArrowsContainer>
                </PaginationContainer>
              </CallsTableHeader>
            </tr>
          </thead>
          <tbody>
            {tokensList.length === 0 ? (
              <div
                style={{
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {t('settings.adminPanel.accessTokens.table.empty')}
              </div>
            ) : (
              tokensList.map((token) => {
                return (
                  <CallsTableRow $selected={false} key={token.id}>
                    <CallsTableData>
                      <H4>{token.name}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>{moment(token.created_at).format('DD.MM.YYYY')}</H4>
                    </CallsTableData>
                    <CallsTableData>
                      <EuiToolTip
                        position="top"
                        content={moment(token.expiration_date).format(
                          'DD.MM.YYYY',
                        )}
                      >
                        <H4 style={{ cursor: 'pointer' }}>
                          {formatDateStatus(token.expiration_date)}
                        </H4>
                      </EuiToolTip>
                    </CallsTableData>
                    <CallsTableData>
                      <H4>
                        {token.last_used
                          ? moment(token.last_used).format('DD.MM.YYYY, HH:mm')
                          : '-'}
                      </H4>
                    </CallsTableData>

                    <CallsTableData>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <TrashIcon
                          $pointer
                          onClick={() => handleDeleteClick(token)}
                        />
                      </div>
                    </CallsTableData>
                  </CallsTableRow>
                );
              })
            )}
          </tbody>
        </CallsTab>
      )}
    </TableContainer>
  );
};

export default AccessTokensTable;

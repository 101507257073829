import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const DashboardIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M8.75425 0.339762C8.33769 -0.113253 7.66231 -0.113255 7.24575 0.339762L0.15621 8.04976C-0.0520699 8.27626 -0.0520699 8.64351 0.15621 8.87001C0.364489 9.09652 0.702177 9.09652 0.910457 8.87001L1.6 8.12012V14.26C1.6 15.221 2.31634 16 3.2 16H12.8C13.6837 16 14.4 15.221 14.4 14.26V8.12012L15.0895 8.87001C15.2978 9.09652 15.6355 9.09652 15.8438 8.87001C16.0521 8.64351 16.0521 8.27626 15.8438 8.04976L13.3333 5.31959V1.49978C13.3333 1.17945 13.0946 0.91977 12.8 0.91977H11.7333C11.4388 0.91977 11.2 1.17945 11.2 1.49978V2.99956L8.75425 0.339762ZM13.3333 6.96011V14.26C13.3333 14.5803 13.0946 14.84 12.8 14.84H3.2C2.90545 14.84 2.66667 14.5803 2.66667 14.26V6.96011L8 1.16002L13.3333 6.96011Z" />
  </StyledSvg>
);

import React, { useEffect, useState } from 'react';
import { CampaignsFiltersContainer } from '../CampaignsBar.style';
import { EuiPopover } from '@elastic/eui';
import CampaignProcesesFilter from './CampaignProcesesFilter';
import { Process } from '../../../../../../generated/tenants/Api';
import CampaignsStatusesFilter from './CampaignsStatusesFilter';
import CampaignsDatesFilter from './CampaignsDatesFilter';
import { Button, H4 } from '../../../../../App.style';
import { EmptyFilterContainer } from './CampaignFilters.style';
import { FilterIcon } from '../../../../../../resources/icons-new/FilterIcon';
import { useTranslation } from 'react-i18next';

export interface CampaingsFiltersValues {
  processes?: string[];
  statuses?: string[];
  date?: {
    start_date?: string;
    end_date?: string;
  };
  search: string;
}

interface CampaignsFiltersProps {
  filters: CampaingsFiltersValues;
  setFilters: React.Dispatch<React.SetStateAction<CampaingsFiltersValues>>;
  processList: Process[];
}

const CampaignsFilters: React.FC<CampaignsFiltersProps> = ({
  filters,
  setFilters,
  processList,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [processesIsOpen, setProcessesIsOpen] = useState(false);
  const [processesBarIsOpen, setProcessesBarIsOpen] = useState<boolean>(false);
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [statusBarIsOpen, setStatusBarIsOpen] = useState<boolean>(false);
  const [datesIsOpen, setDatesIsOpen] = useState<boolean>(false);
  const [datesBarIsOpen, setDatesBarIsOpen] = useState<boolean>(false);

  //processes
  useEffect(() => {
    if (filters.processes) setSelectedProcesses(filters.processes);
  }, [filters.processes]);

  const [selectedProcesses, setSelectedProcesses] = useState<string[]>([]);

  //statuses
  useEffect(() => {
    if (filters.statuses) setSelectedStatuses(filters.statuses);
  }, [filters.statuses]);

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  //dates
  const [selectedStartDate, setSelectedStartDate] = useState<string>('');
  const [selectedEndDate, setSelectedEndDate] = useState<string>('');

  const { start_date, end_date } = filters.date || {};

  return (
    <CampaignsFiltersContainer>
      <EuiPopover
        isOpen={isOpen}
        closePopover={() => {
          setIsOpen(false);

          setProcessesIsOpen(false);
          setStatusIsOpen(false);
        }}
        button={
          <Button
            $size={'M'}
            $styleType="NORMAL"
            onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
          >
            <FilterIcon />
            {t('campaigns.bar.filters.title')}
          </Button>
        }
        panelPaddingSize="none"
        anchorPosition="downLeft"
        hasArrow={false}
      >
        {/* processes popover */}
        <div style={{ width: '100px' }}>
          {(filters.processes === undefined ||
            filters.processes.length === 0) && (
            <CampaignProcesesFilter
              barButton={false}
              processList={processList}
              isOpen={processesIsOpen}
              setIsOpen={setProcessesIsOpen}
              selectedProcesses={selectedProcesses}
              setSelectedProcesses={setSelectedProcesses}
              filters={filters}
              setFilters={setFilters}
            />
          )}

          {(filters.statuses === undefined ||
            filters.statuses.length === 0) && (
            <CampaignsStatusesFilter
              barButton={false}
              isOpen={statusIsOpen}
              setIsOpen={setStatusIsOpen}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              filters={filters}
              setFilters={setFilters}
            />
          )}

          {start_date === undefined && end_date === undefined && (
            <CampaignsDatesFilter
              barButton={false}
              isOpen={datesIsOpen}
              setIsOpen={setDatesIsOpen}
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={setSelectedEndDate}
              filters={filters}
              setFilters={setFilters}
            />
          )}

          {filters.processes !== undefined &&
            filters.processes.length > 0 &&
            filters.statuses !== undefined &&
            filters.statuses.length > 0 &&
            (start_date !== undefined || end_date !== undefined) && (
              <EmptyFilterContainer>
                <H4 $grey>All filters are applied</H4>
              </EmptyFilterContainer>
            )}
        </div>
      </EuiPopover>

      {(start_date || end_date) && (
        <CampaignsDatesFilter
          barButton
          isOpen={datesBarIsOpen}
          setIsOpen={setDatesBarIsOpen}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filters={filters}
          setFilters={setFilters}
        />
      )}

      {filters.processes && filters.processes.length > 0 && (
        <CampaignProcesesFilter
          barButton
          processList={processList}
          isOpen={processesBarIsOpen}
          setIsOpen={setProcessesBarIsOpen}
          selectedProcesses={selectedProcesses}
          setSelectedProcesses={setSelectedProcesses}
          filters={filters}
          setFilters={setFilters}
        />
      )}

      {filters.statuses && filters.statuses.length > 0 && (
        <CampaignsStatusesFilter
          barButton
          isOpen={statusBarIsOpen}
          setIsOpen={setStatusBarIsOpen}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </CampaignsFiltersContainer>
  );
};

export default CampaignsFilters;

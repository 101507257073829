import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const DialogueSuccessIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM9.02274 3.72725C8.80307 3.50758 8.44692 3.50758 8.22725 3.72725C8.22194 3.73256 8.21696 3.73818 8.21233 3.74408L5.60805 7.06256L4.03791 5.49242C3.81824 5.27275 3.46208 5.27275 3.24241 5.49242C3.02274 5.71209 3.02274 6.06824 3.24241 6.28791L5.22725 8.27275C5.44692 8.49242 5.80307 8.49242 6.02274 8.27275C6.02763 8.26786 6.03225 8.2627 6.03657 8.2573L9.03073 4.5146C9.24238 4.29436 9.23972 3.94423 9.02274 3.72725Z"
      fill="#117B11"
    />
  </StyledSvg>
);

import { ApiError } from '../../../../../../../common/types';
import {
  Api,
  PatchedTenantPhoneNumberRequest,
  PhoneNumberSwap,
  PhoneNumberSwapRequest,
  TenantPhoneNumber,
} from '../../../../../../../generated/tenants/Api';

import {
  PatchedPhoneNumberPatchRequest,
  PhoneNumberCreateRequest,
  PhoneNumberResults,
  PhoneNumberPatch,
  Api as PublicApi,
} from '../../../../../../../generated/public/Api';

const { api } = new Api();

export const getTenantPhoneNumbers = () =>
  api.phoneNumbersList().then((_) => _.data);

export const getStrategyPhoneNumbers = (id: string) =>
  api.strategiesPhoneNumbersList(id).then((_) => _.data);

export interface CheckPhoneNumberResult {
  status: 'success' | 'fail';
  error?: ApiError<TenantPhoneNumber>;
}

export const updatePhoneNumber = (
  numberId: string,
  data: PatchedTenantPhoneNumberRequest,
): Promise<CheckPhoneNumberResult> =>
  api
    .phoneNumbersPartialUpdate(numberId, data)
    .then(() => {
      return { status: 'success' } as CheckPhoneNumberResult;
    })
    .catch((res) => {
      return { status: 'fail', error: res.error };
    });

export const getAdminPhoneNumberHistory = (id: string) =>
  api.phoneNumbersHistoryRetrieve(id).then((_) => _.data);

const { private: apiPrivate } = new PublicApi();

export interface CheckNumberResult {
  status: 'success' | 'fail';
  error?: PhoneNumberResults;
}

export const createTenantPhoneNumbers = (
  data: PhoneNumberCreateRequest,
): Promise<CheckNumberResult> =>
  apiPrivate
    .privateApiPhoneNumbersCreate(data)
    .then((res) => {
      return { status: 'success', error: res.data } as CheckNumberResult;
    })
    .catch(() => {
      return { status: 'fail' };
    });

export const getAllNumbersList = () =>
  apiPrivate.privateApiPhoneNumbersList().then((_) => _.data);

export const getSuperAdminPhoneNumberHistory = (id: string) =>
  apiPrivate.privateApiPhoneNumbersHistoryRetrieve(id).then((_) => _.data);

export interface CheckTenantPhoneNumberResult {
  status: 'success' | 'fail';
  error?: ApiError<PhoneNumberPatch>;
}

export const updateTenantPhoneNumber = (
  numberId: string,
  data: PatchedPhoneNumberPatchRequest,
): Promise<CheckPhoneNumberResult> =>
  apiPrivate
    .privateApiPhoneNumbersPartialUpdate(numberId, data)
    .then(() => {
      return { status: 'success' } as CheckPhoneNumberResult;
    })
    .catch((res) => {
      return { status: 'fail', error: res.error };
    });

export interface SwapPhoneNumbersResult {
  status: 'success' | 'fail';
  error?: ApiError<PhoneNumberSwap>;
}
export const swapPhoneNumbers = (
  data: PhoneNumberSwapRequest,
): Promise<SwapPhoneNumbersResult> =>
  api
    .phoneNumbersSwapCreate(data)
    .then(() => {
      return { status: 'success' } as SwapPhoneNumbersResult;
    })
    .catch((res) => {
      return { status: 'fail', error: res.error };
    });

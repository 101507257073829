import { EuiAccordion } from '@elastic/eui';
import React from 'react';
import {
  StrategiesContainer,
  StrategiesPanelContentContainer,
} from './StrategiesPanel.style';
import StrategyPreview from './StrategyPreview';
import { Strategy } from '../../../../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';

interface StrategiesListProps {
  strategiesList: Strategy[];
  archivedStrategiesList: Strategy[];
  handleDeleteClick: (strategy: Strategy, isArchived: boolean) => void;
  handleArchiveClick: (strategy: Strategy) => void;
  handleEditClick: (strategy: Strategy) => void;
  handleDetailedClick: (strategy: Strategy) => void;
  handleRestoreClick: (strategy: Strategy) => void;
}

const StrategiesList: React.FC<StrategiesListProps> = ({
  strategiesList,
  archivedStrategiesList,
  handleArchiveClick,
  handleDeleteClick,
  handleEditClick,
  handleDetailedClick,
  handleRestoreClick,
}) => {
  const { t } = useTranslation();

  return (
    <StrategiesPanelContentContainer>
      <EuiAccordion
        style={{ marginLeft: -5 }}
        initialIsOpen
        id={'active_strategies_accordion'}
        buttonContent={t(
          'settings.adminPanel.manageWorkspaces.list.accordion1title',
        )}
        paddingSize="l"
      >
        <StrategiesContainer>
          {strategiesList.map((strategy, i) => {
            return (
              <StrategyPreview
                key={`strategy_${i}`}
                strategy={strategy}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleArchiveClick={handleArchiveClick}
                handleDetailedClick={handleDetailedClick}
                isArchived={false}
              />
            );
          })}
        </StrategiesContainer>
      </EuiAccordion>

      <EuiAccordion
        isDisabled={archivedStrategiesList.length === 0}
        style={{ marginLeft: -5 }}
        id={'archived_strategies_accordion'}
        buttonContent={t(
          'settings.adminPanel.manageWorkspaces.list.accordion2title',
        )}
        paddingSize="l"
      >
        {archivedStrategiesList.map((strategy, i) => {
          return (
            <StrategyPreview
              key={`archived_strategy_${i}`}
              strategy={strategy}
              isArchived={true}
              handleRestoreClick={handleRestoreClick}
              handleDeleteClick={handleDeleteClick}
            />
          );
        })}
      </EuiAccordion>
    </StrategiesPanelContentContainer>
  );
};

export default StrategiesList;

import { EuiLoadingSpinner } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  PaginatedCallList,
  RecordData,
} from '../../../../../generated/tenants/Api';
import {
  getCampaignCallsListAsyncThunk,
  selectCampaignCallsList,
  selectCampaignRecordsList,
} from '../../api/campaignsSlice';
import CallsTable from './CallsTable';
import { CallsBarContainer, CallsListContainer } from './Calls.styles';
import CallsFilters, { CallsFiltersValues } from './filters/CallsFilters';
import { useNavigate } from 'react-router-dom';

interface CallsListProps {
  campaignId: string;
  selectedRecordId: string;
  clearSelectedRecordId: () => void;
  hasCampaignAccess: boolean;
}

const CallsList: React.FC<CallsListProps> = ({
  campaignId,
  selectedRecordId,
  clearSelectedRecordId,
  hasCampaignAccess,
}) => {
  const dispatch = useAppDispatch();
  const calls = useAppSelector(selectCampaignCallsList);
  const records = useAppSelector(selectCampaignRecordsList);
  const navigate = useNavigate();

  const [loadingCalls, setLoadingCalls] = useState<boolean>(true);

  const [callsList, setCallsList] = useState<PaginatedCallList>();
  const [recordsList, setRecordsList] = useState<RecordData[]>();
  useEffect(() => {
    if (calls) setCallsList(calls);
    if (records?.results) setRecordsList(records.results);

    setTimeout(() => {
      setLoadingCalls(false);
    }, 500);
  }, [calls, records]);

  const [filters, setFilters] = useState<CallsFiltersValues>({
    search: '',
  });

  useEffect(() => {
    if (selectedRecordId) {
      console.log(recordsList);

      setTimeout(() => {
        setFilters({ ...filters, search: selectedRecordId });
      }, 300);
      clearSelectedRecordId();
    }
  }, []);

  //table settings
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);

  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };

  useEffect(() => {
    const { search, call_type, date } = filters;

    const query: {
      call_type?: 'INBOUND' | 'OUTBOUND';
      limit?: number;
      offset?: number;
      record_phone_number?: string;
      date_from?: string;
      date_to?: string;
      ordering?: '-created_at' | 'created_at';
      record_id?: string;
      telco_status_list?: (
        | 'ANSWERED'
        | 'NOT_ANSWERED'
        | 'REJECTED'
        | 'BUSY'
        | 'VOICEMAIL'
        | 'TRANSFER'
        | 'NONEXISTENT_NUMBER'
        | 'UNMAPPED'
        | 'ERROR'
      )[];
    } = {
      limit: itemsPerPage,
      offset: offset,
      record_phone_number: search,
      date_from: date?.start_date,
      date_to: date?.end_date,
      telco_status_list: filters.statuses,
    };

    if (call_type) {
      query.call_type = call_type;
    }

    if (search.length === 36) {
      query.record_phone_number = undefined;
      query.record_id = search;
    }

    const data = {
      id: campaignId,
      query,
    };
    dispatch(getCampaignCallsListAsyncThunk(data));
  }, [filters, offset, limit, campaignId]);

  const [selectedCalls, setSelectedCalls] = useState<string[]>([]);

  const handleDetailedClick = (call_id: string) => {
    if (hasCampaignAccess)
      navigate(`/dashboard/campaigns/${campaignId}/calls/${call_id}`);
  };

  return (
    <CallsListContainer>
      {loadingCalls ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EuiLoadingSpinner size={'xxl'} />
        </div>
      ) : (
        <>
          <CallsBarContainer>
            <CallsFilters filters={filters} setFilters={setFilters} />
          </CallsBarContainer>

          <CallsTable
            callsList={callsList}
            selectedCalls={selectedCalls}
            setSelectedCalls={setSelectedCalls}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleDetailedClick={handleDetailedClick}
            campaignId={campaignId}
            hasCampaignAccess={hasCampaignAccess}
          />
        </>
      )}
    </CallsListContainer>
  );
};

export default CallsList;

import React, { useEffect, useState } from 'react';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../../../generated/tenants/Api';
import {
  CalendarInterface,
  convert2calendar,
  convert2intervals,
  CopiedIntervals,
  ErrorInterface,
  IntervalsError,
} from '../calendarFunctions';
import { H3 } from '../../../../../App.style';
import { WorkingHoursHeader, WorkingHoursTable } from '../WorkingHours.style';
import WorkingHoursDay from './WorkingHoursDay';
import { useTranslation } from 'react-i18next';

interface WorkingHoursEditProps {
  calendarConfiguration: CalendarConfigurationRequest | CalendarConfiguration;
  setCalendarConfiguration?: React.Dispatch<
    React.SetStateAction<CalendarConfigurationRequest | undefined>
  >;
  setCalendarHasErrors?: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkingHoursEdit: React.FC<WorkingHoursEditProps> = ({
  calendarConfiguration,
  setCalendarConfiguration,
  setCalendarHasErrors,
}) => {
  const { t } = useTranslation();
  const [outboundCalendar, setOutboundCalendar] =
    useState<CalendarInterface[]>();
  const [ccCalendar, setCcCalendar] = useState<CalendarInterface[]>();
  const [inboundCalendar, setInboundCalendar] = useState<CalendarInterface[]>();

  useEffect(() => {
    loadCalendarData();
  }, []);

  //converts working hours into calendars
  const loadCalendarData = () => {
    if (calendarConfiguration) {
      if (calendarConfiguration.working_hours) {
        const week = convert2calendar(calendarConfiguration.working_hours);

        setOutboundCalendar(week);
      }
      if (calendarConfiguration.cc_working_hours) {
        const week = convert2calendar(calendarConfiguration.cc_working_hours);
        setCcCalendar(week);
      }
      if (calendarConfiguration.inbound_working_hours) {
        const week = convert2calendar(
          calendarConfiguration.inbound_working_hours,
        );
        setInboundCalendar(week);
      }
    }
  };

  useEffect(() => {
    if (
      outboundCalendar &&
      ccCalendar &&
      inboundCalendar &&
      setCalendarConfiguration
    ) {
      const outboundData = convert2intervals(outboundCalendar);
      const ccHoursData = convert2intervals(ccCalendar);
      const inboundHoursData = convert2intervals(inboundCalendar);

      setCalendarConfiguration({
        ...calendarConfiguration,
        working_hours: outboundData,
        cc_working_hours: ccHoursData,
        inbound_working_hours: inboundHoursData,
      });
    }
  }, [outboundCalendar, ccCalendar, inboundCalendar]);

  const checkForAnyErrors = () => {
    // Checking if any error array is not empty
    const hasErrors =
      outboundTimeErrors.length > 0 ||
      outboundIntervalsError.length > 0 ||
      ccTimeErrors.length > 0 ||
      ccIntervalsError.length > 0 ||
      inboundTimeErrors.length > 0 ||
      outboundOutsideInboundErrors.length > 0;

    // Setting the state based on whether we have any errors or not
    if (setCalendarHasErrors) {
      setCalendarHasErrors(hasErrors);
    }
  };

  //errors logic
  const [outboundOutsideInboundErrors, setOutboundOutsideInboundErrors] =
    useState<ErrorInterface[]>([]);

  //checks if outbound is inside inbound
  useEffect(() => {
    const tempError: Array<ErrorInterface> = [];
    setOutboundOutsideInboundErrors([]);
    if (outboundCalendar && inboundCalendar) {
      outboundCalendar.map((item, dayIndex) => {
        if (
          item.workingHours.length &&
          inboundCalendar[dayIndex].workingHours[0]
        ) {
          item.workingHours.map((interval, intervalIndex) => {
            if (
              interval.from < inboundCalendar[dayIndex].workingHours[0].from ||
              interval.to > inboundCalendar[dayIndex].workingHours[0].to
            ) {
              tempError.push({ day: dayIndex, interval: intervalIndex });
              setOutboundOutsideInboundErrors(tempError);
            }
          });
        }
      });
    }
  }, [outboundCalendar, inboundCalendar]);

  //errors
  //time errors occurs when endTime is earlier than startTime
  const [outboundTimeErrors, setOutboundTimeErrors] = useState<
    Array<ErrorInterface>
  >([]);
  const [ccTimeErrors, setCcTimeErrors] = useState<Array<ErrorInterface>>([]);
  const [inboundTimeErrors, setInboundTimeErrors] = useState<ErrorInterface[]>(
    [],
  );

  //interval errors occurs when intervals are overlapping
  const [outboundIntervalsError, setOutboundIntervalsError] = useState<
    IntervalsError[]
  >([]);
  const [ccIntervalsError, setCcIntervalsError] = useState<IntervalsError[]>(
    [],
  );

  //checking if outbound intervals are not overlapping, and if endTime<startTime
  useEffect(() => {
    const tempTimeErrors: ErrorInterface[] = [];
    const tempIntervalsError: IntervalsError[] = [];

    setOutboundTimeErrors([]);
    setOutboundIntervalsError([]);

    if (outboundCalendar) {
      outboundCalendar.map((item, dayIndex) => {
        if (item.workingHours.length) {
          item.workingHours.map((interval, intervalIndex) => {
            if (interval.from >= interval.to) {
              tempTimeErrors.push({ day: dayIndex, interval: intervalIndex });
            }
          });
        }

        if (item.workingHours.length > 1) {
          for (let i = 0; i < item.workingHours.length - 1; i++) {
            const firstInterval = item.workingHours[i];
            const secondInterval = item.workingHours[i + 1];

            if (firstInterval.to >= secondInterval.from) {
              let type;
              if (firstInterval.to > secondInterval.from) {
                type = 'overlap';
              } else {
                type = 'sameHour';
              }

              tempIntervalsError.push({
                day: dayIndex,
                intervals: { from: i, to: i + 1 },
                type: type as 'overlap' | 'sameHour',
              });
            }
          }
        }
      });

      setOutboundTimeErrors(tempTimeErrors);
      setOutboundIntervalsError(tempIntervalsError);
    }
  }, [outboundCalendar]);

  //checking if cc intervals are not overlapping, and if endTime<startTime
  useEffect(() => {
    const tempTimeErrors: ErrorInterface[] = [];
    const tempIntervalsErrors: IntervalsError[] = [];

    setCcTimeErrors([]);
    setCcIntervalsError([]);

    if (ccCalendar) {
      ccCalendar.map((item, dayIndex) => {
        if (item.workingHours.length) {
          item.workingHours.map((interval, intervalIndex) => {
            if (interval.from >= interval.to) {
              tempTimeErrors.push({ day: dayIndex, interval: intervalIndex });
            }
          });
        }

        if (item.workingHours.length > 1) {
          for (let i = 0; i < item.workingHours.length - 1; i++) {
            const firstInterval = item.workingHours[i];
            const secondInterval = item.workingHours[i + 1];

            if (firstInterval.to >= secondInterval.from) {
              let type;
              if (firstInterval.to > secondInterval.from) {
                type = 'overlap';
              } else {
                type = 'sameHour';
              }

              tempIntervalsErrors.push({
                day: dayIndex,
                intervals: { from: i, to: i + 1 },
                type: type as 'overlap' | 'sameHour',
              });
            }
          }
        }
      });

      setCcTimeErrors(tempTimeErrors);
      setCcIntervalsError(tempIntervalsErrors);
    }
  }, [ccCalendar]);

  //checking endTime<StartTime of inbound
  useEffect(() => {
    const tempError: ErrorInterface[] = [];

    setInboundTimeErrors([]);
    if (inboundCalendar) {
      inboundCalendar.map((item, dayIndex) => {
        if (item.workingHours.length) {
          item.workingHours.map((interval, intervalIndex) => {
            if (interval.from >= interval.to) {
              tempError.push({ day: dayIndex, interval: intervalIndex });
              setInboundTimeErrors(tempError);
            }
          });
        }
      });
    }
  }, [inboundCalendar]);

  //copy intervals logic
  const [coppiedIntervals, setCopiedIntervals] = useState<CopiedIntervals>();
  const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false);

  const handleCopyIntervals = (dayIndex: number) => {
    if (coppiedIntervals === undefined) {
      const arr: CopiedIntervals = {};
      if (ccCalendar) {
        const data = ccCalendar[dayIndex].workingHours;
        arr.cc = data;
      }
      if (inboundCalendar) {
        const data = inboundCalendar[dayIndex].workingHours;
        arr.inbound = data;
      }
      if (outboundCalendar) {
        const data = outboundCalendar[dayIndex].workingHours;
        arr.outbound = data;
      }
      setCopiedIntervals(arr);

      setShowCopiedMessage(true);

      setTimeout(() => {
        setShowCopiedMessage(false);
      }, 1000);
    }
  };

  const handlePasteIntervals = (dayIndex: number) => {
    if (coppiedIntervals !== undefined) {
      const { cc, outbound, inbound } = coppiedIntervals;
      if (ccCalendar && cc) {
        const arr = [...ccCalendar];
        arr[dayIndex].workingHours = cc.map((cc) => {
          return { from: cc.from, to: cc.to };
        });
        setCcCalendar(arr);
      }
      if (inboundCalendar && inbound) {
        const arr = [...inboundCalendar];
        arr[dayIndex].workingHours = inbound.map((inbound) => {
          return { from: inbound.from, to: inbound.to };
        });
        setInboundCalendar(arr);
      }
      if (outboundCalendar && outbound) {
        const arr = [...outboundCalendar];
        arr[dayIndex].workingHours = outbound.map((outbound) => {
          return { from: outbound.from, to: outbound.to };
        });
        setOutboundCalendar(arr);
      }

      setCopiedIntervals(undefined);
    }
  };

  useEffect(() => {
    checkForAnyErrors();
  }, [
    outboundTimeErrors,
    outboundIntervalsError,
    ccTimeErrors,
    ccIntervalsError,
    inboundTimeErrors,
    outboundOutsideInboundErrors,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 950,
      }}
    >
      <WorkingHoursTable>
        <thead>
          <tr>
            <WorkingHoursHeader day></WorkingHoursHeader>
            <WorkingHoursHeader>
              <H3 $bold>{t('presets.calendarForm.outbound')}</H3>
            </WorkingHoursHeader>
            <WorkingHoursHeader>
              <H3 $bold>{t('presets.calendarForm.inbound')}</H3>
            </WorkingHoursHeader>
            <WorkingHoursHeader>
              <H3 $bold>{t('presets.calendarForm.cc')}</H3>
            </WorkingHoursHeader>
            <WorkingHoursHeader copy></WorkingHoursHeader>
          </tr>
        </thead>
        <tbody>
          {outboundCalendar &&
            ccCalendar &&
            inboundCalendar &&
            [...Array(7)].map((e, dayIndex) => {
              return (
                <WorkingHoursDay
                  key={`working-hours-day-${dayIndex}`}
                  dayIndex={dayIndex}
                  //outbound
                  outboundCalendar={outboundCalendar}
                  setOutboundCalendar={setOutboundCalendar}
                  outboundTimeErrors={outboundTimeErrors}
                  outboundIntervalsError={outboundIntervalsError}
                  //call center
                  ccCalendar={ccCalendar}
                  setCcCalendar={setCcCalendar}
                  ccTimeErrors={ccTimeErrors}
                  ccIntervalsError={ccIntervalsError}
                  //inbound
                  inboundCalendar={inboundCalendar}
                  setInboundCalendar={setInboundCalendar}
                  inboundTimeErrors={inboundTimeErrors}
                  //outbound outside inbound errors
                  outboundOutsideInboundErrors={outboundOutsideInboundErrors}
                  //copy intervals logic
                  coppiedIntervals={coppiedIntervals}
                  showCopiedMessage={showCopiedMessage}
                  handleCopyIntervals={handleCopyIntervals}
                  handlePasteIntervals={handlePasteIntervals}
                />
              );
            })}
        </tbody>
      </WorkingHoursTable>
    </div>
  );
};

export default WorkingHoursEdit;

import styled, { css } from 'styled-components';

export const CampaignHistoryContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-left: 30px;
`;

export const CampaignHistoryStepContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  // Linia łącząca kółka
  &:before {
    content: '';
    position: absolute;
    top: 32px;
    left: -1px;
    width: 2px;
    height: 100%;
    background-color: #ccc;
    z-index: 0;
  }

  // Ostatni krok nie ma linii łączącej
  &:last-child:before {
    content: none;
  }
`;

export const CampaignHistoryStatusCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  background: #f1f4fa;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  z-index: 1;
`;

export const CampaignHistoryStepContent = styled.div<{
  status: 'success' | 'error';
}>`
  display: flex;
  gap: 6px;
  margin-left: 30px;
  height: 32px;

  display: flex;
  align-items: center;
  padding-left: 8px;
  border-radius: 6px;

  max-width: 480px;

  ${({ status }) =>
    status === 'error' &&
    css`
      background: #f8e9e9;
    `}
`;

import styled from 'styled-components';
import { COLOR_NEUTRAL_20, H6 } from '../../../../../App.style';
import { EuiDroppable } from '@elastic/eui';

export const PhoneNumbersContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;

  padding: 24px;

  position: relative;

  background: #ffffff;
`;

export const PhoneNumbersContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);

  margin-top: 24px;

  padding-bottom: 0px;

  display: flex;
  gap: 50px;

  position: relative;
  max-width: 1500px;
`;

export const PhoneNumbersContentLeftContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PhoneNumbersContentRightContainer = styled.div`
  flex: 1; // oba kontenery zajmą tę samą przestrzeń
`;

//numbers history
export const NumbersHistoryContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 140px);
  margin-top: 24px;

  padding-bottom: 0px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const NumberHistoryAccordionContainer = styled.div`
  max-height: 200px;
  transition: max-height 0.3s ease-in-out;
  overflow: auto;
  padding: 5px 12px;
  margin-bottom: 10px;
  background: white;

  display: flex;
  align-items: center;
  gap: 10px;

  ${H6} {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

//add new number

export const NumbersAddNewContentContainer = styled.form`
  width: 400px;
  height: calc(100% - 140px);
  margin-top: 24px;

  padding-bottom: 0px;
  gap: 16px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const NumbersAddNewButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

//assign numbers

export const LoadingNumbersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
`;

export const PhoneNumbersDroppableContainer = styled(EuiDroppable)`
  background: ${COLOR_NEUTRAL_20};
  height: 90%;
`;

export const UnassignedNumbersTitleContainer = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const PhoneNumberRowContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  margin: 8px;
  height: 50px;
  position: relative;

  box-shadow: 0px 2px 2px 0px rgba(154, 165, 177, 0.3);

  background: white;
  border-radius: 5px;
`;

export const PhoneNumberRowCopyContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const UnassignedNumbersIconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const TenantUsedNumbersContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const UnassignedNumbersIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

import {
  Api,
  PatchedUserSettingsRequest,
  TokenDisplay,
  TokenRequest,
  UserSettings,
  UserSettingsRequest,
} from '../../../../../generated/tenants/Api';
import {
  ContentType,
  Api as PublicApi,
  UserChangePasswordRequest,
} from '../../../../../generated/public/Api';
import { ApiError } from '../../../../../common/types';

const { api } = new Api();

export const getUserSettings = () =>
  api.userSettingsRetrieve().then((_) => _.data);

export const updateUserSettings = (data: UserSettingsRequest) =>
  api.userSettingsUpdate(data).then((_) => _.data);

export interface CheckUserAvatarResult {
  status: 'success' | 'fail';
  error?: ApiError<UserSettings>;
}

export const updateUserAvatar = (
  data: PatchedUserSettingsRequest,
): Promise<CheckUserAvatarResult> =>
  api
    .userSettingsPartialUpdate(data, { type: ContentType.FormData })
    .then(() => {
      return { status: 'success' } as CheckUserAvatarResult;
    })
    .catch((res) => {
      return { status: 'fail', error: res.error };
    });

export interface CheckUserPasswordChangeResult {
  status: 'success' | 'fail';
  error?: number;
}

//password change

const { api: publicApi } = new PublicApi();
export const userPasswordChange = (
  data: UserChangePasswordRequest,
  baseDomain: string,
): Promise<CheckUserPasswordChangeResult> =>
  publicApi
    .usersChangePasswordUpdate(data, {
      baseUrl: `//${baseDomain}`,
      credentials: 'include',
    })
    .then(() => {
      return { status: 'success' } as CheckUserPasswordChangeResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.status };
    });

//access tokens
export const getAccessTokensList = (query?: {
  limit?: number;
  offset?: number;
}) => api.accessTokensList(query).then((_) => _.data);

export interface CheckAccessTokenResult {
  status: 'success' | 'fail';
  error?: ApiError<TokenDisplay>;
  token?: string;
}

export const createAccessToken = (
  data: TokenRequest,
): Promise<CheckAccessTokenResult> =>
  api
    .accessTokensCreate(data)
    .then((response) => {
      return {
        status: 'success',
        token: response.data.token,
      } as CheckAccessTokenResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteAccessToken = (
  id: string,
): Promise<CheckAccessTokenResult> =>
  api
    .accessTokensDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckAccessTokenResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import {
  disableLoginError,
  loginAsyncThunk,
  selectUser,
} from './api/sessionSlice';
import wavesSvg from '../../../resources/login/login-waves-grey.svg';
import {
  LoginContainer,
  LeftContainer,
  Waves,
  RightContainer,
  LogoContainer,
  LoginForm,
  LektaLogo,
  ForgotPasswordContainer,
  LoginButtonContainer,
  ErrorMessageContainer,
} from './Login.style';
import lektaLogo from '../../../resources/logo-lekta.png';
import { PasswordVisibilityToggle } from '../../../common/inputs/Inputs.style';
import { Button, H2, H5 } from '../../App.style';
import PasswordResetModal from './PasswordResetModal';
import { EuiFieldText } from '@elastic/eui';
import { EyeIcon } from '../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../resources/icons-new/EyeSlashIcon';
import { useTranslation } from 'react-i18next';

export function Login() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const changePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPasswordVisible((prev) => !prev);
  };

  const handleLoginSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    dispatch(loginAsyncThunk({ email, password }));
  };

  const user = useAppSelector(selectUser);

  useEffect(() => {
    user.error ? setLoginError(true) : setLoginError(false);
  }, [user]);

  const [loginError, setLoginError] = useState<boolean>(false);

  const hideError = () => {
    setLoginError(false);
    dispatch(disableLoginError());
  };

  const [isPasswordResetModalVisible, setIsPasswordResetModalVisible] =
    useState<boolean>(false);

  const openPasswordResetModal = () => setIsPasswordResetModalVisible(true);
  const closePasswordResetModal = () => setIsPasswordResetModalVisible(false);

  return (
    <LoginContainer>
      <PasswordResetModal
        isVisible={isPasswordResetModalVisible}
        closeModal={closePasswordResetModal}
      />

      <LeftContainer>
        <Waves src={wavesSvg} alt="waves" />
      </LeftContainer>
      <RightContainer>
        <LogoContainer>
          <LektaLogo src={lektaLogo} alt="lekta logo" />
        </LogoContainer>
        <LoginForm onSubmit={handleLoginSubmit}>
          {loginError && (
            <ErrorMessageContainer>
              <H2 $red>{t('login.error.line1')}</H2>
              <H2 $red>{t('login.error.line2')}</H2>
            </ErrorMessageContainer>
          )}

          <EuiFieldText
            placeholder={t('login.email')}
            value={email}
            onChange={(ev) => {
              setEmail(ev.target.value);
              loginError && hideError();
            }}
          />

          <EuiFieldText
            type={passwordVisible ? 'text' : 'password'}
            placeholder={t('login.password')}
            append={
              <PasswordVisibilityToggle
                type={'button'}
                onClick={changePasswordVisibility}
              >
                {passwordVisible ? (
                  <EyeIcon style={{ width: 25, height: 25 }} />
                ) : (
                  <EyeSlashIcon style={{ width: 25, height: 25 }} />
                )}
              </PasswordVisibilityToggle>
            }
            value={password}
            onChange={(ev) => {
              setPassword(ev.target.value);
              loginError && hideError();
            }}
          />

          <ForgotPasswordContainer>
            <H5>
              {t('login.forgotPassword')}

              <u style={{ cursor: 'pointer' }} onClick={openPasswordResetModal}>
                {t('common.here')}
              </u>
            </H5>
          </ForgotPasswordContainer>

          <LoginButtonContainer>
            <Button type="submit">{t('login.login')}</Button>
          </LoginButtonContainer>
        </LoginForm>
      </RightContainer>
    </LoginContainer>
  );
}

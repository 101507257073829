import React, { useEffect, useState } from 'react';
import {
  UsersListTableRow,
  UsersListTableData,
  EditPermissionsContainer,
  UserAvatarImg,
  UserAvatarDiv,
  UserStrategyPermissionRow,
  EditAccessContainer,
} from '../Users.style';
import { EuiLoadingSpinner, EuiSuperSelect } from '@elastic/eui';
import {
  PatchedUserPermissionEditRequest,
  Strategy,
  StrategyPermissionList,
  UserTenant,
} from '../../../../generated/tenants/Api';
import { H4, Button, COLOR_NEUTRAL_80 } from '../../../App.style';
import {
  PermissionLevel,
  StrategyPermissionDisplay,
  UserRole,
  displayUserRole,
  getInitialRole,
  getUserInitials,
  getUserRolesOptions,
  mapPermissionToData,
  permissionHierarchy,
} from './userFunctions';
import moment from 'moment';
import { useAppDispatch } from '../../../../common/hooks';
import { userPermissionsUpdateAsyncThunk } from '../api/userSlice';
import { EditIcon } from '../../../../resources/icons-new/EditIcon';
import { useTranslation } from 'react-i18next';

interface UserTableRowProps {
  user: UserTenant;
  index: number;
  edit: boolean;
  setEditUserIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  handleDetailedClick: (user_id: string) => void;
  handleReloadUsersList: () => void;
  strategiesList: Strategy[];
  selectedStrategy?: string;
  showSuccessPermissionsPopup: () => void;
  showErrorPermissionsPopup: () => void;
}

const UserTableRow: React.FC<UserTableRowProps> = ({
  user,
  index,
  edit,
  setEditUserIndex,
  handleDetailedClick,
  handleReloadUsersList,
  strategiesList,
  selectedStrategy,
  showSuccessPermissionsPopup,
  showErrorPermissionsPopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>(
    getInitialRole(user),
  );

  const [selectedPermissions, setSelectedPermissions] = useState<
    StrategyPermissionList[]
  >(user.strategies_permissions);

  useEffect(() => {
    setSelectedUserRole(getInitialRole(user));

    if (selectedStrategy) {
      // Filter the selectedPermissions to only include the selectedStrategy
      const selectedStrategyPermissions = user.strategies_permissions.filter(
        (perm) => perm.strategy_id === selectedStrategy,
      );
      setSelectedPermissions(selectedStrategyPermissions);
    } else {
      setSelectedPermissions(user.strategies_permissions);
    }
  }, [user]);

  useEffect(() => {
    if (!edit) {
      setSelectedPermissions(user.strategies_permissions);
      setSelectedUserRole(getInitialRole(user));
    }
  }, [edit, user.strategies_permissions, user]);

  //permisions

  const getPermissionForStrategy = (strategyId: string): PermissionLevel => {
    const permissionList = selectedPermissions.find(
      (p) => p.strategy_id === strategyId,
    );

    if (!permissionList) {
      return 'no_permissions';
    }

    for (const level of Object.keys(permissionHierarchy) as PermissionLevel[]) {
      if (
        permissionHierarchy[level].every((val) =>
          permissionList.permissions.includes(val),
        )
      ) {
        return level;
      }
    }

    return 'no_permissions';
  };

  const handlePermissionChange = (
    strategyId: string,
    newPermission: PermissionLevel,
  ) => {
    const permissionsToUpdate = permissionHierarchy[newPermission] || [];

    const updatedPermissions = selectedPermissions.map((perm) =>
      perm.strategy_id === strategyId
        ? { ...perm, permissions: permissionsToUpdate }
        : perm,
    );

    // Check if the strategy already exists in the updatedPermissions array
    const strategyIndex = updatedPermissions.findIndex(
      (perm) => perm.strategy_id === strategyId,
    );

    // If it doesn't exist, add it
    if (strategyIndex === -1) {
      updatedPermissions.push({
        strategy_id: strategyId,
        permissions: permissionsToUpdate,
      });
    }

    setSelectedPermissions(updatedPermissions);
  };

  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);

  //call to backend after clicking save
  const handleSaveClick = () => {
    setLoadingPermissions(true);
    const permissionArray = selectedPermissions.map((permission) => {
      return {
        strategy_id: permission.strategy_id,
        permission: mapPermissionToData(permission.permissions),
      };
    });

    const payload: { userId: string; data: PatchedUserPermissionEditRequest } =
      {
        userId: user.id,
        data: {
          tenant_owner: selectedUserRole === 'tenant_owner',
          tenant_admin: selectedUserRole === 'tenant_admin',
        },
      };

    if (selectedUserRole === 'user') {
      payload.data.strategies_permissions = permissionArray;
    }

    dispatch(userPermissionsUpdateAsyncThunk(payload))
      .then(() => {
        showSuccessPermissionsPopup();
      })
      .catch(() => {
        showErrorPermissionsPopup();
      })
      .finally(() => {
        handleCancelClick();
        setLoadingPermissions(false);
      });
  };

  //refreshing
  const handleCancelClick = () => {
    setEditUserIndex(undefined);
    setSelectedPermissions(user.strategies_permissions);
    setSelectedUserRole(getInitialRole(user));
    handleReloadUsersList();
  };

  const noStrategiesAccess =
    !user.is_tenant_admin &&
    !user.is_tenant_owner &&
    user.strategies_permissions.length === 0;

  const userRolesOptions = getUserRolesOptions(
    t('common.owner'),
    t('common.admin'),
    t('common.user'),
  );

  const renderStrategyPermissions = () => {
    if (noStrategiesAccess && !edit) {
      return (
        <H4 $grey $italic>
          {t('settings.adminPanel.users.table.row.noAccess')}
        </H4>
      );
    }

    if (selectedUserRole !== 'user') {
      return (
        <H4 $grey $italic>
          {t('settings.adminPanel.users.table.row.fullAccess')}
        </H4>
      );
    }

    const strategiesToRender = selectedStrategy
      ? strategiesList.filter((strategy) => strategy.id === selectedStrategy)
      : strategiesList;

    return (
      <UserStrategyPermissionRow>
        {strategiesToRender.map((strategy) => {
          const currentPermission = getPermissionForStrategy(strategy.id);

          return (
            (currentPermission !== 'no_permissions' || edit) && (
              <StrategyPermissionDisplay
                key={strategy.id}
                strategy={strategy}
                currentPermission={currentPermission}
                isEditing={edit}
                handlePermissionChange={handlePermissionChange}
              />
            )
          );
        })}
      </UserStrategyPermissionRow>
    );
  };

  return (
    <UsersListTableRow
      no_permissions={false}
      key={user.id}
      onClick={() => handleDetailedClick(user.id)}
    >
      <UsersListTableData style={{ paddingLeft: 5 }} avatar>
        {user.avatar ? (
          <UserAvatarImg src={user.avatar} />
        ) : (
          <UserAvatarDiv color={user.accent_color}>
            <H4 $medium style={{ color: 'white' }}>
              {getUserInitials(user.first_name, user.last_name)}
            </H4>
          </UserAvatarDiv>
        )}
      </UsersListTableData>

      <UsersListTableData username>
        <div>
          <H4 $bold>
            {user.first_name} {user.last_name}
          </H4>
          <H4>{user.email}</H4>
        </div>
      </UsersListTableData>

      <UsersListTableData userRole onClick={(e) => e.stopPropagation()}>
        <div style={{ width: 120 }}>
          {edit ? (
            <EuiSuperSelect
              compressed
              options={userRolesOptions}
              valueOfSelected={selectedUserRole}
              onChange={(e) => setSelectedUserRole(e as UserRole)}
              itemLayoutAlign="top"
            />
          ) : (
            <>
              {displayUserRole(
                user,
                t('common.owner'),
                t('common.admin'),
                t('common.user'),
              )}
            </>
          )}
        </div>
      </UsersListTableData>

      <UsersListTableData onClick={(e) => e.stopPropagation()} permissions>
        {loadingPermissions ? (
          <EuiLoadingSpinner />
        ) : (
          renderStrategyPermissions()
        )}
      </UsersListTableData>

      <UsersListTableData>
        <H4>
          {user.last_login
            ? moment(user.last_login).format('DD.MM.YYYY, HH:mm')
            : t('settings.adminPanel.users.table.row.notLogged')}
        </H4>
      </UsersListTableData>

      {edit ? (
        <UsersListTableData pagination onClick={(e) => e.stopPropagation()}>
          <EditPermissionsContainer>
            <Button $size={'S'} onClick={handleSaveClick}>
              {t('common.save')}
            </Button>
            <Button $size={'S'} $styleType="NORMAL" onClick={handleCancelClick}>
              {t('common.cancel')}
            </Button>
          </EditPermissionsContainer>
        </UsersListTableData>
      ) : (
        <UsersListTableData pagination onClick={(e) => e.stopPropagation()}>
          <EditPermissionsContainer>
            <EditAccessContainer onClick={() => setEditUserIndex(index)}>
              <H4 $grey>
                {t('settings.adminPanel.users.table.row.editAccess')}
              </H4>
              <EditIcon color={COLOR_NEUTRAL_80} />
            </EditAccessContainer>
          </EditPermissionsContainer>
        </UsersListTableData>
      )}
    </UsersListTableRow>
  );
};

export default UserTableRow;

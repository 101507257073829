import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H4, Button } from '../../../../App.style';
import {
  CustomTokenSpan,
  DialogueTokenSpan,
  TokenInputPreviewContainer,
  TokenInputPreviewText,
} from './TokenInput.style';
import {
  DialogueToken,
  allBotTokens,
} from '../../configuration/tokenInputComponents/BotsTokens';
import { DialogueSuccessIcon } from '../../../../../resources/icons-new/DialogueSuccessIcon';
import { DialogueFailIcon } from '../../../../../resources/icons-new/DialogueFailIcon';
import BotStatementEditModal from './BotStatementEditModal';

const BotStatementContainer = styled.div`
  width: 100%;
`;

interface BotStatementBuilderProps {
  title?: string;
  value: string;
  onSave: (newValue: string, configKey: string) => void;
  configKey: string;
  customTokens: string[];
  icon?: 'success' | 'fail';
  readOnly?: boolean;
}

const BotStatementBuilder: React.FC<BotStatementBuilderProps> = ({
  title,
  value,
  onSave,
  configKey,
  icon,
  readOnly,
  customTokens,
}) => {
  const [dialogueTokens, setDialogueTokens] = useState<DialogueToken[]>([]);

  const cleanTokenName = (tokenName: string): string => {
    const cleanedName = tokenName
      .replace('%_CUSTOM_', '')
      .replace('/VERBATIM', '');
    return cleanedName;
  };

  const parseDialogueText = (inputText: string): DialogueToken[] => {
    // const tokenRegex = /(%_[A-Z0-9_]+)|(\{[^}]+\})|(\[[^\]]+\])/g;
    const tokenRegex =
      /(%_[A-Z0-9_]+(?:\/VERBATIM)?)|(\{[^}]+\})|(\[[^\]]+\])/g;
    let match;
    const tokens: DialogueToken[] = [];
    let lastIndex = 0;
    let id = 0;

    while ((match = tokenRegex.exec(inputText)) !== null) {
      const text = inputText.substring(lastIndex, match.index).trim();

      if (text) {
        tokens.push({
          displayText: text,
          value: text,
          type: 'text',
          id: id.toString(),
        });
        id++;
      }

      const tokenValue = match[0];

      let type: DialogueToken['type'] = 'token';
      let displayText = tokenValue;

      if (tokenValue.startsWith('%_')) {
        if (tokenValue.includes('_CUSTOM_')) {
          type = 'custom';
          displayText = cleanTokenName(tokenValue);

          if (tokenValue.endsWith('/VERBATIM')) {
            type = 'custom_verbatim';
          }
        } else {
          const foundToken = allBotTokens.find(
            (token) => token.value === tokenValue,
          );

          type = 'token';
          displayText = foundToken?.displayText as string;
        }
      } else if (tokenValue.startsWith('{')) {
        type = 'text_by_bot_gender';
        const [text1, text2] = tokenValue.slice(1, -1).split('|');
        displayText = `${text1} / ${text2}`;
      } else if (tokenValue.startsWith('[')) {
        type = 'text_by_caller_gender';
        const [text1, text2] = tokenValue.slice(1, -1).split('|');
        displayText = `${text1} / ${text2}`;
      }

      tokens.push({
        displayText,
        value: tokenValue,
        type,
        id: id.toString(),
      });
      id++;

      lastIndex = tokenRegex.lastIndex;
    }

    // Dodajemy pozostały tekst jako token typu 'text', jeśli istnieje
    if (lastIndex < inputText.length) {
      const remainingText = inputText.substring(lastIndex).trim();
      if (remainingText) {
        tokens.push({
          displayText: remainingText,
          value: remainingText,
          type: 'text',
          id: id.toString(),
        });
      }
    }

    return tokens;
  };

  //zamiana stringa z tokenami na obiekty
  useEffect(() => {
    if (value) {
      const newArray = parseDialogueText(value);

      setDialogueTokens(newArray);
    }
  }, [value]);

  const handleCancelClick = () => {
    const newArray = parseDialogueText(value);

    setDialogueTokens(newArray);
    setIsModalVisible(false);
  };

  const handleSaveClick = () => {
    const botStatement = dialogueTokens.map((item) => item.value).join(' ');

    onSave(botStatement, configKey);
    setIsModalVisible(false);
  };

  const renderInputText = () => {
    return dialogueTokens.map((item, index) => {
      if (item.type === 'text') {
        return (
          <H4 $wrap key={index}>
            {item.displayText}{' '}
          </H4>
        );
      } else if (item.type === 'custom' || item.type === 'custom_verbatim') {
        return (
          <CustomTokenSpan key={index}>
            <H4 $wrap $medium>
              {item.displayText}{' '}
            </H4>
          </CustomTokenSpan>
        );
      } else {
        return (
          <DialogueTokenSpan key={index}>
            <H4 $wrap $medium>
              {item.displayText}
            </H4>
          </DialogueTokenSpan>
        );
      }
    });
  };

  //bot modal
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  let botStatementEditModal;
  if (isModalVisible) {
    botStatementEditModal = (
      <BotStatementEditModal
        handleSave={handleSaveClick}
        dialogueTokens={dialogueTokens}
        setDialogueTokens={setDialogueTokens}
        handleCancel={handleCancelClick}
        modalTitle={title || 'Bot statement'}
        customTokens={customTokens}
        renderInputText={renderInputText}
      />
    );
  }

  return (
    <BotStatementContainer>
      {botStatementEditModal}
      {title && (
        <div style={{ display: 'flex', gap: 8, marginBottom: 8 }}>
          {icon &&
            (icon === 'success' ? (
              <DialogueSuccessIcon />
            ) : (
              <DialogueFailIcon />
            ))}
          <H4 $medium>{title}</H4>
        </div>
      )}

      <TokenInputPreviewContainer>
        <TokenInputPreviewText>{renderInputText()}</TokenInputPreviewText>
        {!readOnly && (
          <Button
            style={{ position: 'absolute', bottom: 8 }}
            $size={'XS'}
            $styleType="NORMAL"
            onClick={() => setIsModalVisible(true)}
          >
            Edit statement
          </Button>
        )}
      </TokenInputPreviewContainer>
    </BotStatementContainer>
  );
};

export default BotStatementBuilder;

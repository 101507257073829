import React, { useEffect } from 'react';
import {
  CampaignHistoryContainer,
  CampaignHistoryStatusCircle,
  CampaignHistoryStepContent,
  CampaignHistoryStepContainer,
} from './CampaignHistory.style';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  getCampaignHistoryListAsyncThunk,
  selectCampaignHistoryList,
} from '../../api/campaignsSlice';
import {
  CampaignUpdate,
  CampaignUpdateTransitionEnum,
} from '../../../../../generated/tenants/Api';
import { H4 } from '../../../../App.style';
import moment from 'moment';
import checkIcon from './assets/checkIcon.svg';
import errorIcon from './assets/errorIcon.svg';
import { EuiToolTip } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

interface CampaignHistoryProps {
  campaignId: string;
}

const CampaignHistory: React.FC<CampaignHistoryProps> = ({ campaignId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCampaignHistoryListAsyncThunk(campaignId));
  }, [campaignId]);

  const apiCampaignHistoryList = useAppSelector(selectCampaignHistoryList);

  const transitionTexts: { [key in CampaignUpdateTransitionEnum]: string } = {
    INITIALIZE: t('campaigns.history.INITIALIZE'),
    DEPLOY: t('campaigns.history.DEPLOY'),
    RUN: t('campaigns.history.RUN'),
    PAUSE_RUNNING: t('campaigns.history.PAUSE_RUNNING'),
    UNPAUSE_RUNNING: t('campaigns.history.UNPAUSE_RUNNING'),
    PAUSE_SCHEDULED: t('campaigns.history.PAUSE_SCHEDULED'),
    UNPAUSE_SCHEDULED: t('campaigns.history.UNPAUSE_SCHEDULED'),
    ERROR: t('campaigns.history.ERROR'),
    STOP: t('campaigns.history.STOP'),
    FINISH: t('campaigns.history.FINISH'),
    PAUSE_INBOUND: t('campaigns.history.PAUSE_INBOUND'),
    UNPAUSE_INBOUND: t('campaigns.history.UNPAUSE_INBOUND'),
    PAUSE_CC: t('campaigns.history.PAUSE_CC'),
    UNPAUSE_CC: t('campaigns.history.UNPAUSE_CC'),
    UPLOAD_RECORDS: t('campaigns.history.UPLOAD_RECORDS'),
    UPDATE: 'update',
    RESTART: 'restart',
  };

  function mapTriggeredByToText(campaignUpdate: CampaignUpdate) {
    if (campaignUpdate.triggered_by === 'USER') {
      const user = campaignUpdate.user;
      return (
        <EuiToolTip
          content={
            user?.email ? user.email : t('campaigns.history.userNotFound')
          }
          position="top"
        >
          <H4 $medium>
            {user
              ? t('campaigns.history.byLog', {
                  firstName: user.first_name,
                  lastName: user.last_name,
                })
              : t('campaigns.history.byLog')}
          </H4>
        </EuiToolTip>
      );
    }

    if (campaignUpdate.triggered_by === 'TOKEN') {
      const tooltipContent = campaignUpdate.token
        ? campaignUpdate.token.name
        : t('campaigns.history.tokenInfoNotAvailable');
      return (
        <EuiToolTip content={tooltipContent} position="top">
          <H4 $medium>{t('campaigns.history.byToken')}</H4>
        </EuiToolTip>
      );
    }

    if (campaignUpdate.triggered_by === 'SFTP') {
      return <H4 $medium>{t('campaigns.history.viaSftp')}</H4>;
    }

    if (campaignUpdate.triggered_by === 'SUPERADMIN') {
      return <H4 $medium>{t('campaigns.history.byAdmin')}</H4>;
    }

    return null;
  }

  return (
    <CampaignHistoryContainer>
      {apiCampaignHistoryList.map((campaignUpdate, index) => {
        const status =
          campaignUpdate.transition === 'ERROR' ? 'error' : 'success';

        const formattedTime = moment(campaignUpdate.created_at).format(
          'DD.MM.YYYY, HH:MM',
        );

        return (
          <CampaignHistoryStepContainer key={index}>
            <CampaignHistoryStatusCircle>
              <img src={status === 'success' ? checkIcon : errorIcon} />
            </CampaignHistoryStatusCircle>

            <CampaignHistoryStepContent status={status}>
              <H4 $medium>{formattedTime}</H4>
              <H4>{transitionTexts[campaignUpdate.transition]}</H4>
              {mapTriggeredByToText(campaignUpdate)}
            </CampaignHistoryStepContent>
          </CampaignHistoryStepContainer>
        );
      })}
    </CampaignHistoryContainer>
  );
};

export default CampaignHistory;

import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_WHITE,
  COLOR_NEUTRAL_40,
  fontIBM,
} from '../../../App.style';
import styled from 'styled-components';
import { COLOR_NEUTRAL_80 } from '../../../App.style';

export const CampaignsPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;
  max-height: 100vh;

  padding: 24px;

  position: relative;

  background: #ffffff;
`;

export const CampaignsTableContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  padding: 10px 0px;
  padding-bottom: 0px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

//start date / end date

export const DateHeaderContent = styled.div`
  width: 100%;
  display: flex;
  gap: 3px;
  cursor: pointer;
`;

export const DateContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  align-items: center;
`;

export const DateArrowIcon = styled.img`
  width: 13px;
  height: 13px;
`;

//details
export const DetailsRowContainer = styled.div`
  width: 100%;
`;

export const DetailsRowContent = styled.div`
  width: 100%;

  display: flex;

  gap: 8px;
`;

export const DetailsLeftContainer = styled.div`
  width: 50%;
`;

export const DetailsRightContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`;

//done/total
export const DoneTotalRowContainer = styled.div`
  width: 100%;
  display: flex;
  padding-left: 30px;
`;

export const DoneTotalHeaderContainer = styled.div`
  height: 30px;

  width: 100%;

  gap: 5px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

//labels
export const LabelsRowContainer = styled.div`
  width: 100%;
  height: 45px;

  display: flex;
  align-items: center;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LabelsContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;

  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

export const SeeMoreContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SeeMoreArrow = styled.img`
  width: 15px;
  height: 15px;
`;

//pagination
export const PaginationContainer = styled.div`
  width: 100%;
  min-width: 110px;
  padding-left: 3px;

  display: flex;
  justify-content: flex-end;
  gap: 6px;
`;

export const PaginationText = styled.span`
  font-family: ${fontIBM};
  font-weight: 400;
  font-size: 12px;
  color: ${COLOR_NEUTRAL_80};
`;

export const ArrowsContainer = styled.div`
  display: flex;
  gap: 3px;
`;

export const ArrowIcon = styled.img`
  width: 15px;
  height: 15px;
`;

//New campaigns table
export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const CampaignsTab = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    height: 90%;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background: ${COLOR_WHITE};
  }
`;

interface CampaignsTableHeaderProps {
  checkbox?: boolean;
}

export const CampaignsTableHeader = styled.th<CampaignsTableHeaderProps>`
  border-top: 1px solid ${COLOR_NEUTRAL_40};
  border-bottom: 1px solid ${COLOR_NEUTRAL_40};
  padding-left: 3px;

  width: ${(props) => (props.checkbox ? '30px' : '100px')};

  text-align: left;
`;

export const CampaignsTableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background: ${COLOR_NEUTRAL_10};
  }
`;

interface CampaignsTableDataProps {
  checkbox?: boolean;
  $labels?: boolean;
}

export const CampaignsTableData = styled.td<CampaignsTableDataProps>`
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};

  padding-top: ${(props) => (props.$labels ? '10px' : '12px')};
  padding-bottom: ${(props) => props.$labels && '5px'};
  padding-left: 3px;
  padding-right: 3px;

  width: 100px;
  width: ${(props) => (props.checkbox ? '30px' : '100px')};
  vertical-align: top;
`;

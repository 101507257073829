import React from 'react';
import { TenantFormContainer } from '../Tenants.style';
import { EuiSuperSelect } from '@elastic/eui';
import { t } from 'i18next';
import { H3 } from '../../../../../../App.style';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import {
  BotLanguageEnum,
  CompanyCreateRequest,
} from '../../../../../../../generated/public/Api';

interface TenantLanguageForm {
  tenant: CompanyCreateRequest;
  setTenant: React.Dispatch<React.SetStateAction<CompanyCreateRequest>>;
  createNew?: boolean;
  detailedView?: boolean;
}

const TenantLanguageForm: React.FC<TenantLanguageForm> = ({
  tenant,
  setTenant,
  detailedView,
}) => {
  const languagesOptions = languages.map((language) => {
    return { value: language, inputDisplay: language };
  });

  const timezonesOptions = timezones.map((timezone) => {
    return { value: timezone, inputDisplay: timezone };
  });
  return (
    <TenantFormContainer>
      <FormRow
        label={<H3>{t('superAdminPanel.tenants.form.defaultLanguage')}</H3>}
      >
        <EuiSuperSelect
          readOnly={detailedView}
          valueOfSelected={tenant.language}
          options={languagesOptions}
          onChange={(e) => setTenant({ ...tenant, language: e })}
        />
      </FormRow>

      <FormRow label={<H3>{t('superAdminPanel.tenants.form.timezone')}</H3>}>
        <EuiSuperSelect
          readOnly={detailedView}
          valueOfSelected={tenant.timezone}
          options={timezonesOptions}
          onChange={(value) => setTenant({ ...tenant, timezone: value })}
        />
      </FormRow>
    </TenantFormContainer>
  );
};

export default TenantLanguageForm;

export const languages: BotLanguageEnum[] = [
  'pl-PL',
  'es-ES',
  'es-MX',
  'en-US',
  'en-AU',
];

export const timezones = [
  'Europe/Warsaw',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Phoenix',
  'America/Adak',
  'Pacific/Honolulu',
  'Australia/Sydney',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Perth',
  'Australia/Adelaide',
  'Australia/Hobart',
  'Australia/Darwin',
];

import React, { useEffect, useState } from 'react';
import EditPresetButtons from '../../../../presets/shared/EditPresetButtons';
import {
  Strategy,
  StrategyRequest,
} from '../../../../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import moment from 'moment';
import { FormContainer } from '../../../../processes/forms/ProcessForm.style';
import { PresetNameContainer } from '../../../../presets/PresetsPanel.style';
import { H2, H4 } from '../../../../../App.style';
import {
  StrategiesBackToListButton,
  StrategiesPanelContentContainer,
} from './StrategiesPanel.style';
import { ArrowLeftIcon } from '../../../../../../resources/icons-new/ArrowLeftIcon';
import {
  checkGpgRotate,
  clearGpgRotate,
  getStrategiesListAsyncThunk,
  getStrategyDetailedAsyncThunk,
  gpgRotateStrategyAsyncThunk,
  selectStrategyDetailed,
  updateStrategyAsyncThunk,
} from './api/strategiesSlice';
import StrategiesFormReadOnly from './StrategiesFormReadOnly';
import StrategiesForm from './StrategyForm';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

interface StrategyDetailedProps {
  strategy: Strategy;
  backToList: () => void;
}

const StrategyDetailed: React.FC<StrategyDetailedProps> = ({
  strategy,
  backToList,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(strategy.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(strategy.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editStrategy, setEditStrategy] = useState<StrategyRequest>({
    name: '',
    assigned_lines: 0,
  });

  useEffect(() => {
    if (editStrategy.cipher_output_data) {
      setEditStrategy({ ...editStrategy, cipher_public_key_gpg: null });
    }
  }, [editStrategy.cipher_output_data]);

  useEffect(() => {
    setEditStrategy(strategy);
  }, [strategy]);

  const handleCancelClick = () => {
    setEdit(false);
    setEditStrategy(strategy);
  };

  const handleStrategyEditClick = () => {
    const id = strategy.id;
    if (strategy && id) {
      const data = {
        id: strategy.id,
        data: editStrategy,
      };

      dispatch(updateStrategyAsyncThunk(data))
        .then(unwrapResult)
        .then((res) => {
          if (res.status === 'success') {
            dispatch(getStrategiesListAsyncThunk());
            backToList();
          }
        });
    } else {
      alert('something went wrong');
    }
  };

  const [publicGpgKey, setPublicGpgKey] = useState<string>(
    strategy.input_public_key,
  );
  const [publicKeyLoading, setPublicKeyLoading] = useState<boolean>(false);

  const handleRotateGptKey = () => {
    dispatch(gpgRotateStrategyAsyncThunk(strategy.id));
    setPublicKeyLoading(true);
  };

  const checkGpgKeyRotate = useAppSelector(checkGpgRotate);
  useEffect(() => {
    if (checkGpgKeyRotate.state === 'idle') {
      dispatch(getStrategyDetailedAsyncThunk(strategy.id));
      dispatch(clearGpgRotate());
    }
  }, [checkGpgKeyRotate]);

  const checkStrategyDetailed = useAppSelector(selectStrategyDetailed);
  useEffect(() => {
    if (checkStrategyDetailed?.input_public_key) {
      setPublicGpgKey(checkStrategyDetailed?.input_public_key);
      setPublicKeyLoading(false);
    }
  }, [checkStrategyDetailed]);

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  return (
    <StrategiesPanelContentContainer>
      <EditPresetButtons
        edit={edit}
        setEdit={setEdit}
        onCancel={handleCancelClick}
        saveChanges={handleStrategyEditClick}
        noPosition
        hasErrors={hasErrors}
      />

      <StrategiesBackToListButton onClick={backToList}>
        <ArrowLeftIcon />
        {t('common.goBack')}
      </StrategiesBackToListButton>

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{strategy.name}</H2>
            <H4 $grey>
              {t('settings.adminPanel.manageWorkspaces.list.created')}
              {formattedCreated},{' '}
              {t('settings.adminPanel.manageWorkspaces.list.modified')}
              {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <StrategiesFormReadOnly
            strategy={strategy}
            publicKey={publicGpgKey}
          />
        </FormContainer>
      ) : (
        <StrategiesForm
          strategy={editStrategy}
          setStrategy={setEditStrategy}
          backToDetailed={handleCancelClick}
          publicGpgKey={publicGpgKey}
          publicKeyLoading={publicKeyLoading}
          handleRotateGpgKey={handleRotateGptKey}
          setHasErrors={setHasErrors}
        />
      )}
    </StrategiesPanelContentContainer>
  );
};

export default StrategyDetailed;

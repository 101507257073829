import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ThreeDotsIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="13"
    height="4"
    viewBox="0 0 13 4"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M1.41667 3.11157C0.634263 3.11157 0 2.41502 0 1.55579C0 0.696549 0.634263 0 1.41667 0C2.19907 0 2.83333 0.696549 2.83333 1.55579C2.83333 2.41502 2.19907 3.11157 1.41667 3.11157ZM6.13889 3.11157C5.35649 3.11157 4.72222 2.41502 4.72222 1.55579C4.72222 0.696549 5.35649 0 6.13889 0C6.92129 0 7.55556 0.696549 7.55556 1.55579C7.55556 2.41502 6.92129 3.11157 6.13889 3.11157ZM10.8611 3.11157C10.0787 3.11157 9.44444 2.41502 9.44444 1.55579C9.44444 0.696549 10.0787 0 10.8611 0C11.6435 0 12.2778 0.696549 12.2778 1.55579C12.2778 2.41502 11.6435 3.11157 10.8611 3.11157Z" />
  </StyledSvg>
);

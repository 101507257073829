import styled from 'styled-components';
import {
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  fontIBM,
} from '../../app/App.style';
import { EuiSuperSelect } from '@elastic/eui';

interface InputProps {
  $isSearch?: boolean;
  $isDisabled?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  height: 30px;

  font-family: ${fontIBM};
  font-weight: 400;
  font-size: 14px;

  border-radius: 5px;
  border: 1px solid #d3d3d3;

  background: ${(props) => props.$isDisabled && '#EEF2F6'};
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  padding-left: ${(props) => (props.$isSearch ? '35px' : '0px')};
`;

export const PasswordVisibilityToggle = styled.button`
  width: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

//search

export const SearchInputContainer = styled.div`
  width: 100%;
  position: relative;
  min-width: 200px;

  display: flex;
  align-items: center;
`;

export const SuperSelectDark = styled(EuiSuperSelect)`
  background: ${COLOR_NEUTRAL_20};
  border: 1px solid ${COLOR_NEUTRAL_40};
`;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../../common/hooks';
import Modal from '../../../../../../../common/modals/Modal';
import { EuiDatePicker, EuiFieldText, EuiModalBody } from '@elastic/eui';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { H3 } from '../../../../../../App.style';
import { Moment } from 'moment';
import moment from 'moment';
import {
  TokenDisplay,
  TokenRequest,
} from '../../../../../../../generated/tenants/Api';
import {
  createAccessTokenAsyncThunk,
  getAccessTokensListAsyncThunk,
} from '../../../../userSettings/api/userSettingsSlice';
import { ApiError } from '../../../../../../../common/types';
import { unwrapResult } from '@reduxjs/toolkit';

interface CreateAccessTokenModalProps {
  closeModal: () => void;
  openAccessTokenPreview: () => void;
}

const CreateAccessTokenModal: React.FC<CreateAccessTokenModalProps> = ({
  closeModal,
  openAccessTokenPreview,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tokenName, setTokenName] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<moment.Moment | null>(
    moment().add(7, 'days'),
  );

  const [createAccessTokenApiErrors, setCreateAccessTokenApiErrors] =
    useState<ApiError<TokenDisplay>>();

  const handleDateChange = (date: Moment) => {
    setCreateAccessTokenApiErrors({
      ...createAccessTokenApiErrors,
      expiration_date: undefined,
    });
    setExpirationDate(date);
  };

  const handleSubmitClick = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const token: TokenRequest = {
      name: tokenName,
      expiration_date: expirationDate
        ? expirationDate.format('YYYY-MM-DD')
        : undefined,
    };
    dispatch(createAccessTokenAsyncThunk(token))
      .then(unwrapResult)
      .then((res) => {
        if (res.status === 'success') {
          dispatch(getAccessTokensListAsyncThunk());
          setTokenName('');
          setExpirationDate(moment().add(7, 'days'));
          closeModal();
          openAccessTokenPreview();
        } else if (res.status === 'fail') {
          setCreateAccessTokenApiErrors(res.error);
        }
      });

    return false;
  };

  const body = (
    <EuiModalBody>
      <FormRow
        label={<H3>{t('settings.adminPanel.accessTokens.table.tokenName')}</H3>}
        isInvalid={createAccessTokenApiErrors?.name ? true : false}
        error={createAccessTokenApiErrors?.name}
      >
        <EuiFieldText
          autoFocus
          isInvalid={createAccessTokenApiErrors?.name ? true : false}
          value={tokenName}
          onChange={(e) => {
            setTokenName(e.target.value);
            setCreateAccessTokenApiErrors({
              ...createAccessTokenApiErrors,
              name: undefined,
            });
          }}
        />
      </FormRow>
      <FormRow
        label={
          <H3>{t('settings.adminPanel.accessTokens.table.expirationDate')}</H3>
        }
        isInvalid={createAccessTokenApiErrors?.expiration_date ? true : false}
        error={createAccessTokenApiErrors?.expiration_date}
      >
        <EuiDatePicker
          isInvalid={createAccessTokenApiErrors?.expiration_date ? true : false}
          selected={expirationDate}
          onChange={handleDateChange}
          minDate={moment()}
          dateFormat={'DD.MM.YYYY'}
        />
      </FormRow>
    </EuiModalBody>
  );
  return (
    <Modal
      headerTitle={t('settings.adminPanel.accessTokens.table.create')}
      isVisible={true}
      onCancel={closeModal}
      onConfirm={handleSubmitClick}
      children={body}
      buttonDisabled={tokenName === ''}
    />
  );
};

export default CreateAccessTokenModal;

import { EuiModalBody, EuiFormRow, EuiSuperSelect } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { H3 } from '../../../../../../App.style';
import { TenantPhoneNumber } from '../../../../../../../generated/tenants/Api';
import { formatPhoneNumber } from '../numbersFunctions';
import Modal from '../../../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface ReplaceNumberProps {
  isVisible: boolean;
  closeModal: () => void;
  handleSwapNumber: (oldNumber: string, newNumber: string) => void;
  selectedReplaceNumber: TenantPhoneNumber;
  unusedNumbers: TenantPhoneNumber[];
  strategyId: string;
}

const ReplaceNumberModal: React.FC<ReplaceNumberProps> = ({
  isVisible,
  closeModal,
  handleSwapNumber,
  selectedReplaceNumber,
  unusedNumbers,
  // strategyId,
}) => {
  const { t } = useTranslation();
  const [selectedNewNumber, setSelectedNewNumber] = useState<string>('');

  useEffect(() => {
    if (unusedNumbers.length > 0 && selectedNewNumber === '') {
      setSelectedNewNumber(unusedNumbers[0].id);
    }
  }, [unusedNumbers]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSwapNumber(selectedReplaceNumber.id, selectedNewNumber);

    return false;
  };

  const selectedNumberOptions = [
    {
      value: selectedReplaceNumber.id,
      inputDisplay: formatPhoneNumber(selectedReplaceNumber.phone_number_str),
    },
  ];

  const unusedNumberOptions = [
    ...unusedNumbers.map((number) => ({
      value: number.id,
      inputDisplay: formatPhoneNumber(number.phone_number_str),
    })),
  ];

  const body = (
    <EuiModalBody>
      <EuiFormRow
        label={
          <H3>
            {t(
              'settings.adminPanel.numbers.assign.replaceModal.numberToReplace',
            )}
          </H3>
        }
      >
        <EuiSuperSelect
          valueOfSelected={selectedReplaceNumber.id}
          options={selectedNumberOptions}
          readOnly
          disabled
        />
      </EuiFormRow>

      <EuiFormRow
        label={
          <H3>
            {t(
              'settings.adminPanel.numbers.assign.replaceModal.numberToReplace',
            )}
          </H3>
        }
      >
        <EuiSuperSelect
          valueOfSelected={selectedNewNumber}
          options={unusedNumberOptions}
          onChange={(e) => setSelectedNewNumber(e)}
        />
      </EuiFormRow>
    </EuiModalBody>
  );
  return (
    <Modal
      isVisible={isVisible}
      onCancel={closeModal}
      onConfirm={handleSubmit}
      children={body}
      headerTitle={t('settings.adminPanel.numbers.assign.replaceModal.title')}
    />
  );
};

export default ReplaceNumberModal;

import React, { useState } from 'react';
import { VindicationContentContainer } from '../../Vindication.style';
import { Button, H2, H3 } from '../../../../../App.style';
import { EuiFieldText, EuiFormRow } from '@elastic/eui';
import DialogueCompanyNameModal from './DialogueCompanyNameModal';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';

interface DialogueGlobalParametersProps {
  vindicationDialogue: BotConfigurationRequest;
  updateRuntimeParameters: (key: string, newValue: string) => void;
}

const DialogueGlobalParameters: React.FC<DialogueGlobalParametersProps> = ({
  vindicationDialogue,
  updateRuntimeParameters,
}) => {
  const [isCompanyNameModalVisible, setIsCompanyNameModalVisible] =
    useState<boolean>(false);

  const showCompanyNameModal = () => setIsCompanyNameModalVisible(true);
  const closeCompanyNameModal = () => setIsCompanyNameModalVisible(false);

  let companyNameModal;
  if (isCompanyNameModalVisible) {
    companyNameModal = (
      <DialogueCompanyNameModal
        closeModal={closeCompanyNameModal}
        vindicationDialogue={vindicationDialogue}
        updateRuntimeParameters={updateRuntimeParameters}
      />
    );
  }
  return (
    <VindicationContentContainer>
      {companyNameModal}
      <H2 $selected>Global parameters</H2>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 400,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <EuiFormRow
          style={{ width: '100%', marginBottom: 8 }}
          label={<H3>{`Company name (nominative)`}</H3>}
        >
          <EuiFieldText
            value={
              vindicationDialogue.runtime_parameters?.['CompanyName'] ?? ''
            }
            onChange={(e) =>
              updateRuntimeParameters('CompanyName', e.target.value)
            }
          />
        </EuiFormRow>

        <Button $size={'S'} $styleType="NORMAL" onClick={showCompanyNameModal}>
          wprowadź odmiany
        </Button>
      </div>

      <EuiFormRow label={<H3>Bot name</H3>}>
        <EuiFieldText
          value={vindicationDialogue.runtime_parameters?.['SystemName'] ?? ''}
          onChange={(e) =>
            updateRuntimeParameters('SystemName', e.target.value)
          }
        />
      </EuiFormRow>

      <EuiFormRow label={<H3>Company phone number</H3>}>
        <EuiFieldText
          value={
            vindicationDialogue.runtime_parameters?.['CompanyPhoneNumber'] ?? ''
          }
          onChange={(e) =>
            updateRuntimeParameters('CompanyPhoneNumber', e.target.value)
          }
        />
      </EuiFormRow>
    </VindicationContentContainer>
  );
};

export default DialogueGlobalParameters;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const NewPresetIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M0.016 7.14225L6.992 7.14225V0.48625H8.56V7.14225H15.536V8.87025H8.56L8.56 15.5263H6.992L6.992 8.87025L0.016 8.87025V7.14225Z" />
  </StyledSvg>
);

import React from 'react';
import { SnapshotContainer } from '../Campaigns.style';
import {
  CalendarConfiguration,
  Campaign,
} from '../../../../generated/tenants/Api';
import WorkingHoursPreview from '../../presets/calendars/workingHours/preview/WorkingHoursPreview';

interface CalendarSnapshotProps {
  campaign?: Campaign;
}

const CalendarSnapshot: React.FC<CalendarSnapshotProps> = ({ campaign }) => {
  const calendarConfiguration: CalendarConfiguration =
    campaign?.calendar_configuration as CalendarConfiguration;

  return (
    <SnapshotContainer>
      {campaign && (
        <WorkingHoursPreview calendarConfiguration={calendarConfiguration} />
      )}
    </SnapshotContainer>
  );
};

export default CalendarSnapshot;

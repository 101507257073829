import React from 'react';
import Modal from '../../../../../../../common/modals/Modal';
import { EuiFormRow, EuiModalBody, EuiTextArea } from '@elastic/eui';
import { H3 } from '../../../../../../App.style';
import { useTranslation } from 'react-i18next';

interface PublicKeyPreviewModalProps {
  isVisible: boolean;
  closeModal: () => void;
  publicKey: string;
}

const PublicKeyPreviewModal: React.FC<PublicKeyPreviewModalProps> = ({
  isVisible,
  closeModal,
  publicKey,
}) => {
  const { t } = useTranslation();
  const body = (
    <EuiModalBody>
      <EuiFormRow
        label={<H3>{t('settings.adminPanel.sftp.publicKeyTitle')}</H3>}
      >
        <EuiTextArea value={publicKey} />
      </EuiFormRow>
    </EuiModalBody>
  );
  return (
    <Modal
      headerTitle={t('settings.adminPanel.sftp.rsa')}
      children={body}
      isVisible={isVisible}
      onCancel={closeModal}
      noConfirm
    />
  );
};

export default PublicKeyPreviewModal;

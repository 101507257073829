import { ApiError } from '../../../../../../common/types';
import {
  Api,
  CampaignNonWorkingDaysConfiguration,
  CampaignNonWorkingDaysConfigurationRequest,
} from './../../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckBotDaysOffResult {
  status: 'success' | 'fail';
  error?: ApiError<CampaignNonWorkingDaysConfiguration>;
  botDaysOff?: CampaignNonWorkingDaysConfiguration;
}

export const getBotDaysOffList = (id: string) =>
  api.strategiesCampaignNonWorkingDaysConfigurationList(id).then((_) => _.data);

export const createBotDaysOff = (
  data: CampaignNonWorkingDaysConfigurationRequest,
): Promise<CheckBotDaysOffResult> =>
  api
    .campaignNonWorkingDaysConfigurationCreate(data)
    .then(() => {
      return { status: 'success' } as CheckBotDaysOffResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getBotDaysOffDetailed = (id: string) =>
  api.campaignNonWorkingDaysConfigurationRetrieve(id).then((_) => _.data);

export const updateBotDaysOff = (
  id: string,
  data: CampaignNonWorkingDaysConfigurationRequest,
): Promise<CheckBotDaysOffResult> =>
  api
    .campaignNonWorkingDaysConfigurationUpdate(id, data)
    .then(() => {
      return { status: 'success', botDaysOff: data } as CheckBotDaysOffResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteBotDaysOff = (id: string): Promise<CheckBotDaysOffResult> =>
  api
    .campaignNonWorkingDaysConfigurationDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckBotDaysOffResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const TimeIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M8 3.98633C8 3.71019 7.77614 3.48633 7.5 3.48633C7.22386 3.48633 7 3.71019 7 3.98633V9.48633C7 9.66576 7.09614 9.83143 7.25193 9.92045L10.7519 11.9205C10.9917 12.0575 11.2971 11.9742 11.4341 11.7344C11.5711 11.4946 11.4878 11.1892 11.2481 11.0522L8 9.19617V3.98633Z" />
    <path d="M8 16.4863C12.4183 16.4863 16 12.9046 16 8.48633C16 4.06805 12.4183 0.486328 8 0.486328C3.58172 0.486328 0 4.06805 0 8.48633C0 12.9046 3.58172 16.4863 8 16.4863ZM15 8.48633C15 12.3523 11.866 15.4863 8 15.4863C4.13401 15.4863 1 12.3523 1 8.48633C1 4.62033 4.13401 1.48633 8 1.48633C11.866 1.48633 15 4.62033 15 8.48633Z" />
  </StyledSvg>
);

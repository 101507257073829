import {
  EuiDragDropContext,
  euiDragDropReorder,
  euiDragDropMove,
  DropResult,
  EuiDraggable,
  EuiDroppable,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import {
  NewColumnContainer,
  CreateButtonContainer,
  IconContainer,
  ProcessesColumnsContainer,
  ProcessPanelContainer,
} from './ProcessesPanel.style';
import ProcessesColumn from './ProcessesColumn';
import { H3 } from '../../../App.style';
import AddColumnModal from './modals/AddColumnModal';
import DeleteColumnModal from './modals/DeleteColumnModal';
import EditColumnModal from './modals/EditColumnModal';
import ProcessesBar from './bar/ProcessesBar';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  DashboardColumnRequest,
  PatchedDashboardMoveRequest,
  Process,
  ProcessCreateRequest,
} from '../../../../generated/tenants/Api';
import {
  selectStrategiesList,
  selectStrategyId,
} from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkCampaignCreate,
  endCreatingCampaign,
  startCreatingCampaign,
} from '../../campaigns/api/campaignsSlice';
import AddProcessModal from './modals/AddProcessModal';
import { useNavigate } from 'react-router-dom';
import {
  checkProcessCreate,
  createProcessAsyncThunk,
  createProcessesPanelColumnAsyncThunk,
  deleteProcessesesPanelColumnAsyncThunk,
  endCreateProcess,
  endUpdateProcess,
  getProcessesListAsyncThunk,
  getProcessesPanelColumnsAsyncThunk,
  reorderProcessesPanelColumnsAsyncThunk,
  selectProcessesList,
  selectProcessesPanelColumns,
  startCreateProcess,
  updateProcessesPanelColumnAsyncThunk,
} from '../api/processSlice';
import { NewPresetIcon } from '../../../../resources/icons-new/NewPresetIcon';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../users/usePermissions';
import ProcessListLoading from './ProcessListLoading';
import CampaignCreateModal from '../../campaigns/CampaignCreateModal';
import ErrorPopup from '../../../../common/popups/ErrorPopup';

export interface ColumnData {
  id: string;
  name: string;
  items: Process[];
}

export interface ColumnsType {
  columns: ColumnData[];
}

interface ProcessesPanelProps {
  handleSeeCampaignsClick: (id: string) => void;
}

const ProcessesPanel: React.FC<ProcessesPanelProps> = ({
  handleSeeCampaignsClick,
}) => {
  const { t } = useTranslation();
  const strategyId = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [columns, setColumns] = useState<ColumnsType>({ columns: [] });
  const { checkPermission } = usePermissions();
  const hasProcessAccess = checkPermission('process');
  const hasWorkspaceAccess = checkPermission('workspace_admin');
  const apiStrategiesList = useAppSelector(selectStrategiesList);

  useEffect(() => {
    dispatch(
      getProcessesListAsyncThunk({
        id: strategyId,
        query: { is_archived: true },
      }),
    );

    dispatch(getProcessesPanelColumnsAsyncThunk(strategyId)).finally(() => {
      setLoading(false);
    });
    dispatch(endCreateProcess());
    dispatch(endUpdateProcess());
  }, []);

  const dashboard = useAppSelector(selectProcessesPanelColumns);

  //translates data from api to frontend
  useEffect(() => {
    const newColumnsOrder: ColumnData[] = [];

    dashboard?.columns.forEach((column) => {
      const tempColumn: ColumnData = {
        id: column.column.id,
        name: column.column.name,
        items: column.column_processes,
      };
      newColumnsOrder.push(tempColumn);
    });

    if (dashboard?.uncategorized) {
      newColumnsOrder.push({
        id: 'uncategorized',
        name: 'Uncategorized',
        items: dashboard?.uncategorized,
      });
    }

    setColumns({ columns: newColumnsOrder });
  }, [dashboard]);

  //functino handling moving columns and proceses
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (source && destination) {
      if (source.droppableId === destination.droppableId) {
        if (source.droppableId === 'list') {
          if (destination.index !== columns.columns.length - 1) {
            const updatedOrder = euiDragDropReorder(
              columns.columns,
              source.index,
              destination.index,
            );

            setColumns((prevColumns) => ({
              ...prevColumns,
              columns: updatedOrder,
            }));
          } else {
            return;
          }
        } else {
          const items = euiDragDropReorder(
            columns.columns.find((col) => col.id === source.droppableId)
              ?.items ?? [],

            source.index,
            destination.index,
          );

          setColumns((prevColumns) => ({
            ...prevColumns,
            columns: prevColumns.columns.map((col) =>
              col.id === source.droppableId ? { ...col, items: items } : col,
            ),
          }));
        }
      } else {
        const sourceId = source.droppableId;
        const destinationId = destination.droppableId;
        const res = euiDragDropMove(
          columns.columns.find((col) => col.id === sourceId)?.items ?? [],
          columns.columns.find((col) => col.id === destinationId)?.items ?? [],
          source,
          destination,
        );

        setColumns((prevColumns) => ({
          ...prevColumns,
          columns: prevColumns.columns.map((col) => {
            if (col.id === sourceId) {
              return { ...col, items: res[source.droppableId] };
            } else if (col.id === destinationId) {
              return { ...col, items: res[destination.droppableId] };
            } else {
              return col;
            }
          }),
        }));
      }
    }
  };

  //gets current columns object, and returns id's
  function getProcessIdsByColumn(
    columns: ColumnData[],
  ): PatchedDashboardMoveRequest {
    const result = columns.map((column) => ({
      column_id: column.id,
      column_processes: column.items.map((process) => process.id),
    }));

    const uncategorized = result.pop()?.column_processes || [];

    return { columns: result, uncategorized };
  }

  //if anything changes in columns, send new order to backend
  useEffect(() => {
    if (edit) {
      const newDashboardOrderData = getProcessIdsByColumn(columns.columns);

      const reorderData = {
        strategyId: strategyId,
        data: newDashboardOrderData,
      };

      dispatch(reorderProcessesPanelColumnsAsyncThunk(reorderData));
    }
  }, [columns]);

  const [edit, setEdit] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>('');

  const handleEditClick = () => {
    setSearchValue('');
    setEdit(!edit);
  };

  //modals logic

  ////add column logic
  const [isAddingColumnVisible, setIsAddingColumnVisible] =
    useState<boolean>(false);
  const openAddNewColumnModal = () => setIsAddingColumnVisible(true);
  const closeAddNewColumnModal = () => setIsAddingColumnVisible(false);

  const addNewColumn = (columnName: string) => {
    const newColumnData: DashboardColumnRequest = {
      name: columnName,
      strategy: strategyId,
    };

    dispatch(createProcessesPanelColumnAsyncThunk(newColumnData));
  };

  const [isDeleteColumnVisible, setIsDeleteColumnVisible] =
    useState<boolean>(false);
  const openDeleteColumnModal = () => setIsDeleteColumnVisible(true);
  const closeDeleteColumnModal = () => setIsDeleteColumnVisible(false);

  const [selectedColumn, setSelectedColumn] = useState<ColumnData>();

  const showDeleteModal = (column: ColumnData) => {
    setSelectedColumn(column);
    openDeleteColumnModal();
  };

  const deleteColumnAfterModal = () => {
    if (selectedColumn) {
      dispatch(deleteProcessesesPanelColumnAsyncThunk(selectedColumn?.id));
    } else {
      alert('something went wrong');
    }
  };

  //////edit column name logic
  const [isEditColumnModalVisible, setIsEditColumnModalVisible] =
    useState<boolean>(false);
  const openEditColumnModal = () => setIsEditColumnModalVisible(true);
  const closeEditColumnModal = () => setIsEditColumnModalVisible(false);

  const showEditModal = (column: ColumnData) => {
    setSelectedColumn(column);
    openEditColumnModal();
  };

  const editColumnNameAfterModal = (newName: string) => {
    if (selectedColumn) {
      const updateColumnData = {
        id: selectedColumn?.id,
        data: { name: newName, strategy: strategyId },
      };
      dispatch(updateProcessesPanelColumnAsyncThunk(updateColumnData));
    } else {
      alert('something went wrong');
    }
  };

  const [isCreateProcessModalVisible, setIsCreateProcessModalVisible] =
    useState<boolean>(false);
  const openCreateProcessModal = () => setIsCreateProcessModalVisible(true);
  const closeCreateProcessModal = () => setIsCreateProcessModalVisible(false);

  const createProcess = (name: string) => {
    dispatch(startCreateProcess());
    navigate(`/dashboard/process/create/${name}`);
  };

  const apiProcessesList = useAppSelector(selectProcessesList);
  //duplicate process

  const handleDuplicateProcess = (id: string) => {
    dispatch(startCreateProcess());
    const selectedProcess = apiProcessesList.find(
      (calendar) => calendar.id === id,
    );
    if (selectedProcess) {
      let count = 1;
      let newName = selectedProcess.name;

      let nameExists = apiProcessesList.some((item) => item.name === newName);

      while (nameExists) {
        newName =
          selectedProcess.name + ' - copy' + (count > 1 ? `(${count})` : '');
        nameExists = apiProcessesList.some((item) => item.name === newName);
        count++;
      }

      const copiedProcess: ProcessCreateRequest = {
        ...selectedProcess,
        name: newName,
        report_per_call_custom_faas: selectedProcess.report_per_call_custom_faas
          ? selectedProcess.report_per_call_custom_faas.id
          : '',
        report_per_record_custom_faas:
          selectedProcess.report_per_record_custom_faas
            ? selectedProcess.report_per_record_custom_faas.id
            : '',
        report_per_campaign_custom_faas:
          selectedProcess.report_per_campaign_custom_faas
            ? selectedProcess.report_per_campaign_custom_faas.id
            : '',
        get_customer_info_faas: selectedProcess.get_customer_info_faas
          ? selectedProcess.get_customer_info_faas.id
          : '',
        report_feedback_faas: selectedProcess.report_feedback_faas
          ? selectedProcess.report_feedback_faas.id
          : '',
        labels: selectedProcess.labels.map((label) => label.id),
        phone_numbers: selectedProcess.phone_numbers.map((number) => number.id),
      };

      dispatch(createProcessAsyncThunk(copiedProcess));
    }
  };

  const checkCreate = useAppSelector(checkProcessCreate);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      dispatch(getProcessesPanelColumnsAsyncThunk(strategyId));
      dispatch(getProcessesListAsyncThunk({ id: strategyId }));
      dispatch(endCreateProcess());
    }
  }, [checkCreate]);

  //campaign create
  const [selectedProcessId, setSelectedProcessId] = useState<string>('');
  const [campaignCreateErrorPopupVisible, setCampaignCreateErrorPopupVisible] =
    useState<boolean>(false);
  const closeCampaignCreateModal = () => {
    dispatch(endCreatingCampaign());
  };
  const showCampaignCreateModal = (id: string) => {
    dispatch(startCreatingCampaign());
    setSelectedProcessId(id);
  };

  const checkCampaign = useAppSelector(checkCampaignCreate);
  useEffect(() => {
    if (
      checkCampaign?.state === 'idle' &&
      checkCampaign.value?.status === 'success' &&
      checkCampaign.value.campaign
    ) {
      closeCampaignCreateModal();
      navigate(`/dashboard/campaigns/${checkCampaign.value.campaign.id}`, {
        state: { showCreatePopup: true },
      });
    }

    if (
      checkCampaign?.state === 'idle' &&
      checkCampaign.value?.status === 'fail'
    ) {
      closeCampaignCreateModal();
      setCampaignCreateErrorPopupVisible(true);
    }
  }, [checkCampaign]);

  const [loading, setLoading] = useState<boolean>(true);
  return (
    <ProcessPanelContainer>
      {hasWorkspaceAccess && (
        <AddColumnModal
          isVisible={isAddingColumnVisible}
          closeModal={closeAddNewColumnModal}
          addNewColumn={addNewColumn}
        />
      )}

      {hasProcessAccess && (
        <AddProcessModal
          isVisible={isCreateProcessModalVisible}
          closeModal={closeCreateProcessModal}
          createProcess={createProcess}
          processesList={apiProcessesList}
        />
      )}
      {/* delete modal */}
      {selectedColumn && hasWorkspaceAccess && (
        <>
          <DeleteColumnModal
            isVisible={isDeleteColumnVisible}
            selectedColumn={selectedColumn}
            closeModal={closeDeleteColumnModal}
            deleteColumn={deleteColumnAfterModal}
          />

          {/* edit modal */}

          <EditColumnModal
            isVisible={isEditColumnModalVisible}
            selectedColumn={selectedColumn}
            closeModal={closeEditColumnModal}
            editColumn={editColumnNameAfterModal}
          />
        </>
      )}

      {apiProcessesList.length > 0 && (
        <CampaignCreateModal
          isVisible={checkCampaign?.state ? true : false}
          closeModal={closeCampaignCreateModal}
          processesList={apiProcessesList}
          strategiesList={apiStrategiesList}
          processId={selectedProcessId}
        />
      )}
      {campaignCreateErrorPopupVisible && (
        <ErrorPopup
          onClose={() => setCampaignCreateErrorPopupVisible(false)}
          text={`Sorry, an error occured! Campaign wasn't created`}
          textLine2={'Please try again'}
        />
      )}

      <ProcessesBar
        edit={edit}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleEditClick={handleEditClick}
        openCreateProcessModal={openCreateProcessModal}
        hasProcessAccess={hasProcessAccess}
        hasWorkspaceAccess={hasWorkspaceAccess}
      />

      <ProcessesColumnsContainer>
        {loading ? (
          <ProcessListLoading />
        ) : (
          <EuiDragDropContext onDragEnd={onDragEnd}>
            <EuiDroppable
              droppableId="list"
              type="MACRO"
              direction="horizontal"
              spacing="m"
              style={{ display: 'flex', background: 'white' }}
            >
              {columns.columns.map((column, didx) => (
                <EuiDraggable
                  key={column.id}
                  index={didx}
                  draggableId={`COMPLEX_DRAGGABLE_${column.id}`}
                  spacing="l"
                  disableInteractiveElementBlocking
                  hasInteractiveChildren={true}
                  isDragDisabled={!edit || column.id === 'uncategorized'}
                >
                  <ProcessesColumn
                    key={column.id}
                    column={column}
                    edit={edit && hasProcessAccess}
                    deleteColumn={showDeleteModal}
                    editColumn={showEditModal}
                    searchValue={searchValue}
                    handleSeeCampaignsClick={handleSeeCampaignsClick}
                    handleDuplicateProcess={handleDuplicateProcess}
                    hasProcessAccess={hasProcessAccess}
                    showCampaignCreateModal={showCampaignCreateModal}
                  />
                </EuiDraggable>
              ))}
            </EuiDroppable>
          </EuiDragDropContext>
        )}

        {edit && (
          <NewColumnContainer>
            <CreateButtonContainer onClick={openAddNewColumnModal}>
              <IconContainer>
                <NewPresetIcon
                  style={{
                    position: 'absolute',
                    left: 17,
                    top: 17,
                    width: 15,
                    height: 15,
                  }}
                />
              </IconContainer>
              <H3>{t('processes.addNewColumn')}</H3>
            </CreateButtonContainer>
          </NewColumnContainer>
        )}
      </ProcessesColumnsContainer>
    </ProcessPanelContainer>
  );
};

export default ProcessesPanel;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { selectStrategyId } from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import CallCenterCreate from './CallCenterCreate';
import BackToList from '../../shared/BackToList';
import { PresetContainer } from '../../PresetsPanel.style';
import {
  checkCallCenterDaysOffCreate,
  checkCallCenterDaysOffDelete,
  checkCallCenterDaysOffUpdate,
  createCallCenterDaysOffAsyncThunk,
  deleteCallCenterDaysOffAsyncThunk,
  getCallCenterDaysOffListAsyncThunk,
  selectCallCenterDaysOffList,
} from './api/callCentreDaysOffSlice';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../../processes/api/processSlice';
import { CallCenterNonWorkingDaysConfiguration } from '../../../../../generated/tenants/Api';
import PresetDeleteModal from '../../PresetDeleteModal';
import CallCenterDaysOffList from './CallCenterDaysOffList';
import CallCenterDaysOffDetailed from './CallCenterDaysOffDetailed';
import usePermissions from '../../../users/usePermissions';
import LoadingModal from '../../../../../common/modals/LoadingModal';

const CallCenterDaysOff = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('process_campaign');

  const [ccDaysOffState, setCcDaysOffState] = useState<
    'create' | 'list' | 'detailed'
  >('list');

  const handleCreateCallCenterDaysOffClick = () => setCcDaysOffState('create');

  const handleDetailedViewClick = (ccDaysOffId: string) => {
    const ccDaysOffDetailed = apiCallCenterDaysOffList.find(
      (daysOff) => daysOff.id === ccDaysOffId,
    );
    if (ccDaysOffDetailed) setSelectedCallCenterDaysOff(ccDaysOffDetailed);
    setCcDaysOffState('detailed');
  };

  const handleBackToList = () => setCcDaysOffState('list');

  useEffect(() => {
    dispatch(getCallCenterDaysOffListAsyncThunk(strategy));
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);

  const apiCallCenterDaysOffList = useAppSelector(selectCallCenterDaysOffList);

  const apiProcesessList = useAppSelector(selectProcessesList);

  function findProcessNamesById(id: string): string[] {
    return apiProcesessList
      .filter((item) => item.call_center_non_working_days_configuration === id)
      .map((item) => item.name);
  }

  const [selectedCallCenterDaysOff, setSelectedCallCenterDaysOff] =
    useState<CallCenterNonWorkingDaysConfiguration>();

  const checkUpdate = useAppSelector(checkCallCenterDaysOffUpdate);
  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.callCenterDaysOff &&
      checkUpdate.value.status === 'success'
    ) {
      setSelectedCallCenterDaysOff(checkUpdate.value.callCenterDaysOff);
    }
  });

  ///delete logic
  const [
    isDeleteCallCenterDaysOffVisible,
    setIsDeleteCallCenterDaysOffVisible,
  ] = useState<boolean>(false);

  const openDeleteCallCenterModal = () =>
    setIsDeleteCallCenterDaysOffVisible(true);
  const closeDeleteCallCenterModal = () =>
    setIsDeleteCallCenterDaysOffVisible(false);

  const handleDeleteCallCenterDaysOff = (id: string) => {
    const ccDaysOffDetailed = apiCallCenterDaysOffList.find(
      (daysOff) => daysOff.id === id,
    );
    if (ccDaysOffDetailed) setSelectedCallCenterDaysOff(ccDaysOffDetailed);
    openDeleteCallCenterModal();
  };

  const deleteCallCenterDaysOffAfterModal = () => {
    if (selectedCallCenterDaysOff) {
      setIsLoadingVisible(true);
      dispatch(
        deleteCallCenterDaysOffAsyncThunk(selectedCallCenterDaysOff.id),
      ).finally(() => {
        setIsLoadingVisible(false);
      });
      closeDeleteCallCenterModal();
    } else {
      alert('error');
    }
  };

  const checkDeleteCallCenterDaysOff = useAppSelector(
    checkCallCenterDaysOffDelete,
  );

  useEffect(() => {
    if (
      checkDeleteCallCenterDaysOff.value?.status === 'success' &&
      checkDeleteCallCenterDaysOff.state === 'idle'
    ) {
      dispatch(getCallCenterDaysOffListAsyncThunk(strategy));
      setSelectedCallCenterDaysOff(undefined);
    }
  }, [checkDeleteCallCenterDaysOff]);

  //clone
  const handleDuplicateCallCenterDaysOff = (id: string) => {
    const ccDaysOffDetailed = apiCallCenterDaysOffList.find(
      (daysOff) => daysOff.id === id,
    );
    if (ccDaysOffDetailed) {
      let count = 1;
      let newName = ccDaysOffDetailed.name;

      let nameExists = apiCallCenterDaysOffList.some(
        (item) => item.name === newName,
      );

      while (nameExists) {
        newName =
          ccDaysOffDetailed.name + ' - copy' + (count > 1 ? `(${count})` : '');
        nameExists = apiCallCenterDaysOffList.some(
          (item) => item.name === newName,
        );
        count++;
      }

      const copiedCallingPref = {
        ...ccDaysOffDetailed,
        name: newName,
      };
      setIsLoadingVisible(true);
      dispatch(createCallCenterDaysOffAsyncThunk(copiedCallingPref)).finally(
        () => {
          setIsLoadingVisible(false);
        },
      );
    }
  };

  const checkCopy = useAppSelector(checkCallCenterDaysOffCreate);

  useEffect(() => {
    if (checkCopy.state === 'idle' && checkCopy.value?.status === 'success') {
      dispatch(getCallCenterDaysOffListAsyncThunk(strategy));
      setSelectedCallCenterDaysOff(undefined);
    }
  }, [checkCopy]);

  //components
  const componentsMap = {
    create: (
      <CallCenterCreate backToList={handleBackToList} strategy={strategy} />
    ),
    list: (
      <CallCenterDaysOffList
        callCenterDaysOffList={apiCallCenterDaysOffList}
        createCallCenterDaysOff={handleCreateCallCenterDaysOffClick}
        goToDetailed={handleDetailedViewClick}
        findProcessNamesById={findProcessNamesById}
        handleDeleteCallCenterDaysOff={handleDeleteCallCenterDaysOff}
        handleDuplicateCallCenterDaysOff={handleDuplicateCallCenterDaysOff}
        hasPermission={hasPermission}
      />
    ),
    detailed: selectedCallCenterDaysOff && (
      <CallCenterDaysOffDetailed
        callCenterDaysOff={selectedCallCenterDaysOff}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesById}
        hasPermission={hasPermission}
      />
    ),
  };
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <PresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {selectedCallCenterDaysOff && (
        <PresetDeleteModal
          type={'call centre-days off'}
          isVisible={isDeleteCallCenterDaysOffVisible}
          presetName={selectedCallCenterDaysOff?.name}
          closeModal={closeDeleteCallCenterModal}
          deletePreset={deleteCallCenterDaysOffAfterModal}
        />
      )}

      {ccDaysOffState !== 'list' && (
        <BackToList handleBackToList={handleBackToList} />
      )}

      {componentsMap[ccDaysOffState]}
    </PresetContainer>
  );
};

export default CallCenterDaysOff;

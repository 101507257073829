import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const AudioPlayIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <g id="icon">
      <path
        id="Subtract"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM10.1859 7.6397C9.95732 7.47641 9.65662 7.45457 9.40681 7.58313C9.15701 7.71168 9 7.96906 9 8.25V15.75C9 16.0309 9.15701 16.2883 9.40681 16.4169C9.65662 16.5454 9.95732 16.5236 10.1859 16.3603L15.4359 12.6103C15.633 12.4695 15.75 12.2422 15.75 12C15.75 11.7578 15.633 11.5305 15.4359 11.3897L10.1859 7.6397Z"
        fill="#127FBF"
      />
    </g>
  </StyledSvg>
);

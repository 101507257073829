import { Moment } from 'moment';
import moment from 'moment';
import i18n from 'i18next';

export interface DateRange {
  start_date?: string;
  end_date?: string;
}

export const renderStatus = (status: string, type: 'text' | 'color') => {
  let color = '#000000';
  let name = 'UNKNOWN';

  switch (status) {
    case 'RUNNING':
      color = '#127FBF';
      name = i18n.t('processes.campaignStatus.running');
      break;
    case 'FINISHED':
      color = '#117B11';
      name = i18n.t('processes.campaignStatus.finished');
      break;
    case 'SCHEDULED':
      color = '#0000FF';
      name = i18n.t('processes.campaignStatus.scheduled');
      break;
    case 'ERROR':
      color = '#FF0000';
      name = i18n.t('processes.campaignStatus.error');
      break;
    case 'PAUSED':
      color = '#983700';
      name = i18n.t('processes.campaignStatus.paused');
      break;
    case 'PENDING':
      color = '#3E4C59';
      name = i18n.t('processes.campaignStatus.pending');
      break;
    case 'STOPPED':
      color = '#117B11';
      name = i18n.t('processes.campaignStatus.stopped');
      break;
  }

  if (type === 'text') return <span style={{ color }}>{name}</span>;
  else return color;
};

//status select do zmiany

export const statusOptions = [
  { value: 'RUNNING', text: 'RUNNING' },
  { value: 'PAUSED', text: 'PAUSED' },
  { value: 'FINISHED', text: 'FINISHED' },
  { value: 'ERROR', text: 'ERROR' },
  { value: 'SCHEDULED', text: 'SCHEDULED' },
  { value: 'PENDING', text: 'PENDING' },
];

export const formatDate = (date: string) => {
  return moment(date).format('DD.MM.YY');
};

export const formatTime = (date: string) => {
  return moment(date).format('HH:mm:ss');
};

export const addDays = (dateStr: string, daysToAdd: number): string => {
  const date: Moment = moment(dateStr, 'DD/MM/YY');

  date.add(daysToAdd, 'days');

  return date.format('DD/MM/YY');
};

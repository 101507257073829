import React, { useEffect, useState } from 'react';
import { useAutoClosePopup } from '../../../../../../common/popups/useAutoClosePopup';
import SuccessPopup from '../../../../../../common/popups/SuccessPopup';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import {
  DropResult,
  EuiDragDropContext,
  EuiLoadingSpinner,
  EuiDraggable,
  EuiSuperSelect,
  EuiToolTip,
  copyToClipboard,
} from '@elastic/eui';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { H3, H1, H2 } from '../../../../../App.style';
import {
  PhoneNumbersContainer,
  PhoneNumbersContentContainer,
  PhoneNumbersContentLeftContainer,
  PhoneNumbersDroppableContainer,
  LoadingNumbersContainer,
  PhoneNumberRowContainer,
  PhoneNumbersContentRightContainer,
  UnassignedNumbersTitleContainer,
  PhoneNumberRowCopyContainer,
} from '../../../adminPanel/components/phoneNumbers/PhoneNumbers.style';
import {
  checkTenantPhoneNumberUpdate,
  getAllNumbersListAsyncThunk,
  selectAllTenantNumbers,
  updateTenantPhoneNumberAsyncThunk,
} from '../../../adminPanel/components/phoneNumbers/api/phoneNumbersSlice';
import { formatPhoneNumber } from '../../../adminPanel/components/phoneNumbers/numbersFunctions';
import { PhoneNumberDetail } from '../../../../../../generated/public/Api';
import {
  getTenantsListAsyncThunk,
  selectTenantsList,
} from '../../api/superAdminSlice';
import ErrorPopup from '../../../../../../common/popups/ErrorPopup';
import { useTranslation } from 'react-i18next';
import { FakeButton } from '../../../../campaigns/Campaigns.style';

const NumbersTenantAssign = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tenantPhoneNumbers: Record<string, PhoneNumberDetail[]> = {};
  const unusedNumbers: PhoneNumberDetail[] = [];

  //search
  const [assignedNumbersSearch, setAssignedNumbersSearch] = useState('');
  const handleAssignedNumbersSearchChange = (value: string) => {
    setAssignedNumbersSearch(value);
  };
  const [availableNumbersSearch, setAvailableNumbersSearch] = useState('');
  const handleAvailablenumbersSearchChange = (value: string) => {
    setAvailableNumbersSearch(value);
  };

  useEffect(() => {
    dispatch(getTenantsListAsyncThunk());
  }, []);

  const apiTenantsList = useAppSelector(selectTenantsList);

  const tenantSelectOptions = apiTenantsList.map((tenant) => {
    return {
      value: tenant.name,
      inputDisplay: <H3>{tenant.name}</H3>,
      dropdownDisplay: <H3>{tenant.name}</H3>,
    };
  });

  const [selectedTenantSlug, setSelectedTenantSlug] = useState<string>('');

  useEffect(() => {
    if (apiTenantsList.length > 0 && selectedTenantSlug === '') {
      setSelectedTenantSlug(apiTenantsList[0].slug);
    }
  }, [apiTenantsList]);

  useEffect(() => {
    dispatch(getAllNumbersListAsyncThunk());
  }, []);

  const apiAllNumbers = useAppSelector(selectAllTenantNumbers);

  for (const phoneNumber of apiAllNumbers) {
    if (phoneNumber.tenant_slug) {
      if (!tenantPhoneNumbers[phoneNumber.tenant_slug]) {
        tenantPhoneNumbers[phoneNumber.tenant_slug] = [];
      }

      tenantPhoneNumbers[phoneNumber.tenant_slug].push(phoneNumber);
    } else {
      unusedNumbers.push(phoneNumber);
    }
  }

  const filteredNumbers = assignedNumbersSearch
    ? tenantPhoneNumbers[selectedTenantSlug].filter((date) =>
        date.phone_number.includes(assignedNumbersSearch),
      )
    : tenantPhoneNumbers[selectedTenantSlug];

  const filteredUnusedNumbers = availableNumbersSearch
    ? unusedNumbers.filter((date) =>
        date.phone_number.includes(availableNumbersSearch),
      )
    : unusedNumbers;

  const [loading, setLoading] = useState<boolean>(false);
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // Check if the destination is valid (item dropped in a droppable area)
    if (!destination) return;

    // Dragging from left to right (unusedNumbers to filteredNumbers)
    if (
      source.droppableId === 'unusedNumbersDroppable' &&
      destination.droppableId === 'filteredNumbersDroppable'
    ) {
      setLoading(true);
      const item = unusedNumbers[source.index];

      const selectedTenant = apiTenantsList.find(
        (tenant) => tenant.slug === selectedTenantSlug,
      );

      if (selectedTenant) {
        const data = {
          numberId: item.id,
          data: { company: selectedTenant.id },
        };

        dispatch(updateTenantPhoneNumberAsyncThunk(data));
      }
    }
  };

  const checkUpdate = useAppSelector(checkTenantPhoneNumberUpdate);
  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      dispatch(getAllNumbersListAsyncThunk());
      showNumberAssignedPopup();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'fail'
    ) {
      showErrorPopup();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [checkUpdate]);

  //popup
  const {
    isVisible: numberAssignedPopupVisible,
    showPopup: showNumberAssignedPopup,
    closePopup: closeNumberAssignedPopup,
  } = useAutoClosePopup();

  const {
    isVisible: errorPopupVisible,
    showPopup: showErrorPopup,
    closePopup: closeErrorPopup,
  } = useAutoClosePopup();

  const [phoneNumberCopied, setPhoneNumberCopied] = useState<boolean>(false);

  const handleNumberCopyClick = (number: string) => {
    copyToClipboard(number);
    setPhoneNumberCopied(true);
  };

  return (
    <PhoneNumbersContainer>
      {numberAssignedPopupVisible && (
        <SuccessPopup
          text={t('superAdminPanel.numbers.assign.popups.success')}
          onClose={closeNumberAssignedPopup}
        />
      )}

      {errorPopupVisible && (
        <ErrorPopup
          text={t('superAdminPanel.numbers.assign.popups.error')}
          onClose={closeErrorPopup}
        />
      )}

      <H1 $bold>{t('superAdminPanel.numbers.assign.title')}</H1>

      <PhoneNumbersContentContainer>
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <PhoneNumbersContentLeftContainer>
            <H2 $selected>{t('superAdminPanel.numbers.assign.all')}</H2>
            <H3>{t('superAdminPanel.numbers.assign.free')}</H3>
            <PhoneNumbersDroppableContainer
              droppableId="unusedNumbersDroppable"
              withPanel
              grow={false}
            >
              <>
                <div style={{ margin: 8 }}>
                  <SearchInput
                    style={{
                      border: '1px solid #9AA5B1',
                      borderRadius: 5,
                      outline: 'none',
                    }}
                    value={availableNumbersSearch}
                    setValue={handleAvailablenumbersSearchChange}
                  />
                </div>
                {loading ? (
                  <LoadingNumbersContainer>
                    <EuiLoadingSpinner size="xl" />
                  </LoadingNumbersContainer>
                ) : (
                  <>
                    {filteredUnusedNumbers &&
                    filteredUnusedNumbers.length > 0 ? (
                      filteredUnusedNumbers.map((number, idx) => (
                        <EuiDraggable
                          key={number.id}
                          index={idx}
                          draggableId={number.id}
                        >
                          {(provided, state) => (
                            <PhoneNumberRowContainer>
                              {formatPhoneNumber(number.phone_number)}

                              {state.isDragging && ' ✨'}
                            </PhoneNumberRowContainer>
                          )}
                        </EuiDraggable>
                      ))
                    ) : (
                      <div style={{ margin: 10 }}>
                        <H3>{t('superAdminPanel.numbers.assign.empty')}</H3>
                      </div>
                    )}
                  </>
                )}
              </>
            </PhoneNumbersDroppableContainer>
          </PhoneNumbersContentLeftContainer>
          <PhoneNumbersContentRightContainer>
            <UnassignedNumbersTitleContainer>
              <H2 $selected>{t('superAdminPanel.numbers.assign.tenant')}</H2>

              <EuiSuperSelect
                compressed
                style={{ width: 250, marginTop: -5 }}
                valueOfSelected={selectedTenantSlug}
                onChange={(e) => setSelectedTenantSlug(e)}
                options={tenantSelectOptions} //lista tenantow
              />
            </UnassignedNumbersTitleContainer>
            <PhoneNumbersDroppableContainer
              droppableId="filteredNumbersDroppable"
              withPanel
              grow={false}
            >
              <>
                <div style={{ margin: 8, paddingTop: 8 }}>
                  <SearchInput
                    style={{
                      border: '1px solid #9AA5B1',
                      borderRadius: 5,
                      outline: 'none',
                    }}
                    value={assignedNumbersSearch}
                    setValue={handleAssignedNumbersSearchChange}
                  />
                </div>
                {filteredNumbers && filteredNumbers.length > 0 ? (
                  filteredNumbers.map((number, idx) => (
                    <EuiDraggable
                      key={number.id}
                      index={idx}
                      draggableId={number.id}
                      isDragDisabled={true}
                    >
                      {() => (
                        <PhoneNumberRowContainer>
                          {formatPhoneNumber(number.phone_number)}
                          <PhoneNumberRowCopyContainer>
                            <EuiToolTip
                              content={
                                phoneNumberCopied
                                  ? t(
                                      'superAdminPanel.numbers.assign.copiedNumber',
                                    )
                                  : t(
                                      'superAdminPanel.numbers.assign.copyNumber',
                                    )
                              }
                              position="top"
                            >
                              <FakeButton
                                onClick={() =>
                                  handleNumberCopyClick(number.phone_number)
                                }
                                onMouseLeave={() => setPhoneNumberCopied(false)}
                              >
                                {t('superAdminPanel.numbers.assign.copy')}
                              </FakeButton>
                            </EuiToolTip>
                          </PhoneNumberRowCopyContainer>
                        </PhoneNumberRowContainer>
                      )}
                    </EuiDraggable>
                  ))
                ) : (
                  <div style={{ margin: 10 }}>
                    <H3>{t('superAdminPanel.numbers.assign.empty')}</H3>
                  </div>
                )}
              </>
            </PhoneNumbersDroppableContainer>
          </PhoneNumbersContentRightContainer>
        </EuiDragDropContext>
      </PhoneNumbersContentContainer>
    </PhoneNumbersContainer>
  );
};

export default NumbersTenantAssign;

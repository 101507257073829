import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const DialogueFailIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM4.01516 3.48484C3.86872 3.33839 3.63128 3.33839 3.48484 3.48484C3.33839 3.63128 3.33839 3.86872 3.48484 4.01516L5.46967 6L3.48484 7.98483C3.33839 8.13128 3.33839 8.36872 3.48484 8.51517C3.63128 8.66161 3.86872 8.66161 4.01516 8.51517L6 6.53033L7.98483 8.51517C8.13128 8.66161 8.36872 8.66161 8.51517 8.51517C8.66161 8.36872 8.66161 8.13128 8.51517 7.98483L6.53033 6L8.51517 4.01516C8.66161 3.86872 8.66161 3.63128 8.51517 3.48484C8.36872 3.33839 8.13128 3.33839 7.98483 3.48484L6 5.46967L4.01516 3.48484Z"
      fill="#BA2525"
    />
  </StyledSvg>
);

import styled from 'styled-components';
import {
  fontIBM,
  BLACK_typography,
  COLOR_NEUTRAL_20,
  H4,
} from '../../../App.style';

interface LabelsProps {
  color: string;
  fontWhite?: boolean;
}

export const Label = styled.div<LabelsProps>`
  width: auto !important;

  border-radius: 6px;

  height: 18px;

  background: ${(props) => props.color};

  padding: 2px 5px;

  font-family: ${fontIBM};
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => (props.fontWhite ? 'white' : BLACK_typography)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
`;

export const LabelsContentContainer = styled.div`
  width: 100%;

  margin-top: 40px;

  padding-top: 30px;
  padding-bottom: 50px;

  max-height: 600px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

//table
export const LabelsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  height: 100%;
  margin-bottom: 20px;
`;

export const LabelsListActionsContainer = styled.div`
  display: flex;
  gap: 15px;

  ${H4} {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const LabelsRowContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;

  padding: 6px 0px;

  border-bottom: 0.6px solid ${COLOR_NEUTRAL_20};

  margin-bottom: 5px;
`;

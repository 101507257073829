import { EuiFormRow } from '@elastic/eui';
import styled from 'styled-components';
import {
  BLACK_typography,
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_WHITE,
} from '../../../App.style';

export const ProcessFormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  background: ${COLOR_NEUTRAL_10};
`;

export const ProcessFormContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 35px);

  background: #f5f7fa;
`;

export const ProcessTitleContainer = styled.div`
  width: 100%;
  background: ${COLOR_NEUTRAL_10};

  padding-bottom: 10px;

  display: flex;
  justify-content: space-between;
`;

export const Sidebar = styled.div`
  width: 250px;
  min-width: 210px;

  margin-right: 10px;
  margin-top: 0px;
`;

export const TabButton = styled.button<{ $selected: boolean }>`
  display: flex;
  background-color: ${({ $selected }) =>
    $selected ? '#ffffff' : 'transparent'};
  height: 50px;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-radius: 5px;

  color: ${BLACK_typography};

  justify-content: space-between;

  align-items: center;

  border-bottom: 0.5px solid ${COLOR_NEUTRAL_20};

  &:hover {
    background-color: ${COLOR_WHITE};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 300px;
  justify-content: flex-end;
  flex-direction: row;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  overflow: auto;

  border-radius: 5px;

  position: relative;

  background: ${COLOR_WHITE};

  display: flex;
  justify-content: center;

  overflow: auto;
`;

//GeneralForm

export const GeneralFormTip = styled.div`
  position: absolute;
  left: 13px;
  top: 13px;
`;

interface FormContainerProps {
  $processForm?: boolean;
  $calendarForm?: boolean;
}

export const FormContainer = styled.form<FormContainerProps>`
  width: 100%;

  margin-top: ${(props) => (props.$processForm ? '20px' : '40px')};

  padding-top: ${(props) => (props.$processForm ? '0px' : '30px')};
  padding-bottom: 50px;

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormRow = styled(EuiFormRow)`
  margin-bottom: 16px;
  width: 100%;
`;

export const FormRowLabel = styled.div`
  display: flex;
  gap: 8px;
`;

export const PresetsBorder = styled.div`
  min-width: 650px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  align-items: center;

  margin-top: -50px;
  margin-bottom: 30px;
  padding-top: 50px;
`;

export const DayOffRow = styled.div`
  width: 60%;
  margin-bottom: 10px;
`;

export const PresetCreateButton = styled.div`
  position: absolute;

  right: 61px;
  top: 29px;
`;

export const SwitchFormRowContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin-bottom: 16px;
`;

export const CyclicFormContainer = styled.div`
  margin-top: 16px;
  display: flex;
  width: 400px;
  border-left: 1px solid #bfbfbf;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16px;
`;

import {
  EuiFlexGroup,
  EuiStepsHorizontal,
  EuiFlexItem,
  EuiText,
  EuiAccordion,
  EuiPanel,
  EuiButton,
  EuiSelect,
  EuiCheckbox,
  EuiLink,
  EuiFieldNumber,
} from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VindicationBackToPreset } from '../../BotsStartPanel';
import TokenInput from '../tokenInputComponents/TokenInput';
import { SUPPORTED_MODULES } from './VinIdentification';

interface GenerationsConfigVinPaymentStatus {
  'INF-STA-debt_intro-1-1': string;
  'REQ-INF-is_payment_done-1-1': string;
  'INF-STA-waiting_for_transfer-1-1': string;
  'INF-STA-negotiation_intro_partially_paid-1-1': string;
  'FEE-UNC-REQ-INF-past_payment_date-1-1': string;
  'REQ-INF-past_payment_amount-1-1': string;
  'INF-STA-payment_not_found-1-1': string;
}

interface BotStrategyConfigVinPaymentStatus {
  DEFAULT_CURRENCY: string;
  MIN_ACCEPTABLE_PAST_PAYMENT_AMOUNT: { percent: number };
  PAST_PAYMENT_DATE_QUESTION: boolean;
  ACCEPTABLE_DAYS_SINCE_PAYMENT?: number;
  PAST_PAYMENT_AMOUNT_QUESTION: boolean;
  BRANCHES: {
    DEBTOR_STATUS: { success: string; not_acceptable_date: string };
  };
  SUPPORTED_CURRENCIES: {
    [text: string]: boolean;
  };
}

const VinPaymentStatus = () => {
  const navigate = useNavigate();

  const [
    vinPaymentStatusGenerationsConfig,
    setVinPaymentStatusGenerationsConfig,
  ] = useState<GenerationsConfigVinPaymentStatus>();
  const [
    vinPaymentStatusBotStrategyConfig,
    setVinPaymentStatusBotStrategyConfig,
  ] = useState<BotStrategyConfigVinPaymentStatus>();

  //if there are items in storage, set them, otherwise set standard values
  useEffect(() => {
    localStorage.setItem('lastVisitedStep', '3');
    const storageGenerationsConfig = localStorage.getItem(
      'vinPaymentStatusGenerationsConfig',
    );
    if (storageGenerationsConfig !== null) {
      setVinPaymentStatusGenerationsConfig(
        JSON.parse(storageGenerationsConfig),
      );
    } else {
      setVinPaymentStatusGenerationsConfig({
        'INF-STA-debt_intro-1-1':
          'Kontaktuję się z Panią w sprawie zaległej płatności za %_PRODUCTNAME_NOM na kwotę %_CURRENTDEBTAMOUNT_NOM , której termin minął %_DEADLINEMAX_NOM.',
        'REQ-INF-is_payment_done-1-1': 'Czy dokonała Pani juz tej wpłaty?',
        'INF-STA-waiting_for_transfer-1-1':
          'Rozumiem. W takim razie czekam na zaksięgowanie wpłaty na rachunku. Od momentu wpłaty do zaksięgowania może minąć kilka dni.',
        'FEE-UNC-REQ-INF-past_payment_date-1-1':
          'Kiedy płatność została wysłana?',
        'INF-STA-negotiation_intro_partially_paid-1-1':
          'Do zapłaty pozostało %_CURRENTDEBTAMOUNT_NOM',
        'REQ-INF-past_payment_amount-1-1':
          'Proszę podać kwotę, którą Pani zapłaciła',
        'INF-STA-payment_not_found-1-1':
          'Niestety, nie odnalazłem takiej wpłaty w systemie',
      });
    }

    const storageBotStrategyConfig = localStorage.getItem(
      'vinPaymentStatusBotStrategyConfig',
    );
    if (storageBotStrategyConfig !== null) {
      const storageDataBotStrategy = JSON.parse(storageBotStrategyConfig);
      setVinPaymentStatusBotStrategyConfig(storageDataBotStrategy);
      setCurrenciesChecked(storageDataBotStrategy.SUPPORTED_CURRENCIES);
    } else {
      setVinPaymentStatusBotStrategyConfig({
        DEFAULT_CURRENCY: defaultCurrencyOptions[0].value,
        MIN_ACCEPTABLE_PAST_PAYMENT_AMOUNT: { percent: 30 },
        PAST_PAYMENT_DATE_QUESTION: false,
        ACCEPTABLE_DAYS_SINCE_PAYMENT: 3,
        PAST_PAYMENT_AMOUNT_QUESTION: false,
        BRANCHES: {
          DEBTOR_STATUS: {
            success: SUPPORTED_MODULES[0].value,
            not_acceptable_date: SUPPORTED_MODULES[3].value,
          },
        },
        SUPPORTED_CURRENCIES: {},
      });
    }
  }, []);

  //if user is making changes, save to localStorage
  useEffect(() => {
    localStorage.setItem(
      'vinPaymentStatusGenerationsConfig',
      JSON.stringify(vinPaymentStatusGenerationsConfig),
    );
  }, [vinPaymentStatusGenerationsConfig]);
  useEffect(() => {
    localStorage.setItem(
      'vinPaymentStatusBotStrategyConfig',
      JSON.stringify(vinPaymentStatusBotStrategyConfig),
    );
  }, [vinPaymentStatusBotStrategyConfig]);

  const handleSubmitPaymentStatusClick = () => {
    let arr: string[];
    const completedSteps = localStorage.getItem('vinCompletedSteps');
    if (completedSteps !== null && !completedSteps?.includes('3')) {
      arr = JSON.parse(completedSteps);
      arr.push('3');
      arr.sort();
      localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
    } else if (completedSteps && completedSteps.length > 0) {
      console.log('this step was already added');
    } else {
      arr = ['3'];
      localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
    }
    navigate('/dashboard/bots/new/1/4');
  };

  //closing/opening accordion state
  const [showAccordion, setShowAccordion] = useState<{
    currencyConfig: boolean;
    debtInfo: boolean;
    paymentDate: boolean;
    paymentAmount: boolean;
  }>({
    currencyConfig: false,
    debtInfo: false,
    paymentDate: false,
    paymentAmount: false,
  });

  //steps logic
  let steps: string[] = [];
  if (localStorage.getItem('vinCompletedSteps') !== null) {
    steps = JSON.parse(localStorage.getItem('vinCompletedSteps') as string);
  }
  const horizontalSteps = [
    {
      title: 'Identification',
      status: steps.includes('1') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/1');
      },
    },
    {
      title: 'Verification',
      status: steps.includes('2') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/2');
      },
    },
    {
      title: 'Payment status',
      status: 'current',
      onClick: () => {
        navigate('/dashboard/bots/new/1/3');
      },
    },
    {
      title: 'Negotiations',
      status: steps.includes('4') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/4');
      },
    },
    {
      title: 'Auxiliary modules',
      status: steps.includes('5') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/5');
      },
    },
    {
      title: 'Summary',
      status: steps.includes('6') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/6');
      },
    },
  ];

  const discardBotConfiguration = () => {
    navigate('/dashboard/bots');
  };

  const SUPPORTED_CURRENCIES = [
    'PLN',
    'EUR',
    'USD',
    'CAD',
    'AUD',
    'GBP',
    'CHF',
    'UAH',
    'JPY',
    'SEK',
    'DKK',
    'NOK',
    'CZK',
    'BGN',
    'HRK',
    'RON',
  ];
  const defaultCurrencyOptions = SUPPORTED_CURRENCIES.map((val) => {
    return { value: val, text: val };
  });

  //sets the default currency as checked
  useEffect(() => {
    if (vinPaymentStatusBotStrategyConfig?.DEFAULT_CURRENCY !== undefined) {
      const currency: string =
        vinPaymentStatusBotStrategyConfig?.DEFAULT_CURRENCY;
      setCurrenciesChecked({ ...currenciesChecked, [currency]: true });
    }
  }, [vinPaymentStatusBotStrategyConfig?.DEFAULT_CURRENCY]);

  //state of checkboxes
  const [currenciesChecked, setCurrenciesChecked] = useState<{
    [text: string]: boolean;
  }>({
    PLN: false,
    EUR: false,
    USD: false,
    CAD: false,
    AUD: false,
    GBP: false,
    CHF: false,
    UAH: false,
    JPY: false,
    SEK: false,
    DKK: false,
    NOK: false,
    CZK: false,
    BGN: false,
    HRK: false,
    RON: false,
  });

  //sets checked currencies into botStrategyConfig
  useEffect(() => {
    if (vinPaymentStatusBotStrategyConfig) {
      setVinPaymentStatusBotStrategyConfig({
        ...vinPaymentStatusBotStrategyConfig,
        SUPPORTED_CURRENCIES: currenciesChecked,
      });
    }
  }, [currenciesChecked]);

  const [numberOfDaysSincePayment, setNumberOfDaysSincePayment] =
    useState<boolean>(false);

  //back to presets button logic
  const [backToPresetsButtonVisible, setBackToPresetsButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    const botConfigurationType = localStorage.getItem('botConfigurationType');
    if (botConfigurationType !== null && botConfigurationType === 'new') {
      setBackToPresetsButtonVisible(true);
    } else {
      setBackToPresetsButtonVisible(false);
    }
  }, []);

  return (
    <>
      {vinPaymentStatusBotStrategyConfig &&
        vinPaymentStatusGenerationsConfig && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              flexDirection: 'column',
              margin: 10,
              padding: 10,

              overflow: 'auto',
              marginTop: 80,
            }}
          >
            <EuiFlexItem
              css={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
            >
              {backToPresetsButtonVisible && <VindicationBackToPreset />}
              <div>
                <EuiButton fill onClick={discardBotConfiguration}>
                  Discard
                </EuiButton>
              </div>
            </EuiFlexItem>
            <EuiStepsHorizontal
              steps={horizontalSteps as Omit<EuiStepHorizontalProps, 'step'>[]}
            />
            <EuiFlexItem>
              <EuiText css={{ fontSize: 22 }}>Moduł status płatności</EuiText>
              <EuiText>
                Na tym etapie bot podaje rozmówcy informację o kwocie zaległej
                płatności na określonym produkcie i zadaje pytanie, czy dłużnik
                uregulował już tę zaległość. Gdy dłużnik zadeklaruje brak
                wpłaty, dialog jest automatycznie kierowany do modułu
                negocjacji. W przypadku gdy dłużnik deklaruje, że dokonał już
                wpłaty, następuje zakończenie rozmowy lub przejście do modułów
                pomocniczych.
              </EuiText>
            </EuiFlexItem>
            <div style={{ marginTop: 30 }}>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  buttonContent="Currency configuration"
                  forceState={showAccordion.currencyConfig ? 'open' : 'closed'}
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      currencyConfig: !showAccordion.currencyConfig,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem>
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          Default currency
                        </EuiText>
                        <EuiSelect
                          options={defaultCurrencyOptions}
                          value={
                            vinPaymentStatusBotStrategyConfig.DEFAULT_CURRENCY
                          }
                          onChange={(e) =>
                            setVinPaymentStatusBotStrategyConfig({
                              ...vinPaymentStatusBotStrategyConfig,
                              DEFAULT_CURRENCY: e.target.value,
                            })
                          }
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          Acceptable currencies
                        </EuiText>
                        <EuiFlexGroup
                          css={{
                            flexDirection: 'row',
                            gap: 6,
                            padding: 20,
                            flexWrap: 'wrap',
                            width: 400,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {defaultCurrencyOptions &&
                            defaultCurrencyOptions.map((item, i) => {
                              return (
                                <EuiFlexItem
                                  key={i}
                                  css={{ flexDirection: 'row', gap: 6 }}
                                >
                                  <EuiText key={`text${i}`}>
                                    {item.text}
                                  </EuiText>
                                  <EuiCheckbox
                                    key={`currencyCheckbox${i}`}
                                    id={`check${i}`}
                                    checked={currenciesChecked[item.value]}
                                    value={item.value}
                                    onChange={(ev) => {
                                      if (
                                        ev.target.value ===
                                        vinPaymentStatusBotStrategyConfig?.DEFAULT_CURRENCY
                                      ) {
                                        alert(
                                          'You cant delete default currency',
                                        );
                                      } else {
                                        setCurrenciesChecked({
                                          ...currenciesChecked,
                                          [ev.target.value]: ev.target.checked,
                                        });
                                      }
                                    }}
                                  />
                                </EuiFlexItem>
                              );
                            })}
                        </EuiFlexGroup>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                currencyConfig: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  buttonContent="Information about the amount of arrears, question about payment"
                  forceState={showAccordion.debtInfo ? 'open' : 'closed'}
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      debtInfo: !showAccordion.debtInfo,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 20,
                        padding: 10,
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        Information on the amount of the arrears. After
                        successful verification, the bot gives you the debt
                        amount.
                      </EuiText>

                      <EuiFlexItem
                        css={{
                          gap: 10,
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          • How the bot should tell you the amount of debt
                        </EuiText>

                        <EuiText>The first parameter</EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus1'}
                          text={
                            vinPaymentStatusGenerationsConfig?.[
                              'INF-STA-debt_intro-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'INF-STA-debt_intro-1-1': text,
                            });
                          }}
                        />

                        <EuiText>The second parameter</EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus2'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'REQ-INF-is_payment_done-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'REQ-INF-is_payment_done-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          • What should be the bot's reaction when the debtor
                          declares that he has already settled his debt?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus3'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'INF-STA-waiting_for_transfer-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'INF-STA-waiting_for_transfer-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem css={{ alignItems: 'center' }}>
                        <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                          • Please select which module should be activated after
                          this situation
                        </EuiText>
                        <div>
                          <EuiSelect
                            options={SUPPORTED_MODULES}
                            value={
                              vinPaymentStatusBotStrategyConfig.BRANCHES
                                .DEBTOR_STATUS.success
                            }
                            onChange={(e) =>
                              setVinPaymentStatusBotStrategyConfig({
                                ...vinPaymentStatusBotStrategyConfig,
                                BRANCHES: {
                                  DEBTOR_STATUS: {
                                    ...vinPaymentStatusBotStrategyConfig
                                      .BRANCHES.DEBTOR_STATUS,
                                    success: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          • What should be the bot's reaction when the debtor
                          declares that he has settled part of the debt?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus4'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'INF-STA-negotiation_intro_partially_paid-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'INF-STA-negotiation_intro_partially_paid-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                          After the message, there is a redirection to{' '}
                          <EuiLink
                            onClick={() => navigate('/dashboard/bots/new/1/4')}
                          >
                            Negotiations module
                          </EuiLink>
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem css={{ alignItems: 'center', gap: 20 }}>
                        <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                          • The minimum amount declared to end the conversation,
                          expressed in % of the total
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                debtInfo: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem
                css={{
                  flexDirection: 'row',
                  gap: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EuiText>
                  Asking about the date is optional, if you want to turn it ON,
                  click the checkbox
                </EuiText>
                <EuiCheckbox
                  id="second_turn_questions"
                  checked={
                    vinPaymentStatusBotStrategyConfig.PAST_PAYMENT_DATE_QUESTION
                  }
                  onChange={(e) =>
                    setVinPaymentStatusBotStrategyConfig({
                      ...vinPaymentStatusBotStrategyConfig,
                      PAST_PAYMENT_DATE_QUESTION: e.target.checked,
                    })
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  buttonContent="Asking about the date of the payment"
                  forceState={showAccordion.paymentDate ? 'open' : 'closed'}
                  isDisabled={
                    !vinPaymentStatusBotStrategyConfig.PAST_PAYMENT_DATE_QUESTION
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      paymentDate: !showAccordion.paymentDate,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 20,
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          How should a bot ask about the amount the debtor has
                          already paid?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus5'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'FEE-UNC-REQ-INF-past_payment_date-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'FEE-UNC-REQ-INF-past_payment_date-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem css={{ gap: 20, alignItems: 'center' }}>
                        <EuiFlexItem css={{ flexDirection: 'row', gap: 10 }}>
                          <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                            Acceptable number of days since the declared payment
                          </EuiText>
                          <EuiCheckbox
                            id="second_turn_questions"
                            checked={numberOfDaysSincePayment}
                            onChange={() =>
                              setNumberOfDaysSincePayment(
                                !numberOfDaysSincePayment,
                              )
                            }
                          />
                        </EuiFlexItem>
                        <div>
                          <EuiFieldNumber
                            disabled={!numberOfDaysSincePayment}
                            css={{ textAlign: 'center', width: 200 }}
                            value={
                              vinPaymentStatusBotStrategyConfig.ACCEPTABLE_DAYS_SINCE_PAYMENT
                            }
                            onChange={(e) =>
                              setVinPaymentStatusBotStrategyConfig({
                                ...vinPaymentStatusBotStrategyConfig,
                                ACCEPTABLE_DAYS_SINCE_PAYMENT: Number(
                                  e.target.value,
                                ),
                              })
                            }
                          />
                        </div>
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should be the bot's reaction to the information
                          that the debtor has made the payment in an acceptable
                          past date, e.g. yesterday?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus6'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'INF-STA-waiting_for_transfer-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'INF-STA-waiting_for_transfer-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should be the bot's reaction to the information
                          that the debtor made the payment earlier than it is
                          acceptable, e.g. a month ago?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus7'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'INF-STA-payment_not_found-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'INF-STA-payment_not_found-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem css={{ alignItems: 'center', gap: 20 }}>
                        <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                          • Please select which module should be activated after
                          this situation
                        </EuiText>
                        <div>
                          <EuiSelect
                            options={SUPPORTED_MODULES}
                            value={
                              vinPaymentStatusBotStrategyConfig.BRANCHES
                                .DEBTOR_STATUS.not_acceptable_date
                            }
                            onChange={(e) =>
                              setVinPaymentStatusBotStrategyConfig({
                                ...vinPaymentStatusBotStrategyConfig,
                                BRANCHES: {
                                  DEBTOR_STATUS: {
                                    ...vinPaymentStatusBotStrategyConfig
                                      .BRANCHES.DEBTOR_STATUS,
                                    not_acceptable_date: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                paymentDate: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>

              <EuiFlexItem
                css={{
                  flexDirection: 'row',
                  gap: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EuiText>
                  Asking about the payment amount is optional, if you want to
                  turn it ON, click the checkbox
                </EuiText>
                <EuiCheckbox
                  id="second_turn_questions"
                  checked={
                    vinPaymentStatusBotStrategyConfig.PAST_PAYMENT_AMOUNT_QUESTION
                  }
                  onChange={(e) =>
                    setVinPaymentStatusBotStrategyConfig({
                      ...vinPaymentStatusBotStrategyConfig,
                      PAST_PAYMENT_AMOUNT_QUESTION: e.target.checked,
                    })
                  }
                />
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiAccordion
                  id="start"
                  buttonContent="Asking about the amount paid"
                  isDisabled={
                    !vinPaymentStatusBotStrategyConfig.PAST_PAYMENT_AMOUNT_QUESTION
                  }
                  forceState={showAccordion.paymentAmount ? 'open' : 'closed'}
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      paymentAmount: !showAccordion.paymentAmount,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should the bot's question be about the amount the
                          debtor has paid?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinPaymentStatus8'}
                          text={
                            vinPaymentStatusGenerationsConfig[
                              'INF-STA-waiting_for_transfer-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinPaymentStatusGenerationsConfig({
                              ...vinPaymentStatusGenerationsConfig,
                              'INF-STA-waiting_for_transfer-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <div>
                          <EuiButton
                            fill
                            onClick={() =>
                              setShowAccordion({
                                ...showAccordion,
                                paymentAmount: false,
                              })
                            }
                          >
                            Close this section
                          </EuiButton>
                        </div>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
            </div>
            <EuiFlexItem>
              <div>
                <EuiButton fill onClick={handleSubmitPaymentStatusClick}>
                  Next
                </EuiButton>
              </div>
            </EuiFlexItem>
          </div>
        )}
    </>
  );
};

export default VinPaymentStatus;

import React, { useEffect, useState } from 'react';
import {
  CustomFaas,
  CustomFaasRequest,
} from '../../../../../generated/tenants/Api';
import { useAppDispatch } from '../../../../../common/hooks';
import moment from 'moment';
import { updateCustomFaasAsyncThunk } from '../api/dialoguesSlice';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../../PresetsPanel.style';
import EditPresetButtons from '../../shared/EditPresetButtons';
import AssociatedProcesses from '../../shared/AssociatedProcesses';
import CustomFaasFormReadOnly from './CustomFaasFormReadOnly';
import CustomFaasForm from './CustomFaasForm';
import { H2, H4 } from '../../../../App.style';
import { FormContainer } from '../../../processes/forms/ProcessForm.style';

interface CustomFaasDetailedProps {
  customFaas: CustomFaas;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  isSuperAdmin: boolean;
}

const CustomFaasDetailed: React.FC<CustomFaasDetailedProps> = ({
  customFaas,
  backToList,
  findProcessNamesById,
  isSuperAdmin,
}) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(customFaas.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(customFaas.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editCustomFaas, setEditCustomFaas] = useState<CustomFaasRequest>({
    name: '',
    lambda_type: 'REPORT',
    faas_endpoint: '',
    strategy: '',
  });

  useEffect(() => {
    setEditCustomFaas(customFaas);
  }, [customFaas]);

  const handleCustomFaasUpdateClick = () => {
    const id = customFaas.id;
    if (editCustomFaas && id) {
      const args = { id: id, data: editCustomFaas };
      dispatch(updateCustomFaasAsyncThunk(args));
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditCustomFaas(customFaas);
  };

  const associatedWithProcesses = findProcessNamesById(customFaas.id);

  const handleBackToDetailed = () => setEdit(false);

  return (
    <DetailedPresetContainer>
      {isSuperAdmin && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleCustomFaasUpdateClick}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{customFaas.name}</H2>
            <H4 $grey>
              created {formattedCreated}, modified {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <CustomFaasFormReadOnly customFaas={customFaas} />
        </FormContainer>
      ) : (
        <CustomFaasForm
          customFaas={editCustomFaas}
          setCustomFaas={setEditCustomFaas}
          backToList={backToList}
          backToDetailed={handleBackToDetailed}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default CustomFaasDetailed;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const EyeIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M28 14C28 14 22.75 4.375 14 4.375C5.25 4.375 0 14 0 14C0 14 5.25 23.625 14 23.625C22.75 23.625 28 14 28 14ZM2.05222 14C2.15185 13.8481 2.26545 13.6794 2.39273 13.4967C2.97878 12.6559 3.84354 11.5376 4.95619 10.4249C7.21181 8.16932 10.2911 6.125 14 6.125C17.7089 6.125 20.7882 8.16932 23.0438 10.4249C24.1565 11.5376 25.0212 12.6559 25.6073 13.4967C25.7346 13.6794 25.8482 13.8481 25.9478 14C25.8482 14.1519 25.7346 14.3206 25.6073 14.5033C25.0212 15.3441 24.1565 16.4624 23.0438 17.5751C20.7882 19.8307 17.7089 21.875 14 21.875C10.2911 21.875 7.21181 19.8307 4.95619 17.5751C3.84354 16.4624 2.97878 15.3441 2.39273 14.5033C2.26545 14.3206 2.15185 14.1519 2.05222 14Z" />
    <path d="M14 9.625C11.5838 9.625 9.625 11.5838 9.625 14C9.625 16.4162 11.5838 18.375 14 18.375C16.4162 18.375 18.375 16.4162 18.375 14C18.375 11.5838 16.4162 9.625 14 9.625ZM7.875 14C7.875 10.6173 10.6173 7.875 14 7.875C17.3827 7.875 20.125 10.6173 20.125 14C20.125 17.3827 17.3827 20.125 14 20.125C10.6173 20.125 7.875 17.3827 7.875 14Z" />
  </StyledSvg>
);

import styled from 'styled-components';
import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_PRIMARY_3,
  H4,
} from '../../../../../App.style';

export const BillingContainer = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  padding: 24px;

  background: #ffffff;

  position: relative;

  display: flex;
  flex-direction: column;
`;

//bar

export const BillingBarContainer = styled.div`
  width: 100%;
  min-width: 500px;

  z-index: 10;
  gap: 16px;

  display: flex;

  flex-direction: column;
`;

export const BillingBarFiltersContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: ${COLOR_NEUTRAL_10};
  padding: 6px 10px;
  gap: 8px;
`;

export const BillingsContentContainer = styled.div`
  padding: 16px 0px;
  height: 100%;
`;

export const BillingsFiltersContainer = styled.div`
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: flex-end;

  gap: 16px;
`;

export const BillingsFiltersDateContainer = styled.div<{
  $isProcessSelected: boolean;
}>`
  display: flex;
  align-items: center;

  border-radius: 5px;

  box-sizing: border-box;

  border: ${({ $isProcessSelected }) =>
    $isProcessSelected ? `0.5px solid ${COLOR_PRIMARY_3}` : 'none'};
  background: ${({ $isProcessSelected }) =>
    $isProcessSelected ? 'white' : '#EEF2F6'};

  box-shadow: 0px 2px 2px 0px rgba(175, 175, 175, 0.3);
`;

//table

export const BillingsTableHeaderSelected = styled.th`
  height: 100%;
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  margin-bottom: 3px;
  gap: 40px;

  border-top: 1px solid ${COLOR_NEUTRAL_20};
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};

  ${H4} {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BillingsTableHeaderSelectedItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const BillingsTableHeaderSelectedLeftContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const BillingsTableHeaderSelectedRightContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const BillingsSelectedStyledSpan = styled.span`
  font-weight: 500;
  margin-left: 8px;
`;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminPanelSidebar from './AdminPanelSidebar';
import UserDetailed from '../../users/detailed/UserDetailed';
import {
  SettingsPanelContainer,
  TenantSettingsContentContainer,
} from '../Settings.style';
import UsersPanel from '../../users/UsersPanel';

import NumbersHistory from './components/phoneNumbers/NumbersHistory';
import NumbersStrategyAssign from './components/phoneNumbers/NumbersStrategyAssign';
import SftpPanel from './components/sftp/SftpPanel';
import StrategiesPanel from './components/strategies/StrategiesPanel';
import AccessTokens from './components/tokens/AccessTokens';

const AdminPanel = () => {
  return (
    <SettingsPanelContainer style={{ position: 'relative' }}>
      <AdminPanelSidebar />

      <TenantSettingsContentContainer>
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/tenant_panel/users" />}
          />
          <Route path="/users" element={<UsersPanel />} />
          <Route path="/users/:id" element={<UserDetailed />} />

          <Route path="/phone_numbers" element={<NumbersStrategyAssign />} />
          <Route path="/numbers_history" element={<NumbersHistory />} />

          <Route path="/sftp_settings" element={<SftpPanel />} />

          <Route path="/manage_strategies" element={<StrategiesPanel />} />

          <Route path="/access_tokens" element={<AccessTokens />} />
        </Routes>
      </TenantSettingsContentContainer>
    </SettingsPanelContainer>
  );
};

export default AdminPanel;

import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { ChevronDownIcon } from '../../resources/icons-new/ChevronDownIcon';
import { COLOR_NEUTRAL_50, H3 } from '../../app/App.style';
import { useTranslation } from 'react-i18next';

interface ProcessOption {
  value: string;
  processName: string;
  strategyName: string;
  is_archived: boolean;
}

interface ProcessesInputProps {
  processesList: ProcessOption[];
  selectedProcessId: string;
  setSelectedProcessId: (value: React.SetStateAction<string>) => void;
  fullWidth?: boolean;
}

const ProcessesInput: React.FC<ProcessesInputProps> = ({
  processesList,
  selectedProcessId,
  setSelectedProcessId,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const activeProcesses = processesList.filter(
    (process) => !process.is_archived,
  );
  const archivedProcesses = processesList.filter(
    (process) => process.is_archived,
  );

  const handleSelect = (value: string) => {
    setSelectedProcessId(value);
    setIsDropdownOpen(false);
  };

  return (
    <Container ref={dropdownRef} $fullWidth={fullWidth}>
      <Dropdown>
        <DropdownHeader onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <H3
            style={{ color: selectedProcessId === '' ? COLOR_NEUTRAL_50 : '' }}
          >
            {processesList.find(
              (process) => process.value === selectedProcessId,
            )?.processName || t('common.selectProcess')}
          </H3>
          <DropdownArrow open={isDropdownOpen}>
            <ChevronDownIcon />
          </DropdownArrow>
        </DropdownHeader>
        {isDropdownOpen && (
          <DropdownList>
            {activeProcesses.map((process) => (
              <DropdownItem
                key={process.value}
                onClick={() => handleSelect(process.value)}
              >
                <H3>{process.processName}</H3>
              </DropdownItem>
            ))}
            {archivedProcesses.length > 0 && (
              <DropdownGroup>
                <DropdownItem onClick={() => setShowArchived(!showArchived)}>
                  <H3 style={{ color: 'grey' }} $grey>
                    {showArchived
                      ? t('common.hideArchive')
                      : t('common.showArchive')}
                  </H3>
                  <DropdownArrow open={showArchived}>
                    <ChevronDownIcon />
                  </DropdownArrow>
                </DropdownItem>
                {showArchived && (
                  <>
                    {archivedProcesses.map((process) => (
                      <ArchivedItem
                        key={process.value}
                        onClick={() => handleSelect(process.value)}
                      >
                        <H3 $grey>{process.processName} - archived</H3>
                      </ArchivedItem>
                    ))}
                  </>
                )}
              </DropdownGroup>
            )}
          </DropdownList>
        )}
      </Dropdown>
    </Container>
  );
};

export default ProcessesInput;

const Container = styled.div<{ $fullWidth?: boolean }>`
  position: relative;
  width: ${({ $fullWidth }) => ($fullWidth ? '300px' : '200px')};
  max-height: 41px;
`;

const Dropdown = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
`;

const DropdownHeader = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  max-height: 41px;
`;

const DropdownArrow = styled.span<{ open: boolean }>`
  transition: transform 0.3s;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownList = styled.div`
  position: absolute;
  width: 300px;

  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;

  border-radius: 6px;
  box-shadow:
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
`;

const DropdownItem = styled.div`
  padding: 10px;
  padding-left: 16px;
  background: white;
  box-shadow: 0px -1px 0px 0px rgba(211, 218, 230, 0.5) inset;

  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownGroup = styled.div`
  box-shadow: 0px -1px 0px 0px rgba(211, 218, 230, 0.5) inset;
`;

const ArchivedItem = styled(DropdownItem)`
  color: gray;
`;

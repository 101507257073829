import { COLOR_NEUTRAL_10, COLOR_NEUTRAL_40 } from './../../../../../App.style';
import styled from 'styled-components';

export const StrategiesPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;
  padding: 24px;

  position: relative;

  background: #ffffff;
`;

export const StrategiesTopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StrategiesPanelContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 30px);

  margin-top: 16px;

  padding-bottom: 0px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const StrategiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StrategyPreviewContainer = styled.div<{ isArchived: boolean }>`
  padding: 12px 16px;

  opacity: ${(props) => props.isArchived && '40%'};

  width: 260px;
  height: 240px;
  background: ${COLOR_NEUTRAL_10};
  position: relative;
  border-radius: 5px;

  -webkit-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  -moz-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);

  display: flex;
  flex-direction: column;
  gap: 16px;

  cursor: pointer;
`;

export const StrategyNameContainer = styled.div`
  margin-top: 20px;
  word-wrap: break-word;
  height: 40px;
`;

export const ThreeDotsContainer = styled.div`
  position: absolute;
  height: 14px;
  width: 18px;

  top: 8px;
  right: 9px;

  cursor: pointer;
`;

//navbar select

interface StrategyButtonProps {
  $selected: boolean;
}
export const StrategyButton = styled.button<StrategyButtonProps>`
  width: 100%;
  height: 30px;

  text-align: start;

  padding: 8px 12px;

  border-bottom: 0.5px solid #d3dae6;

  background: ${(props) => (props.$selected ? '#f2f2f2' : 'white')};

  &:hover {
    background: #f2f2f2;
  }
`;

export const PopoverStrategies = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  overflow: hidden;
`;

//detailed
export const StrategiesBackToListButton = styled.button`
  position: absolute;

  left: 0px;

  z-index: 1000;

  padding: 3px 8px;

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  gap: 6px;

  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 5px;
  border: 1px solid #e4e7eb;
  background: white;

  box-shadow: 0px 2px 2px 0px rgba(152, 162, 179, 0.3);

  &:hover {
    filter: brightness(90%);
  }
`;

export const StrategyDetailedKeyContainer = styled.div`
  display: flex;
  padding: 24px;
  gap: 32px;
  width: 400px;

  border-radius: 5px;

  margin-bottom: 20px;

  border: 1px solid ${COLOR_NEUTRAL_40};
`;

//StrategiesForm

export const StrategyKeyRowContainer = styled.div`
  display: flex;
  width: 400px;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 8px;
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CopyIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

import { EuiFieldText, EuiFlexItem, EuiSwitch } from '@elastic/eui';
import React from 'react';
import { H3 } from '../../../App.style';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import { Campaign, Process } from '../../../../generated/tenants/Api';
import { SnapshotContainer } from '../Campaigns.style';
import LabelsComboboxComponent from '../../presets/labels/LabelsComboboxComponent';
import { useTranslation } from 'react-i18next';

interface GeneralSnapshotProps {
  campaign?: Campaign;
  usedProcess?: Process;
}

const GeneralSnapshot: React.FC<GeneralSnapshotProps> = ({
  campaign,
  usedProcess,
}) => {
  const { t } = useTranslation();
  return (
    <SnapshotContainer>
      <FormRow label={<H3>{t('processes.form.general.processName')}</H3>}>
        <EuiFieldText readOnly value={usedProcess?.name} />
      </FormRow>

      <FormRow label={<H3>{t('processes.form.general.labels')}</H3>}>
        <LabelsComboboxComponent labels={campaign?.labels} isDisabled={true} />
      </FormRow>

      <FormRow label={<H3>{t('processes.form.general.description')}</H3>}>
        <EuiFieldText readOnly value={usedProcess?.description} />
      </FormRow>

      <FormRow label={<H3>{t('processes.form.general.guarantedLines')}</H3>}>
        <EuiFieldText readOnly value={campaign?.guaranteed_lines} />
      </FormRow>

      <FormRow
        style={{ position: 'relative' }}
        label={<H3>{t('processes.form.general.dynamicLinesUsage')}</H3>}
      >
        <EuiFlexItem
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            left: 140,
            top: 0,
          }}
        >
          <EuiSwitch
            compressed
            checked={campaign?.use_idle_lines ? true : false}
            onChange={() => console.log('')}
            label=""
          />
        </EuiFlexItem>
      </FormRow>

      <FormRow
        style={{ position: 'relative' }}
        label={<H3>{t('processes.form.general.sftp')}</H3>}
      >
        <EuiFlexItem
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            left: 140,
            top: 0,
          }}
        >
          <EuiSwitch
            compressed
            label=""
            checked={campaign?.sftp_data_transfer ? true : false}
            onChange={() => console.log('')}
          />
        </EuiFlexItem>
      </FormRow>

      {campaign?.sftp_data_transfer && (
        <>
          <FormRow label={<H3>{t('processes.form.general.inGlob')}</H3>}>
            <EuiFieldText value={campaign.in_glob ? campaign.in_glob : ''} />
          </FormRow>

          <FormRow label={<H3>{t('processes.form.general.archivePath')}</H3>}>
            <EuiFieldText
              value={campaign.archive_path ? campaign.archive_path : ''}
            />
          </FormRow>

          <FormRow
            style={{ position: 'relative' }}
            label={<H3>{t('processes.form.general.deleteArchived')}</H3>}
          >
            <EuiFlexItem
              style={{
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                left: 140,
                top: 0,
              }}
            >
              <EuiSwitch
                compressed
                label=""
                checked={campaign?.delete_archived_files ? true : false}
                onChange={() => console.log('')}
              />
            </EuiFlexItem>
          </FormRow>

          <FormRow
            label={<H3>{t('processes.form.general.recordingsFormat')}</H3>}
          >
            <EuiFieldText readOnly value={campaign?.recordings_format} />
          </FormRow>

          <FormRow
            label={<H3>{t('processes.form.general.importFileHandling')}</H3>}
          >
            <EuiFieldText readOnly value={campaign?.import_file_handling} />
          </FormRow>

          <FormRow
            label={
              <H3>{t('processes.form.general.externalSftpRecordings')}</H3>
            }
          >
            <EuiFieldText
              readOnly
              value={campaign?.external_sftp_recordings_path ?? ''}
            />
          </FormRow>

          <FormRow
            label={<H3>{t('processes.form.general.externalSftpReports')}</H3>}
          >
            <EuiFieldText
              readOnly
              value={campaign?.external_sftp_reports_path ?? ''}
            />
          </FormRow>

          <FormRow
            label={<H3>{t('processes.form.general.externalSftpReports')}</H3>}
          >
            <EuiFieldText
              readOnly
              value={campaign?.external_sftp_transcripts_path ?? ''}
            />
          </FormRow>
        </>
      )}
    </SnapshotContainer>
  );
};

export default GeneralSnapshot;

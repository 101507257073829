import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { H3, H4, H6 } from '../../../App.style';
import {
  VindicationBuilderSidebarContainer,
  VindicationBuilderSidebarSectionContainer,
  VindicationBuilderSidebarStatusCircle,
  VindicationBuilderSidebarStepContainer,
  VindicationBuilderSidebarStepContent,
} from './Vindication.style';

const VindicationBuilderSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const GLOBAL_SETTINGS_LINK = '/builder/vindication/global';
  const IDENTIFICATION_LINK = '/builder/vindication/identification';
  const VERIFICATION_LINK = '/builder/vindication/verification';
  const PAYMENT_STATUS_LINK = '/builder/vindication/payment_status';
  const NEGOTIATIONS_LINK = '/builder/vindication/negotiations';
  const AUXILIARY_MODULES_LINK = '/builder/vindication/auxilary_modules';
  const QA_LINK = '/builder/vindication/qa';
  const TOKENS_LINK = '/builder/vindication/tokens';
  const FINAL_SETTINGS_LINK = '/builder/vindication/final';
  const ADDITIONAL_SETTINGS_LINK = '/builder/vindication/additional';

  return (
    <VindicationBuilderSidebarContainer>
      <VindicationBuilderSidebarSectionContainer
        $selected={location.pathname === GLOBAL_SETTINGS_LINK}
        onClick={() => navigate(GLOBAL_SETTINGS_LINK)}
      >
        <H3>Global parameters</H3>
      </VindicationBuilderSidebarSectionContainer>

      <VindicationBuilderSidebarSectionContainer $noHover>
        <H6 style={{ marginLeft: 8 }}>Dialog flow</H6>

        <VindicationBuilderSidebarStepContainer
          $lineDown
          $selected={location.pathname === IDENTIFICATION_LINK}
          onClick={() => navigate(IDENTIFICATION_LINK)}
        >
          <VindicationBuilderSidebarStatusCircle>
            <H6>1</H6>
          </VindicationBuilderSidebarStatusCircle>

          <VindicationBuilderSidebarStepContent $status={'success'}>
            <H4>Identification</H4>
          </VindicationBuilderSidebarStepContent>
        </VindicationBuilderSidebarStepContainer>

        <VindicationBuilderSidebarStepContainer
          $lineDown
          $selected={location.pathname === VERIFICATION_LINK}
          onClick={() => navigate(VERIFICATION_LINK)}
        >
          <VindicationBuilderSidebarStatusCircle>
            <H6>2</H6>
          </VindicationBuilderSidebarStatusCircle>

          <VindicationBuilderSidebarStepContent $status={'success'}>
            <H4>Verification</H4>
          </VindicationBuilderSidebarStepContent>
        </VindicationBuilderSidebarStepContainer>

        <VindicationBuilderSidebarStepContainer
          $lineDown
          $selected={location.pathname === PAYMENT_STATUS_LINK}
          onClick={() => navigate(PAYMENT_STATUS_LINK)}
        >
          <VindicationBuilderSidebarStatusCircle>
            <H6>3</H6>
          </VindicationBuilderSidebarStatusCircle>

          <VindicationBuilderSidebarStepContent $status={'success'}>
            <H4>Payment status</H4>
          </VindicationBuilderSidebarStepContent>
        </VindicationBuilderSidebarStepContainer>

        <VindicationBuilderSidebarStepContainer
          $selected={location.pathname === NEGOTIATIONS_LINK}
          onClick={() => navigate(NEGOTIATIONS_LINK)}
        >
          <VindicationBuilderSidebarStatusCircle>
            <H6>4</H6>
          </VindicationBuilderSidebarStatusCircle>

          <VindicationBuilderSidebarStepContent $status={'success'}>
            <H4>Negotiations</H4>
          </VindicationBuilderSidebarStepContent>
        </VindicationBuilderSidebarStepContainer>

        <VindicationBuilderSidebarStepContainer
          $selected={location.pathname === AUXILIARY_MODULES_LINK}
          onClick={() => navigate(AUXILIARY_MODULES_LINK)}
        >
          <VindicationBuilderSidebarStatusCircle $dot>
            <H6>•</H6>
          </VindicationBuilderSidebarStatusCircle>

          <VindicationBuilderSidebarStepContent $status={'success'}>
            <H4>Auxilary modules</H4>
          </VindicationBuilderSidebarStepContent>
        </VindicationBuilderSidebarStepContainer>

        <VindicationBuilderSidebarStepContainer
          $selected={location.pathname === QA_LINK}
          onClick={() => navigate(QA_LINK)}
        >
          <VindicationBuilderSidebarStatusCircle $dot>
            <H6>•</H6>
          </VindicationBuilderSidebarStatusCircle>

          <VindicationBuilderSidebarStepContent $status={'success'}>
            <H4>Q&A</H4>
          </VindicationBuilderSidebarStepContent>
        </VindicationBuilderSidebarStepContainer>
      </VindicationBuilderSidebarSectionContainer>

      <VindicationBuilderSidebarSectionContainer
        $selected={location.pathname === TOKENS_LINK}
        onClick={() => navigate(TOKENS_LINK)}
      >
        <H3>Tokens</H3>
      </VindicationBuilderSidebarSectionContainer>

      <VindicationBuilderSidebarSectionContainer
        $selected={location.pathname === ADDITIONAL_SETTINGS_LINK}
        onClick={() => navigate(ADDITIONAL_SETTINGS_LINK)}
      >
        <H3>Additional settings</H3>
      </VindicationBuilderSidebarSectionContainer>

      <VindicationBuilderSidebarSectionContainer
        $selected={location.pathname === FINAL_SETTINGS_LINK}
        onClick={() => navigate(FINAL_SETTINGS_LINK)}
      >
        <H3>Final settings</H3>
      </VindicationBuilderSidebarSectionContainer>
    </VindicationBuilderSidebarContainer>
  );
};

export default VindicationBuilderSidebar;

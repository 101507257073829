import styled from 'styled-components';
import {
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
} from '../../../../App.style';

export const WorkingHoursTable = styled.table`
  margin-top: 8px;
  width: 100%;

  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    height: 90%;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

interface WorkingHoursHeaderProps {
  day?: boolean;
  copy?: boolean;
}

export const WorkingHoursHeader = styled.th<WorkingHoursHeaderProps>`
  padding: 8px;
  text-align: left;

  width: ${(props) => (props.copy ? '30px' : props.day ? '150px' : '220px')};
`;

export const WorkingHoursRow = styled.tr`
  &:hover {
    background: ${COLOR_NEUTRAL_10};
  }
`;

interface WorkingHoursDataProps {
  day?: boolean;
  copy?: boolean;
}

export const WorkingHoursData = styled.td<WorkingHoursDataProps>`
  border-bottom: 1px solid #3e4c59;
  padding: 4px 8px;

  width: ${(props) => (props.copy ? '30px' : props.day ? '150px' : '220px')};
  vertical-align: middle;

  min-height: 55px;

  /* border: 1px solid red; */
  border-right: 5px;
`;

export const AddPeriodContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  align-items: center;
  padding-left: 35px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TimeInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeInputsButtons = styled.div`
  display: flex;
  margin-left: 3px;

  height: 40px;
  margin-top: 7px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

export const TimeInputsButton = styled.img`
  &:hover {
    transform: scale(1.1);
  }
`;

///preview

export const WorkingHoursGroupsContainer = styled.div`
  margin-top: 5px;
`;

export const WorkingHoursBorder = styled.div`
  border: 1px solid ${COLOR_NEUTRAL_40};
  border-radius: 5px;
  width: 400px;

  margin-bottom: 20px;
`;

export const WorkingHoursPreviewTitle = styled.div`
  padding: 11px;
  border-bottom: 0.5px solid ${COLOR_NEUTRAL_20};
`;

export const WorkingHoursNotDefinedContainer = styled.div`
  padding: 10px 20px;
`;

export const GroupsContainer = styled.div`
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  gap: 6px;
  border-bottom: 0.5px solid ${COLOR_NEUTRAL_20};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../common/modals/Modal';
import { EuiFieldText, EuiModalBody } from '@elastic/eui';
import { FormRow } from '../../../../processes/forms/ProcessForm.style';
import { H3 } from '../../../../../App.style';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';

interface DialogueCompanyNameModalProps {
  closeModal: () => void;
  vindicationDialogue: BotConfigurationRequest;
  updateRuntimeParameters: (key: string, newValue: string) => void;
}

const DialogueCompanyNameModal: React.FC<DialogueCompanyNameModalProps> = ({
  closeModal,
  vindicationDialogue,
  updateRuntimeParameters,
}) => {
  const { t } = useTranslation();

  const body = (
    <EuiModalBody>
      <FormRow label={<H3>Nominative</H3>}>
        <EuiFieldText
          value={vindicationDialogue.runtime_parameters?.['CompanyName'] ?? ''}
          onChange={(e) =>
            updateRuntimeParameters('CompanyName', e.target.value)
          }
        />
      </FormRow>
      <FormRow label={<H3>Genitive</H3>}>
        <EuiFieldText
          value={
            vindicationDialogue.runtime_parameters?.['CompanyNameGen'] ?? ''
          }
          onChange={(e) =>
            updateRuntimeParameters('CompanyNameGen', e.target.value)
          }
        />
      </FormRow>
      <FormRow label={<H3>Dative</H3>}>
        <EuiFieldText
          value={
            vindicationDialogue.runtime_parameters?.['CompanyNameDat'] ?? ''
          }
          onChange={(e) =>
            updateRuntimeParameters('CompanyNameDat', e.target.value)
          }
        />
      </FormRow>
      <FormRow label={<H3>Accusative</H3>}>
        <EuiFieldText
          value={
            vindicationDialogue.runtime_parameters?.['CompanyNameAcc'] ?? ''
          }
          onChange={(e) =>
            updateRuntimeParameters('CompanyNameAcc', e.target.value)
          }
        />
      </FormRow>
      <FormRow label={<H3>Instrumental</H3>}>
        <EuiFieldText
          value={
            vindicationDialogue.runtime_parameters?.['CompanyNameInst'] ?? ''
          }
          onChange={(e) =>
            updateRuntimeParameters('CompanyNameInst', e.target.value)
          }
        />
      </FormRow>
      <FormRow label={<H3>Locative</H3>}>
        <EuiFieldText
          value={
            vindicationDialogue.runtime_parameters?.['CompanyNameLoc'] ?? ''
          }
          onChange={(e) =>
            updateRuntimeParameters('CompanyNameLoc', e.target.value)
          }
        />
      </FormRow>
    </EuiModalBody>
  );
  return (
    <Modal
      isVisible={true}
      headerTitle={'Company name'}
      onCancel={closeModal}
      buttonText={t('common.save')}
      children={body}
      noConfirm
    />
  );
};

export default DialogueCompanyNameModal;

import {
  EuiModalBody,
  EuiSuperSelect,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiLoadingSpinner,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { H3, H6, fontIBM } from '../../App.style';
import { FormRow } from '../processes/forms/ProcessForm.style';
import moment, { Moment } from 'moment';
import {
  CampaignCreateRequest,
  Process,
  Strategy,
} from '../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { createCampaignsAsyncThunk } from './api/campaignsSlice';
import Modal from '../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';

interface CampaignCreateModalProps {
  isVisible: boolean;
  closeModal: () => void;
  processesList: Process[];
  strategiesList: Strategy[];
  processId?: string;
}

const CampaignCreateModal: React.FC<CampaignCreateModalProps> = ({
  isVisible,
  closeModal,
  processesList,
  strategiesList,
  processId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const strategy = useAppSelector(selectStrategyId);

  const [linesError, setLinesError] = useState<boolean>(false);

  const renderStrategyLines = (myLines: number | undefined) => {
    const selectedStrategy = strategiesList.find((obj) => obj.id === strategy);

    if (selectedStrategy && myLines) {
      const { busy_lines, assigned_lines } = selectedStrategy;
      const availableLines = assigned_lines - busy_lines;

      if (myLines > availableLines) {
        setLinesError(true);
        return ``;
      } else {
        setLinesError(false);
        return `${availableLines} / ${assigned_lines}`;
      }
    } else {
      return '';
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data: CampaignCreateRequest = {
      start_date: startDate ? startDate.format('YYYY-MM-DD') : undefined,
      end_date: endDate ? endDate.format('YYYY-MM-DD') : undefined,
      process_id: selectedProcessId,
    };

    dispatch(createCampaignsAsyncThunk(data));

    setLoading(true);
    return false;
  };

  const closeAddColumnModal = () => {
    closeModal();
  };

  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const processesOptions = processesList
    .filter((process) => !process.is_archived)
    .map((process) => {
      return {
        value: process.id,
        inputDisplay: process.name,
        dropdownDisplay: <H3>{process.name}</H3>,
      };
    });

  const [selectedProcessId, setSelectedProcessId] = useState<string>(
    processesList[0].id,
  );

  const processOptionsLenghtError = processesOptions.length === 0;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isVisible) {
      setLoading(false);
    }
  }, [isVisible]);

  function renderProcessLines() {
    const foundObject = processesList.find(
      (object) => object.id === selectedProcessId,
    );
    return foundObject ? foundObject.guaranteed_lines : undefined;
  }

  const selectedProcessLines = renderProcessLines();
  useEffect(() => {
    const strategyLines = renderStrategyLines(selectedProcessLines);
    setAvailableLines(strategyLines);
  }, [strategy, selectedProcessLines]);

  const [availableLines, setAvailableLines] = useState<string>('');

  useEffect(() => {
    if (processId) {
      setSelectedProcessId(processId);
    }
  }, [processId]);

  const loadingBody = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 20,
      }}
    >
      <EuiLoadingSpinner size={'xxl'} />
    </div>
  );

  const resultBody = (
    <EuiModalBody>
      <FormRow
        label={<H3>{t('campaigns.createModal.selectProcess')}</H3>}
        isInvalid={processOptionsLenghtError}
        error={t('campaigns.createModal.noProcesses')}
        helpText={
          selectedProcessId && (
            <div>
              <H6>
                {t('campaigns.createModal.requestedLines')}
                {selectedProcessLines}
              </H6>
              <H6 style={{ color: '#127FBF' }}>
                {t('campaigns.createModal.availableLines')}
                {availableLines}
              </H6>
              {linesError && (
                <H6 style={{ color: '#BA2525' }}>
                  {t('campaigns.createModal.linesError')}
                </H6>
              )}
            </div>
          )
        }
      >
        <EuiSuperSelect
          isInvalid={processOptionsLenghtError}
          valueOfSelected={selectedProcessId}
          readOnly={processId ? true : false}
          onChange={(e) => {
            setSelectedProcessId(e);
          }}
          options={processesOptions}
        />
      </FormRow>

      <FormRow
        style={{ marginBottom: 0 }}
        label={<H3>{t('campaigns.createModal.startEndDates')}</H3>}
      >
        <EuiDatePickerRange
          css={{ width: 280, background: 'white', cursor: 'pointer' }}
          isInvalid={
            startDate && endDate ? endDate.isBefore(startDate, 'day') : false
          }
          startDateControl={
            <EuiDatePicker
              css={{
                background: 'white',
                fontFamily: fontIBM,
                cursor: 'pointer',
              }}
              placeholder="start date"
              selected={startDate}
              onChange={(date) => date && setStartDate(date)}
              startDate={startDate}
              endDate={endDate}
              aria-label="Start date"
              dateFormat="DD/MM/YYYY"
            />
          }
          endDateControl={
            <EuiDatePicker
              css={{
                background: 'white',
                fontFamily: fontIBM,
                cursor: 'pointer',
              }}
              placeholder="end date"
              selected={endDate}
              onChange={(date) => date && setEndDate(date)}
              startDate={startDate}
              endDate={endDate}
              aria-label="End date"
              dateFormat="DD/MM/YYYY"
            />
          }
        />
      </FormRow>
    </EuiModalBody>
  );

  return (
    <Modal
      headerTitle={
        loading
          ? t('campaigns.createModal.titleLoading')
          : t('campaigns.createModal.title')
      }
      isVisible={isVisible}
      onCancel={closeAddColumnModal}
      onConfirm={handleSubmit}
      children={loading ? loadingBody : resultBody}
      noButtons={loading}
    />
  );
};

export default CampaignCreateModal;

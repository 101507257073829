import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PasswordVisibilityToggle } from '../../../common/inputs/Inputs.style';
import { Button, H1 } from '../../App.style';
import wavesSvg from '../../../resources/login/login-waves-grey.svg';
import lektaLogo from '../../../resources/logo-lekta.png';
import {
  LoginContainer,
  LeftContainer,
  Waves,
  RightContainer,
  LoginForm,
  LoginButtonContainer,
  LektaLogo,
  LogoContainer,
  BadTokenContainer,
} from './Login.style';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { ResetPasswordRequest } from '../../../generated/public/Api';
import {
  checkPasswordReset,
  checkPasswordResetTokenAsyncThunk,
  checkResetPasswordToken,
  resetUserPasswordAsyncThunk,
} from '../users/api/userSlice';
import { ApiError } from '../../../common/types';
import {
  EuiFieldText,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalFooter,
  EuiModalHeader,
  EuiTextColor,
} from '@elastic/eui';
import { EyeIcon } from '../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../resources/icons-new/EyeSlashIcon';
import { useTranslation } from 'react-i18next';

const PasswordReset = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(checkPasswordResetTokenAsyncThunk(token));
    }
  }, []);

  const checkToken = useAppSelector(checkResetPasswordToken);

  const [badToken, setBadToken] = useState<boolean>(false);

  const updateTokenStatus = () => {
    const isTokenIdleAndSuccessful =
      checkToken.state === 'idle' && checkToken.value?.status === 'success';
    setBadToken(!isTokenIdleAndSuccessful);
  };

  useEffect(() => {
    updateTokenStatus();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [checkToken]);

  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isRePasswordVisible, setIsRePasswordVisible] =
    useState<boolean>(false);

  const handleResetPasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password.length > 11 && token && password === rePassword) {
      const data: ResetPasswordRequest = {
        new_password: password,
        confirm_password: rePassword,
        password_reset_token: token,
      };

      dispatch(resetUserPasswordAsyncThunk(data));
    } else {
      setSamePasswordsError(true);
    }

    return false;
  };

  const checkResetPassword = useAppSelector(checkPasswordReset);

  useEffect(() => {
    if (
      checkResetPassword.state === 'idle' &&
      checkResetPassword.value?.status === 'success'
    ) {
      showPasswordChangedModal();
    } else if (checkResetPassword.value?.error) {
      setApiErrors(checkResetPassword.value.error);
    }
  }, [checkResetPassword]);

  const [apiErrors, setApiErrors] = useState<ApiError<ResetPasswordRequest>>();

  const [samePasswordsError, setSamePasswordsError] = useState<boolean>(false);

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const [loading, setLoading] = useState<boolean>(true);

  const [changedPasswordModalVisible, setChangedPasswordModalVisible] =
    useState<boolean>(false);

  const closePasswordChangedModal = () => {
    setChangedPasswordModalVisible(false);
    navigate('/login');
  };

  const showPasswordChangedModal = () => setChangedPasswordModalVisible(true);

  let changedPasswordModal;

  if (changedPasswordModalVisible) {
    changedPasswordModal = (
      <EuiModal onClose={closePasswordChangedModal}>
        <EuiModalHeader>
          <H1 $bold>{t('login.resetPassword.form.changed')}</H1>
        </EuiModalHeader>

        <EuiModalFooter>
          <Button onClick={closePasswordChangedModal}>
            {t('login.resetPassword.form.backToLogin')}
          </Button>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <LoginContainer>
      {changedPasswordModal}
      <LeftContainer>
        <Waves src={wavesSvg} alt="waves" />
      </LeftContainer>
      <RightContainer>
        <LogoContainer>
          <LektaLogo src={lektaLogo} alt="lekta logo" />
        </LogoContainer>
        {loading ? (
          <EuiLoadingSpinner size={'xxl'} />
        ) : (
          <>
            {badToken ? (
              <BadTokenContainer>
                <H1>{t('login.resetPassword.form.wrongToken')}</H1>

                <Button onClick={handleBackToLogin}>
                  {t('login.resetPassword.form.backToLogin')}
                </Button>
              </BadTokenContainer>
            ) : (
              <LoginForm id="reset" onSubmit={handleResetPasswordSubmit}>
                <EuiFieldText
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={t('login.resetPassword.form.newPassword')}
                  append={
                    <PasswordVisibilityToggle
                      type={'button'}
                      onClick={() => setIsPasswordVisible((prev) => !prev)}
                    >
                      {isPasswordVisible ? (
                        <EyeIcon style={{ width: 25, height: 25 }} />
                      ) : (
                        <EyeSlashIcon style={{ width: 25, height: 25 }} />
                      )}
                    </PasswordVisibilityToggle>
                  }
                  value={password}
                  onChange={(ev) => {
                    setPassword(ev.target.value);
                    samePasswordsError && setSamePasswordsError(false);
                  }}
                />
                {apiErrors?.new_password && (
                  <EuiTextColor color={'danger'}>
                    {apiErrors.new_password}
                  </EuiTextColor>
                )}
                {samePasswordsError && (
                  <EuiTextColor
                    color={'danger'}
                    style={{ textAlign: 'center' }}
                  >
                    {t('login.resetPassword.form.tooShort')}
                  </EuiTextColor>
                )}

                <EuiFieldText
                  type={isRePasswordVisible ? 'text' : 'password'}
                  placeholder={t('login.resetPassword.form.confirmNewPassword')}
                  append={
                    <PasswordVisibilityToggle
                      type={'button'}
                      onClick={() => setIsRePasswordVisible((prev) => !prev)}
                    >
                      {isRePasswordVisible ? (
                        <EyeIcon style={{ width: 25, height: 25 }} />
                      ) : (
                        <EyeSlashIcon style={{ width: 25, height: 25 }} />
                      )}
                    </PasswordVisibilityToggle>
                  }
                  value={rePassword}
                  onChange={(ev) => {
                    setRePassword(ev.target.value);
                    samePasswordsError && setSamePasswordsError(false);
                  }}
                />

                {apiErrors?.confirm_password && (
                  <EuiTextColor color={'danger'}>
                    {apiErrors.confirm_password}
                  </EuiTextColor>
                )}

                <LoginButtonContainer>
                  <Button id="reset" type="submit">
                    {t('common.submit')}
                  </Button>
                </LoginButtonContainer>
              </LoginForm>
            )}
          </>
        )}
      </RightContainer>
    </LoginContainer>
  );
};

export default PasswordReset;

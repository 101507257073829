import styled from 'styled-components';

export const SecurityContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;
  max-height: 100vh;

  padding: 24px;

  position: relative;

  background: #ffffff;
`;

export const BreadcumbsContainer = styled.div`
  margin-top: 15px;
  width: 100%;

  display: flex;
  align-items: center;
  padding-left: 30px;
`;

export const SecurityContentContainer = styled.div`
  width: 100%;

  height: calc(100% - 40px);

  margin-top: 30px;

  padding-bottom: 0px;

  display: flex;

  position: relative;
`;

export const SecurityButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

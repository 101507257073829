import {
  EuiModalBody,
  EuiFieldText,
  EuiFlexItem,
  EuiToolTip,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { ApiError } from '../../../../common/types';
import { Label, LabelRequest } from '../../../../generated/tenants/Api';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  checkLabelCreate,
  checkLabelUpdate,
  clearCreateLabel,
  clearUpdateLabel,
  createLabelAsyncThunk,
  getLabelsListAsyncThunk,
  updateLabelAsyncThunk,
} from './api/labelsSlice';
import Modal from '../../../../common/modals/Modal';
import { H3 } from '../../../App.style';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import { useTranslation } from 'react-i18next';

interface LabelFormModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedLabel?: Label;
  labelText?: string;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LabelFormModal: React.FC<LabelFormModalProps> = ({
  isVisible,
  closeModal,
  selectedLabel,
  labelText,

  setIsLoadingVisible,
}) => {
  const { t } = useTranslation();
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState<LabelRequest>({
    text: '',
    color: '#EAD6C3',
    strategy: strategy,
  });

  useEffect(() => {
    if (isVisible) {
      setLabel({
        text: '',
        color: '#EAD6C3',
        strategy: strategy,
      });
    }
  }, [isVisible]);

  useEffect(() => {
    if (labelText) {
      setLabel({ ...label, text: labelText });
    }
  }, [labelText]);

  useEffect(() => {
    if (selectedLabel !== undefined) {
      setLabel(selectedLabel);
    }
  }, [selectedLabel]);

  const suggestedColors = [
    { hex: '#EAD6C3', text: t('presets.labelsForm.color1') },
    { hex: '#F1E2F6', text: t('presets.labelsForm.color2') },
    { hex: '#BADBCA', text: t('presets.labelsForm.color3') },
    { hex: '#BEE6EC', text: t('presets.labelsForm.color4') },
    { hex: '#D0CCC2', text: t('presets.labelsForm.color5') },
    { hex: '#DFB8B8', text: t('presets.labelsForm.color6') },
    { hex: '#EFD69F', text: t('presets.labelsForm.color7') },
    { hex: '#E7EBC5', text: t('presets.labelsForm.color8') },
  ];

  const [apiErrors, setApiErrors] = useState<ApiError<Label>>();

  const checkCreate = useAppSelector(checkLabelCreate);

  useEffect(() => {
    if (
      checkCreate?.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      closeModal();
      dispatch(getLabelsListAsyncThunk(strategy));
      dispatch(clearCreateLabel());
    } else {
      setApiErrors(checkCreate?.value?.error);
    }
  }, [checkCreate]);

  const checkUpdate = useAppSelector(checkLabelUpdate);

  useEffect(() => {
    if (
      checkUpdate?.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      closeModal();
      dispatch(getLabelsListAsyncThunk(strategy));
      dispatch(clearUpdateLabel());
    } else {
      setApiErrors(checkUpdate?.value?.error);
    }
  }, [checkUpdate]);

  //adding/editing label
  const handleButtonClick = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const labelData: LabelRequest = {
      text: label.text,
      description: label.description === '' ? null : label.description,
      color: label.color,
      strategy: strategy,
    };

    setIsLoadingVisible(true);

    if (selectedLabel) {
      const data: { id: string; data: LabelRequest } = {
        id: selectedLabel.id,
        data: labelData,
      };
      dispatch(updateLabelAsyncThunk(data)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      dispatch(createLabelAsyncThunk(labelData)).finally(() => {
        setIsLoadingVisible(false);
      });
    }

    return false;
  };

  const handleCloseLabelFormModal = () => {
    setLabel({ text: '', color: '#EAD6C3', strategy: strategy });
    closeModal();
  };

  const body = (
    <EuiModalBody>
      <FormRow
        label={<H3>{t('presets.labelsForm.name')}</H3>}
        fullWidth
        isInvalid={apiErrors?.text && true}
        error={apiErrors?.text}
      >
        <EuiFieldText
          fullWidth
          autoFocus
          maxLength={16}
          value={label.text}
          onChange={(e) => {
            setLabel({ ...label, text: e.target.value });
            setApiErrors({ ...apiErrors, text: undefined });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>{t('presets.labelsForm.selectColor')}</H3>}
        style={{ marginBottom: 0 }}
      >
        <EuiFlexItem
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 5,
          }}
        >
          {suggestedColors.map((color) => {
            const isSelected = label.color && label.color === color.hex;
            return (
              <EuiToolTip
                position="top"
                content={<p>{color.text}</p>}
                key={`label-color-${color.hex}`}
              >
                <EuiFlexItem
                  id={'color-box'}
                  style={{
                    background: color.hex,
                    height: 40,
                    width: 40,
                    borderRadius: 10,
                    border: isSelected ? '1px solid #127FBF' : '',
                    cursor: 'pointer',
                  }}
                  onClick={() => setLabel({ ...label, color: color.hex })}
                />
              </EuiToolTip>
            );
          })}
        </EuiFlexItem>
      </FormRow>
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={
        selectedLabel === undefined
          ? t('presets.labelsForm.createNewLabel')
          : t('presets.labelsForm.editLabel')
      }
      onCancel={handleCloseLabelFormModal}
      onConfirm={handleButtonClick}
      buttonText={
        selectedLabel === undefined
          ? t('common.create')
          : t('common.saveChanges')
      }
      children={body}
    />
  );
};

export default LabelFormModal;

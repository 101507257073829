import { RootState } from '../../../../store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CheckCalendarResult,
  getCalendarsList,
  createCalendar,
  getCalendarDetailed,
  updateCalendar,
  deleteCalendar,
} from './calendarApi';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../../generated/tenants/Api';

export interface CalendarsState {
  list: {
    state: 'pending' | 'idle';
    value: CalendarConfiguration[];
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckCalendarResult;
  };
  detailed: {
    state: 'pending' | 'idle';
    value?: CalendarConfiguration;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckCalendarResult;
  };
  delete: {
    state: 'pending' | 'idle';
    value?: CheckCalendarResult;
  };
}

const initialState: CalendarsState = {
  list: {
    state: 'pending',
    value: [],
  },
  create: { state: 'pending' },
  update: { state: 'pending' },
  delete: { state: 'pending' },
  detailed: { state: 'pending' },
};

export const getCalendarsListAsyncThunk = createAsyncThunk(
  'calendars/list',
  (id: string) => getCalendarsList(id),
);

export const createCalendarAsyncThunk = createAsyncThunk(
  'calendars/create',
  (data: CalendarConfigurationRequest) => createCalendar(data),
);

export const getCalendarDetailedAsyncThunk = createAsyncThunk(
  'calendars/detailed',
  (id: string) => getCalendarDetailed(id),
);

export const updateCalendarAsyncThunk = createAsyncThunk(
  'calendar/update',
  (args: { id: string; data: CalendarConfigurationRequest }) =>
    updateCalendar(args.id, args.data),
);

export const deleteCalendarAsyncThunk = createAsyncThunk(
  'calendar/delete',
  (id: string) => deleteCalendar(id),
);

export const calendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {
    clearCreateCalendar: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateCalendar: (state) => {
      state.update = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder

      //list
      .addCase(getCalendarsListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(getCalendarsListAsyncThunk.fulfilled, (state, action) => {
        state.list.state = 'idle';
        state.list.value = action.payload;
      })
      .addCase(getCalendarsListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //detailed
      .addCase(getCalendarDetailedAsyncThunk.pending, (state) => {
        state.detailed.state = 'pending';
      })
      .addCase(getCalendarDetailedAsyncThunk.fulfilled, (state, action) => {
        state.detailed.state = 'idle';
        state.detailed.value = action.payload;
      })
      .addCase(getCalendarDetailedAsyncThunk.rejected, (state) => {
        state.detailed.state = 'idle';
      })

      //create
      .addCase(createCalendarAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(createCalendarAsyncThunk.fulfilled, (state, action) => {
        state.create.state = 'idle';
        state.create.value = action.payload;
      })
      .addCase(createCalendarAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })

      //update
      .addCase(updateCalendarAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(updateCalendarAsyncThunk.fulfilled, (state, action) => {
        state.update.state = 'idle';
        state.update.value = action.payload;
      })
      .addCase(updateCalendarAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })

      //delete
      .addCase(deleteCalendarAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteCalendarAsyncThunk.fulfilled, (state, action) => {
        state.delete.state = 'idle';
        state.delete.value = action.payload;
      })
      .addCase(deleteCalendarAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      });
  },
});

export const selectCalendarsList = (state: RootState) =>
  state.calendar.list.value;

export const selectCalendarDetailed = (state: RootState) =>
  state.calendar.detailed.value;

export const checkCalendarCreate = (state: RootState) => state.calendar.create;

export const checkCalendarUpdate = (state: RootState) => state.calendar.update;

export const checkCalendarDelete = (state: RootState) => state.calendar.delete;

export const { clearCreateCalendar, clearUpdateCalendar } =
  calendarsSlice.actions;

export default calendarsSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PaginatedTokenList,
  PatchedUserSettingsRequest,
  TokenRequest,
  UserSettings,
  UserSettingsRequest,
} from '../../../../../generated/tenants/Api';
import {
  CheckUserPasswordChangeResult,
  CheckAccessTokenResult,
  getAccessTokensList,
  createAccessToken,
  deleteAccessToken,
  getUserSettings,
  updateUserAvatar,
  updateUserSettings,
  userPasswordChange,
} from './userSettingsApi';
import { UserChangePasswordRequest } from '../../../../../generated/public/Api';
import { RootState } from '../../../../store';

export interface UserSettingsState {
  settings: {
    update: { state: 'pending' | 'idle' };
    updateAvatar: { state: 'pending' | 'idle' };
    retrieve: { state: 'pending' | 'idle'; value?: UserSettings };
  };
  passwordChange: {
    state: 'pending' | 'idle';
    value?: CheckUserPasswordChangeResult;
  };
  accessTokens: {
    list: { state: 'pending' | 'idle'; value?: PaginatedTokenList };
    create: { state: 'pending' | 'idle'; value?: CheckAccessTokenResult };
    delete: { state: 'pending' | 'idle'; value?: CheckAccessTokenResult };
  };
}

const initialState: UserSettingsState = {
  settings: {
    update: { state: 'pending' },
    updateAvatar: { state: 'pending' },
    retrieve: { state: 'pending' },
  },
  passwordChange: {
    state: 'pending',
  },
  accessTokens: {
    list: { state: 'pending' },
    create: { state: 'pending' },
    delete: { state: 'pending' },
  },
};

//settings
export const updateUserSettingsAsyncThunk = createAsyncThunk(
  'user/settings/update',
  (data: UserSettingsRequest) => updateUserSettings(data),
);

export const updateUserAvatarAsyncThunk = createAsyncThunk(
  'user/settings/updateAvatar',
  (data: PatchedUserSettingsRequest) => updateUserAvatar(data),
);

export const getUserSettingsAsyncThunk = createAsyncThunk(
  'user/settings/retrieve',
  () => getUserSettings(),
);

//password change

export const userPasswordChangeAsyncThunk = createAsyncThunk(
  'session/passwordChange',
  (args: { data: UserChangePasswordRequest; baseDomain: string }) =>
    userPasswordChange(args.data, args.baseDomain),
);

//access tokens

export const getAccessTokensListAsyncThunk = createAsyncThunk(
  'accessTokens/list',
  (query?: { limit?: number; offset?: number }) => getAccessTokensList(query),
);

export const createAccessTokenAsyncThunk = createAsyncThunk(
  'accessTokens/create',
  (data: TokenRequest) => createAccessToken(data),
);

export const deleteAccessTokenAsyncThunk = createAsyncThunk(
  'accessTokens/delete',
  (id: string) => deleteAccessToken(id),
);

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    clearPasswordChangeState: (state) => {
      state.passwordChange.state = 'pending';
      state.passwordChange.value = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      //get user settings
      .addCase(getUserSettingsAsyncThunk.pending, (state) => {
        state.settings.retrieve.state = 'pending';
      })
      .addCase(getUserSettingsAsyncThunk.fulfilled, (state, action) => {
        state.settings.retrieve.state = 'idle';
        state.settings.retrieve.value = action.payload;
      })
      .addCase(getUserSettingsAsyncThunk.rejected, (state) => {
        state.settings.retrieve.state = 'idle';
      })

      //update settings
      .addCase(updateUserSettingsAsyncThunk.pending, (state) => {
        state.settings.update.state = 'pending';
      })
      .addCase(updateUserSettingsAsyncThunk.fulfilled, (state) => {
        state.settings.update.state = 'idle';
      })
      .addCase(updateUserSettingsAsyncThunk.rejected, (state) => {
        state.settings.update.state = 'idle';
      })

      //avatar
      .addCase(updateUserAvatarAsyncThunk.pending, (state) => {
        state.settings.updateAvatar.state = 'pending';
      })
      .addCase(updateUserAvatarAsyncThunk.fulfilled, (state) => {
        state.settings.updateAvatar.state = 'idle';
      })
      .addCase(updateUserAvatarAsyncThunk.rejected, (state) => {
        state.settings.updateAvatar.state = 'idle';
      })

      //password change
      .addCase(userPasswordChangeAsyncThunk.pending, (state) => {
        state.passwordChange.state = 'pending';
      })
      .addCase(userPasswordChangeAsyncThunk.fulfilled, (state, action) => {
        state.passwordChange.state = 'idle';
        state.passwordChange.value = action.payload;
      })
      .addCase(userPasswordChangeAsyncThunk.rejected, (state) => {
        state.passwordChange.state = 'idle';
      })

      //access tokens list
      .addCase(getAccessTokensListAsyncThunk.pending, (state) => {
        state.accessTokens.list.state = 'pending';
      })
      .addCase(getAccessTokensListAsyncThunk.fulfilled, (state, action) => {
        state.accessTokens.list.state = 'idle';
        state.accessTokens.list.value = action.payload;
      })
      .addCase(getAccessTokensListAsyncThunk.rejected, (state) => {
        state.accessTokens.list.state = 'idle';
      })

      //access tokens create
      .addCase(createAccessTokenAsyncThunk.pending, (state) => {
        state.accessTokens.create.state = 'pending';
      })
      .addCase(createAccessTokenAsyncThunk.fulfilled, (state, action) => {
        state.accessTokens.create.state = 'idle';
        state.accessTokens.create.value = action.payload;
      })
      .addCase(createAccessTokenAsyncThunk.rejected, (state) => {
        state.accessTokens.create.state = 'idle';
      })

      //access tokens delete
      .addCase(deleteAccessTokenAsyncThunk.pending, (state) => {
        state.accessTokens.delete.state = 'pending';
      })
      .addCase(deleteAccessTokenAsyncThunk.fulfilled, (state, action) => {
        state.accessTokens.delete.state = 'idle';
        state.accessTokens.delete.value = action.payload;
      })
      .addCase(deleteAccessTokenAsyncThunk.rejected, (state) => {
        state.accessTokens.delete.state = 'idle';
      });
  },
});

export const selectUserSettings = (state: RootState) =>
  state.userSettings.settings.retrieve;

export const checkUserPasswordChangeResult = (state: RootState) =>
  state.userSettings.passwordChange;

//access tokens
export const selectAccessTokensList = (state: RootState) =>
  state.userSettings.accessTokens.list.value;
export const checkAccessTokensCreate = (state: RootState) =>
  state.userSettings.accessTokens.create;
export const checkAccessTokensDelete = (state: RootState) =>
  state.userSettings.accessTokens.delete;

export const { clearPasswordChangeState } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;

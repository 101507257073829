import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../common/modals/Modal';
import { useAppDispatch } from '../../../../../../../common/hooks';
import {
  EuiModalBody,
  EuiFormRow,
  EuiFieldText,
  EuiSuperSelect,
} from '@elastic/eui';
import { H3, H5 } from '../../../../../../App.style';
import { inviteSuperUserAsyncThunk } from '../../../api/superAdminSlice';
import {
  AdminInvitation,
  AdminInvitationRequest,
} from '../../../../../../../generated/public/Api';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { ApiError } from '../../../../../../../common/types';

interface AddSuperUserModalProps {
  isVisible: boolean;
  closeModal: () => void;
  showPopup: () => void;
}

const AddSuperUserModal: React.FC<AddSuperUserModalProps> = ({
  isVisible,
  closeModal,
  showPopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [selectedRole, setSelectedRole] =
    useState<SuperPermissions>('SUPERADMIN');

  type SuperPermissions = 'SUPERADMIN' | 'SUPERVISOR';

  useEffect(() => {
    if (email.length) {
      validateEmail() ? setEmailError(false) : setEmailError(true);
    }
  }, [email]);

  const validateEmail = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [emailError, setEmailError] = useState<boolean>(false);

  const [apiEmailError, setApiEmailError] =
    useState<ApiError<AdminInvitation>>();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data: AdminInvitationRequest = {
      email: email,
      role: selectedRole === 'SUPERADMIN' ? 'SUPERUSER' : 'SUPERVISOR',
    };

    dispatch(inviteSuperUserAsyncThunk(data))
      .then(unwrapResult)
      .then((res) => {
        if (res.error) {
          setApiEmailError(res.error);
          console.log(res.error);
        } else {
          handleCancelClick();
          showPopup();
        }
      });

    return false;
  };

  //select options
  const superUserPermissionsOptions = [
    {
      value: 'SUPERADMIN',
      inputDisplay: <H5>Superadmin</H5>,
    },
    {
      value: 'SUPERVISOR',
      inputDisplay: <H5>Supervisor</H5>,
    },
  ];

  const handleCancelClick = () => {
    closeModal();
    setEmail('');
    setSelectedRole('SUPERADMIN');
    setApiEmailError(undefined);
  };

  const body = (
    <EuiModalBody>
      <EuiFormRow
        isInvalid={emailError ? true : apiEmailError ? true : false}
        error={
          emailError
            ? t('superAdminPanel.superUsers.inviteModal.emailError')
            : apiEmailError?.email &&
              `bug na backendzie, zwraca {email: {email: 'Error'}} // patrz konsola`
        }
        label={<H3>Email</H3>}
      >
        <EuiFieldText
          isInvalid={emailError ? true : apiEmailError ? true : false}
          value={email}
          autoFocus
          onChange={(e) => {
            setEmail(e.target.value);
            emailError && setEmailError(false);
            apiEmailError && setApiEmailError(undefined);
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        label={<H3>{t('superAdminPanel.superUsers.inviteModal.role')}</H3>}
      >
        <EuiSuperSelect
          valueOfSelected={selectedRole}
          onChange={(e) => setSelectedRole(e as SuperPermissions)}
          options={superUserPermissionsOptions}
        />
      </EuiFormRow>
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('superAdminPanel.superUsers.inviteModal.title')}
      onConfirm={handleSubmit}
      onCancel={handleCancelClick}
      buttonDisabled={emailError || apiEmailError?.email ? true : email === ''}
      children={body}
      buttonText={t('superAdminPanel.superUsers.inviteModal.buttonText')}
    />
  );
};

export default AddSuperUserModal;

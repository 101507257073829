import React, { useEffect, useState } from 'react';
import {
  BotConfiguration,
  BotConfigurationRequest,
  CustomFaas,
} from '../../../../../generated/tenants/Api';
import { useAppDispatch } from '../../../../../common/hooks';
import moment from 'moment';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../../PresetsPanel.style';
import EditPresetButtons from '../../shared/EditPresetButtons';
import AssociatedProcesses from '../../shared/AssociatedProcesses';
import CustomDialogueForm from './CustomDialogueForm';
import {
  FormContainer,
  FormRow,
} from '../../../processes/forms/ProcessForm.style';
import { H2, H3, H4 } from '../../../../App.style';
import { EuiFieldText } from '@elastic/eui';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { JSONCodeBlockContainer } from '../Dialogues.style';
import { updateDialogueAsyncThunk } from '../api/dialoguesSlice';
import LoadingModal from '../../../../../common/modals/LoadingModal';

interface CustomDialogueDetailedProps {
  customDialogue: BotConfiguration;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  customFaasesList: CustomFaas[];
  isSuperAdmin: boolean;
}

const CustomDialogueDetailed: React.FC<CustomDialogueDetailedProps> = ({
  customDialogue,
  backToList,
  findProcessNamesById,

  customFaasesList,
  isSuperAdmin,
}) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(customDialogue.created_at).format(
    'DD/MM/YYYY, HH:mm',
  );
  const fomrattedModified = moment(customDialogue.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editCustomDialogue, setEditCustomDialogue] =
    useState<BotConfigurationRequest>({
      name: '',
      template: 'DEBTOR',
      is_custom: true,
      language: 'pl-PL',
      strategy: '',
      runtime_parameters: {},

      record_close_rules: {},
      custom_label: '',
    });

  useEffect(() => {
    setEditCustomDialogue({ ...customDialogue, is_draft: false });
  }, [customDialogue]);

  const handleCustomDialogueUpdateClick = () => {
    const id = customDialogue.id;
    if (editCustomDialogue && id) {
      setIsLoadingVisible(true);
      const args = { id: id, data: editCustomDialogue };
      dispatch(updateDialogueAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditCustomDialogue(customDialogue);
  };

  const associatedWithProcesses = findProcessNamesById(customDialogue.id);

  const handleBackToDetailed = () => setEdit(false);

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {isSuperAdmin && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleCustomDialogueUpdateClick}
          hasErrors={hasErrors}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{customDialogue.name}</H2>
            <H4 $grey>
              created {formattedCreated}, modified {fomrattedModified}
            </H4>
          </PresetNameContainer>

          <FormRow label={<H3>Custom label</H3>}>
            <EuiFieldText
              value={customDialogue.custom_label ?? ''}
              readOnly={true}
            />
          </FormRow>

          <FormRow label={<H3>Image tag</H3>}>
            <EuiFieldText
              value={customDialogue.image_tag ?? ''}
              readOnly={true}
            />
          </FormRow>

          <FormRow label={<H3>Runtime parameters</H3>}>
            <JSONCodeBlockContainer>
              <AceEditor
                mode="json"
                theme="github"
                value={JSON.stringify(
                  customDialogue.runtime_parameters,
                  null,
                  2,
                )}
                name="runtime_parameters_json"
                height="150px"
                readOnly={true}
              />
            </JSONCodeBlockContainer>
          </FormRow>

          <FormRow label={<H3>Template</H3>}>
            <EuiFieldText
              value={customDialogue.template ?? ''}
              readOnly={true}
            />
          </FormRow>

          <FormRow label={<H3>Record close rules</H3>}>
            <JSONCodeBlockContainer>
              <AceEditor
                mode="json"
                theme="github"
                value={JSON.stringify(
                  customDialogue.record_close_rules,
                  null,
                  2,
                )}
                name="record_close_rules_json"
                height="150px"
                readOnly={true}
              />
            </JSONCodeBlockContainer>
          </FormRow>

          <FormRow label={<H3>Strategy config</H3>}>
            <JSONCodeBlockContainer>
              <AceEditor
                mode="json"
                theme="github"
                value={JSON.stringify(customDialogue.strategy_config, null, 2)}
                name="strategy_config_json"
                height="150px"
                readOnly={true}
              />
            </JSONCodeBlockContainer>
          </FormRow>

          <FormRow label={<H3>Generations config</H3>}>
            <JSONCodeBlockContainer>
              <AceEditor
                mode="json"
                theme="github"
                value={JSON.stringify(
                  customDialogue.generations_config,
                  null,
                  2,
                )}
                name="generations_config_json"
                height="150px"
                readOnly={true}
              />
            </JSONCodeBlockContainer>
          </FormRow>

          {/* <FormRow label={<H3>Report per call FAAS URL</H3>}>
            <EuiFieldText
              readOnly
              value={selectedCustomFaasPerCall?.name ?? 'Not selected'}
            />
          </FormRow>

          <FormRow label={<H3>Report per record FAAS URL</H3>}>
            <EuiFieldText
              readOnly
              value={selectedCustomFaasPerRecord?.name ?? 'Not selected'}
            />
          </FormRow> */}
        </FormContainer>
      ) : (
        <CustomDialogueForm
          customDialogue={editCustomDialogue}
          setCustomDialogue={setEditCustomDialogue}
          backToList={backToList}
          backToDetailed={handleBackToDetailed}
          setHasErrors={setHasErrors}
          customFaasesList={customFaasesList}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default CustomDialogueDetailed;

import { EuiModalBody, EuiFormRow, EuiFieldText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { H2, H3 } from '../../App.style';
import {
  checkPasswordResetTokenCreate,
  clearPasswordResetToken,
  sendPasswordResetTokenAsyncThunk,
} from '../users/api/userSlice';
import Modal from '../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface PasswordResetModalProps {
  isVisible: boolean;
  closeModal: () => void;
}

const PasswordResetModal: React.FC<PasswordResetModalProps> = ({
  isVisible,
  closeModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      sendPasswordResetTokenAsyncThunk({
        email: email,
      }),
    );
    setEmailSent(true);

    return false;
  };

  function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    if (email.length) {
      validateEmail(email) ? setEmailError(false) : setEmailError(true);
    }
  }, [email]);

  const checkPasswordResetToken = useAppSelector(checkPasswordResetTokenCreate);

  useEffect(() => {
    if (checkPasswordResetToken.state === 'idle') {
      dispatch(clearPasswordResetToken());
    }
  }, [checkPasswordResetToken]);

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleCloseModal = () => {
    setEmail('');
    setEmailSent(false);
    closeModal();
  };

  const body = (
    <EuiModalBody style={{ alignItems: 'center' }}>
      {emailSent ? (
        <H2>{t('login.resetPassword.modal.after')}</H2>
      ) : (
        <>
          <H3 style={{ marginBottom: 16 }}>
            {t('login.resetPassword.modal.tip')}
          </H3>
          <EuiFormRow
            isInvalid={emailError}
            error={t('login.resetPassword.modal.notValidEmail')}
            label={<H3>Email</H3>}
          >
            <EuiFieldText
              type={'email'}
              isInvalid={emailError}
              value={email}
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </EuiFormRow>
        </>
      )}
    </EuiModalBody>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={
        !emailSent
          ? t('login.resetPassword.modal.title')
          : t('login.resetPassword.modal.confirmation')
      }
      onCancel={handleCloseModal}
      onConfirm={handleSubmit}
      children={body}
      buttonDisabled={email === '' || !validateEmail(email)}
      noConfirm={emailSent}
      buttonText={emailSent ? 'Ok' : undefined}
      width={400}
    />
  );
};

export default PasswordResetModal;

import React, { useEffect, useState } from 'react';
import {
  PhoneNumberSwapRequest,
  TenantPhoneNumber,
} from '../../../../../../generated/tenants/Api';
import {
  PhoneNumberRowContainer,
  UnassignedNumbersIconContainer,
  PhoneNumbersContainer,
  PhoneNumbersContentContainer,
  PhoneNumbersContentLeftContainer,
  PhoneNumbersContentRightContainer,
  PhoneNumbersDroppableContainer,
  UnassignedNumbersIconsContainer,
  UnassignedNumbersTitleContainer,
  LoadingNumbersContainer,
  TenantUsedNumbersContainer,
} from './PhoneNumbers.style';
import { COLOR_NEUTRAL_80, H1, H2, H3, H6 } from '../../../../../App.style';

import MinusInCircleIcon from '../../../../../../resources/icons-new/MinusInCircleIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  getStrategiesListAsyncThunk,
  selectStrategiesList,
} from '../strategies/api/strategiesSlice';
import {
  checkPhoneNumberSwap,
  getTenantPhoneNumbersAsyncThunk,
  selectTenantPhoneNumbersList,
  swapPhoneNumbersAsyncThunk,
  updatePhoneNumberAsyncThunk,
} from './api/phoneNumbersSlice';
import {
  DropResult,
  EuiDragDropContext,
  EuiDraggable,
  EuiLoadingSpinner,
  EuiSuperSelect,
  EuiToolTip,
} from '@elastic/eui';
import ReplaceNumberModal from './modals/ReplaceNumberModal';
import { ExchangeIcon } from '../../../../../../resources/icons-new/ExchangeIcon';
import { formatPhoneNumber } from './numbersFunctions';
import UnassignNumberModal from './modals/UnassignNumberModal';
import { useAutoClosePopup } from '../../../../../../common/popups/useAutoClosePopup';
import SuccessPopup from '../../../../../../common/popups/SuccessPopup';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from '../../../../../../resources/icons-new/EyeIcon';
import DetailedTenantNumberModal from './modals/DetailedTenantNumberModal';

const NumbersStrategyAssign = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const strategyToPhoneNumbers: Record<string, TenantPhoneNumber[]> = {};
  const unusedNumbers: TenantPhoneNumber[] = [];

  //search
  const [assignedNumbersSearch, setAssignedNumbersSearch] = useState('');
  const handleAssignedNumbersSearchChange = (value: string) => {
    setAssignedNumbersSearch(value);
  };
  const [availableNumbersSearch, setAvailableNumbersSearch] = useState('');
  const handleAvailablenumbersSearchChange = (value: string) => {
    setAvailableNumbersSearch(value);
  };

  useEffect(() => {
    dispatch(getTenantPhoneNumbersAsyncThunk());
    dispatch(getStrategiesListAsyncThunk());
  }, []);

  const apiTenantPhoneNumbers = useAppSelector(selectTenantPhoneNumbersList);
  const apiStrategiesList = useAppSelector(selectStrategiesList);

  for (const phoneNumber of apiTenantPhoneNumbers) {
    if (phoneNumber.strategy) {
      if (!strategyToPhoneNumbers[phoneNumber.strategy]) {
        strategyToPhoneNumbers[phoneNumber.strategy] = [];
      }

      strategyToPhoneNumbers[phoneNumber.strategy].push(phoneNumber);
    } else {
      unusedNumbers.push(phoneNumber);
    }
  }

  //strategy select
  const [selectedStrategyId, setSelectedStrategyId] = useState<string>('');

  const strategySelectOptions = apiStrategiesList.map((strategy) => {
    return {
      value: strategy.id,
      inputDisplay: <H3>{strategy.name}</H3>,
      dropdownDisplay: <H3>{strategy.name}</H3>,
    };
  });

  useEffect(() => {
    if (apiStrategiesList.length > 0 && selectedStrategyId === '') {
      setSelectedStrategyId(apiStrategiesList[0].id);
    }
  }, [apiStrategiesList]);

  const filteredNumbers = assignedNumbersSearch
    ? strategyToPhoneNumbers[selectedStrategyId].filter((date) =>
        date.phone_number_str.includes(assignedNumbersSearch),
      )
    : strategyToPhoneNumbers[selectedStrategyId];

  const filteredUnusedNumbers = availableNumbersSearch
    ? unusedNumbers.filter((date) =>
        date.phone_number_str.includes(availableNumbersSearch),
      )
    : unusedNumbers;

  const getStrategyNameById = (strategy_id: string) => {
    const foundStrategy = apiStrategiesList.find(
      (strategy) => strategy.id === strategy_id,
    );
    return foundStrategy ? foundStrategy.name : 'error';
  };

  const selectedStrategyName = getStrategyNameById(selectedStrategyId);

  //replace number modal
  const [selectedNumber, setSelectedNumber] = useState<TenantPhoneNumber>();
  const [isReplaceNumberModalVisible, setIsReplaceNumberModalVisible] =
    useState<boolean>(false);
  const closeReplaceNumberModal = () => {
    setIsReplaceNumberModalVisible(false);
    setSelectedNumber(undefined);
  };
  const showReplaceNumberModal = (number: TenantPhoneNumber) => {
    setSelectedNumber(number);
    setIsReplaceNumberModalVisible(true);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // Check if the destination is valid (item dropped in a droppable area)
    if (!destination) return;

    // Dragging from left to right (unusedNumbers to filteredNumbers)
    if (
      source.droppableId === 'unusedNumbersDroppable' &&
      destination.droppableId === 'filteredNumbersDroppable'
    ) {
      setLoading(true);
      const item = unusedNumbers[source.index];

      // Prepare data for dispatch
      const data = {
        numberId: item.id,
        data: { strategy: selectedStrategyId },
      };

      // Dispatch action
      dispatch(updatePhoneNumberAsyncThunk(data)).then(() => {
        dispatch(getTenantPhoneNumbersAsyncThunk());
        setSuccessPopupNumber(formatPhoneNumber(item.phone_number_str));
        showNumberAssignedPopup();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  };

  const handleUnassignNumber = (numberId: string) => {
    const args = {
      numberId: numberId,
      data: { strategy: null },
    };

    dispatch(updatePhoneNumberAsyncThunk(args)).then(() => {
      dispatch(getTenantPhoneNumbersAsyncThunk());
      closeUnassignNewNumberModal();
    });
  };

  const handleSwapNumber = (oldNumber: string, newNumber: string) => {
    const data: PhoneNumberSwapRequest = {
      old_phone_number: oldNumber,
      new_phone_number: newNumber,
    };
    dispatch(swapPhoneNumbersAsyncThunk(data));
  };

  const apiSwapNumber = useAppSelector(checkPhoneNumberSwap);

  useEffect(() => {
    if (
      apiSwapNumber.state === 'idle' &&
      apiSwapNumber.value?.status === 'success'
    ) {
      closeReplaceNumberModal();
      showSwapNumberPopup();
      dispatch(getTenantPhoneNumbersAsyncThunk());
      dispatch(getStrategiesListAsyncThunk());
    }
  }, [apiSwapNumber]);

  //unasign modal
  const [isUnassignNewNumberModalVisible, setIsUnassignNewNumberModalVisible] =
    useState<boolean>(false);
  const closeUnassignNewNumberModal = () => {
    setIsUnassignNewNumberModalVisible(false);
    setSelectedNumber(undefined);
  };
  const showUnassignNumberModal = (number: TenantPhoneNumber) => {
    setSelectedNumber(number);
    setIsUnassignNewNumberModalVisible(true);
  };

  //detail modal
  const [isDetailModalVisible, setIsDetailModalVisible] =
    useState<boolean>(false);
  const [selectedTenantPhoneNumber, setSelectedTenantPhoneNumber] =
    useState<TenantPhoneNumber>();
  const openDetailedTenantPhoneNumberModal = (number: TenantPhoneNumber) => {
    setSelectedTenantPhoneNumber(number);
    setIsDetailModalVisible(true);
  };

  const closeDetailedTenantPhoneNumberModal = () => {
    setSelectedTenantPhoneNumber(undefined);
    setIsDetailModalVisible(false);
  };

  //popup
  const [successPopupNumber, setSuccessPopupNumber] = useState<string>('');
  const {
    isVisible: numberAssignedPopupVisible,
    showPopup: showNumberAssignedPopup,
    closePopup: closeNumberAssignedPopup,
  } = useAutoClosePopup();

  const {
    isVisible: numberSwappedPopupVisible,
    showPopup: showSwapNumberPopup,
    closePopup: closeSwapNumberPopup,
  } = useAutoClosePopup();

  const closeSuccessPopup = () => {
    closeNumberAssignedPopup();
    setSuccessPopupNumber('');
  };
  return (
    <PhoneNumbersContainer>
      {selectedNumber && isUnassignNewNumberModalVisible && (
        <UnassignNumberModal
          isVisible={isUnassignNewNumberModalVisible}
          closeModal={closeUnassignNewNumberModal}
          handleUnassignNumber={handleUnassignNumber}
          strategyName={selectedStrategyName}
          selectedNumber={selectedNumber}
        />
      )}

      {selectedNumber && (
        <ReplaceNumberModal
          isVisible={isReplaceNumberModalVisible}
          closeModal={closeReplaceNumberModal}
          handleSwapNumber={handleSwapNumber}
          unusedNumbers={unusedNumbers}
          selectedReplaceNumber={selectedNumber}
          strategyId={selectedStrategyId}
        />
      )}

      {selectedTenantPhoneNumber && (
        <DetailedTenantNumberModal
          isVisible={isDetailModalVisible}
          closeModal={closeDetailedTenantPhoneNumberModal}
          selectedNumber={selectedTenantPhoneNumber}
        />
      )}

      {numberAssignedPopupVisible && (
        <SuccessPopup
          text={`Number ${successPopupNumber} assigned properly`}
          onClose={closeSuccessPopup}
        />
      )}

      {numberSwappedPopupVisible && (
        <SuccessPopup
          text={`Number swapped properly.`}
          onClose={closeSwapNumberPopup}
        />
      )}

      <H1 $bold>{t('settings.adminPanel.numbers.assign.title')}</H1>

      <PhoneNumbersContentContainer>
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <PhoneNumbersContentLeftContainer>
            <H2 $selected>{t('settings.adminPanel.numbers.assign.all')}</H2>
            <H3>{t('settings.adminPanel.numbers.assign.free')}</H3>
            <PhoneNumbersDroppableContainer
              droppableId="unusedNumbersDroppable"
              withPanel
              grow={false}
            >
              <>
                <div style={{ margin: 8 }}>
                  <SearchInput
                    style={{
                      border: '1px solid #9AA5B1',
                      borderRadius: 5,
                      outline: 'none',
                    }}
                    value={availableNumbersSearch}
                    setValue={handleAvailablenumbersSearchChange}
                  />
                </div>
                {loading ? (
                  <LoadingNumbersContainer>
                    <EuiLoadingSpinner size="xl" />
                  </LoadingNumbersContainer>
                ) : (
                  <>
                    {filteredUnusedNumbers &&
                    filteredUnusedNumbers.length > 0 ? (
                      filteredUnusedNumbers.map((number, idx) => (
                        <EuiDraggable
                          key={number.id}
                          index={idx}
                          draggableId={number.id}
                        >
                          {(provided, state) => (
                            <PhoneNumberRowContainer>
                              {formatPhoneNumber(number.phone_number_str)}
                              {state.isDragging && ' ✨'}
                            </PhoneNumberRowContainer>
                          )}
                        </EuiDraggable>
                      ))
                    ) : (
                      <div style={{ margin: 10 }}>
                        <H3>{t('settings.adminPanel.numbers.assign.empty')}</H3>
                      </div>
                    )}
                  </>
                )}
              </>
            </PhoneNumbersDroppableContainer>
          </PhoneNumbersContentLeftContainer>
          <PhoneNumbersContentRightContainer>
            <UnassignedNumbersTitleContainer>
              <H2 $selected>
                {t('settings.adminPanel.numbers.assign.workspace')}
              </H2>

              <EuiSuperSelect
                compressed
                style={{ width: 250, marginTop: -5 }}
                valueOfSelected={selectedStrategyId}
                onChange={(e) => setSelectedStrategyId(e)}
                options={strategySelectOptions}
              />
            </UnassignedNumbersTitleContainer>
            <PhoneNumbersDroppableContainer
              droppableId="filteredNumbersDroppable"
              withPanel
              grow={false}
            >
              <>
                <div style={{ margin: 8, paddingTop: 8 }}>
                  <SearchInput
                    style={{
                      border: '1px solid #9AA5B1',
                      borderRadius: 5,
                      outline: 'none',
                    }}
                    value={assignedNumbersSearch}
                    setValue={handleAssignedNumbersSearchChange}
                  />
                </div>
                {filteredNumbers && filteredNumbers.length > 0 ? (
                  filteredNumbers.map((number, idx) => {
                    const is_only_number_process = number.process_list.some(
                      (process) => process.is_only_used_number,
                    );
                    const is_only_number_campaign = number.campaign_list.some(
                      (process) => process.is_only_used_number,
                    );

                    return (
                      <EuiDraggable
                        key={number.id}
                        index={idx}
                        draggableId={number.id}
                        isDragDisabled={true}
                      >
                        {(provided, state) => (
                          <PhoneNumberRowContainer>
                            {formatPhoneNumber(number.phone_number_str)}
                            {state.isDragging && ' ✨'}
                            <UnassignedNumbersIconsContainer>
                              <UnassignedNumbersIconContainer
                                onClick={() => showReplaceNumberModal(number)}
                              >
                                <ExchangeIcon color={COLOR_NEUTRAL_80} />
                                <H6>
                                  {t(
                                    'settings.adminPanel.numbers.assign.replace',
                                  )}
                                </H6>
                              </UnassignedNumbersIconContainer>
                              <UnassignedNumbersIconContainer
                                onClick={() => {
                                  if (
                                    !is_only_number_process &&
                                    !is_only_number_campaign
                                  ) {
                                    showUnassignNumberModal(number);
                                  }
                                }}
                              >
                                {is_only_number_process ||
                                is_only_number_campaign ? (
                                  <>
                                    <EuiToolTip
                                      position="top"
                                      content={`Tego numeru nie można odpiąć, ponieważ jest przypisany do procesu/ów lub trwających kampanii w którym jest jedynym numerem`}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          gap: 5,
                                        }}
                                      >
                                        <MinusInCircleIcon
                                          color={COLOR_NEUTRAL_80}
                                          $disabled={
                                            is_only_number_campaign ||
                                            is_only_number_process
                                          }
                                        />
                                        <H6>
                                          {t(
                                            'settings.adminPanel.numbers.assign.unassign',
                                          )}
                                        </H6>
                                      </div>
                                    </EuiToolTip>
                                  </>
                                ) : (
                                  <>
                                    <MinusInCircleIcon
                                      color={COLOR_NEUTRAL_80}
                                      $disabled={
                                        is_only_number_campaign ||
                                        is_only_number_process
                                      }
                                    />
                                    <H6>
                                      {t(
                                        'settings.adminPanel.numbers.assign.unassign',
                                      )}
                                    </H6>
                                  </>
                                )}
                              </UnassignedNumbersIconContainer>
                            </UnassignedNumbersIconsContainer>

                            <TenantUsedNumbersContainer
                              onClick={(e) => {
                                e.stopPropagation();
                                openDetailedTenantPhoneNumberModal(number);
                              }}
                            >
                              <H6 $grey>
                                in use: {number.campaign_list.length} campaigns,{' '}
                                {number.process_list.length} process
                              </H6>
                              <EyeIcon style={{ width: 16, height: 16 }} />
                            </TenantUsedNumbersContainer>
                          </PhoneNumberRowContainer>
                        )}
                      </EuiDraggable>
                    );
                  })
                ) : (
                  <div style={{ margin: 10 }}>
                    <H3>{t('settings.adminPanel.numbers.assign.empty')}</H3>
                  </div>
                )}
              </>
            </PhoneNumbersDroppableContainer>
          </PhoneNumbersContentRightContainer>
        </EuiDragDropContext>
      </PhoneNumbersContentContainer>
    </PhoneNumbersContainer>
  );
};

export default NumbersStrategyAssign;

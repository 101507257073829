import React, { CSSProperties } from 'react';
import { Input, SearchInputContainer } from './Inputs.style';
import { SearchIcon } from '../../resources/icons-new/SearchIcon';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  setValue,
  placeholder,
  disabled,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <SearchInputContainer>
      <Input
        disabled={disabled}
        style={style}
        value={value}
        $isDisabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        $isSearch={true}
        placeholder={placeholder ? placeholder : t('common.search')}
      />
      <SearchIcon
        style={{ position: 'absolute', width: 15, height: 15, marginLeft: 8 }}
      />
    </SearchInputContainer>
  );
};

export default SearchInput;

import { fontIBM, COLOR_NEUTRAL_80 } from '../../../../App.style';
import styled from 'styled-components';

export const CampaignsBarContainer = styled.div`
  width: 100%;
  min-width: 500px;

  z-index: 10;

  display: flex;

  flex-direction: column;

  justify-content: center;
`;

export const TopContainer = styled.div`
  width: 100%;

  display: flex;

  align-items: center;
  margin-top: -3px;
  gap: 16px;

  position: relative;
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 16px;

  display: flex;
`;

export const SelectContainer = styled.div`
  height: 100%;
`;

export const CalendarContainer = styled.div`
  height: 100%;
  padding-left: 400px;

  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ClearDatesContainer = styled.div`
  font-family: ${fontIBM};
  font-size: 12px;
  color: ${COLOR_NEUTRAL_80};

  display: flex;
  align-items: center;
  gap: 7px;

  cursor: pointer;

  &:hover {
    color: #127fbf;
  }
`;

export const CampaignsSearchContainer = styled.div`
  width: 40%;
  height: 100%;

  display: flex;
  padding-left: 30px;
  align-items: center;

  position: relative;
`;

export const SearchContainer = styled.div`
  position: absolute;
  right: 0px;
  height: 30px;
`;

//Filters

export const CampaignsFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const FilterContainer = styled.div`
  padding: 2px 6px;
  display: flex;
  height: 30px;
  gap: 6px;
  background: #f2f2f2;
  border-radius: 5px;

  align-items: center;
`;

import React, { useEffect, useState } from 'react';
import { SFTPPanelContainer, SftpContentContainer } from './SftpPanel.style';
import { H1 } from '../../../../../App.style';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  getSftpConfigAsyncThunk,
  getSftpPublicKeysListAsyncThunk,
  checkSftpPasswordAsyncThunk,
  selectSftpConfig,
  getSftpConfigurationsListAsyncThunk,
} from './api/sftpConfigSlice';
import { SftpConfig } from '../../../../../../generated/tenants/Api';
import SftpUploadAccordion from './upload/SftpUploadAccordion';
import SftpConfigurationsListAccordion from './list/SftpConfigurationsListAccordion';

const SftpPanel = () => {
  const dispatch = useAppDispatch();
  const [sftpConfig, setSftpConfig] = useState<SftpConfig>();

  const [openSection, setOpenSection] = useState<'upload' | 'list' | undefined>(
    'upload',
  );

  //wtf
  useEffect(() => {
    dispatch(getSftpConfigAsyncThunk());
    dispatch(getSftpPublicKeysListAsyncThunk());
    dispatch(checkSftpPasswordAsyncThunk());
    dispatch(getSftpConfigurationsListAsyncThunk());
  }, []);

  const lektaSftpConfig = useAppSelector(selectSftpConfig);
  useEffect(() => {
    if (lektaSftpConfig) {
      setSftpConfig(lektaSftpConfig);
    }
  }, [lektaSftpConfig]);

  const handleHeaderClick = (section: 'list' | 'upload') => {
    if (section === 'list') {
      openSection === 'list'
        ? setOpenSection(undefined)
        : setOpenSection('list');
    } else if (section === 'upload') {
      openSection === 'upload'
        ? setOpenSection(undefined)
        : setOpenSection('upload');
    }
  };

  return (
    <SFTPPanelContainer>
      <H1 $medium>SFTP</H1>

      {sftpConfig && (
        <SftpContentContainer>
          <SftpUploadAccordion
            handleHeaderClick={handleHeaderClick}
            isOpen={openSection === 'upload'}
            sftpConfig={sftpConfig}
          />

          {/* new  */}

          <SftpConfigurationsListAccordion
            handleHeaderClick={handleHeaderClick}
            isOpen={openSection === 'list'}
            sftpConfig={sftpConfig}
          />
        </SftpContentContainer>
      )}
    </SFTPPanelContainer>
  );
};

export default SftpPanel;

import { COLOR_PRIMARY_3, H4 } from './../../App.style';
import styled from 'styled-components';
import {
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_10,
  COLOR_NEUTRAL_20,
  COLOR_PRIMARY_2,
  COLOR_PRIMARY_1,
} from '../../App.style';
import { CampaignUpdateStatusEnum } from '../../../generated/tenants/Api';

export const UsersListContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;

  padding: 24px;

  position: relative;

  background: #ffffff;
`;

//breadcrumbs
export const BreadcumbsContainer = styled.div`
  margin-top: 15px;
  width: 100%;

  display: flex;
  align-items: center;
  padding-left: 30px;
`;

//bar

export const UsersBarContainer = styled.div`
  width: 100%;
  min-width: 500px;

  z-index: 10;

  display: flex;

  flex-direction: column;
`;

export const UsersBarTopContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const UsersBarBottomContainer = styled.div`
  width: 100%;
  margin-top: 16px;

  display: flex;
  align-items: center;
  position: relative;
`;

//table
export const UsersListTableContainer = styled.div`
  width: 100%;
  height: calc(100% - 80px);

  padding: 16px 0px;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const UsersListTab = styled.table`
  margin-top: 8px;
  width: 100%;
  height: 100%;
  border-collapse: separate;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    height: 100%;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background: white; // adjust this to your needs
  }
`;

interface UsersListTableProps {
  avatar?: boolean;
  username?: boolean;
  userRole?: boolean;
  permissions?: boolean;
  pagination?: boolean;
}

const getWidth = (props: UsersListTableProps) => {
  if (props.avatar) return '50px';
  if (props.pagination) return '200px';
  if (props.username) return '250px';
  if (props.userRole) return '150px';
  if (props.permissions) return '400px';
  return 'auto'; // A default width if no props match
};

export const UsersListTableHeader = styled.th<UsersListTableProps>`
  border-top: 1px solid ${COLOR_NEUTRAL_40};
  border-bottom: 1px solid ${COLOR_NEUTRAL_40};
  padding: 8px;
  width: ${getWidth};
  text-align: left;
  vertical-align: middle;
`;

export const UsersListTableRow = styled.tr<{ no_permissions?: boolean }>`
  opacity: ${(props) => props.no_permissions && '50%'};
  &:hover {
    cursor: pointer;
    background: ${COLOR_NEUTRAL_10};
  }
`;

export const UsersListTableData = styled.td<UsersListTableProps>`
  border-bottom: 1px solid ${COLOR_NEUTRAL_20};
  padding: ${(props) =>
    props.avatar ? '0px' : props.pagination ? '0px 8px' : '12px 8px'};
  width: ${getWidth};
  vertical-align: middle;
`;

export const UserAvatarImg = styled.img<{ detailed?: boolean }>`
  width: ${(props) => (props.detailed ? '63px' : '28px')};
  height: ${(props) => (props.detailed ? '63px' : '28px')};
  border-radius: 50%;
`;

interface UserAvatarDiv {
  color?: string;
  detailed?: boolean;
}

export const UserAvatarDiv = styled.div<UserAvatarDiv>`
  width: ${(props) => (props.detailed ? '63px' : '28px')};
  height: ${(props) => (props.detailed ? '63px' : '28px')};
  border-radius: 50%;

  background: ${(props) => props.color && props.color};

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface UserRoleContainerProps {
  role: 'owner' | 'admin' | 'user';
}

const getUserRoleBackgroundColor = (role: UserRoleContainerProps['role']) => {
  switch (role) {
    case 'owner':
      return COLOR_PRIMARY_3;
    case 'admin':
      return COLOR_PRIMARY_2;
    case 'user':
      return COLOR_NEUTRAL_20;
    default:
      return COLOR_NEUTRAL_20; // Default color if none of the roles match
  }
};

export const UserRoleContainer = styled.div<UserRoleContainerProps>`
  display: inline-flex;
  padding: 2px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  background: ${({ role }) => getUserRoleBackgroundColor(role)};
`;

export const UserStrategyPermissionRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

//search
export const UsersListSearchContainer = styled.div`
  position: absolute;
  right: 0px;
  height: 30px;
`;

//popup
export const UserPopupContainer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 400px;
  z-index: 10;
  background: ${COLOR_PRIMARY_2};
  border: 1px solid ${COLOR_PRIMARY_1};
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 11px;
`;

export const UserPopupCloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 7px;
  cursor: pointer;
`;

export const UserPopupContent = styled.div`
  display: flex;
  gap: 8px;
`;

export const EditPermissionsContainer = styled.div`
  width: 100%;
  min-width: 110px;
  padding-left: 3px;

  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const EditAccessContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &:hover ${H4} {
    text-decoration: underline;
  }
`;

//Add user
export const NewUserStrategyPermissionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

//stary obiekt
export interface CampaignUpdate {
  /** @format uuid */
  readonly id: string;

  /** @format date-time */
  readonly created_at: string;

  /** @format uuid */
  campaign: string;

  /** @format uuid */
  triggered_by?: string | null;
  triggered_by_system?: boolean;

  /**
   * * `PENDING` - Pending
   * * `SCHEDULED` - Scheduled
   * * `RUNNING` - Running
   * * `PAUSED` - Paused
   * * `STOPPED` - Stopped
   * * `FINISHED` - Finished
   * * `ERROR` - Error
   */
  status: CampaignUpdateStatusEnum;

  /**
   * @min 0
   * @max 2147483647
   */
  active_calls?: number;
  inbound_paused?: boolean;
  cc_paused?: boolean;
}

import {
  Campaign,
  CampaignChangeRequest,
  CampaignCreateRequest,
  CampaignPauseCcRequest,
  CampaignPauseInboundRequest,
  CampaignUpdateStatusRequest,
  RecordImportFileRequest,
  RecordImportFileResponse,
} from '../../../../generated/tenants/Api';
import { Api } from '../../../../generated/tenants/Api';
import { ApiError } from '../../../../common/types';

const { api } = new Api();

export interface CheckCampaignsResult {
  status: 'success' | 'fail';
  error?: ApiError<Campaign>;
  campaign?: Campaign;
}

export interface CheckCampaignStatusResult {
  status: 'success' | 'fail';
  error?: ApiError<Campaign>;
}

//panel
export const getCampaignsPanelList = (
  strategyId: string,
  query?: {
    end_date_lte?: string;
    limit?: number;
    offset?: number;
    ordering?: '-end_date' | '-start_date' | 'end_date' | 'start_date';
    process_id?: string;
    start_date_gte?: string;
    status?: string;
    label_text?: string;
  },
) => api.strategiesCampaignsList(strategyId, query).then((_) => _.data);

//campaigns

export const createCampaigns = (
  data: CampaignCreateRequest,
): Promise<CheckCampaignsResult> =>
  api
    .campaignsCreate(data)
    .then((response) => {
      return {
        status: 'success',
        campaign: response.data,
      } as CheckCampaignsResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getCampaignsList = (id: string) =>
  api.strategiesCampaignsList(id).then((_) => _.data);

export const getCampaignById = (id: string) =>
  api.campaignsRetrieve(id).then((_) => _.data);

export const startCampaign = () => Promise.resolve();

export const updateCampaignStatus = (
  id: string,
  data: CampaignUpdateStatusRequest,
): Promise<CheckCampaignStatusResult> =>
  api
    .campaignsCampaignUpdatesCreate(id, data)
    .then(() => {
      return { status: 'success' } as CheckCampaignStatusResult;
    })
    .catch((response) => {
      console.log(response);
      return { status: 'fail', error: response.error };
    });

//records
export const getCampaignRecordsList = (
  id: string,
  query?: {
    imported_from?: string;
    limit?: number;
    offset?: number;
    ordering?: '-created_at' | 'created_at';
    phone_number?: string;
    record_id?: string;
    status_list?: ('OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED')[];
  },
) => api.campaignsRecordsList(id, query).then((_) => _.data);

export const campaignsPauseCc = (id: string, data: CampaignPauseCcRequest) =>
  api.campaignsPauseCcCreate(id, data).then((_) => _.data);

export const campaignPauseInbound = (
  id: string,
  data: CampaignPauseInboundRequest,
) => api.campaignsPauseInboundCreate(id, data).then((_) => _.data);

export const pauseRecords = (campaignId: string, query: { id: string[] }) =>
  api.campaignsRecordsStopUpdate(campaignId, query).then((_) => _.data);

export interface CheckImportRecordsResult {
  status: 'success' | 'fail';
  error?: ApiError<RecordImportFileResponse>;
  result?: RecordImportFileResponse;
}

export const importRecords = (
  campaignId: string,
  data: RecordImportFileRequest,
): Promise<CheckImportRecordsResult> =>
  api
    .campaignsRecordsImportCreate(campaignId, data)
    .then((response) => {
      return {
        status: 'success',
        result: response.data,
      } as CheckImportRecordsResult;
    })
    .catch((response) => {
      return {
        status: 'fail',
        error: response.error,
      };
    });

export const getCampaignCallsList = (
  id: string,
  query?: {
    call_type?: 'INBOUND' | 'OUTBOUND';
    date_from?: string;
    date_to?: string;
    limit?: number;
    offset?: number;
    ordering?: '-created_at' | 'created_at';
    record_id?: string;
    record_phone_number?: string;
    telco_status_list?: (
      | 'ANSWERED'
      | 'NOT_ANSWERED'
      | 'REJECTED'
      | 'BUSY'
      | 'VOICEMAIL'
      | 'TRANSFER'
      | 'NONEXISTENT_NUMBER'
      | 'UNMAPPED'
      | 'ERROR'
    )[];
  },
) => api.campaignsCallsList(id, query).then((_) => _.data);

export const callDetailedView = (id: string) =>
  api.callsRetrieve(id).then((_) => _.data);

export const getCallRecording = (
  callId: string,
  query?: { audio_format?: 'flac' | 'mp3' | 'wav' },
) =>
  api.callsRecordingRetrieve(callId, query, { format: 'blob' }).then((res) => {
    const contentDisposition = res.headers.get('Content-Disposition');
    const filenameMatch = contentDisposition
      ? contentDisposition.match(/filename="([^"]+)"/)
      : null;
    const filename = filenameMatch
      ? filenameMatch[1]
      : 'default_filename.extension';
    const blob = res.data;
    const recordingUrl = URL.createObjectURL(blob);
    return { recordingUrl, filename };
  });

export const getCallTranscript = (callId: string) =>
  api.callsTranscriptRetrieve(callId, { format: 'blob' }).then((res) => {
    const contentDisposition = res.headers.get('Content-Disposition');
    const filenameMatch = contentDisposition
      ? contentDisposition.match(/filename="([^"]+)"/)
      : null;
    const filename = filenameMatch
      ? filenameMatch[1]
      : 'default_filename.extension';
    const blob = res.data;
    const transcriptUrl = URL.createObjectURL(blob);
    return { transcriptUrl, filename };
  });

export const getCampaignsRecordings = (
  campaignId: string,
  query?: { call_ids?: string[] },
) =>
  api
    .campaignsRecordingsRetrieve(campaignId, query, { format: 'blob' })
    .then((res) => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition
        ? contentDisposition.match(/filename="([^"]+)"/)
        : null;
      const filename = filenameMatch
        ? filenameMatch[1]
        : 'default_filename.extension';
      const blob = res.data;
      const campaignsRecordingsUrl = URL.createObjectURL(blob);
      return { campaignsRecordingsUrl, filename };
    });

export const getCampaignsTranscripts = (
  campaignId: string,
  query?: { call_ids?: string[] },
) =>
  api
    .campaignsTranscriptsRetrieve(campaignId, query, { format: 'blob' })
    .then((res) => {
      const contentDisposition = res.headers.get('Content-Disposition');
      const filenameMatch = contentDisposition
        ? contentDisposition.match(/filename="([^"]+)"/)
        : null;
      const filename = filenameMatch
        ? filenameMatch[1]
        : 'default_filename.extension';
      const blob = res.data;
      const campaignsTranscriptUrl = URL.createObjectURL(blob);
      return { campaignsTranscriptUrl, filename };
    });

//history

export const getCampaignHistoryList = (campaignId: string) =>
  api.campaignsHistoryList(campaignId).then((_) => _.data);

export interface CheckCampaignUpdateResult {
  status: 'success' | 'fail';
  error?: CampaignUpdateResponse;
}

export interface CampaignUpdateResponse {
  guaranteed_lines?: string;
  use_idle_lines?: string;
  call_attempts?: string;
  call_attempts_per_day?: string;
  rest_days_after_call_attempts_per_day_reached?: string;
  time_between_attempts_mode?: string;
  time_between_attempts_seconds?: string;
  dynamic_time_between_attempts?: string;
  randomized_time_between_attempts?: string;
}

//optimalization
export const updateCampaign = (
  campaignId: string,
  data: CampaignChangeRequest,
): Promise<CheckCampaignUpdateResult> =>
  api
    .campaignsUpdateCreate(campaignId, data)
    .then((response) => {
      return {
        status: 'success',
        campaign: response.data,
      } as CheckCampaignUpdateResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getCampaignStatus = (campaignId: string) =>
  api.campaignsStatusRetrieve(campaignId).then((_) => _.data);

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React from 'react';
import { H3, H6 } from '../../../App.style';
import {
  ProcessesColumnContainer,
  DroppableContainer,
  DraggableContainer,
  ProcessesColumnHeader,
  LeftColumnHeaderContainer,
  RightColumnHeaderContainer,
  NoProcessesContainer,
  ColumnLenghtBox,
} from './ProcessesPanel.style';
import { ColumnData } from './ProcessesPanel';
import ProcessPreview from './ProcessPreview';

import { TrashIcon } from '../../../../resources/icons-new/TrashIcon';
import { EditIcon } from '../../../../resources/icons-new/EditIcon';
import { MoveIcon } from '../../../../resources/icons-new/MoveIcon';
import { useTranslation } from 'react-i18next';

interface ProcessesColumnProps {
  column: ColumnData;
  edit: boolean;
  deleteColumn: (column: ColumnData) => void;
  editColumn: (column: ColumnData) => void;

  searchValue: string;
  handleSeeCampaignsClick: (id: string) => void;
  handleDuplicateProcess: (id: string) => void;
  hasProcessAccess: boolean;
  showCampaignCreateModal: (id: string) => void;
}

const ProcessesColumn: React.FC<ProcessesColumnProps> = ({
  column,
  edit,
  deleteColumn,
  editColumn,
  searchValue,
  handleDuplicateProcess,
  hasProcessAccess,
  showCampaignCreateModal,
}) => {
  const { t } = useTranslation();
  const { name, id, items } = column;

  const noArchivedItems = items.filter((item) => item.is_archived == false);

  return (
    <ProcessesColumnContainer $edit={edit}>
      <ProcessesColumnHeader>
        {column.id !== 'uncategorized' ? (
          <>
            <LeftColumnHeaderContainer>
              {edit && <MoveIcon $pointer />}
              <H3>{name} </H3>
              <ColumnLenghtBox>
                <H6>{noArchivedItems.length}</H6>
              </ColumnLenghtBox>
            </LeftColumnHeaderContainer>
            {edit && (
              <RightColumnHeaderContainer>
                <TrashIcon onClick={() => deleteColumn(column)} $pointer />

                <EditIcon onClick={() => editColumn(column)} $pointer />
              </RightColumnHeaderContainer>
            )}
          </>
        ) : (
          <LeftColumnHeaderContainer>
            <H3>{t('processes.uncategorized')}</H3>
            <ColumnLenghtBox>
              <H6>{noArchivedItems.length}</H6>
            </ColumnLenghtBox>
          </LeftColumnHeaderContainer>
        )}
      </ProcessesColumnHeader>

      <DroppableContainer
        droppableId={`${id}`}
        grow={false}
        spacing="s"
        style={{
          background: edit ? '#dee5ee' : '',
        }}
      >
        {noArchivedItems.length > 0 ? (
          noArchivedItems.filter((process) =>
            searchValue === ''
              ? true
              : process.name.toLowerCase().includes(searchValue.toLowerCase()),
          ).length > 0 ? (
            noArchivedItems
              .filter((process) =>
                searchValue === ''
                  ? true
                  : process.name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()),
              )
              .map((process, idx) => {
                //campaigns that use this process
                return (
                  <DraggableContainer
                    spacing="m"
                    key={process.id}
                    index={idx}
                    draggableId={process.id}
                    isDragDisabled={!edit}
                  >
                    <ProcessPreview
                      edit={edit}
                      process={process}
                      handleDuplicateProcess={handleDuplicateProcess}
                      hasProcessAccess={hasProcessAccess}
                      showCampaignCreateModal={showCampaignCreateModal}
                    />
                  </DraggableContainer>
                );
              })
          ) : (
            <NoProcessesContainer>
              <H3>{t('processes.noProcesses')}</H3>
            </NoProcessesContainer>
          )
        ) : (
          <EuiFlexGroup
            alignItems="center"
            justifyContent="spaceAround"
            gutterSize="none"
            style={{ height: '100%' }}
          >
            <EuiFlexItem grow={false}></EuiFlexItem>
          </EuiFlexGroup>
        )}
      </DroppableContainer>
    </ProcessesColumnContainer>
  );
};

export default ProcessesColumn;

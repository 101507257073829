import React, { useState } from 'react';
import {
  BuilderNavbarContainer,
  BuilderNavbarLeftContainer,
  BuilderNavbarRightContainer,
  BuilderPanelContainer,
  VindicationBuilderContentContainer,
} from './Vindication.style';
import VindicationBuilderSidebar from './VindicationBuilderSidebar';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { BotConfigurationRequest } from '../../../../generated/tenants/Api';
import DialogueTokens from './components/customTokens/DialogueTokens';
import { useAppSelector } from '../../../../common/hooks';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { Button, H1 } from '../../../App.style';
import { ArrowLeftIcon } from '../../../../resources/icons-new/ArrowLeftIcon';
import { EditIcon } from '../../../../resources/icons-new/EditIcon';
import { Label } from '../../presets/labels/Labels.style';
import DialogueGlobalParameters from './components/globalParameters/DialogueGlobalParameters';
import DialogueAdditionalSettings from './components/additionalSettings/DialogueAdditionalSettings';
import DialogueFinalSettings from './components/finalSettings/DialogueFinalSettings';
import DialogueIdentification from './components/identification/DialogueIdentification';
import DialogueVerification from './components/verification/DialogueVerification';
import DialoguePaymentStatus from './components/paymentStatus/DialoguePaymentStatus';

const VindicationBuilder = () => {
  const strategy = useAppSelector(selectStrategyId);
  const navigate = useNavigate();
  const [vindicationDialogue, setVindicationDialogue] =
    useState<BotConfigurationRequest>({
      strategy: strategy,
      name: 'Elo',
      template: 'DEBTOR',
      custom_label: '',
      language: 'pl-PL',
      runtime_parameters: {
        CompanyName: 'nazwa firmy',
        CompanyNameGen: 'nazwy firmy',
        CompanyNameDat: 'nazwie firmy',
        CompanyNameAcc: 'nazwę firmy',
        CompanyNameInst: 'nazwą firmy',
        CompanyNameLoc: 'nazwie firmy',
        SystemName: 'Bocik testowy',
        CompanyPhoneNumber: '999999999',
      },
      strategy_config: {
        //weryfikacja
        FIRST_TURN_ATTEMPTS: 1,
        FIRST_TURN_RANDOM: true,
        FIRST_TURN_QUESTIONS: ['birth_date', 'last_pesel_digits', 'id'],
        SECOND_TURN: false,

        SECOND_TURN_QUESTIONS: [],
        SECOND_TURN_ATTEMPTS: 1,
        SECOND_TURN_RANDOM: false,
        BRANCHES: {
          IDENTIFICATION: 'GOODBYE',
          VERIFICATION: 'GOODBYE',
        },
        is_dead: 'GOODBYE',
        wrong_number: 'REDIRECT_TO_HUMAN',
        ASK_FOR_MISSING_ZIP_CODE: false,

        //additional settings
        DEFAULT_SPEECH_RECOGNITION_TIMEOUT: 2000,
        DEFAULT_SPEECH_COMPLETE_TIMEOUT: 3000,
        DEFAULT_NO_INPUT_TIMEOUT: 5000,
      },
      generations_config: {
        //Identyfikacja
        'SOC-INI-identification_intro-1-1': 'Dzień dobry,',
        'INF-STA-identification_intro-1-1':
          'Tu Wirtualny Agent %_BOTNAME Reprezentuję %_COMPANYNAME_NOM. Nasza rozmowa jest nagrywana.',
        'REQ-INF-may_i_speak_with_client_now-1-1':
          'Czy mogę rozmawia z %_OFFADDRESS_DAT ?',
        'REQ-ACT-identification_failed-1-1':
          'Rozumiem, w takm wypadku proszę o ...',
        'REQ-INF-can_you_hand_over-1-1':
          'Rozumiem. Czy mogłabym prosić o przekazanie słuchawki do %_OFFADDRESS_GEN ?',
        'INF-STA-is_dead-1-1':
          'Rozumiem, bardzo mi przykro. Uprzejmie informuję, że fakt ten należy udokumentować w najbliższym oddziale banku BNP Paribas. Szczegółowe informacje w tym zakresie można uzyskać pod numerem telefonu',
        'SOC-FIN-is_dead-1-1': 'Pozegnanie jak ktos umrzył',
        'INF-STA-wrong_number-1-1': 'Informacja na zły numer telefonu',
        'SOC-FIN-wrong_number-1-1': 'Pozegnanie na zły numer telefonu',

        //weryfikacja
        'INF-STA-verification_intro_1_1':
          'Informacje, które mam do przekazania, objęte s tajemnicą bankową. Aby kontynuowa rozmowę, muszę zweryfikować Pana/Pani <- token tożsamość.',
        'FEE-SUM-verification_passed-1-1': 'Dziękuję, wszystko sie zgadza',
        'INF-STA-verification_failed-1-1':
          'Dziękuję. Niestety, weryfikacja nie przeszła pomyślnie i w tej sytuacji nie mogę kontynuować rozmowy',
        'INF-STA-verification_failed_empty_item-1-1':
          'Niestety nie odpowiedziales? XD',
        'REQ-INF-birth_date-1-1': 'Proszę o podanie Pana daty urodzenia.',
        'REQ-INF-last_pesel_digits-1-1':
          'Proszę o podanie trzech ostatnich cyfr numeru pesel',
        'REQ-INF-id-1-1':
          'Proszę o podanie numeru %_ADDRESS_GEN dowodu osobistego.',
        'REQ-INF-mothers_name-1-1':
          'Proszę o podanie imienia %_ADDRESS_GEN matki',
        'REQ-INF-fathers_name-1-1':
          'Proszę o podanie imienia %_ADDRESS_GEN ojca',
        'REQ-INF-zip_code-1-1':
          'Proszę o podanie kodu pocztowego %_ADDRESS_GEN adresu korespondencyjnego',
        'REQ-INF-product_possession-1-1':
          'Czy posiada %_ADDRESS_NOM %_PRODUCTNAME_ACC w %_COMPANYNAME_LOC?',
        'REQ-INF-address-1-1':
          'Proszę o podanie %_ADDRESS_GEN pełnego adresu zamieszkania',

        //status platnosci
        'INF-STA-debt_intro-1-1': 'Intro platnosci',
        'REQ-INF-is_payment_done-1-1': 'Dokonales platnosc?',
      },
    });

  // useEffect(() => {
  //   console.log(vindicationDialogue.strategy_config);
  // }, [vindicationDialogue]);

  const updateRuntimeParameters = (key: string, newValue: string) => {
    // Create a new object for runtime_parameters with the updated key-value pair
    const updatedRuntimeParameters = {
      ...vindicationDialogue.runtime_parameters,
      [key]: newValue,
    };

    // Update the botConfiguration state with the new runtime_parameters object
    setVindicationDialogue((prevConfig) => ({
      ...prevConfig,
      runtime_parameters: updatedRuntimeParameters,
    }));
  };

  const updateStrategyConfig = (key: string, newValue: string) => {
    const updatedStrategyConfig = {
      ...vindicationDialogue.strategy_config,
      [key]: newValue,
    };

    setVindicationDialogue((prevConfig) => ({
      ...prevConfig,
      strategy_config: updatedStrategyConfig,
    }));
  };

  return (
    <BuilderPanelContainer>
      <BuilderNavbarContainer>
        <BuilderNavbarLeftContainer>
          <Button
            $styleType="NORMAL"
            $size={'S'}
            onClick={() => navigate('/dashboard')}
          >
            <ArrowLeftIcon />
          </Button>
          <H1 $bold>Build dialog: Windykacja Kaczmarski</H1>
          <EditIcon />
          <Label color="#E5D1D0">dialog debtorowy</Label>
        </BuilderNavbarLeftContainer>
        <BuilderNavbarRightContainer>
          <Button $size={'S'} $styleType="NORMAL">
            Save changes
          </Button>
          <Button $size={'S'}>Publish dialogue</Button>
        </BuilderNavbarRightContainer>
      </BuilderNavbarContainer>
      <div style={{ display: 'flex' }}>
        <VindicationBuilderSidebar />

        <VindicationBuilderContentContainer>
          <Routes>
            <Route
              path="/"
              element={<Navigate replace to="/builder/vindication" />}
            />
            <Route
              path="/global"
              element={
                <DialogueGlobalParameters
                  vindicationDialogue={vindicationDialogue}
                  updateRuntimeParameters={updateRuntimeParameters}
                />
              }
            />
            <Route
              path="/identification"
              element={
                <DialogueIdentification
                  vindicationDialogue={vindicationDialogue}
                  setVindicationDialogue={setVindicationDialogue}
                />
              }
            />
            <Route
              path="/verification"
              element={
                <DialogueVerification
                  vindicationDialogue={vindicationDialogue}
                  setVindicationDialogue={setVindicationDialogue}
                />
              }
            />
            <Route
              path="/payment_status"
              element={
                <DialoguePaymentStatus
                  vindicationDialogue={vindicationDialogue}
                  setVindicationDialogue={setVindicationDialogue}
                />
              }
            />
            <Route path="/negotiations" element={<div>Negotiations</div>} />
            <Route
              path="/auxilary_modules"
              element={<div>Auxilary modules</div>}
            />
            <Route path="/qa" element={<div>Q & A</div>} />
            <Route
              path="/tokens"
              element={
                <DialogueTokens
                  vindicationDialogue={vindicationDialogue}
                  setVindicationDialogue={setVindicationDialogue}
                />
              }
            />
            <Route
              path="/final"
              element={
                <DialogueFinalSettings
                  vindicationDialogue={vindicationDialogue}
                />
              }
            />
            <Route
              path="/additional"
              element={
                <DialogueAdditionalSettings
                  vindicationDialogue={vindicationDialogue}
                  updateStrategyConfig={updateStrategyConfig}
                />
              }
            />
          </Routes>
        </VindicationBuilderContentContainer>
      </div>
    </BuilderPanelContainer>
  );
};

export default VindicationBuilder;

import React, { useEffect, useRef, useState } from 'react';
import {
  DashboardFiltersContainer,
  DashboardFiltersSelect,
  DashboardFiltersSelectContainer,
  DashboardPanelContainer,
  DashboardPanelContentContainer,
} from './DashboardPanel.style';
import { H1, H2, H4 } from '../../App.style';
import DashboardCampaignPreview from './DashboardCampaignPreview';
import { ArrowDownIcon } from '../../../resources/icons-new/ArrowDownIcon';
import {
  CampaignDashboardData,
  StrategyDashboardData,
} from '../../../generated/tenants/Api';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { selectStrategyId } from '../settings/adminPanel/components/strategies/api/strategiesSlice';
import { getDashboardDataAsyncThunk } from '../analytics/api/analyticsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { EuiSkeletonRectangle, EuiSkeletonTitle } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

const DashboardPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const [chartData, setChartData] = useState<StrategyDashboardData>();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isMountedRef = useRef<boolean>(false);

  const filterOptions = [
    { value: 'oldest', label: t('dashboard.filters.oldest') },
    { value: 'newest', label: t('dashboard.filters.newest') },
    { value: 'alpha_asc', label: t('dashboard.filters.alpha_asc') },
    { value: 'alpha_desc', label: t('dashboard.filters.alpha_desc') },
  ];

  const [loading, setLoading] = useState<boolean>(true);

  const fetchDashboardData = () => {
    const refreshSeconds = 30000; //30 seconds
    dispatch(getDashboardDataAsyncThunk(strategy))
      .then(unwrapResult)
      .then((res) => {
        setChartData(res);

        timerRef.current = setTimeout(fetchDashboardData, refreshSeconds);
      })
      .finally(() => {
        if (loading) setLoading(false);
      });
  };

  useEffect(() => {
    isMountedRef.current = true;
    fetchDashboardData();

    return () => {
      // Clean up the timeout and mark the component as unmounted
      isMountedRef.current = false;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [strategy]);

  const [filter, setFilter] = useState<string>(filterOptions[0].value);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };

  const handleClick = () => {
    selectRef.current?.click();
  };

  const sortCampaigns = (
    campaigns: CampaignDashboardData[],
    filterValue: string,
  ): CampaignDashboardData[] => {
    switch (filterValue) {
      case 'newest':
        return [...campaigns].sort(
          (a, b) =>
            new Date(b.start_date).getTime() - new Date(a.start_date).getTime(),
        );
      case 'oldest':
        return [...campaigns].sort(
          (a, b) =>
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
        );
      case 'alpha_asc':
        return [...campaigns].sort((a, b) =>
          a.process_name.localeCompare(b.process_name),
        );
      case 'alpha_desc':
        return [...campaigns].sort((a, b) =>
          b.process_name.localeCompare(a.process_name),
        );
      default:
        return campaigns;
    }
  };
  return (
    <DashboardPanelContainer>
      <H1 $medium>{t('dashboard.title')}</H1>
      <H4 $grey>{t('dashboard.description')}</H4>

      <DashboardFiltersContainer>
        <H4 $grey $medium>
          {t('dashboard.sort')}
        </H4>
        <ArrowDownIcon />

        <DashboardFiltersSelectContainer onClick={handleClick}>
          <H4>
            {filterOptions.find((option) => option.value === filter)?.label ||
              ''}
          </H4>
          <DashboardFiltersSelect
            ref={selectRef}
            value={filter}
            onChange={handleChange}
          >
            {filterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </DashboardFiltersSelect>
        </DashboardFiltersSelectContainer>
      </DashboardFiltersContainer>

      {/* kampanie */}
      <DashboardPanelContentContainer>
        {loading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            {Array.from({ length: 8 }).map(() => (
              <>
                <EuiSkeletonTitle
                  size="xs"
                  isLoading={true}
                  contentAriaLabel="Demo skeleton title"
                />
                <EuiSkeletonRectangle
                  width="100%"
                  height={200}
                  style={{ marginBottom: 16 }}
                ></EuiSkeletonRectangle>
              </>
            ))}
          </div>
        ) : (
          <>
            {chartData && chartData.campaigns.length === 0 ? (
              <div>
                <H2>{t('dashboard.noCampaignsToShow')}</H2>
              </div>
            ) : (
              sortCampaigns(chartData?.campaigns || [], filter).map(
                (campaign) => {
                  return (
                    <DashboardCampaignPreview
                      key={campaign.id}
                      data={campaign}
                    />
                  );
                },
              )
            )}
          </>
        )}
      </DashboardPanelContentContainer>
    </DashboardPanelContainer>
  );
};

export default DashboardPanel;

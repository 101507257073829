import styled from 'styled-components';

export const DialoguesContainer = styled.div`
  width: 100%;

  overflow: auto;

  position: relative;
`;

export const JSONFieldLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  width: 400px;
`;

interface JSONCodeBlockContainer {
  error?: boolean;
}

export const JSONCodeBlockContainer = styled.div<JSONCodeBlockContainer>`
  max-height: 300px;
  overflow: auto;

  border: ${(props) =>
    props.error ? '1px solid #BA2525' : '1px solid #E4E7EB'};
  border-radius: 5px;

  position: relative;
`;

export const JSONFieldContainer = styled.div`
  display: flex;
  position: relative;
`;

export const JSONFieldButtonContainer = styled.div`
  position: absolute;
  right: -110px;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  gap: 15px;
`;

export const DialoguesListContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-top: 16px;

  display: flex;
  justify-content: flex-start;

  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

import React, { useEffect, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import { H4 } from '../../../../../../App.style';
import {
  ArrowsContainer,
  PaginationContainer,
  PaginationText,
  TableContainer,
} from '../../../../../campaigns/panel/CampaignsPanel.style';
import {
  UsersListTableContainer,
  UsersListTab,
  UsersListTableHeader,
} from '../../../../../users/Users.style';
import {
  getSuperUsersListAsyncThunk,
  selectSuperUsersList,
} from '../../../api/superAdminSlice';
import SuperUserTableRow from './SuperUserTableRow';
import { ArrowIconLeft } from '../../../../../../../resources/icons-new/ArrowIconLeft';
import { ArrowIconRight } from '../../../../../../../resources/icons-new/ArrowIconRight';
import { RecordsTableHeader } from '../../../../../campaigns/tabs/records/Records.style';
import { useTranslation } from 'react-i18next';

interface SuperUsersTableProps {
  search: string;
  showSuccessPermissionsPopup: () => void;
  showErrorPermissionsPopup: () => void;
}

const SuperUsersTable: React.FC<SuperUsersTableProps> = ({
  search,
  showErrorPermissionsPopup,
  showSuccessPermissionsPopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [editUserIndex, setEditUserIndex] = useState<number>();

  //pagination
  //table settings
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0);

  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageChange = (offset: number, limit: number) => {
    setOffset(offset);
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const offset = (page - 1) * itemsPerPage;
    onPageChange(offset, itemsPerPage);
  };

  const [reload, setReload] = useState<number>(0);

  const handleReloadSuperUsersList = () => setReload((prev) => prev + 1);

  useEffect(() => {
    const query: {
      filter_info?: string;
      limit?: number;
      offset?: number;
    } = {
      filter_info: search,
      limit: itemsPerPage,
      offset: offset,
    };
    dispatch(getSuperUsersListAsyncThunk(query));
  }, [search, offset, limit, reload]);

  const apiSuperUsersList = useAppSelector(selectSuperUsersList);

  const totalItems = apiSuperUsersList?.count as number;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginationTextContent = (
    currentPage: number,
    itemsPerPage: number,
    totalItems: number,
  ) => {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return <>{`${startItem} - ${endItem} of ${totalItems}`}</>;
  };

  return (
    <UsersListTableContainer style={{ height: '100%', paddingBottom: 50 }}>
      <TableContainer>
        {apiSuperUsersList?.results && (
          <UsersListTab>
            <thead>
              <tr>
                <UsersListTableHeader style={{ width: 250 }}>
                  <H4 $grey>
                    {t('superAdminPanel.superUsers.table.username')}
                  </H4>
                </UsersListTableHeader>

                <UsersListTableHeader style={{ width: 150 }}>
                  <H4 $grey>{t('superAdminPanel.superUsers.table.role')}</H4>
                </UsersListTableHeader>

                <UsersListTableHeader style={{ width: 150 }}>
                  <H4 $grey>
                    {t('superAdminPanel.superUsers.table.permissions')}
                  </H4>
                </UsersListTableHeader>

                <UsersListTableHeader>
                  <H4 $grey>
                    {t('superAdminPanel.superUsers.table.lastLogin')}
                  </H4>
                </UsersListTableHeader>

                <RecordsTableHeader>
                  <PaginationContainer>
                    <PaginationText>
                      {getPaginationTextContent(
                        currentPage,
                        itemsPerPage,
                        totalItems,
                      )}
                    </PaginationText>

                    <ArrowsContainer>
                      <button
                        disabled={currentPage === 1}
                        onClick={() =>
                          handlePageChange(Math.max(1, currentPage - 1))
                        }
                      >
                        <ArrowIconLeft disabled={currentPage === 1} />
                      </button>

                      <button
                        disabled={currentPage === totalPages}
                        onClick={() =>
                          handlePageChange(
                            Math.min(totalPages, currentPage + 1),
                          )
                        }
                      >
                        <ArrowIconRight disabled={currentPage === totalPages} />
                      </button>
                    </ArrowsContainer>
                  </PaginationContainer>
                </RecordsTableHeader>
              </tr>
            </thead>
            <tbody>
              {apiSuperUsersList.results.length === 0 ? (
                <tr
                  style={{
                    padding: 20,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <td>{t('superAdminPanel.superUsers.table.noUsers')} </td>
                </tr>
              ) : (
                apiSuperUsersList.results.map((superUser, index) => {
                  return (
                    <SuperUserTableRow
                      key={`super-user-row-${index}`}
                      superUser={superUser}
                      index={index}
                      edit={editUserIndex === index}
                      setEditUserIndex={setEditUserIndex}
                      showSuccessPermissionsPopup={showSuccessPermissionsPopup}
                      showErrorPermissionsPopup={showErrorPermissionsPopup}
                      handleReloadSuperUsersList={handleReloadSuperUsersList}
                    />
                  );
                })
              )}
            </tbody>
          </UsersListTab>
        )}
      </TableContainer>
    </UsersListTableContainer>
  );
};

export default SuperUsersTable;

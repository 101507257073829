import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const VoicePresetIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2188 0.875C10.6157 0.875 10.9375 1.1968 10.9375 1.59375V17.4062C10.9375 17.8032 10.6157 18.125 10.2188 18.125C9.8218 18.125 9.5 17.8032 9.5 17.4062V1.59375C9.5 1.1968 9.8218 0.875 10.2188 0.875ZM7.34375 3.75C7.74071 3.75 8.0625 4.0718 8.0625 4.46875V14.5312C8.0625 14.9282 7.74071 15.25 7.34375 15.25C6.9468 15.25 6.625 14.9282 6.625 14.5312V4.46875C6.625 4.0718 6.9468 3.75 7.34375 3.75ZM13.0938 3.75C13.4907 3.75 13.8125 4.0718 13.8125 4.46875V14.5312C13.8125 14.9282 13.4907 15.25 13.0938 15.25C12.6968 15.25 12.375 14.9282 12.375 14.5312V4.46875C12.375 4.0718 12.6968 3.75 13.0938 3.75ZM4.46875 5.90625C4.8657 5.90625 5.1875 6.22805 5.1875 6.625V12.375C5.1875 12.772 4.8657 13.0938 4.46875 13.0938C4.0718 13.0938 3.75 12.772 3.75 12.375V6.625C3.75 6.22805 4.0718 5.90625 4.46875 5.90625ZM15.9688 5.90625C16.3657 5.90625 16.6875 6.22805 16.6875 6.625V12.375C16.6875 12.772 16.3657 13.0938 15.9688 13.0938C15.5718 13.0938 15.25 12.772 15.25 12.375V6.625C15.25 6.22805 15.5718 5.90625 15.9688 5.90625ZM1.59375 7.34375C1.9907 7.34375 2.3125 7.66554 2.3125 8.0625V10.9375C2.3125 11.3345 1.9907 11.6562 1.59375 11.6562C1.1968 11.6562 0.875 11.3345 0.875 10.9375V8.0625C0.875 7.66554 1.1968 7.34375 1.59375 7.34375ZM18.8438 7.34375C19.2407 7.34375 19.5625 7.66554 19.5625 8.0625V10.9375C19.5625 11.3345 19.2407 11.6562 18.8438 11.6562C18.4468 11.6562 18.125 11.3345 18.125 10.9375V8.0625C18.125 7.66554 18.4468 7.34375 18.8438 7.34375Z"
    />
  </StyledSvg>
);

import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { CalendarInterface, WorkingHoursInterval } from '../calendarFunctions';

interface TimeSelectFields {
  highlightError: boolean;
  item: WorkingHoursInterval;
  dayIndex: number;
  workingHourIndex: number;
  calendar: CalendarInterface[];
  setCalendar: React.Dispatch<React.SetStateAction<CalendarInterface[]>>;
}

const TimeSelect: React.FC<TimeSelectFields> = ({
  highlightError,
  dayIndex,
  workingHourIndex,
  calendar,
  setCalendar,
}) => {
  const momentToInverval = (date: Moment): number => {
    const mmtMidnight = date.clone().startOf('day');
    const diffMinutes = date.diff(mmtMidnight, 'second');
    return diffMinutes;
  };

  const convertIntervalToMoment = (interval: number) => {
    const formatted = moment.utc(interval * 1000);
    return formatted;
  };

  useEffect(() => {
    setStartDate(
      convertIntervalToMoment(
        calendar[dayIndex].workingHours[workingHourIndex].from,
      ),
    );
    setEndDate(
      convertIntervalToMoment(
        calendar[dayIndex].workingHours[workingHourIndex].to,
      ),
    );
  }, [calendar]);

  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();

  const handleOnBlur = (type: 'start' | 'end') => {
    if (startDate && endDate) {
      const arr = [...calendar];
      if (type === 'start') {
        const start = momentToInverval(startDate);
        arr[dayIndex].workingHours[workingHourIndex].from = start;
        setCalendar(arr);
      } else if (type === 'end') {
        const end = momentToInverval(endDate);
        arr[dayIndex].workingHours[workingHourIndex].to = end;
        setCalendar(arr);
      }
    }
  };

  return (
    <>
      {startDate && endDate && (
        <div style={{ marginTop: 5 }}>
          <EuiDatePickerRange
            isInvalid={highlightError}
            startDateControl={
              <EuiDatePicker
                selected={startDate}
                onChange={(date) => {
                  date && setStartDate(date);
                }}
                startDate={startDate}
                endDate={endDate}
                onBlur={() => handleOnBlur('start')}
                aria-label="Start date"
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                css={{
                  textAlign: 'center',
                  width: 90,
                  fontFamily: 'IBM Plex Sans',
                  fontSize: 14,
                }}
              />
            }
            endDateControl={
              <EuiDatePicker
                selected={endDate}
                onChange={(date) => {
                  date && setEndDate(date);
                }}
                startDate={startDate}
                endDate={endDate}
                onBlur={() => handleOnBlur('end')}
                aria-label="End date"
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                css={{
                  textAlign: 'center',
                  width: 70,
                  fontFamily: 'IBM Plex Sans',
                  fontSize: 14,
                }}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default TimeSelect;

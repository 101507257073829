import { configureStore, unwrapResult } from '@reduxjs/toolkit';
import sessionReducer, {
  getConfigAsyncThunk,
  getLoginStateAsyncThunk,
} from './features/session/api/sessionSlice';
import usersReducer from './features/users/api/userSlice';
import { sessionMiddleware } from './features/session/api/sessionMiddleware';
import callingConfigurationReducer from './features/presets/callingPreferences/api/callingConfigurationSlice';
import voiceConfigurationReducer from './features/presets/voices/api/voiceConfigurationSlice';
import sftpConfigReducer from './features/settings/adminPanel/components/sftp/api/sftpConfigSlice';
import campaignsReducer from './features/campaigns/api/campaignsSlice';
import processesReducer from './features/processes/api/processSlice';
import labelsReducer from './features/presets/labels/api/labelsSlice';
import strategiesReducer, {
  getStrategiesListAsyncThunk,
  setStrategyId,
} from './features/settings/adminPanel/components/strategies/api/strategiesSlice';
import calendarsReducer from './features/presets/calendars/api/calendarSlice';
import callCenterDaysOffReducer from './features/presets/days_off/callCentre/api/callCentreDaysOffSlice';
import botDaysOffReducer from './features/presets/days_off/bot/api/botDaysOffSlice';
import phoneNumbersReducer from './features/settings/adminPanel/components/phoneNumbers/api/phoneNumbersSlice';
import reportsReducer from './features/reports/api/reportsSlice';
import userSettingsReducer from './features/settings/userSettings/api/userSettingsSlice';
import dialoguesReducer from './features/presets/dialogues/api/dialoguesSlice';
import superAdminReducer from './features/settings/superAdminPanel/api/superAdminSlice';
import analyticsReducer from './features/analytics/api/analyticsSlice';
import { invitationMiddleware } from './features/users/api/userMiddleware';
import { superInvitationMiddleware } from './features/settings/superAdminPanel/api/superAdminMiddleware';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    users: usersReducer,
    userSettings: userSettingsReducer,
    voice_configuration: voiceConfigurationReducer,
    sftpConfigurations: sftpConfigReducer,
    callingConfigurations: callingConfigurationReducer,
    campaigns: campaignsReducer,
    processes: processesReducer,
    labels: labelsReducer,
    strategies: strategiesReducer,
    calendar: calendarsReducer,
    callCenterDaysOff: callCenterDaysOffReducer,
    botDaysOff: botDaysOffReducer,
    phoneNumbers: phoneNumbersReducer,
    reports: reportsReducer,
    dialogues: dialoguesReducer,
    superAdmin: superAdminReducer,
    analytics: analyticsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(sessionMiddleware.middleware)
      .prepend(invitationMiddleware.middleware)
      .prepend(superInvitationMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

store
  .dispatch(getConfigAsyncThunk())
  .then(unwrapResult)
  .then(() => {
    store.dispatch(getLoginStateAsyncThunk());
  });

const strategy = localStorage.getItem('strategy');
if (strategy !== undefined && strategy !== null) {
  store
    .dispatch(getStrategiesListAsyncThunk())
    .then(unwrapResult)
    .then((strategies) => {
      const strategyIsValid = strategies.some((s) => s.id === strategy);
      const firstStrategy = strategies[0];

      if (strategies.length === 0) {
        store.dispatch(setStrategyId(''));
        return;
      }

      if (strategyIsValid) {
        store.dispatch(setStrategyId(strategy));
      } else {
        store.dispatch(setStrategyId(firstStrategy.id));
      }
    });
}

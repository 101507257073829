import { IconProps, StyledSvg } from './Icon';

const InviteUserIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12.5714 10.2857V11.4286H13.7143C14.0299 11.4286 14.2857 11.6844 14.2857 12C14.2857 12.3156 14.0299 12.5714 13.7143 12.5714H12.5714V13.7143C12.5714 14.0299 12.3156 14.2857 12 14.2857C11.6844 14.2857 11.4286 14.0299 11.4286 13.7143V12.5714H10.2857C9.97012 12.5714 9.71429 12.3156 9.71429 12C9.71429 11.6844 9.97012 11.4286 10.2857 11.4286H11.4286V10.2857C11.4286 9.97012 11.6844 9.71429 12 9.71429C12.3156 9.71429 12.5714 9.97012 12.5714 10.2857Z" />
    <path d="M10.2857 3.42857C10.2857 5.32212 8.75069 6.85714 6.85714 6.85714C4.9636 6.85714 3.42857 5.32212 3.42857 3.42857C3.42857 1.53502 4.9636 0 6.85714 0C8.75069 0 10.2857 1.53502 10.2857 3.42857ZM6.85714 5.71429C8.11951 5.71429 9.14286 4.69094 9.14286 3.42857C9.14286 2.16621 8.11951 1.14286 6.85714 1.14286C5.59478 1.14286 4.57143 2.16621 4.57143 3.42857C4.57143 4.69094 5.59478 5.71429 6.85714 5.71429Z" />
    <path d="M7.14978 13.7143C7.02073 13.3492 6.93174 12.9651 6.88809 12.5674H1.14286C1.14449 12.2854 1.31858 11.4405 2.09382 10.6653C2.83926 9.91983 4.24101 9.14286 6.85713 9.14286C7.15484 9.14286 7.43683 9.15292 7.70391 9.17185C7.96124 8.78175 8.27026 8.42877 8.62124 8.12267C8.08809 8.04362 7.5017 8 6.85714 8C1.14286 8 0 11.4286 0 12.5714C0 13.7143 1.14286 13.7143 1.14286 13.7143H7.14978Z" />
  </StyledSvg>
);

export default InviteUserIcon;

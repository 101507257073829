import React, { useEffect, useState } from 'react';
import {
  StrategiesPanelContainer,
  StrategiesTopContainer,
} from './StrategiesPanel.style';
import { Button, H1 } from '../../../../../App.style';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  getStrategiesListAsyncThunk,
  getArchivedStrategiesListAsyncThunk,
  selectStrategiesList,
  selectArchivedStrategiesList,
  selectStrategyId,
  checkStrategyUpdate,
} from './api/strategiesSlice';
import StrategyFormModal from './modals/StrategyFormModal';
import { Strategy } from '../../../../../../generated/tenants/Api';
import DeleteStrategyModal from './modals/DeleteStrategyModal';
import ArchiveStrategyModal from './modals/ArchiveStrategyModal';
import RestoreStrategyModal from './modals/RestoreStrategyModal';
import StrategiesList from './StrategiesList';
import StrategyDetailed from './StrategyDetailed';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../../../common/modals/LoadingModal';

const StrategiesPanel = () => {
  const { t } = useTranslation();
  const strategyId = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStrategiesListAsyncThunk());
    dispatch(getArchivedStrategiesListAsyncThunk());
  }, []);
  const apiStrategiesList = useAppSelector(selectStrategiesList);
  const apiArchivedStrategiesList = useAppSelector(
    selectArchivedStrategiesList,
  );

  //create and edit
  const [isStrategyFormModalVisible, setIsStrategyFormModalVisible] =
    useState<boolean>(false);
  const [selectedStrategy, setSelectedStrategy] = useState<Strategy>();

  const openFormModal = () => {
    setIsStrategyFormModalVisible(true);
  };

  const closeFormModal = () => {
    setIsStrategyFormModalVisible(false);
    setSelectedStrategy(undefined);
  };

  const handleEditClick = (strategy: Strategy) => {
    setSelectedStrategy(strategy);
    openFormModal();
  };

  //delete
  const [isDeleteStrategyModalVisible, setIsDeleteStrategyModalVisible] =
    useState<boolean>(false);
  const [isSelectedStrategyArchived, setIsSelectedStrategyArchived] =
    useState<boolean>(false);
  const handleDeleteClick = (strategy: Strategy, isArchived: boolean) => {
    setSelectedStrategy(strategy);
    setIsDeleteStrategyModalVisible(true);
    setIsSelectedStrategyArchived(isArchived);
  };
  const closeDeleteStrategyModal = () => {
    setSelectedStrategy(undefined);
    setIsDeleteStrategyModalVisible(false);
    setIsSelectedStrategyArchived(false);
  };

  //archive
  const [isArchiveStrategyModalVisible, setIsArchiveStrategyModalVisible] =
    useState<boolean>(false);

  const handleArchiveClick = (strategy: Strategy) => {
    setSelectedStrategy(strategy);
    setIsArchiveStrategyModalVisible(true);
  };
  const closeArchiveStrategyModal = () => {
    setSelectedStrategy(undefined);
    setIsArchiveStrategyModalVisible(false);
  };

  //restore
  const [isRestoreStrategyModalVisible, setIsRestoreStrategyModalVisible] =
    useState<boolean>(false);

  const handleRestoreClick = (strategy: Strategy) => {
    setSelectedStrategy(strategy);
    setIsRestoreStrategyModalVisible(true);
  };

  const closeRestoreStrategyModal = () => {
    setSelectedStrategy(undefined);
    setIsRestoreStrategyModalVisible(false);
  };

  const [strategyState, setStrategyState] = useState<'list' | 'detailed'>(
    'list',
  );

  const handleDetailedStrategyClick = (strategy: Strategy) => {
    if (strategy) setSelectedStrategy(strategy);
    setStrategyState('detailed');
  };

  const handleBackToList = () => {
    setStrategyState('list');
    setSelectedStrategy(undefined);
  };

  const checkUpdate = useAppSelector(checkStrategyUpdate);
  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success' &&
      checkUpdate.value.strategy
    ) {
      setSelectedStrategy(checkUpdate.value.strategy);
    }
  }, [checkUpdate]);

  const componentsMap = {
    list: (
      <StrategiesList
        strategiesList={apiStrategiesList}
        archivedStrategiesList={apiArchivedStrategiesList}
        handleArchiveClick={handleArchiveClick}
        handleDeleteClick={handleDeleteClick}
        handleEditClick={handleEditClick}
        handleDetailedClick={handleDetailedStrategyClick}
        handleRestoreClick={handleRestoreClick}
      />
    ),
    detailed: selectedStrategy && (
      <StrategyDetailed
        strategy={selectedStrategy}
        backToList={handleBackToList}
      />
    ),
  };
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <StrategiesPanelContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />

      <StrategyFormModal
        isVisible={isStrategyFormModalVisible}
        closeModal={closeFormModal}
        selectedStrategy={selectedStrategy}
        setIsLoadingVisible={setIsLoadingVisible}
      />

      {selectedStrategy && (
        <DeleteStrategyModal
          isVisible={isDeleteStrategyModalVisible}
          closeModal={closeDeleteStrategyModal}
          selectedStrategy={selectedStrategy}
          currentStrategyId={strategyId}
          isArchived={isSelectedStrategyArchived}
          setIsLoadingVisible={setIsLoadingVisible}
        />
      )}

      {selectedStrategy && (
        <ArchiveStrategyModal
          isVisible={isArchiveStrategyModalVisible}
          closeModal={closeArchiveStrategyModal}
          selectedStrategy={selectedStrategy}
          currentStrategyId={strategyId}
          setIsLoadingVisible={setIsLoadingVisible}
        />
      )}

      {selectedStrategy && (
        <RestoreStrategyModal
          isVisible={isRestoreStrategyModalVisible}
          closeModal={closeRestoreStrategyModal}
          selectedStrategy={selectedStrategy}
          setIsLoadingVisible={setIsLoadingVisible}
        />
      )}

      <StrategiesTopContainer>
        {strategyState === 'list' ? (
          <>
            <H1 $medium>{t('settings.adminPanel.manageWorkspaces.title')}</H1>

            <Button onClick={openFormModal} $size={'S'}>
              {t('settings.adminPanel.manageWorkspaces.createNew')}
            </Button>
          </>
        ) : (
          <>
            <H1 $medium>{t('settings.adminPanel.manageWorkspaces.edit')}</H1>
          </>
        )}
      </StrategiesTopContainer>

      {componentsMap[strategyState]}
    </StrategiesPanelContainer>
  );
};

export default StrategiesPanel;

import React, { useEffect, useState } from 'react';
import {
  CustomFaas,
  CustomFaasRequest,
} from '../../../../../generated/tenants/Api';
import { useAppDispatch } from '../../../../../common/hooks';
import { createCustomFaasAsyncThunk } from '../api/dialoguesSlice';
import { CreatePresetContainer } from '../../PresetsPanel.style';
import CustomFaasForm from './CustomFaasForm';

interface CreateCustomFaasProps {
  customFaas?: CustomFaas;
  strategy: string;
  backToList: () => void;
  handleNewCustomFaasChange?: () => void;
}

const CreateCustomFaas: React.FC<CreateCustomFaasProps> = ({
  customFaas,
  strategy,
  backToList,
  handleNewCustomFaasChange,
}) => {
  const dispatch = useAppDispatch();
  const [newCustomFaas, setNewCustomFaas] = useState<CustomFaasRequest>({
    name: '',
    lambda_type: 'REPORT',
    faas_endpoint: '',
    strategy: strategy,
  });

  useEffect(() => {
    if (customFaas) {
      setNewCustomFaas({ ...customFaas, name: `${customFaas.name}-modified` });
    }
  }, [customFaas]);

  const handleAddCustomFaas = () => {
    dispatch(createCustomFaasAsyncThunk(newCustomFaas));
  };
  return (
    <CreatePresetContainer>
      <CustomFaasForm
        customFaas={newCustomFaas}
        setCustomFaas={setNewCustomFaas}
        backToList={backToList}
        button={handleAddCustomFaas}
        createNew
        handleNewCustomFaasChange={handleNewCustomFaasChange}
      />
    </CreatePresetContainer>
  );
};

export default CreateCustomFaas;

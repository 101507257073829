import React from 'react';
import {
  CustomizeDashboardContainer,
  EditBarContainer,
  LeftContainer,
  ProcessBarContainer,
  RightContainer,
  SearchContainer,
} from './ProcessesBar.style';
import { Button, H1, H3 } from '../../../../App.style';
import SearchInput from '../../../../../common/inputs/SearchInput';
import { XIcon } from '../../../../../resources/icons-new/XIcon';
import { CustomizeDashboardIcon } from '../../../../../resources/icons-new/CustomizeDashboardIcon';
import { useTranslation } from 'react-i18next';

interface ProcessesBarProps {
  searchValue: string;
  handleEditClick: () => void;
  edit: boolean;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  openCreateProcessModal: () => void;
  hasProcessAccess: boolean;
  hasWorkspaceAccess: boolean;
}

const ProcessesBar: React.FC<ProcessesBarProps> = ({
  searchValue,
  handleEditClick,
  edit,
  setSearchValue,
  openCreateProcessModal,
  hasProcessAccess,
  hasWorkspaceAccess,
}) => {
  const { t } = useTranslation();
  return (
    <ProcessBarContainer>
      {!edit ? (
        <>
          <LeftContainer>
            <H1 $medium>{t('processes.title')}</H1>
            {hasProcessAccess && (
              <Button onClick={openCreateProcessModal} $size="S">
                {t('processes.new')}
              </Button>
            )}
          </LeftContainer>
          <RightContainer>
            <SearchContainer>
              <SearchInput value={searchValue} setValue={setSearchValue} />
            </SearchContainer>
            {hasWorkspaceAccess && (
              <CustomizeDashboardContainer onClick={handleEditClick}>
                <H3 $grey>{t('processes.customizeView')}</H3>
                <CustomizeDashboardIcon />
              </CustomizeDashboardContainer>
            )}
          </RightContainer>
        </>
      ) : (
        <EditBarContainer>
          <H3 style={{ fontWeight: 500 }}>{t('processes.customizeView')}</H3>

          <Button $size="S" $styleType="NORMAL" onClick={handleEditClick}>
            <XIcon />
            {t('common.close')}
          </Button>
        </EditBarContainer>
      )}
    </ProcessBarContainer>
  );
};

export default ProcessesBar;

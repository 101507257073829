import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const CheckIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M14.7223 0.402728C15.2592 0.939699 15.2592 1.8103 14.7223 2.34727L6.47227 10.5973C6.21441 10.8551 5.86467 11 5.5 11C5.13533 11 4.78559 10.8551 4.52773 10.5973L0.402728 6.47227C-0.134243 5.9353 -0.134243 5.0647 0.402728 4.52773C0.939699 3.99076 1.8103 3.99076 2.34727 4.52773L5.5 7.68046L12.7777 0.402728C13.3147 -0.134243 14.1853 -0.134243 14.7223 0.402728Z" />
  </StyledSvg>
);

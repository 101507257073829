import {
  Call,
  Campaign,
  CampaignChangeRequest,
  CampaignCreateRequest,
  CampaignPauseCcRequest,
  CampaignPauseInboundRequest,
  CampaignStatus,
  CampaignUpdate,
  CampaignUpdateStatusRequest,
  PaginatedCallList,
  PaginatedCampaignOverviewList,
  PaginatedRecordDataList,
  RecordImportFileRequest,
} from '../../../../generated/tenants/Api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  callDetailedView,
  campaignPauseInbound,
  campaignsPauseCc,
  CheckCampaignsResult,
  CheckCampaignStatusResult,
  CheckCampaignUpdateResult,
  CheckImportRecordsResult,
  createCampaigns,
  getCallRecording,
  getCallTranscript,
  getCampaignById,
  getCampaignCallsList,
  getCampaignHistoryList,
  getCampaignRecordsList,
  getCampaignsList,
  getCampaignsPanelList,
  getCampaignsRecordings,
  getCampaignStatus,
  getCampaignsTranscripts,
  importRecords,
  pauseRecords,
  startCampaign,
  updateCampaign,
  updateCampaignStatus,
} from './campaignsApi';

export interface CampaignState {
  panelList: {
    state: 'pending' | 'idle';
    value?: PaginatedCampaignOverviewList;
  };
  create?: {
    state: 'pending' | 'idle';
    value?: CheckCampaignsResult;
  };
  list: {
    state: 'pending' | 'idle';
    value?: PaginatedCampaignOverviewList;
  };
  start: {
    state: 'pending' | 'idle';
    value?: string | void;
  };
  byId: {
    state: 'pending' | 'idle';
    value?: Campaign;
  };
  campaignStatus: {
    state: 'pending' | 'idle';
    value?: CampaignStatus;
  };
  updateStatus: {
    state: 'pending' | 'idle';
    value?: CheckCampaignStatusResult;
  };
  recordsList: {
    state: 'pending' | 'idle';
    value?: PaginatedRecordDataList;
  };
  pauseCc: {
    state: 'pending' | 'idle';
  };
  pauseInbound: {
    state: 'pending' | 'idle';
  };
  pauseRecords: {
    state: 'pending' | 'idle';
  };
  importRecords: {
    state: 'pending' | 'idle';
    value?: CheckImportRecordsResult;
  };
  callsList: {
    state: 'pending' | 'idle';
    value?: PaginatedCallList;
  };
  callDetailed: {
    state: 'pending' | 'idle';
    value?: Call;
  };
  callRecording: {
    state: 'pending' | 'idle';
    value?: { recordingUrl: string; filename: string };
  };
  callTranscript: {
    state: 'pending' | 'idle';
    value?: { transcriptUrl: string; filename: string };
  };
  campaignsRecordings: {
    state: 'pending' | 'idle';
    value?: { campaignsRecordingsUrl: string; filename: string };
  };
  campaignsTranscripts: {
    state: 'pending' | 'idle';
    value?: { campaignsTranscriptUrl: string; filename: string };
  };
  historyList: {
    state: 'pending' | 'idle';
    value: CampaignUpdate[];
  };
  campaignUpdate: {
    state: 'pending' | 'idle';
    value?: CheckCampaignUpdateResult;
  };
}

const initialState: CampaignState = {
  panelList: { state: 'pending' },
  list: {
    state: 'pending',
  },
  start: {
    state: 'pending',
  },
  byId: {
    state: 'pending',
  },
  campaignStatus: {
    state: 'pending',
  },
  updateStatus: {
    state: 'pending',
  },
  recordsList: {
    state: 'pending',
  },
  pauseCc: {
    state: 'pending',
  },
  pauseInbound: {
    state: 'pending',
  },
  pauseRecords: {
    state: 'pending',
  },
  importRecords: {
    state: 'pending',
  },
  callsList: { state: 'pending' },
  callDetailed: { state: 'pending' },
  callRecording: { state: 'pending' },
  callTranscript: { state: 'pending' },
  campaignsRecordings: { state: 'pending' },
  campaignsTranscripts: {
    state: 'pending',
  },
  historyList: {
    state: 'pending',
    value: [],
  },
  campaignUpdate: { state: 'pending' },
};

//panel

export const getCampaignsPanelListAsyncThunk = createAsyncThunk(
  'campaigns/panel/list',
  (args: {
    strategyId: string;
    query?: {
      end_date_lte?: string;
      limit?: number;
      offset?: number;
      ordering?: '-end_date' | '-start_date' | 'end_date' | 'start_date';
      process_ids?: string[];
      start_date_gte?: string;
      status?: string;
      label_text?: string;
    };
  }) => getCampaignsPanelList(args.strategyId, args.query),
);

//campaigns

export const getCampaignsListAsyncThunk = createAsyncThunk(
  'campaigns/list',
  (id: string) => getCampaignsList(id),
);

export const createCampaignsAsyncThunk = createAsyncThunk(
  'campaigns/create',
  (data: CampaignCreateRequest) => createCampaigns(data),
);

export const getCampaignByIdAsyncThunk = createAsyncThunk(
  'campaigns/byId',
  (id: string) => getCampaignById(id),
);

export const startCampaignAsyncThunk = createAsyncThunk('campaigns/start', () =>
  startCampaign(),
);

export const updateCampaignStatusAsyncThunk = createAsyncThunk(
  'campaigns/updateStatus',
  (args: { id: string; data: CampaignUpdateStatusRequest }) =>
    updateCampaignStatus(args.id, args.data),
);

export const getCampaignRecordsListAsyncThunk = createAsyncThunk(
  'campaigns/recordsList',
  (args: {
    id: string;
    query?: {
      imported_from?: string;
      limit?: number;
      offset?: number;
      ordering?: '-created_at' | 'created_at';
      phone_number?: string;
      record_id?: string;
      status_list?: ('OPEN' | 'FINISHED' | 'CANCELLED' | 'EXHAUSTED')[];
    };
  }) => getCampaignRecordsList(args.id, args.query),
);

export const campaignsPauseCcAsyncThunk = createAsyncThunk(
  'campaigns/pauseCc',
  (args: { id: string; data: CampaignPauseCcRequest }) =>
    campaignsPauseCc(args.id, args.data),
);

export const campaignsPauseInboundAsyncThunk = createAsyncThunk(
  'campaigns/pauseInbound',
  (args: { id: string; data: CampaignPauseInboundRequest }) =>
    campaignPauseInbound(args.id, args.data),
);

export const pauseRecordsAsyncThunk = createAsyncThunk(
  'campaigns/records/pause',
  (args: { campaignId: string; query: { id: string[] } }) =>
    pauseRecords(args.campaignId, args.query),
);

export const importRecordsAsyncThunk = createAsyncThunk(
  'campaigns/records/impot',
  (args: { campaignId: string; data: RecordImportFileRequest }) =>
    importRecords(args.campaignId, args.data),
);

export const getCampaignCallsListAsyncThunk = createAsyncThunk(
  'campaigns/calls/list',
  (args: {
    id: string;
    query?: {
      call_type?: 'INBOUND' | 'OUTBOUND';
      date_from?: string;
      date_to?: string;
      limit?: number;
      offset?: number;
      ordering?: '-created_at' | 'created_at';
      record_id?: string;
      record_phone_number?: string;
      telco_status_list?: (
        | 'ANSWERED'
        | 'NOT_ANSWERED'
        | 'REJECTED'
        | 'BUSY'
        | 'VOICEMAIL'
        | 'TRANSFER'
        | 'NONEXISTENT_NUMBER'
        | 'UNMAPPED'
        | 'ERROR'
      )[];
    };
  }) => getCampaignCallsList(args.id, args.query),
);

export const callDetailedViewAsyncThunk = createAsyncThunk(
  'campaigns/calls/detailed',
  (id: string) => callDetailedView(id),
);

export const getCallRecordingAsyncThunk = createAsyncThunk(
  'campaigns/calls/recording',
  async (args: {
    callId: string;
    query?: { audio_format?: 'flac' | 'mp3' | 'wav' };
  }) => await getCallRecording(args.callId, args.query),
);

export const getCallTranscriptAsyncThunk = createAsyncThunk(
  'campaigns/calls/transcript',
  (callId: string) => getCallTranscript(callId),
);

export const getCampaignsRecordingsAsyncThunk = createAsyncThunk(
  'campaigns/recordings',
  (args: { campaignId: string; query?: { call_ids?: string[] } }) =>
    getCampaignsRecordings(args.campaignId, args.query),
);

export const getCampaignsTranscriptsAsyncThunk = createAsyncThunk(
  'campaigns/transcripts',
  (args: { campaignId: string; query?: { call_ids?: string[] } }) =>
    getCampaignsTranscripts(args.campaignId, args.query),
);

export const getCampaignHistoryListAsyncThunk = createAsyncThunk(
  'campaign/history',
  (campaignId: string) => getCampaignHistoryList(campaignId),
);

export const updateCampaignAsyncThunk = createAsyncThunk(
  'campaigns/update',
  (args: { campaignId: string; data: CampaignChangeRequest }) =>
    updateCampaign(args.campaignId, args.data),
);

export const getCampaignStatusAsyncThunk = createAsyncThunk(
  'campaigns/status',
  (campaignid: string) => getCampaignStatus(campaignid),
);

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    startCreatingCampaign: (state) => {
      state.create = { state: 'pending' };
    },
    endCreatingCampaign: (state) => {
      state.create = undefined;
    },
    clearImportRecords: (state) => {
      state.importRecords = { state: 'pending' };
    },
    clearRecordsList: (state) => {
      state.recordsList = { state: 'pending' };
    },

    clearCampaignUpdate: (state) => {
      state.campaignUpdate = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder

      //panel list
      .addCase(getCampaignsPanelListAsyncThunk.pending, (state) => {
        state.panelList.state = 'pending';
      })
      .addCase(getCampaignsPanelListAsyncThunk.fulfilled, (state, action) => {
        state.panelList.state = 'idle';
        state.panelList.value = action.payload;
      })
      .addCase(getCampaignsPanelListAsyncThunk.rejected, (state) => {
        state.panelList.state = 'idle';
      })

      //campaign create
      .addCase(createCampaignsAsyncThunk.pending, (state) => {
        if (state.create) {
          state.create.state = 'pending';
        }
      })
      .addCase(createCampaignsAsyncThunk.fulfilled, (state, action) => {
        if (state.create) {
          state.create.state = 'idle';
          state.create.value = action.payload;
        }
      })
      .addCase(createCampaignsAsyncThunk.rejected, (state) => {
        if (state.create) {
          state.create.state = 'idle';
        }
      })

      //campaigns list
      .addCase(getCampaignsListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(getCampaignsListAsyncThunk.fulfilled, (state, action) => {
        state.list.state = 'pending';
        state.list.value = action.payload;
      })
      .addCase(getCampaignsListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //campaign detailed
      .addCase(getCampaignByIdAsyncThunk.pending, (state) => {
        state.byId.state = 'pending';
      })
      .addCase(getCampaignByIdAsyncThunk.fulfilled, (state, action) => {
        state.byId.state = 'pending';
        state.byId.value = action.payload;
      })
      .addCase(getCampaignByIdAsyncThunk.rejected, (state) => {
        state.byId.state = 'idle';
      })

      //start campaign
      .addCase(startCampaignAsyncThunk.pending, (state) => {
        state.start.state = 'pending';
      })
      .addCase(startCampaignAsyncThunk.fulfilled, (state) => {
        state.start.state = 'idle';
        state.start.value = 'success';
      })
      .addCase(startCampaignAsyncThunk.rejected, (state) => {
        state.start.state = 'idle';
      })

      //update status
      .addCase(updateCampaignStatusAsyncThunk.pending, (state) => {
        state.updateStatus.state = 'pending';
      })
      .addCase(updateCampaignStatusAsyncThunk.fulfilled, (state, action) => {
        state.updateStatus.state = 'idle';
        state.updateStatus.value = action.payload;
      })
      .addCase(updateCampaignStatusAsyncThunk.rejected, (state) => {
        state.updateStatus.state = 'idle';
      })

      //records list
      .addCase(getCampaignRecordsListAsyncThunk.pending, (state) => {
        state.recordsList.state = 'pending';
      })
      .addCase(getCampaignRecordsListAsyncThunk.fulfilled, (state, action) => {
        state.recordsList.state = 'pending';
        state.recordsList.value = action.payload;
      })
      .addCase(getCampaignRecordsListAsyncThunk.rejected, (state) => {
        state.recordsList.state = 'idle';
      })

      //pause call-center
      .addCase(campaignsPauseCcAsyncThunk.pending, (state) => {
        state.pauseCc.state = 'pending';
      })
      .addCase(campaignsPauseCcAsyncThunk.fulfilled, (state) => {
        state.pauseCc.state = 'idle';
      })
      .addCase(campaignsPauseCcAsyncThunk.rejected, (state) => {
        state.pauseCc.state = 'idle';
      })

      //pause inbound
      .addCase(campaignsPauseInboundAsyncThunk.pending, (state) => {
        state.pauseInbound.state = 'pending';
      })
      .addCase(campaignsPauseInboundAsyncThunk.fulfilled, (state) => {
        state.pauseInbound.state = 'idle';
      })
      .addCase(campaignsPauseInboundAsyncThunk.rejected, (state) => {
        state.pauseInbound.state = 'idle';
      })

      //pause records
      .addCase(pauseRecordsAsyncThunk.pending, (state) => {
        state.pauseRecords.state = 'pending';
      })
      .addCase(pauseRecordsAsyncThunk.fulfilled, (state) => {
        state.pauseRecords.state = 'idle';
      })
      .addCase(pauseRecordsAsyncThunk.rejected, (state) => {
        state.pauseRecords.state = 'idle';
      })

      //import records
      .addCase(importRecordsAsyncThunk.pending, (state) => {
        state.importRecords.state = 'pending';
      })
      .addCase(importRecordsAsyncThunk.fulfilled, (state, action) => {
        state.importRecords.state = 'idle';
        state.importRecords.value = action.payload;
      })
      .addCase(importRecordsAsyncThunk.rejected, (state) => {
        state.importRecords.state = 'idle';
      })

      //calls list
      .addCase(getCampaignCallsListAsyncThunk.pending, (state) => {
        state.callsList.state = 'pending';
      })
      .addCase(getCampaignCallsListAsyncThunk.fulfilled, (state, action) => {
        state.callsList.state = 'idle';
        state.callsList.value = action.payload;
      })
      .addCase(getCampaignCallsListAsyncThunk.rejected, (state) => {
        state.callsList.state = 'idle';
      })

      //calls detailed
      .addCase(callDetailedViewAsyncThunk.pending, (state) => {
        state.callDetailed.state = 'pending';
      })
      .addCase(callDetailedViewAsyncThunk.fulfilled, (state, action) => {
        state.callDetailed.state = 'idle';
        state.callDetailed.value = action.payload;
      })
      .addCase(callDetailedViewAsyncThunk.rejected, (state) => {
        state.callDetailed.state = 'idle';
      })

      //call recording
      .addCase(getCallRecordingAsyncThunk.pending, (state) => {
        state.callRecording.state = 'pending';
      })
      .addCase(getCallRecordingAsyncThunk.fulfilled, (state, action) => {
        state.callRecording.state = 'idle';
        state.callRecording.value = action.payload;
      })
      .addCase(getCallRecordingAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.callRecording.state = 'idle';
      })

      //call transcript
      .addCase(getCallTranscriptAsyncThunk.pending, (state) => {
        state.callTranscript.state = 'pending';
      })
      .addCase(getCallTranscriptAsyncThunk.fulfilled, (state, action) => {
        state.callTranscript.state = 'idle';
        state.callTranscript.value = action.payload;
      })
      .addCase(getCallTranscriptAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.callTranscript.state = 'idle';
      })

      //campaign recordings
      .addCase(getCampaignsRecordingsAsyncThunk.pending, (state) => {
        state.campaignsRecordings.state = 'pending';
      })
      .addCase(getCampaignsRecordingsAsyncThunk.fulfilled, (state, action) => {
        state.campaignsRecordings.state = 'idle';
        state.campaignsRecordings.value = action.payload;
      })
      .addCase(getCampaignsRecordingsAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.campaignsRecordings.state = 'idle';
      })

      //campaign transcripts
      .addCase(getCampaignsTranscriptsAsyncThunk.pending, (state) => {
        state.campaignsTranscripts.state = 'pending';
      })
      .addCase(getCampaignsTranscriptsAsyncThunk.fulfilled, (state, action) => {
        state.campaignsTranscripts.state = 'idle';
        state.campaignsTranscripts.value = action.payload;
      })
      .addCase(getCampaignsTranscriptsAsyncThunk.rejected, (state, action) => {
        console.error('Error occurred:', action.error.message);
        state.campaignsTranscripts.state = 'idle';
      })

      //campaign history
      .addCase(getCampaignHistoryListAsyncThunk.pending, (state) => {
        state.historyList.state = 'pending';
      })
      .addCase(getCampaignHistoryListAsyncThunk.fulfilled, (state, action) => {
        state.historyList.state = 'idle';
        state.historyList.value = action.payload;
      })
      .addCase(getCampaignHistoryListAsyncThunk.rejected, (state) => {
        state.historyList.state = 'idle';
      })

      //campaign history
      .addCase(updateCampaignAsyncThunk.pending, (state) => {
        state.campaignUpdate.state = 'pending';
      })
      .addCase(updateCampaignAsyncThunk.fulfilled, (state, action) => {
        state.campaignUpdate.state = 'idle';
        state.campaignUpdate.value = action.payload;
      })
      .addCase(updateCampaignAsyncThunk.rejected, (state) => {
        state.campaignUpdate.state = 'idle';
      })

      .addCase(getCampaignStatusAsyncThunk.pending, (state) => {
        state.campaignStatus.state = 'pending';
      })
      .addCase(getCampaignStatusAsyncThunk.fulfilled, (state, action) => {
        state.campaignStatus.state = 'idle';
        state.campaignStatus.value = action.payload;
      })
      .addCase(getCampaignStatusAsyncThunk.rejected, (state) => {
        state.campaignStatus.state = 'idle';
      });
  },
});

export const selectCampaignsPanelList = (state: RootState) =>
  state.campaigns.panelList.value;

export const selectCampaignsList = (state: RootState) =>
  state.campaigns.list.value;
export const checkCampaignCreate = (state: RootState) => state.campaigns.create;
export const selectCampaignById = (state: RootState) =>
  state.campaigns.byId.value;
export const checkCampaignStart = (state: RootState) => state.campaigns.start;
export const checkCampaignStatus = (state: RootState) =>
  state.campaigns.updateStatus;

export const selectCampaignRecordsList = (state: RootState) =>
  state.campaigns.recordsList.value;

export const selectCampaignHistoryList = (state: RootState) =>
  state.campaigns.historyList.value;

export const checkImportRecords = (state: RootState) =>
  state.campaigns.importRecords;

export const selectCampaignCallsList = (state: RootState) =>
  state.campaigns.callsList.value;
export const selectCallDetailed = (state: RootState) =>
  state.campaigns.callDetailed.value;
export const selectCallRecording = (state: RootState) =>
  state.campaigns.callRecording;

export const checkPauseRecords = (state: RootState) =>
  state.campaigns.pauseRecords;

export const checkCampaignUpdate = (state: RootState) =>
  state.campaigns.campaignUpdate;
export const {
  startCreatingCampaign,
  endCreatingCampaign,
  clearImportRecords,
  clearRecordsList,
  clearCampaignUpdate,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;

import React from 'react';
import { CampaingsFiltersValues } from './CampaignsFilters';
import { EuiPopover, EuiCheckbox } from '@elastic/eui';
import { Button, H4, H5 } from '../../../../../App.style';
import {
  FilterButton,
  FilterBarButton,
  FiltersContainer,
  FiltersTopContainer,
  FiltersContentContainer,
  FiltersOptionContainer,
  FiltersBottomContainer,
} from './CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { useTranslation } from 'react-i18next';

interface CampaignsStatusesFilterProps {
  barButton: boolean;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedStatuses: string[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>;
  filters: CampaingsFiltersValues;
  setFilters: (value: React.SetStateAction<CampaingsFiltersValues>) => void;
}

const CampaignsStatusesFilter: React.FC<CampaignsStatusesFilterProps> = ({
  barButton,
  isOpen,
  setIsOpen,
  selectedStatuses,
  setSelectedStatuses,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();

  const statusesOptions = [
    {
      value: 'PENDING',
      text: t('settings.users.notifications.pending'),
    },
    {
      value: 'RUNNING',
      text: t('settings.users.notifications.running'),
    },
    {
      value: 'FINISHED',
      text: t('settings.users.notifications.finished'),
    },
    {
      value: 'SCHEDULED',
      text: t('settings.users.notifications.scheduled'),
    },
    {
      value: 'ERROR',
      text: t('settings.users.notifications.error'),
    },
    {
      value: 'STOPPED',
      text: t('settings.users.notifications.stopped'),
    },
  ];

  const handleRemoveStatuses = () => {
    setIsOpen(false);
    setFilters({ ...filters, statuses: [] });
  };
  const handleApplySelectedStatuses = () => {
    setIsOpen(false);
    setFilters({ ...filters, statuses: selectedStatuses });
  };

  const handleStatusChange = (option: string) => {
    if (selectedStatuses.includes(option)) {
      setSelectedStatuses(selectedStatuses.filter((val) => val !== option));
    } else {
      setSelectedStatuses([...selectedStatuses, option]);
    }
  };

  const getTranslatedStatus = (status: string): string => {
    const statusOption = statusesOptions.find(
      (option) => option.value === status,
    );
    return statusOption ? statusOption.text : status;
  };

  // Example usage in your component
  const translatedStatuses = selectedStatuses
    .map((status) => getTranslatedStatus(status))
    .join(', ');

  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            {t('campaigns.bar.filters.status')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <H4 $bold>{t('campaigns.bar.filters.statusIs')}</H4>{' '}
            <H4>{translatedStatuses}</H4> <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <FiltersContainer>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.bar.filters.statusIs')}</H4>
          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleRemoveStatuses}
            >
              {t('campaigns.bar.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer>
          {statusesOptions.map((option, index) => (
            <FiltersOptionContainer
              key={`status-filter-${index}`}
              onClick={() => handleStatusChange(option.value)}
            >
              <EuiCheckbox
                id={option.value}
                key={index}
                label={''}
                checked={selectedStatuses.includes(option.value)}
                onChange={(e) => e.preventDefault()}
              />
              <H4>{option.text}</H4>
            </FiltersOptionContainer>
          ))}
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button
            onClick={handleApplySelectedStatuses}
            style={{ minWidth: 40, height: 20, padding: '0px 8px' }}
            $size="S"
            color="white"
          >
            {t('campaigns.bar.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </FiltersContainer>
    </EuiPopover>
  );
};

export default CampaignsStatusesFilter;

import React from 'react';
import { CustomFaas } from '../../../../../generated/tenants/Api';
import { EuiFieldText } from '@elastic/eui';
import { H3 } from '../../../../App.style';
import { FormRow } from '../../../processes/forms/ProcessForm.style';

interface CustomFaasFormReadOnlyProps {
  customFaas: CustomFaas;
}

const CustomFaasFormReadOnly: React.FC<CustomFaasFormReadOnlyProps> = ({
  customFaas,
}) => {
  return (
    <>
      <FormRow label={<H3>Name</H3>}>
        <EuiFieldText value={customFaas.name} readOnly={true} />
      </FormRow>

      <FormRow label={<H3>Type</H3>}>
        <EuiFieldText value={customFaas.lambda_type} readOnly={true} />
      </FormRow>

      <FormRow label={<H3>Service endpoint</H3>}>
        <EuiFieldText value={customFaas.faas_endpoint} readOnly={true} />
      </FormRow>
    </>
  );
};

export default CustomFaasFormReadOnly;

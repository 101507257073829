import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../../common/hooks';
import {
  CallCenterNonWorkingDaysConfiguration,
  CallCenterNonWorkingDaysConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import { CreatePresetContainer } from '../../PresetsPanel.style';
import DaysOffForm from './CallCenterDaysOffForm';
import { createCallCenterDaysOffAsyncThunk } from './api/callCentreDaysOffSlice';
import LoadingModal from '../../../../../common/modals/LoadingModal';

interface CallCenterCreateProps {
  ccDaysOff?: CallCenterNonWorkingDaysConfiguration;
  strategy: string;
  backToList: () => void;
  handleNewCallCenterDaysOffChange?: () => void;
  formButtonDown?: boolean;
}

const CallCenterCreate: React.FC<CallCenterCreateProps> = ({
  ccDaysOff,
  strategy,
  backToList,
  handleNewCallCenterDaysOffChange,
  formButtonDown,
}) => {
  const dispatch = useAppDispatch();
  const [newCcDaysOff, setNewCCDaysOff] =
    useState<CallCenterNonWorkingDaysConfigurationRequest>({
      name: '',
      strategy: strategy,
      holidays: [],
    });

  useEffect(() => {
    if (ccDaysOff)
      setNewCCDaysOff({ ...ccDaysOff, name: `${ccDaysOff.name}-modified` });
  }, [ccDaysOff]);

  const handleAddCallCenterClick = () => {
    setIsLoadingVisible(true);
    dispatch(createCallCenterDaysOffAsyncThunk(newCcDaysOff)).finally(() => {
      setIsLoadingVisible(false);
    });
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <CreatePresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <DaysOffForm
        callCenterDaysOff={newCcDaysOff}
        setCallCenterDaysOff={setNewCCDaysOff}
        button={handleAddCallCenterClick}
        createNew
        backToList={backToList}
        handleNewCallCenterDaysOffChange={handleNewCallCenterDaysOffChange}
        buttonDown={formButtonDown}
      />
    </CreatePresetContainer>
  );
};

export default CallCenterCreate;

import React from 'react';
import { EuiFieldText, EuiPopover } from '@elastic/eui';
import { Button, H4, H5 } from '../../../../../App.style';
import { RecordsFiltersValues } from './RecordsFilters';
import {
  FilterButton,
  FilterBarButton,
  FiltersTopContainer,
  FiltersContentContainer,
  FiltersBottomContainer,
  FiltersContainer,
} from '../../../panel/bar/filters/CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { useTranslation } from 'react-i18next';

interface RecordsSourceFilterProps {
  barButton: boolean;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedSource: string;
  setSelectedSource: React.Dispatch<React.SetStateAction<string>>;
  filters: RecordsFiltersValues;
  setFilters: (value: React.SetStateAction<RecordsFiltersValues>) => void;
}

const RecordsSourceFilter: React.FC<RecordsSourceFilterProps> = ({
  barButton,
  isOpen,
  setIsOpen,
  selectedSource,
  setSelectedSource,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const handleClearSource = () => {
    setSelectedSource('');
    setFilters((prev) => ({ ...prev, source: '' }));
    setIsOpen(false);
  };

  const handleApplySource = () => {
    setFilters((prev) => ({
      ...prev,
      source: selectedSource,
    }));
    setIsOpen(false);
  };

  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            {t('campaigns.records.filters.source')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <H4 $bold>{t('campaigns.records.filters.sourceIs')}</H4>{' '}
            <H4>{filters.source}</H4>
            <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <FiltersContainer>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.records.filters.sourceIs')}</H4>
          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleClearSource}
            >
              {t('campaigns.records.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer
          style={{
            alignItems: 'center',
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <EuiFieldText
            autoFocus
            value={selectedSource}
            onChange={(e) => setSelectedSource(e.target.value)}
          />
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button onClick={handleApplySource} $size="S" color="white">
            {t('campaigns.records.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </FiltersContainer>
    </EuiPopover>
  );
};

export default RecordsSourceFilter;

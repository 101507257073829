import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Dashboard,
  DashboardColumnRequest,
  PatchedDashboardMoveRequest,
  Process,
  ProcessCreateRequest,
} from '../../../../generated/tenants/Api';
import { RootState } from '../../../store';
import {
  CheckProcessResult,
  CheckProcessesPanelResult,
  createProcess,
  createProcessesPanelColumn,
  deleteProcess,
  deleteProcessesesPanelColumn,
  getProcessesList,
  getProcessesPanelColumns,
  processDetailedView,
  reorderProcessesPanelColumns,
  updateProcess,
  updateProcessesPanelColumn,
} from './processApi';

export interface ProcessState {
  list: {
    state: 'pending' | 'idle';
    value: Process[];
  };
  create?: {
    state: 'pending' | 'idle';
    value?: CheckProcessResult;
  };
  update?: {
    state: 'pending' | 'idle';
    value?: CheckProcessResult;
  };
  detailedView: {
    state: 'pending' | 'idle';
    value?: Process;
  };
  delete: {
    state: 'pending' | 'idle';
  };
  panelColumns: {
    state: 'pending' | 'idle';
    value?: Dashboard;
  };
  panelReorder: { state: 'pending' | 'idle' };
  panelCreateColumn: {
    state: 'pending' | 'idle';
    value?: CheckProcessesPanelResult;
  };
  panelUpdateColumn: {
    state: 'pending' | 'idle';
    value?: CheckProcessesPanelResult;
  };
  panelDeleteColumn: {
    state: 'pending' | 'idle';
    value?: CheckProcessesPanelResult;
  };
}

const initialState: ProcessState = {
  list: { state: 'pending', value: [] },
  detailedView: { state: 'pending' },
  delete: { state: 'pending' },
  panelColumns: { state: 'pending' },
  panelReorder: { state: 'pending' },
  panelCreateColumn: { state: 'pending' },
  panelUpdateColumn: { state: 'pending' },
  panelDeleteColumn: { state: 'pending' },
};

//panel
export const getProcessesPanelColumnsAsyncThunk = createAsyncThunk(
  'processes/panel/columns',
  (strategyId: string) => getProcessesPanelColumns(strategyId),
);

export const reorderProcessesPanelColumnsAsyncThunk = createAsyncThunk(
  'processes/panel/reorder',
  (args: { strategyId: string; data: PatchedDashboardMoveRequest }) =>
    reorderProcessesPanelColumns(args.strategyId, args.data),
);

export const createProcessesPanelColumnAsyncThunk = createAsyncThunk(
  'processes/panel/create',
  (data: DashboardColumnRequest) => createProcessesPanelColumn(data),
);

export const updateProcessesPanelColumnAsyncThunk = createAsyncThunk(
  'processes/panel/update',
  (args: { id: string; data: DashboardColumnRequest }) =>
    updateProcessesPanelColumn(args.id, args.data),
);

export const deleteProcessesesPanelColumnAsyncThunk = createAsyncThunk(
  'processes/panel/delete',
  (id: string) => deleteProcessesesPanelColumn(id),
);

//proceses

export const getProcessesListAsyncThunk = createAsyncThunk(
  'processes/list',
  (args: { id: string; query?: { is_archived?: boolean } }) =>
    getProcessesList(args.id, args.query),
);

export const createProcessAsyncThunk = createAsyncThunk(
  'processes/create',
  (data: ProcessCreateRequest) => createProcess(data),
);

export const updateProcessAsyncThunk = createAsyncThunk(
  'processes/update',
  (args: { id: string; data: ProcessCreateRequest }) =>
    updateProcess(args.id, args.data),
);

export const processDetailedViewAsyncThunk = createAsyncThunk(
  'processes/detailedView',
  (id: string) => processDetailedView(id),
);

export const deleteProcessAsyncThunk = createAsyncThunk(
  'processes/delete',
  (id: string) => deleteProcess(id),
);

export const processSlice = createSlice({
  name: 'processes',
  initialState,
  reducers: {
    startCreateProcess: (state) => {
      state.create = { state: 'pending' };
    },
    endCreateProcess: (state) => {
      state.create = undefined;
    },
    startUpdateProcess: (state) => {
      state.update = { state: 'pending' };
    },
    endUpdateProcess: (state) => {
      state.update = undefined;
    },
    clearDeleteProcess: (state) => {
      state.delete = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder

      //get columns
      .addCase(getProcessesPanelColumnsAsyncThunk.pending, (state) => {
        state.panelColumns.state = 'pending';
      })
      .addCase(
        getProcessesPanelColumnsAsyncThunk.fulfilled,
        (state, action) => {
          state.panelColumns.state = 'idle';
          state.panelColumns.value = action.payload;
        },
      )
      .addCase(getProcessesPanelColumnsAsyncThunk.rejected, (state) => {
        state.panelColumns.state = 'idle';
      })

      //reorder columns
      .addCase(reorderProcessesPanelColumnsAsyncThunk.pending, (state) => {
        state.panelReorder.state = 'pending';
      })
      .addCase(reorderProcessesPanelColumnsAsyncThunk.fulfilled, (state) => {
        state.panelReorder.state = 'idle';
      })
      .addCase(reorderProcessesPanelColumnsAsyncThunk.rejected, (state) => {
        state.panelReorder.state = 'idle';
      })

      //create column
      .addCase(createProcessesPanelColumnAsyncThunk.pending, (state) => {
        state.panelCreateColumn.state = 'pending';
      })
      .addCase(
        createProcessesPanelColumnAsyncThunk.fulfilled,
        (state, action) => {
          state.panelCreateColumn.state = 'idle';
          state.panelCreateColumn.value = action.payload;
        },
      )
      .addCase(createProcessesPanelColumnAsyncThunk.rejected, (state) => {
        state.panelCreateColumn.state = 'idle';
      })

      //update column
      .addCase(updateProcessesPanelColumnAsyncThunk.pending, (state) => {
        state.panelUpdateColumn.state = 'pending';
      })
      .addCase(
        updateProcessesPanelColumnAsyncThunk.fulfilled,
        (state, action) => {
          state.panelUpdateColumn.state = 'idle';
          state.panelUpdateColumn.value = action.payload;
        },
      )
      .addCase(updateProcessesPanelColumnAsyncThunk.rejected, (state) => {
        state.panelUpdateColumn.state = 'idle';
      })

      //delete column
      .addCase(deleteProcessesesPanelColumnAsyncThunk.pending, (state) => {
        state.panelDeleteColumn.state = 'pending';
      })
      .addCase(
        deleteProcessesesPanelColumnAsyncThunk.fulfilled,
        (state, action) => {
          state.panelDeleteColumn.state = 'idle';
          state.panelDeleteColumn.value = action.payload;
        },
      )
      .addCase(deleteProcessesesPanelColumnAsyncThunk.rejected, (state) => {
        state.panelDeleteColumn.state = 'idle';
      })

      //process
      //list
      .addCase(getProcessesListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(getProcessesListAsyncThunk.fulfilled, (state, action) => {
        state.list.state = 'idle';
        state.list.value = action.payload;
      })
      .addCase(getProcessesListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })

      //create
      .addCase(createProcessAsyncThunk.pending, (state) => {
        if (state.create) {
          state.create.state = 'pending';
        }
      })
      .addCase(createProcessAsyncThunk.fulfilled, (state, action) => {
        if (state.create) {
          state.create.state = 'idle';
          state.create.value = action.payload;
        }
      })
      .addCase(createProcessAsyncThunk.rejected, (state) => {
        if (state.create) {
          state.create.state = 'idle';
        }
      })

      //update
      .addCase(updateProcessAsyncThunk.pending, (state) => {
        if (state.update) {
          state.update.state = 'pending';
        }
      })
      .addCase(updateProcessAsyncThunk.fulfilled, (state, action) => {
        if (state.update) {
          state.update.state = 'idle';
          state.update.value = action.payload;
        }
      })
      .addCase(updateProcessAsyncThunk.rejected, (state) => {
        if (state.update) {
          state.update.state = 'idle';
        }
      })

      //detailed view
      .addCase(processDetailedViewAsyncThunk.pending, (state) => {
        state.detailedView.state = 'idle';
      })
      .addCase(processDetailedViewAsyncThunk.fulfilled, (state, action) => {
        state.detailedView.state = 'idle';
        state.detailedView.value = action.payload;
      })
      .addCase(processDetailedViewAsyncThunk.rejected, (state) => {
        state.detailedView.state = 'idle';
      })

      //delete
      .addCase(deleteProcessAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(deleteProcessAsyncThunk.fulfilled, (state) => {
        state.delete.state = 'idle';
      })
      .addCase(deleteProcessAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      });
  },
});

//panel
export const selectProcessesPanelColumns = (state: RootState) =>
  state.processes.panelColumns.value;

export const checkProcessesPanelReorder = (state: RootState) =>
  state.processes.panelReorder;

export const checkProcessesPanelColumnCreate = (state: RootState) =>
  state.processes.panelCreateColumn;
export const checkProcessesPanelColumnUpdate = (state: RootState) =>
  state.processes.panelUpdateColumn;
export const checkProcessesPanelColumnDelete = (state: RootState) =>
  state.processes.panelDeleteColumn;

//processes
export const selectProcessesList = (state: RootState) =>
  state.processes.list.value;

export const selectProcessDetailedView = (state: RootState) =>
  state.processes.detailedView.value;

export const checkProcessCreate = (state: RootState) => state.processes.create;
export const checkProcessUpdate = (state: RootState) => state.processes.update;
export const checkProcessDelete = (state: RootState) => state.processes.delete;

export const {
  startCreateProcess,
  endCreateProcess,
  startUpdateProcess,
  endUpdateProcess,
  clearDeleteProcess,
} = processSlice.actions;

export default processSlice.reducer;

import React, { useEffect, useState } from 'react';
import { DialogueToken } from '../../configuration/tokenInputComponents/BotsTokens';
import {
  TextTokenButtonsContainer,
  TextTokenContainer,
  TextTokenInputContainer,
} from './TokenInput.style';
import { EuiFieldText } from '@elastic/eui';
import { Button, ButtonEmpty } from '../../../../App.style';
import { ArrowRightIcon } from '../../../../../resources/icons-new/ArrowRightIcon';

interface TextTokenProps {
  addToken: (token: DialogueToken) => void;
  type: 'text' | 'text_by_bot_gender' | 'text_by_caller_gender';
}
const TextToken: React.FC<TextTokenProps> = ({ addToken, type }) => {
  const [text, setText] = useState<string>('');
  const [text2, setText2] = useState<string>('');

  useEffect(() => {
    handleCancelClick();
  }, [type]);

  const handleCancelClick = () => {
    setText('');
    setText2('');
  };

  const handleAddToken = () => {
    if (type === 'text') {
      addToken({
        displayText: text,
        value: text,
        type: 'text',
        id: text,
      });
    } else if (type === 'text_by_bot_gender') {
      addToken({
        displayText: `${text} / ${text2}`,
        value: `{${text2}|${text}}`,
        type: 'text_by_bot_gender',
        id: `[${text2}|${text}]`,
      });
    } else if (type === 'text_by_caller_gender') {
      addToken({
        displayText: `${text} / ${text2}`,
        value: `[${text2}|${text}]`,
        type: 'text_by_caller_gender',
        id: `{${text2}|${text}}`,
      });
    } else {
      alert('wtf?');
    }
  };

  const isButtonDisabled = () => {
    if (type === 'text') {
      return !text;
    } else if (
      type === 'text_by_bot_gender' ||
      type === 'text_by_caller_gender'
    ) {
      return !text || !text2;
    }
    return false;
  };
  return (
    <TextTokenContainer>
      <TextTokenInputContainer>
        <EuiFieldText
          compressed
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={type !== 'text' ? 'Męski' : 'Wpisz tekst'}
        />
      </TextTokenInputContainer>
      {type !== 'text' && (
        <TextTokenInputContainer>
          <EuiFieldText
            compressed
            value={text2}
            onChange={(e) => setText2(e.target.value)}
            placeholder="Żeński"
          />
        </TextTokenInputContainer>
      )}
      <TextTokenButtonsContainer>
        <ButtonEmpty onClick={handleCancelClick}>Cancel</ButtonEmpty>
        <Button
          type={'button'}
          $styleType="NORMAL"
          $size="S"
          disabled={isButtonDisabled()}
          onClick={handleAddToken}
        >
          Add <ArrowRightIcon />
        </Button>
      </TextTokenButtonsContainer>
    </TextTokenContainer>
  );
};

export default TextToken;

import React, { useEffect, useState } from 'react';
import {
  FormContainer,
  FormRow,
} from '../../../../processes/forms/ProcessForm.style';
import {
  Strategy,
  StrategyRequest,
} from '../../../../../../generated/tenants/Api';
import {
  EuiFieldText,
  EuiFieldNumber,
  EuiToolTip,
  EuiText,
  EuiSwitch,
  EuiTextArea,
  EuiLoadingSpinner,
  copyToClipboard,
} from '@elastic/eui';
import { H3, Button } from '../../../../../App.style';
import {
  checkStrategyUpdate,
  clearUpdateStrategy,
} from './api/strategiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { ApiError } from '../../../../../../common/types';
import {
  getLoginStateAsyncThunk,
  selectConfig,
  selectUser,
} from '../../../../session/api/sessionSlice';
import {
  CopyIconContainer,
  StrategyDetailedKeyContainer,
  StrategyKeyRowContainer,
  TextAreaWrapper,
} from './StrategiesPanel.style';
import { CopyIcon } from '../../../../../../resources/icons-new/CopyIcon';
import { InfoIcon } from '../../../../../../resources/icons-new/InfoIcon';
import { useTranslation } from 'react-i18next';

const useStrategyApiErrors = () => {
  const checkStrategyConfigUpdate = useAppSelector(checkStrategyUpdate);
  const updateErrors = checkStrategyConfigUpdate?.value?.error;

  const [strategyApiErrors, setStrategyApiErrors] = useState<
    ApiError<Strategy>
  >({} as ApiError<Strategy>);

  useEffect(() => {
    if (updateErrors)
      setStrategyApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [strategyApiErrors, setStrategyApiErrors] as const;
};

interface StrategyFormProps {
  strategy: StrategyRequest;
  setStrategy: React.Dispatch<React.SetStateAction<StrategyRequest>>;
  backToDetailed: () => void;
  publicGpgKey: string;
  publicKeyLoading: boolean;
  handleRotateGpgKey: () => void;
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>;
}

const StrategyForm: React.FC<StrategyFormProps> = ({
  strategy,
  setStrategy,
  backToDetailed,
  publicGpgKey,
  publicKeyLoading,
  handleRotateGpgKey,
  setHasErrors,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checkUpdate = useAppSelector(checkStrategyUpdate);

  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      dispatch(clearUpdateStrategy());
      backToDetailed();
    }
  });

  const config = useAppSelector(selectConfig);
  const user = useAppSelector(selectUser);

  const currentTenantName = (user.value?.companies || [])
    .filter((tenant) => !!tenant.slug && tenant.slug === config.tenantSubdomain)
    .map((tenant) => tenant.name)[0];

  const freeLinesByTenantName = (name: string) => {
    return user.value?.companies.find((obj) => obj.name === name)?.free_lines;
  };

  const [assignedLinesError, setAssignedLinesError] = useState<boolean>(false);
  useEffect(() => {
    if (availableLines) {
      if (strategy.assigned_lines > availableLines) {
        setAssignedLinesError(true);
      } else {
        setAssignedLinesError(false);
      }
    }
  }, [strategy.assigned_lines]);

  const freeLines = freeLinesByTenantName(currentTenantName);

  const [availableLines, setAvailableLines] = useState<number>(0);
  useEffect(() => {
    if (freeLines !== undefined) {
      if (strategy) {
        setAvailableLines(freeLines + strategy.assigned_lines);
      } else {
        setAvailableLines(freeLines);
      }
    }
  }, [freeLines]);

  const [strategyApiErrors, setStrategyApiErrors] = useStrategyApiErrors();

  useEffect(() => {
    setStrategyApiErrors({} as ApiError<Strategy>);
    dispatch(clearUpdateStrategy());
    dispatch(getLoginStateAsyncThunk());
  }, []);

  const [publicKeyCopied, setPublicKeyCopied] = useState<boolean>(false);
  const handleCopyPublicKey = (key: string) => {
    setPublicKeyCopied(true);
    copyToClipboard(key);
  };

  useEffect(() => {
    if (strategy.cipher_output_data && !strategy.cipher_public_key_gpg) {
      setStrategyApiErrors((prevErrors) => ({
        ...prevErrors,
        cipher_public_key_gpg: ['Public GPG key cannot be empty'],
      }));
    } else {
      setStrategyApiErrors((prevErrors) => ({
        ...prevErrors,
        cipher_public_key_gpg: undefined,
      }));
    }
  }, [strategy.cipher_output_data, strategy.cipher_public_key_gpg]);

  useEffect(() => {
    const hasApiErrors = Object.values(strategyApiErrors).some(
      (error) => error !== undefined && error !== null,
    );

    setHasErrors(hasApiErrors);
  }, [strategyApiErrors, setHasErrors]);

  const noLinesErrorText = t(
    'settings.adminPanel.manageWorkspaces.form.noLines',
  );

  return (
    <FormContainer>
      <FormRow
        label={<H3>{t('settings.adminPanel.manageWorkspaces.form.name')}</H3>}
        isInvalid={strategyApiErrors.name ? true : false}
        error={strategyApiErrors.name}
      >
        <EuiFieldText
          value={strategy.name}
          isInvalid={strategyApiErrors.name ? true : false}
          onChange={(e) => {
            setStrategy({ ...strategy, name: e.target.value });
            strategyApiErrors.name &&
              setStrategyApiErrors({
                ...strategyApiErrors,
                name: undefined,
              });
          }}
        />
      </FormRow>

      <FormRow
        label={
          <H3>
            {t('settings.adminPanel.manageWorkspaces.form.assignedLines')}
          </H3>
        }
        isInvalid={
          strategyApiErrors?.assigned_lines ? true : assignedLinesError
        }
        error={
          strategyApiErrors?.assigned_lines
            ? strategyApiErrors.assigned_lines
            : noLinesErrorText
        }
      >
        <EuiFieldNumber
          value={strategy.assigned_lines}
          isInvalid={
            strategyApiErrors?.assigned_lines ? true : assignedLinesError
          }
          onChange={(e) => {
            setStrategy({
              ...strategy,
              assigned_lines: Number(e.target.value),
            });
            setStrategyApiErrors({
              ...strategyApiErrors,
              assigned_lines: undefined,
            });
          }}
          append={
            <EuiToolTip content="">
              <EuiText size="s">
                {t('settings.adminPanel.manageWorkspaces.form.freeLines')}
                {freeLines}
              </EuiText>
            </EuiToolTip>
          }
        />
      </FormRow>

      <StrategyKeyRowContainer>
        <H3>
          {t('settings.adminPanel.manageWorkspaces.form.encryptImportedData')}
        </H3>
        <EuiToolTip
          position="top"
          content={t(
            'settings.adminPanel.manageWorkspaces.form.encryptImportedDataTooltip',
          )}
        >
          <InfoIcon />
        </EuiToolTip>

        <EuiSwitch
          checked={strategy.decipher_input_data ?? false}
          compressed
          label={
            strategy.decipher_input_data
              ? t('settings.adminPanel.manageWorkspaces.form.yes')
              : t('settings.adminPanel.manageWorkspaces.form.no')
          }
          id=""
          onChange={(e) => {
            setStrategy({
              ...strategy,
              decipher_input_data: e.target.checked,
            });
            strategyApiErrors.decipher_input_data &&
              setStrategyApiErrors({
                ...strategyApiErrors,
                decipher_input_data: undefined,
              });
          }}
        />
      </StrategyKeyRowContainer>

      {strategy.decipher_input_data && (
        <StrategyDetailedKeyContainer>
          {!publicGpgKey ? (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                $styleType="NORMAL"
                $size="S"
                type="button"
                onClick={handleRotateGpgKey}
              >
                {t(
                  'settings.adminPanel.manageWorkspaces.form.generateNewPublicKey',
                )}
              </Button>
            </div>
          ) : (
            <FormRow
              label={
                <H3>
                  {t('settings.adminPanel.manageWorkspaces.form.publicKey')}
                </H3>
              }
              style={{ marginBottom: 0 }}
            >
              <div style={{ position: 'relative' }}>
                <Button
                  $styleType="NORMAL"
                  $size="S"
                  type="button"
                  style={{ position: 'absolute', right: 0, top: -30 }}
                  onClick={handleRotateGpgKey}
                >
                  {t(
                    'settings.adminPanel.manageWorkspaces.form.generateNewPublicKey',
                  )}
                </Button>
                {publicKeyLoading ? (
                  <EuiLoadingSpinner size="xl" />
                ) : (
                  <TextAreaWrapper>
                    <EuiTextArea
                      style={{
                        width: 350,
                        minHeight: 50,
                        background: '#F5F7FA',
                        paddingRight: 25,
                      }}
                      value={publicGpgKey ?? 'No key generated'}
                      readOnly
                      disabled
                    />
                    <CopyIconContainer>
                      <EuiToolTip
                        position="top"
                        content={
                          <p style={{ textAlign: 'center' }}>
                            {publicKeyCopied
                              ? t(
                                  'settings.adminPanel.manageWorkspaces.form.publicKeyCopied',
                                )
                              : t(
                                  'settings.adminPanel.manageWorkspaces.form.copy',
                                )}
                          </p>
                        }
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyPublicKey(publicGpgKey);
                          }}
                          onMouseLeave={() => setPublicKeyCopied(false)}
                        >
                          <CopyIcon $pointer />
                        </div>
                      </EuiToolTip>
                    </CopyIconContainer>
                  </TextAreaWrapper>
                )}
              </div>
            </FormRow>
          )}
        </StrategyDetailedKeyContainer>
      )}

      <StrategyKeyRowContainer>
        <H3>
          {t('settings.adminPanel.manageWorkspaces.form.encryptOutgoingData')}
        </H3>
        <EuiToolTip
          position="top"
          content={t(
            'settings.adminPanel.manageWorkspaces.form.encryptOutgoingDataTooltip',
          )}
        >
          <InfoIcon />
        </EuiToolTip>
        <EuiSwitch
          checked={strategy.cipher_output_data ?? false}
          compressed
          label={
            strategy.cipher_output_data
              ? t('settings.adminPanel.manageWorkspaces.form.yes')
              : t('settings.adminPanel.manageWorkspaces.form.no')
          }
          id=""
          onChange={(e) =>
            setStrategy({
              ...strategy,
              cipher_output_data: e.target.checked,
            })
          }
        />
      </StrategyKeyRowContainer>

      {strategy.cipher_output_data && (
        <StrategyDetailedKeyContainer>
          <FormRow
            label={
              <H3>
                {t('settings.adminPanel.manageWorkspaces.form.publicGpgKey')}
              </H3>
            }
            style={{ marginBottom: 0 }}
            isInvalid={strategyApiErrors.cipher_public_key_gpg ? true : false}
            error={strategyApiErrors.cipher_public_key_gpg}
          >
            <EuiTextArea
              isInvalid={strategyApiErrors.cipher_public_key_gpg ? true : false}
              value={strategy.cipher_public_key_gpg ?? ''}
              onChange={(e) => {
                setStrategy({
                  ...strategy,
                  cipher_public_key_gpg: e.target.value,
                });
                strategyApiErrors.cipher_public_key_gpg &&
                  setStrategyApiErrors({
                    ...strategyApiErrors,
                    cipher_public_key_gpg: undefined,
                  });
              }}
            />
          </FormRow>
        </StrategyDetailedKeyContainer>
      )}
    </FormContainer>
  );
};

export default StrategyForm;

import React, { useEffect } from 'react';
import { H3 } from '../../../../App.style';
import { Process } from '../../../../../generated/tenants/Api';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../common/modals/Modals.style';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  checkProcessDelete,
  clearDeleteProcess,
  deleteProcessAsyncThunk,
  getProcessesPanelColumnsAsyncThunk,
} from '../../api/processSlice';
import { selectStrategyId } from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { TrashIcon } from '../../../../../resources/icons-new/TrashIcon';
import Modal from '../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface DeleteProcessModalProps {
  isVisible: boolean;
  closeModal: () => void;
  process: Process;
}

const DeleteProcessModal: React.FC<DeleteProcessModalProps> = ({
  isVisible,
  closeModal,
  process,
}) => {
  const { t } = useTranslation();
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const deleteProcess = () => {
    dispatch(deleteProcessAsyncThunk(process.id));
  };

  const checkDelete = useAppSelector(checkProcessDelete);

  useEffect(() => {
    if (checkDelete.state === 'idle') {
      closeModal();
      dispatch(getProcessesPanelColumnsAsyncThunk(strategy));
      dispatch(clearDeleteProcess());
    }
  }, [checkDelete]);

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>{t('processes.modals.deleteProcessQuestion')}</H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteProcess();

    return false;
  };

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={`${(<H3>{t('processes.modals.removeProcess')}</H3>)} ${
        process.name
      }`}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default DeleteProcessModal;

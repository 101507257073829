import { EuiFieldText, EuiSuperSelect } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { Button, H3 } from '../../../App.style';
import {
  CalendarConfiguration,
  CalendarConfigurationRequest,
} from '../../../../generated/tenants/Api';
import { timezoneOptions } from './workingHours/calendarFunctions';
import {
  FormContainer,
  FormRow,
  PresetCreateButton,
} from '../../processes/forms/ProcessForm.style';
import {
  checkCalendarCreate,
  checkCalendarUpdate,
  clearCreateCalendar,
  clearUpdateCalendar,
  getCalendarsListAsyncThunk,
} from './api/calendarSlice';
import { ApiError } from '../../../../common/types';
import WorkingHoursEdit from './workingHours/edit/WorkingHoursEdit';
import { useTranslation } from 'react-i18next';

const useCalendarApiErrors = () => {
  const checkCreate = useAppSelector(checkCalendarCreate);
  const createErrors = checkCreate.value?.error;

  const checkUpdate = useAppSelector(checkCalendarUpdate);
  const updateErrors = checkUpdate.value?.error;

  const [calendarApiErrors, setCalendarApiErrors] = useState<
    ApiError<CalendarConfiguration>
  >({} as ApiError<CalendarConfiguration>);

  useEffect(() => {
    if (createErrors)
      setCalendarApiErrors((prevErrors) => ({
        ...prevErrors,
        ...createErrors,
      }));
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors)
      setCalendarApiErrors((prevErrors) => ({
        ...prevErrors,
        ...updateErrors,
      }));
  }, [updateErrors]);

  return [calendarApiErrors, setCalendarApiErrors] as const;
};

interface CalendarFormProps {
  calendar: CalendarConfigurationRequest;
  setCalendar: React.Dispatch<
    React.SetStateAction<CalendarConfigurationRequest | undefined>
  >;
  button?: () => void;
  createNew?: boolean;
  back: () => void;
  backToDetailed?: () => void;
  handleNewCalendarChange?: () => void;
  calendarHasErrors: boolean;
  setCalendarHasErrors: React.Dispatch<React.SetStateAction<boolean>>;
  buttonDown?: boolean;
}

const CalendarForm: React.FC<CalendarFormProps> = ({
  calendar,
  setCalendar,
  button,
  createNew,
  back,
  backToDetailed,
  handleNewCalendarChange,

  setCalendarHasErrors,
  buttonDown,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const checkCreate = useAppSelector(checkCalendarCreate);
  const checkUpdate = useAppSelector(checkCalendarUpdate);

  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      handleNewCalendarChange && handleNewCalendarChange();
      dispatch(getCalendarsListAsyncThunk(calendar.strategy));
      dispatch(clearCreateCalendar());
      back();
    }

    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success'
    ) {
      dispatch(getCalendarsListAsyncThunk(calendar.strategy));
      dispatch(clearUpdateCalendar());
      backToDetailed && backToDetailed();
    }
  });

  const [calendarApiErrors, setCalendarApiErrors] = useCalendarApiErrors();

  useEffect(() => {
    if (createNew && calendar.name !== '') {
      setCalendar({ ...calendar, name: `${calendar.name}-modified` });
    }
  }, [createNew]);

  return (
    <FormContainer
      $processForm={buttonDown}
      $calendarForm
      onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        button && button();
      }}
    >
      <FormRow
        label={<H3>{t('presets.calendarForm.name')}</H3>}
        isInvalid={calendarApiErrors.name ? true : false}
        error={calendarApiErrors.name}
      >
        <EuiFieldText
          autoFocus
          isInvalid={calendarApiErrors.name ? true : false}
          value={calendar.name}
          onChange={(e) => {
            setCalendar({ ...calendar, name: e.target.value });

            calendarApiErrors.name &&
              setCalendarApiErrors({ ...calendarApiErrors, name: undefined });
          }}
        />
      </FormRow>
      <FormRow
        label={<H3>{t('presets.calendarForm.timezone')}</H3>}
        isInvalid={calendarApiErrors.timezone ? true : false}
        error={calendarApiErrors.timezone}
      >
        <EuiSuperSelect
          isInvalid={calendarApiErrors.timezone ? true : false}
          valueOfSelected={calendar.timezone}
          options={timezoneOptions.map((t) => ({
            value: t,
            inputDisplay: t,
            dropdownDisplay: <H3>{t}</H3>,
          }))}
          onChange={(e) => {
            setCalendar({ ...calendar, timezone: e });

            calendarApiErrors.timezone &&
              setCalendarApiErrors({
                ...calendarApiErrors,
                timezone: undefined,
              });
          }}
        />
      </FormRow>

      <WorkingHoursEdit
        calendarConfiguration={calendar}
        setCalendarConfiguration={setCalendar}
        setCalendarHasErrors={setCalendarHasErrors}
      />

      {createNew && !buttonDown ? (
        <PresetCreateButton>
          <Button type="submit">{t('common.create')}</Button>
        </PresetCreateButton>
      ) : (
        buttonDown && (
          <Button
            type="submit"
            style={{ height: 80, marginTop: 16, padding: '16px 16px' }}
          >
            {t('common.create')}
          </Button>
        )
      )}
    </FormContainer>
  );
};

export default CalendarForm;

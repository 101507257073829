import { EuiLoadingSpinner, EuiToolTip } from '@elastic/eui';
import React, { useState } from 'react';
import { EyeIcon } from '../../../../../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../../../../../resources/icons-new/EyeSlashIcon';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H4, Button, H3, COLOR_PRIMARY_1 } from '../../../../../../App.style';
import {
  SftpTableData,
  PasswordContainer,
  SftpActionsButtonsContainer,
  SftpDeleteActionContainer,
  SftpPublicKeyContainer,
} from '../SftpPanel.style';
import { SftpConfiguration } from '../../../../../../../generated/tenants/Api';
import { SuccessIcon } from '../../../../../../../resources/icons-new/SuccessIcon';
import { XIcon } from '../../../../../../../resources/icons-new/XIcon';
import PublicKeyPreviewModal from './PublicKeyPreviewModal';
import { useTranslation } from 'react-i18next';

interface SftpConfigurationListRowProps {
  sftpConfig: SftpConfiguration;
  index: number;
  handleChangePasswordIndexVisible: (index: number) => void;
  isPasswordVisible: boolean;
  showDeleteSftpModal: (sftpConfig: SftpConfiguration) => void;
  handleEditClick: (sftpConfig: SftpConfiguration) => void;
  handleTestSftp: (id: string, index: number) => void;
  isTested: boolean;
  testState?: 'loading' | 'result';
  testResult?: boolean;
}

const SftpConfigurationListRow: React.FC<SftpConfigurationListRowProps> = ({
  sftpConfig,
  index,
  handleChangePasswordIndexVisible,
  isPasswordVisible,
  showDeleteSftpModal,
  handleEditClick,
  handleTestSftp,
  isTested,
  testState,
  testResult,
}) => {
  const { t } = useTranslation();
  const [isPublicKeyPreviewModalVisible, setIsPublicKeyPreviewModalVisible] =
    useState<boolean>(false);
  const showPublicKeyModal = () => setIsPublicKeyPreviewModalVisible(true);
  return (
    <tr key={`sftp-table-${index}`}>
      {sftpConfig.sftp_ssh_public_key && (
        <PublicKeyPreviewModal
          isVisible={isPublicKeyPreviewModalVisible}
          closeModal={() => setIsPublicKeyPreviewModalVisible(false)}
          publicKey={sftpConfig.sftp_ssh_public_key}
        />
      )}

      <SftpTableData>
        <H4>{sftpConfig.sftp_host}</H4>
      </SftpTableData>
      <SftpTableData>
        <H4>{sftpConfig.sftp_port}</H4>
      </SftpTableData>
      <SftpTableData>
        <H4>{sftpConfig.auth_method}</H4>
      </SftpTableData>
      <SftpTableData>
        <H4>{sftpConfig.sftp_username}</H4>
      </SftpTableData>

      {sftpConfig.auth_method === 'PASSWORD' ? (
        <SftpTableData onClick={() => handleChangePasswordIndexVisible(index)}>
          {isPasswordVisible ? (
            <PasswordContainer>
              <div>
                <EyeIcon
                  $pointer
                  style={{ width: 20, height: 20, marginTop: 3 }}
                />
              </div>
              {sftpConfig.sftp_password &&
              sftpConfig.sftp_password.length > 15 ? (
                <EuiToolTip position="top" content={sftpConfig.sftp_password}>
                  <H4>{sftpConfig.sftp_password.substring(0, 15)}</H4>
                </EuiToolTip>
              ) : (
                <H4>{sftpConfig.sftp_password}</H4>
              )}
            </PasswordContainer>
          ) : (
            <PasswordContainer>
              <EyeSlashIcon $pointer style={{ width: 20, height: 20 }} />
              <H4 style={{ marginTop: 5 }}>***** ***</H4>
            </PasswordContainer>
          )}
        </SftpTableData>
      ) : (
        <SftpTableData>
          <SftpPublicKeyContainer onClick={showPublicKeyModal}>
            <H4>
              {sftpConfig.sftp_ssh_public_key
                ? sftpConfig.sftp_ssh_public_key
                : 'undefined'}
            </H4>
          </SftpPublicKeyContainer>
        </SftpTableData>
      )}

      <SftpTableData>
        {isTested ? (
          <>
            {testState === 'loading' ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <H3>{t('settings.adminPanel.sftp.list.checking')}</H3>
                <EuiLoadingSpinner size={'m'} />
              </div>
            ) : (
              <>
                {testResult ? (
                  <div style={{ display: 'flex' }}>
                    <H3 $semibold style={{ color: COLOR_PRIMARY_1 }}>
                      {t('settings.adminPanel.sftp.list.correctly')}
                    </H3>
                    <SuccessIcon color={COLOR_PRIMARY_1} />
                  </div>
                ) : (
                  <div style={{ display: 'flex' }}>
                    <H3 $semibold style={{ color: '#983700' }}>
                      {t('settings.adminPanel.sftp.list.failed')}
                    </H3>
                    <XIcon color={'#983700'} />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <Button
            $size={'S'}
            $styleType="SECONDARY"
            onClick={() => handleTestSftp(sftpConfig.id, index)}
            disabled={testState === 'loading'}
          >
            {t('settings.adminPanel.sftp.list.test')}
          </Button>
        )}
      </SftpTableData>
      <SftpTableData>
        {testState !== 'loading' && (
          <SftpActionsButtonsContainer>
            <SftpDeleteActionContainer
              onClick={() => showDeleteSftpModal(sftpConfig)}
            >
              <H4>{t('common.delete')}</H4>
              <TrashIcon />
            </SftpDeleteActionContainer>
            <H4 onClick={() => handleEditClick(sftpConfig)}>
              {t('common.edit')}
            </H4>
          </SftpActionsButtonsContainer>
        )}
      </SftpTableData>
    </tr>
  );
};

export default SftpConfigurationListRow;

import React from 'react';
import {
  FakeLoadingImageContainer,
  FakeLoadingImage,
  FakeLoadingTextContainer,
} from '../../Analytics.style';
import { COLOR_NEUTRAL_80, H1 } from '../../../../App.style';
import { useTranslation } from 'react-i18next';

interface FakeLoadingChartProps {
  src: string;
  pieChart?: boolean;
}

export const FakeLoadingChart: React.FC<FakeLoadingChartProps> = ({
  src,
  pieChart,
}) => {
  const { t } = useTranslation();
  return (
    <FakeLoadingImageContainer>
      <FakeLoadingImage pieChart={pieChart} src={src} alt="loading-image" />
      <FakeLoadingTextContainer>
        <H1 style={{ color: COLOR_NEUTRAL_80 }}>
          {t('analytics.charts.noDataToShow')}
        </H1>
      </FakeLoadingTextContainer>
    </FakeLoadingImageContainer>
  );
};

export default FakeLoadingChart;

import React from 'react';
import UserPanelSidebar from './UserSettingsPanelSidebar';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  SettingsPanelContainer,
  UserSettingsContentContainer,
} from '../Settings.style';
import { useAppSelector } from '../../../../common/hooks';
import UserDetailed from '../../users/detailed/UserDetailed';
import { selectUser } from '../../session/api/sessionSlice';
import PasswordChange from './components/PasswordChange';
import Notifications from './components/Notifications';
import Language from './components/Language';

const UserPanel = () => {
  const user = useAppSelector(selectUser);

  return (
    <SettingsPanelContainer>
      <UserPanelSidebar />
      {user.value && (
        <UserSettingsContentContainer>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate replace to="/dashboard/user_settings/profile" />
              }
            />
            <Route
              path="/profile"
              element={
                <UserDetailed
                  userId={user.value.id}
                  isSuperAdmin={user.value.is_super_admin}
                />
              }
            />
            <Route path="/password" element={<PasswordChange />} />

            <Route path="/notifications" element={<Notifications />} />

            <Route path="/language" element={<Language />} />

            <Route
              path="*"
              element={<Navigate replace to="/user_settings/profile" />}
            />
          </Routes>
        </UserSettingsContentContainer>
      )}
    </SettingsPanelContainer>
  );
};

export default UserPanel;

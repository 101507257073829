import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ArrowRightLongIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="56"
    height="8"
    viewBox="0 0 56 8"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path d="M55.8536 4.35355C56.0488 4.15829 56.0488 3.84171 55.8536 3.64645L52.6716 0.464466C52.4763 0.269204 52.1597 0.269204 51.9645 0.464466C51.7692 0.659728 51.7692 0.976311 51.9645 1.17157L54.7929 4L51.9645 6.82843C51.7692 7.02369 51.7692 7.34027 51.9645 7.53553C52.1597 7.7308 52.4763 7.7308 52.6716 7.53553L55.8536 4.35355ZM0.5 4.5H55.5V3.5H0.5V4.5Z" />
  </StyledSvg>
);

import React from 'react';
import Modal from '../../../common/modals/Modal';
import { EuiModalBody } from '@elastic/eui';
import { H3 } from '../../App.style';
import { formatNumber } from './tabs/calls/CallsTable';
import { CampaignNumberRow, CampaignNumbersContainer } from './Campaigns.style';
import { useTranslation } from 'react-i18next';

interface CampaignNumbersModalProps {
  isVisible: boolean;
  closeModal: () => void;
  phoneNumbers?: string[];
}

const CampaignNumbersModal: React.FC<CampaignNumbersModalProps> = ({
  isVisible,
  closeModal,
  phoneNumbers,
}) => {
  const { t } = useTranslation();
  const body = (
    <EuiModalBody>
      <CampaignNumbersContainer>
        {phoneNumbers?.map((number, i) => {
          return (
            <CampaignNumberRow key={`campaign-number-${i}`}>
              <H3>{formatNumber(number)}</H3>
            </CampaignNumberRow>
          );
        })}
      </CampaignNumbersContainer>
    </EuiModalBody>
  );
  return (
    <Modal
      headerTitle={t('campaigns.numbersModal.title')}
      isVisible={isVisible}
      noConfirm
      onCancel={closeModal}
      children={body}
    />
  );
};

export default CampaignNumbersModal;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../processes/api/processSlice';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { CalendarsContainer } from './Calendars.style';

import CalendarsList from './CalendarsList';
import {
  checkCalendarCreate,
  checkCalendarDelete,
  checkCalendarUpdate,
  createCalendarAsyncThunk,
  deleteCalendarAsyncThunk,
  getCalendarsListAsyncThunk,
  selectCalendarsList,
} from './api/calendarSlice';
import { CalendarConfiguration } from '../../../../generated/tenants/Api';
import CalendarCreate from './CalendarCreate';
import CalendarDetailed from './CalendarDetailed';
import PresetDeleteModal from '../PresetDeleteModal';
import BackToList from '../shared/BackToList';
import usePermissions from '../../users/usePermissions';
import LoadingModal from '../../../../common/modals/LoadingModal';

const Calendars = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const [calendarsState, setCalendarsState] = useState<
    'list' | 'create' | 'detailed'
  >('list');
  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('process_campaign');

  const handleCreateCalendarClick = () => setCalendarsState('create');

  const handleDetailedViewClick = (calendarId: string) => {
    const calendarDetailed = apiCalendarsList.find(
      (calendar) => calendar.id === calendarId,
    );
    if (calendarDetailed) setSelectedCalendar(calendarDetailed);
    setCalendarsState('detailed');
  };

  const handleBackToList = () => {
    setCalendarsState('list');
  };

  useEffect(() => {
    dispatch(getCalendarsListAsyncThunk(strategy));
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);

  const apiCalendarsList = useAppSelector(selectCalendarsList);

  const apiProcesessList = useAppSelector(selectProcessesList);

  function findProcessNamesById(id: string): string[] {
    return apiProcesessList
      .filter((item) => item.calendar_configuration === id)
      .map((item) => item.name);
  }

  const [selectedCalendar, setSelectedCalendar] =
    useState<CalendarConfiguration>();

  const checkUpdate = useAppSelector(checkCalendarUpdate);
  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success' &&
      checkUpdate.value.calendar
    ) {
      setSelectedCalendar(checkUpdate.value.calendar);
    }
  }, [checkUpdate]);

  //delete
  const [isDeleteCalendarModalVisible, setIsDeleteCalendarModalVisible] =
    useState<boolean>(false);

  const openDeleteCalendarModal = () => setIsDeleteCalendarModalVisible(true);
  const closeDeleteCalendarModal = () => setIsDeleteCalendarModalVisible(false);

  const handleDeleteCalendar = (id: string) => {
    const selectedCalendar = apiCalendarsList.find(
      (calendar) => calendar.id === id,
    );
    if (selectedCalendar) setSelectedCalendar(selectedCalendar);
    openDeleteCalendarModal();
  };

  const deleteCalendarAfterModal = () => {
    if (selectedCalendar) {
      setIsLoadingVisible(true);
      dispatch(deleteCalendarAsyncThunk(selectedCalendar.id)).finally(() => {
        setIsLoadingVisible(false);
      });
      closeDeleteCalendarModal();
    } else {
      alert('error');
    }
  };

  const checkDeleteCalendar = useAppSelector(checkCalendarDelete);

  useEffect(() => {
    if (
      checkDeleteCalendar.value?.status === 'success' &&
      checkDeleteCalendar.state === 'idle'
    ) {
      dispatch(getCalendarsListAsyncThunk(strategy));
      setSelectedCalendar(undefined);
    }
  }, [checkDeleteCalendar]);

  //clone

  const handleDuplicateCalendar = (id: string) => {
    const selectedCalendar = apiCalendarsList.find(
      (calendar) => calendar.id === id,
    );
    if (selectedCalendar) {
      let count = 1;
      let newName = selectedCalendar.name;

      let nameExists = apiCalendarsList.some((item) => item.name === newName);

      while (nameExists) {
        newName =
          selectedCalendar.name + ' - copy' + (count > 1 ? `(${count})` : '');
        nameExists = apiCalendarsList.some((item) => item.name === newName);
        count++;
      }

      const copiedCallingPref = {
        ...selectedCalendar,
        name: newName,
      };

      setIsLoadingVisible(true);
      dispatch(createCalendarAsyncThunk(copiedCallingPref)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const checkCopy = useAppSelector(checkCalendarCreate);

  useEffect(() => {
    if (checkCopy.state === 'idle' && checkCopy.value?.status === 'success') {
      dispatch(getCalendarsListAsyncThunk(strategy));
      setSelectedCalendar(undefined);
    }
  }, [checkCopy]);

  //components logic
  const componentsMap = {
    create: (
      <CalendarCreate strategy={strategy} backToList={handleBackToList} />
    ),
    list: (
      <CalendarsList
        calendarsList={apiCalendarsList}
        createCalendar={handleCreateCalendarClick}
        goToDetailed={handleDetailedViewClick}
        findProcessNamesById={findProcessNamesById}
        handleDeleteCalendar={handleDeleteCalendar}
        handleDuplicateCalendar={handleDuplicateCalendar}
        hasPermission={hasPermission}
      />
    ),
    detailed: selectedCalendar && (
      <CalendarDetailed
        calendar={selectedCalendar}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesById}
        hasPermission={hasPermission}
      />
    ),
  };

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <CalendarsContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {selectedCalendar && (
        <PresetDeleteModal
          type={'calendar'}
          isVisible={isDeleteCalendarModalVisible}
          presetName={selectedCalendar.name}
          closeModal={closeDeleteCalendarModal}
          deletePreset={deleteCalendarAfterModal}
        />
      )}

      {calendarsState !== 'list' && (
        <BackToList handleBackToList={handleBackToList} />
      )}

      {componentsMap[calendarsState]}
    </CalendarsContainer>
  );
};

export default Calendars;

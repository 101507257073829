import {
  SftpPublicKeyRequest,
  SftpCredentialsCreateRequest,
  SftpPublicKey,
  TestSftpConfiguration,
} from '../../../../../../../generated/tenants/Api';
import { ApiError } from '../../../../../../../common/types';
import {
  Api,
  AuthMethodEnum,
  SftpConfiguration,
  SftpConfigurationRequest,
} from '../../../../../../../generated/tenants/Api';

const { api } = new Api();

export const authMethods: Array<AuthMethodEnum> = ['PASSWORD', 'RSA_KEY'];

export interface CheckeSftpResult {
  status: 'success' | 'fail';
  error?: ApiError<SftpConfiguration>;
}

export const getSftpConfigurationsList = () =>
  api.sftpConfigurationsList().then((_) => _.data);

export const getSftpConfigurationById = (id: string) =>
  api.sftpConfigurationsRetrieve(id).then((_) => _.data);

export const createSftpConfiguration = (
  data: SftpConfigurationRequest,
): Promise<CheckeSftpResult> =>
  api
    .sftpConfigurationsCreate(data)
    .then(() => {
      return { status: 'success' } as CheckeSftpResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export interface CheckTestSftpConfiguration {
  status: 'success' | 'fail';
  error?: ApiError<TestSftpConfiguration>;
}

export const testSftpConfiguration = (
  data: SftpConfigurationRequest,
): Promise<CheckTestSftpConfiguration> =>
  api
    .sftpConfigurationsTestCreate(data)
    .then((response) => {
      if (response.data.success === true) {
        return { status: 'success' } as CheckTestSftpConfiguration;
      } else {
        return {
          status: 'fail',
        } as CheckTestSftpConfiguration;
      }
    })
    .catch(() => {
      return { status: 'fail' };
    });

export const testSftpConfigurationById = (id: string) =>
  api.sftpConfigurationsTestRetrieve(id).then((_) => _.data);

export const updateSftpConfiguration = (
  id: string,
  data: SftpConfigurationRequest,
): Promise<CheckeSftpResult> =>
  api
    .sftpConfigurationsUpdate(id, data)
    .then(() => {
      return { status: 'success' } as CheckeSftpResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteSftpConfiguration = (
  id: string,
): Promise<CheckeSftpResult> =>
  api
    .sftpConfigurationsDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckeSftpResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

//sftpCredentials data

export const getSftpConfig = () => api.configSftpRetrieve().then((_) => _.data);

export interface CheckSftpPasswordResult {
  status: 'success' | 'fail';
}

export interface CheckSftpAddPublicKey {
  status: 'success' | 'fail';
  error?: ApiError<SftpPublicKey>;
}

//sftpCredentials password
export const checkSftpPassword = (): Promise<CheckSftpPasswordResult> =>
  api
    .sftpCredentialsRetrieve()
    .then(() => {
      return { status: 'success' } as CheckSftpPasswordResult;
    })
    .catch(() => {
      return { status: 'fail' };
    });

export const setSftpCredentialsPassword = (
  data: SftpCredentialsCreateRequest,
) => api.sftpCredentialsCreate(data).then((_) => _.data);

//sftpCredentials public keys
export const getPublicKeysList = () =>
  api.sftpPublicKeysList().then((_) => _.data);

export const addPublicKey = (
  data: SftpPublicKeyRequest,
): Promise<CheckSftpAddPublicKey> =>
  api
    .sftpPublicKeysCreate(data)
    .then(() => {
      return { status: 'success' } as CheckSftpAddPublicKey;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deletePublicKey = (id: string) =>
  api.sftpPublicKeysDestroy(id).then((_) => _.data);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { Token } from '../../../../../../generated/tenants/Api';

import PreviewAccessTokensModal from './modals/PreviewAccessTokensModal';
import {
  getAccessTokensListAsyncThunk,
  checkAccessTokensDelete,
  deleteAccessTokenAsyncThunk,
} from '../../../userSettings/api/userSettingsSlice';
import { SecurityContainer } from '../../../userSettings/components/Security.style';
import { Button, H1 } from '../../../../../App.style';
import AccessTokensTable from './AccessTokensTable';
import CreateAccessTokenModal from './modals/CreateAccessTokenModal';
import DeleteAccessTokenModal from './modals/DeleteAccessTokenModal';

const AccessTokens = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isAccessTokenModalVisible, setIsAccessTokenModalVisible] =
    useState<boolean>(false);
  const openAccessTokenPreview = () => setIsAccessTokenModalVisible(true);
  const closeAccessTokensModal = () => setIsAccessTokenModalVisible(false);

  let accessTokenModal;
  if (isAccessTokenModalVisible) {
    accessTokenModal = (
      <PreviewAccessTokensModal closeModal={closeAccessTokensModal} />
    );
  }

  //delete Token after modal
  const [isDeleteTokenModalVisible, setIsDeleteTokenModalVisible] =
    useState<boolean>(false);
  let deleteTokenModal;
  const [selectedToken, setSelectedToken] = useState<Token>();

  const handleDeleteClick = (token: Token) => {
    setIsDeleteTokenModalVisible(true);
    setSelectedToken(token);
  };

  const deleteToken = () => {
    if (selectedToken) dispatch(deleteAccessTokenAsyncThunk(selectedToken.id));
  };

  if (isDeleteTokenModalVisible && selectedToken) {
    deleteTokenModal = (
      <DeleteAccessTokenModal
        closeModal={() => setIsDeleteTokenModalVisible(false)}
        deleteToken={deleteToken}
      />
    );
  }

  //delete logic
  const checkDelete = useAppSelector(checkAccessTokensDelete);

  //if everything went OK, hide delete modal and refresh list
  useEffect(() => {
    if (
      checkDelete.state === 'idle' &&
      checkDelete.value?.status === 'success'
    ) {
      setIsDeleteTokenModalVisible(false);
      dispatch(getAccessTokensListAsyncThunk({}));
    }
  }, [checkDelete]);

  let createModal;
  const [isCreateTokenVisible, setIsCreateTokenVisible] =
    useState<boolean>(false);
  if (isCreateTokenVisible) {
    createModal = (
      <CreateAccessTokenModal
        closeModal={() => setIsCreateTokenVisible(false)}
        openAccessTokenPreview={openAccessTokenPreview}
      />
    );
  }

  return (
    <SecurityContainer>
      {createModal}
      {accessTokenModal}
      {deleteTokenModal}

      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <H1 $medium>{t('settings.adminPanel.accessTokens.title')}</H1>
        <Button $size="S" onClick={() => setIsCreateTokenVisible(true)}>
          {t('settings.adminPanel.accessTokens.buttonText')}
        </Button>
      </div>

      <AccessTokensTable handleDeleteClick={handleDeleteClick} />
    </SecurityContainer>
  );
};

export default AccessTokens;

import React from 'react';
import styled from 'styled-components';
import { Button, ButtonEmpty } from '../../../App.style';
import { useTranslation } from 'react-i18next';

export const EditPresetButtonsContainer = styled.div<{ noPosition?: boolean }>`
  position: absolute;

  right: ${({ noPosition }) => (noPosition ? '0px' : '61px')};
  top: ${({ noPosition }) => (noPosition ? '0px' : '29px')};
`;

interface EditPresetProps {
  edit: boolean;
  onCancel: () => void;
  saveChanges: () => void;
  setEdit: (value: React.SetStateAction<boolean>) => void;
  hasErrors?: boolean;
  noPosition?: boolean;
  disabled?: boolean;
}

const EditPreset: React.FC<EditPresetProps> = ({
  edit,
  onCancel,
  saveChanges,
  setEdit,
  hasErrors,
  noPosition,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <EditPresetButtonsContainer noPosition={noPosition}>
      {edit ? (
        <div style={{ display: 'flex', gap: 8 }}>
          <ButtonEmpty onClick={onCancel}>{t('common.cancel')}</ButtonEmpty>
          <Button $size={'L'} onClick={saveChanges} disabled={hasErrors}>
            {t('common.saveChanges')}
          </Button>
        </div>
      ) : (
        <Button
          $styleType="NORMAL"
          onClick={() => setEdit(true)}
          disabled={disabled}
        >
          {t('common.modify')}
        </Button>
      )}
    </EditPresetButtonsContainer>
  );
};

export default EditPreset;

import {
  EuiFlexItem,
  EuiFlexGroup,
  useEuiBackgroundColor,
  EuiButton,
  EuiTextColor,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import AddGenderTokenModal from './AddGenderTokenModal';
import { allBotTokens, DialogueToken } from './BotsTokens';
import './token.css';

interface TokenInputProps {
  tokenInputId: string;
  text: string;
  inputHook: (text: string) => void;
}

interface TokenDiv {
  value: string;
  div: string;
  displayText: string;
}

const TokenInput: React.FC<TokenInputProps> = ({
  tokenInputId,
  text,
  inputHook,
}) => {
  const [botStatementWithTokens, setBotStatementWithTokens] =
    useState<string>('');
  const [tokens, setTokens] = useState<TokenDiv[]>([]);

  //replaces bot placeholders and gender based statements with divs(tokens)
  useEffect(() => {
    const formatted = setTokensInText(text);
    setBotStatementWithTokens(formatted);
  }, []);

  //replaces %_DATA... with <div />
  const setTokensInText = (input: string) => {
    let tokensArr = allBotTokens.map((token) => {
      const tokenDiv = createTokenDiv(token);
      return {
        value: token.value,
        div: tokenDiv,
        displayText: token.displayText,
      };
    });

    tokensArr = tokensArr.reverse();

    let format = input;
    tokensArr.map((token) => {
      const re = new RegExp(token.value, 'g');
      format = format.replaceAll(re, token.div);
    });

    // replaces all texts in curly brackets with botDivs
    const botGenderBasedStatements = extractFromCurlyBrackets(format);
    if (botGenderBasedStatements)
      botGenderBasedStatements.forEach((botGenderStatement) => {
        const genderTokenDiv = createBotGenderBasedTokenDiv(botGenderStatement);
        format = format.replace(botGenderStatement, genderTokenDiv);
        const newGenderBasedToken: TokenDiv = {
          value: botGenderStatement,
          div: genderTokenDiv,
          displayText: botGenderStatement.slice(1, -1),
        };
        if (
          !tokensArr.find(
            (e) => e.displayText === newGenderBasedToken.displayText,
          )
        ) {
          tokensArr.push(newGenderBasedToken);
        }
      });

    //replace all texts in square brackets with callerDivs
    const callerGenderBasedStatements = extractFromSquareBrackets(format);
    if (callerGenderBasedStatements)
      callerGenderBasedStatements.forEach((callerGenderStatement) => {
        const genderTokenDiv = createCallerGenderBasedTokenDiv(
          callerGenderStatement,
        );
        format = format.replace(callerGenderStatement, genderTokenDiv);
        const newGenderBasedToken: TokenDiv = {
          value: callerGenderStatement,
          div: genderTokenDiv,
          displayText: callerGenderStatement.slice(1, -1),
        };
        if (
          !tokensArr.find(
            (e) => e.displayText === newGenderBasedToken.displayText,
          )
        ) {
          tokensArr.push(newGenderBasedToken);
        }
      });

    setTokens(tokensArr);
    return format.replace(/\s{2,}/g, ' ');
  };

  function extractFromCurlyBrackets(str: string) {
    const regex = /{([^}]*)}/g;
    const matches = str.match(regex);
    return matches;
  }

  function extractFromSquareBrackets(str: string) {
    const regex = /\[([^\]]*)\]/g;
    const matches = str.match(regex);
    return matches;
  }

  //formats <div...> into {%_DATA}
  const formatTextWithTokens = (divText: string) => {
    let format = divText;
    tokens.forEach((token, i) => {
      if (i < allBotTokens.length) {
        const re = new RegExp(token.div, 'g');
        format = format.replace(re, token.value);
      } else {
        format = format.replaceAll(token.div, token.value);
      }
    });

    return format.replace(/\s+/g, ' ');
  };

  const [caretPosition, setCaretPosition] = useState<number>(0);

  //function for list of tokens

  const handleSetCaretPosition = () => {
    const caretIndex = getCaretPos();
    setCaretPosition(caretIndex);
  };

  const handleAddGenderBasedToken = (str: string) => {
    let tokenDiv = '';
    if (str[0] === '{') {
      tokenDiv = createBotGenderBasedTokenDiv(str);
      const newGenderBasedToken: TokenDiv = {
        value: str,
        div: tokenDiv,
        displayText: str.slice(1, -1),
      };
      handleAddTokenToBotStatement(newGenderBasedToken);
    } else {
      tokenDiv = createCallerGenderBasedTokenDiv(str);
      const newGenderBasedToken: TokenDiv = {
        value: str,
        div: tokenDiv,
        displayText: str.slice(1, -1),
      };
      handleAddTokenToBotStatement(newGenderBasedToken);
    }
  };

  const handleAddTokenToBotStatement = (newToken: TokenDiv) => {
    const oldTokens = [...tokens];

    if (!oldTokens.find((e) => e.displayText === newToken.displayText)) {
      oldTokens.push(newToken);
    }

    let format = text;
    oldTokens.forEach((token, i) => {
      if (i < allBotTokens.length) {
        const re = new RegExp(token.value, 'g');
        format = format.replace(re, token.displayText);
      } else {
        format = format.replaceAll(token.value, token.displayText);
      }
    });

    format = `${format.slice(0, caretPosition)} ${
      newToken.displayText
    } ${format.slice(caretPosition)}`;

    oldTokens.forEach((token, i) => {
      if (i < allBotTokens.length) {
        const re = new RegExp(token.displayText, 'g');
        format = format.replace(re, token.value);
      } else {
        if (
          format[format.indexOf(token.displayText) - 1] !== '{' ||
          format[format.indexOf(token.displayText) - 1] !== '['
        )
          format = format.replaceAll(token.displayText, token.value);
      }
    });

    const formatted = setTokensInText(format);
    setBotStatementWithTokens(formatted);
  };

  const createTokenDiv = (token: DialogueToken) => {
    return `<div id="${token.id}" contenteditable="false" draggable="true" style="background: ${token.id}" class="token">${token.displayText}</div>`;
  };

  const createBotGenderBasedTokenDiv = (text: string) => {
    return `<div id="botTokenGender" contenteditable="false" draggable="true" style="background: #0074D9" class="token">${text.slice(
      1,
      -1,
    )}</div>`;
  };

  const createCallerGenderBasedTokenDiv = (text: string) => {
    return `<div id="callerTokenGender" contenteditable="false" draggable="true" style="background: #01FF70" class="token">${text.slice(
      1,
      -1,
    )}</div>`;
  };

  // gets innerHTML onBlur and saves it to state
  const handleSaveText = () => {
    const divText = document.getElementById(tokenInputId)?.innerHTML.toString();
    if (divText) {
      setBotStatementWithTokens(divText);
      setEmptyInputError(false);
    } else {
      setEmptyInputError(true);
    }
  };

  // saves to State and localStorage after every change of text in input
  useEffect(() => {
    const newText = formatTextWithTokens(botStatementWithTokens);
    if (newText.includes('<div>')) {
      alert('something went wrong');
    } else {
      inputHook(formatTextWithTokens(botStatementWithTokens));
    }
  }, [botStatementWithTokens]);

  //genderTokenModal logic
  const [isAddGenderTokenModalVisible, setIsAddGenderTokenModalVisible] =
    useState<boolean>(false);

  const closeAddGenderTokenmodal = () => {
    setIsAddGenderTokenModalVisible(false);
  };

  let addGenderTokenModal;
  if (isAddGenderTokenModalVisible) {
    addGenderTokenModal = (
      <AddGenderTokenModal
        closeModal={closeAddGenderTokenmodal}
        handleAddGenderBasedToken={handleAddGenderBasedToken}
      />
    );
  }

  // drag drop function
  // const tokenDivs: NodeListOf<HTMLDivElement> =
  //   document.querySelectorAll('.token');

  // tokenDivs.forEach((token) => {
  //   token.addEventListener('dragstart', (event: DragEvent) => {
  //     const textInDiv: string = (event.target as HTMLElement).innerText;
  //     event.dataTransfer?.setData('text/plain', textInDiv);
  //   });
  // });

  // const handleDragOver = (event: React.DragEvent<HTMLInputElement>) => {
  //   event.preventDefault();
  // };

  // //function adding token in cursor position after dragging (fix me pls)
  // const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
  //   event.preventDefault();
  //   const divText = event.dataTransfer.getData('text/plain');
  //   const copyToken = tokens.find((e) => e.displayText === divText);
  //   if (copyToken) handleAddTokenToBotStatement(copyToken);
  // };

  //cursor position
  function getCaretCharacterOffsetWithin(element: HTMLElement | null): number {
    let caretOffset = 0;
    if (element === null) {
      alert('error');
      return 0;
    } else {
      const doc = element.ownerDocument;
      const win = doc.defaultView as Window;
      let sel;
      if (win && typeof win.getSelection != 'undefined') {
        sel = win.getSelection();
        if (sel && sel.rangeCount > 0) {
          const selection = win.getSelection() as Selection;
          const range = selection.getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      }
      return caretOffset;
    }
  }

  function getCaretPos() {
    const el = document.getElementById(tokenInputId);
    return getCaretCharacterOffsetWithin(el);
  }

  const [emptyInputError, setEmptyInputError] = useState<boolean>(false);

  return (
    <>
      {/* {addTokenModal} */}
      {addGenderTokenModal}
      <EuiFlexGroup
        css={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '90%',
          maxWidth: '90%',
          borderRadius: 10,
          padding: 10,
        }}
      >
        <EuiFlexItem css={{ minWidth: '85%', gap: 10 }}>
          <EuiFlexItem
            id={tokenInputId}
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: botStatementWithTokens,
            }}
            onClick={handleSetCaretPosition}
            onBlur={handleSaveText}
            // onDragOver={handleDragOver}
            // onDrop={handleDrop}
            style={{
              whiteSpace: 'pre-wrap',
              border: emptyInputError ? '2px solid red' : '1px solid black',
              borderRadius: 5,
              display: 'inline-block',
              flexDirection: 'row',
              textAlign: 'center',
              background: useEuiBackgroundColor('plain'),
              minWidth: '100%',
              color: 'black',
              minHeight: 100,
              padding: 10,
            }}
          />
          {emptyInputError && (
            <EuiTextColor color={'danger'}>
              This field cant be empty
            </EuiTextColor>
          )}
        </EuiFlexItem>
        <EuiFlexItem
          css={{
            padding: 10,
            margin: 10,
            maxWidth: '25%',
            minHeight: 100,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <EuiButton
            fill
            css={{
              fontSize: 13,
              whiteSpace: 'normal',
              minHeight: 45,
              wordWrap: 'break-word',
            }}
          >
            Parametrized statement
          </EuiButton>
          <EuiButton
            css={{
              fontSize: 13,
              whiteSpace: 'normal',
              minHeight: 45,
              wordWrap: 'break-word',
            }}
            fill
            onClick={() => setIsAddGenderTokenModalVisible(true)}
          >
            Gender based statement
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default TokenInput;

import { EuiRadioGroup } from '@elastic/eui';
import React from 'react';
import { H2, H3, H4 } from '../../../../../App.style';
import { VindicationContentContainer } from '../../Vindication.style';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';
import {
  DialogueFinalSettingsContent,
  DialogueFinalSettingsContentRow,
  DialogueFinalSettingsRowContent,
  DialogueFinalSettingsRowTitle,
} from './DialogueFinalSettings.style';
import Checkbox from '../../../../../../common/inputs/Checkbox';

interface DialogueFinalSettingsProps {
  vindicationDialogue: BotConfigurationRequest;
}
const DialogueFinalSettings: React.FC<DialogueFinalSettingsProps> = () => {
  const callStartRadios = [
    {
      id: '1',
      label: 'odpowiedz rozmowcy na pierwszy komunikat bota',
    },
    {
      id: '2',
      label: 'rozmówca zidentyfikowany',
    },
  ];
  return (
    <VindicationContentContainer>
      <H2 $selected>Final settings // nie skończone</H2>
      <DialogueFinalSettingsContent>
        <H3>Wybierz zdarzenia zamykające rekord</H3>
        <DialogueFinalSettingsContentRow>
          <DialogueFinalSettingsRowTitle>
            <H4 $medium>Rozpoczęcie rozmowy</H4>
          </DialogueFinalSettingsRowTitle>
          <DialogueFinalSettingsRowContent>
            <EuiRadioGroup
              options={callStartRadios}
              idSelected={'1'}
              onChange={(id) => alert(id)}
              name="radio group"
            />
          </DialogueFinalSettingsRowContent>
        </DialogueFinalSettingsContentRow>

        <DialogueFinalSettingsContentRow>
          <DialogueFinalSettingsRowTitle>
            <H4 $medium>Weryfikacja</H4>
          </DialogueFinalSettingsRowTitle>
          <DialogueFinalSettingsRowContent>
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'niezweryfikowany'}
            />
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'brak odpowiedzi'}
            />
          </DialogueFinalSettingsRowContent>
        </DialogueFinalSettingsContentRow>

        <DialogueFinalSettingsContentRow>
          <DialogueFinalSettingsRowTitle>
            <H4 $medium>Payment</H4>
          </DialogueFinalSettingsRowTitle>
          <DialogueFinalSettingsRowContent>
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'podjęto próbę przekazania informacji o zaległości'}
            />
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'dłużnik zadeklarował płatność dokonaną lub przyszłą'}
            />
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'dłużnik nie zapłacił lub zapłacił niewystarczającą kwotę'}
            />
          </DialogueFinalSettingsRowContent>
        </DialogueFinalSettingsContentRow>

        <DialogueFinalSettingsContentRow>
          <DialogueFinalSettingsRowTitle>
            <H4 $medium>Negocjacje</H4>
          </DialogueFinalSettingsRowTitle>
          <DialogueFinalSettingsRowContent>
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'udane'}
            />
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'nie udane'}
            />
          </DialogueFinalSettingsRowContent>
        </DialogueFinalSettingsContentRow>

        <DialogueFinalSettingsContentRow>
          <DialogueFinalSettingsRowTitle>
            <H4 $medium>Dodatkowe</H4>
          </DialogueFinalSettingsRowTitle>
          <DialogueFinalSettingsRowContent>
            <Checkbox
              checked={true}
              onChange={() => alert('elo')}
              text={'smierc dluznika'}
            />
            <Checkbox checked={true} onChange={() => alert('elo')} text={'x'} />
            <Checkbox checked={true} onChange={() => alert('elo')} text={'x'} />
          </DialogueFinalSettingsRowContent>
        </DialogueFinalSettingsContentRow>
      </DialogueFinalSettingsContent>

      <DialogueFinalSettingsContent>
        <H2 $medium>CSV Template</H2>
      </DialogueFinalSettingsContent>
    </VindicationContentContainer>
  );
};

export default DialogueFinalSettings;

import moment from 'moment';
import { WorkingHours } from '../../../../../generated/tenants/Api';
import _ from 'lodash';

export const HOUR_IN_SECONDS = 3600;
export const EIGHT_O_CLOCK_IN_SECONDS = 28800;
export const SIXTEEN_O_CLOCK_IN_SECONDS = 57600;
export const MIDNIGHT_IN_SECONDS = 86400;

export interface WorkingHoursInterval {
  from: number;
  to: number;
}
export interface CalendarInterface {
  name:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';
  workingHours: Array<WorkingHoursInterval>;
}

export interface Interval {
  offset: number;
  duration: number;
}

export interface IntervalsError {
  day: number;
  intervals: {
    from: number;
    to: number;
  };
  type: 'overlap' | 'sameHour';
}

export interface ErrorInterface {
  day: number;
  interval: number;
}

export interface CopiedIntervals {
  cc?: WorkingHoursInterval[];
  inbound?: WorkingHoursInterval[];
  outbound?: WorkingHoursInterval[];
}

//function formating time
export function formatTime(time: number) {
  return time < 10 ? `0${time}` : `${time}`;
}

const mergeIntervals = (intervals: Interval[]) => {
  const newArr: Interval[] = [];
  let tempOffset: undefined | number = undefined;
  let tempDuration = 0;

  intervals.forEach((interval, i) => {
    if (i < intervals.length - 1) {
      const nextInterval = intervals[i + 1];

      if (interval.offset + interval.duration < nextInterval.offset) {
        newArr.push(extendInterval(tempOffset, tempDuration, interval));
        tempDuration = 0;
        tempOffset = undefined;
      } else {
        if (tempOffset === undefined) {
          tempOffset = interval.offset;
        }
        tempDuration = tempDuration + interval.duration;
      }
    } else {
      newArr.push(extendInterval(tempOffset, tempDuration, interval));
    }
  });
  return newArr;
};

const extendInterval = (
  tempOffset: number | undefined,
  tempDuration: number,
  interval: Interval,
) => {
  if (tempOffset !== undefined && tempDuration > 0) {
    return {
      offset: tempOffset,
      duration: interval.duration + tempDuration,
    };
  } else {
    return { offset: interval.offset, duration: interval.duration };
  }
};

//converting intervals into Array<WorkingHours>
export const convert2intervals = (
  calendar: CalendarInterface[],
): Array<WorkingHours> => {
  const arrayOfIntervals: Array<{ offset: number; duration: number }> = [];
  let dayValue = 0; //starting value, monday midnight is 0, tuesday midnight is 86400 ... += 86400
  calendar.forEach((day) => {
    day.workingHours.forEach((workTime) => {
      const duration = workTime.to - workTime.from;
      arrayOfIntervals.push({
        offset: workTime.from + dayValue,
        duration: duration,
      });
    });
    dayValue += 86400;
  });

  return mergeIntervals(arrayOfIntervals);
};

//gets intervals and returns the formated data for calendar_configuration

export const convert2calendar = (
  intervals: Array<{ offset: number; duration: number }>,
) => {
  const mondayWorkingHours: Array<WorkingHoursInterval> = [];
  const tuesdayWorkingHours: Array<WorkingHoursInterval> = [];
  const wednesdayWorkingHours: Array<WorkingHoursInterval> = [];
  const thursdayWorkingHours: Array<WorkingHoursInterval> = [];
  const fridayWorkingHours: Array<WorkingHoursInterval> = [];
  const saturdayWorkingHours: Array<WorkingHoursInterval> = [];
  const sundayWorkingHours: Array<WorkingHoursInterval> = [];
  const returnArr: Array<CalendarInterface> = [];

  const secondsInDay = 86400;

  const days = [
    mondayWorkingHours,
    tuesdayWorkingHours,
    wednesdayWorkingHours,
    thursdayWorkingHours,
    fridayWorkingHours,
    saturdayWorkingHours,
    sundayWorkingHours,
  ];

  intervals.forEach((interval) => {
    let duration = interval.duration;
    let offset = interval.offset;

    days.forEach((dayHours, dayIndex) => {
      if (
        interval.offset >= secondsInDay * dayIndex &&
        interval.offset + interval.duration <= secondsInDay * (dayIndex + 1)
      ) {
        dayHours.push({
          from: interval.offset - secondsInDay * dayIndex,
          to: interval.offset + interval.duration - secondsInDay * dayIndex,
        });
      } else if (
        offset >= secondsInDay * dayIndex &&
        offset < secondsInDay * (dayIndex + 1)
      ) {
        const from = offset - secondsInDay * dayIndex;
        if (from + duration > secondsInDay) {
          dayHours.push({ from: from, to: secondsInDay });
          const leftDuration = secondsInDay - from;
          duration = duration - leftDuration;
          offset = secondsInDay * (dayIndex + 1);
        } else {
          dayHours.push({ from: from, to: duration });
          duration = 0;
          offset = 0;
        }
      }
    });
  });

  returnArr.push({ name: 'Monday', workingHours: mondayWorkingHours });
  returnArr.push({ name: 'Tuesday', workingHours: tuesdayWorkingHours });
  returnArr.push({ name: 'Wednesday', workingHours: wednesdayWorkingHours });
  returnArr.push({ name: 'Thursday', workingHours: thursdayWorkingHours });
  returnArr.push({ name: 'Friday', workingHours: fridayWorkingHours });
  returnArr.push({ name: 'Saturday', workingHours: saturdayWorkingHours });
  returnArr.push({ name: 'Sunday', workingHours: sundayWorkingHours });
  return returnArr;
};

export const timezoneOptions = [
  'Europe/Warsaw',
  'Europe/Madrid',
  'America/Mexico_City',
];

//calendar preview

export interface WorkingHoursWithMoment {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export interface GroupedData {
  workingHours: WorkingHoursWithMoment[];
  days: string[];
}

export function convertIntervalToWorkingHours(interval: {
  from: number;
  to: number;
}): WorkingHoursWithMoment {
  const startDate = moment().startOf('day').add(interval.from, 'seconds');
  const endDate = moment().startOf('day').add(interval.to, 'seconds');

  return {
    startDate,
    endDate,
  };
}

export function groupByWorkingHours(data: CalendarInterface[]): GroupedData[] {
  const groups: GroupedData[] = [];

  for (const dayData of data) {
    const convertedWorkingHours = dayData.workingHours.map(
      convertIntervalToWorkingHours,
    );
    let found = false;

    for (const group of groups) {
      if (_.isEqual(group.workingHours, convertedWorkingHours)) {
        group.days.push(dayData.name);
        found = true;
        break;
      }
    }

    if (!found) {
      groups.push({
        workingHours: convertedWorkingHours,
        days: [dayData.name],
      });
    }
  }

  return groups;
}

import {
  CallCenterNonWorkingDaysConfiguration,
  CallCenterNonWorkingDaysConfigurationRequest,
} from './../../../../../../generated/tenants/Api';
import { ApiError } from './../../../../../../common/types';
import { Api } from '../../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckCallCenterDaysOffResult {
  status: 'success' | 'fail';
  error?: ApiError<CallCenterNonWorkingDaysConfiguration>;
  callCenterDaysOff?: CallCenterNonWorkingDaysConfiguration;
}

export const getCallCenterDaysOffList = (id: string) =>
  api
    .strategiesCallCenterNonWorkingDaysConfigurationList(id)
    .then((_) => _.data);

export const createCallCenterDaysOff = (
  data: CallCenterNonWorkingDaysConfigurationRequest,
): Promise<CheckCallCenterDaysOffResult> =>
  api
    .callCenterNonWorkingDaysConfigurationCreate(data)
    .then(() => {
      return { status: 'success' } as CheckCallCenterDaysOffResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const getCallCenterDaysOffDetailed = (id: string) =>
  api.callCenterNonWorkingDaysConfigurationRetrieve(id).then((_) => _.data);

export const updateCallCenterDaysOff = (
  id: string,
  data: CallCenterNonWorkingDaysConfigurationRequest,
): Promise<CheckCallCenterDaysOffResult> =>
  api
    .callCenterNonWorkingDaysConfigurationUpdate(id, data)
    .then(() => {
      return {
        status: 'success',
        callCenterDaysOff: data,
      } as CheckCallCenterDaysOffResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteCallCenterDaysOff = (
  id: string,
): Promise<CheckCallCenterDaysOffResult> =>
  api
    .callCenterNonWorkingDaysConfigurationDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckCallCenterDaysOffResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

import {
  EuiAccordion,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSelect,
  EuiStepsHorizontal,
  EuiText,
} from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VindicationBackToPreset } from '../../BotsStartPanel';
import TokenInput from '../tokenInputComponents/TokenInput';

export interface BotStrategyConfigVinIdentification {
  IMPORT_DEBTOR_STATUS_SKILL: boolean;
  IMPORT_NEGOTIATION_SKILL: boolean;
  BRANCHES: { IDENTIFICATION: string };
}

export interface GenerationsConfigVinIdentification {
  'REQ-INF-may_i_speak_with_debtor_now-1-1': string;
  'REQ-INF-can_you_hand_over-1-1': string;
  'INF-STA-WAITING_A_MOMENT-1-1': string;
  'FEE-REF-odmowa_podania_danej/may_i_speak_with_debtor_now/-1-1': string;
  'FEE-REF-rodo/may_i_speak_with_debtor_now/-1-1': string;
  'FEE-REF-podstawa_prawna/may_i_speak_with_debtor_now/-1-1': string;
}

//questions for form
export const SUPPORTED_MODULES = [
  { value: 'INFORM_PHONENUMBER', text: 'INFORM_PHONENUMBER' },
  { value: 'CALLBACK_NUMBER', text: 'CALLBACK_NUMBER' },
  { value: 'CALLBACK_DATE_TIME', text: 'CALLBACK_DATE_TIME' },
  { value: 'GOODBYE', text: 'GOODBYE' },
  { value: 'MAY_WE_CONTINUE', text: 'MAY_WE_CONTINUE' },
];

const VinIdentification = () => {
  const navigate = useNavigate();

  //state of accordions
  const [showAccordion, setShowAccordion] = useState<{
    startingConversation: boolean;
    handlingRefusal: boolean;
    handlingUnreliable: boolean;
  }>({
    startingConversation: true,
    handlingRefusal: false,
    handlingUnreliable: false,
  });

  const [
    vinIdentificationBotStrategyConfig,
    setVinIdenfitifacionBotStrategyConfig,
  ] = useState<BotStrategyConfigVinIdentification>();
  const [
    vinIdentificationGenerationsConfig,
    setVinIdentificationGenerationsConfig,
  ] = useState<GenerationsConfigVinIdentification>();

  //if there is a vinIdentificationGenerationsConfig in localstorage -> load it, if not -> load standard questions
  useEffect(() => {
    localStorage.setItem('lastVisitedStep', '1');
    const storageGenerationsConfig = localStorage.getItem(
      'vinIdentificationGenerationsConfig',
    );
    if (storageGenerationsConfig != null) {
      setVinIdentificationGenerationsConfig(
        JSON.parse(storageGenerationsConfig),
      );
    } else {
      setVinIdentificationGenerationsConfig({
        'REQ-INF-may_i_speak_with_debtor_now-1-1':
          'Dzień dobry, tu Wirtualny Agent. Reprezentuję %_COMPANYNAME_ACC. Nasza rozmowa jest nagrywana. Czy mogę rozmawiać z %_ADDRESS_INS %_NAME_INS %_SURNAME_INS ?',
        'REQ-INF-can_you_hand_over-1-1':
          'czy mógłbym prosić o przekazanie słuchawki?',
        'INF-STA-WAITING_A_MOMENT-1-1': 'oczywiście, zaczekam',
        'FEE-REF-odmowa_podania_danej/may_i_speak_with_debtor_now/-1-1':
          'Rozumiem, w takm razie bardzo proszę o pilny kontakt',
        'FEE-REF-rodo/may_i_speak_with_debtor_now/-1-1':
          'Rozumiem, w takm razie bardzo proszę o pilny kontakt',
        'FEE-REF-podstawa_prawna/may_i_speak_with_debtor_now/-1-1':
          'Rozumiem, w takm razie bardzo proszę o pilny kontakt',
      });
    }

    const storageBotStrategyConfig = localStorage.getItem(
      'vinIdentificationBotStrategyConfig',
    );
    if (storageBotStrategyConfig !== null) {
      setVinIdenfitifacionBotStrategyConfig(
        JSON.parse(storageBotStrategyConfig),
      );
    } else {
      setVinIdenfitifacionBotStrategyConfig({
        IMPORT_DEBTOR_STATUS_SKILL: true,
        IMPORT_NEGOTIATION_SKILL: true,
        BRANCHES: { IDENTIFICATION: SUPPORTED_MODULES[0].value },
      });
    }
  }, []);

  //if user is changing something, everything is saved in localStorage
  useEffect(() => {
    localStorage.setItem(
      'vinIdentificationGenerationsConfig',
      JSON.stringify(vinIdentificationGenerationsConfig),
    );
  }, [vinIdentificationGenerationsConfig]);
  useEffect(() => {
    localStorage.setItem(
      'vinIdentificationBotStrategyConfig',
      JSON.stringify(vinIdentificationBotStrategyConfig),
    );
  }, [vinIdentificationBotStrategyConfig]);

  //after clicking discard, redirect to bots/new and delete all data from localStorage
  const discardBotConfiguration = () => {
    navigate('/dashboard/bots');
  };

  //logic of horizontalSteps
  let steps: string[] = [];
  if (localStorage.getItem('vinCompletedSteps') !== null) {
    steps = JSON.parse(localStorage.getItem('vinCompletedSteps') as string);
  }
  const horizontalSteps = [
    {
      title: 'Identification',
      status: 'current',
      onClick: () => {
        navigate('/dashboard/bots/new/1/1');
      },
    },
    {
      title: 'Verification',
      status: steps.includes('2') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/2');
      },
    },
    {
      title: 'Payment status',
      status: steps.includes('3') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/3');
      },
    },
    {
      title: 'Negotiations',
      status: steps.includes('4') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/4');
      },
    },
    {
      title: 'Auxiliary modules',
      status: steps.includes('5') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/5');
      },
    },
    {
      title: 'Summary',
      status: steps.includes('6') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/6');
      },
    },
  ];

  //after clicking next, adds 1 to completed steps, and redirects to next step
  const handleSubmitIdentificationClick = () => {
    let arr: string[];
    const completedSteps = localStorage.getItem('vinCompletedSteps');
    if (completedSteps !== null && !completedSteps?.includes('1')) {
      arr = JSON.parse(completedSteps);
      arr.push('1');
      arr.sort();
      localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
    } else if (completedSteps && completedSteps.length > 0) {
      console.log('this step was already added');
    } else {
      arr = ['1'];
      localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
    }
    navigate('/dashboard/bots/new/1/2');
  };

  //back to presets button logic
  const [backToPresetsButtonVisible, setBackToPresetsButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    const botConfigurationType = localStorage.getItem('botConfigurationType');
    if (botConfigurationType !== null && botConfigurationType === 'new') {
      setBackToPresetsButtonVisible(true);
    } else {
      setBackToPresetsButtonVisible(false);
    }
  }, []);
  return (
    <>
      {vinIdentificationGenerationsConfig &&
        vinIdentificationBotStrategyConfig && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              flexDirection: 'column',
              margin: 10,
              padding: 10,

              overflow: 'auto',
              marginTop: 80,
            }}
          >
            <EuiFlexItem
              css={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
            >
              {backToPresetsButtonVisible && <VindicationBackToPreset />}
              <div>
                <EuiButton fill onClick={discardBotConfiguration}>
                  Discard
                </EuiButton>
              </div>
            </EuiFlexItem>
            <EuiStepsHorizontal
              steps={horizontalSteps as Omit<EuiStepHorizontalProps, 'step'>[]}
            />

            <EuiFlexItem>
              <EuiText css={{ fontSize: 22 }}>
                MODUŁ IDENTYFIKACJI ROZPOCZYNA ROZMOWĘ WINDYKACYJNĄ
              </EuiText>
              <EuiText>
                dialog zaczyna się z komunikatem powitalnym. informacją, że
                rozmowa jest nagrywana i pytaniem o możliwość rozmowy z
                dłużnikiem. udana identyfikacja automatycznie aktywuje moduł
                weryfikacji, natomiast w przypadku, gdy rozmowa z dłużnikiem nie
                jest możliwa, można przekierować dialog do modułów pomocniczych.
              </EuiText>
            </EuiFlexItem>
            <div style={{ marginTop: 30 }}>
              <EuiFlexItem>
                {/* starting conversation */}
                <EuiAccordion
                  id="start"
                  buttonContent="Starting a conversation"
                  forceState={
                    showAccordion.startingConversation ? 'open' : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      startingConversation: !showAccordion.startingConversation,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          width: '90%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should be the first statement of the bot that
                          starts the conversation?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinIdentification1'}
                          text={
                            vinIdentificationGenerationsConfig[
                              'REQ-INF-may_i_speak_with_debtor_now-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinIdentificationGenerationsConfig({
                              ...vinIdentificationGenerationsConfig,
                              'REQ-INF-may_i_speak_with_debtor_now-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        Information about the reaction to the answers
                      </EuiText>
                      <EuiText>
                        • The debtor confirms that he is on the phone - Redirect
                        to{' '}
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          Verification Module
                        </EuiText>
                      </EuiText>
                      <EuiFlexItem
                        css={{
                          width: '90%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText>
                          • The interlocutor informs that he is not a debtor
                          but, for example, a spouse, parent
                        </EuiText>

                        <TokenInput
                          tokenInputId="vinIdentification2"
                          text={
                            vinIdentificationGenerationsConfig[
                              'REQ-INF-can_you_hand_over-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinIdentificationGenerationsConfig({
                              ...vinIdentificationGenerationsConfig,
                              'REQ-INF-can_you_hand_over-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '90%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText>
                          • The interlocutor informs that he will hand over the
                          phone to the debtor in a moment
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinIdentification3'}
                          text={
                            vinIdentificationGenerationsConfig[
                              'INF-STA-WAITING_A_MOMENT-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinIdentificationGenerationsConfig({
                              ...vinIdentificationGenerationsConfig,
                              'INF-STA-WAITING_A_MOMENT-1-1': text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem css={{ alignItems: 'center', gap: 20 }}>
                        <EuiText>
                          • The interlocutor informs that it is not possible to
                          talk to the debtor
                        </EuiText>

                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinIdentificationBotStrategyConfig.BRANCHES
                              .IDENTIFICATION
                          }
                          onChange={(e) =>
                            setVinIdenfitifacionBotStrategyConfig({
                              ...vinIdentificationBotStrategyConfig,
                              BRANCHES: { IDENTIFICATION: e.target.value },
                            })
                          }
                        />
                      </EuiFlexItem>

                      <EuiFlexItem>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              startingConversation: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem>
                {/* Handling refusal accordion */}
                <EuiAccordion
                  id="start"
                  // arrowDisplay="right"
                  buttonContent="Handling of refusal to provide data"
                  forceState={showAccordion.handlingRefusal ? 'open' : 'closed'}
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      handlingRefusal: !showAccordion.handlingRefusal,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EuiFlexItem
                        css={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '90%',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          Information about the reaction to the answers
                        </EuiText>
                        <EuiText>
                          • Refusal to provide data. The person on the phone
                          refuses to provide the requested information. → What
                          should the bot say when the interlocutor refuses to
                          provide the required information
                        </EuiText>
                        <TokenInput
                          tokenInputId="vinIdentification4"
                          text={
                            vinIdentificationGenerationsConfig[
                              'FEE-REF-odmowa_podania_danej/may_i_speak_with_debtor_now/-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinIdentificationGenerationsConfig({
                              ...vinIdentificationGenerationsConfig,
                              'FEE-REF-odmowa_podania_danej/may_i_speak_with_debtor_now/-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem css={{ alignItems: 'center' }}>
                        <EuiText>
                          • selection of one module activated after this
                          statement, will be done later
                        </EuiText>
                        <div>
                          <EuiSelect />
                        </div>
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '90%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText>
                          • What should the bot say when the interlocutor refers
                          to the RODO?
                        </EuiText>
                        <TokenInput
                          tokenInputId="vinIdentification5"
                          text={
                            vinIdentificationGenerationsConfig[
                              'FEE-REF-rodo/may_i_speak_with_debtor_now/-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinIdentificationGenerationsConfig({
                              ...vinIdentificationGenerationsConfig,
                              'FEE-REF-rodo/may_i_speak_with_debtor_now/-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>

                      <EuiFlexItem
                        css={{
                          width: '90%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText>
                          • What should the bot say when the interlocutor asks
                          for the legal basis of the phone?
                        </EuiText>

                        <TokenInput
                          tokenInputId="vinIdentification5"
                          text={
                            vinIdentificationGenerationsConfig[
                              'FEE-REF-podstawa_prawna/may_i_speak_with_debtor_now/-1-1'
                            ]
                          }
                          inputHook={(text) => {
                            setVinIdentificationGenerationsConfig({
                              ...vinIdentificationGenerationsConfig,
                              'FEE-REF-podstawa_prawna/may_i_speak_with_debtor_now/-1-1':
                                text,
                            });
                          }}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              handlingRefusal: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem>
                {/* handling uncertain answers */}
                <EuiAccordion
                  id="start"
                  buttonContent="Handling of unreliable user responses"
                  forceState={
                    showAccordion.handlingUnreliable ? 'open' : 'closed'
                  }
                  onToggle={() =>
                    setShowAccordion({
                      ...showAccordion,
                      handlingUnreliable: !showAccordion.handlingUnreliable,
                    })
                  }
                >
                  <EuiPanel color="subdued">
                    <EuiFlexGroup
                      css={{ flexDirection: 'column', gap: 20, padding: 20 }}
                    >
                      We're skipping this part of config for now
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              handlingUnreliable: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiAccordion>
              </EuiFlexItem>
            </div>
            <div>
              <EuiButton onClick={handleSubmitIdentificationClick} fill>
                Next
              </EuiButton>
            </div>
          </div>
        )}
    </>
  );
};

export default VinIdentification;

import React from 'react';
import { TenantPhoneNumber } from '../../../../../../../generated/tenants/Api';
import Modal from '../../../../../../../common/modals/Modal';
import { EuiModalBody } from '@elastic/eui';
import { H3, H5 } from '../../../../../../App.style';
import moment from 'moment';

interface DetailedTenantNumberModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedNumber: TenantPhoneNumber;
}

const DetailedTenantNumberModal: React.FC<DetailedTenantNumberModalProps> = ({
  isVisible,
  closeModal,
  selectedNumber,
}) => {
  const body = (
    <EuiModalBody>
      <H3 style={{ marginBottom: 16 }}>Number is associated with:</H3>
      <H3 $semibold>Process</H3>
      {selectedNumber.process_list.length === 0
        ? 'No processes'
        : selectedNumber.process_list.map((process) => {
            return <H3>{process.name}</H3>;
          })}

      <H3 $semibold style={{ marginTop: 16 }}>
        Campaigns
      </H3>
      {selectedNumber.campaign_list.length === 0
        ? 'No campaigns'
        : selectedNumber.campaign_list.map((campaign) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <H5>{campaign.process}</H5>
                <H3>
                  Start:{' '}
                  <b>{moment(campaign.start_date).format('DD.MM.YYYY')}</b>
                </H3>
                <H3>
                  End: <b>{moment(campaign.end_date).format('DD.MM.YYYY')}</b>
                </H3>
              </div>
            );
          })}
    </EuiModalBody>
  );
  return (
    <Modal
      isVisible={isVisible}
      headerTitle="Description"
      onCancel={closeModal}
      noConfirm
      children={body}
    />
  );
};

export default DetailedTenantNumberModal;

import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '../../../../../../../resources/icons-new/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../../../../resources/icons-new/ChevronUpIcon';
import { H2, H4, Button } from '../../../../../../App.style';
import {
  AccordionHeader,
  AccordionContent,
  SftpTableContainer,
  SftpTab,
  SftpTableHeader,
  SftpButtonContainer,
} from '../SftpPanel.style';
import {
  SftpConfig,
  SftpConfiguration,
} from '../../../../../../../generated/tenants/Api';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  checkSftpByIdTest,
  selectSftpConfigurationsList,
  testSftpConfigurationByIdAsyncThunk,
} from '../api/sftpConfigSlice';
import SftpConfigurationFormModal from './SftpConfigurationFormModal';
import DeleteSftpConfigurationModal from './DeleteSftpConfigurationModal';
import SftpConfigurationListRow from './SftpConfigurationListRow';
import { useTranslation } from 'react-i18next';

interface SftpConfigurationsListAccordionProps {
  handleHeaderClick: (section: 'list' | 'upload') => void;
  isOpen: boolean;
  sftpConfig: SftpConfig;
}

const SftpConfigurationsListAccordion: React.FC<
  SftpConfigurationsListAccordionProps
> = ({ handleHeaderClick, isOpen, sftpConfig }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const apiSftpConfigurationsList = useAppSelector(
    selectSftpConfigurationsList,
  );

  const [passwordIndexVisible, setPasswordIndexVisible] = useState<number>();

  const handleChangePasswordIndexVisible = (index: number) => {
    passwordIndexVisible === index
      ? setPasswordIndexVisible(undefined)
      : setPasswordIndexVisible(index);
  };

  const [
    isSftpConfigurationFormModalVisible,
    setIsSftpConfigurationFormModalVisible,
  ] = useState<boolean>(false);

  const [
    isDeleteSftpConfigurationModalVisible,
    setIsDeleteSftpConfigurationModalVisible,
  ] = useState<boolean>(false);

  const [selectedSftpConfiguration, setSelectedSftpConfiguration] =
    useState<SftpConfiguration>();

  const clearSelectedSftp = () => setSelectedSftpConfiguration(undefined);

  const showDeleteSftpModal = (sftpConfig: SftpConfiguration) => {
    setSelectedSftpConfiguration(sftpConfig);
    setIsDeleteSftpConfigurationModalVisible(true);
  };

  const handleEditClick = (sftpConfig: SftpConfiguration) => {
    setSelectedSftpConfiguration(sftpConfig);
    setIsSftpConfigurationFormModalVisible(true);
  };

  //test
  const handleTestSftp = (id: string, index: number) => {
    dispatch(testSftpConfigurationByIdAsyncThunk(id));
    setTestSftpIndex(index);
    setTestState('loading');
  };

  const testById = useAppSelector(checkSftpByIdTest);
  useEffect(() => {
    if (testById.state === 'idle') {
      setTestState('result');

      setTimeout(() => {
        setTestState(undefined);
        setTestSftpIndex(undefined);
      }, 10000);
    }
  }, [testById]);

  const [testSftpIndex, setTestSftpIndex] = useState<number>();
  const [testState, setTestState] = useState<'loading' | 'result'>();

  return (
    <>
      <SftpConfigurationFormModal
        isVisible={isSftpConfigurationFormModalVisible}
        closeModal={() => setIsSftpConfigurationFormModalVisible(false)}
        lektaPublicKey={sftpConfig.ssh_public_key}
        sftpConfigurationEdit={selectedSftpConfiguration}
        clearSelectedSftp={clearSelectedSftp}
      />

      {selectedSftpConfiguration && (
        <DeleteSftpConfigurationModal
          isVisible={isDeleteSftpConfigurationModalVisible}
          closeModal={() => setIsDeleteSftpConfigurationModalVisible(false)}
          sftpConfig={selectedSftpConfiguration}
          clearSelectedSftp={clearSelectedSftp}
        />
      )}

      <AccordionHeader
        style={{ marginTop: 20 }}
        onClick={() => handleHeaderClick('list')}
      >
        <H2 $selected>{t('settings.adminPanel.sftp.list.title')}</H2>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        <SftpTableContainer>
          <SftpTab>
            <thead>
              <tr>
                <SftpTableHeader>
                  <H4 $grey>{t('settings.adminPanel.sftp.list.host')} </H4>
                </SftpTableHeader>
                <SftpTableHeader>
                  <H4 $grey>{t('settings.adminPanel.sftp.list.port')}</H4>
                </SftpTableHeader>
                <SftpTableHeader>
                  <H4 $grey>
                    {t('settings.adminPanel.sftp.list.authorization')}
                  </H4>
                </SftpTableHeader>
                <SftpTableHeader>
                  <H4 $grey>{t('settings.adminPanel.sftp.list.username')}</H4>
                </SftpTableHeader>
                <SftpTableHeader>
                  <H4 $grey>{t('settings.adminPanel.sftp.list.details')}</H4>
                </SftpTableHeader>
                <SftpTableHeader></SftpTableHeader>
                <SftpTableHeader></SftpTableHeader>
              </tr>
            </thead>
            <tbody>
              {apiSftpConfigurationsList.map((sftpConfig, i) => {
                return (
                  <SftpConfigurationListRow
                    key={`sftp-config-list-item-${i}`}
                    sftpConfig={sftpConfig}
                    index={i}
                    handleChangePasswordIndexVisible={
                      handleChangePasswordIndexVisible
                    }
                    isPasswordVisible={passwordIndexVisible === i}
                    showDeleteSftpModal={showDeleteSftpModal}
                    handleEditClick={handleEditClick}
                    handleTestSftp={handleTestSftp}
                    isTested={testSftpIndex === i}
                    testState={testState}
                    testResult={testById.value?.success}
                  />
                );
              })}
              <tr>
                <SftpButtonContainer>
                  <Button
                    $size={'M'}
                    onClick={() => setIsSftpConfigurationFormModalVisible(true)}
                  >
                    {t('settings.adminPanel.sftp.list.addNew')}
                  </Button>
                </SftpButtonContainer>
              </tr>
            </tbody>
          </SftpTab>
        </SftpTableContainer>
      </AccordionContent>
    </>
  );
};

export default SftpConfigurationsListAccordion;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  BotConfiguration,
  BotConfigurationRequest,
  CustomFaas,
  CustomFaasRequest,
} from '../../../../../generated/tenants/Api';
import {
  CheckCustomFaasResult,
  CheckDialogueResult,
  createCustomFaas,
  createDialogue,
  deleteCustomFaas,
  deleteDialogue,
  getCustomFasesList,
  getDialogueDetailed,
  getDialoguesList,
  updateCustomFaas,
  updateDialogue,
} from './dialoguesApi';
import { RootState } from '../../../../store';

export interface DialoguesState {
  dialogues: {
    list: {
      state: 'pending' | 'idle';
      value: BotConfiguration[];
    };
    detailed: {
      state: 'pending' | 'idle';
      value?: BotConfiguration;
    };
    create: {
      state: 'pending' | 'idle';
      value?: CheckDialogueResult;
    };
    update: {
      state: 'pending' | 'idle';
      value?: CheckDialogueResult;
    };
    delete: {
      state: 'pending' | 'idle';
      value?: CheckDialogueResult;
    };
  };
  customFaas: {
    list: {
      state: 'pending' | 'idle';
      value: CustomFaas[];
    };
    create: {
      state: 'pending' | 'idle';
      value?: CheckCustomFaasResult;
    };
    update: {
      state: 'pending' | 'idle';
      value?: CheckCustomFaasResult;
    };
    delete: {
      state: 'pending' | 'idle';
    };
  };
}

const initialState: DialoguesState = {
  dialogues: {
    list: { state: 'pending', value: [] },
    detailed: { state: 'pending' },
    create: { state: 'pending' },
    update: { state: 'pending' },
    delete: { state: 'pending' },
  },
  customFaas: {
    list: { state: 'pending', value: [] },
    create: { state: 'pending' },
    update: { state: 'pending' },
    delete: { state: 'pending' },
  },
};

//dialogues
export const getDialoguesListAsyncThunk = createAsyncThunk(
  'dialogues/bots/list',
  (strategy: string) => getDialoguesList(strategy),
);

export const getDialogueDetailedAsyncThunk = createAsyncThunk(
  'dialogues/bots/detailed',
  (id: string) => getDialogueDetailed(id),
);

export const createDialogueAsyncThunk = createAsyncThunk(
  'dialogues/bots/create',
  (data: BotConfigurationRequest) => createDialogue(data),
);

export const updateDialogueAsyncThunk = createAsyncThunk(
  'dialogues/bots/update',
  (args: { id: string; data: BotConfigurationRequest }) =>
    updateDialogue(args.id, args.data),
);

export const deleteDialogueAsyncThunk = createAsyncThunk(
  'dialogues/bots/delete',
  (id: string) => deleteDialogue(id),
);

//custom faases

export const getCustomFaasesListAsyncThunk = createAsyncThunk(
  'dialogues/customFaas/list',
  (strategy: string) => getCustomFasesList(strategy),
);

export const createCustomFaasAsyncThunk = createAsyncThunk(
  'dialogues/customFaas/create',
  (data: CustomFaasRequest) => createCustomFaas(data),
);

export const updateCustomFaasAsyncThunk = createAsyncThunk(
  'dialogues/customFaas/update',
  (args: { id: string; data: CustomFaasRequest }) =>
    updateCustomFaas(args.id, args.data),
);

export const deleteCustomFaasAsyncThunk = createAsyncThunk(
  'dialogues/customFaas/delete',
  (id: string) => deleteCustomFaas(id),
);

export const dialoguesSlice = createSlice({
  name: 'dialogues',
  initialState,
  reducers: {
    clearCreateDialogue: (state) => {
      state.dialogues.create = { state: 'pending' };
    },
    clearUpdateDialogue: (state) => {
      state.dialogues.update = { state: 'pending' };
    },
    clearCreateCustomFaas: (state) => {
      state.customFaas.create = { state: 'pending' };
    },
    clearUpdateCustomFaas: (state) => {
      state.customFaas.update = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder
      //dialogues
      .addCase(getDialoguesListAsyncThunk.pending, (state) => {
        state.dialogues.list.state = 'pending';
      })
      .addCase(getDialoguesListAsyncThunk.fulfilled, (state, action) => {
        state.dialogues.list.state = 'idle';
        state.dialogues.list.value = action.payload;
      })
      .addCase(getDialoguesListAsyncThunk.rejected, (state) => {
        state.dialogues.list.state = 'idle';
      })

      .addCase(getDialogueDetailedAsyncThunk.pending, (state) => {
        state.dialogues.detailed.state = 'pending';
      })
      .addCase(getDialogueDetailedAsyncThunk.fulfilled, (state, action) => {
        state.dialogues.detailed.state = 'idle';
        state.dialogues.detailed.value = action.payload;
      })
      .addCase(getDialogueDetailedAsyncThunk.rejected, (state) => {
        state.dialogues.detailed.state = 'idle';
      })

      .addCase(createDialogueAsyncThunk.pending, (state) => {
        state.dialogues.create.state = 'pending';
      })
      .addCase(createDialogueAsyncThunk.fulfilled, (state, action) => {
        state.dialogues.create.state = 'idle';
        state.dialogues.create.value = action.payload;
      })
      .addCase(createDialogueAsyncThunk.rejected, (state) => {
        state.dialogues.create.state = 'idle';
      })

      .addCase(updateDialogueAsyncThunk.pending, (state) => {
        state.dialogues.update.state = 'pending';
      })
      .addCase(updateDialogueAsyncThunk.fulfilled, (state, action) => {
        state.dialogues.update.state = 'idle';
        state.dialogues.update.value = action.payload;
      })
      .addCase(updateDialogueAsyncThunk.rejected, (state) => {
        state.dialogues.update.state = 'idle';
      })

      .addCase(deleteDialogueAsyncThunk.pending, (state) => {
        state.dialogues.delete.state = 'pending';
      })
      .addCase(deleteDialogueAsyncThunk.fulfilled, (state, action) => {
        state.dialogues.delete.state = 'idle';
        state.dialogues.delete.value = action.payload;
      })
      .addCase(deleteDialogueAsyncThunk.rejected, (state) => {
        state.dialogues.delete.state = 'idle';
      })

      //custom faases
      .addCase(getCustomFaasesListAsyncThunk.pending, (state) => {
        state.customFaas.list.state = 'pending';
      })
      .addCase(getCustomFaasesListAsyncThunk.fulfilled, (state, action) => {
        state.customFaas.list.state = 'idle';
        state.customFaas.list.value = action.payload;
      })
      .addCase(getCustomFaasesListAsyncThunk.rejected, (state) => {
        state.customFaas.list.state = 'idle';
      })

      .addCase(createCustomFaasAsyncThunk.pending, (state) => {
        state.customFaas.create.state = 'pending';
      })
      .addCase(createCustomFaasAsyncThunk.fulfilled, (state, action) => {
        state.customFaas.create.state = 'idle';
        state.customFaas.create.value = action.payload;
      })
      .addCase(createCustomFaasAsyncThunk.rejected, (state) => {
        state.customFaas.create.state = 'idle';
      })

      .addCase(updateCustomFaasAsyncThunk.pending, (state) => {
        state.customFaas.update.state = 'pending';
      })
      .addCase(updateCustomFaasAsyncThunk.fulfilled, (state, action) => {
        state.customFaas.update.state = 'idle';
        state.customFaas.update.value = action.payload;
      })
      .addCase(updateCustomFaasAsyncThunk.rejected, (state) => {
        state.customFaas.update.state = 'idle';
      })

      //delete custom faas
      .addCase(deleteCustomFaasAsyncThunk.pending, (state) => {
        state.customFaas.delete.state = 'pending';
      })
      .addCase(deleteCustomFaasAsyncThunk.fulfilled, (state) => {
        state.customFaas.delete.state = 'idle';
      })
      .addCase(deleteCustomFaasAsyncThunk.rejected, (state) => {
        state.customFaas.delete.state = 'idle';
      });
  },
});
//dialogues
export const selectDialoguesList = (state: RootState) =>
  state.dialogues.dialogues.list.value;
export const selectDialogueDetailed = (state: RootState) =>
  state.dialogues.dialogues.detailed.value;

export const checkDialogueCreate = (state: RootState) =>
  state.dialogues.dialogues.create;
export const checkDialogueUpdate = (state: RootState) =>
  state.dialogues.dialogues.update;
export const checkDialogueDelete = (state: RootState) =>
  state.dialogues.dialogues.delete;

//custom faases
export const selectCustomFaasesList = (state: RootState) =>
  state.dialogues.customFaas.list.value;

export const checkCustomFaasCreate = (state: RootState) =>
  state.dialogues.customFaas.create;
export const checkCustomFaasUpdate = (state: RootState) =>
  state.dialogues.customFaas.update;
export const checkCustomFaasDelete = (state: RootState) =>
  state.dialogues.customFaas.delete;

export const {
  clearCreateDialogue,
  clearUpdateDialogue,
  clearCreateCustomFaas,
  clearUpdateCustomFaas,
} = dialoguesSlice.actions;

export default dialoguesSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PatchedTenantPhoneNumberRequest,
  PhoneNumberHistory,
  PhoneNumberSwapRequest,
  TenantPhoneNumber,
} from '../../../../../../../generated/tenants/Api';
import {
  CheckPhoneNumberResult,
  createTenantPhoneNumbers,
  getAllNumbersList,
  getSuperAdminPhoneNumberHistory,
  getAdminPhoneNumberHistory,
  getStrategyPhoneNumbers,
  getTenantPhoneNumbers,
  updatePhoneNumber,
  CheckTenantPhoneNumberResult,
  updateTenantPhoneNumber,
  CheckNumberResult,
  SwapPhoneNumbersResult,
  swapPhoneNumbers,
} from './phoneNumbersApi';
import { RootState } from '../../../../../../store';
import {
  PatchedPhoneNumberPatchRequest,
  PhoneNumberCreateRequest,
  PhoneNumberDetail,
  PhoneNumberHistory as SuperAdminPhoneNumberHistory,
} from '../../../../../../../generated/public/Api';

export interface PhoneNumbersState {
  tenantPhoneNumbersList: {
    state: 'pending' | 'idle';
    value: TenantPhoneNumber[];
  };
  updateNumber: {
    state: 'pending' | 'idle';
    value?: CheckPhoneNumberResult;
  };
  strategyPhoneNumbersList: {
    state: 'pending' | 'idle';
    value: TenantPhoneNumber[];
  };
  numbersHistory: {
    state: 'pending' | 'idle';
    value?: PhoneNumberHistory;
  };
  swap: {
    state: 'pending' | 'idle';
    value?: SwapPhoneNumbersResult;
  };

  tenantPhoneNumbersCreate: {
    state: 'pending' | 'idle';
    value?: CheckNumberResult;
  };
  tenantAllNumbers: {
    state: 'pending' | 'idle';
    value: PhoneNumberDetail[];
  };
  tenantNumberHistory: {
    state: 'pending' | 'idle';
    value?: SuperAdminPhoneNumberHistory;
  };
  tenantUpdateNumber: {
    state: 'pending' | 'idle';
    value?: CheckTenantPhoneNumberResult;
  };
}

const initialState: PhoneNumbersState = {
  tenantPhoneNumbersList: {
    state: 'pending',
    value: [],
  },

  updateNumber: {
    state: 'pending',
  },
  strategyPhoneNumbersList: {
    state: 'pending',
    value: [],
  },
  numbersHistory: {
    state: 'pending',
  },
  swap: {
    state: 'pending',
  },

  //super admin only
  tenantPhoneNumbersCreate: {
    state: 'pending',
  },
  tenantAllNumbers: {
    state: 'pending',
    value: [],
  },
  tenantNumberHistory: {
    state: 'pending',
  },
  tenantUpdateNumber: {
    state: 'pending',
  },
};

export const getTenantPhoneNumbersAsyncThunk = createAsyncThunk(
  'phoneNumbers/tenants',
  () => getTenantPhoneNumbers(),
);

export const getStrategyPhoneNumbersAsyncThunk = createAsyncThunk(
  'phoneNumbers/strategy',
  (id: string) => getStrategyPhoneNumbers(id),
);

export const updatePhoneNumberAsyncThunk = createAsyncThunk(
  'phoneNumbers/update',
  (args: { numberId: string; data: PatchedTenantPhoneNumberRequest }) =>
    updatePhoneNumber(args.numberId, args.data),
);

export const getAdminPhoneNumberHistoryAsyncThunk = createAsyncThunk(
  'phoneNumbers/history',
  (id: string) => getAdminPhoneNumberHistory(id),
);

//super admin
export const createTenantPhoneNumbersAsyncThunk = createAsyncThunk(
  'phoneNumbers/tenants/create',
  (data: PhoneNumberCreateRequest) => createTenantPhoneNumbers(data),
);

export const getAllNumbersListAsyncThunk = createAsyncThunk(
  'phoneNumbers/tenants/list',
  () => getAllNumbersList(),
);

export const getSuperAdminPhoneNumberHistoryAsyncThunk = createAsyncThunk(
  'phoneNumbers/tenants/number/list',
  (id: string) => getSuperAdminPhoneNumberHistory(id),
);

export const updateTenantPhoneNumberAsyncThunk = createAsyncThunk(
  'phoneNumbers/tenants/update',
  (args: { numberId: string; data: PatchedPhoneNumberPatchRequest }) =>
    updateTenantPhoneNumber(args.numberId, args.data),
);

export const swapPhoneNumbersAsyncThunk = createAsyncThunk(
  'phoneNumbers/swap',
  (data: PhoneNumberSwapRequest) => swapPhoneNumbers(data),
);

export const phoneNumbersSlice = createSlice({
  name: 'phoneNumbers',
  initialState,
  reducers: {
    clearNumberHistory: (state) => {
      state.tenantNumberHistory.value = undefined;
      state.numbersHistory.value = undefined;
    },
    clearCreateNumber: (state) => {
      state.tenantPhoneNumbersCreate.state = 'pending';
      state.tenantPhoneNumbersCreate.value = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      //tenants list
      .addCase(getTenantPhoneNumbersAsyncThunk.pending, (state) => {
        state.tenantPhoneNumbersList.state = 'pending';
      })
      .addCase(getTenantPhoneNumbersAsyncThunk.fulfilled, (state, action) => {
        state.tenantPhoneNumbersList.state = 'idle';
        state.tenantPhoneNumbersList.value = action.payload;
      })
      .addCase(getTenantPhoneNumbersAsyncThunk.rejected, (state) => {
        state.tenantPhoneNumbersList.state = 'idle';
      })

      //strategies phone numbers
      .addCase(getStrategyPhoneNumbersAsyncThunk.pending, (state) => {
        state.strategyPhoneNumbersList.state = 'pending';
      })
      .addCase(getStrategyPhoneNumbersAsyncThunk.fulfilled, (state, action) => {
        state.strategyPhoneNumbersList.state = 'idle';
        state.strategyPhoneNumbersList.value = action.payload;
      })
      .addCase(getStrategyPhoneNumbersAsyncThunk.rejected, (state) => {
        state.strategyPhoneNumbersList.state = 'idle';
      })

      //update number
      .addCase(updatePhoneNumberAsyncThunk.pending, (state) => {
        state.updateNumber.state = 'pending';
      })
      .addCase(updatePhoneNumberAsyncThunk.fulfilled, (state, action) => {
        state.updateNumber.state = 'idle';
        state.updateNumber.value = action.payload;
      })
      .addCase(updatePhoneNumberAsyncThunk.rejected, (state) => {
        state.updateNumber.state = 'idle';
      })

      //swap number
      .addCase(swapPhoneNumbersAsyncThunk.pending, (state) => {
        state.swap.state = 'pending';
      })
      .addCase(swapPhoneNumbersAsyncThunk.fulfilled, (state, action) => {
        state.swap.state = 'idle';
        state.swap.value = action.payload;
      })
      .addCase(swapPhoneNumbersAsyncThunk.rejected, (state) => {
        state.swap.state = 'idle';
      })

      //numbers history
      .addCase(getAdminPhoneNumberHistoryAsyncThunk.pending, (state) => {
        state.numbersHistory.state = 'pending';
      })
      .addCase(
        getAdminPhoneNumberHistoryAsyncThunk.fulfilled,
        (state, action) => {
          state.numbersHistory.state = 'idle';
          state.numbersHistory.value = action.payload;
        },
      )
      .addCase(getAdminPhoneNumberHistoryAsyncThunk.rejected, (state) => {
        state.numbersHistory.state = 'idle';
      })

      //super admin
      .addCase(createTenantPhoneNumbersAsyncThunk.pending, (state) => {
        state.tenantPhoneNumbersCreate.state = 'pending';
      })
      .addCase(
        createTenantPhoneNumbersAsyncThunk.fulfilled,
        (state, action) => {
          state.tenantPhoneNumbersCreate.state = 'idle';
          state.tenantPhoneNumbersCreate.value = action.payload;
        },
      )
      .addCase(createTenantPhoneNumbersAsyncThunk.rejected, (state) => {
        state.tenantPhoneNumbersCreate.state = 'idle';
      })

      .addCase(getAllNumbersListAsyncThunk.pending, (state) => {
        state.tenantAllNumbers.state = 'pending';
      })
      .addCase(getAllNumbersListAsyncThunk.fulfilled, (state, action) => {
        state.tenantAllNumbers.state = 'idle';
        state.tenantAllNumbers.value = action.payload;
      })
      .addCase(getAllNumbersListAsyncThunk.rejected, (state) => {
        state.tenantAllNumbers.state = 'idle';
      })

      .addCase(getSuperAdminPhoneNumberHistoryAsyncThunk.pending, (state) => {
        state.tenantNumberHistory.state = 'pending';
      })
      .addCase(
        getSuperAdminPhoneNumberHistoryAsyncThunk.fulfilled,
        (state, action) => {
          state.tenantNumberHistory.state = 'idle';
          state.tenantNumberHistory.value = action.payload;
        },
      )
      .addCase(getSuperAdminPhoneNumberHistoryAsyncThunk.rejected, (state) => {
        state.tenantNumberHistory.state = 'idle';
      })
      .addCase(updateTenantPhoneNumberAsyncThunk.pending, (state) => {
        state.tenantUpdateNumber.state = 'pending';
      })
      .addCase(updateTenantPhoneNumberAsyncThunk.fulfilled, (state, action) => {
        state.tenantUpdateNumber.state = 'idle';
        state.tenantUpdateNumber.value = action.payload;
      })
      .addCase(updateTenantPhoneNumberAsyncThunk.rejected, (state) => {
        state.tenantUpdateNumber.state = 'idle';
      });
  },
});

export const checkCreatePhoneNumber = (state: RootState) =>
  state.phoneNumbers.tenantPhoneNumbersCreate;

export const selectTenantPhoneNumbersList = (state: RootState) =>
  state.phoneNumbers.tenantPhoneNumbersList.value;
export const selectStrategyPhoneNumbersList = (state: RootState) =>
  state.phoneNumbers.strategyPhoneNumbersList.value;

export const selectAllTenantNumbers = (state: RootState) =>
  state.phoneNumbers.tenantAllNumbers.value;

export const checkPhoneNumberUpdate = (state: RootState) =>
  state.phoneNumbers.updateNumber;

export const checkPhoneNumberSwap = (state: RootState) =>
  state.phoneNumbers.swap;

export const selectPhoneNumberHistory = (state: RootState) =>
  state.phoneNumbers.numbersHistory.value;

export const getSuperAdminPhoneNumberHistoryList = (state: RootState) =>
  state.phoneNumbers.tenantNumberHistory.value;

export const checkTenantPhoneNumberCreate = (state: RootState) =>
  state.phoneNumbers.tenantPhoneNumbersCreate;

export const checkTenantPhoneNumberUpdate = (state: RootState) =>
  state.phoneNumbers.tenantUpdateNumber;

export const { clearNumberHistory, clearCreateNumber } =
  phoneNumbersSlice.actions;
export default phoneNumbersSlice.reducer;

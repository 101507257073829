import styled from 'styled-components';

export const DaysOffDatesContainer = styled.div`
  padding: 5px;
`;

export const DayOffContainer = styled.div`
  height: 30px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;

  border-bottom: 1px solid #d3d3d3;
`;

export const TrashIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CalendarIconContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
`;

import { ApiError } from '../../../../../common/types';
import {
  Api,
  BotConfiguration,
  BotConfigurationRequest,
  CustomFaas,
  CustomFaasRequest,
} from '../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckDialogueResult {
  status: 'success' | 'fail';
  error?: ApiError<BotConfiguration>;
  dialogue?: BotConfiguration;
}

export const getDialoguesList = (strategy: string) =>
  api.strategiesBotConfigurationsList(strategy).then((_) => _.data);

export const getDialogueDetailed = (id: string) =>
  api.botConfigurationsRetrieve(id).then((_) => _.data);

export const createDialogue = (
  data: BotConfigurationRequest,
): Promise<CheckDialogueResult> =>
  api
    .botConfigurationsCreate(data)
    .then(() => {
      return { status: 'success' } as CheckDialogueResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const updateDialogue = (
  id: string,
  data: BotConfigurationRequest,
): Promise<CheckDialogueResult> =>
  api
    .botConfigurationsUpdate(id, data)
    .then(() => {
      return { status: 'success', dialogue: data } as CheckDialogueResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteDialogue = (id: string): Promise<CheckDialogueResult> =>
  api
    .botConfigurationsDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckDialogueResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });
//custom faases
export interface CheckCustomFaasResult {
  status: 'success' | 'fail';
  error?: ApiError<CustomFaas>;
  customFaas?: CustomFaas;
}

export const getCustomFasesList = (strategy: string) =>
  api.strategiesCustomFaasesList(strategy).then((_) => _.data);

export const createCustomFaas = (
  data: CustomFaasRequest,
): Promise<CheckCustomFaasResult> =>
  api
    .customFaasesCreate(data)
    .then(() => {
      return { status: 'success' } as CheckCustomFaasResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const updateCustomFaas = (
  id: string,
  data: CustomFaasRequest,
): Promise<CheckCustomFaasResult> =>
  api
    .customFaasesUpdate(id, data)
    .then(() => {
      return { status: 'success', customFaas: data } as CheckCustomFaasResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteCustomFaas = (id: string) =>
  api.customFaasesDestroy(id).then((_) => _.data);

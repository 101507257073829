import React, { useEffect, useState } from 'react';
import {
  AdminUser,
  PatchedAdminRoleUpdateRequest,
} from '../../../../../../../generated/public/Api';
import { useAppDispatch } from '../../../../../../../common/hooks';
import { EuiSuperSelect, EuiLoadingSpinner } from '@elastic/eui';
import { EditIcon } from '../../../../../../../resources/icons-new/EditIcon';
import {
  H4,
  Button,
  COLOR_NEUTRAL_80,
  H6,
  H5,
} from '../../../../../../App.style';
import {
  UsersListTableRow,
  UsersListTableData,
  EditPermissionsContainer,
  EditAccessContainer,
  UserRoleContainer,
} from '../../../../../users/Users.style';
import { updateSuperUserPermissionsAsyncThunk } from '../../../api/superAdminSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface SuperUserTableRowProps {
  superUser: AdminUser;
  index: number;
  edit: boolean;
  setEditUserIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  showSuccessPermissionsPopup: () => void;
  showErrorPermissionsPopup: () => void;
  handleReloadSuperUsersList: () => void;
}

const SuperUserTableRow: React.FC<SuperUserTableRowProps> = ({
  superUser,
  index,
  edit,
  setEditUserIndex,
  showErrorPermissionsPopup,
  showSuccessPermissionsPopup,
  handleReloadSuperUsersList,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  type SuperPermissions = 'SUPERADMIN' | 'SUPERVISOR';

  const [selectedSuperUserRole, setSelectedSuperUserRole] =
    useState<SuperPermissions>();

  useEffect(() => {
    setSelectedSuperUserRole(
      superUser.is_super_admin ? 'SUPERADMIN' : 'SUPERVISOR',
    );
  }, [edit]);

  //select options
  const superUserPermissionsOptions = [
    {
      value: 'SUPERADMIN',
      inputDisplay: <H5>Superadmin</H5>,
    },
    {
      value: 'SUPERVISOR',
      inputDisplay: <H5>Supervisor</H5>,
    },
  ];

  const renderName = (firstName?: string, lastName?: string) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return t('superAdminPanel.superUsers.table.notKnown');
  };

  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);

  const displaySuperUserRole = (superUser: AdminUser) => {
    const isSuperadmin = superUser.is_super_admin;
    return (
      <UserRoleContainer role={isSuperadmin ? 'admin' : 'user'}>
        <H6 $bold>{isSuperadmin ? 'Superadmin' : 'Supervisor'}</H6>
      </UserRoleContainer>
    );
  };

  const handleSaveClick = () => {
    setLoadingPermissions(true);
    const request: PatchedAdminRoleUpdateRequest = {
      is_super_admin: false,
      is_supervisor: false,
    };

    if (selectedSuperUserRole === 'SUPERADMIN') {
      request.is_super_admin = true;
    } else if (selectedSuperUserRole === 'SUPERVISOR') {
      request.is_supervisor = true;
    }

    const data = {
      userId: superUser.id,
      data: request,
    };

    dispatch(updateSuperUserPermissionsAsyncThunk(data))
      .then(() => {
        showSuccessPermissionsPopup();
      })
      .catch(() => {
        showErrorPermissionsPopup();
      })
      .finally(() => {
        handleCancelClick();
        setLoadingPermissions(false);
        handleReloadSuperUsersList();
      });
  };

  const handleCancelClick = () => {
    setEditUserIndex(undefined);

    setSelectedSuperUserRole(
      superUser.is_super_admin ? 'SUPERADMIN' : 'SUPERVISOR',
    );
  };

  return (
    <UsersListTableRow no_permissions={false} key={superUser.id}>
      <UsersListTableData style={{ width: 250 }}>
        <div>
          <H4 $bold>{renderName(superUser.first_name, superUser.last_name)}</H4>
          <H4>{superUser.email}</H4>
        </div>
      </UsersListTableData>

      <UsersListTableData
        style={{ width: 150 }}
        onClick={(e) => e.stopPropagation()}
      >
        {edit ? (
          <EuiSuperSelect
            compressed
            options={superUserPermissionsOptions}
            valueOfSelected={selectedSuperUserRole}
            onChange={(e) => setSelectedSuperUserRole(e as SuperPermissions)}
            itemLayoutAlign="top"
          />
        ) : (
          <>{displaySuperUserRole(superUser)}</>
        )}
      </UsersListTableData>

      <UsersListTableData
        style={{ width: 150 }}
        onClick={(e) => e.stopPropagation()}
      >
        {loadingPermissions ? (
          <EuiLoadingSpinner />
        ) : (
          <H4 $grey $italic>
            {superUser.is_super_admin
              ? t('superAdminPanel.superUsers.table.full')
              : t('superAdminPanel.superUsers.table.viewOnly')}
          </H4>
        )}
      </UsersListTableData>

      <UsersListTableData onClick={(e) => e.stopPropagation()}>
        <H4>
          {superUser.last_login
            ? moment(superUser.last_login).format('DD.MM.YYYY, HH:mm')
            : t('superAdminPanel.superUsers.table.notLogged')}
        </H4>
      </UsersListTableData>

      {edit ? (
        <UsersListTableData pagination onClick={(e) => e.stopPropagation()}>
          <EditPermissionsContainer>
            <Button $size={'S'} onClick={handleSaveClick}>
              {t('common.save')}
            </Button>
            <Button $size={'S'} $styleType="NORMAL" onClick={handleCancelClick}>
              {t('common.cancel')}
            </Button>
          </EditPermissionsContainer>
        </UsersListTableData>
      ) : (
        <UsersListTableData pagination onClick={(e) => e.stopPropagation()}>
          <EditPermissionsContainer>
            <EditAccessContainer onClick={() => setEditUserIndex(index)}>
              <H4 $grey>{t('superAdminPanel.superUsers.table.editAccess')}</H4>
              <EditIcon color={COLOR_NEUTRAL_80} />
            </EditAccessContainer>
          </EditPermissionsContainer>
        </UsersListTableData>
      )}
    </UsersListTableRow>
  );
};

export default SuperUserTableRow;

import React, { useEffect, useState } from 'react';
import {
  AnalyticsChartBottomContainer,
  AnalyticsChartContainer,
  AnalyticsChartNoDataContainer,
  AnalyticsChartTopContainer,
  FakeLoadingTextContainer,
} from '../Analytics.style';
import PieChartCard from './overview/PieChartCard';
import ProgressBarChart from './overview/ProgressBarChart';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  AnalyticsOverviewResponse,
  CampaignOverview,
} from '../../../../generated/tenants/Api';
import { Moment } from 'moment';
import {
  getProcessesMetricsOverviewAsyncThunk,
  selectProcessMetricsOverview,
} from '../api/analyticsSlice';
import { COLOR_NEUTRAL_80, H1, H2 } from '../../../App.style';
import { useTranslation } from 'react-i18next';

interface OverwiewChartsProps {
  processId: string;
  type?: 'campaign' | 'time';
  campaigns: CampaignOverview[];
  startDate?: Moment;
  endDate?: Moment;
}

const OverwiewCharts: React.FC<OverwiewChartsProps> = ({
  processId,
  type,
  campaigns,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!processId || !type) return;

    let payload;

    if (type === 'campaign') {
      payload = {
        processId: processId,
        data: {
          campaign_ids: campaigns.map((campaign) => campaign.id),
        },
      };
    } else {
      payload = {
        processId: processId,
        data: {
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        },
      };
    }

    dispatch(getProcessesMetricsOverviewAsyncThunk(payload));
  }, [processId, type, campaigns, startDate, endDate]);

  const apiMetricsOverview = useAppSelector(selectProcessMetricsOverview);

  useEffect(() => {
    if (apiMetricsOverview) {
      setAnalyticsOverview(apiMetricsOverview);
    }
  }, [apiMetricsOverview]);

  const [analyticsOverview, setAnalyticsOverview] =
    useState<AnalyticsOverviewResponse>({
      contact_rate_count: 0,
      conversion_rate_count: 0,
      success_rate_count: 0,
      calls_count: 0,
      records_count: 0,
      inbound_calls_count: 0,
      outbound_calls_count: 0,
      record_fulfillment_count: 0,
      total_call_time_seconds: 0,
      average_call_time_seconds: 0,
      contact_rate_percent: 0,
      conversion_rate_percent: 0,
      success_rate_percent: 0,
      outbound_calls_percent: 0,
      inbound_calls_percent: 0,
      record_fulfillment_percent: 0,
    });

  function roundToTwo(num: number) {
    return parseFloat(num.toFixed(2));
  }

  const showCharts = () => {
    return (
      (type === 'time' && startDate && endDate) ||
      (type === 'campaign' && campaigns.length > 0)
    );
  };

  return (
    <AnalyticsChartContainer style={{ maxWidth: 1500, minWidth: 1100 }}>
      {!showCharts() && (
        <FakeLoadingTextContainer>
          <H1 style={{ color: COLOR_NEUTRAL_80 }}>
            {t('analytics.charts.noDataToShow')}
          </H1>
        </FakeLoadingTextContainer>
      )}

      {showCharts() && analyticsOverview.records_count === 0 ? (
        <AnalyticsChartNoDataContainer>
          <H2 $grey>{t('analytics.charts.noDataAvailableLine1')}</H2>
          <H2 $grey>{t('analytics.charts.noDataAvailableLine2')}</H2>
        </AnalyticsChartNoDataContainer>
      ) : (
        <>
          <AnalyticsChartTopContainer>
            <PieChartCard
              title={t('analytics.charts.overview.contactRate')}
              subtitle={t('analytics.charts.overview.contactRateSubtitle')}
              chartColor="#339989"
              percentage={roundToTwo(analyticsOverview.contact_rate_percent)}
              cornerText={{
                value: analyticsOverview.contact_rate_count,
                total: analyticsOverview.records_count,
              }}
              isLoading={!showCharts()}
            />

            <PieChartCard
              title={t('analytics.charts.overview.conversionRate')}
              subtitle={t('analytics.charts.overview.conversionRateSubtitle')}
              percentage={roundToTwo(analyticsOverview.conversion_rate_percent)}
              chartColor="#BBBE64"
              cornerText={{
                value: analyticsOverview.conversion_rate_count,
                total: analyticsOverview.records_count,
              }}
              isLoading={!showCharts()}
            />

            <PieChartCard
              title={t('analytics.charts.overview.successRate')}
              subtitle={t('analytics.charts.overview.successRateSubtitle')}
              percentage={roundToTwo(analyticsOverview.success_rate_percent)}
              chartColor="#473BF0"
              cornerText={{
                value: analyticsOverview.success_rate_count,
                total: analyticsOverview.records_count,
              }}
              isLoading={!showCharts()}
            />
          </AnalyticsChartTopContainer>

          <AnalyticsChartBottomContainer>
            <ProgressBarChart
              title={t('analytics.charts.overview.databaseProgress')} //Realizacja bazy
              subtitle={t('analytics.charts.overview.databaseProgressSubtitle')}
              percentage={roundToTwo(
                analyticsOverview.record_fulfillment_percent,
              )}
              callsAmount={{
                inbound: analyticsOverview.inbound_calls_count,
                outbound: analyticsOverview.outbound_calls_count,
                total: analyticsOverview.calls_count,
                recordsTotal: analyticsOverview.records_count,
              }}
              avg_calls_time={analyticsOverview.average_call_time_seconds}
              total_calls_time={analyticsOverview.total_call_time_seconds}
              isLoading={!showCharts()}
            />
          </AnalyticsChartBottomContainer>
        </>
      )}
    </AnalyticsChartContainer>
  );
};

export default OverwiewCharts;

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { H3 } from '../../../../App.style';
import { selectStrategyId } from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import { ColumnData } from '../ProcessesPanel';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../../common/modals/Modals.style';
import {
  checkProcessesPanelColumnDelete,
  getProcessesPanelColumnsAsyncThunk,
} from '../../api/processSlice';
import { TrashIcon } from '../../../../../resources/icons-new/TrashIcon';
import Modal from '../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface DeleteColumnModalProps {
  isVisible: boolean;
  selectedColumn: ColumnData;
  closeModal: () => void;
  deleteColumn: () => void;
}

const DeleteColumnModal: React.FC<DeleteColumnModalProps> = ({
  isVisible,
  closeModal,
  selectedColumn,
  deleteColumn,
}) => {
  const { t } = useTranslation();
  const strategyId = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();
  const checkDeleteColumn = useAppSelector(checkProcessesPanelColumnDelete);

  //if new column is added, refresh list
  useEffect(() => {
    if (
      checkDeleteColumn.state === 'idle' &&
      checkDeleteColumn.value?.status === 'success'
    ) {
      dispatch(getProcessesPanelColumnsAsyncThunk(strategyId));
      closeModal();
    }
  }, [checkDeleteColumn]);

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>{t('processes.modals.deleteColumnQuestion')}</H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteColumn();

    return false;
  };

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={`${t('processes.modals.removeColumn')}${
        selectedColumn.name
      }`}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default DeleteColumnModal;

export function formatPhoneNumber(phoneNumber: string): string {
  // Remove non-numeric characters
  const numericString = phoneNumber.replace(/\D/g, '');

  // Check if the phone number starts with a country code
  const hasCountryCode = phoneNumber.startsWith('+');

  if (hasCountryCode) {
    // If it has a country code, separate the country code from the rest of the number
    const countryCode = numericString.slice(0, 2);
    const localNumber = numericString.slice(2);

    // Format the local number part
    const formattedLocalNumber = localNumber.replace(
      /(\d{3})(\d{3})(\d{3})/,
      '$1 $2 $3',
    );

    // Combine the country code and formatted local number
    return `+${countryCode} ${formattedLocalNumber}`;
  } else {
    // If it doesn't have a country code, just format the number
    return numericString.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
  }
}

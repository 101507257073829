import {
  CallingConfiguration,
  CallingConfigurationRequest,
} from '../../../../../generated/tenants/Api';
import { ApiError } from '../../../../../common/types';
import { Api } from '../../../../../generated/tenants/Api';

const { api } = new Api();

export interface CheckCallingConfigurationResult {
  status: 'success' | 'fail';
  error?: ApiError<CallingConfiguration>;
  callingConfiguration?: CallingConfiguration;
}

export const getCallingConfigurationList = (id: string) =>
  api.strategiesCallingConfigurationsList(id).then((_) => _.data);

export const getCallingConfigurationDetailedView = (id: string) =>
  api.callingConfigurationsRetrieve(id).then((_) => _.data);

export const createCallingConfiguration = (
  data: CallingConfigurationRequest,
): Promise<CheckCallingConfigurationResult> =>
  api
    .callingConfigurationsCreate(data)
    .then(() => {
      return { status: 'success' } as CheckCallingConfigurationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const updateCallingConfiguration = (
  id: string,
  data: CallingConfigurationRequest,
): Promise<CheckCallingConfigurationResult> =>
  api
    .callingConfigurationsUpdate(id, data)
    .then(() => {
      return {
        status: 'success',
        callingConfiguration: data,
      } as CheckCallingConfigurationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

export const deleteCallingConfiguration = (
  id: string,
): Promise<CheckCallingConfigurationResult> =>
  api
    .callingConfigurationsDestroy(id)
    .then(() => {
      return { status: 'success' } as CheckCallingConfigurationResult;
    })
    .catch((response) => {
      return { status: 'fail', error: response.error };
    });

import React, { useEffect, useState } from 'react';
import { PhoneNumbersContainer } from './PhoneNumbers.style';
import { EuiAccordion, EuiSelectable, EuiSelectableOption } from '@elastic/eui';
import {
  ButtonEmpty,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY_1,
  H1,
  H2,
  H3,
} from '../../../../../App.style';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import {
  clearNumberHistory,
  getAdminPhoneNumberHistoryAsyncThunk,
  getStrategyPhoneNumbersAsyncThunk,
  selectPhoneNumberHistory,
  selectStrategyPhoneNumbersList,
} from './api/phoneNumbersSlice';
import { selectStrategyId } from '../strategies/api/strategiesSlice';
import moment from 'moment';
import { formatPhoneNumber } from './numbersFunctions';
import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from '../../../../../../resources/icons-new/ArrowRightIcon';
import {
  NumberHistoryCampaignRow,
  NumbersHistoryCampaignRowLeftContainer,
  NumbersHistoryCampaignRowRightContainer,
  NumbersHistoryContainer,
  NumbersSearchList,
  NumbersTopRowContainer,
} from '../../../superAdminPanel/components/numbers/SuperAdminNumbers.style';
import { Label } from '../../../../presets/labels/Labels.style';

const NumbersHistory = () => {
  const { t } = useTranslation();
  const strategy = useAppSelector(selectStrategyId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStrategyPhoneNumbersAsyncThunk(strategy));
  }, [strategy]);

  const apiTenantNumbersHistory = useAppSelector(
    selectStrategyPhoneNumbersList,
  );

  const [selectedPhoneNumberId, setSelectedPhoneNumberId] =
    useState<string>('');

  useEffect(() => {
    if (selectedPhoneNumberId === '' && apiTenantNumbersHistory.length > 0) {
      setSelectedPhoneNumberId(apiTenantNumbersHistory[0].id);
    }
  }, [apiTenantNumbersHistory]);

  useEffect(() => {
    if (selectedPhoneNumberId !== '') {
      dispatch(getAdminPhoneNumberHistoryAsyncThunk(selectedPhoneNumberId));
    }
  }, [selectedPhoneNumberId]);

  const apiNumberHistory = useAppSelector(selectPhoneNumberHistory);

  const handleShowCampaignDetails = (id: string) => {
    const url = `/dashboard/campaigns/${id}`;
    window.open(url, '_blank');
  };

  const generateLabel = (label: string) => {
    if (label === 'debtor') {
      return <Label color={'#F1E4C4'}>debtor</Label>;
    } else if (label === 'vendor') {
      return <Label color={'#D5E6DD'}>vendor</Label>;
    } else if (label === 'reminder') {
      return <Label color={'orange'}>reminder</Label>;
    } else {
      return (
        <Label color={COLOR_PRIMARY_1} fontWhite>
          {label}
        </Label>
      );
    }
  };

  //select
  const [isListVisible, setIsListVisible] = useState<boolean>(false);

  const onFocusHandler = () => {
    setIsListVisible(true);
  };

  const onBlurHandler = () => {
    setTimeout(() => {
      setIsListVisible(false);
    }, 200);
  };

  const selectableOptions: EuiSelectableOption[] = apiTenantNumbersHistory.map(
    (phone) => ({
      label: formatPhoneNumber(phone.phone_number_str),
      key: phone.id,
    }),
  );
  useEffect(() => {
    if (apiTenantNumbersHistory.length > 0) {
      setSelectOptions(selectableOptions);
    }
  }, [apiTenantNumbersHistory]);

  const [selectOptions, setSelectOptions] = useState<EuiSelectableOption[]>([]);

  const findNumberById = (id: string) => {
    const selectedNumber = apiTenantNumbersHistory.find(
      (number) => number.id === id,
    );
    return selectedNumber
      ? formatPhoneNumber(selectedNumber.phone_number_str)
      : '';
  };

  const onChange = (options: EuiSelectableOption[]) => {
    setSelectOptions(options);

    const selectedOption = options.find((option) => option.checked === 'on');

    if (selectedOption?.key) {
      setSelectedPhoneNumberId(selectedOption.key);
    } else {
      dispatch(clearNumberHistory());
      setSelectedPhoneNumberId('');
    }
  };

  return (
    <PhoneNumbersContainer>
      <H1 $medium>{t('superAdminPanel.numbers.history.title')}</H1>

      <NumbersTopRowContainer>
        <H2 $selected>{t('superAdminPanel.numbers.history.select')}</H2>

        <div
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          style={{ position: 'relative' }}
        >
          <EuiSelectable
            options={selectOptions}
            onChange={onChange}
            singleSelection={true}
            searchable={true}
          >
            {(list, search) => (
              <React.Fragment>
                {search}
                {isListVisible && <NumbersSearchList>{list}</NumbersSearchList>}
              </React.Fragment>
            )}
          </EuiSelectable>
        </div>
      </NumbersTopRowContainer>

      <NumbersHistoryContainer>
        {selectedPhoneNumberId && (
          <H2 $grey>
            {t('superAdminPanel.numbers.history.historyFor')}{' '}
            {findNumberById(selectedPhoneNumberId)}
          </H2>
        )}

        <div style={{ marginTop: 16 }}>
          {apiNumberHistory?.history.length === 0 ? (
            <H2 $selected>{t('superAdminPanel.numbers.history.empty')}</H2>
          ) : (
            apiNumberHistory?.history.map((history, index) => (
              <div key={`number-history-row-${index}`}>
                <EuiAccordion
                  initialIsOpen
                  id={`number-history-${index}`}
                  buttonContent={
                    <H3>
                      {history.process_name}
                      <span
                        style={{
                          color: COLOR_NEUTRAL_80,
                          marginLeft: 4,
                        }}
                      >
                        / {history.campaign_strategy}
                      </span>
                    </H3>
                  }
                  paddingSize="m"
                >
                  <NumberHistoryCampaignRow key={`numbers-history-${index}`}>
                    <NumbersHistoryCampaignRowLeftContainer>
                      <H3>
                        {`${moment(history.start_of_campaign).format(
                          'DD.MM.YY',
                        )} - ${moment(history.end_of_campaign).format(
                          'DD.MM.YY',
                        )}`}
                      </H3>

                      {generateLabel('brak backendu')}
                    </NumbersHistoryCampaignRowLeftContainer>
                    <NumbersHistoryCampaignRowRightContainer>
                      <ButtonEmpty
                        onClick={() =>
                          handleShowCampaignDetails(history.campaign)
                        }
                      >
                        <ArrowRightIcon style={{ width: 16, height: 16 }} />
                        {t('superAdminPanel.numbers.history.goToCampaign')}
                      </ButtonEmpty>
                    </NumbersHistoryCampaignRowRightContainer>
                  </NumberHistoryCampaignRow>
                </EuiAccordion>
              </div>
            ))
          )}
        </div>
      </NumbersHistoryContainer>
    </PhoneNumbersContainer>
  );
};

export default NumbersHistory;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../common/hooks';
import { H2, H4 } from '../../../App.style';
import {
  VoiceConfiguration,
  VoiceConfigurationRequest,
} from '../../../../generated/tenants/Api';
import { FormContainer } from '../../processes/forms/ProcessForm.style';
import { updateVoiceConfigurationAsyncThunk } from './api/voiceConfigurationSlice';
import VoiceForm from './VoiceForm';
import {
  DetailedPresetContainer,
  PresetNameContainer,
} from '../PresetsPanel.style';
import AssociatedProcesses from '../shared/AssociatedProcesses';
import EditPresetButtons from '../shared/EditPresetButtons';
import VoiceFormReadOnly from './VoiceFormReadOnly';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../common/modals/LoadingModal';

interface DetailedVoiceProps {
  voice: VoiceConfiguration;
  backToList: () => void;
  findProcessNamesById(id: string): string[];
  hasPermission: boolean;
}

const DetailedVoice: React.FC<DetailedVoiceProps> = ({
  voice,
  backToList,
  findProcessNamesById,
  hasPermission,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState<boolean>(false);

  const formattedCreated = moment(voice.created_at).format('DD/MM/YYYY, HH:mm');
  const fomrattedModified = moment(voice.updated_at).format(
    'DD/MM/YYYY, HH:mm',
  );

  const [editVoice, setEditVoice] = useState<VoiceConfigurationRequest>({
    strategy: '',
    name: '',
    asr_vendor: 'google',
    tts_vendor: 'Google',
    voice_name: 'a',
  });

  useEffect(() => {
    setEditVoice(voice);
  }, [voice]);

  const handleVoiceEditClick = () => {
    const id = voice.id;
    if (editVoice && id) {
      const args = { id: id, data: editVoice };
      setIsLoadingVisible(true);
      dispatch(updateVoiceConfigurationAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      alert('something went wrong');
    }
  };

  const handleCancelClick = () => {
    setEdit(false);
    setEditVoice(voice);
  };

  const associatedWithProcesses = findProcessNamesById(voice.id);

  const handleBackToDetailed = () => setEdit(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DetailedPresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {hasPermission && (
        <EditPresetButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={handleCancelClick}
          saveChanges={handleVoiceEditClick}
        />
      )}

      <AssociatedProcesses
        edit={edit}
        associatedProcesess={associatedWithProcesses}
      />

      {!edit ? (
        <FormContainer>
          <PresetNameContainer>
            <H2>{voice.name}</H2>
            <H4 $grey>
              {t('common.created')} {formattedCreated}, {t('common.modified')}{' '}
              {fomrattedModified}
            </H4>
          </PresetNameContainer>
          <VoiceFormReadOnly voice={voice} />
        </FormContainer>
      ) : (
        <VoiceForm
          voice={editVoice}
          setVoice={setEditVoice}
          back={backToList}
          backToDetailed={handleBackToDetailed}
        />
      )}
    </DetailedPresetContainer>
  );
};

export default DetailedVoice;

import React, { useEffect, useState } from 'react';
import { DialoguesContainer } from './Dialogues.style';
import DialoguesList from './DialoguesList';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import { selectStrategyId } from '../../settings/adminPanel/components/strategies/api/strategiesSlice';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../processes/api/processSlice';
import {
  BotConfiguration,
  CustomFaas,
} from '../../../../generated/tenants/Api';
import PresetDeleteModal from '../PresetDeleteModal';
import BackToList from '../shared/BackToList';
import CreateCustomDialogue from './custom/CreateCustomDialogue';
import CustomDialogueDetailed from './custom/CustomDialogueDetailed';
import {
  checkCustomFaasCreate,
  checkCustomFaasDelete,
  checkCustomFaasUpdate,
  checkDialogueDelete,
  checkDialogueUpdate,
  createCustomFaasAsyncThunk,
  deleteCustomFaasAsyncThunk,
  deleteDialogueAsyncThunk,
  getCustomFaasesListAsyncThunk,
  getDialoguesListAsyncThunk,
  selectCustomFaasesList,
  selectDialoguesList,
} from './api/dialoguesSlice';
import CreateCustomFaas from './customFaas/CreateCustomFaas';
import CustomFaasDetailed from './customFaas/CustomFaasDetailed';
import usePermissions from '../../users/usePermissions';
import LoadingModal from '../../../../common/modals/LoadingModal';

const Dialogues = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);
  const [dialoguesState, setDialoguesState] = useState<
    'list' | 'create' | 'createCustomFaas' | 'detailed' | 'detailedCustomFaas'
  >('list');
  const { isSuperAdmin } = usePermissions();

  useEffect(() => {
    dispatch(getDialoguesListAsyncThunk(strategy));
    dispatch(getCustomFaasesListAsyncThunk(strategy));
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);

  const apiBotsList = useAppSelector(selectDialoguesList);

  const apiProcessesList = useAppSelector(selectProcessesList);

  const apiCustomFaasesList = useAppSelector(selectCustomFaasesList);

  const handleBackToList = () => {
    setDialoguesState('list');
  };

  //create dialogue
  const handleAddCustomBotClick = () => setDialoguesState('create');

  //create custom faas
  const handleCreateCustomFaasClick = () =>
    setDialoguesState('createCustomFaas');

  //detailed dialogue
  const [selectedBot, setSelectedBot] = useState<BotConfiguration>();
  const handleDetailedDialogueClick = (dialogue: string) => {
    const dialogueById = apiBotsList.find((voice) => voice.id === dialogue);
    if (dialogueById) setSelectedBot(dialogueById);
    setDialoguesState('detailed');
  };

  const [selectedCustomFaas, setSelectedCustomFaas] = useState<CustomFaas>();
  const handleDetailedCustomFaasClick = (customFaas: string) => {
    const customFaasById = apiCustomFaasesList.find(
      (faas) => faas.id === customFaas,
    );
    if (customFaasById) setSelectedCustomFaas(customFaasById);
    setDialoguesState('detailedCustomFaas');
  };

  function findProcessNamesByDialoguesId(id: string): string[] {
    return apiProcessesList
      .filter((item) => item.bot_configuration === id)
      .map((item) => item.name);
  }

  function findProcessNamesByCustomFaasId(id: string): string[] {
    return apiProcessesList
      .filter((item) =>
        [
          item.import_custom_faas,
          item.report_per_call_custom_faas?.id,
          item.report_per_record_custom_faas?.id,
          item.report_per_campaign_custom_faas?.id,
          item.get_customer_info_faas?.id,
          item.report_feedback_faas?.id,
        ].includes(id),
      )
      .map((item) => item.name);
  }

  //delete dialogue
  const [isDeleteBotVisible, setIsDeleteBotVisible] = useState<boolean>(false);
  const openDeleteBotModal = () => setIsDeleteBotVisible(true);
  const closeDeleteBotModal = () => setIsDeleteBotVisible(false);

  const handleDeleteBot = (id: string) => {
    const selectedBot = apiBotsList.find((bot) => bot.id === id);
    if (selectedBot) setSelectedBot(selectedBot);
    openDeleteBotModal();
  };

  const deleteBotAfterModal = () => {
    if (selectedBot) {
      dispatch(deleteDialogueAsyncThunk(selectedBot.id));
      closeDeleteBotModal();
    } else {
      alert('error');
    }
  };

  const checkUpdateDialogue = useAppSelector(checkDialogueUpdate);
  useEffect(() => {
    if (
      checkUpdateDialogue.state === 'idle' &&
      checkUpdateDialogue.value?.status === 'success' &&
      checkUpdateDialogue.value.dialogue
    ) {
      setSelectedBot(checkUpdateDialogue.value.dialogue);
    }
  }, [checkUpdateDialogue]);

  const checkDeleteBot = useAppSelector(checkDialogueDelete);

  useEffect(() => {
    if (checkDeleteBot.state === 'idle') {
      dispatch(getDialoguesListAsyncThunk(strategy));
      setSelectedBot(undefined);
    }
  }, [checkDeleteBot]);

  //delete custom faas
  const [isDeleteCustomFaasVisible, setIsDeleteCustomFaasVisible] =
    useState<boolean>(false);
  const openDeleteCustomFaasModal = () => setIsDeleteCustomFaasVisible(true);
  const closeDeleteCustomFaasModal = () => setIsDeleteCustomFaasVisible(false);

  const handleDeleteCustomFaas = (id: string) => {
    const selectedCustomFaas = apiCustomFaasesList.find(
      (faas) => faas.id === id,
    );
    if (selectedCustomFaas) setSelectedCustomFaas(selectedCustomFaas);
    openDeleteCustomFaasModal();
  };

  const deleteCustomFaasAfterModal = () => {
    if (selectedCustomFaas) {
      dispatch(deleteCustomFaasAsyncThunk(selectedCustomFaas.id));
      closeDeleteCustomFaasModal();
    } else {
      alert('error');
    }
  };
  const checkDeleteCustomFaas = useAppSelector(checkCustomFaasDelete);
  useEffect(() => {
    if (checkDeleteCustomFaas.state === 'idle') {
      dispatch(getCustomFaasesListAsyncThunk(strategy));
      setSelectedCustomFaas(undefined);
    }
  }, [checkDeleteCustomFaas]);

  //duplicate custom faas
  const handleDuplicateCustomFaas = (id: string) => {
    const selectedCustomFaas = apiCustomFaasesList.find(
      (customFaas) => customFaas.id === id,
    );
    if (selectedCustomFaas) {
      let count = 1;
      let newName = selectedCustomFaas.name;

      let nameExists = apiCustomFaasesList.some(
        (item) => item.name === newName,
      );

      while (nameExists) {
        newName =
          selectedCustomFaas.name + ' - copy' + (count > 1 ? `(${count})` : '');
        nameExists = apiCustomFaasesList.some((item) => item.name === newName);
        count++;
      }

      const copiedCustomFaas = {
        ...selectedCustomFaas,
        name: newName,
      };
      setIsLoadingVisible(true);
      dispatch(createCustomFaasAsyncThunk(copiedCustomFaas)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const checkFaasUpdate = useAppSelector(checkCustomFaasUpdate);

  useEffect(() => {
    if (
      checkFaasUpdate.state === 'idle' &&
      checkFaasUpdate.value?.status === 'success' &&
      checkFaasUpdate.value.customFaas
    ) {
      setSelectedCustomFaas(checkFaasUpdate.value.customFaas);
    }
  }, [checkFaasUpdate]);

  const checkCopy = useAppSelector(checkCustomFaasCreate);

  useEffect(() => {
    if (checkCopy.state === 'idle' && checkCopy.value?.status === 'success') {
      dispatch(getCustomFaasesListAsyncThunk(strategy));
      setSelectedCustomFaas(undefined);
    }
  }, [checkCopy]);

  const componentsMap = {
    create: (
      <CreateCustomDialogue
        strategy={strategy}
        backToList={handleBackToList}
        customFaasesList={apiCustomFaasesList}
      />
    ),
    createCustomFaas: (
      <CreateCustomFaas strategy={strategy} backToList={handleBackToList} />
    ),
    list: (
      <DialoguesList
        //dialogues list
        dialoguesList={apiBotsList}
        createCustomDialogue={handleAddCustomBotClick}
        handleDeleteDialoguePreset={handleDeleteBot}
        goToDetailedDialogue={handleDetailedDialogueClick}
        findProcessNamesByDialoguesId={findProcessNamesByDialoguesId}
        //custom faas
        customFaasesList={apiCustomFaasesList}
        createCustomFaas={handleCreateCustomFaasClick}
        handleDeleteCustomFaas={handleDeleteCustomFaas}
        goToDetailedCustomFaas={handleDetailedCustomFaasClick}
        findProcessNamesByCustomFaasId={findProcessNamesByCustomFaasId}
        handleDuplicateCustomFaas={handleDuplicateCustomFaas}
        isSuperAdmin={isSuperAdmin}
      />
    ),
    detailed: selectedBot && (
      <CustomDialogueDetailed
        customDialogue={selectedBot}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesByDialoguesId}
        customFaasesList={apiCustomFaasesList}
        isSuperAdmin={isSuperAdmin}
      />
    ),
    detailedCustomFaas: selectedCustomFaas && (
      <CustomFaasDetailed
        customFaas={selectedCustomFaas}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesByCustomFaasId}
        isSuperAdmin={isSuperAdmin}
      />
    ),
  };
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <DialoguesContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {selectedBot && isSuperAdmin && (
        <PresetDeleteModal
          type={'dialogue'}
          isVisible={isDeleteBotVisible}
          presetName={selectedBot.name}
          closeModal={closeDeleteBotModal}
          deletePreset={deleteBotAfterModal}
        />
      )}

      {selectedCustomFaas && isSuperAdmin && (
        <PresetDeleteModal
          type={'custom faas'}
          isVisible={isDeleteCustomFaasVisible}
          presetName={selectedCustomFaas.name}
          closeModal={closeDeleteCustomFaasModal}
          deletePreset={deleteCustomFaasAfterModal}
        />
      )}

      {dialoguesState !== 'list' && (
        <BackToList handleBackToList={handleBackToList} />
      )}

      {componentsMap[dialoguesState]}
    </DialoguesContainer>
  );
};

export default Dialogues;

import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const ChevronRightIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14645 1.14645C4.34171 0.951184 4.65829 0.951184 4.85355 1.14645L10.8536 7.14645C11.0488 7.34171 11.0488 7.65829 10.8536 7.85355L4.85355 13.8536C4.65829 14.0488 4.34171 14.0488 4.14645 13.8536C3.95118 13.6583 3.95118 13.3417 4.14645 13.1464L9.79289 7.5L4.14645 1.85355C3.95118 1.65829 3.95118 1.34171 4.14645 1.14645Z"
    />
  </StyledSvg>
);

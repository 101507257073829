import {
  EuiFormRow,
  EuiTextArea,
  copyToClipboard,
  EuiToolTip,
} from '@elastic/eui';
import React, { useState } from 'react';
import { ChevronDownIcon } from '../../../../../../../resources/icons-new/ChevronDownIcon';
import { ChevronUpIcon } from '../../../../../../../resources/icons-new/ChevronUpIcon';
import { InfoIcon } from '../../../../../../../resources/icons-new/InfoIcon';
import { TrashIcon } from '../../../../../../../resources/icons-new/TrashIcon';
import { H2, H3, Button, H4 } from '../../../../../../App.style';
import {
  AccordionHeader,
  AccordionContent,
  AccordionBoxContent,
  RSAKeyTableItem,
  RSATableButton,
  LektaSSHKeyContainer,
  TextAreaWrapper,
  CopyIconContainer,
} from '../SftpPanel.style';
import SftpPasswordChangeForm from './SftpPasswordChangeForm';
import {
  SftpConfig,
  SftpPublicKey,
} from '../../../../../../../generated/tenants/Api';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  checkSftpPasswordSet,
  deleteSftpPublicKeyAsyncThunk,
  getSftpPublicKeysListAsyncThunk,
  selectSftpPublicKeysList,
} from '../api/sftpConfigSlice';
import AddPublicKeyModal from './AddPublicKeyModal';
import DeletePublicKeyModal from './DeletePublicKeyModal';
import { CopyIcon } from '../../../../../../../resources/icons-new/CopyIcon';
import { useTranslation } from 'react-i18next';

interface SftpUploadAccordionProps {
  handleHeaderClick: (section: 'list' | 'upload') => void;
  isOpen: boolean;
  sftpConfig: SftpConfig;
}

const SftpUploadAccordion: React.FC<SftpUploadAccordionProps> = ({
  handleHeaderClick,
  isOpen,
  sftpConfig,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isChangePasswordFormVisible, setIsChangePasswordFormVisible] =
    useState<boolean>(false);
  const handleChangePasswordFormVisibility = () =>
    setIsChangePasswordFormVisible((prev) => !prev);

  const isSftpPasswordSet = useAppSelector(checkSftpPasswordSet);
  const publicKeysList = useAppSelector(selectSftpPublicKeysList);

  const [isAddPublicKeyVisible, setIsAddPublicKeyVisible] =
    useState<boolean>(false);

  const showAddPublicKeyModal = () => setIsAddPublicKeyVisible(true);
  const closeAddPublicModal = () => setIsAddPublicKeyVisible(false);

  const [isDeletePublicKeyModalVisible, setIsDeletePublicKeyModalVisible] =
    useState<boolean>(false);
  const showDeletePublicKeyModal = () => setIsDeletePublicKeyModalVisible(true);
  const closeDeletePublicKeyModal = () =>
    setIsDeletePublicKeyModalVisible(false);
  const [selectedKey, setSelectedKey] = useState<SftpPublicKey>();

  const showDeleteSftpPublicKeyModal = (key: SftpPublicKey) => {
    setSelectedKey(key);
    showDeletePublicKeyModal();
  };
  const handleDeleteSftpPublicKey = () => {
    if (selectedKey) {
      dispatch(deleteSftpPublicKeyAsyncThunk(selectedKey.id)).finally(() => {
        dispatch(getSftpPublicKeysListAsyncThunk());
        setSelectedKey(undefined);
        closeDeletePublicKeyModal();
      });
    }
  };

  const [lektaSshCopied, setLektaSshCopied] = useState<boolean>(false);
  const handleCopyLektaSsh = (key: string) => {
    setLektaSshCopied(true);
    copyToClipboard(key);
  };

  return (
    <>
      <AddPublicKeyModal
        isVisible={isAddPublicKeyVisible}
        closeModal={closeAddPublicModal}
      />

      <DeletePublicKeyModal
        isVisible={isDeletePublicKeyModalVisible}
        closeModal={closeDeletePublicKeyModal}
        deleteKey={handleDeleteSftpPublicKey}
      />

      <AccordionHeader onClick={() => handleHeaderClick('upload')}>
        <H2 $selected>{t('settings.adminPanel.sftp.title')}</H2>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              marginBottom: 15,
            }}
          >
            <H2 $selected>Host: </H2> <H2>{sftpConfig.host}</H2>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              marginBottom: 15,
            }}
          >
            <H2 $selected>Port: </H2> <H2>{sftpConfig.port}</H2>
          </div>
        </div>

        <AccordionBoxContent>
          <H3 $semibold>{t('settings.adminPanel.sftp.username')}</H3>
          <H3>{sftpConfig.login}</H3>

          {isChangePasswordFormVisible ? (
            <SftpPasswordChangeForm
              closeForm={handleChangePasswordFormVisibility}
            />
          ) : (
            <EuiFormRow
              style={{ marginTop: 10, color: '#1a1a1a' }}
              label={<H3>{t('settings.adminPanel.sftp.password')}</H3>}
            >
              <div style={{ marginTop: 5 }}>
                <Button
                  $styleType="NORMAL"
                  $size="S"
                  type={'button'}
                  onClick={handleChangePasswordFormVisibility}
                >
                  {isSftpPasswordSet?.status === 'success'
                    ? t('settings.adminPanel.sftp.changePassword')
                    : t('settings.adminPanel.sftp.setPassword')}
                </Button>
                {isSftpPasswordSet?.status === 'success' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 5,
                      marginTop: 8,
                    }}
                  >
                    <InfoIcon />
                    <H4 $grey>
                      {t('settings.adminPanel.sftp.alreadyPassword')}
                    </H4>
                  </div>
                )}
              </div>
            </EuiFormRow>
          )}
        </AccordionBoxContent>
        <AccordionBoxContent>
          <div style={{ display: 'flex', gap: 8 }}>
            <H3 $semibold style={{ marginLeft: 3 }}>
              {t('settings.adminPanel.sftp.viaKeys')}
            </H3>
            <EuiToolTip
              position="top"
              content={t('settings.adminPanel.sftp.keysTooltip')}
            >
              <InfoIcon />
            </EuiToolTip>
          </div>

          <H3 style={{ marginLeft: 3 }}>
            {t('settings.adminPanel.sftp.trustedKeys')}
          </H3>

          {publicKeysList?.length === 0 ? (
            <H3 style={{ marginLeft: 3 }}>
              {t('settings.adminPanel.sftp.noKeys')}
            </H3>
          ) : (
            publicKeysList?.map((publicKey, i) => {
              return (
                <RSAKeyTableItem key={`sftp-${i}`}>
                  <H3>{publicKey.name}</H3>
                  <TrashIcon
                    onClick={() => showDeleteSftpPublicKeyModal(publicKey)}
                  />
                </RSAKeyTableItem>
              );
            })
          )}

          <RSATableButton>
            <Button $size="S" onClick={showAddPublicKeyModal}>
              {t('settings.adminPanel.sftp.addKey')}
            </Button>
          </RSATableButton>
        </AccordionBoxContent>
        <LektaSSHKeyContainer>
          <EuiFormRow
            label={<H3 $semibold>{t('settings.adminPanel.sftp.publicKey')}</H3>}
          >
            <TextAreaWrapper>
              <EuiTextArea
                style={{
                  width: 400,
                  minHeight: 50,
                  background: '#F5F7FA',
                  paddingRight: 25,
                }}
                value={sftpConfig.ssh_public_key}
                readOnly
                disabled
              />
              <CopyIconContainer>
                <EuiToolTip
                  position="top"
                  content={
                    <p style={{ textAlign: 'center' }}>
                      {lektaSshCopied
                        ? t('settings.adminPanel.sftp.copiedSSH')
                        : t('settings.adminPanel.sftp.copySSH')}
                    </p>
                  }
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyLektaSsh(sftpConfig.ssh_public_key);
                    }}
                    onMouseLeave={() => setLektaSshCopied(false)}
                  >
                    <CopyIcon $pointer />
                  </div>
                </EuiToolTip>
              </CopyIconContainer>
            </TextAreaWrapper>
          </EuiFormRow>
        </LektaSSHKeyContainer>
      </AccordionContent>
    </>
  );
};

export default SftpUploadAccordion;

import React from 'react';
import { CallsFiltersValues, CallStatusOption } from './CallsFilters';
import { EuiPopover, EuiCheckbox } from '@elastic/eui';
import { H4, H5, Button } from '../../../../../App.style';
import {
  FilterButton,
  FilterBarButton,
  FiltersContainer,
  FiltersTopContainer,
  FiltersContentContainer,
  FiltersOptionContainer,
  FiltersBottomContainer,
} from '../../../panel/bar/filters/CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { useTranslation } from 'react-i18next';

interface CallsStatusesFilterProps {
  barButton: boolean;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedStatuses: string[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<string[]>>;
  filters: CallsFiltersValues;
  setFilters: (value: React.SetStateAction<CallsFiltersValues>) => void;
}

const CallsStatusesFilter: React.FC<CallsStatusesFilterProps> = ({
  barButton,
  isOpen,
  setIsOpen,
  selectedStatuses,
  setSelectedStatuses,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const availableStatuses = [
    'VOICEMAIL',
    'ANSWERED',
    'NOT_ANSWERED',
    'REJECTED',
    'BUSY',
    'TRANSFER',
    'NONEXISTENT_NUMBER',
    'UNMAPPED',
    'ERROR',
  ];

  const statusesOptions = availableStatuses.map((status) => {
    return { value: status, text: status.toLowerCase() };
  });

  const renderCallStatus = (status: string) => {
    return t(`campaigns.calls.filters.${status}`);
  };

  const handleRemoveStatuses = () => {
    setIsOpen(false);
    setFilters({ ...filters, statuses: undefined });
    setSelectedStatuses([]);
  };
  const handleApplySelectedStatuses = () => {
    setIsOpen(false);
    setFilters({
      ...filters,
      statuses: selectedStatuses as CallStatusOption[],
    });
  };

  const handleStatusChange = (option: string) => {
    if (selectedStatuses.includes(option)) {
      setSelectedStatuses(selectedStatuses.filter((val) => val !== option));
    } else {
      setSelectedStatuses([...selectedStatuses, option]);
    }
  };

  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            {t('campaigns.calls.filters.status')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <H4 $bold>{t('campaigns.calls.filters.statusIs')}</H4>{' '}
            <H4>{selectedStatuses.join(', ').toLowerCase()}</H4>{' '}
            <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <FiltersContainer>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.calls.filters.statusIs')}</H4>
          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleRemoveStatuses}
            >
              {t('campaigns.calls.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer>
          {statusesOptions.map((option, index) => (
            <FiltersOptionContainer
              key={`status-filter-${index}`}
              onClick={() => handleStatusChange(option.value)}
            >
              <EuiCheckbox
                id={option.value}
                key={index}
                label={''}
                checked={selectedStatuses.includes(option.value)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleStatusChange(option.value);
                }}
              />
              <H4>{renderCallStatus(option.value)}</H4>
            </FiltersOptionContainer>
          ))}
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button onClick={handleApplySelectedStatuses} $size="S" color="white">
            {t('campaigns.calls.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </FiltersContainer>
    </EuiPopover>
  );
};

export default CallsStatusesFilter;

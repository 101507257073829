import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import {
  createVoiceConfiguration,
  getVoiceConfigurationsList,
  getAvailableVoices,
  getVoiceConfigurationById,
  CheckVoiceConfigurationResult,
  updateVoiceConfiguration,
  VoiceQuery,
  deleteVoiceConfiguration,
} from './voiceConfigurationApi';
import {
  VoiceList,
  VoiceConfiguration,
  VoiceConfigurationRequest,
} from '../../../../../generated/tenants/Api';

export interface VoiceConfigurationsState {
  list: {
    state: 'pending' | 'idle';
    value: Array<VoiceConfiguration>;
  };
  create: {
    state: 'pending' | 'idle';
    value?: CheckVoiceConfigurationResult;
  };
  availableVoices: {
    state: 'pending' | 'idle';
    value: VoiceList;
  };
  byId: {
    state: 'pending' | 'idle';
    value?: VoiceConfiguration;
  };
  update: {
    state: 'pending' | 'idle';
    value?: CheckVoiceConfigurationResult;
  };
  delete: {
    state: 'pending' | 'idle';
    value?: CheckVoiceConfigurationResult;
  };
}

const initialState: VoiceConfigurationsState = {
  list: {
    state: 'pending',
    value: [],
  },
  create: { state: 'pending' },
  update: { state: 'pending' },
  availableVoices: {
    state: 'pending',
    value: { voices: [] },
  },
  byId: {
    state: 'pending',
  },
  delete: {
    state: 'pending',
  },
};

export const getVoiceConfigurationsListAsyncThunk = createAsyncThunk(
  'voice_configurations/List',
  (id: string) => getVoiceConfigurationsList(id),
);
export const createVoiceConfigurationAsyncThunk = createAsyncThunk(
  'voice_configurations/Create',
  (data: VoiceConfigurationRequest) => createVoiceConfiguration(data),
);

export const RetrieveAvailableVoicesAsyncThunk = createAsyncThunk(
  'voice_configurations/getAvailableVoices',
  (data: VoiceQuery) => getAvailableVoices(data),
);

export const getVoiceConfigurationByIdAsyncThunk = createAsyncThunk(
  'voice_configurations/ById',
  (id: string) => getVoiceConfigurationById(id),
);

export const updateVoiceConfigurationAsyncThunk = createAsyncThunk(
  'voice_configurations/update',
  (args: { id: string; data: VoiceConfigurationRequest }) =>
    updateVoiceConfiguration(args.id, args.data),
);

export const deleteVoiceConfigurationAsyncThunk = createAsyncThunk(
  'voice_configurations/delete',
  (id: string) => deleteVoiceConfiguration(id),
);

export const voiceConfigurationsSlice = createSlice({
  name: 'voiceConfigurations',
  initialState,
  reducers: {
    clearCreateVoice: (state) => {
      state.create = { state: 'pending' };
    },
    clearUpdateVoice: (state) => {
      state.update = { state: 'pending' };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVoiceConfigurationsListAsyncThunk.pending, (state) => {
        state.list.state = 'pending';
      })
      .addCase(
        getVoiceConfigurationsListAsyncThunk.fulfilled,
        (state, action) => {
          state.list.state = 'idle';
          state.list.value = action.payload;
        },
      )
      .addCase(getVoiceConfigurationsListAsyncThunk.rejected, (state) => {
        state.list.state = 'idle';
      })
      .addCase(createVoiceConfigurationAsyncThunk.pending, (state) => {
        state.create.state = 'pending';
      })
      .addCase(
        createVoiceConfigurationAsyncThunk.fulfilled,
        (state, action) => {
          state.create.state = 'idle';
          state.create.value = action.payload;
        },
      )
      .addCase(createVoiceConfigurationAsyncThunk.rejected, (state) => {
        state.create.state = 'idle';
      })
      .addCase(RetrieveAvailableVoicesAsyncThunk.pending, (state) => {
        state.availableVoices.state = 'pending';
      })
      .addCase(RetrieveAvailableVoicesAsyncThunk.fulfilled, (state, action) => {
        state.availableVoices.state = 'idle';
        state.availableVoices.value = action.payload;
      })
      .addCase(RetrieveAvailableVoicesAsyncThunk.rejected, (state) => {
        state.availableVoices.state = 'idle';
      })
      .addCase(getVoiceConfigurationByIdAsyncThunk.pending, (state) => {
        state.byId.state = 'pending';
      })
      .addCase(
        getVoiceConfigurationByIdAsyncThunk.fulfilled,
        (state, action) => {
          state.byId.state = 'idle';
          state.byId.value = action.payload;
        },
      )
      .addCase(getVoiceConfigurationByIdAsyncThunk.rejected, (state) => {
        state.byId.state = 'idle';
      })
      .addCase(updateVoiceConfigurationAsyncThunk.pending, (state) => {
        state.update.state = 'pending';
      })
      .addCase(
        updateVoiceConfigurationAsyncThunk.fulfilled,
        (state, action) => {
          state.update.state = 'idle';
          state.update.value = action.payload;
        },
      )
      .addCase(updateVoiceConfigurationAsyncThunk.rejected, (state) => {
        state.update.state = 'idle';
      })
      .addCase(deleteVoiceConfigurationAsyncThunk.pending, (state) => {
        state.delete.state = 'pending';
      })
      .addCase(
        deleteVoiceConfigurationAsyncThunk.fulfilled,
        (state, action) => {
          state.delete.state = 'idle';
          state.delete.value = action.payload;
        },
      )
      .addCase(deleteVoiceConfigurationAsyncThunk.rejected, (state) => {
        state.delete.state = 'idle';
      });
  },
});

export const selectVoiceConfigurationsList = (state: RootState) =>
  state.voice_configuration.list.value;
export const selectAvailableVoices = (state: RootState) =>
  state.voice_configuration.availableVoices.value;
export const selectVoiceConfigurationById = (state: RootState) =>
  state.voice_configuration.byId.value;
export const checkVoiceConfigurationCreate = (state: RootState) =>
  state.voice_configuration.create;
export const checkVoiceConfigurationUpdate = (state: RootState) =>
  state.voice_configuration.update;
export const checkVoiceConfigurationDelete = (state: RootState) =>
  state.voice_configuration.delete;

export const { clearCreateVoice, clearUpdateVoice } =
  voiceConfigurationsSlice.actions;

export default voiceConfigurationsSlice.reducer;

import React from 'react';
import { CallsFiltersValues } from './CallsFilters';
import { EuiPopover, EuiDatePickerRange, EuiDatePicker } from '@elastic/eui';
import moment, { Moment } from 'moment';
import { H4, H5, fontIBM, Button } from '../../../../../App.style';
import {
  FilterButton,
  FilterBarButton,
  FiltersTopContainer,
  FiltersContentContainer,
  FiltersBottomContainer,
} from '../../../panel/bar/filters/CampaignFilters.style';
import { ChevronDownIcon } from '../../../../../../resources/icons-new/ChevronDownIcon';
import { PlusIcon } from '../../../../../../resources/icons-new/PlusIcon';
import { CalendarIcon } from '../../../../../../resources/icons-new/CalendarIcon';
import { useTranslation } from 'react-i18next';

interface CallsDatesFilterProps {
  barButton: boolean;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  selectedStartDate: string;
  setSelectedStartDate: React.Dispatch<React.SetStateAction<string>>;
  selectedEndDate: string;
  setSelectedEndDate: React.Dispatch<React.SetStateAction<string>>;
  filters: CallsFiltersValues;
  setFilters: (value: React.SetStateAction<CallsFiltersValues>) => void;
}

const CallsDatesFilter: React.FC<CallsDatesFilterProps> = ({
  barButton,
  isOpen,
  setIsOpen,
  selectedStartDate,
  setSelectedEndDate,
  selectedEndDate,
  setSelectedStartDate,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation();
  const onDatesChange = (start: Moment | null, end: Moment | null) => {
    if (start) setSelectedStartDate(start.format('YYYY-MM-DD'));
    if (end) setSelectedEndDate(end.format('YYYY-MM-DD'));
  };

  const handleClearDatesClick = () => {
    setSelectedStartDate('');
    setSelectedEndDate('');
    setFilters((prev) => ({
      ...prev,
      date: {
        start_date: undefined,
        end_date: undefined,
      },
    }));
  };

  const handleApplyDates = () => {
    setFilters((prev) => ({
      ...prev,
      date: {
        start_date: selectedStartDate || undefined,
        end_date: selectedEndDate || undefined,
      },
    }));
    setIsOpen(false);
  };

  const startDate = selectedStartDate ? moment(selectedStartDate) : null;
  const endDate = selectedEndDate ? moment(selectedEndDate) : null;

  const { start_date, end_date } = filters.date || {};

  const checkApplyButton = () => {
    if (startDate && endDate && endDate.isSameOrBefore(startDate)) {
      return true;
    } else if (startDate || endDate) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      button={
        !barButton ? (
          <FilterButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            style={{
              padding: '0px 8px',
              width: '110px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
          >
            {t('campaigns.calls.filters.date')}
            <PlusIcon />
          </FilterButton>
        ) : (
          <FilterBarButton
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <CalendarIcon />
            <H4>
              {`${
                start_date
                  ? moment(startDate).format('DD.MM.YYYY')
                  : 'undefined'
              } - ${
                end_date ? moment(endDate).format('DD.MM.YYYY') : 'undefined'
              }`}
            </H4>{' '}
            <ChevronDownIcon />
          </FilterBarButton>
        )
      }
      anchorPosition={barButton ? 'downLeft' : 'rightUp'}
      panelPaddingSize="none"
      hasArrow={false}
    >
      <div style={{ width: '300px' }}>
        <FiltersTopContainer>
          <H4 $bold>{t('campaigns.calls.filters.dateIs')}</H4>

          {barButton && (
            <H5
              $underlineHover
              color={'#127FBF'}
              style={{ cursor: 'pointer' }}
              onClick={handleClearDatesClick}
            >
              {t('campaigns.calls.filters.remove')}
            </H5>
          )}
        </FiltersTopContainer>

        <FiltersContentContainer
          style={{
            alignItems: 'center',
            paddingTop: 10,
          }}
        >
          <EuiDatePickerRange
            css={{
              width: 280,
              background: 'white',
              cursor: 'pointer',
            }}
            isInvalid={
              startDate && endDate ? endDate.isSameOrBefore(startDate) : false
            }
            startDateControl={
              <EuiDatePicker
                css={{
                  background: 'white',
                  fontFamily: fontIBM,
                  cursor: 'pointer',
                }}
                placeholder="start date"
                selected={startDate}
                onChange={(date) => onDatesChange(date, endDate)}
                startDate={startDate}
                endDate={endDate}
                aria-label="Start date"
                dateFormat="DD/MM/YYYY"
              />
            }
            endDateControl={
              <EuiDatePicker
                css={{
                  background: 'white',
                  fontFamily: fontIBM,
                  cursor: 'pointer',
                }}
                placeholder="end date"
                selected={endDate}
                onChange={(date) => onDatesChange(startDate, date)}
                startDate={startDate}
                endDate={endDate}
                aria-label="End date"
                dateFormat="DD/MM/YYYY"
              />
            }
          />
        </FiltersContentContainer>

        <FiltersBottomContainer>
          <Button
            onClick={handleApplyDates}
            $size="S"
            color="white"
            disabled={checkApplyButton()}
          >
            {t('campaigns.calls.filters.apply')}
          </Button>
        </FiltersBottomContainer>
      </div>
    </EuiPopover>
  );
};

export default CallsDatesFilter;

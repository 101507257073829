import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const CampaignsIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H1V15H16V16H0V0ZM14.8166 3.11302C15.0303 3.28789 15.0618 3.6029 14.887 3.81662L10.387 9.31662C10.2975 9.42595 10.166 9.49234 10.0249 9.49938C9.88382 9.50642 9.74634 9.45344 9.64645 9.35355L7.0602 6.76731L3.40437 11.7941C3.24195 12.0174 2.92924 12.0668 2.70591 11.9044C2.48259 11.7419 2.43321 11.4292 2.59563 11.2059L6.59563 5.70591C6.68164 5.58765 6.81511 5.51297 6.96089 5.50153C7.10667 5.49009 7.25015 5.54305 7.35355 5.64645L9.96288 8.25577L14.113 3.18338C14.2879 2.96966 14.6029 2.93816 14.8166 3.11302Z"
    />
  </StyledSvg>
);

import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../../../../store';
import { getLoginStateAsyncThunk } from '../../../session/api/sessionSlice';
import {
  checkSuperUserTokenAsyncThunk,
  createSuperUserAfterInvitationAsyncThunk,
} from './superAdminSlice';

export const superInvitationMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const startAppListening =
  superInvitationMiddleware.startListening as unknown as AppStartListening;
export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

startAppListening({
  actionCreator: createSuperUserAfterInvitationAsyncThunk.fulfilled,
  effect: (action, listenerApi) => {
    if (action.payload.status === 'success') {
      listenerApi.dispatch(getLoginStateAsyncThunk());
    }
  },
});

startAppListening({
  actionCreator: checkSuperUserTokenAsyncThunk.fulfilled,
  effect: (action, listenerApi) => {
    if (action.payload.status === 'success')
      listenerApi.dispatch(getLoginStateAsyncThunk());
  },
});

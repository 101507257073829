import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  LoginRequest,
  AppConfig,
  UserPublic,
} from '../../../../generated/public/Api';
import { RootState } from '../../../store';
import { getConfig, getLoginState, login, logout } from './sessionAPI';
import { getCookieConsent } from '../cookies';
import { getTheme } from '../localStorage';

export type Theme = 'light' | 'dark';
export interface SessionState {
  theme: Theme;
  config: {
    state: 'pending' | 'idle';
    value?: AppConfig;
    tenantSubdomain?: string;
    showCookieInfo?: boolean;
  };
  user: {
    state: 'pending' | 'idle';
    value?: UserPublic;
    error?: boolean;
  };
}

const initialState: SessionState = {
  theme: getTheme(),
  config: {
    state: 'pending',
    showCookieInfo: !getCookieConsent(),
  },
  user: {
    state: 'pending',
  },
};

export const getConfigAsyncThunk = createAsyncThunk('session/getConfig', () =>
  getConfig(),
);

export const getLoginStateAsyncThunk = createAsyncThunk(
  'session/getLoginState',
  () => getLoginState(),
);

export const loginAsyncThunk = createAsyncThunk(
  'session/login',
  (args: LoginRequest) => login(args),
);

export const logoutAsyncThunk = createAsyncThunk('session/logout', () =>
  logout(),
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme == 'light' ? 'dark' : 'light';
    },
    acceptCookies: (state) => {
      if (state.config.value) {
        state.config.showCookieInfo = false;
      }
    },
    disableLoginError: (state) => {
      state.user.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigAsyncThunk.pending, (state) => {
        state.config.state = 'pending';
      })
      .addCase(getConfigAsyncThunk.fulfilled, (state, action) => {
        state.config.state = 'idle';
        state.config.value = action.payload;
        if (window.location.hostname.endsWith(action.payload.base_domain)) {
          state.config.tenantSubdomain = window.location.hostname.slice(
            0,
            -action.payload.base_domain.length - 1,
          );
        }
      })
      .addCase(getConfigAsyncThunk.rejected, (state) => {
        state.config.state = 'idle';
      })
      .addCase(getLoginStateAsyncThunk.pending, (state) => {
        state.user.state = 'pending';
      })
      .addCase(getLoginStateAsyncThunk.fulfilled, (state, action) => {
        state.user.state = 'idle';
        state.user.value = action.payload;
      })
      .addCase(getLoginStateAsyncThunk.rejected, (state) => {
        state.user.state = 'idle';
      })
      .addCase(loginAsyncThunk.pending, (state) => {
        state.user.state = 'pending';
      })
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        state.user.state = 'idle';
        state.user.error = false;
        state.user.value = action.payload;
      })
      .addCase(loginAsyncThunk.rejected, (state) => {
        state.user.state = 'idle';
        state.user.error = true;
      })
      .addCase(logoutAsyncThunk.pending, (state) => {
        state.user.state = 'pending';
      })
      .addCase(logoutAsyncThunk.fulfilled, (state) => {
        state.user.state = 'idle';
        state.user.value = undefined;
      })
      .addCase(logoutAsyncThunk.rejected, (state) => {
        state.user.state = 'idle';
      });
  },
});

export const { toggleTheme, acceptCookies, disableLoginError } =
  sessionSlice.actions;

export const redirectToPublic = createAction('session/redirectToPublic');
export const redirectToTenant = createAction<string>(
  'session/redirectToTenant',
);

export const selectConfig = (state: RootState) => state.session.config;
export const selectUser = (state: RootState) => state.session.user;
export const selectTheme = (state: RootState) => state.session.theme;

export default sessionSlice.reducer;

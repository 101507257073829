import React, { useEffect, useState } from 'react';
import { VindicationContentContainer } from '../../Vindication.style';
import { BotConfigurationRequest } from '../../../../../../generated/tenants/Api';
import BotStatementBuilder from '../../tokenInput/BotStatementBuilder';
import {
  DropResult,
  EuiAccordion,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiSwitch,
  EuiToolTip,
  euiDragDropReorder,
} from '@elastic/eui';
import { DialogueFailIcon } from '../../../../../../resources/icons-new/DialogueFailIcon';
import { DialogueSphereIcon } from '../../../../../../resources/icons-new/DialogueSphereIcon';
import { DialogueSuccessIcon } from '../../../../../../resources/icons-new/DialogueSuccessIcon';
import { H4, H6 } from '../../../../../App.style';
import {
  DialogueVerificationQuestionContainer,
  DialogueVerificationQuestionsContainer,
  DialogueVerificationQuestionsContentContainer,
  DialogueVerificationQuestionsRandom,
  DialogueVerificationQuestionsTitle,
} from './DialogueVerification.style';
import { DotsIcon } from '../../../../../../resources/icons-new/DotsIcon';
import DialogueActionSelect from '../../actionsSelects/DialogueActionSelect';
import { WarningIcon } from '../../../../../../resources/icons-new/WarningIcon';

interface DialogueVerificationProps {
  vindicationDialogue: BotConfigurationRequest;
  setVindicationDialogue: React.Dispatch<
    React.SetStateAction<BotConfigurationRequest>
  >;
}

const DialogueVerification: React.FC<DialogueVerificationProps> = ({
  vindicationDialogue,
  setVindicationDialogue,
}) => {
  const handleUpdateGenerationsConfig = (
    newValue: string,
    configKey: string,
  ) => {
    setVindicationDialogue((prevState) => ({
      ...prevState,
      generations_config: {
        ...prevState.generations_config,
        [configKey]: newValue, // Dynamicznie aktualizujemy klucz na podstawie configKey
      },
    }));
  };

  const handleUpdateStrategyConfig = (
    newValue: string | boolean | string[],
    configKey: string,
  ) => {
    setVindicationDialogue((prevState) => ({
      ...prevState,
      strategy_config: {
        ...prevState.strategy_config,
        [configKey]: newValue, // Dynamicznie aktualizujemy klucz na podstawie configKey
      },
    }));
  };

  const renderAccordionTitle = (
    text: string,
    icon: 'success' | 'fail' | 'sphere',
  ) => {
    const iconMap = {
      success: <DialogueSuccessIcon />,
      fail: <DialogueFailIcon />,
      sphere: <DialogueSphereIcon />,
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {iconMap[icon]}
        <H4 $medium>{text}</H4>
      </div>
    );
  };

  const [firstQuestions, setFirstQuestions] = useState<
    VerificationQuestionType[]
  >([
    {
      content: `Imię matki`,
      id: 'mothers_name',
      key: 'REQ-INF-mothers_name-1-1',
    },
    {
      content: `Imię ojca`,
      id: 'fathers_name',
      key: 'REQ-INF-fathers_name-1-1',
    },
  ]);

  //jesli jest wylaczony random, to ustawia najpierw te wybrane potem te nie wybrane, jesli random to bierze z tablicy
  useEffect(() => {
    if (!vindicationDialogue.strategy_config?.['FIRST_TURN_RANDOM']) {
      const botFirstQuestions: string[] =
        vindicationDialogue.strategy_config?.['FIRST_TURN_QUESTIONS'] ?? [];

      if (botFirstQuestions) {
        const orderedSelectedQuestions: VerificationQuestionType[] =
          botFirstQuestions
            .map((qId): VerificationQuestionType | undefined =>
              allOptionsDragDrop.find(({ id }) => id === qId),
            )
            .filter(
              (question): question is VerificationQuestionType =>
                question !== undefined,
            ); // Type guard to remove undefined

        // Step 2: Filter out the unselected questions
        const unselectedQuestions: VerificationQuestionType[] =
          allOptionsDragDrop.filter(
            ({ id }) => !botFirstQuestions.includes(id),
          );

        // Step 3: Concatenate the selected (and ordered) questions with the unselected ones
        const finalQuestionsOrder: VerificationQuestionType[] =
          orderedSelectedQuestions.concat(unselectedQuestions);

        setFirstQuestions(finalQuestionsOrder);
        // Use finalQuestionsOrder as needed
      }
    } else {
      setFirstQuestions(allOptionsDragDrop);
    }
  }, [
    vindicationDialogue.strategy_config?.['FIRST_TURN_RANDOM'],
    vindicationDialogue.strategy_config?.['FIRST_TURN_QUESTIONS'],
  ]);

  const [secondQuestions, setSecondQuestions] = useState<
    VerificationQuestionType[]
  >([
    {
      content: `Imię matki`,
      id: 'mothers_name',
      key: 'REQ-INF-mothers_name-1-1',
    },
  ]);

  useEffect(() => {
    if (!vindicationDialogue.strategy_config?.['SECOND_TURN_RANDOM']) {
      const botSecondQuestions: string[] =
        vindicationDialogue.strategy_config?.['SECOND_TURN_QUESTIONS'] ?? [];

      if (botSecondQuestions) {
        const orderedSelectedQuestions: VerificationQuestionType[] =
          botSecondQuestions
            .map((qId): VerificationQuestionType | undefined =>
              allOptionsDragDrop.find(({ id }) => id === qId),
            )
            .filter(
              (question): question is VerificationQuestionType =>
                question !== undefined,
            ); // Type guard to remove undefined

        // Step 2: Filter out the unselected questions
        const unselectedQuestions: VerificationQuestionType[] =
          allOptionsDragDrop.filter(
            ({ id }) => !botSecondQuestions.includes(id),
          );

        // Step 3: Concatenate the selected (and ordered) questions with the unselected ones
        const finalQuestionsOrder: VerificationQuestionType[] =
          orderedSelectedQuestions.concat(unselectedQuestions);

        setSecondQuestions(finalQuestionsOrder);
        // Use finalQuestionsOrder as needed
      }
    } else {
      setSecondQuestions(allOptionsDragDrop);
    }
  }, [
    vindicationDialogue.strategy_config?.['SECOND_TURN_RANDOM'],
    vindicationDialogue.strategy_config?.['SECOND_TURN_QUESTIONS'],
  ]);

  interface VerificationQuestionType {
    content: string;
    id: string;
    key: string;
  }

  const allOptionsDragDrop: Array<VerificationQuestionType> = [
    {
      content: `Imię matki`,
      id: 'mothers_name',
      key: 'REQ-INF-mothers_name-1-1',
    },
    {
      content: `Imię ojca`,
      id: 'fathers_name',
      key: 'REQ-INF-fathers_name-1-1',
    },
    {
      content: 'Kod pocztowy adresu korespondencyjnego',
      id: `zip_code`,
      key: 'REQ-INF-zip_code-1-1',
    },
    {
      content: `Posiadanie kredytu`,
      id: 'product_possesion',
      key: 'REQ-INF-product_possession-1-1',
    },
    {
      content: `Adres zamieszkania`,
      id: 'address',
      key: 'REQ-INF-address-1-1',
    },
    {
      content: `Data urodzenia`,
      id: 'birth_date',
      key: 'REQ-INF-birth_date-1-1',
    },
    {
      content: `Trzy ostatnie cyfry PESEL`,
      id: 'last_pesel_digits',
      key: 'REQ-INF-last_pesel_digits-1-1',
    },
    {
      content: `Numer dowodu osobistego`,
      id: 'id',
      key: 'REQ-INF-id-1-1',
    },
  ];

  const handleQuestionSwitchChange = (
    questionId: string,
    isChecked: boolean,
    turnType: 'FIRST_TURN_QUESTIONS' | 'SECOND_TURN_QUESTIONS',
  ) => {
    setVindicationDialogue((prevDialogue) => {
      // Make a shallow copy of the strategy_config to ensure React detects the change
      const strategyConfigCopy = { ...prevDialogue.strategy_config };

      // Determine which question list to update based on turnType
      const currentQuestions = strategyConfigCopy[turnType] || [];

      // Update the questions list
      const updatedQuestions = isChecked
        ? [...currentQuestions, questionId] // Add question ID if checked
        : currentQuestions.filter((id: string) => id !== questionId); // Remove question ID if unchecked

      // Ensure we're creating a new array so React detects a change
      strategyConfigCopy[turnType] = updatedQuestions;

      return {
        ...prevDialogue,
        strategy_config: strategyConfigCopy,
      };
    });
  };

  const handleDragFirstQuestionEnd = ({ source, destination }: DropResult) => {
    // Early return if the item is dropped outside the droppable area or if the location hasn't changed
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    // Remove unnecessary comments and refactor the code to adjust the destination index if needed
    const askedQuestionsCount =
      vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS.length ?? 0;

    // Check if moving within bounds, otherwise adjust to move to the end of asked questions
    const adjustedDestinationIndex =
      !isFirstTurnQuestionsRandom && destination.index >= askedQuestionsCount
        ? askedQuestionsCount - 1
        : destination.index;

    const newFirstQuestions = euiDragDropReorder(
      firstQuestions,
      source.index,
      adjustedDestinationIndex,
    );

    setFirstQuestions(newFirstQuestions);

    const updatedFirstTurnQuestions = newFirstQuestions
      .map((q) => q.id) // Extract the IDs from the reordered list
      .filter((id) =>
        vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS.includes(id),
      ); // Keep only selected IDs

    // Assuming setVindicationDialogue is your state setter for vindicationDialogue
    setVindicationDialogue((prev) => ({
      ...prev,
      strategy_config: {
        ...prev.strategy_config,
        FIRST_TURN_QUESTIONS: updatedFirstTurnQuestions,
      },
    }));
  };

  const handleDragSecondQuestionEnd = ({ source, destination }: DropResult) => {
    // Early return if the item is dropped outside the droppable area or if the location hasn't changed
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    const askedQuestionsCount =
      vindicationDialogue.strategy_config?.SECOND_TURN_QUESTIONS.length ?? 0;

    // Check if moving within bounds, otherwise adjust to move to the end of asked questions
    const adjustedDestinationIndex =
      !isSecondTurnQuestionRandom && destination.index >= askedQuestionsCount
        ? askedQuestionsCount - 1
        : destination.index;

    const newSecondQuestions = euiDragDropReorder(
      secondQuestions,
      source.index,
      adjustedDestinationIndex,
    );

    setSecondQuestions(newSecondQuestions);

    const updatedSecondTurnQuestions = newSecondQuestions
      .map((q) => q.id) // Extract the IDs from the reordered list
      .filter((id) =>
        vindicationDialogue.strategy_config?.SECOND_TURN_QUESTIONS.includes(id),
      ); // Keep only selected IDs

    // Update the state for vindicationDialogue with the new second turn questions
    setVindicationDialogue((prev) => ({
      ...prev,
      strategy_config: {
        ...prev.strategy_config,
        SECOND_TURN_QUESTIONS: updatedSecondTurnQuestions,
      },
    }));
  };

  const isFirstTurnQuestionsRandom =
    vindicationDialogue.strategy_config?.['FIRST_TURN_RANDOM'];

  const isSecondTurnQuestionRandom =
    vindicationDialogue.strategy_config?.['SECOND_TURN_RANDOM'];

  useEffect(() => {
    if (
      vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS.length === 0
    ) {
      setVindicationDialogue((prev) => ({
        ...prev,
        strategy_config: {
          ...prev.strategy_config,
          SECOND_TURN: false,
          SECOND_TURN_QUESTIONS: [],
        },
      }));
    }
  }, [vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS]);

  useEffect(() => {
    checkForDuplicateQuestionAndDisplayError();
  }, [
    vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS,
    vindicationDialogue.strategy_config?.SECOND_TURN_QUESTIONS,
  ]);

  const [duplicatedQuestionError, setDuplicationQuestionError] =
    useState<boolean>(false);
  const checkForDuplicateQuestionAndDisplayError = () => {
    const firstQuestions =
      vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS;
    const secondQuestions =
      vindicationDialogue.strategy_config?.SECOND_TURN_QUESTIONS;

    // Sprawdzamy, czy obie tury zawierają dokładnie jedno pytanie
    if (firstQuestions.length === 1 && secondQuestions.length === 1) {
      // Sprawdzamy, czy to samo pytanie jest w obu turach
      if (firstQuestions[0] === secondQuestions[0]) {
        setDuplicationQuestionError(true);
        return;
      }
    } else {
      setDuplicationQuestionError(false);
    }
  };

  const updateIdentificationAction = (selectedValue: string) => {
    const updatedDialogue = {
      ...vindicationDialogue,
      strategy_config: {
        ...vindicationDialogue.strategy_config,
        BRANCHES: {
          ...vindicationDialogue.strategy_config?.BRANCHES,
          VERIFICATION: selectedValue,
        },
      },
    };

    setVindicationDialogue(updatedDialogue);
  };
  return (
    <VindicationContentContainer>
      <BotStatementBuilder
        title="Rozpoczęcie modułu Verification"
        value={
          vindicationDialogue.generations_config?.[
            'INF-STA-verification_intro_1_1'
          ]
        }
        onSave={handleUpdateGenerationsConfig}
        configKey={'INF-STA-verification_intro_1_1'}
        customTokens={vindicationDialogue.custom_tokens ?? []}
      />

      <EuiAccordion
        id={'first-verification-round'}
        initialIsOpen
        buttonContent={
          <div>
            <EuiSwitch
              compressed
              checked={true}
              onChange={(e) => {
                e.stopPropagation();
                console.log(e);
              }}
              label={<H4 $medium>I tura weryfikacji</H4>}
            />
          </div>
        }
      >
        <DialogueVerificationQuestionsContainer>
          <DialogueVerificationQuestionsTitle>
            <H4 $wrap>
              Zaznacz dane o które bot może zapytać w tej rundzie (w
              preferowanej kolejności)
            </H4>
          </DialogueVerificationQuestionsTitle>

          <DialogueVerificationQuestionsContentContainer>
            <EuiDragDropContext onDragEnd={handleDragFirstQuestionEnd}>
              <EuiDroppable droppableId="DROPPABLE_AREA">
                {firstQuestions.map(({ content, id, key }, idx) => {
                  const isSelected =
                    vindicationDialogue.strategy_config?.[
                      'FIRST_TURN_QUESTIONS'
                    ].includes(id);

                  const isDragDisabled =
                    isFirstTurnQuestionsRandom ||
                    (!isFirstTurnQuestionsRandom && !isSelected);
                  return (
                    <EuiDraggable
                      style={{ marginBottom: 8 }}
                      isDragDisabled={isDragDisabled}
                      spacing="m"
                      key={id}
                      index={idx}
                      draggableId={id}
                    >
                      {(provided, state) => (
                        <DialogueVerificationQuestionContainer>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'center',
                                marginBottom: 8,
                              }}
                            >
                              {!isDragDisabled && <DotsIcon />}
                              <EuiSwitch
                                compressed
                                checked={isSelected}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleQuestionSwitchChange(
                                    id,
                                    e.target.checked,
                                    'FIRST_TURN_QUESTIONS',
                                  );
                                }}
                                label={<H4 $medium>{content}</H4>}
                              />
                              {state.isDragging && ' ✨'}
                            </div>
                            {isSelected && (
                              <BotStatementBuilder
                                value={
                                  vindicationDialogue.generations_config?.[key]
                                }
                                onSave={handleUpdateGenerationsConfig}
                                configKey={key}
                                customTokens={
                                  vindicationDialogue.custom_tokens ?? []
                                }
                              />
                            )}
                          </div>
                        </DialogueVerificationQuestionContainer>
                      )}
                    </EuiDraggable>
                  );
                })}
              </EuiDroppable>
            </EuiDragDropContext>
          </DialogueVerificationQuestionsContentContainer>

          <DialogueVerificationQuestionsRandom>
            <H6 $medium>zadwać pytania w kolejności losowej?</H6>
            <EuiSwitch
              checked={isFirstTurnQuestionsRandom}
              onChange={(e) =>
                handleUpdateStrategyConfig(
                  e.target.checked,
                  'FIRST_TURN_RANDOM',
                )
              }
              compressed
              label={<H6>{isFirstTurnQuestionsRandom ? 'tak' : 'nie'}</H6>}
            />
          </DialogueVerificationQuestionsRandom>
        </DialogueVerificationQuestionsContainer>
      </EuiAccordion>

      <EuiAccordion
        id={'first-verification-round'}
        forceState={
          vindicationDialogue.strategy_config?.['SECOND_TURN']
            ? 'open'
            : 'closed'
        }
        buttonContent={
          <div>
            {vindicationDialogue.strategy_config?.FIRST_TURN_QUESTIONS
              .length === 0 ? (
              <EuiToolTip
                content={'Wybierz cos 1 turze zeby wybrac tu'}
                position="top"
              >
                <EuiSwitch
                  compressed
                  disabled={true}
                  checked={vindicationDialogue.strategy_config?.['SECOND_TURN']}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleUpdateStrategyConfig(e.target.checked, 'SECOND_TURN');
                  }}
                  label={<H4 $medium>II tura weryfikacji</H4>}
                />
              </EuiToolTip>
            ) : (
              <EuiSwitch
                compressed
                checked={vindicationDialogue.strategy_config?.['SECOND_TURN']}
                onChange={(e) => {
                  e.stopPropagation();
                  handleUpdateStrategyConfig(e.target.checked, 'SECOND_TURN');
                }}
                label={<H4 $medium>II tura weryfikacji</H4>}
              />
            )}
          </div>
        }
      >
        <DialogueVerificationQuestionsContainer>
          <DialogueVerificationQuestionsTitle>
            <H4 $wrap>
              Zaznacz dane o które bot może zapytać w tej rundzie (w
              preferowanej kolejności)
            </H4>
          </DialogueVerificationQuestionsTitle>

          <DialogueVerificationQuestionsContentContainer>
            <EuiDragDropContext onDragEnd={handleDragSecondQuestionEnd}>
              {duplicatedQuestionError && (
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    marginLeft: 8,
                    marginBottom: 10,
                  }}
                >
                  <WarningIcon color="#FF0000" />
                  <H4 style={{ color: '#FF0000' }}>
                    To pytanie może zostać zadane przez bota w poprzedniej
                    rundzie weryfikacji. Zaznacz dodatkowe lub inne pole.
                  </H4>
                </div>
              )}
              <EuiDroppable droppableId="DROPPABLE_AREA_2">
                {secondQuestions.map(({ content, id, key }, idx) => {
                  const isSelected =
                    vindicationDialogue.strategy_config?.[
                      'SECOND_TURN_QUESTIONS'
                    ].includes(id);

                  const isDragDisabled =
                    isSecondTurnQuestionRandom ||
                    (!isSecondTurnQuestionRandom && !isSelected);
                  return (
                    <EuiDraggable
                      style={{ marginBottom: 8 }}
                      isDragDisabled={isDragDisabled}
                      spacing="m"
                      key={id}
                      index={idx}
                      draggableId={id}
                    >
                      {(provided, state) => (
                        <DialogueVerificationQuestionContainer>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'center',
                                marginBottom: 8,
                              }}
                            >
                              {!isDragDisabled && <DotsIcon />}
                              <EuiSwitch
                                compressed
                                checked={isSelected}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleQuestionSwitchChange(
                                    id,
                                    e.target.checked,
                                    'SECOND_TURN_QUESTIONS',
                                  );
                                }}
                                label={<H4 $medium>{content}</H4>}
                              />
                              {state.isDragging && ' ✨'}
                            </div>
                            {isSelected && (
                              <BotStatementBuilder
                                value={
                                  vindicationDialogue.generations_config?.[key]
                                }
                                onSave={handleUpdateGenerationsConfig}
                                configKey={key}
                                readOnly
                                customTokens={
                                  vindicationDialogue.custom_tokens ?? []
                                }
                              />
                            )}
                          </div>
                        </DialogueVerificationQuestionContainer>
                      )}
                    </EuiDraggable>
                  );
                })}
              </EuiDroppable>
            </EuiDragDropContext>
          </DialogueVerificationQuestionsContentContainer>

          <DialogueVerificationQuestionsRandom>
            <H6 $medium>zadwać pytania w kolejności losowej?</H6>
            <EuiSwitch
              checked={isSecondTurnQuestionRandom}
              onChange={(e) =>
                handleUpdateStrategyConfig(
                  e.target.checked,
                  'SECOND_TURN_RANDOM',
                )
              }
              compressed
              label={<H6>{isSecondTurnQuestionRandom ? 'tak' : 'nie'}</H6>}
            />
          </DialogueVerificationQuestionsRandom>
        </DialogueVerificationQuestionsContainer>
      </EuiAccordion>

      <div style={{ marginLeft: 50 }}>
        <EuiAccordion
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'Jeżeli weryfikacja przebiegła pomyślnie',
            'success',
          )}
        >
          <BotStatementBuilder
            value={
              vindicationDialogue.generations_config?.[
                'FEE-SUM-verification_passed-1-1'
              ]
            }
            onSave={handleUpdateGenerationsConfig}
            configKey={'FEE-SUM-verification_passed-1-1'}
            customTokens={vindicationDialogue.custom_tokens ?? []}
          />
          <DialogueActionSelect staticStep="PAYMENT_STATUS" />
        </EuiAccordion>
      </div>

      <div style={{ marginLeft: 50 }}>
        <EuiAccordion
          id={'myAccordion'}
          buttonContent={renderAccordionTitle(
            'Jeżeli weryfikacja nie przebiegła pomyślnie',
            'fail',
          )}
        >
          <div style={{ display: 'flex', gap: 16, paddingTop: 16 }}>
            <div style={{ width: 120 }}>
              <H6>ze wzgledu na błedne informacje</H6>
            </div>
            <BotStatementBuilder
              value={
                vindicationDialogue.generations_config?.[
                  'INF-STA-verification_failed-1-1'
                ]
              }
              onSave={handleUpdateGenerationsConfig}
              configKey={'INF-STA-verification_failed-1-1'}
              customTokens={vindicationDialogue.custom_tokens ?? []}
            />
          </div>

          <div style={{ display: 'flex', gap: 16, paddingTop: 16 }}>
            <div style={{ width: 120 }}>
              <H6>ze wzgledu na brak odpowiedzi</H6>
            </div>
            <BotStatementBuilder
              value={
                vindicationDialogue.generations_config?.[
                  'INF-STA-verification_failed_empty_item-1-1'
                ]
              }
              onSave={handleUpdateGenerationsConfig}
              configKey={'INF-STA-verification_failed_empty_item-1-1'}
              customTokens={vindicationDialogue.custom_tokens ?? []}
            />
          </div>

          <DialogueActionSelect
            value={vindicationDialogue.strategy_config?.BRANCHES.VERIFICATION}
            onChange={updateIdentificationAction}
          />
        </EuiAccordion>
      </div>
    </VindicationContentContainer>
  );
};

export default DialogueVerification;

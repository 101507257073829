import React, { useEffect } from 'react';
import { SecurityContainer, SecurityContentContainer } from './Security.style';
import { H2, H3 } from '../../../../App.style';
import { FormRow } from '../../../processes/forms/ProcessForm.style';
import { EuiSuperSelect } from '@elastic/eui';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import {
  getUserSettingsAsyncThunk,
  selectUserSettings,
  updateUserSettingsAsyncThunk,
} from '../api/userSettingsSlice';
import { AppLanguageEnum } from '../../../../../generated/tenants/Api';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserSettingsAsyncThunk());
  }, []);

  const userSettings = useAppSelector(selectUserSettings);

  const languagesOptions = [
    {
      value: 'POLISH',
      inputDisplay: 'Polski',
    },
    {
      value: 'ENGLISH',
      inputDisplay: 'English',
    },
    {
      value: 'SPANISH',
      inputDisplay: 'Español',
      disabled: true,
    },
  ];

  const handleLanguageChange = (value: string) => {
    dispatch(
      updateUserSettingsAsyncThunk({
        ...userSettings,
        app_language: value as AppLanguageEnum,
      }),
    ).then(() => {
      dispatch(getUserSettingsAsyncThunk());
    });
  };

  return (
    <SecurityContainer>
      <H2 $medium>{t('settings.users.language')}</H2>

      <SecurityContentContainer style={{ flexDirection: 'column' }}>
        <FormRow label={<H3>{t('settings.users.language')}</H3>}>
          <EuiSuperSelect
            valueOfSelected={userSettings.value?.app_language}
            options={languagesOptions}
            onChange={handleLanguageChange}
            compressed
          />
        </FormRow>
      </SecurityContentContainer>
    </SecurityContainer>
  );
};

export default Language;

import React from 'react';
import { GroupedData } from '../calendarFunctions';
import { EuiDatePickerRange, EuiDatePicker } from '@elastic/eui';
import { H3 } from '../../../../../App.style';
import { GroupsContainer } from '../WorkingHours.style';
import { useTranslation } from 'react-i18next';

interface WorkingHoursPreviewGroupsProps {
  workingHoursData: GroupedData[];
}

const WorkingHoursPreviewGroups: React.FC<WorkingHoursPreviewGroupsProps> = ({
  workingHoursData,
}) => {
  const { t } = useTranslation();

  function translateDays(days: string[]): string {
    const translatedDays = days.map((day) =>
      t(`presets.calendarForm.${day.toLowerCase()}`),
    );
    return translatedDays.join(', ');
  }

  return (
    <>
      {workingHoursData &&
        workingHoursData.map((group, index) => (
          <div key={`group-${group.days.length}-${index}`}>
            {group.workingHours.length > 0 && (
              <GroupsContainer>
                <H3>{translateDays(group.days)}</H3>
                {group.workingHours.map((hours, i) => (
                  <div key={`hours-${i}`} style={{ width: 200 }}>
                    <EuiDatePickerRange
                      readOnly
                      startDateControl={
                        <EuiDatePicker
                          selected={hours.startDate}
                          startDate={hours.startDate}
                          endDate={hours.endDate}
                          aria-label="Start date"
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          css={{
                            textAlign: 'center',
                            fontFamily: 'IBM Plex Sans',
                            fontSize: 14,
                          }}
                        />
                      }
                      endDateControl={
                        <EuiDatePicker
                          selected={hours.endDate}
                          startDate={hours.startDate}
                          endDate={hours.endDate}
                          aria-label="End date"
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          css={{
                            textAlign: 'center',
                            fontFamily: 'IBM Plex Sans',
                            fontSize: 14,
                          }}
                        />
                      }
                    />
                  </div>
                ))}
              </GroupsContainer>
            )}
          </div>
        ))}
    </>
  );
};

export default WorkingHoursPreviewGroups;

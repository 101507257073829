import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const PlusSmallIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <g id="icon">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C4.13807 1 4.25 1.11193 4.25 1.25V3.75H6.75C6.88807 3.75 7 3.86193 7 4C7 4.13807 6.88807 4.25 6.75 4.25H4.25V6.75C4.25 6.88807 4.13807 7 4 7C3.86193 7 3.75 6.88807 3.75 6.75V4.25H1.25C1.11193 4.25 1 4.13807 1 4C1 3.86193 1.11193 3.75 1.25 3.75H3.75V1.25C3.75 1.11193 3.86193 1 4 1Z"
        fill="black"
      />
    </g>
  </StyledSvg>
);

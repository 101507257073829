import React from 'react';
import { Strategy } from '../../../../../../../generated/tenants/Api';
import Modal from '../../../../../../../common/modals/Modal';
import { H3 } from '../../../../../../App.style';
import {
  ArchiveStrategyContainer,
  ArchiveStrategyContent,
} from '../../../../../../../common/modals/Modals.style';
import { useAppDispatch } from '../../../../../../../common/hooks';
import {
  getStrategiesListAsyncThunk,
  getArchivedStrategiesListAsyncThunk,
  clearUnarchiveStrategy,
  unarchiveStrategyAsyncThunk,
} from '../api/strategiesSlice';
import { useTranslation } from 'react-i18next';

interface RestoreStrategyModalProps {
  isVisible: boolean;
  closeModal: () => void;
  selectedStrategy: Strategy;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RestoreStrategyModal: React.FC<RestoreStrategyModalProps> = ({
  isVisible,
  closeModal,
  selectedStrategy,
  setIsLoadingVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleRestoreClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoadingVisible(true);

    dispatch(unarchiveStrategyAsyncThunk(selectedStrategy.id))
      .then(() => {
        dispatch(getStrategiesListAsyncThunk());
        dispatch(getArchivedStrategiesListAsyncThunk());
        dispatch(clearUnarchiveStrategy());
        closeModal();
      })
      .finally(() => {
        setIsLoadingVisible(false);
      });

    return false;
  };

  const body = (
    <ArchiveStrategyContainer>
      <ArchiveStrategyContent>
        <H3>
          {t('settings.adminPanel.manageWorkspaces.form.restoreQuestion')}
        </H3>
      </ArchiveStrategyContent>
    </ArchiveStrategyContainer>
  );
  return (
    <Modal
      headerTitle={`${t('settings.adminPanel.manageWorkspaces.form.restore')}${
        selectedStrategy.name
      }`}
      isVisible={isVisible}
      onCancel={closeModal}
      buttonText={t('common.restore')}
      children={body}
      onConfirm={handleRestoreClick}
    />
  );
};

export default RestoreStrategyModal;

import React, { useState } from 'react';
import {
  SuperUsersBarBottomContainer,
  SuperUsersBarContainer,
  SuperUsersBarTopContainer,
  SuperUsersContainer,
  SuperUsersListSearchContainer,
} from './SuperUsers.style';
import { Button, H1 } from '../../../../../App.style';
import SearchInput from '../../../../../../common/inputs/SearchInput';
import InviteUserIcon from '../../../../../../resources/icons-new/InviteUserIcon';
import { useAutoClosePopup } from '../../../../../../common/popups/useAutoClosePopup';
import SuperUsersTable from './list/SuperUsersTable';
import SuccessPopup from '../../../../../../common/popups/SuccessPopup';
import ErrorPopup from '../../../../../../common/popups/ErrorPopup';
import AddSuperUserModal from './list/AddSuperUserModal';
import { useTranslation } from 'react-i18next';

const SuperUsers = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  const [isAddSuperUserModalVisible, setIsAddSuperUserModalVisible] =
    useState<boolean>(false);
  const openAddSuperUserModal = () => setIsAddSuperUserModalVisible(true);
  const closeAddSuperUserModal = () => setIsAddSuperUserModalVisible(false);

  const {
    isVisible: superUserInvitedPopupVisible,
    showPopup: showInvitePopup,
    closePopup: closeInvitePopup,
  } = useAutoClosePopup();

  const {
    isVisible: successPermissionChangePopupVisible,
    showPopup: showSuccessPermissionsPopup,
    closePopup: closeShowSuccessPopup,
  } = useAutoClosePopup();

  const {
    isVisible: errorPermissionChangePopupVisible,
    showPopup: showErrorPermissionsPopup,
    closePopup: closeShowErrorPopup,
  } = useAutoClosePopup();
  return (
    <SuperUsersContainer>
      {successPermissionChangePopupVisible && (
        <SuccessPopup
          text={t('superAdminPanel.superUsers.popups.permissionEditSuccess')}
          onClose={closeShowSuccessPopup}
        />
      )}

      {errorPermissionChangePopupVisible && (
        <ErrorPopup
          text={t('superAdminPanel.superUsers.popups.permissionEditError')}
          onClose={closeShowErrorPopup}
        />
      )}

      {superUserInvitedPopupVisible && (
        <SuccessPopup
          text={t('superAdminPanel.superUsers.popups.invited')}
          onClose={closeInvitePopup}
        />
      )}

      <AddSuperUserModal
        isVisible={isAddSuperUserModalVisible}
        closeModal={closeAddSuperUserModal}
        showPopup={showInvitePopup}
      />

      <SuperUsersBarContainer>
        <SuperUsersBarTopContainer>
          <H1 $bold>{t('superAdminPanel.superUsers.title')}</H1>
          <Button
            $size={'S'}
            $styleType="NORMAL"
            onClick={openAddSuperUserModal}
          >
            {t('superAdminPanel.superUsers.invite')}
            <InviteUserIcon />
          </Button>
        </SuperUsersBarTopContainer>
        <SuperUsersBarBottomContainer>
          <SuperUsersListSearchContainer>
            <SearchInput value={search} setValue={setSearch} />
          </SuperUsersListSearchContainer>
        </SuperUsersBarBottomContainer>
      </SuperUsersBarContainer>

      <SuperUsersTable
        search={search}
        showSuccessPermissionsPopup={showSuccessPermissionsPopup}
        showErrorPermissionsPopup={showErrorPermissionsPopup}
      />
    </SuperUsersContainer>
  );
};

export default SuperUsers;

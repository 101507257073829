import {
  EuiFlexGroup,
  EuiStepsHorizontal,
  EuiFlexItem,
  EuiText,
  EuiAccordion,
  EuiPanel,
  EuiButton,
  EuiCheckbox,
  EuiSelect,
} from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VindicationBackToPreset } from '../../BotsStartPanel';
import TokenInput from '../tokenInputComponents/TokenInput';
import { SUPPORTED_MODULES } from './VinIdentification';

interface GenerationsConfigVinAuxModules {
  'REQ-INF-may_we_continue-1-1': string;
  'INF-STA-consultant_unavailable-1-1': string;
  'INF-STA-refusal_to_be_recordered-continue': string;
  'INF-STA-refusal_to_be_recorded-1-1': string;
  'INF-STA-refusal_to_pay-1-1': string;
  'INF-STA-refusal_to_pay-not_verified-1-1': string;
  'INF-STA-refusal_to_pay-not_identified-1-1': string;
  'SOC-FIN-1-1': string;
  'INF-STA-CALLBACK-TIME-1-1': string;
  'REQ-INF-callback_number-1-1': string;
  'INF-STA-no_number-1-1': string;
  'FEE-POS-new_number-1-1': string;
}

interface BotStrategyConfigVinAuxModules {
  IMPORT_REDIRECT_TO_HUMAN_FOX_DEN_SKILL: boolean;
  BRANCHES: {
    REDIRECT_TO_HUMAN: { fail: string };
    REFUSAL: string;
    INFORM_PHONENUMBER: { repeat: string; sms: string };
    no_debt: { verified: string; not_verified: string };
    is_dead: string;
    wrong_number: string;
    CALLBACK_NUMBER: { no_number: string; new_number: string };
  };
  ASK_QUESTION_MAY_WE_CONTINUE: boolean;
  REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE: boolean;
  HARD_REFUSAL_TO_PAY: boolean;
  IMPORT_INFORM_PHONENUMBER_SKILL: boolean;
  ASK_ABOUT_REPEAT_OR_SMS: boolean;

  //IF USER HAS SELECTED CALLBACK_DATE_TIME somewhere in BRANCHES, set it to true
  IMPORT_CALLBACK_DATE_TIME_SKILL: boolean;
  IMPORT_CALLBACK_NUMBER_SKILL: boolean;
}

const VinAuxModules = () => {
  const navigate = useNavigate();

  const [vinAuxModulesGenerationsConfig, setVinAuxModulesGenerationsConfig] =
    useState<GenerationsConfigVinAuxModules>();

  const [vinAuxModulesBotStrategyConfig, setVinAuxModulesBotStrategyConfig] =
    useState<BotStrategyConfigVinAuxModules>();

  //if there are items in storage, set them, otherwise set standard values
  useEffect(() => {
    localStorage.setItem('lastVisitedStep', '5');
    const storageGenerationsConfig = localStorage.getItem(
      'vinAuxModulesGenerationsConfig',
    );
    if (storageGenerationsConfig !== null) {
      setVinAuxModulesGenerationsConfig(JSON.parse(storageGenerationsConfig));
    } else {
      setVinAuxModulesGenerationsConfig({
        'REQ-INF-may_we_continue-1-1':
          'Czy możemy jednak dokończyć rozmowę tutaj?',
        'INF-STA-consultant_unavailable-1-1':
          'Niestety nie mogę teraz przekierować rozmowy do konsultanta',
        'INF-STA-refusal_to_be_recordered-continue':
          'Rozumiem. W takim wypadku proszę o rozłączenie się. <PAUZA> Zakładam, że jednak możemy kontynuować',
        'INF-STA-refusal_to_be_recorded-1-1':
          'Rozumiem. W takim przypadku prosimy o kontakt z nami',
        'INF-STA-refusal_to_pay-1-1':
          'Kwota zaległości powinna do nas wpłynąć w ciągu <deadline>  dni. Przypominam, że brak wpłaty może się wiązać z rozpoczęciem procesu windykacyjnego',
        'INF-STA-refusal_to_pay-not_verified-1-1':
          'Rozumiem. W takiej sytuacji proszę o kontakt.',
        'INF-STA-refusal_to_pay-not_identified-1-1':
          'Rozumiem. w takiej sytuacji proszę o kontakt.',
        'SOC-FIN-1-1':
          'Dziękuję za rozmowę i zyczę miłego dnia. Do usłyszenia!',
        'INF-STA-CALLBACK-TIME-1-1':
          'W takim wypadku zadzwonię ponownie w innym terminie.',
        'REQ-INF-callback_number-1-1':
          'Pod jakim numerem mogę się skontaktować z Panią Janiną Kowalską?',
        'INF-STA-no_number-1-1':
          'Rozumiem. Numer, pod który dzwonię, został nam przekazany jako należący do Pani Janiny Kowalskiej. Celem wyjaśnienia bardzo proszę o kontakt',
        'FEE-POS-new_number-1-1':
          'Dziękuję. Proszę również o przekazanie Pani Janinie prośby o kontakt',
      });
    }

    const storageBotStrategyConfig = localStorage.getItem(
      'vinAuxModulesBotStrategyConfig',
    );

    if (storageBotStrategyConfig !== null) {
      setVinAuxModulesBotStrategyConfig(JSON.parse(storageBotStrategyConfig));
    } else {
      setVinAuxModulesBotStrategyConfig({
        IMPORT_REDIRECT_TO_HUMAN_FOX_DEN_SKILL: true,
        BRANCHES: {
          REDIRECT_TO_HUMAN: { fail: SUPPORTED_MODULES[0].value },
          REFUSAL: SUPPORTED_MODULES[3].value,
          INFORM_PHONENUMBER: {
            repeat: SUPPORTED_MODULES[3].value,
            sms: SUPPORTED_MODULES[3].value,
          },
          no_debt: {
            verified: SUPPORTED_MODULES[4].value,
            not_verified: SUPPORTED_MODULES[2].value,
          },
          is_dead: SUPPORTED_MODULES[3].value,
          wrong_number: SUPPORTED_MODULES[3].value,
          CALLBACK_NUMBER: {
            no_number: SUPPORTED_MODULES[3].value,
            new_number: SUPPORTED_MODULES[2].value,
          },
        },
        ASK_QUESTION_MAY_WE_CONTINUE: true,
        REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE: true,
        HARD_REFUSAL_TO_PAY: true,
        ASK_ABOUT_REPEAT_OR_SMS: false,
        IMPORT_INFORM_PHONENUMBER_SKILL: true,
        IMPORT_CALLBACK_DATE_TIME_SKILL: true,
        IMPORT_CALLBACK_NUMBER_SKILL: true,
      });
    }
  }, []);

  //all changes to localStorage
  useEffect(() => {
    localStorage.setItem(
      'vinAuxModulesGenerationsConfig',
      JSON.stringify(vinAuxModulesGenerationsConfig),
    );
  }, [vinAuxModulesGenerationsConfig]);

  useEffect(() => {
    localStorage.setItem(
      'vinAuxModulesBotStrategyConfig',
      JSON.stringify(vinAuxModulesBotStrategyConfig),
    );
  }, [vinAuxModulesBotStrategyConfig]);

  //open/close accordions logic
  const [showAccordion, setShowAccordion] = useState<{
    redirectToHuman: boolean;
    refusal: boolean;
    contactNumber: boolean;
    callback: boolean;
    newNumber: boolean;
    noDebt: boolean;
    debtorDeath: boolean;
    botMistake: boolean;
    goodbye: boolean;
  }>({
    redirectToHuman: false,
    refusal: false,
    contactNumber: false,
    callback: false,
    newNumber: false,
    noDebt: false,
    debtorDeath: false,
    botMistake: false,
    goodbye: false,
  });

  //steps logic
  let steps: string[] = [];
  if (localStorage.getItem('vinCompletedSteps') !== null) {
    steps = JSON.parse(localStorage.getItem('vinCompletedSteps') as string);
  }
  const horizontalSteps = [
    {
      title: 'Identification',
      status: steps.includes('1') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/1');
      },
    },
    {
      title: 'Verification',
      status: steps.includes('2') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/2');
      },
    },
    {
      title: 'Payment status',
      status: steps.includes('3') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/3');
      },
    },
    {
      title: 'Negotiations',
      status: steps.includes('4') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/4');
      },
    },
    {
      title: 'Auxiliary modules',
      status: 'current',
      onClick: () => {
        navigate('/dashboard/bots/new/1/5');
      },
    },
    {
      title: 'Summary',
      status: steps.includes('6') ? 'complete' : 'incomplete',
      onClick: () => {
        navigate('/dashboard/bots/new/1/6');
      },
    },
  ];

  //last button logic
  const handleSubmitAuxModulesClick = () => {
    let arr: string[];
    const completedSteps = localStorage.getItem('vinCompletedSteps');
    if (completedSteps !== null && !completedSteps?.includes('5')) {
      arr = JSON.parse(completedSteps);
      arr.push('5');
      arr.sort();
      localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
    } else if (completedSteps && completedSteps?.length > 0) {
      console.log('this step was already added');
    } else {
      arr = ['5'];
      localStorage.setItem('vinCompletedSteps', JSON.stringify(arr));
    }
    navigate('/dashboard/bots/new/1/6');
  };

  const discardBotConfiguration = () => {
    navigate('/dashboard/bots');
  };

  //back to presets button logic
  const [backToPresetsButtonVisible, setBackToPresetsButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    const botConfigurationType = localStorage.getItem('botConfigurationType');
    if (botConfigurationType !== null && botConfigurationType === 'new') {
      setBackToPresetsButtonVisible(true);
    } else {
      setBackToPresetsButtonVisible(false);
    }
  }, []);

  return (
    <>
      {vinAuxModulesBotStrategyConfig && vinAuxModulesGenerationsConfig && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            flexDirection: 'column',
            margin: 10,
            padding: 10,

            overflow: 'auto',
            marginTop: 80,
          }}
        >
          <EuiFlexItem
            css={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
          >
            {backToPresetsButtonVisible && <VindicationBackToPreset />}

            <div>
              <EuiButton fill onClick={discardBotConfiguration}>
                Discard
              </EuiButton>
            </div>
          </EuiFlexItem>
          <EuiStepsHorizontal
            steps={horizontalSteps as Omit<EuiStepHorizontalProps, 'step'>[]}
          />
          <EuiFlexItem>
            <EuiText css={{ fontSize: 22 }}>MODUŁY POMOCNICZE</EuiText>
            <EuiText>
              Moduły pomocnicze służą do obsługi sytuacji, jakie mogą wydarzyć
              się w rozmowie windykacyjnej poza czterema podstawowymi czterema
              etapami.
            </EuiText>
            <EuiText>
              Aktywacja tych modułów może następować poprzez przekierowanie z
              określonego modułu lub poprzez komunikat rozmówcy w prawie każdym
              miejscu w dialogu (np. gdy negocjujemy termin płatności i dłużnik
              nagle powiadamia nas, że coś się wydarzyło i prosi o telefon w
              innym terminie).
            </EuiText>
          </EuiFlexItem>
          <div style={{ marginTop: 30 }}>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Redirect to human"
                forceState={showAccordion.redirectToHuman ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    redirectToHuman: !showAccordion.redirectToHuman,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                        The module is launched when the user asks to connect to
                        consultant.
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem
                      css={{
                        paddingTop: 20,
                        flexDirection: 'row',
                        gap: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText>
                        Should the bot ask for the continuation of the current
                        conversation
                      </EuiText>
                      <EuiCheckbox
                        id="beggining-negotiations"
                        checked={
                          vinAuxModulesBotStrategyConfig.ASK_QUESTION_MAY_WE_CONTINUE
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            ASK_QUESTION_MAY_WE_CONTINUE: e.target.checked,
                          })
                        }
                      />
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        Should the bot ask to continue the current conversation?
                        What should the question be?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules1'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'REQ-INF-may_we_continue-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'REQ-INF-may_we_continue-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot's statement say that redirection to
                        a consultant is not possible?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules2'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-consultant_unavailable-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-consultant_unavailable-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <EuiText>
                        Bot reaction when redirection is disabled
                      </EuiText>
                      <EuiSelect
                        options={SUPPORTED_MODULES}
                        value={
                          vinAuxModulesBotStrategyConfig.BRANCHES
                            .REDIRECT_TO_HUMAN.fail
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            BRANCHES: {
                              ...vinAuxModulesBotStrategyConfig.BRANCHES,
                              REDIRECT_TO_HUMAN: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES
                                  .REDIRECT_TO_HUMAN,
                                fail: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              redirectToHuman: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Refusal"
                forceState={showAccordion.refusal ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    refusal: !showAccordion.refusal,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 20, fontWeight: 'bold' }}>
                        The debtor does not agree to the recording of the
                        conversation
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem css={{ flexDirection: 'row', gap: 10 }}>
                      <EuiText>
                        Should the bot try to continue the conversation after
                        refusing to record?
                      </EuiText>
                      <EuiCheckbox
                        id="continue_conversation_after_refuse"
                        checked={
                          vinAuxModulesBotStrategyConfig.REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE:
                              e.target.checked,
                          })
                        }
                      />
                    </EuiFlexItem>
                    {vinAuxModulesBotStrategyConfig.REFUSAL_TO_BE_RECORDER_TRY_TO_CONTINUE && (
                      <EuiFlexItem
                        css={{
                          width: '80%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                          What should the bot say when you try to continue the
                          conversation?
                        </EuiText>

                        <TokenInput
                          tokenInputId={'vinAuxModules3'}
                          text={
                            vinAuxModulesGenerationsConfig[
                              'INF-STA-refusal_to_be_recordered-continue'
                            ]
                          }
                          inputHook={(text) => {
                            setVinAuxModulesGenerationsConfig({
                              ...vinAuxModulesGenerationsConfig,
                              'INF-STA-refusal_to_be_recordered-continue': text,
                            });
                          }}
                        />
                      </EuiFlexItem>
                    )}
                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot say when the debtor informs him that
                        he does not agree to the recording of the telephone
                        conversation?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules4'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-refusal_to_be_recorded-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-refusal_to_be_recorded-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem css={{ flexDirection: 'row', gap: 10 }}>
                      <EuiText>
                        Should the bot handle a hard denial of payment?
                      </EuiText>
                      <EuiCheckbox
                        id="continue_conversation_after_refuse"
                        checked={
                          vinAuxModulesBotStrategyConfig.HARD_REFUSAL_TO_PAY
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            HARD_REFUSAL_TO_PAY: e.target.checked,
                          })
                        }
                      />
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot say if the debtor strongly refuses
                        to pay?
                      </EuiText>

                      <EuiText>• Target statement</EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules5'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-refusal_to_pay-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-refusal_to_pay-1-1': text,
                          });
                        }}
                      />

                      <EuiText>• Statement for an unverified user</EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules6'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-refusal_to_pay-not_verified-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-refusal_to_pay-not_verified-1-1': text,
                          });
                        }}
                      />

                      <EuiText>• Statement for an unidentified user</EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules7'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-refusal_to_pay-not_identified-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-refusal_to_pay-not_identified-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem css={{ gap: 10 }}>
                      <EuiText>
                        What should be the further behavior of the bot after
                        this module
                      </EuiText>
                      <EuiSelect
                        options={SUPPORTED_MODULES}
                        value={vinAuxModulesBotStrategyConfig.BRANCHES.REFUSAL}
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            BRANCHES: {
                              ...vinAuxModulesBotStrategyConfig.BRANCHES,
                              REFUSAL: e.target.value,
                            },
                          })
                        }
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              refusal: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Contact number module"
                forceState={showAccordion.contactNumber ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    contactNumber: !showAccordion.contactNumber,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem css={{ flexDirection: 'row', gap: 10 }}>
                      <EuiText>
                        Whether the bot has to ask a question about the
                        repetition or sending of the SMS
                      </EuiText>
                      <EuiCheckbox
                        id="ask_about_continue_or_sms"
                        checked={
                          vinAuxModulesBotStrategyConfig.ASK_ABOUT_REPEAT_OR_SMS
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            ASK_ABOUT_REPEAT_OR_SMS: e.target.checked,
                          })
                        }
                      />
                    </EuiFlexItem>
                    {vinAuxModulesBotStrategyConfig.ASK_ABOUT_REPEAT_OR_SMS && (
                      <>
                        <EuiFlexItem css={{ gap: 10 }}>
                          <EuiText>
                            Bot reaction after repeating the number
                          </EuiText>
                          <EuiSelect
                            options={SUPPORTED_MODULES}
                            value={
                              vinAuxModulesBotStrategyConfig.BRANCHES
                                .INFORM_PHONENUMBER.repeat
                            }
                            onChange={(e) =>
                              setVinAuxModulesBotStrategyConfig({
                                ...vinAuxModulesBotStrategyConfig,
                                BRANCHES: {
                                  ...vinAuxModulesBotStrategyConfig.BRANCHES,
                                  INFORM_PHONENUMBER: {
                                    ...vinAuxModulesBotStrategyConfig.BRANCHES
                                      .INFORM_PHONENUMBER,
                                    repeat: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </EuiFlexItem>

                        <EuiFlexItem css={{ gap: 10 }}>
                          <EuiText>
                            Bot reaction after sending a text message
                          </EuiText>
                          <EuiSelect
                            options={SUPPORTED_MODULES}
                            value={
                              vinAuxModulesBotStrategyConfig.BRANCHES
                                .INFORM_PHONENUMBER.sms
                            }
                            onChange={(e) =>
                              setVinAuxModulesBotStrategyConfig({
                                ...vinAuxModulesBotStrategyConfig,
                                BRANCHES: {
                                  ...vinAuxModulesBotStrategyConfig.BRANCHES,
                                  INFORM_PHONENUMBER: {
                                    ...vinAuxModulesBotStrategyConfig.BRANCHES
                                      .INFORM_PHONENUMBER,
                                    sms: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </EuiFlexItem>
                      </>
                    )}
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              contactNumber: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Callback module"
                forceState={showAccordion.callback ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    callback: !showAccordion.callback,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 22, fontWeight: 'bold' }}>
                        The debtor cannot talk now
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot's reply be when the debtor informs
                        him that he is busy when the bot called him?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules8'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-CALLBACK-TIME-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-CALLBACK-TIME-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <EuiText>
                        After this statement, the bot goes to the Farewell
                        module
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              callback: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Module for retrieving a new phone number"
                forceState={showAccordion.newNumber ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    newNumber: !showAccordion.newNumber,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 22, fontWeight: 'bold' }}>
                        Module for retrieving a new telephone number
                      </EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot's answer be when the interlocutor
                        informs him that the debtor is available on the new
                        phone number?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules9'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'REQ-INF-callback_number-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'REQ-INF-callback_number-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot say when the interlocutor informs
                        him that he does not know the debtor's new phone number?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules10'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'INF-STA-no_number-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'INF-STA-no_number-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <EuiText>
                        What should be the bot's behavior after this statement?
                      </EuiText>
                      <div>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinAuxModulesBotStrategyConfig.BRANCHES
                              .CALLBACK_NUMBER.no_number
                          }
                          onChange={(e) =>
                            setVinAuxModulesBotStrategyConfig({
                              ...vinAuxModulesBotStrategyConfig,
                              BRANCHES: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES,
                                CALLBACK_NUMBER: {
                                  ...vinAuxModulesBotStrategyConfig.BRANCHES
                                    .CALLBACK_NUMBER,
                                  no_number: e.target.value,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot's answer be when the interlocutor
                        gives him the new debtor's number?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules11'}
                        text={
                          vinAuxModulesGenerationsConfig[
                            'FEE-POS-new_number-1-1'
                          ]
                        }
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'FEE-POS-new_number-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <EuiText>
                        What should be the bot's behavior after this statement?
                      </EuiText>
                      <div>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinAuxModulesBotStrategyConfig.BRANCHES
                              .CALLBACK_NUMBER.new_number
                          }
                          onChange={(e) =>
                            setVinAuxModulesBotStrategyConfig({
                              ...vinAuxModulesBotStrategyConfig,
                              BRANCHES: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES,
                                CALLBACK_NUMBER: {
                                  ...vinAuxModulesBotStrategyConfig.BRANCHES
                                    .CALLBACK_NUMBER,
                                  new_number: e.target.value,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              newNumber: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Bot reaction to the information about the lack of debt"
                forceState={showAccordion.noDebt ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    noDebt: !showAccordion.noDebt,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 22, fontWeight: 'bold' }}>
                        What should be the bot's reaction to the information
                        about the lack of debt
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem css={{ gap: 10 }}>
                      <EuiText>• If the caller is verified</EuiText>
                      <EuiSelect
                        options={SUPPORTED_MODULES}
                        value={
                          vinAuxModulesBotStrategyConfig.BRANCHES.no_debt
                            .verified
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            BRANCHES: {
                              ...vinAuxModulesBotStrategyConfig.BRANCHES,
                              no_debt: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES
                                  .no_debt,
                                verified: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </EuiFlexItem>

                    <EuiFlexItem css={{ gap: 10 }}>
                      <EuiText>• If the caller is not verified</EuiText>
                      <EuiSelect
                        options={SUPPORTED_MODULES}
                        value={
                          vinAuxModulesBotStrategyConfig.BRANCHES.no_debt
                            .not_verified
                        }
                        onChange={(e) =>
                          setVinAuxModulesBotStrategyConfig({
                            ...vinAuxModulesBotStrategyConfig,
                            BRANCHES: {
                              ...vinAuxModulesBotStrategyConfig.BRANCHES,
                              no_debt: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES
                                  .no_debt,
                                not_verified: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              noDebt: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Bot reaction to information about death"
                forceState={showAccordion.debtorDeath ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    debtorDeath: !showAccordion.debtorDeath,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 22, fontWeight: 'bold' }}>
                        The interlocutor informs about the death of the debtor
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem
                      css={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <EuiText>
                        What should be the bot's reaction to the information
                        about the debtor's death?
                      </EuiText>
                      <div>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinAuxModulesBotStrategyConfig.BRANCHES.is_dead
                          }
                          onChange={(e) =>
                            setVinAuxModulesBotStrategyConfig({
                              ...vinAuxModulesBotStrategyConfig,
                              BRANCHES: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES,
                                is_dead: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              debtorDeath: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="The bot's reaction to a mistake"
                forceState={showAccordion.botMistake ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    botMistake: !showAccordion.botMistake,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 22, fontWeight: 'bold' }}>
                        The caller informs that the bot reached the wrong number
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem
                      css={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <EuiText>
                        What should be the bot's behavior when the interlocutor
                        informs that the bot reached the wrong number.
                      </EuiText>
                      <div>
                        <EuiSelect
                          options={SUPPORTED_MODULES}
                          value={
                            vinAuxModulesBotStrategyConfig.BRANCHES.wrong_number
                          }
                          onChange={(e) =>
                            setVinAuxModulesBotStrategyConfig({
                              ...vinAuxModulesBotStrategyConfig,
                              BRANCHES: {
                                ...vinAuxModulesBotStrategyConfig.BRANCHES,
                                wrong_number: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              botMistake: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiAccordion
                id="start"
                buttonContent="Farewell
                module"
                forceState={showAccordion.goodbye ? 'open' : 'closed'}
                onToggle={() =>
                  setShowAccordion({
                    ...showAccordion,
                    goodbye: !showAccordion.goodbye,
                  })
                }
              >
                <EuiPanel color="subdued">
                  <EuiFlexGroup
                    css={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EuiFlexItem>
                      <EuiText css={{ fontSize: 22, fontWeight: 'bold' }}>
                        Ending the conversation
                      </EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem
                      css={{
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EuiText css={{ fontSize: 25, fontWeight: 'bold' }}>
                        What should the bot ending the conversation be like?
                      </EuiText>

                      <TokenInput
                        tokenInputId={'vinAuxModules12'}
                        text={vinAuxModulesGenerationsConfig['SOC-FIN-1-1']}
                        inputHook={(text) => {
                          setVinAuxModulesGenerationsConfig({
                            ...vinAuxModulesGenerationsConfig,
                            'SOC-FIN-1-1': text,
                          });
                        }}
                      />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <div>
                        <EuiButton
                          fill
                          onClick={() =>
                            setShowAccordion({
                              ...showAccordion,
                              goodbye: false,
                            })
                          }
                        >
                          Close this section
                        </EuiButton>
                      </div>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
              </EuiAccordion>
            </EuiFlexItem>
          </div>
          <EuiFlexItem>
            <div>
              <EuiButton fill onClick={handleSubmitAuxModulesClick}>
                Next
              </EuiButton>
            </div>
          </EuiFlexItem>
        </div>
      )}
    </>
  );
};

export default VinAuxModules;

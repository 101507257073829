import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AnalyticsRequest,
  AnalyticsOverviewResponse,
  CallStatusDistributionData,
  CallStatusDistributionRequest,
  ProcessStatusChartResponse,
  StrategyDashboardData,
} from '../../../../generated/tenants/Api';
import {
  getDashboardData,
  getProcessCallStatusChartData,
  getProcessesMetricsOverview,
  getProcessesStatusCharts,
} from './analyticsApi';
import { RootState } from '../../../store';

export interface AnalyticsState {
  processesCallStatus: {
    state: 'pending' | 'idle';
    value?: CallStatusDistributionData;
  };
  overview: {
    state: 'pending' | 'idle';
    value?: AnalyticsOverviewResponse;
  };
  processStatus: {
    state: 'pending' | 'idle';
    value?: ProcessStatusChartResponse;
  };
  dashboard: {
    state: 'pending' | 'idle';
    value?: StrategyDashboardData;
  };
}

const initialState: AnalyticsState = {
  processesCallStatus: {
    state: 'pending',
  },
  overview: {
    state: 'pending',
  },
  processStatus: {
    state: 'pending',
  },
  dashboard: {
    state: 'pending',
  },
};

export const getProcessCallStatusChartDataAsyncThunk = createAsyncThunk(
  'analytics/process',
  (args: { processId: string; data: CallStatusDistributionRequest }) =>
    getProcessCallStatusChartData(args.processId, args.data),
);

export const getProcessesMetricsOverviewAsyncThunk = createAsyncThunk(
  'analytics/overview',
  (args: { processId: string; data: AnalyticsRequest }) =>
    getProcessesMetricsOverview(args.processId, args.data),
);

export const getProcessesStatusChartsAsyncThunk = createAsyncThunk(
  'analytics/process_status',
  (args: { processId: string; data: AnalyticsRequest }) =>
    getProcessesStatusCharts(args.processId, args.data),
);

export const getDashboardDataAsyncThunk = createAsyncThunk(
  'analytics/dashboard',
  (strategyId: string) => getDashboardData(strategyId),
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProcessCallStatusChartDataAsyncThunk.pending, (state) => {
        state.processesCallStatus.state = 'pending';
      })
      .addCase(
        getProcessCallStatusChartDataAsyncThunk.fulfilled,
        (state, action) => {
          state.processesCallStatus.state = 'idle';
          state.processesCallStatus.value = action.payload;
        },
      )
      .addCase(getProcessCallStatusChartDataAsyncThunk.rejected, (state) => {
        state.processesCallStatus.state = 'idle';
      })
      .addCase(getProcessesMetricsOverviewAsyncThunk.pending, (state) => {
        state.overview.state = 'pending';
      })
      .addCase(
        getProcessesMetricsOverviewAsyncThunk.fulfilled,
        (state, action) => {
          state.overview.state = 'idle';
          state.overview.value = action.payload;
        },
      )
      .addCase(getProcessesMetricsOverviewAsyncThunk.rejected, (state) => {
        state.overview.state = 'idle';
      })
      .addCase(getProcessesStatusChartsAsyncThunk.pending, (state) => {
        state.processStatus.state = 'pending';
      })
      .addCase(
        getProcessesStatusChartsAsyncThunk.fulfilled,
        (state, action) => {
          state.processStatus.state = 'idle';
          state.processStatus.value = action.payload;
        },
      )
      .addCase(getProcessesStatusChartsAsyncThunk.rejected, (state) => {
        state.processStatus.state = 'idle';
      })

      .addCase(getDashboardDataAsyncThunk.pending, (state) => {
        state.dashboard.state = 'pending';
      })
      .addCase(getDashboardDataAsyncThunk.fulfilled, (state, action) => {
        state.dashboard.state = 'idle';
        state.dashboard.value = action.payload;
      })
      .addCase(getDashboardDataAsyncThunk.rejected, (state) => {
        state.dashboard.state = 'idle';
      });
  },
});

export const selectProcessCallStatusChart = (state: RootState) =>
  state.analytics.processesCallStatus.value;

export const selectProcessMetricsOverview = (state: RootState) =>
  state.analytics.overview.value;

export const selectProcessStatus = (state: RootState) =>
  state.analytics.processStatus.value;

export const selectDashboardData = (state: RootState) =>
  state.analytics.dashboard.value;
export default analyticsSlice.reducer;

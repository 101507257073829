import React from 'react';
import { IconProps, StyledSvg } from './Icon';

export const BillingsIcon: React.FC<IconProps> = ({
  color,
  style,
  onClick,
  $disabled,
  $pointer,
}) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    color={color}
    style={style}
    onClick={$disabled ? undefined : onClick}
    $disabled={$disabled}
    $pointer={$pointer}
  >
    <path
      d="M14.5 3C14.7761 3 15 3.22386 15 3.5L15 12.5C15 12.7761 14.7761 13 14.5 13H1.5C1.22386 13 1 12.7761 1 12.5L1 3.5C1 3.22386 1.22386 3 1.5 3L14.5 3ZM1.5 2C0.671573 2 -1.93325e-08 2.67157 -4.31802e-08 3.5L-3.02262e-07 12.5C-3.2611e-07 13.3284 0.671573 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5L16 3.5C16 2.67157 15.3284 2 14.5 2L1.5 2Z"
      fill="white"
    />
    <path
      d="M3 8.5C3 8.22386 3.22386 8 3.5 8H12.5C12.7761 8 13 8.22386 13 8.5C13 8.77614 12.7761 9 12.5 9H3.5C3.22386 9 3 8.77614 3 8.5ZM3 10.5C3 10.2239 3.22386 10 3.5 10H9.5C9.77614 10 10 10.2239 10 10.5C10 10.7761 9.77614 11 9.5 11H3.5C3.22386 11 3 10.7761 3 10.5Z"
      fill="white"
    />
    <path
      d="M3 5.5C3 5.22386 3.22386 5 3.5 5H12.5C12.7761 5 13 5.22386 13 5.5L13 6.5C13 6.77614 12.7761 7 12.5 7H3.5C3.22386 7 3 6.77614 3 6.5L3 5.5Z"
      fill="white"
    />
  </StyledSvg>
);

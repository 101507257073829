import React from 'react';
import { useNavigate } from 'react-router-dom';
import { H6, H4 } from '../../../App.style';
import { CampaignOverview } from '../../../../generated/tenants/Api';
import { Label } from '../../presets/labels/Labels.style';
import {
  DateContent,
  DetailsRowContent,
  DetailsLeftContainer,
  DetailsRightContainer,
  DoneTotalRowContainer,
  LabelsRowContainer,
  LabelsContainer,
  SeeMoreContainer,
  CampaignsTableRow,
  CampaignsTableData,
} from './CampaignsPanel.style';
import { formatDate, renderStatus } from './campaignTableFunctions';
import { ArrowRightIcon } from '../../../../resources/icons-new/ArrowRightIcon';
import { useTranslation } from 'react-i18next';

interface CampaignRowProps {
  campaign: CampaignOverview;
}

const CampaignRow: React.FC<CampaignRowProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CampaignsTableRow
      key={campaign.id}
      onClick={() => navigate(`/dashboard/campaigns/${campaign.id}`)}
    >
      <CampaignsTableData>
        <DateContent>
          <H4>{formatDate(campaign.start_date)}</H4>
        </DateContent>
      </CampaignsTableData>

      <CampaignsTableData>
        <H4>{formatDate(campaign.end_date)}</H4>
      </CampaignsTableData>

      <CampaignsTableData>
        <H4>{campaign.process_name}</H4>
      </CampaignsTableData>
      <CampaignsTableData>
        <H4>{renderStatus(campaign.status, 'text')}</H4>
      </CampaignsTableData>

      <CampaignsTableData>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            marginTop: -8,
          }}
        >
          <DetailsRowContent>
            <DetailsLeftContainer>
              <H4>{t('campaigns.table.outbound')}</H4>
            </DetailsLeftContainer>

            <DetailsRightContainer>
              <H4>{campaign.outbound_calls_count}</H4>
            </DetailsRightContainer>
          </DetailsRowContent>

          <DetailsRowContent>
            <DetailsLeftContainer>
              <H4>{t('campaigns.table.inbound')}</H4>
            </DetailsLeftContainer>

            <DetailsRightContainer>
              <H4>{campaign.inbound_calls_count}</H4>
            </DetailsRightContainer>
          </DetailsRowContent>

          <DetailsRowContent>
            <DetailsLeftContainer>
              <H4>{t('campaigns.table.cc')}</H4>
            </DetailsLeftContainer>

            <DetailsRightContainer>
              <H4>{campaign.call_center_calls_count}</H4>
            </DetailsRightContainer>
          </DetailsRowContent>
        </div>
      </CampaignsTableData>

      <CampaignsTableData>
        <DoneTotalRowContainer>
          <H4>{campaign.closed_records_count}</H4>
          <H4 style={{ paddingLeft: 4 }} $grey>
            / {campaign.records_count}
          </H4>
        </DoneTotalRowContainer>
      </CampaignsTableData>

      <CampaignsTableData $labels>
        <LabelsRowContainer>
          <LabelsContainer>
            {campaign.labels.map((label, index) => {
              return (
                <Label key={`label-${index}`} color={label.color as string}>
                  <H6>{label.text}</H6>
                </Label>
              );
            })}
          </LabelsContainer>
        </LabelsRowContainer>
      </CampaignsTableData>
      <CampaignsTableData style={{ cursor: 'pointer' }}>
        <SeeMoreContainer>
          <H4>{t('campaigns.table.seeMore')}</H4>
          <ArrowRightIcon />
        </SeeMoreContainer>
      </CampaignsTableData>
    </CampaignsTableRow>
  );
};

export default CampaignRow;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks';
import { CampaignNonWorkingDaysConfiguration } from '../../../../../generated/tenants/Api';
import {
  getProcessesListAsyncThunk,
  selectProcessesList,
} from '../../../processes/api/processSlice';
import { selectStrategyId } from '../../../settings/adminPanel/components/strategies/api/strategiesSlice';
import PresetDeleteModal from '../../PresetDeleteModal';
import { PresetContainer } from '../../PresetsPanel.style';
import BackToList from '../../shared/BackToList';
import {
  checkBotDaysOffCreate,
  checkBotDaysOffDelete,
  checkBotDaysOffUpdate,
  createBotDaysOffAsyncThunk,
  deleteBotDaysOffAsyncThunk,
  getBotDaysOffListAsyncThunk,
  selectBotDaysOffList,
} from './api/botDaysOffSlice';
import BotDaysOffCreate from './BotDaysOffCreate';
import BotDaysOffDetailed from './BotDaysOffDetailed';
import BotDaysOffList from './BotDaysOffList';
import usePermissions from '../../../users/usePermissions';
import LoadingModal from '../../../../../common/modals/LoadingModal';

const BotDaysOff = () => {
  const dispatch = useAppDispatch();
  const strategy = useAppSelector(selectStrategyId);

  const [botDaysOffState, setBotDaysOffState] = useState<
    'create' | 'list' | 'detailed'
  >('list');

  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('process_campaign');

  const handleCreateBotDaysOffClick = () => setBotDaysOffState('create');

  const handleDetailedViewClick = (botDaysOffId: string) => {
    const botDaysOffDetailed = findBotById(botDaysOffId);
    if (botDaysOffDetailed) setSelectedBotDaysOff(botDaysOffDetailed);
    setBotDaysOffState('detailed');
  };

  const handleBackToList = () => setBotDaysOffState('list');

  useEffect(() => {
    dispatch(getBotDaysOffListAsyncThunk(strategy));
    dispatch(
      getProcessesListAsyncThunk({
        id: strategy,
        query: { is_archived: true },
      }),
    );
  }, [strategy]);

  const apiBotDaysOffList = useAppSelector(selectBotDaysOffList);

  const apiProcesessList = useAppSelector(selectProcessesList);

  function findBotById(id: string) {
    return apiBotDaysOffList.find((botDaysOff) => botDaysOff.id === id);
  }

  function findProcessNamesById(id: string): string[] {
    return apiProcesessList
      .filter((item) => item.campaign_non_working_days_configuration === id)
      .map((item) => item.name);
  }

  const [selectedBotDaysOff, setSelectedBotDaysOff] =
    useState<CampaignNonWorkingDaysConfiguration>();

  const checkUpdate = useAppSelector(checkBotDaysOffUpdate);
  useEffect(() => {
    if (
      checkUpdate.state === 'idle' &&
      checkUpdate.value?.status === 'success' &&
      checkUpdate.value.botDaysOff
    ) {
      setSelectedBotDaysOff(checkUpdate.value.botDaysOff);
    }
  }, [checkUpdate]);

  //delete logic
  const [isDeleteBotDaysOffVisible, setIsDeleteBotDaysOffVisible] =
    useState<boolean>(false);

  const openDeleteBotDaysOffModal = () => setIsDeleteBotDaysOffVisible(true);
  const closeDeleteBotDaysOffModal = () => setIsDeleteBotDaysOffVisible(false);

  const handleDeleteBotDaysOff = (id: string) => {
    const botDaysOffDetailed = findBotById(id);
    if (botDaysOffDetailed) setSelectedBotDaysOff(botDaysOffDetailed);
    openDeleteBotDaysOffModal();
  };

  const deleteBotDaysOffAfterModal = () => {
    if (selectedBotDaysOff) {
      dispatch(deleteBotDaysOffAsyncThunk(selectedBotDaysOff.id));
      closeDeleteBotDaysOffModal();
    } else {
      alert('something went wrong');
    }
  };

  const checkDeleteBotDaysOff = useAppSelector(checkBotDaysOffDelete);

  useEffect(() => {
    if (
      checkDeleteBotDaysOff.value?.status === 'success' &&
      checkDeleteBotDaysOff.state === 'idle'
    ) {
      dispatch(getBotDaysOffListAsyncThunk(strategy));
      setSelectedBotDaysOff(undefined);
    }
  }, [checkDeleteBotDaysOff]);

  const handleDuplicateBotDaysOff = (id: string) => {
    const botDaysOffDetailed = findBotById(id);
    if (botDaysOffDetailed) setSelectedBotDaysOff(botDaysOffDetailed);

    if (botDaysOffDetailed) {
      let count = 1;
      let newName = botDaysOffDetailed.name;

      let nameExists = apiBotDaysOffList.some((item) => item.name === newName);

      while (nameExists) {
        newName =
          botDaysOffDetailed.name + ' - copy' + (count > 1 ? `(${count})` : '');
        nameExists = apiBotDaysOffList.some((item) => item.name === newName);
        count++;
      }

      const copiedCallingPref = {
        ...botDaysOffDetailed,
        name: newName,
      };
      setIsLoadingVisible(true);
      dispatch(createBotDaysOffAsyncThunk(copiedCallingPref)).finally(() => {
        setIsLoadingVisible(false);
      });
    }
  };

  const checkCopy = useAppSelector(checkBotDaysOffCreate);

  useEffect(() => {
    if (checkCopy.state === 'idle' && checkCopy.value?.status === 'success') {
      dispatch(getBotDaysOffListAsyncThunk(strategy));
      setSelectedBotDaysOff(undefined);
    }
  }, [checkCopy]);

  const componentsMap = {
    create: (
      <BotDaysOffCreate backToList={handleBackToList} strategy={strategy} />
    ),
    list: (
      <BotDaysOffList
        botDaysOffList={apiBotDaysOffList}
        createBotDaysOff={handleCreateBotDaysOffClick}
        goToDetailed={handleDetailedViewClick}
        findProcessNamesById={findProcessNamesById}
        handleDeleteBotDaysOff={handleDeleteBotDaysOff}
        handleDuplicateBotDaysOff={handleDuplicateBotDaysOff}
        hasPermission={hasPermission}
      />
    ),
    detailed: selectedBotDaysOff && (
      <BotDaysOffDetailed
        botDaysOff={selectedBotDaysOff}
        backToList={handleBackToList}
        findProcessNamesById={findProcessNamesById}
        hasPermission={hasPermission}
      />
    ),
  };
  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);
  return (
    <PresetContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      {selectedBotDaysOff && (
        <PresetDeleteModal
          type={'bot-days off'}
          isVisible={isDeleteBotDaysOffVisible}
          presetName={selectedBotDaysOff.name}
          closeModal={closeDeleteBotDaysOffModal}
          deletePreset={deleteBotDaysOffAfterModal}
        />
      )}
      {botDaysOffState !== 'list' && (
        <BackToList handleBackToList={handleBackToList} />
      )}

      {componentsMap[botDaysOffState]}
    </PresetContainer>
  );
};

export default BotDaysOff;

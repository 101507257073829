import { EuiModalBody, EuiFieldText, EuiTextArea } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { H3 } from '../../../../../../App.style';
import { FormRow } from '../../../../../processes/forms/ProcessForm.style';
import { ApiError } from '../../../../../../../common/types';
import {
  SftpPublicKey,
  SftpPublicKeyRequest,
} from '../../../../../../../generated/tenants/Api';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks';
import {
  addSftpPublicKeyAsyncThunk,
  checkAddSftpPublicKey,
  getSftpPublicKeysListAsyncThunk,
} from '../api/sftpConfigSlice';
import Modal from '../../../../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface AddPublicKeyModalProps {
  isVisible: boolean;
  closeModal: () => void;
}

const AddPublicKeyModal: React.FC<AddPublicKeyModalProps> = ({
  isVisible,
  closeModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [publicKeyName, setPublicKeyName] = useState<string>('');
  const [publicKey, setPublicKey] = useState<string>('');

  const [publicKeyApiError, setPublicKeyApiError] =
    useState<ApiError<SftpPublicKey>>();

  const checkAddPublicKey = useAppSelector(checkAddSftpPublicKey);
  const addPublicKeyError = checkAddPublicKey.value?.error;

  useEffect(() => {
    if (addPublicKeyError) setPublicKeyApiError(addPublicKeyError);
  }, [addPublicKeyError]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: SftpPublicKeyRequest = {
      name: publicKeyName,
      ssh_public_key: publicKey,
    };
    dispatch(addSftpPublicKeyAsyncThunk(data));

    return false;
  };

  useEffect(() => {
    if (
      checkAddPublicKey.state === 'idle' &&
      checkAddPublicKey.value?.status === 'success'
    ) {
      dispatch(getSftpPublicKeysListAsyncThunk());
      setPublicKeyName('');
      setPublicKey('');
      closeModal();
    }
  }, [checkAddPublicKey]);

  const [publicKeyError, setPublicKeyError] = useState<boolean>(false);

  useEffect(() => {
    publicKey.length > 256 || publicKey.length === 0
      ? setPublicKeyError(false)
      : setPublicKeyError(true);
  }, [publicKey]);

  const body = (
    <EuiModalBody>
      <FormRow
        label={<H3>{t('settings.adminPanel.sftp.name')}</H3>}
        isInvalid={addPublicKeyError?.name ? true : false}
        error={addPublicKeyError?.name}
      >
        <EuiFieldText
          value={publicKeyName}
          autoFocus
          maxLength={30}
          onChange={(e) => {
            setPublicKeyName(e.target.value);
            setPublicKeyApiError({
              ...publicKeyApiError,
              name: undefined,
            });
          }}
        />
      </FormRow>

      <FormRow
        label={<H3>{t('settings.adminPanel.sftp.publicKeyTitle')}</H3>}
        style={{ marginBottom: 10 }}
        isInvalid={addPublicKeyError?.ssh_public_key ? true : publicKeyError}
        error={
          addPublicKeyError?.ssh_public_key
            ? addPublicKeyError.ssh_public_key
            : t('settings.adminPanel.sftp.notValidKey')
        }
      >
        <EuiTextArea
          isInvalid={publicKeyError}
          value={publicKey}
          onChange={(e) => {
            setPublicKey(e.target.value);
            setPublicKeyApiError({
              ...publicKeyApiError,
              ssh_public_key: undefined,
            });
          }}
        />
      </FormRow>
    </EuiModalBody>
  );

  const buttonDisabled = publicKeyName.length === 0 || publicKey.length === 0;

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={t('settings.adminPanel.sftp.addKeyTitle')}
      onConfirm={handleSubmit}
      onCancel={closeModal}
      children={body}
      buttonDisabled={buttonDisabled}
    />
  );
};

export default AddPublicKeyModal;

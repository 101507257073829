import React from 'react';
import { CallingConfiguration } from '../../../../generated/tenants/Api';
import {
  EuiFieldText,
  EuiDatePicker,
  EuiFormRow,
  EuiDatePickerRange,
  EuiToolTip,
} from '@elastic/eui';
import FormRowTitle from '../../processes/forms/FormRowTitle';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import moment from 'moment';
import { H3 } from '../../../App.style';
import {
  DynamicContainer,
  DynamicContentContainer,
  DynamicTitleContainer,
  RandomizedContainer,
} from './CallingPreferences.style';
import { InfoIcon } from '../../../../resources/icons-new/InfoIcon';
import { useTranslation } from 'react-i18next';

interface CallingPreferenceFormReadOnlyProps {
  callingPreference: CallingConfiguration;
}

const CallingPreferenceFormReadOnly: React.FC<
  CallingPreferenceFormReadOnlyProps
> = ({ callingPreference }) => {
  const { t } = useTranslation();
  const timeBetweenAttempts = moment()
    .startOf('day')
    .add(callingPreference.time_between_attempts_seconds, 'seconds');

  const secondsToMoment = (seconds: number) => {
    return moment().startOf('day').add(seconds, 'seconds');
  };
  return (
    <>
      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.callAttempts')}
            tooltip={t('presets.callingPreferencesForm.callAttemptsTooltip')}
          />
        }
      >
        <EuiFieldText value={callingPreference.call_attempts} readOnly={true} />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.dailyCallAttempts')}
            tooltip={t(
              'presets.callingPreferencesForm.dailyCallAttemptsTooltip',
            )}
          />
        }
      >
        <EuiFieldText
          value={callingPreference.call_attempts_per_day}
          readOnly={true}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.restDays')}
            tooltip={t('presets.callingPreferencesForm.restDaysTooltip')}
          />
        }
      >
        <EuiFieldText
          value={
            callingPreference.rest_days_after_call_attempts_per_day_reached
          }
          readOnly={true}
        />
      </FormRow>

      <FormRow
        label={
          <FormRowTitle
            title={t('presets.callingPreferencesForm.intervalBetweenAttempts')}
            tooltip={t(
              'presets.callingPreferencesForm.intervalBetweenAttemptsTooltip',
            )}
          />
        }
      >
        <EuiFieldText
          value={callingPreference.time_between_attempts_mode}
          readOnly={true}
        />
      </FormRow>

      {callingPreference.time_between_attempts_mode === 'STATIC' && (
        <FormRow
          label={
            <FormRowTitle
              title={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSeconds',
              )}
              tooltip={t(
                'presets.callingPreferencesForm.timeBetweenAttemptsSecondsTooltip',
              )}
            />
          }
        >
          <EuiDatePicker
            selected={timeBetweenAttempts}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat={'HH:mm'}
            timeFormat={'HH:mm'}
            minTime={moment().hours(0).minutes(30)}
            maxTime={moment().hours(4).minutes(0)}
            readOnly
          />
        </FormRow>
      )}

      {callingPreference.time_between_attempts_mode === 'RANDOMIZED' &&
        callingPreference.randomized_time_between_attempts && (
          <RandomizedContainer>
            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.minimal')}</H3>}
              style={{ width: 150 }}
            >
              <EuiDatePicker
                selected={secondsToMoment(
                  callingPreference.randomized_time_between_attempts
                    .min_time_between_attempts_seconds,
                )}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
                readOnly
              />
            </EuiFormRow>

            <EuiFormRow
              label={<H3>{t('presets.callingPreferencesForm.maximal')}</H3>}
              style={{ width: 150, marginTop: -2 }}
            >
              <EuiDatePicker
                selected={secondsToMoment(
                  callingPreference.randomized_time_between_attempts
                    .max_time_between_attempts_seconds,
                )}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
                readOnly
              />
            </EuiFormRow>
          </RandomizedContainer>
        )}

      {callingPreference.time_between_attempts_mode === 'DYNAMIC' &&
        callingPreference.dynamic_time_between_attempts && (
          <DynamicContainer>
            <DynamicTitleContainer>
              <H3>{t('presets.callingPreferencesForm.timeRange')}</H3>
              <div style={{ display: 'flex', gap: 8 }}>
                <H3>{`Interval (hh:mm)`}</H3>
                <EuiToolTip
                  position="top"
                  content={
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {t('presets.callingPreferencesForm.timeRangeTooltip')}
                    </div>
                  }
                >
                  <InfoIcon $pointer />
                </EuiToolTip>
              </div>
            </DynamicTitleContainer>
            {callingPreference.dynamic_time_between_attempts.map(
              (interval, index) => (
                <DynamicContentContainer
                  key={`dynamic-time-between-attempts-${index}`}
                >
                  <div style={{ width: 220 }}>
                    <EuiDatePickerRange
                      readOnly
                      startDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_start_seconds,
                          )}
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          readOnly
                        />
                      }
                      endDateControl={
                        <EuiDatePicker
                          selected={secondsToMoment(
                            interval.interval_end_seconds,
                          )}
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          readOnly
                        />
                      }
                    />
                  </div>
                  <div style={{ width: 100 }}>
                    <EuiDatePicker
                      selected={secondsToMoment(
                        interval.time_between_attempts_seconds,
                      )}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={'HH:mm'}
                      timeFormat={'HH:mm'}
                      minTime={moment().hours(0).minutes(30)}
                      maxTime={moment().hours(4).minutes(0)}
                      readOnly
                    />
                  </div>
                </DynamicContentContainer>
              ),
            )}

            <FormRow
              style={{ marginTop: 30 }}
              label={
                <FormRowTitle
                  title={t('presets.callingPreferencesForm.basicInterval')}
                  tooltip={t(
                    'presets.callingPreferencesForm.basicIntervalTooltip',
                  )}
                />
              }
            >
              <EuiDatePicker
                selected={timeBetweenAttempts}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={'HH:mm'}
                timeFormat={'HH:mm'}
                minTime={moment().hours(0).minutes(30)}
                maxTime={moment().hours(4).minutes(0)}
                readOnly
              />
            </FormRow>
          </DynamicContainer>
        )}
    </>
  );
};

export default CallingPreferenceFormReadOnly;

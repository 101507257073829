import styled, { css, keyframes } from 'styled-components';
import { H3, fontIBM } from '../../App.style';

export const PresetsPanelContainer = styled.div`
  width: 100%;
  height: 100vh;

  overflow: hidden;

  padding: 24px;
`;

export const TabsContainer = styled.div`
  width: 100%;
  height: 90%;

  margin-top: 16px;
`;

export const TabButtons = styled.div`
  display: flex;

  justify-content: space-around;
`;

export const TabButton = styled.button<{ active: boolean; invalid?: boolean }>`
  width: 20%;
  padding: 10px;
  height: 40px;

  cursor: pointer;

  background: white;

  ${({ active, invalid }) =>
    invalid
      ? css`
          border-bottom: 2px solid red;
          background-color: white;
        `
      : active
        ? css`
            border-bottom: 2px solid #127fbf;
            background-color: white;
          `
        : css`
            border-bottom: none;
            border-bottom: 1px solid #3e4c59;
          `}

  &:hover ${H3} {
    font-weight: ${({ active }) => !active && 600};
  }
`;

export const TabContent = styled.div`
  width: 100%;
  padding-bottom: 80px;

  height: 80vh;

  overflow: auto;

  background: white;

  position: relative;
`;

//Lists

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  padding: 24px;
  justify-content: flex-start;

  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

//add new button
const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const AddPresetIconContainer = styled.div`
  width: 50px;
  height: 50px;

  border-radius: 50%;

  position: relative;

  border: 1px solid black;

  transition: all 0.3s ease-out;

  &:active {
    animation: ${clickAnimation} 0.3s ease-in-out;
  }
`;

export const CreateNewPresetIcon = styled.img`
  position: absolute;
  left: 17px;
  top: 17px;

  width: 15px;
  height: 15px;
`;

export const CreatePresetButtonContainer = styled.div`
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-family: ${fontIBM};
  font-size: 14px;
  font-weight: 400;
`;

export const CreateNewPresetContainer = styled.div`
  width: 270px;
  height: 230px;
  position: relative;
  padding: 12px;

  background: white;

  border-radius: 5px;

  border: 1px solid #d3d3d3;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f6f8fa;
    /* text-decoration: underline; */
    -webkit-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
    -moz-box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
    box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.5);
  }

  &:hover ${AddPresetIconContainer} {
    background: white;
  }

  &:hover ${H3} {
    /* text-shadow: 0 0 1px currentColor; */
    margin-left: -1px;
    font-weight: 600;
  }

  &:active {
    animation: ${clickAnimation} 0.3s ease-in-out;
  }
`;

//Detailed

//DetailedVoice.tsx

export const DetailedPresetContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  position: relative;
`;

export const PresetNameContainer = styled.div<{ noBorder?: boolean }>`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;

  padding-bottom: 14px;

  border-bottom: 0.5px solid #d3d3d3;

  margin-bottom: 18px;
`;

//
export const PresetContainer = styled.div`
  width: 100%;

  overflow: auto;

  position: relative;
`;

export const CreatePresetContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  position: relative;
`;

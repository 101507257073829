import moment from 'moment';
import React from 'react';
import { CallingConfiguration } from '../../../../generated/tenants/Api';
import PresetListPreview from '../PresetListPreview';
import {
  ListContainer,
  CreateNewPresetContainer,
  AddPresetIconContainer,
  CreatePresetButtonContainer,
} from '../PresetsPanel.style';
import { H3 } from '../../../App.style';
import { NewPresetIcon } from '../../../../resources/icons-new/NewPresetIcon';
import { useTranslation } from 'react-i18next';

interface CallingPreferencesListProps {
  callingPreferencesList: CallingConfiguration[];
  createCallingPreference: () => void;
  goToDetailed: (id: string) => void;
  findProcessNamesById(id: string): string[];
  handleDeletePreset: (id: string) => void;
  handleDuplicateCallingConf: (id: string) => void;
  hasPermission: boolean;
}

const CallingPreferencesList: React.FC<CallingPreferencesListProps> = ({
  callingPreferencesList,
  createCallingPreference,
  goToDetailed,
  findProcessNamesById,
  handleDeletePreset,
  handleDuplicateCallingConf,
  hasPermission,
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer>
      {callingPreferencesList &&
        callingPreferencesList.map((callingPreference, index) => {
          const formattedCreated = moment(callingPreference.created_at).format(
            'DD/MM/YYYY, HH:mm',
          );
          const fomrattedModified = moment(callingPreference.updated_at).format(
            'DD/MM/YYYY, HH:mm',
          );

          const usedIn = findProcessNamesById(callingPreference.id);
          return (
            <PresetListPreview
              type="calling_preferences"
              key={`callingPreferences-${index}`}
              index={index}
              onClick={goToDetailed}
              presetId={callingPreference.id}
              presetName={callingPreference.name}
              createDate={formattedCreated}
              modifiedDate={fomrattedModified}
              usedIn={usedIn}
              handleDeletePreset={handleDeletePreset}
              handleDuplicatePreset={handleDuplicateCallingConf}
              disableDots={!hasPermission}
            />
          );
        })}

      {hasPermission && (
        <CreateNewPresetContainer onClick={createCallingPreference}>
          <CreatePresetButtonContainer>
            <AddPresetIconContainer>
              <NewPresetIcon
                style={{
                  position: 'absolute',
                  left: 17,
                  top: 17,
                  width: 15,
                  height: 15,
                }}
              />
            </AddPresetIconContainer>
            <H3>{t('common.createNew')}</H3>
          </CreatePresetButtonContainer>
        </CreateNewPresetContainer>
      )}
    </ListContainer>
  );
};

export default CallingPreferencesList;

import styled from 'styled-components';
import { BLACK_typography, COLOR_NEUTRAL_30 } from '../../App.style';

export const DashboardPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 700px;

  position: relative;

  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;

export const DashboardFiltersContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
`;

export const DashboardFiltersSelectContainer = styled.div`
  cursor: pointer;
  padding: 5px;
  position: relative;
  display: inline-block;
`;

export const DashboardFiltersSelect = styled.select`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const DashboardPanelContentContainer = styled.div`
  width: calc(100% - 20px);
  width: 100%;
  gap: 32px;
  display: flex;
  height: calc(100% - 30px);

  flex-direction: column;

  overflow: auto;
`;

export const DashboardCampaignPreviewContainer = styled.div`
  padding: 0px 5px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
  gap: 16px;
`;

export const DashboardCampaignPreviewTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const DashboardCampaignPreviewContentContainer = styled.div`
  width: 100%;
  max-height: 250px;
  border-radius: 9px;
  background: #fff;

  padding: 16px;
  display: flex;

  box-shadow: 0px 2px 10px 0px rgba(62, 76, 89, 0.3);
`;

export const DashboardCampaignPreviewFirstContainer = styled.div`
  display: flex;
  padding-right: 16px;
  min-width: 280px;
`;

export const DashboardCampaignPreviewFirstRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const DashboardCampaignsPreviewCallsBar = styled.div`
  display: flex;

  gap: 8px;
  align-items: center;
`;

export const DashboardCampaigPreviewSecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: 20px;

  border-left: 1px solid ${COLOR_NEUTRAL_30};
  border-right: 1px solid ${COLOR_NEUTRAL_30};
  padding: 0px 16px;
`;

export const DashboardCampaigPreviewThirdContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 300px;
  gap: 12px;

  border-right: 1px solid ${COLOR_NEUTRAL_30};
  padding: 0px 16px;
`;

export const DashboardCampaignPreviewThirdContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
`;

export const DashboardCampaigPreviewFourthContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  padding: 0px 16px;
`;

export const CustomLabelTSpan = styled.tspan`
  color: ${BLACK_typography};
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 400;
`;

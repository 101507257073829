import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../common/hooks';
import { EuiLoadingSpinner, EuiFieldText } from '@elastic/eui';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PasswordVisibilityToggle } from '../../../../../../common/inputs/Inputs.style';
import { EyeIcon } from '../../../../../../resources/icons-new/EyeIcon';
import { EyeSlashIcon } from '../../../../../../resources/icons-new/EyeSlashIcon';
import { H2, Button } from '../../../../../App.style';
import {
  LoginContainer,
  LeftContainer,
  Waves,
  RightContainer,
  LogoContainer,
  LektaLogo,
  LoginForm,
} from '../../../../session/Login.style';
import wavesSvg from '../../../../../../resources/login/login-waves.svg';
import {
  checkSuperUserCreate,
  checkSuperUserInvitationToken,
  checkSuperUserTokenAsyncThunk,
  createSuperUserAfterInvitationAsyncThunk,
} from '../../api/superAdminSlice';
import { ApiError } from '../../../../../../common/types';
import lektaLogo from '../../../../../../resources/logo-lekta.png';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../../../../common/modals/LoadingModal';

const SuperUserRegister = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const [passwordNotMatch, setPasswordNotMatch] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(checkSuperUserTokenAsyncThunk(token));
    }
  }, [token]);

  const handleSubmitInvitation = () => {
    if (token && password === rePassword) {
      setPasswordNotMatch(false);
      const args = {
        token: token,
        data: {
          password: password,
          first_name: firstName,
          last_name: lastName,
        },
      };

      setIsLoadingVisible(true);
      dispatch(createSuperUserAfterInvitationAsyncThunk(args)).finally(() => {
        setIsLoadingVisible(false);
      });
    } else {
      setPasswordNotMatch(true);
    }
  };
  const checkInvite = useAppSelector(checkSuperUserInvitationToken); //sprawdz zapraszanie

  //   if state is idle, and status is success redirect to tenant selector / main page
  useEffect(() => {
    if (
      checkCreate.state === 'idle' &&
      checkCreate.value?.status === 'success'
    ) {
      navigate('/');
    }
  });

  const [invitationApiErrors, setInvitationApiErrors] =
    useState<ApiError<{ password: 'string' }>>();

  const checkCreate = useAppSelector(checkSuperUserCreate);
  const addUserError = checkCreate.value?.error;

  useEffect(() => {
    if (addUserError) {
      setInvitationApiErrors(addUserError);
    }
  }, [addUserError]);

  const passwordTooShort = t('strenghtBar.tooShort');

  const clearErrors = () => {
    setPasswordNotMatch(false);
    setInvitationApiErrors({
      ...invitationApiErrors,
      password: undefined,
    });
  };

  //new
  const [step, setStep] = useState<1 | 2>(1);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [rePasswordVisible, setRePasswordVisible] = useState<boolean>(false);

  const [isLoadingVisible, setIsLoadingVisible] = useState<boolean>(false);

  return (
    <LoginContainer>
      <LoadingModal
        isVisible={isLoadingVisible}
        closeModal={() => setIsLoadingVisible(false)}
      />
      <LeftContainer>
        <Waves src={wavesSvg} />
      </LeftContainer>
      <RightContainer>
        {checkInvite.state === 'pending' ? (
          <EuiLoadingSpinner size={'xl'} />
        ) : checkInvite.state === 'idle' && !checkInvite.value ? (
          <H2 $medium>{t('superAdminPanel.superUsers.invitation.badToken')}</H2>
        ) : checkInvite.value && checkInvite.value.status === 'fail' ? (
          <H2>{t('common.error')}</H2>
        ) : checkInvite.value && checkInvite.value.status === 'needInfo' ? (
          <>
            <LogoContainer>
              <LektaLogo src={lektaLogo} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 3,
                  marginTop: 50,
                }}
              >
                {step === 1 && (
                  <>
                    <H2 $medium>
                      {t('superAdminPanel.superUsers.invitation.step1.hello')}
                    </H2>
                    <H2 $medium>
                      {t('superAdminPanel.superUsers.invitation.step1.invited')}
                    </H2>
                  </>
                )}

                {invitationApiErrors?.password ? (
                  <H2 $red>{invitationApiErrors.password}</H2>
                ) : (
                  passwordNotMatch && (
                    <H2 $red>
                      P
                      {t(
                        'superAdminPanel.superUsers.invitation.passwordsNotMatch',
                      )}
                    </H2>
                  )
                )}
              </div>
            </LogoContainer>
            {step === 1 ? (
              <LoginForm
                onSubmit={(e) => {
                  e.preventDefault();
                  setStep(2);
                }}
              >
                <H2>
                  {t('superAdminPanel.superUsers.invitation.step1.introduce')}
                </H2>
                <EuiFieldText
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={t(
                    'superAdminPanel.superUsers.invitation.step1.firstName',
                  )}
                />
                <EuiFieldText
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={t(
                    'superAdminPanel.superUsers.invitation.step1.lastName',
                  )}
                />

                <Button
                  type="submit"
                  style={{ marginTop: 50 }}
                  disabled={firstName.length === 0 || lastName.length === 0}
                >
                  {t('common.next')}
                </Button>
              </LoginForm>
            ) : (
              <LoginForm
                onSubmit={(ev) => {
                  ev.preventDefault();
                  handleSubmitInvitation();
                }}
              >
                <H2>
                  {t(
                    'superAdminPanel.superUsers.invitation.step2.createPassword',
                  )}
                </H2>
                <div style={{ width: '100%', marginTop: 10 }}>
                  <EuiFieldText
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder={t(
                      'superAdminPanel.superUsers.invitation.step2.password',
                    )}
                    autoFocus
                    isInvalid={
                      invitationApiErrors?.password ? true : passwordNotMatch
                    }
                    append={
                      <PasswordVisibilityToggle
                        type={'button'}
                        onClick={() => setPasswordVisible((prev) => !prev)}
                      >
                        {passwordVisible ? (
                          <EyeIcon style={{ width: 25, height: 25 }} />
                        ) : (
                          <EyeSlashIcon style={{ width: 25, height: 25 }} />
                        )}
                      </PasswordVisibilityToggle>
                    }
                    value={password}
                    onChange={(ev) => {
                      setPassword(ev.target.value);
                      clearErrors();
                    }}
                  />
                  <PasswordStrengthBar
                    password={password}
                    minLength={12}
                    scoreWords={[
                      t('strenghtBar.weak'),
                      t('strenghtBar.weak'),
                      t('strenghtBar.okay'),
                      t('strenghtBar.good'),
                      t('strenghtBar.strong'),
                    ]}
                    shortScoreWord={passwordTooShort}
                  />
                </div>

                <EuiFieldText
                  type={rePasswordVisible ? 'text' : 'password'}
                  placeholder={t(
                    'superAdminPanel.superUsers.invitation.step2.repeatPassword',
                  )}
                  isInvalid={
                    invitationApiErrors?.password ? true : passwordNotMatch
                  }
                  append={
                    <PasswordVisibilityToggle
                      type={'button'}
                      onClick={() => setRePasswordVisible((prev) => !prev)}
                    >
                      {rePasswordVisible ? (
                        <EyeIcon style={{ width: 25, height: 25 }} />
                      ) : (
                        <EyeSlashIcon style={{ width: 25, height: 25 }} />
                      )}
                    </PasswordVisibilityToggle>
                  }
                  value={rePassword}
                  onChange={(ev) => {
                    setRePassword(ev.target.value);
                    clearErrors();
                  }}
                />

                <div>
                  <Button
                    type={'submit'}
                    style={{ marginTop: 40 }}
                    disabled={password.length < 12 || rePassword.length === 0}
                  >
                    {t('superAdminPanel.superUsers.invitation.step2.join')}
                  </Button>
                </div>
              </LoginForm>
            )}
          </>
        ) : (
          <>
            <H2>
              {t('superAdminPanel.superUsers.invitation.step3.userCreated')}
            </H2>
            <Button onClick={() => navigate('/')}>
              {t('superAdminPanel.superUsers.invitation.step3.redirect')}
            </Button>
          </>
        )}
      </RightContainer>
    </LoginContainer>
  );
};

export default SuperUserRegister;

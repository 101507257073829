import {
  EuiModal,
  EuiModalHeader,
  EuiForm,
  EuiModalBody,
  EuiFieldText,
  EuiModalFooter,
  EuiLoadingSpinner,
  EuiFlexItem,
  EuiSuperSelect,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks';
import {
  checkInvitationSend,
  endSendNewInvitation,
  invitationAsyncThunk,
  startSendNewInvitation,
} from '../api/userSlice';
import { ApiError } from '../../../../common/types';
import {
  Invitation,
  InvitationRequest,
  RoleEnum,
  Strategy,
  UserStrategyPermissionRequest,
} from '../../../../generated/tenants/Api';
import { H2, H3, Button, ButtonEmpty, H4 } from '../../../App.style';
import { FormRow } from '../../processes/forms/ProcessForm.style';
import {
  PermissionLevel,
  getPermissionBooleans,
  getStrategyPermissionsOptions,
} from './userFunctions';
import { NewUserStrategyPermissionsContainer } from '../Users.style';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

interface AddUserModalProps {
  isVisible: boolean;
  closeModal: () => void;
  strategiesList: Strategy[];
  showPopup: () => void;
  setIsLoadingVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  isVisible,
  closeModal,
  strategiesList,
  showPopup,
  setIsLoadingVisible,
}) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const checkInvite = useAppSelector(checkInvitationSend);
  const invitationErrors = checkInvite?.value?.error;

  const [addUserApiErrors, setAddUserApiErrors] =
    useState<ApiError<Invitation>>();

  useEffect(() => {
    if (invitationErrors) {
      setLoading(false);
      setAddUserApiErrors(invitationErrors);
    } else {
      setAddUserApiErrors(undefined);
    }
  }, [invitationErrors]);

  function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  useEffect(() => {
    if (email.length) {
      validateEmail(email) ? setEmailError(false) : setEmailError(true);
    }
  }, [email]);

  const [selectedRole, setSelectedRole] = useState<RoleEnum>('USER');

  const rolesOptions = [
    {
      value: 'USER',
      inputDisplay: <H3>{t('common.user')}</H3>,
      dropdownDisplay: <H3>{t('common.user')}</H3>,
    },
    {
      value: 'ADMIN',
      inputDisplay: <H3>{t('common.admin')}</H3>,
      dropdownDisplay: <H3>{t('common.admin')}</H3>,
    },
    {
      value: 'OWNER',
      inputDisplay: <H3>{t('common.owner')}</H3>,
      dropdownDisplay: <H3>{t('common.owner')}</H3>,
    },
  ];

  //permissions

  const initialStrategyPermissions = strategiesList.map((strategy) => {
    return {
      strategy: strategy.id,
      permission: 'no_permissions' as PermissionLevel,
    };
  });

  useEffect(() => {
    setStrategyPermissions(initialStrategyPermissions);
  }, [strategiesList]);

  const [strategyPermissions, setStrategyPermissions] = useState<
    { strategy: string; permission: PermissionLevel }[]
  >(initialStrategyPermissions);

  // Function to handle permission change for a strategy
  const handlePermissionChange = (
    strategyId: string,
    newPermission: string,
  ) => {
    setStrategyPermissions(
      strategyPermissions.map((strategy) => {
        if (strategy.strategy === strategyId) {
          return { ...strategy, permission: newPermission as PermissionLevel };
        }
        return strategy;
      }),
    );
  };

  const onClose = () => {
    dispatch(endSendNewInvitation());
    setEmail('');
    setSelectedRole('USER');
    setStrategyPermissions(initialStrategyPermissions);
    closeModal();
    setLoading(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    dispatch(startSendNewInvitation());

    if (email) {
      const userPermission: UserStrategyPermissionRequest[] =
        strategyPermissions.map((permission) => {
          return {
            strategy_id: permission.strategy,
            permission: getPermissionBooleans(permission.permission),
          };
        });

      const data: InvitationRequest = {
        email: email,
        role: selectedRole as RoleEnum,
        strategies_permissions:
          selectedRole === 'USER' ? userPermission : undefined,
      };

      setIsLoadingVisible(true);
      dispatch(invitationAsyncThunk(data))
        .then(unwrapResult)
        .then((res) => {
          if (!res.error) {
            onClose();
            showPopup();
          }
        })
        .finally(() => {
          setIsLoadingVisible(false);
        });
    } else {
      alert(t('common.somethingWentWrong'));
    }
    return false;
  };

  const strategyPermissionsOptions = getStrategyPermissionsOptions(
    t('common.permissions.no_permissions'),
    t('common.permissions.read_only'),
    t('common.permissions.process_write'),
    t('common.permissions.campaign_write'),
    t('common.permissions.process_campaign_write'),
    t('common.permissions.workspace'),
  );

  const renderStrategyPermissions = () => {
    return strategiesList.map((strategy) => {
      const permission = strategyPermissions.find(
        (permission) => permission.strategy === strategy.id,
      )?.permission;
      return (
        <NewUserStrategyPermissionsContainer
          key={`strategy-permission-${strategy.id}`}
        >
          <H4>{strategy.name}</H4>

          <EuiSuperSelect
            style={{ width: 180 }}
            valueOfSelected={permission ?? 'wtf'}
            compressed
            onChange={(permission) =>
              handlePermissionChange(strategy.id, permission)
            }
            options={strategyPermissionsOptions}
          />
        </NewUserStrategyPermissionsContainer>
      );
    });
  };

  let addUserModal;
  if (isVisible) {
    addUserModal = (
      <EuiModal className="custom-modal" onClose={onClose}>
        <EuiModalHeader>
          <H2>{t('settings.adminPanel.users.invite')}</H2>
        </EuiModalHeader>

        <EuiForm component="form" id="add" onSubmit={handleSubmit}>
          <EuiModalBody>
            <FormRow
              label={
                <H3>{t('settings.adminPanel.users.inviteModal.form.email')}</H3>
              }
              isInvalid={addUserApiErrors?.email ? true : emailError}
              error={
                addUserApiErrors?.email
                  ? addUserApiErrors.email
                  : t(
                      'settings.adminPanel.users.inviteModal.form.notValidEmail',
                    )
              }
            >
              <EuiFieldText
                type="email"
                isInvalid={addUserApiErrors?.email ? true : emailError}
                value={email}
                autoFocus
                maxLength={30}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setAddUserApiErrors({
                    ...addUserApiErrors,
                    email: undefined,
                  });
                }}
              />
            </FormRow>

            <FormRow
              label={
                <H3>{t('settings.adminPanel.users.inviteModal.form.role')}</H3>
              }
            >
              <EuiSuperSelect
                valueOfSelected={selectedRole}
                onChange={(e) => setSelectedRole(e as RoleEnum)}
                options={rolesOptions}
              />
            </FormRow>

            {selectedRole === 'USER' && (
              <FormRow
                label={
                  <H3>
                    {t('settings.adminPanel.users.inviteModal.form.workspace')}
                  </H3>
                }
              >
                <div>{renderStrategyPermissions()}</div>
              </FormRow>
            )}
          </EuiModalBody>

          <EuiModalFooter style={{ marginTop: -20 }}>
            {loading ? (
              <EuiFlexItem
                style={{
                  height: 40,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 15,
                }}
              >
                <EuiLoadingSpinner size={'l'} />
              </EuiFlexItem>
            ) : (
              <>
                <ButtonEmpty onClick={onClose}>
                  <H3>{t('common.cancel')}</H3>
                </ButtonEmpty>
                <Button
                  id="add"
                  type={'submit'}
                  disabled={
                    email === '' || addUserApiErrors?.email ? true : emailError
                  }
                >
                  {t('common.invite')}
                </Button>
              </>
            )}
          </EuiModalFooter>
        </EuiForm>
      </EuiModal>
    );
  }

  return <div>{addUserModal}</div>;
};

export default AddUserModal;

import React, { useEffect, useRef, useState } from 'react';
import { CampaignOverview } from '../../../generated/tenants/Api';
import moment from 'moment';
import { EuiToolTip } from '@elastic/eui';
import {
  ReportsCampaignInputContainer,
  Input,
  SelectedCampaignsDiv,
  DropdownList,
  ListItem,
  ButtonContainer,
  SelectedCampaignsEmpty,
  SelectedCampaignsArrow,
  CampaignsInputContainer,
} from './Reports.style';
import { H4, H3, Button } from '../../App.style';
import { SearchIcon } from '../../../resources/icons-new/SearchIcon';
import { PlusIcon } from '../../../resources/icons-new/PlusIcon';
import { ChevronUpIcon } from '../../../resources/icons-new/ChevronUpIcon';
import { ChevronDownIcon } from '../../../resources/icons-new/ChevronDownIcon';
import { useTranslation } from 'react-i18next';

interface ReportsCampaignInputProps {
  campaignsList: CampaignOverview[];
  selectedCampaigns: CampaignOverview[];
  setSelectedCampaigns: React.Dispatch<
    React.SetStateAction<CampaignOverview[]>
  >;
  noText?: boolean;
  analytics?: boolean;
}

const ReportsCampaignInput: React.FC<ReportsCampaignInputProps> = ({
  campaignsList,
  selectedCampaigns,
  setSelectedCampaigns,
  noText,
  analytics,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<CampaignOverview[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSelectedItems(selectedCampaigns);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setSelectedItems(selectedCampaigns);
    setIsOpen(true);
  };

  const handleItemClick = (item: CampaignOverview) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.find((i) => i.id === item.id)) {
        return prevSelectedItems.filter((i) => i.id !== item.id);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  const handleApply = () => {
    setIsOpen(false);
    setSelectedCampaigns(selectedItems);
  };

  const handleCancel = () => {
    setSelectedItems([]);
    setSelectedCampaigns([]);
    setIsOpen(false);
  };

  const filteredItems = campaignsList.filter((item) =>
    item.id.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  function truncateUUID(uuid: string): string {
    if (uuid.length > 13) {
      return `${uuid.substring(0, 13)}...`;
    }
    return uuid;
  }
  return (
    <ReportsCampaignInputContainer ref={containerRef}>
      {isOpen ? (
        <CampaignsInputContainer>
          <SearchIcon style={{ position: 'absolute', left: 10 }} />

          <Input
            type="text"
            placeholder={t('reports.selectCampaign')}
            autoFocus
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <ChevronUpIcon
            onClick={() => setIsOpen(false)}
            style={{ position: 'absolute', right: 10 }}
          />
        </CampaignsInputContainer>
      ) : (
        <>
          {selectedCampaigns.length === 0 ? (
            <SelectedCampaignsEmpty
              onClick={() => {
                toggleDropdown();
                setSearchTerm('');
              }}
            >
              <H4 $medium>{t('reports.campaign')}</H4>
              <PlusIcon />
            </SelectedCampaignsEmpty>
          ) : (
            <SelectedCampaignsDiv
              analytics={analytics}
              onClick={() => {
                toggleDropdown();
                setSearchTerm('');
              }}
            >
              {!noText && (
                <H4 style={{ marginBottom: 5 }} $medium>
                  {t('reports.campaignIs')}
                </H4>
              )}
              {selectedCampaigns.map((campaign, i) => {
                return (
                  <div
                    style={{ display: 'flex' }}
                    key={`report-campaigns-${i}`}
                  >
                    <H4>
                      {moment(campaign.start_date).format('DD.MM.YYYY')} -
                      {moment(campaign.end_date).format('DD.MM.YYYY')}
                    </H4>
                    <H4 style={{ color: '#9AA5B1', marginLeft: 5 }}>
                      id: {truncateUUID(campaign.id)}
                    </H4>
                  </div>
                );
              })}
              <SelectedCampaignsArrow>
                <ChevronDownIcon />
              </SelectedCampaignsArrow>
            </SelectedCampaignsDiv>
          )}
        </>
      )}
      {isOpen && (
        <DropdownList>
          {filteredItems.length === 0 ? (
            <ListItem>
              <H3>{t('reports.noCampaigns')}</H3>
            </ListItem>
          ) : (
            filteredItems.map((item) => (
              <ListItem key={item.id} onClick={() => handleItemClick(item)}>
                <input
                  type="checkbox"
                  checked={!!selectedItems.find((i) => i.id === item.id)}
                  readOnly
                />
                <H3>
                  {moment(item.start_date).format('DD.MM.YYYY')} -
                  {moment(item.end_date).format('DD.MM.YYYY')}
                </H3>
                <EuiToolTip position="top" content={item.id}>
                  <H3 style={{ color: '#9AA5B1' }}>
                    id: {truncateUUID(item.id)}
                  </H3>
                </EuiToolTip>
              </ListItem>
            ))
          )}
          <ButtonContainer>
            <Button $size={'S'} $styleType="NORMAL" onClick={handleCancel}>
              {t('reports.removeSelection')}
            </Button>
            <Button
              $size={'S'}
              onClick={handleApply}
              $styleType="SECONDARY"
              disabled={filteredItems.length === 0}
            >
              {t('reports.apply')}
            </Button>
          </ButtonContainer>
        </DropdownList>
      )}
    </ReportsCampaignInputContainer>
  );
};

export default ReportsCampaignInput;

import React, { useState } from 'react';
import {
  PreviewPanel,
  ThreeDotsContainer,
  ProcessPreviewContainer,
  ProcessPreviewTitleContainer,
  ProcessPreviewLabelsContainer,
  ProcessPreviewButtonsContainer,
  ProcessPreviewButton,
  ProcessPreviewLastModifiedContainer,
} from './ProcessesPanel.style';
import { Label } from '../../presets/labels/Labels.style';
import {
  COLOR_NEUTRAL_30,
  COLOR_NEUTRAL_40,
  H3,
  H4,
  H6,
} from '../../../App.style';
import { Process } from '../../../../generated/tenants/Api';
import moment from 'moment';
import DescriptionModal from './modals/DescriptionModal';
import { useNavigate } from 'react-router-dom';
import { EuiPopover } from '@elastic/eui';

import DeleteProcessModal from './modals/DeleteProcessModal';
import { ThreeDotsIcon } from '../../../../resources/icons-new/ThreeDotsIcon';

import { useTranslation } from 'react-i18next';
import { TimeIcon } from '../../../../resources/icons-new/TimeIcon';
import { EyeIcon } from '../../../../resources/icons-new/EyeIcon';
import { CampaignsIcon } from '../../../../resources/icons-new/CampaignsIcon';
import { UserPopoverButton } from '../../sidebar/Sidebar.style';

interface ProcessPreviewProps {
  edit: boolean;
  process: Process;
  handleDuplicateProcess: (id: string) => void;
  hasProcessAccess: boolean;
  showCampaignCreateModal: (id: string) => void;
}

const ProcessPreview: React.FC<ProcessPreviewProps> = ({
  edit,
  process,
  handleDuplicateProcess,
  hasProcessAccess,
  showCampaignCreateModal,
}) => {
  const { t } = useTranslation();
  const formatDate = (date: string) => {
    return moment(date).format('DD.MM.YYYY');
  };

  const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
    useState<boolean>(false);
  const openDescriptionModal = () => {
    setIsDescriptionModalVisible(true);
  };

  const navigate = useNavigate();
  const handleDetailedViewClick = () => {
    navigate(`/dashboard/process/detailed/${process.id}`);
  };

  const [isProcessPopoverOpen, setIsProcessPopoverOpen] =
    useState<boolean>(false);
  const closeProcessPopover = () => setIsProcessPopoverOpen(false);
  const handleProcessPopoverClick = () => {
    setIsProcessPopoverOpen((prev) => !prev);
  };

  const [isDeleteProcessModalVisible, setIsDeleteProcessModalVisible] =
    useState<boolean>(false);
  const openDeleteProcessModal = () => setIsDeleteProcessModalVisible(true);
  const closeDeleteProcessModal = () => setIsDeleteProcessModalVisible(false);

  const handleGoToEditClick = () => {
    navigate(`/dashboard/process/detailed/${process.id}?edit=true`);
  };

  return (
    <PreviewPanel>
      <DeleteProcessModal
        isVisible={isDeleteProcessModalVisible}
        process={process}
        closeModal={closeDeleteProcessModal}
      />

      <DescriptionModal
        isVisible={isDescriptionModalVisible}
        closeModal={() => setIsDescriptionModalVisible(false)}
        process={process}
      />

      <ThreeDotsContainer
        onClick={(e) => {
          e.stopPropagation();
          handleProcessPopoverClick();
        }}
      >
        <EuiPopover
          ownFocus={false}
          button={
            <ThreeDotsIcon $pointer $disabled={edit} color={COLOR_NEUTRAL_40} />
          }
          isOpen={isProcessPopoverOpen}
          closePopover={() => setIsProcessPopoverOpen(false)}
          anchorPosition="downRight"
          panelPaddingSize="none"
        >
          <div style={{ maxWidth: 150 }}>
            <UserPopoverButton
              disabled={!hasProcessAccess}
              onClick={(e) => {
                e.stopPropagation();
                handleGoToEditClick();
              }}
            >
              <H3>{t('common.edit')}</H3>
            </UserPopoverButton>

            {process.description && (
              <UserPopoverButton
                disabled={!hasProcessAccess}
                onClick={(e) => {
                  e.stopPropagation();
                  openDescriptionModal();
                }}
              >
                <H3>{t('common.getInfo')}</H3>
              </UserPopoverButton>
            )}

            <UserPopoverButton
              disabled={!hasProcessAccess}
              onClick={(e) => {
                e.stopPropagation();
                handleDuplicateProcess(process.id);

                closeProcessPopover();
              }}
            >
              <H3>{t('common.duplicate')}</H3>
            </UserPopoverButton>

            <UserPopoverButton
              disabled={!hasProcessAccess}
              onClick={(e) => {
                e.stopPropagation();
                openDeleteProcessModal();

                closeProcessPopover();
              }}
            >
              <H3>{t('common.delete')}</H3>
            </UserPopoverButton>
          </div>
        </EuiPopover>
      </ThreeDotsContainer>

      <ProcessPreviewContainer>
        <ProcessPreviewTitleContainer title={process.name}>
          <H3 $selected>{process.name}</H3>
        </ProcessPreviewTitleContainer>

        {process.labels.length > 0 && (
          <ProcessPreviewLabelsContainer>
            {process.labels.map((label, index) => {
              if (label.color)
                return (
                  <Label
                    key={`label-${index}`}
                    color={label.color}
                    title={label.text}
                  >
                    {label.text}
                  </Label>
                );
            })}
          </ProcessPreviewLabelsContainer>
        )}

        <ProcessPreviewLastModifiedContainer
          style={{ marginTop: process.labels.length === 0 ? '16px' : '0px' }}
        >
          <TimeIcon
            color={COLOR_NEUTRAL_40}
            style={{ width: 11, height: 11 }}
          />
          <H6 style={{ color: COLOR_NEUTRAL_40 }}>
            {t('common.lastChange', {
              date: formatDate(process.updated_at),
              name: 'Adam Kowalski',
            })}
          </H6>
        </ProcessPreviewLastModifiedContainer>

        <ProcessPreviewButtonsContainer>
          <ProcessPreviewButton
            style={{ borderRight: `1px solid ${COLOR_NEUTRAL_30}` }}
            onClick={(e) => {
              e.stopPropagation();
              if (
                !isDescriptionModalVisible &&
                !isDeleteProcessModalVisible &&
                !edit
              )
                handleDetailedViewClick();
            }}
          >
            <EyeIcon style={{ width: 16 }} />
            <H4>{t('common.preview')}</H4>
          </ProcessPreviewButton>
          <ProcessPreviewButton
            onClick={(e) => {
              if (!edit) {
                e.stopPropagation();
                showCampaignCreateModal(process.id);
              }
            }}
          >
            <CampaignsIcon style={{ width: 16 }} />
            <H4>{t('common.newCampaign')}</H4>
          </ProcessPreviewButton>
        </ProcessPreviewButtonsContainer>
      </ProcessPreviewContainer>
    </PreviewPanel>
  );
};

export default ProcessPreview;

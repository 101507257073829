import moment from 'moment';
import React from 'react';
import { H3 } from '../../../../App.style';
import { CampaignNonWorkingDaysConfiguration } from '../../../../../generated/tenants/Api';
import PresetListPreview from '../../PresetListPreview';
import {
  AddPresetIconContainer,
  CreateNewPresetContainer,
  CreatePresetButtonContainer,
  ListContainer,
} from '../../PresetsPanel.style';
import { NewPresetIcon } from '../../../../../resources/icons-new/NewPresetIcon';
import { useTranslation } from 'react-i18next';

interface BotDaysOffListProps {
  botDaysOffList: CampaignNonWorkingDaysConfiguration[];
  createBotDaysOff: () => void;
  goToDetailed: (botDaysOffId: string) => void;
  findProcessNamesById(id: string): string[];
  handleDeleteBotDaysOff: (id: string) => void;
  handleDuplicateBotDaysOff: (id: string) => void;
  hasPermission: boolean;
}

const BotDaysOffList: React.FC<BotDaysOffListProps> = ({
  botDaysOffList,
  createBotDaysOff,
  goToDetailed,
  findProcessNamesById,
  handleDeleteBotDaysOff,
  handleDuplicateBotDaysOff,
  hasPermission,
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer>
      {botDaysOffList &&
        botDaysOffList.map((botDaysOff, index) => {
          const formattedCreated = moment(botDaysOff.created_at).format(
            'DD/MM/YYYY, HH:mm',
          );
          const fomrattedModified = moment(botDaysOff.updated_at).format(
            'DD/MM/YYYY, HH:mm',
          );

          const usedIn = findProcessNamesById(botDaysOff.id);
          return (
            <PresetListPreview
              type={'call_centre_days_off'}
              key={`call_centre_days_off-${index}`}
              index={index}
              onClick={goToDetailed}
              presetId={botDaysOff.id}
              presetName={botDaysOff.name}
              createDate={formattedCreated}
              modifiedDate={fomrattedModified}
              usedIn={usedIn}
              handleDeletePreset={handleDeleteBotDaysOff}
              handleDuplicatePreset={handleDuplicateBotDaysOff}
              disableDots={!hasPermission}
            />
          );
        })}

      {hasPermission && (
        <CreateNewPresetContainer onClick={createBotDaysOff}>
          <CreatePresetButtonContainer>
            <AddPresetIconContainer>
              <NewPresetIcon
                style={{
                  position: 'absolute',
                  left: 17,
                  top: 17,
                  width: 15,
                  height: 15,
                }}
              />
            </AddPresetIconContainer>
            <H3>{t('common.createNew')}</H3>
          </CreatePresetButtonContainer>
        </CreateNewPresetContainer>
      )}
    </ListContainer>
  );
};

export default BotDaysOffList;

import React from 'react';
import { Label } from '../../../../generated/tenants/Api';
import {
  DeleteColumnContainer,
  DeleteColumnText,
} from '../../../../common/modals/Modals.style';
import { TrashIcon } from '../../../../resources/icons-new/TrashIcon';
import { H3 } from '../../../App.style';
import Modal from '../../../../common/modals/Modal';
import { useTranslation } from 'react-i18next';

interface LabelDeleteModalProps {
  isVisible: boolean;
  selectedLabel: Label;
  closeModal: () => void;
  deleteLabel: () => void;
}

const LabelDeleteModal: React.FC<LabelDeleteModalProps> = ({
  isVisible,
  selectedLabel,
  closeModal,
  deleteLabel,
}) => {
  const { t } = useTranslation();
  const handleConfirmClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    deleteLabel();

    return false;
  };

  const body = (
    <DeleteColumnContainer>
      <TrashIcon style={{ width: 18, height: 18 }} />
      <DeleteColumnText>
        <H3>{t('presets.labelsForm.deleteQuestion')}</H3>
      </DeleteColumnText>
    </DeleteColumnContainer>
  );

  return (
    <Modal
      isVisible={isVisible}
      headerTitle={`${t('presets.labelsForm.deleteLabel')} 
      ${selectedLabel.text}`}
      onCancel={closeModal}
      onConfirm={handleConfirmClick}
      children={body}
      buttonStyle="RED"
    />
  );
};

export default LabelDeleteModal;
